interface Props {
  label: string;
  action: any;
}

function LabelActions(props: Props) {
  return (
    <div className="label-action flex t-pl-4 t-p-0 hover:t-bg-appgrey-100 t-rounded-xl t-items-center t-w-full t-justify-between">
      <div className="t-pb-[2px]">
        <p className="t-font-semibold t-text-appgrey-800">{props.label}</p>
      </div>
      <div >{props.action}</div>
    </div>
  );
}

export default LabelActions;
