const kDebugMode: boolean = true;

const homeSlideEventPostCategoryId = "7cd47be0-e3b5-4096-acd5-53c0998328e8";

const homeSlideMandatedCropsPostCategoryId =
  "0fb97a4f-3d3b-4261-950b-887b0f1878ca";

const homeSlideNewsPostCategoryId = "65ca60f3-5284-40b0-86c5-aba47b270af2";

const homeSlideArticlesPostCategoryId = "166e252e-663b-44c0-825c-dbff62b1fc12";

const contentUsersFollow = [
  "Event/Gallery",
  "News/Announcements",
  "MandatedCrops",
  "Articles/Publications",
  "NewsLetters",
];

export {
  kDebugMode,
  homeSlideEventPostCategoryId,
  homeSlideNewsPostCategoryId,
  homeSlideMandatedCropsPostCategoryId,
  homeSlideArticlesPostCategoryId,
  contentUsersFollow,
};
