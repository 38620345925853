import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { GraphQLResult } from "@aws-amplify/api-graphql";
import { DataLoadingState, Folder, S3UploadedItem, } from "../../../models";
import useDataFilesManagerList from "../data/data-for-folders-list";
import useDataForUserAuth from "../../../store/auth/data-for-auth";
import { createFolder } from "../../../graphql/mutations";
import { listFolders } from "../../../graphql/queries";
import * as subscriptions from "../../../graphql/subscriptions";

interface ServicesForFoldersFilesInterface {
    addNewFolder: (item: Folder) => Promise<boolean>;
    addNewFolderFile?: (item: S3UploadedItem) => Promise<boolean>;

    updateFolder: (item: Folder) => Promise<boolean>;
    updateFolderFile?: (item: S3UploadedItem) => Promise<boolean>;

    updateFolderField: (
        id: string,
        key: string,
        value: any
    ) => Promise<boolean>;

    deleteFolder: (item: Folder) => Promise<boolean>;

    findOneFolder: (id: string) => Promise<Folder | null>;

    listAllFolders: () => Promise<Folder[] | null>;

    findOneFolderFile?: (id: string) => Promise<S3UploadedItem | null>;

    listAllFolderFiles?: () => Promise<S3UploadedItem[] | null>;
}

export function useFoldersFilesServiceAPI(): ServicesForFoldersFilesInterface {

    const addItemFile = useDataFilesManagerList(
        (state) => state.addItemFile
    );

    const addItemFolder = useDataFilesManagerList(
        (state) => state.addItemFolder
    );


    const setListItemsFolders = useDataFilesManagerList(
        (state) => state.setListItemsFolders
    );

    const updateItemFolder = useDataFilesManagerList(
        (state) => state.updateItemFolder
    );

    const deleteItemFolder = useDataFilesManagerList(
        (state) => state.deleteItemFolder
    );

    const listItemsFolders = useDataFilesManagerList(
        (state) => state.listItemsFolders
    );

    const [addingNewItem, setAddingNewItem] = useState(false);

    const [updatingNewItem, setUpdatingNewItem] = useState(false);

    const [deletingNewItem, setDeletingNewItem] = useState(false);

    const loggedUser = useDataForUserAuth((state) => state.loggedUser);

    const statusForLoadingListItemsFolders = useDataFilesManagerList(
        (state) => state.statusForLoadingListItemsFolders
    );
    const setStatusForLoadingListItemsFolders = useDataFilesManagerList(
        (state) => state.setStatusForLoadingListItemsFolders
    );


    const addNewFolder = async (item: Folder) => {
        let newItem: Folder;
        let promise: any;

        newItem = {
            ...item,
            dateUpdated: new Date().toISOString(),
            dateAdded: new Date().toISOString(),

        };

        console.log("addNewFolder newItemToSave", newItem);


        promise = new Promise((resolve, reject) => {
            const savedItem = API.graphql(
                graphqlOperation(createFolder, { input: newItem })
            );
            console.log("addNewFolder savedItem", savedItem);
            resolve(savedItem);
            reject(savedItem);
        });

        async function onFulfilled(results: any) {
            console.log("addNewFolder results onFulfilled", results);
            return true;
        }
        function onError(results: any) {
            console.log("addNewFolder results onError", results);

            return false;
        }

        promise.then(onFulfilled).catch(onError);

        return false;

    };

    const updateFolder = async (item: Folder) => {
        let newItem: Folder;
        let promise: any;


        newItem = {
            ...item,
            dateUpdated: new Date().toISOString(),
            dateAdded: new Date().toISOString(),

        };

        delete (newItem as any).pages;
        delete (newItem as any).createdAt;
        delete (newItem as any).staffProfiles;
        delete (newItem as any).updatedAt;
        delete (newItem as any)._lastChangedAt;
        delete (newItem as any)._deleted;



        console.log("updateFolder newItemToUpdate", newItem);



        promise = new Promise((resolve, reject) => {
            const savedItem = API.graphql(
                graphqlOperation(updateFolder, { input: newItem })
            );
            console.log("updateFolder savedItem", savedItem);
            resolve(savedItem);
            reject(savedItem);
        });

        async function onFulfilled(results: any) {
            console.log("updateFolder results onFulfilled", results);
            return true;
        }
        function onError(results: any) {
            console.log("updateFolder results onError", results);

            return false;
        }

        promise.then(onFulfilled).catch(onError);

        return false;

    };

    const findOneFolder = async (id: string) => {
        let foundItem: Folder | null | undefined;
        let promise: Promise<GraphQLResult<any>>;

        promise = API.graphql(
            //fix this later
            graphqlOperation(findOneFolder, { id: id })
        );

        await promise
            .then((results) => {
                foundItem = results.data as Folder;
                console.log("findOneFolder foundItem", foundItem);
                return foundItem;
            })
            .catch((e) => {
                console.log("error findOneFolder", e);
                return null;
            });

        return null;
    };

    const listAllFolders = async () => {
        let foundItems: Folder[] | null | undefined;
        let promise: Promise<GraphQLResult<any>>;

        promise = API.graphql({
            query: listFolders,
        });

        await promise
            .then((results) => {

                if (statusForLoadingListItemsFolders == DataLoadingState.LOADING) return
                if (statusForLoadingListItemsFolders == DataLoadingState.LOADED) return

                if (statusForLoadingListItemsFolders == DataLoadingState.PENDING) {
                    setStatusForLoadingListItemsFolders(DataLoadingState.LOADING);
                }

                foundItems = results.data.listFolders.items as Folder[];


                console.log("listAllFolders", foundItems);
                setListItemsFolders(foundItems);
                setStatusForLoadingListItemsFolders(DataLoadingState.LOADED)
                return foundItems;
            })
            .catch((e) => {
                console.log("error listAllFolders", e);
                setStatusForLoadingListItemsFolders(DataLoadingState.ERROR)
                return null;
            });
        return null;
    };

    const deleteFolder = async (item: Folder) => {
        let promise: Promise<GraphQLResult<any>>;
        //fix this later
        promise = API.graphql(
            graphqlOperation(deleteFolder, { input: item })
        );

        await promise
            .then((_) => {
                deleteItemFolder(item);
                return true;
            })
            .catch((e) => {
                console.log("error deleteFolder", e);
                return false;
            });
        return false;
    };

    //FIGURE OUT API
    const updateFolderField = async (
        id: string,
        key: string,
        value: any
    ) => {
        return false;
    };

    useEffect(() => {
        const createSubscription = (
            API.graphql(
                graphqlOperation(subscriptions.onCreateFolder)
            ) as unknown as any
        ).subscribe({
            next: ({ provider, value }) => {
                console.log("onCreateFolder", { provider, value });

                if (addingNewItem) return;

                setAddingNewItem(true);

                const newItem = value.data.onCreateFolder as Folder;

                addItemFolder(newItem);

                setAddingNewItem(false);

            },
            error: (error: any) => {
                console.warn(error);
            },
        });

        const updateSubscription = (
            API.graphql(
                graphqlOperation(subscriptions.onUpdateFolder)
            ) as unknown as any
        ).subscribe({
            next: ({ provider, value }) => {
                console.log("onUpdateFolder", { provider, value });


                if (updatingNewItem) return;

                setUpdatingNewItem(true);

                const newItem = value.data.onUpdateFolder as Folder;

                updateItemFolder(newItem);

                setUpdatingNewItem(false);

            },
            error: (error: any) => {
                console.warn(error);
            },
        });

        const deleteSubscription = (
            API.graphql(
                graphqlOperation(subscriptions.onDeleteFolder)
            ) as unknown as any
        ).subscribe({
            next: ({ provider, value }) => {
                console.log("onDeleteFolder", { provider, value });


                if (deletingNewItem) return;

                setDeletingNewItem(true);

                const newItem = value.data.onDeleteFolder as Folder;

                deleteItemFolder(newItem);

                setDeletingNewItem(false);

            },
            error: (error: any) => {
                console.warn(error);
            },
        });

        return () => {
            const cleanupSubscriptions = () => {
                createSubscription.unsubscribe();
                updateSubscription.unsubscribe();
                deleteSubscription.unsubscribe();
            };
            cleanupSubscriptions();
        };
    }, []);

    return {
        addNewFolder,
        updateFolder,
        deleteFolder,
        findOneFolder,
        updateFolderField,
        listAllFolders,
    };
}


