import * as Yup from "yup";

export const SchemaForPostCategory = Yup.object().shape({
    name: Yup.string()
        .min(1, "Too Short!")
        .max(100, "Too Long!")
        .required("Required"),
    slug: Yup.string()
        .required("Required"),
    shortDescription: Yup.string()
        .min(1, "Too Short!")
        .max(1000, "Too Long!")
        .required("Required"),
    // subCategories: Yup.mixed<PostSubCategory[]>().optional()
});


export const SchemaForPostSubCategory = Yup.object().shape({
    // id: Yup.string()
    //     .required("Required").default(''),
    // postCategoryID: Yup.string()
    //     .required("Required").default(''),
    name: Yup.string()
        .min(1, "Too Short!")
        .max(100, "Too Long!")
        .required("Required").default(''),
    // slug: Yup.string()
    //     .required("Required").default(''),
});