import { useEffect } from "react";
import { DataLoadingState, Page, PageType } from "../../../../models";
import useDataForCoverImageFile from "../../../../shared-components/images/data-cover-image";
import useDataForUserAuth from "../../../../store/auth/data-for-auth";
import useDataDivisionSubStationsForm from "../data/data-for-divisions-sub-stations-form";
import useDataDivisionAndSubstationsList from "../data/data-for-divisions-sub-stations-list";
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../../graphql/mutations';
import * as queries from '../../../../graphql/queries';
import * as subscriptions from '../../../../graphql/subscriptions';
import { GraphQLResult } from "@aws-amplify/api-graphql"
import { createPageGQL, listPagesGQL, updatePageGQL } from "../../../../custom-graphql/custom-gql-page";

interface ServiceInterfaceForDivisionSubStations {
    addNewData: (item: Page) => Promise<boolean>;
    updateData: (item: Page, isDelete: boolean) => Promise<boolean | Page>;
    updateDataField: (
        id: string,
        key: string,
        value: any
    ) => Promise<boolean>;
    deleteData: (item: Page) => Promise<boolean>;
    findOneData: (id: string) => Promise<Page | null>;
    listAllData: () => Promise<[Page] | null>;
}


export function useServiceForDivisionsSubstationsAPI(): ServiceInterfaceForDivisionSubStations {
    const pageDataAdd = useDataDivisionAndSubstationsList((state) => state.addItem);
    const loggedUser = useDataForUserAuth((state) => state.loggedUser);
    const formData = useDataDivisionSubStationsForm((state) => state.formData);
    const setFormData = useDataDivisionSubStationsForm((state) => state.setFormData);
    const pageDataSetStatusForLoading = useDataDivisionAndSubstationsList((state) => state.setStatusForLoadingListItems);
    const coverImageGetS3URL = useDataForCoverImageFile((state) => state.getS3URL);

    const pageDataSetList = useDataDivisionAndSubstationsList(
        (state) => state.setListItems
    );
    const pageDataUpdate = useDataDivisionAndSubstationsList(
        (state) => state.updateItem
    );
    const pageDataDelete = useDataDivisionAndSubstationsList(
        (state) => state.deleteItem
    );

    const addNewData = async (item: Page) => {
        let newItem: Page;
        let promise: Promise<GraphQLResult<any>>;
        let dataResult: Page;

        newItem = {
            ...item,
            dateUpdated: new Date().toISOString(),
            creatorUserAccountID: loggedUser!.userAccount!.id
        };

        promise = API.graphql(graphqlOperation(createPageGQL, { input: newItem }));

        await promise.then((results) => {
            dataResult = results.data.createPage;
            console.log('dataResult', dataResult)
            setFormData(newItem)
            return true

        }).catch((e) => {
            console.log('error addNewDivision', e)
            return false
        });
        return false
    };

    const updateData = async (item: Page, isDelete: boolean) => {
        let newItem: Page;
        let promise: Promise<GraphQLResult<any>>;
        let dataResult: Page;
        newItem = {
            ...item,

            dateUpdated: new Date().toISOString(),
        };

        delete (newItem as any).createdAt;
        delete (newItem as any).staffProfiles;
        delete (newItem as any).postCategory;
        delete (newItem as any).postSubCategory;
        delete (newItem as any).updatedAt;
        delete (newItem as any).creatorUserAccount;
        delete (newItem as any)._lastChangedAt;
        delete (newItem as any)._deleted;
        delete (newItem as any).files;
        delete (newItem as any).unitDepartments;

        console.log('updateDivision item', newItem)

        promise = API.graphql(graphqlOperation(updatePageGQL, { input: newItem }));

        await promise.then((results) => {
            dataResult = results.data as Page;
            console.log('dataResult', dataResult)
            setFormData(newItem)
            return true
        }).catch((e) => {
            console.log('error updateDivision', e)
            return false
        });
        return false
    };


    const listAllData = async () => {

        let foundItems: Page[] | null | undefined;
        let promise: Promise<GraphQLResult<any>>;

        const variables = {
            filter: {
                pageType: {
                    eq: PageType.DIVISION_SUB_STATION,
                }
            }
        };


        promise = API.graphql({
            query: listPagesGQL,
            variables: variables
        })

        await promise.then(async (results) => {
            foundItems = results.data.listPages.items as Page[]
            console.log('listAllDivisions foundItems', foundItems)
            if (foundItems.length > 0) {
                for (var item of foundItems) {
                    const coverImage = item.coverImage;
                    let url: string | null = '';
                    if (coverImage != null) {
                        url = await coverImageGetS3URL(coverImage.key);
                        if (url != null) {
                            function findIndex(listItem: Page) {
                                return listItem.id === item.id;
                            }

                            const index = foundItems.findIndex(findIndex)
                            item = { ...item, coverImage: { ...item.coverImage!, signedURL: url } }
                            console.log('listAllDivisions foundItems index', index)
                            foundItems[index] = item;
                        }
                    }

                }
                foundItems.sort((a, b) => new Date(b.datePublished!).getTime() - new Date(a.datePublished!).getTime())
            }

            pageDataSetList(foundItems)
            pageDataSetStatusForLoading(DataLoadingState.LOADED)
            return foundItems
        }).catch((e) => {
            console.log('error listAllDivisions', e)
            return null
        });
        return null;
    };


    const findOneData = async (id: string) => {
        let foundItem: Page | null | undefined;
        let promise: Promise<GraphQLResult<any>>;

        promise = API.graphql(graphqlOperation(queries.getPage, { id: id }));

        await promise.then((results) => {
            foundItem = results.data as Page;
            console.log('foundItem', foundItem)
            return foundItem

        }).catch((e) => {
            console.log('error findOneDivision', e)
            return null
        });

        return null
    };



    const deleteData = async (item: Page) => {
        let promise: Promise<GraphQLResult<any>>;

        promise = API.graphql(graphqlOperation(mutations.deletePage, { input: item }));

        await promise.then((_) => {
            // pageDataDelete(item)
            return true
        }).catch((e) => {
            console.log('error deleteDivision', e)
            return false
        });
        return false
    };


    //FIGURE OUT API
    const updateDataField = async (
        id: string,
        key: string,
        value: any
    ) => {
        return false;
    };



    useEffect(() => {

        const createSubscription = (API.graphql(
            graphqlOperation(subscriptions.onCreatePage, {
                filter: {
                    pageType: { eq: PageType.DIVISION_SUB_STATION }
                }
            })
        ) as unknown as any).subscribe({
            next: async ({ provider, value }) => {
                console.log({ provider, value })
                let data = value.data.onCreatePage as Page;
                let url: string | null = ''
                if (data.coverImage != null) {
                    url = await coverImageGetS3URL(data.coverImage.key);
                    if (url != null) {
                        data = { ...data, coverImage: { ...data.coverImage!, signedURL: url } }
                        // console.log('onCreatePage next ', data)
                    }
                }

                pageDataAdd(data);
                if (formData?.id == data.id) {
                    setFormData(data);
                }
            },
            error: (error: any) => {
                console.warn(error)
            }
        });

        const updateSubscription = (API.graphql(
            graphqlOperation(subscriptions.onUpdatePage, {
                filter: {
                    pageType: { eq: PageType.DIVISION_SUB_STATION }
                }
            })
        ) as unknown as any).subscribe({
            next: async ({ provider, value }) => {
                console.log({ provider, value })
                let data = value.data.onUpdatePage as Page;
                let url: string | null = ''
                if (data.coverImage != null) {
                    url = await coverImageGetS3URL(data.coverImage.key);
                    if (url != null) {
                        data = { ...data, coverImage: { ...data.coverImage!, signedURL: url } }
                        // console.log('onUpdatePage next ', data)
                    }
                }

                pageDataUpdate(data);
                if (formData?.id == data.id) {
                    setFormData(data);
                }
            },
            error: (error: any) => {
                console.warn(error)
            }
        });


        const deleteSubscription = (API.graphql(
            graphqlOperation(subscriptions.onDeletePage, {
                filter: {
                    pageType: { eq: PageType.DIVISION_SUB_STATION }
                }
            })
        ) as unknown as any).subscribe({
            next: ({ provider, value }) => {
                console.log({ provider, value })
                pageDataDelete(value.data.onDeletePage as Page);
            },
            error: (error: any) => {
                console.warn(error)
            }
        });


        return () => {
            const cleanupSubscriptions = () => {
                createSubscription.unsubscribe();
                updateSubscription.unsubscribe();
                deleteSubscription.unsubscribe();
            }
            cleanupSubscriptions();

        }
    }, []);

    return {
        addNewData,
        updateData,
        updateDataField,
        deleteData,
        findOneData,
        listAllData,
    };
}
