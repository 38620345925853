interface Props {
  title: string;
}

function SectionHeader({ title }: Props) {
  return (
    <div>
      <p className="t-mb-2 t-text-[26px] t-font-playfair t-font-bold ">{title}</p>
      <hr />
    </div>
  );
}

export default SectionHeader;
