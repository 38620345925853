import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
// import NavMenu from "../../../components/global/navs/MenuItems";
// import { AuthResponseModel, LoggedUser } from "../../../models-app/auth";
import { ProgressBar } from "primereact/progressbar";
import useRoutePaths from "../../routes/route-path";
import { Blurhash } from "react-blurhash";
import useDataForUserAuth from "../../store/auth/data-for-auth";
import { DataLoadingState } from "../../models";

// import { useNavMenuStore } from "../../../store/nav/data-for-nav-menu-control";
// import { useServiceForAuthentication } from "../../../services/useServiceForAuthentication";
// import { useStoreForTheming } from "../../../store/hooks/theming/data-for-theme";
// import { useStoreForLoggedUser } from "../../../store/auth/data-for-auth";

const Spinner2 = (
  <div className="t-w-[30vw] md:t-w-[50vw] t-max-w-[250px] mt-0 t-mx-auto">
    <ProgressBar
      className="!t-bg-black/[.20]"
      mode="indeterminate"
      color="#629c44"
      style={{ height: "10px" }}
    ></ProgressBar>
  </div>
);

const AuthSplash = () => {
  const [imageLoading, setLoading] = useState(true);

  const isInSession = useDataForUserAuth(
    (state) => state.loggedUser?.currentSession
  );

  const loadingCredentials = useDataForUserAuth(
    (state) => state.loadingCredentials
  );

  const loggedUser = useDataForUserAuth((state) => state.loggedUser);

  const setLoadingCredentials = useDataForUserAuth(
    (state) => state.setLoadingCredentials
  );

  const navigate = useNavigate();

  const [timedOut, timedOutSetState] = useState<Boolean>(false);

  const [reload, reloadSetState] = useState<Boolean>(false);

  const [doneLoading, doneLoadingSetState] = useState<Boolean>(false);

  const setTimeOutVal = async (value: Boolean) => {
    timedOutSetState((_) => value);
    console.log("The new timeout value is: " + value);
  };

  const { paths: route } = useRoutePaths();

  // //TODO: BRING THIS BACK
  const next = async () => {
    if (loadingCredentials === DataLoadingState.LOADED && timedOut === true) {
      console.log("loadingCredentials is ", loadingCredentials);
      if (loggedUser == null) {
        console.log("loggedUser is null");
        navigate(`${route.authSignIn}`, { replace: true });
        return;
      }

      if (loggedUser?.currentUser == null) {
        console.log("loggedUser is null 2");
        navigate(`${route.authSignIn}`, { replace: true });
        return;
      }

      if (loggedUser.isInSession == true) {
        console.log("isInSession");
        navigate(`${route.articlesPublications.list}`, { replace: true });
        return;
      }
    }

    if (loadingCredentials === DataLoadingState.LOADING) {
      return;
    }

    return;
  };

  useEffect(() => {
    const countDowner = setTimeout(function () {
      setTimeOutVal(true);
    }, 2000);

    return () => clearTimeout(countDowner);
  }, []);

  const onLoadedSplashBgImage = () => {
    setLoading(false);
  };

  useEffect(() => {
    next();

    return () => {};
  }, [timedOut]);

  useEffect(() => {
    next();

    return () => {};
  }, [loadingCredentials]);

  return (
    <div
      id="splash"
      className="t-h-screen t-bg-appbrown-500 t-w-screen t-items-center  t-flex t-flex-row t-justify-center"
    >
      <motion.img
        initial={{ opacity: 0 }}
        animate={{
          opacity: imageLoading ? 0 : 1,
        }}
        transition={{ opacity: { delay: 0.5, duration: 0.4 } }}
        loading="lazy"
        onLoad={onLoadedSplashBgImage}
        className="t-h-full t-object-cover   t-left-0 t-z-[0]  t-absolute t-top-0 t-w-full"
        src={"/img/splash-bg.jpg"}
        alt="splash-bg"
      />

      {imageLoading && (
        <Blurhash
          hash="L7A,UO0fE3$$~UNGM|V]s;aJbHNH"
          className="!t-h-full !t-left-0 !t-z-[-1] !t-absolute !t-top-0 !t-w-full"
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      )}

      <div className="t-h-screen  t-bg-appbrown-500/[.95] t-z-[1] t-w-screen  t-absolute" />

      <div className="t-my-auto t-py-5 t-flex t-flex-col t-items-center t-z-[2] t-justify-center">
        <div className="t-mx-10 t-my-0">
          <img
            alt=""
            loading="lazy"
            className="t-px-10 t-py-0 t-object-contain"
            width={212}
            src="/img/crig-logo.png"
          />
        </div>
        <div className="t-text-center t-py-5">
          <p className="t-text-appyellow-500 t-font-playfair t-font-bold  t-text-xl md:t-text-3xl">
            CRIG
          </p>
        </div>
        <div className="t-pt-2">{Spinner2}</div>
      </div>
    </div>
  );
};

export default AuthSplash;
