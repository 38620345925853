
import { useNavigate } from "react-router-dom";
import useDataForTextEditor from "../../../editors/data-for-editor";
import { FormActionType } from "../../../models";
import useRoutePaths from "../../../routes/route-path";
import TableAddNewButton from "../../../shared-components/button/TableAddNewButton";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataForGalleryImagesFile from "../../../shared-components/images/data-gallery-images";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import useDataNewsAnnouncementForm from "../data/data-for-news-announcement-form";
import TableNewsAnnouncement from "../tables/table-for-news-announcements";

function ListNewsAnnouncement() {
    const { paths: route } = useRoutePaths();
    const navigation = useNavigate();
    const setFormActionType = useDataNewsAnnouncementForm(
        (state) => state.setFormActionType
    );
    const galleryImageReset = useDataForGalleryImagesFile((state) => state.reset);
    const coverImageReset = useDataForCoverImageFile((state) => state.reset);
    const editorReset = useDataForTextEditor((state) => state.reset);
    const resetForm = useDataNewsAnnouncementForm(
        (state) => state.reset
    );
    return (
        <div className="flex t-flex-col t-overflow-hidden t-justify-start t-h-fit t-rounded-lg t-shadow-lg t-m-10">
            <div className="">
                <CustomNavigation
                    background={false}
                    title="News/Announcement"
                    buttons={
                        <TableAddNewButton label='Add New' onClick={() => {
                            resetForm()
                            coverImageReset()
                            editorReset()
                            galleryImageReset()
                            setFormActionType(FormActionType.NEW);
                            navigation(route.newsAnnouncements.details);
                        }} />
                    }
                />
            </div>

            <TableNewsAnnouncement />
        </div>
    );
}

export default ListNewsAnnouncement;
