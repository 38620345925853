import produce from "immer";
import { create } from "zustand";
import { S3UploadedItem } from "../../../models";
import { PageDataInterfaceFolderFileForm } from "./data-interface-for-files-manager";

const useDataFilesManagerFile =
  create<PageDataInterfaceFolderFileForm>()((set, get) => {
    return {

      showDialog: false,
      setFormData: async (item) => {
        set((_) => ({ formData: item }));
        return true;
      },
      formActionType: undefined,
      setShowDialog: async (value) => {
        set((_) => ({ showDialog: value }));
        return true;
      },
      formData: {} as S3UploadedItem,
      setFormActionType: async (item) => {
        set((_) => ({ formActionType: item }));
        return true;
      },
      updateFormDataField: async (property, value) => {
        set(
          produce((state: PageDataInterfaceFolderFileForm) => {
            state.formData![property] = value;
          })
        );
        return true;
      },
    };
  });

export default useDataFilesManagerFile;
