import produce from "immer";
import { create } from "zustand";
import { DataLoadingState, PostCategory } from "../../../../models";
import { PageDataInterfacePostCategoryList } from "./data-interface-for-post-main-sub-categories";

// const dummyItem: any = {
//   id: "id",
//   name: "Articles",
//   shortDescription: "Articles and publications",
//   slug: "articles",
//   articlesPublications: undefined,
//   pages: undefined,
//   creatorUserAccount: undefined,
//   subCategories: undefined,
//   totalPostsCount: 12,
//   totalSubCategoriesCount: 0,
//   dateUpdated: '12th  Jan. 2023'
// };

const useDataPostCategoryList =
  create<PageDataInterfacePostCategoryList>()((set, get) => {
    return {
      statusForLoadingListItems: DataLoadingState.PENDING as
        | DataLoadingState
        | undefined,
      listItems: [

      ],

      setStatusForLoadingListItems: async (newValue) => {
        set((_) => ({ statusForLoadingListItems: newValue }));
        return true;
      },
      setListItems: async (items) => {
        set((_) => ({ listItems: items }));
        return true;
      },
      updateItem: async (newItem) => {
        const newItemRecord = newItem as PostCategory;
        set(
          produce((state: PageDataInterfacePostCategoryList) => {
            function findIndexFxn(item: PostCategory) {
              return item.id === newItemRecord.id;
            }
            const index = state.listItems.findIndex(findIndexFxn);
            if (index >= 0) {
              state.listItems[index] = newItemRecord;
            }

          })
        );

        return true;
      },

      deleteItem: async (newItem) => {
        set(
          produce((state: PageDataInterfacePostCategoryList) => {
            function findIndexFxn(item: PostCategory) {
              return item.id === newItem.id;
            }

            const index = get().listItems.findIndex(findIndexFxn);
            if (index >= 0) {
              state.listItems.splice(index, 1);
            }
          })
        );

        return true;
      },

      addItem: async (newItem) => {
        let newItemRecord = newItem as PostCategory;
        set(
          produce((state: PageDataInterfacePostCategoryList) => {
            function findIndexFxn(item: PostCategory) {
              return item.id === newItem.id;
            }

            const index = get().listItems.findIndex(findIndexFxn);


            if (index >= 0) {
              state.listItems[index] = newItemRecord;
            } else {
              state.listItems.unshift(newItemRecord);
            }
          })
        );

        return true;
      },
    };
  });

export default useDataPostCategoryList;
