import ArticleStatus from "../components/ArticleStatus";
import { FormikProvider, useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import { Chips } from "primereact/chips";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { MultiSelect } from "primereact/multiselect";
import { useCallback, useEffect, useRef, useState } from "react";
import TextEditorCK from "../../../editors/ckeditorjs/TextEditorCKEditor";
import useDataForTextEditor from "../../../editors/data-for-editor";
import PageBuilder from "../../../editors/grapejs/PageBuilderGrape";
import { InputTextarea } from "primereact/inputtextarea";
import {
  ArticlePublication,
  ArticlePublicationPublisher,
  DataLoadingState,
  EditorType,
  Folder,
  FormActionType,
  PageType,
  PostCategory,
  PostSubCategory,
  S3UploadedItem,
  StaffProfile,
  UploadCategory,
  UserAccount,
} from "../../../models";
import useRoutePaths from "../../../routes/route-path";
// import useDataStaffProfileList from "../../page-cms-users/data/data-for-cms-users-list";
import FormActionButtonSet from "../../../shared-components/button/FormActionButtonSet";
import FormErrorMsg from "../../../shared-components/form/ErrorMsg";
import SectionHeader from "../../../shared-components/header/SectionHeader";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataForGalleryImagesFile from "../../../shared-components/images/data-gallery-images";
import { GalleryImagesUploader } from "../../../shared-components/images/GalleryImagesUploader";
import { CoverImageUploader } from "../../../shared-components/images/CoverImageUploader";
import useToaster from "../../../shared-components/toasts/Toaster";
import { StatusTypeSelect } from "../../../shared-components/toasts/forms/forms-interfaces";
import {
  formatDateToIsoString,
  formatIsoStringToDate,
  selectPageDesignTypes,
  selectPublishStatusType,
  visibility,
  slugify,
  publishers,
  authors,
} from "../../../utils/forms/util-forms";
import useDataFilesManagerList from "../../page-files-manager/data/data-for-folders-list";
import useDataPostCategoryList from "../../page-settings/page-main-sub-categories/data/data-for-post-categories-list";
import useDataPostSubCategoryList from "../../page-settings/page-main-sub-categories/data/data-for-sub-post-categories-list";
import { SchemaForArticlesPublications } from "../form-schemas/form-schema-articles-publications";
import { useServiceForArticlePublicationsAPI } from "../services/services-for-articles-publications";
import CustomNavigation from "./../../../shared-components/navigation/CustomNavigation";
import useDataArticlesPublicationsForm from "./../data/data-for-articles-publications-form";
import ErrorDialog from "../../../shared-components/form/ErrorDialog";
import LoadingDialog from "../../../shared-components/form/LoadingDialog";
import isDevMode from "../../../utils/check-dev-mode";
import { v4 as uuid } from "uuid";
import { useServiceForStaffProfileAPI } from "../../page-staff-profiles/services/services-for-staff-profiles";
import useDataStaffProfileList from "../../page-staff-profiles/data/data-for-staff-profiles-list";
import useDataForDocumentFile from "../../../shared-components/images/data-document-file";
import { FileUploader } from "../../../shared-components/images/FileUploader";


function FormArticlesPublications() {

  const { paths: route } = useRoutePaths();
  const findOneFolderByPageType = useDataFilesManagerList(
    (state) => state.findOneFolderByPageType
  );

  const formData = useDataArticlesPublicationsForm((state) => state.formData);

  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const [errorMsg] = useState(
    "Something went wrong kindly try again."
  );

  // const [postId, setPostId] = useState("");

  const formAction = useDataArticlesPublicationsForm(
    (state) => state.formActionType
  );

  const formActionSetState = useDataArticlesPublicationsForm(
    (state) => state.setFormActionType
  );

  const [pageDesignView, pageDesignViewSetState] = useState<string>(
    formData?.pageDesignType ?? EditorType.TEXT_EDITOR
  );

  const [validateForm, setValidateForm] = useState<boolean>(false);

  const [doneFindingAuthors, setDoneFindingAuthors] = useState<boolean>(false);

  const [folder, setFolder] = useState<Folder | null | undefined>(null);

  const [formItemStatus, setFormItemStatus] = useState<StatusTypeSelect | null>(
    selectPublishStatusType[0]
  );

  const [_formItemPageDesignSelect, setFormItemPageDesignSelect] =
    useState<StatusTypeSelect | null>(selectPageDesignTypes[0]);

  const coverImageFile = useDataForCoverImageFile((state) => state.file);

  const coverImageS3Item = useDataForCoverImageFile((state) => state.s3Item);

  const coverImageSetS3Item = useDataForCoverImageFile(
    (state) => state.setS3Item
  );


  const documentFile = useDataForDocumentFile((state) => state.file);

  const documentFileS3Item = useDataForDocumentFile((state) => state.s3Item);

  const documentFileSetS3Item = useDataForDocumentFile(
    (state) => state.setS3Item
  );


  const setTextEditorIsLoading = useDataForTextEditor(
    (state) => state.setTextEditorIsLoading
  );

  const setTextEditorDataString = useDataForTextEditor(
    (state) => state.setTextEditorDataString
  );

  const listStaffItemsFromService = useServiceForStaffProfileAPI().listAllData;

  const listStaffItems = useDataStaffProfileList((state) => state.listItems);

  console.log("rrrListStaffItems", listStaffItems);

  const setPageBuilderIsLoading = useDataForTextEditor(
    (state) => state.setPageBuilderIsLoading
  );

  const setPageBuilderDataString = useDataForTextEditor(
    (state) => state.setPageBuilderDataString
  );

  const setPageBuilderDataEncoded = useDataForTextEditor(
    (state) => state.setPageBuilderDataEncoded
  );

  const listItemsFolders = useDataFilesManagerList(
    (state) => state.listItemsFolders
  );

  const textEditorIsLoading = useDataForTextEditor(
    (state) => state.textEditorIsLoading
  );

  const textEditorDataString = useDataForTextEditor(
    (state) => state.textEditorDataString
  );

  const pageBuilderDataString = useDataForTextEditor(
    (state) => state.pageBuilderDataString
  );

  const postCategories = useDataPostCategoryList((state) => state.listItems);

  const { addNewData, updateData } = useServiceForArticlePublicationsAPI();

  const galleryImagesS3Items =
    useDataForGalleryImagesFile((state) => state.s3Items) ?? [];

  const galleryImagesS3ItemsSet =
    useDataForGalleryImagesFile((state) => state.setS3Items) ?? [];

  const galleryImagesFiles = useDataForGalleryImagesFile(
    (state) => state.files
  );

  const postSubCategories = useDataPostSubCategoryList(
    (state) => state.listItems
  );

  const [selectedPostCat, setSelectedPostCat] = useState<PostCategory | null>(
    null
  );
  const [selectedAuthors, setSelectedAuthors] = useState<StaffProfile[]>([]);
  const [selectedPublishers, setSelectedPublishers] = useState<ArticlePublicationPublisher[]>([]);


  const [selectedPostSubCat, setSelectedPostSubCat] =
    useState<PostSubCategory | null>(null);

  const [filteredPostSubCats, setFilteredPostSubCats] = useState<
    PostSubCategory[]
  >([]);

  useEffect(() => {
    if (listStaffItems.length == 0) {
      listStaffItemsFromService();
    }
    return () => { };
  }, []);



  const { showError, showSuccess } = useToaster();

  const formFormik = useFormik({
    initialValues: formData as ArticlePublication,
    validateOnBlur: false,
    validateOnChange: validateForm,
    enableReinitialize: true,
    validationSchema: SchemaForArticlesPublications,
    validate: (values) => {
      setValidateForm(true);
      if (isDevMode()) {
        console.log("validate", values);
        console.log("errors", formFormik.errors);
      }
    },
    onSubmit: async (values) => {
      if (isDevMode()) {
        console.log("submit", values);
      }


      // if (coverImageUploadStatus == DataLoadingState.PENDING || coverImageUploadStatus == DataLoadingState.ERROR) {
      //   showError(toast, 'ATTENTION', 'Kindly upload cover image to continue');
      //   formFormik.setSubmitting(false);
      //   return;
      // }

      await saveForm(values);
    },
  });



  useEffect(() => {
    let authorIds = formData?.authorStaffProfilesIds ?? [];
    if ((authorIds.length)
      && selectedAuthors.length == 0 && (listStaffItems ?? []).length && doneFindingAuthors == false) {

      let staffProfiles: StaffProfile[] = [];

      for (var authorIdItem of authorIds) {

        const filterFxnForAuthorProfile = (item: StaffProfile) => {
          return (item.id === authorIdItem);
        }

        let profileFound = listStaffItems.find(filterFxnForAuthorProfile)

        if (profileFound != undefined) {
          staffProfiles.push(profileFound);
        }

      }

      setSelectedAuthors(staffProfiles)

      setDoneFindingAuthors(true)

    }

    return () => {

    }
  }, [selectedAuthors, listStaffItems])


  const toast = useRef(null);
  const refTitle = useRef(null);
  const refShortDescription = useRef(null);
  const refSlug = useRef(null);
  const refCoverImage = useRef(null);
  const refGalleryImages = useRef(null);
  const refPageDesignType = useRef(null);
  const refDetails = useRef(null);
  const refPublishers = useRef(null);
  const refAuthorStaffProfiles = useRef(null);
  const refVolumeNumber = useRef(null);
  const refEditionNumber = useRef(null);
  const refIssueNumber = useRef(null);
  const refIssn = useRef(null);
  const refVisibilityType = useRef(null);
  const refTags = useRef(null);
  const refDateUploaded = useRef(null);
  const refDatePublished = useRef(null);
  const refMainCategory = useRef(null);
  const refSubCategory = useRef(null);
  const refStatus = useRef(null);
  const refDocFile = useRef(null);

  const fieldLabels = {
    coverImage: {
      id: "coverImage",
      ref: refCoverImage,
      label: "Cover Image",
    },
    documentFile: {
      id: "documentFile",
      ref: refDocFile,
      label: "Document File",
    },
    galleryImages: {
      id: "galleryImages",
      ref: refGalleryImages,
      label: "Files",
    },
    title: {
      id: "title",
      ref: refTitle,
      label: "Title",
    },
    shortDescription: {
      id: "shortDescription",
      ref: refShortDescription,
      label: "External Link (optional)",
    },
    slug: {
      id: "slug",
      ref: refSlug,
      label: "Slug",
    },
    details: {
      id: "details",
      ref: refDetails,
      label: "Details (optional)",
    },
    authorStaffProfiles: {
      id: "authorStaffProfiles",
      ref: refAuthorStaffProfiles,
      label: "Author(s) (optional)",
    },
    publishers: {
      id: "publishers",
      ref: refPublishers,
      label: "Publisher(s) (optional)",
    },
    pageDesignType: {
      id: "pageDesignType",
      ref: refPageDesignType,
      label: "Page Design Type",
    },
    moreDetails: {
      id: "details",
      ref: refDetails,
      label: "Details (Optional)",
    },
    mainCategory: {
      id: "mainCategory",
      ref: refMainCategory,
      label: "Main Category",
    },
    subCategory: {
      id: "subCategory",
      ref: refSubCategory,
      label: "Sub Category",
    },
    tags: {
      id: "tags",
      ref: refTags,
      label: "Tags (optional)",
    },
    volumeNumber: {
      id: "volumeNumber",
      ref: refVolumeNumber,
      label: "Volume Number (optional)",
    },
    editionNumber: {
      id: "editionNumber",
      ref: refEditionNumber,
      label: "Edition Number (optional)",
    },
    issueNumber: {
      id: "issueNumber",
      ref: refIssueNumber,
      label: "Issue Number (optional)",
    },
    issn: {
      id: "issn",
      ref: refIssn,
      label: "ISSN (optional)",
    },
    visibilityType: {
      id: "visibilityType",
      ref: refVisibilityType,
      label: "Visibility Type",
    },
    dateUploaded: {
      id: "dateAdded",
      ref: refDateUploaded,
      label: "Date Uploaded",
    },
    datePublished: {
      id: "datePublished",
      ref: refDatePublished,
      label: "Date Published",
    },
    status: {
      id: "status",
      ref: refStatus,
      label: "Status",
    },
  };

  const getStatus = (value: string) => {
    function findIndexFxn(item: StatusTypeSelect) {
      return item.value === value;
    }
    const index = selectPublishStatusType.findIndex(findIndexFxn);

    const item = selectPublishStatusType[index];

    return item;
  };

  const getPageDesignType = (value: string) => {
    function findIndexFxn(item: StatusTypeSelect) {
      return item.value === value;
    }
    const index = selectPageDesignTypes.findIndex(findIndexFxn);

    const item = selectPageDesignTypes[index];

    return item;
  };

  const changePageDesignView = useCallback(async () => {
    switch (pageDesignView) {
      case EditorType.TEXT_EDITOR:
        pageDesignViewSetState((_) => EditorType.PAGE_BUILDER);

        break;

      case EditorType.PAGE_BUILDER:
        pageDesignViewSetState((_) => EditorType.TEXT_EDITOR);
        break;

      default:
        pageDesignViewSetState((_) => EditorType.TEXT_EDITOR);
        break;
    }
  }, [pageDesignView]);

  async function saveForm(values: ArticlePublication) {
    let newItem: ArticlePublication;

    let authorsStaffIds: string[] = [];

    if (selectedAuthors.length > 0) {
      for (var item of selectedAuthors) {
        authorsStaffIds.push(item.id);
      }
    }


    switch (formAction ?? FormActionType.NEW) {
      case FormActionType.NEW:
        newItem = new ArticlePublication({
          title: values.title,
          status: values.status!,
          editionNumber: values.editionNumber,
          volumeNumber: values.volumeNumber,
          issueNumber: values.issueNumber,
          issn: values.issn,
          shortDescription: values.shortDescription,
          coverImage: coverImageS3Item,
          documents: documentFileS3Item == null ? null : [documentFileS3Item],
          galleryImages: galleryImagesS3Items,
          datePublished: formatDateToIsoString(values.datePublished!)!,
          pageDesignPageBuilder: pageBuilderDataString,
          pageDesignTextEditor: textEditorDataString,
          pageDesignType: values.pageDesignType,
          postCategoryID: values.postCategoryID,
          postSubCategoryID: values.postSubCategoryID,
          slug: values.slug,
          tags: values.tags,
          authorStaffProfilesIds: authorsStaffIds,
          visibilityType: values.visibilityType,
          deleted: false,
          dateUploaded: formatDateToIsoString(values.dateUploaded!)!,
        });

        newItem = { ...newItem, id: values.id }

        await addNewData(newItem, selectedAuthors)
          .then((done) => {

            if (done) {
              showSuccess(toast, 'ATTENTION', 'Successfully saved.');
              formActionSetState(FormActionType.EDIT)
              formFormik.setSubmitting(false);
              console.log('done addNewData');
            } else {
              console.log('err submitting addNewData',);
              showError(toast, 'ATTENTION', 'Something went wrong, kindly try again.');
              setShowErrorDialog(true);
              formFormik.setSubmitting(false);
            }

          })
          .catch((err) => {
            console.log("err submitting addNewData", err);
            showError(
              toast,
              "ATTENTION",
              "Something went wrong, kindly try again."
            );
            setShowErrorDialog(true);
            formFormik.setSubmitting(false);
          });
        break;

      case FormActionType.EDIT:
        // if (isDevMode()) {
        //   console.log('isoDate', isoDate)
        // }

        newItem = {
          ...values,
          coverImage: coverImageS3Item,
          galleryImages: galleryImagesS3Items,
          documents: documentFileS3Item == null ? null : [documentFileS3Item],
          dateUploaded: formatDateToIsoString(values.dateUploaded!)!,
          datePublished: formatDateToIsoString(values.datePublished!)!,
          tags: values.tags,
          authorStaffProfilesIds: authorsStaffIds,
          deleted: false,
        };

        await updateData(newItem, false, [])
          .then(async (done) => {
            showSuccess(toast, "ATTENTION", "Successfully saved.");
            formFormik.setSubmitting(false);
          })
          .catch((error) => {
            console.log("err submitting updateData", error);
            showError(
              toast,
              "ATTENTION",
              "Something went wrong, kindly try again."
            );
            setShowErrorDialog(true);
            formFormik.setSubmitting(false);
          });
        break;

      default:
        break;
    }
  }

  const onChangeSlug = (e: any) => {
    const slugged = slugify(e.target.value);
    formFormik.setFieldValue("slug", slugged);
    return;
  };

  useEffect(() => {
    if (formFormik.values.title?.length != 0) {
      const slugged = slugify(formFormik.values.title ?? "");
      formFormik.setFieldValue("slug", slugged);
    }

    return () => { };
  }, [formFormik.values.title]);

  useEffect(() => {
    if (formFormik.values.tags == null) {
      formFormik.setFieldValue("tags", []);
    }

    return () => { };
  }, [formFormik.values.tags]);

  useEffect(() => {
    if (pageDesignView === EditorType.PAGE_BUILDER) {
      const dataStringBuilder = formFormik.values.pageDesignPageBuilder ?? "";

      if (dataStringBuilder.length == 0) {
        setPageBuilderDataEncoded(JSON.parse("{}"));
        setPageBuilderDataString("");
        setPageBuilderIsLoading(false);
        return;
      }

      const dataJsonBuilder = JSON.parse(dataStringBuilder);
      setPageBuilderDataEncoded(dataJsonBuilder);
      setPageBuilderDataString(dataStringBuilder);
      setPageBuilderIsLoading(false);
    }

    if (pageDesignView === EditorType.TEXT_EDITOR) {
      const dataStringTextEditor = formFormik.values.pageDesignTextEditor ?? "";

      if (dataStringTextEditor.length == 0) {
        setTextEditorDataString("");
        setTextEditorIsLoading(false);
        return;
      }
      setTextEditorDataString(dataStringTextEditor);
      setTextEditorIsLoading(false);
    }

    return () => { };
  }, [pageDesignView]);

  useEffect(() => {
    setFormItemStatus(getStatus(formFormik.values.status ?? ""));
    return () => { };
  }, [formFormik.values.status]);

  useEffect(() => {
    formFormik.setFieldValue("pageDesignTextEditor", textEditorDataString);
    return () => { };
  }, [textEditorDataString]);

  useEffect(() => {
    formFormik.setFieldValue("pageDesignPageBuilder", pageBuilderDataString);
    return () => { };
  }, [pageBuilderDataString]);

  useEffect(() => {
    setFormItemPageDesignSelect(
      getPageDesignType(formFormik.values.pageDesignType!)
    );
    return () => { };
  }, [formFormik.values.pageDesignType]);

  useEffect(() => {
    const findIndexFxnPostCat = (item: PostCategory) => {
      return item.id == formFormik.values.postCategoryID;
    };

    const findIndexFxnPostSubCat = (item: PostSubCategory) => {
      return item.id == formFormik.values.postSubCategoryID;
    };

    const indexPostCat = [...postCategories].findIndex(findIndexFxnPostCat);

    const indexPostSubCat = [...postSubCategories].findIndex(
      findIndexFxnPostSubCat
    );

    if (indexPostCat >= 0 && selectedPostCat == null) {
      const selectedPostC = postCategories[indexPostCat];
      setSelectedPostCat(selectedPostC);

      const filterFxn = (item: PostSubCategory) => {
        return item.postCategoryID == selectedPostC.id;
      };

      const selectedPostSubC = postSubCategories[indexPostSubCat];
      setSelectedPostSubCat(selectedPostSubC);

      const filteredSubPostCats = [...postSubCategories].filter(filterFxn);
      setFilteredPostSubCats(filteredSubPostCats);
    }

    return () => { };
  }, [formFormik.values.postCategoryID, postCategories, postSubCategories]);

  useEffect(() => {
    if (formData?.coverImage != null && coverImageS3Item == null) {
      formFormik.setFieldValue("coverImage", coverImageS3Item);
      coverImageSetS3Item(formData.coverImage);
    }

    return () => { };
  }, [coverImageFile, coverImageS3Item, formData?.coverImage]);

  //GALLERY IMAGES SET
  useEffect(() => {
    if (formData?.galleryImages != null && galleryImagesS3Items.length == 0) {
      formFormik.setFieldValue("galleryImages", galleryImagesS3Items);
      galleryImagesS3ItemsSet(formData.galleryImages as S3UploadedItem[]);
    }

    return () => { };
  }, [galleryImagesFiles, galleryImagesS3Items, formData?.galleryImages]);

  //DOCUMENTS  files SET
  useEffect(() => {
    if (formData?.documents != null && formData?.documents.length > 0 && documentFileS3Item == null) {
      formFormik.setFieldValue("documents", documentFileS3Item);
      documentFileSetS3Item(formData.documents[0] as S3UploadedItem);
    }

    return () => { };
  }, [documentFileS3Item, documentFile, formData?.documents]);

  useEffect(() => {
    if (folder != null) return;

    const fetchData = async () => {
      const folderFound = await findOneFolderByPageType(
        PageType.ARTICLE_PUBLICATION
      );
      setFolder(folderFound);
    };

    fetchData().catch(console.error);

    return () => {
      if (folder != null) return;
    };
  }, [folder, listItemsFolders]);

  const fieldSlug = (
    <div className="form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.slug.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.slug.id}
          onChange={onChangeSlug}
          onBlur={formFormik.handleBlur}
          disabled
          value={formFormik.values.slug ?? ""}
          ref={fieldLabels.slug.ref}
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.slug.id}
        errorMsg={formFormik.errors.title}
      />
    </div>
  );

  const fieldTitle = (
    <div className="form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.title.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.title.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.title ?? ""}
          ref={fieldLabels.title.ref}
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.title.id}
        errorMsg={formFormik.errors.title}
      />
    </div>
  );
  const fieldShortDescription = (
    <div className="form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.shortDescription.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.shortDescription.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.shortDescription ?? ""}
          ref={fieldLabels.shortDescription.ref}
        />
      </span>
      {/* <FormErrorMsg
        fieldId={fieldLabels.shortDescription.id}
        errorMsg={formFormik.errors.shortDescription}
      /> */}
    </div>
  );

  const fieldDetails = (
    <div className="t-pl-[0rem] t-w-full ">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.details.label}
      </p>

      <div
        className={` w-full 
       p-input-filled t-border-[2px] t-shadow-none    
       t-pt-[0px] t-rounded-lg t-h-full form-text-editor t-overflow-hidden 
       ${textEditorIsLoading ? "t-hidden" : "t-flex"}
        t-border-gray-300 t-flex-col t-h-full`}
      >
        <TextEditorCK holder="editorjs-container" />
      </div>
      <div
        className={`w-full 
        t-border-[2px] 
       t-pt-[0px] form-field t-rounded-lg
       ${textEditorIsLoading ? "t-flex" : "t-hidden"}
        t-border-gray-300 t-flex-col t-h-full`}
      >
        <Skeleton width="100%" height="500px" />
      </div>
    </div>
  );

  const fieldTags = (
    <div className="t-w-full form-field">
      <p className="t-text-appgrey-800 t-mt-10 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.tags.label}
      </p>
      <span className="t-w-full  p-input-filled">
        <Chips
          id={fieldLabels.tags.id}
          value={formFormik.values.tags ?? []}
          ref={fieldLabels.tags.ref}
          className="t-w-full t-min-h-[100px] tags-input t-items-start"
          disabled={formFormik.isSubmitting}
          onBlur={formFormik.handleBlur}
          onChange={formFormik.handleChange}
        ></Chips>
      </span>
    </div>
  );

  const fieldDateUploaded = (
    <div className="t-w-1/2 t-mr-4 form-field date-pick">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.dateUploaded.label}
      </p>
      <Calendar
        id={fieldLabels.dateUploaded.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        dateFormat="dd/mm/yy"
        disabled={formFormik.isSubmitting}
        value={formatIsoStringToDate(formFormik.values.dateUploaded!)}
        ref={fieldLabels.dateUploaded.ref}
        className="w-full p-input-filled"
        showIcon
        iconPos="right"
      />
      <FormErrorMsg
        fieldId={fieldLabels.dateUploaded.id}
        errorMsg={formFormik.errors.dateUploaded}
      />
    </div>
  );

  const fieldDatePublished = (
    <div className="t-w-1/2 t-ml-4 form-field date-pick">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.datePublished.label}
      </p>
      <Calendar
        id={fieldLabels.datePublished.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        dateFormat="dd/mm/yy"
        disabled={formFormik.isSubmitting}
        value={formatIsoStringToDate(formFormik.values.datePublished!)}
        ref={fieldLabels.datePublished.ref}
        className="w-full p-input-filled"
        showIcon
        iconPos="right"
      />
      <FormErrorMsg
        fieldId={fieldLabels.datePublished.id}
        errorMsg={formFormik.errors.datePublished}
      />
    </div>
  );

  const fieldAuthors = (
    <div className="t-w-full form-field">
      <p className="t-text-appgrey-800 t-mt-8 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.authorStaffProfiles.label}
      </p>
      <div className="p-input-filled t-w-full  t-mr-4 t-mb-4 md:t-mb-0">
        <MultiSelect
          options={listStaffItems}
          optionLabel="nameFull"
          placeholder="Select"
          filter
          id={fieldLabels.authorStaffProfiles.id}
          onChange={(e) => {
            const selectedAuthor = e.value;
            if (isDevMode()) {
              console.log('selectedAuthor', selectedAuthor)
            }
            setSelectedAuthors(selectedAuthor);
          }}
          disabled={formFormik.isSubmitting}
          value={selectedAuthors}
          ref={fieldLabels.authorStaffProfiles.ref}
          display="chip"
          // maxSelectedLabels={3}
          className=" p-input-filled t-w-full "
        />
      </div>

      {/* <FormErrorMsg
        fieldId={fieldLabels.authorStaffProfiles.id}
        errorMsg={formFormik.errors.authorStaffProfiles}
      />  */}
    </div>
  );

  const fieldPublishers = (
    <div className="t-w-full form-field">
      <p className="t-text-appgrey-800 t-mt-8 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.publishers.label}
      </p>
      <div className="p-input-filled t-w-full t-mr-4 t-mb-4 md:t-mb-0">
        <MultiSelect
          options={publishers}
          optionLabel="name"
          placeholder="Select"
          id={fieldLabels.publishers.id}
          onChange={(e) => {
            const selectedPublisher = e.value;
            if (isDevMode()) {
              console.log('selectedPublisher', selectedPublisher)
            }
            setSelectedPublishers(selectedPublisher);
          }}
          disabled={formFormik.isSubmitting}
          value={selectedPublishers}
          ref={fieldLabels.authorStaffProfiles.ref}

          display="chip"
          // maxSelectedLabels={3}
          className=" p-input-filled t-w-full"
        />
      </div>
      {/* <FormErrorMsg
        fieldId={fieldLabels.publishers.id}
        errorMsg={formFormik.errors.publishers}
      /> */}
    </div>
  );

  const fieldStatus = (
    <div className="t-w-full form-field">
      <p className="t-text-appgrey-800 t-mt-8 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.status.label}
      </p>
      <Dropdown
        className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
        options={selectPublishStatusType}
        optionLabel="name"
        placeholder="Select"
        id={fieldLabels.status.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        disabled={formFormik.isSubmitting}
        value={formItemStatus?.value}
        ref={fieldLabels.status.ref}
      />
      <FormErrorMsg
        fieldId={fieldLabels.status.id}
        errorMsg={formFormik.errors.status}
      />
    </div>
  );
  const fieldVisibilityType = (
    <div className="t-w-full form-field">
      <p className="t-text-appgrey-800 t-mt-8 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.visibilityType.label}
      </p>
      <Dropdown
        className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
        options={visibility}
        optionLabel="name"
        placeholder="Select"
        id={fieldLabels.visibilityType.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        disabled={formFormik.isSubmitting}
        value={formFormik.values.visibilityType}
        ref={fieldLabels.visibilityType.ref}
      />
      <FormErrorMsg
        fieldId={fieldLabels.visibilityType.id}
        errorMsg={formFormik.errors.visibilityType}
      />
    </div>
  );


  const fieldMainCategory = (
    <div className="t-w-1/2 t-mr-4 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.mainCategory.label}
      </p>
      <Dropdown
        className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
        options={postCategories}
        optionLabel="name"
        filter
        placeholder="Select"
        id={fieldLabels.mainCategory.id}
        onChange={(e) => {
          const selectedPostC: PostCategory = e.value;
          setSelectedPostCat(selectedPostC);
          const filterFxn = (item: PostSubCategory) => {
            return item.postCategoryID == selectedPostC.id;
          };
          const filteredSubPostCats = [...postSubCategories].filter(filterFxn);
          setFilteredPostSubCats(filteredSubPostCats);
          formFormik.setFieldValue("postCategoryID", selectedPostC.id);
          formFormik.setFieldValue("postSubCategoryID", "");
        }}
        disabled={formFormik.isSubmitting}
        value={selectedPostCat}
        ref={fieldLabels.mainCategory.ref}
      />
    </div>
  );

  const fieldSubCategory = (
    <div className="t-w-1/2 t-mr-4 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.subCategory.label}
      </p>
      <Dropdown
        className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
        options={filteredPostSubCats}
        optionLabel="name"
        filter
        placeholder="Select"
        id={fieldLabels.subCategory.id}
        onChange={(e) => {
          const selectedPostSubC: PostSubCategory = e.value;
          setSelectedPostSubCat(selectedPostSubC);
          formFormik.setFieldValue("postSubCategoryID", selectedPostSubC.id);
        }}
        disabled={formFormik.isSubmitting}
        value={selectedPostSubCat}
        ref={fieldLabels.subCategory.ref}
      />
    </div>
  );

  const fieldVolumeNumber = (
    <div className="form-field t-w-1/2 t-mr-4">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.volumeNumber.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.volumeNumber.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.volumeNumber ?? ""}
          ref={fieldLabels.volumeNumber.ref}
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.volumeNumber.id}
        errorMsg={formFormik.errors.volumeNumber}
      />
    </div>
  );

  const fieldEditionNumber = (
    <div className="form-field t-w-1/2 t-ml-4">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.editionNumber.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.editionNumber.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.editionNumber ?? ""}
          ref={fieldLabels.editionNumber.ref}
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.editionNumber.id}
        errorMsg={formFormik.errors.editionNumber}
      />
    </div>
  );

  const fieldIssueNumber = (
    <div className="form-field t-w-1/2 t-mr-4">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.issueNumber.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.issueNumber.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.issueNumber ?? ""}
          ref={fieldLabels.issueNumber.ref}
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.issueNumber.id}
        errorMsg={formFormik.errors.issueNumber}
      />
    </div>
  );

  const fieldIssn = (
    <div className="form-field t-w-1/2 t-ml-4">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.issn.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.issn.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.issn ?? ""}
          ref={fieldLabels.issn.ref}
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.issn.id}
        errorMsg={formFormik.errors.issn}
      />
    </div>
  );

  const fieldCoverImage = (
    <div>
      <CoverImageUploader
        title="Cover Image"
        folder={folder}
        id={fieldLabels.coverImage.id}
        postId={formFormik.values.id}
      />
    </div>
  );


  const fieldDocumentFile = (
    <div>
      <FileUploader
        title="Document File"
        folder={folder}
        id={fieldLabels.documentFile.id}
        postId={formFormik.values.id}
      />
    </div>
  );

  const textEditorForm = (
    <div
      className={` 
    ${pageDesignView == EditorType.TEXT_EDITOR ? "t-flex" : "t-hidden"} 
    t-transition t-duration-150 t-ease-out t-h-full 
     t-overflow-auto md:t-max-w-[70rem] 
    t-bg-white t-mx-auto t-flex-col 
    t-rounded-lg t-p-2  t-my-0 md:t-my-5 md:t-p-[2.25rem] md:t-max-h-[86vh]  t-pb-[100px]`}
    >
      {/* {fieldCoverImage} */}

      <div className=" t-mt-0">
        {/*Basic Details From */}
        <SectionHeader title="Basic Details" />
        <div className="t-mb-14">
          {fieldTitle}
          {fieldSlug}
          {fieldShortDescription}
          <div className="t-pt-10">
            <SectionHeader title="Text Editor" />
          </div>
          {fieldDetails}
        </div>
        <div>
          {/* Categories and  Tags*/}
          <SectionHeader title="Category / Tags" />
          <div className="t-mb-10">
            <div className="flex t-w-full">
              {fieldMainCategory}
              {fieldSubCategory}
            </div>
            <div className="flex t-w-full">{fieldTags}</div>
          </div>
        </div>
        <div>
          {/* Authors and  Publications*/}
          <SectionHeader title="Authors and  Publishers" />
          <div className="t-mb-10">
            <div className="flex t-w-full">{fieldAuthors}</div>
            <div className="flex t-w-full">{fieldPublishers}</div>
          </div>
        </div>
        <div>
          {/* Edition and  Volume*/}
          <SectionHeader title="Edition and  Volume" />
          <div className="t-mb-10">
            <div className="flex t-w-full">
              {fieldVolumeNumber}
              {fieldEditionNumber}
            </div>
            <div className="flex t-w-full">
              {fieldIssueNumber}
              {fieldIssn}
            </div>
          </div>
        </div>
        <div className='mb-6'>{fieldDocumentFile}</div>
        <div>
          {/* Dates and  Status*/}
          <SectionHeader title="Dates / Design / Status" />
          <div className="t-mb-10">
            <div className="flex t-w-full">
              {fieldDateUploaded}
              {fieldDatePublished}
            </div>
            <div className="t-flex t-justify-between t-items-stretch">
              <div className="flex t-w-full t-mr-4">{fieldVisibilityType}</div>
              <div className="flex t-w-full t-ml-4">{fieldStatus}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="t-mb-[150px]"></div>
    </div>
  );

  return (
    <div className="t-w-full  t-m-auto t-bg-appmainview">
      <Toast ref={toast} position="top-right"></Toast>
      <FormikProvider value={formFormik}>
        <form
          className="main-form t-h-screen"
          onSubmit={formFormik.handleSubmit}
        >
          <CustomNavigation
            title={`${formAction == FormActionType.NEW
              ? "Add New Article/Publication"
              : formAction == FormActionType.EDIT
                ? "Update Article/Publication"
                : "New Article/Publication"
              }`}
            background={true}
            useWhiteBackButton={true}
            backArrow={true}
            titleFont={"t-font-inter"}
            titleSize={"t-text-24px"}
            titleFontWeight={"t-font-medium"}
            yPadding={"t-py-1"}
            buttons={
              <FormActionButtonSet
                onPressedPreview={() => { }}
                onPressedSave={() => { }}
                onPressedChangeEditorType={changePageDesignView}
                editorType={pageDesignView}
                hasPageBuilders={false}
              />
            }
            backArrowNavUrl={route.articlesPublications.list}
          />
          <div className="flex w-full">{textEditorForm}</div>
          {
            <div
              className={`t-h-screen
         ${pageDesignView == EditorType.PAGE_BUILDER ? "t-block" : "t-hidden"} 
         t-transition t-duration-150 t-ease-out t-sticky`}
            >
              <PageBuilder
                isSelected={pageDesignView == EditorType.PAGE_BUILDER}
              />
            </div>
          }
        </form>
      </FormikProvider>
      <ErrorDialog
        show={showErrorDialog}
        errorMsg={errorMsg}
        onHide={() => {
          setShowErrorDialog(false);
        }}
      />
      <LoadingDialog show={formFormik.isSubmitting} />
      <Toast ref={toast} position="top-right"></Toast>
    </div>
  );
}

export default FormArticlesPublications;
