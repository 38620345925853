
import { Button } from "primereact/button";
import { FileUpload, ItemTemplateOptions } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useRef, useState } from "react";
import { FileIcon, defaultStyles } from 'react-file-icon';

import { Folder } from "../../models";

import './style.css';
import { ProgressBar } from 'primereact/progressbar';

import isDevMode from "../../utils/check-dev-mode";
import { Tag } from "primereact/tag";
import useDataForDocumentFile from "./data-document-file";

interface Props {
  title: string;
  id: string;
  ref?: any;
  folder: Folder | null | undefined;
  postId: string;
}


export const FileUploader = ({ title, id, folder, postId }: Props) => {
  const [totalSize, setTotalSize] = useState(0);
  const toast = useRef<any>(null);
  const fileUploadRef = useRef<any>(null);
  const cropImgRef = useRef<HTMLImageElement>(null);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const file = useDataForDocumentFile((state) => state.file);
  const onUpload = useDataForDocumentFile((state) => state.onUpload);
  const onSelect = useDataForDocumentFile((state) => state.onSelect);
  const onError = useDataForDocumentFile((state) => state.onError);
  const onClear = useDataForDocumentFile((state) => state.onClear);

  const s3Item = useDataForDocumentFile((state) => state.s3Item);
  const findS3Object = useDataForDocumentFile((state) => state.findS3Object);

  function convertBlobToFile(blob: any, fileName: string) {
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
  }

  useEffect(() => {
    if (s3Item?.key && fileToUpload == null) {

      (async () => {
        let blob = await findS3Object(s3Item!.key)
        if (blob != null) {
          var name = s3Item!.key.split('/')[3];
          blob = convertBlobToFile(blob, name);
          if (fileUploadRef && fileUploadRef.current) {
            fileUploadRef.current.setFiles([blob]);
            setTotalSize(blob!.size)
          }
        }
      })();
    }



    return () => {

    }
  }, [file, s3Item])



  const onImageSelect = async (e) => {
    const file = e.files[0];
    setFileToUpload(file);
    onSelect(file);

    let _totalSize = totalSize;
    [...e.files].forEach((file) => {
      // console.log(file);
      // _totalSize += file.size;
      _totalSize = file.size;
    });

    setTotalSize(_totalSize);


  };



  const onImageUpload = async (e) => {

    setIsUploading(true);

    const file = e.files[0];
    let fileSize = 0;
    e.files.forEach((file: File) => {
      // _totalSize += file.size || 0;
      fileSize = file.size;
    });

    setTotalSize(fileSize);

    if (fileSize > 20000000) {
      toast.current.show({
        severity: "error",
        summary: "ATTENTION",
        detail: "File size is larger than 20MB",
      });
      setIsUploading(false);
      return;
    }



    if (folder != null || folder != undefined) {
      await onUpload({ file, folder, fileSize, postId }).then((doneUploading) => {
        console.log('onUpload done');

        toast.current.show({
          severity: "info",
          summary: "Success",
          detail: "File Uploaded",
        });
      }).catch((e) => {
        console.log('onUpload failed', e);

        toast.current.show({
          severity: "error",
          summary: "ATTENTION",
          detail: "File Upload Failed",
        });
      });

      setIsUploading(false);
    }


  };

  const onImageRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onImageClear = () => {
    setTotalSize(0);
    setFileToUpload(null);
    onClear();
  };


  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const formattedValue: string =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";

    return (
      <div
        className={`${className}`}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div className="t-flex t-flex-col t-w-full t-justify-between t-h-full">
          <div className="t-flex t-w-full t-justify-between t-items-center">
            <p className="t-font-bold t-font-playfair t-text-[26px]">{title}
              <span className="t-text-xs t-font-inter t-text-gray-300 t-ml-[10px]">
                {`(${formattedValue.substring(0, (formattedValue.length - 2))} / 20 MB)`}
              </span>
            </p>
            <div>
              {chooseButton}
              {uploadButton}
              {/* {cropButton} */}
              {cancelButton}
            </div>
          </div>
        </div>
        {isUploading && <div className="t-flex t-justify-end t-flex-col t-w-full t-mt-[1.5rem]">
          <div className="image-upload">
            <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
          </div>
        </div>}
      </div>
    );
  };

  // const headerTemplate = (options) => {
  //   const { className, chooseButton, uploadButton, cancelButton } = options;
  //   const value = totalSize / 10000;
  //   const formattedValue =
  //     fileUploadRef && fileUploadRef.current
  //       ? fileUploadRef.current.formatSize(totalSize)
  //       : "0 B";

  //   return (
  //     <div
  //       className={`${className}`}
  //       style={{
  //         backgroundColor: "transparent",
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "space-between",
  //         width: "100%",
  //       }}
  //     >
  //       <p className="t-font-bold t-font-playfair t-text-[26px]">{title}
  //         <span className="t-text-xs t-font-inter t-text-gray-300 t-ml-[10px]">
  //           {`(${formattedValue})`}
  //         </span>
  //       </p>
  //       <div>
  //         {chooseButton}
  //         {uploadButton}
  //         {cancelButton}
  //       </div>

  //     </div>
  //   );
  // };

  const onTemplateRemove = (file, callback) => {
    console.log('onTemplateRemove', file, callback)
    const currentSize = file.size;

    if (currentSize < 0) {
      setTotalSize(0);
    } else {
      setTotalSize(currentSize);
    }

    callback();
  };

  const getFileIcon = (type: string) => {
    let icon: any;
    if (isDevMode()) {
      console.log('type', type)
    }
    switch (type) {
      case 'application/pdf':
        icon = <FileIcon extension="pdf" {...defaultStyles.pdf} />;
        return icon;


      case 'application/msword':
        icon = <FileIcon extension=".docx" {...defaultStyles.docx} />;
        return icon;

      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        icon = <FileIcon extension=".docx" {...defaultStyles.docx} />;
        return icon;

      default:
        break;
    }
  }


  const itemTemplate = (file: any, props: any) => {

    let formatSize = String(`${(file!.size / 1000).toFixed(3)} KB`);



    return (
      <div className="flex align-items-center flex-wrap t-my-2">
        <div className="flex align-items-center"  >
          <div className="t-w-[50px]  t-ml-2">
            {getFileIcon(file?.type)}
          </div>
          {/* <img alt={file?.name} role="presentation" src={itemUrl} width={100} /> */}
          <span className="flex flex-column text-left mx-3 t-min-w-[300px] t-max-w-xl">
            {file?.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={formatSize} severity="warning" className="px-3 py-2" />
        {/* <div>{file?.type}</div> */}
        <Button type="button" icon="pi pi-times" className="p-button-outlined t-mr-[1.65rem] p-button-rounded p-button-danger ml-auto"
          onClick={() => {
            onTemplateRemove(file, props.onRemove)
          }} />
      </div>
    )

  };


  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-file mt-3 p-5"
          style={{
            fontSize: "4em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-3"
        >
          Drag and Drop File Here
        </span>
      </div>
    );
  };





  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };



  return (
    <div>
      <Toast ref={toast}></Toast>
      <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
      <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
      <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

      <div className="card form-file-uploader">
        <FileUpload
          ref={fileUploadRef}
          name="documentFile"
          id={id}
          multiple={false}
          accept=".pdf"
          maxFileSize={20000000}
          customUpload={true}
          uploadHandler={onImageUpload}
          onSelect={onImageSelect}
          onError={onImageClear}
          onClear={onImageClear}
          headerTemplate={headerTemplate}
          itemTemplate={itemTemplate}
          contentClassName=' !t-bg-slate-50/40'
          emptyTemplate={emptyTemplate}
          chooseOptions={chooseOptions}
          uploadOptions={uploadOptions}
          cancelOptions={cancelOptions}
        />
      </div>
    </div>
  );
};


