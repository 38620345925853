import { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import SectionHeader from "../../../../shared-components/header/SectionHeader";
import CustomNavigation from "../../../../shared-components/navigation/CustomNavigation";
import { FormikProvider, useFormik } from "formik";
import useDataMainSubCategoryForm from "../data/data-for-post-categories-form";
import { SchemaForPostCategory, SchemaForPostSubCategory } from "../form-schemas/form-schema-for-main-sub-categories";
import { FormActionType, PostCategory, PostSubCategory } from "../../../../models";
import useRoutePaths from "../../../../routes/route-path";
import { Button } from "primereact/button";
import LabelActions from "../../../../shared-components/button/LabelActions";
import FormActionButtonSet from "../../../../shared-components/button/FormActionButtonSet";
import FormErrorMsg from "../../../../shared-components/form/ErrorMsg";
import { slugify } from "../../../../utils/forms/util-forms";
import useDataPostSubCategoryList from "../data/data-for-sub-post-categories-list";
import { Skeleton } from "primereact/skeleton";
import { usePostCategoryServiceAPI } from "../services/services-for-post-categories";
import { v4 as uuid } from 'uuid';
import LoadingDialog from "../../../../shared-components/form/LoadingDialog";
import useToaster from "../../../../shared-components/toasts/Toaster";
import { Toast } from 'primereact/toast';
import ErrorDialog from "../../../../shared-components/form/ErrorDialog";
import isDevMode from "../../../../utils/check-dev-mode";

function FormMainSubCategory() {

  const { paths: route } = useRoutePaths();

  const { showError, showSuccess } = useToaster();

  const formData = useDataMainSubCategoryForm((state) => state.formData);

  const findPostSubCategories = useDataPostSubCategoryList((state) => state.findItemsForAPost);

  const toast = useRef(null);

  const { addNewPostCategory, updatePostCategory } = usePostCategoryServiceAPI();

  const skels = [1, 2, 3, 4, 5, 6, 7];

  const newPostCategoryId = uuid();


  const formAction = useDataMainSubCategoryForm(
    (state) => state.formActionType
  );


  const formActionSetState = useDataMainSubCategoryForm(
    (state) => state.setFormActionType
  );


  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const [errorMsg, setErrorMsg] = useState('Something went wrong kindly try again.');

  const [postSubCategories, setPostSubCategories] = useState<PostSubCategory[]>([]);

  const [postSubCategoriesLoaded, setPostSubCategoriesLoaded] = useState<boolean>(false);


  const formFormik = useFormik({
    initialValues: formData as PostCategory,
    validateOnBlur: true,

    onSubmit: async (values) => {
      console.log("submit post cat", values);
      saveForm(values);
    },
    validationSchema: SchemaForPostCategory,
  });

  const formFormikSubCategory = useFormik({
    initialValues: {
      name: '',
      id: '',
      slug: '',
      postCategoryID: formData?.id,

    } as PostSubCategory,
    validateOnBlur: false,
    validate(values) {
      if (isDevMode()) {
        console.log("validate formFormikSubCategory", values, formFormikSubCategory.errors);
      }
    },
    onSubmit: (values) => {
      if (isDevMode()) {
        console.log("submit formFormikSubCategory", values);
      }


      const postCategoryIsComplete = formFormik.isValid;

      if (postCategoryIsComplete == false) {
        if (isDevMode()) {
          console.log("postCategoryIsComplete", postCategoryIsComplete, formFormik.errors);
        }

        formFormikSubCategory.setSubmitting(false);
        return;
      }

      if (values.id.length != 0) {
        if (isDevMode()) {
          console.log("submit update subcategory", values);
        }



        const indexToUpdate = postSubCategories.findIndex((subCat) => subCat.id === values.id);
        const updatedList = [...postSubCategories];

        updatedList[indexToUpdate] = formFormikSubCategory.values;
        setPostSubCategories(updatedList);

        formFormikSubCategory.resetForm();
        formFormikSubCategory.setSubmitting(false);

        return;
      }


      // const slugged = slugify(values.name);
      // console.log('slugged subcategory', slugged)

      const newSubCategory: PostSubCategory = new PostSubCategory({
        name: values.name,
        postCategoryID: 'setOnSave',
        slug: values.slug,
        totalPosts: 0,
        deleted: false,
      });

      if (isDevMode()) {

        console.log('submit new SubCategory', newSubCategory);
      }



      setPostSubCategories([...postSubCategories, newSubCategory]);

      formFormikSubCategory.resetForm();
      formFormikSubCategory.setSubmitting(false);
    },
    validationSchema: SchemaForPostSubCategory,
  });

  const saveForm = async (values: PostCategory) => {
    let newPostCat: PostCategory;
    let newSubCatList: PostSubCategory[] = [];

    switch (formAction ?? FormActionType.NEW) {
      case FormActionType.NEW:

        newPostCat = new PostCategory({
          name: values.name,
          slug: values.slug,
          shortDescription: values.shortDescription,
          deleted: false,
        });


        newPostCat = { ...newPostCat, id: newPostCategoryId }
        newSubCatList = [...postSubCategories];


        if (newSubCatList.length > 0) {

          for (var item of newSubCatList) {

            const indexToUpdate = newSubCatList.findIndex((subCat) => subCat === item);

            let newSubCat: PostSubCategory = { ...item, postCategoryID: newPostCategoryId }
            newSubCatList[indexToUpdate] = newSubCat;
          }
        }
        if (isDevMode()) {

          console.log('new Item', newPostCat);
        }


        await addNewPostCategory(newPostCat, newSubCatList).then(async (done) => {
          showSuccess(toast, 'ATTENTION', 'Successfully saved.');
          formActionSetState(FormActionType.EDIT)
          formFormik.setSubmitting(false);


        }).catch((error) => {
          if (isDevMode()) {

            console.log('err submitting addNewPostCategory', error);
          }


          showError(toast, 'ATTENTION', 'Something went wrong, kindly try again.');
          setShowErrorDialog(true);
          formFormik.setSubmitting(false);
        });
        break;

      case FormActionType.EDIT:
        newPostCat = { ...values, deleted: false, }

        await updatePostCategory(newPostCat, postSubCategories).then(async (done) => {
          showSuccess(toast, 'ATTENTION', 'Successfully saved.');
          formFormik.setSubmitting(false);

        }).catch((error) => {
          if (isDevMode()) {
            console.log('err submitting updatePostCategory', error);
          }


          showError(toast, 'ATTENTION', 'Something went wrong, kindly try again.');
          setShowErrorDialog(true);
          formFormik.setSubmitting(false);
        });
        break;

      default:
        break;
    }
  }

  const onChangeSlug = (e: any) => {
    const slugged = slugify(e.target.value);
    formFormik.setFieldValue('slug', slugged);
    return;
  }

  const refMainCategoryName = useRef(null);
  const refSlug = useRef(null);
  const refShortDescription = useRef(null);
  const refNewSubCategory = useRef<any>(null);


  useEffect(() => {

    if (formFormik.values.name.length != 0) {
      const slugged = slugify(formFormik.values.name);
      formFormik.setFieldValue('slug', slugged);
    }

    return () => { }
  }, [formFormik.values.name])

  useEffect(() => {

    if (formFormikSubCategory.values.name.length != 0) {
      const slugged = slugify(formFormikSubCategory.values.name);
      formFormikSubCategory.setFieldValue('slug', slugged);
    }

    return () => { }
  }, [formFormikSubCategory.values.name])


  useEffect(() => {

    if (formFormik.values.id.length != 0) {
      if (postSubCategoriesLoaded) return;

      (async () => {
        let foundSubCatPosts: PostSubCategory[] = await findPostSubCategories(formFormik.values.id);
        if (isDevMode()) {
          console.log(foundSubCatPosts)
        }

        setPostSubCategories(foundSubCatPosts);
        setPostSubCategoriesLoaded(true);
      })();

    } else {
      setPostSubCategoriesLoaded(true);
    }

    return () => {
      if (postSubCategoriesLoaded) return;
    }
  }, [formFormik.values.id])




  const fieldLabels = {
    mainCategoryName: {
      id: "name",
      ref: refMainCategoryName,
      label: "Main Category Name",
    },
    slug: {
      id: "slug",
      ref: refSlug,
      label: "Slug",
    },
    shortDescription: {
      id: "shortDescription",
      ref: refShortDescription,
      label: "Short Description",
    },
    newSubCategory: {
      id: "name",
      ref: refNewSubCategory,
      label: "Sub-Category Name",
    },
    /*  */
  };

  const mainCategoryName = (
    <div className="form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.mainCategoryName.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.mainCategoryName.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.name}
          ref={fieldLabels.mainCategoryName.ref}
        />
      </span>
      <FormErrorMsg fieldId={fieldLabels.mainCategoryName.id} errorMsg={formFormik.errors.name} />
    </div>
  );

  const slug = (
    <div className="form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.slug.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.slug.id}
          onChange={onChangeSlug}
          onBlur={formFormik.handleBlur}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.slug ?? ''}
          ref={fieldLabels.slug.ref}
        />
      </span>
      <FormErrorMsg fieldId={fieldLabels.slug.id} errorMsg={formFormik.errors.slug} />
    </div>
  );

  const shortDescription = (
    <div className="form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.shortDescription.label}
      </p>
      <span className="w-full p-input-filled">
        <InputTextarea
          id={fieldLabels.shortDescription.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.shortDescription}
          ref={fieldLabels.shortDescription.ref}
          rows={2}
          cols={30}
        />
      </span>
      <FormErrorMsg fieldId={fieldLabels.shortDescription.id} errorMsg={formFormik.errors.shortDescription} />
    </div>
  );

  const fieldSubCategory = (
    <div className="form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.newSubCategory.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.newSubCategory.id}
          onChange={formFormikSubCategory.handleChange}
          onBlur={formFormikSubCategory.handleBlur}
          maxLength={100}
          disabled={formFormikSubCategory.isSubmitting}
          value={formFormikSubCategory.values.name}
          ref={fieldLabels.newSubCategory.ref}
        />
      </span>

    </div>
  );

  return (
    <div className="t-w-full  t-m-auto t-bg-appmainview">
      <FormikProvider value={formFormik}>
        <form
          className="main-form t-h-screen"
          onSubmit={formFormik.handleSubmit}
        >
          <CustomNavigation
            title={`${formAction == FormActionType.NEW ? "New Post Category" : formAction == FormActionType.EDIT ? "Update Post Category" : "New Post Category"}`}
            background={true}
            useWhiteBackButton={true}
            backArrow={true}
            maxWidth={'t-max-w-[96rem]'}
            titleFont={"t-font-inter"}
            titleSize={"t-text-24px"}
            titleFontWeight={"t-font-medium"}
            yPadding={"t-py-1"}
            buttons={
              <FormActionButtonSet
                onPressedSave={() => { }}
                showSave={true}
                showPreview={false}
                saveBtnWidth={'t-w-[150px]'}
                hasPageBuilders={false}
              />
            }
            backArrowNavUrl={route.settings.mainSubCategories.list}
          />
          <div
            className={` 
            t-transition t-duration-150 t-ease-out t-h-full 
            t-overflow-auto md:t-max-w-[70rem] 
            t-bg-white t-mx-auto t-flex-col 
            t-rounded-lg t-p-2  t-my-0 md:t-my-5 md:t-p-[2.25rem] !t-pt-[1.0rem] md:t-max-h-[88vh]  t-pb-[100px]`}>

            <div className="t-mb-10">
              <SectionHeader title="Basic Details" />
              {mainCategoryName}
              {slug}
              {shortDescription}
            </div>
            <div>
              {/* Sub Categories*/}
              <SectionHeader title="Sub Categories" />
              <div className="t-mb-10">
                <div className="flex t-w-full t-items-end t-justify-between">
                  <FormikProvider value={formFormikSubCategory}>
                    <form
                      className="t-w-full"
                      onSubmit={formFormikSubCategory.handleSubmit}>
                      <div className="">{fieldSubCategory}</div>
                    </form>

                  </FormikProvider>

                  <div className="t-max-h-[50px]">
                    <Button
                      type="button"
                      onClick={() => {
                        formFormikSubCategory.handleSubmit();
                      }}
                      className="t-bg-appdeepblue t-max-h-[50px] sub-cat-add-btn
                         p-button-rounded p-button-raised t-w-36 t-ml-4"
                      label="SAVE"
                      icon="pi pi-plus-circle"
                    />
                  </div>
                </div>
                <FormErrorMsg fieldId={fieldLabels.newSubCategory.id} errorMsg={formFormikSubCategory.errors.name} />
              </div>
              <div>
                <div className="t-p-4 t-bg-appgrey-100 t-rounded-xl t-w-full">
                  <p className="t-font-semibold t-text-appgrey-900">
                    Sub Category Name
                  </p>
                </div>
              </div>
              {/* Sub Categories */}
              {!postSubCategoriesLoaded && <div className="t-h-[500px] t-pt-4">
                {skels.map((number) => {
                  return <Skeleton key={number.toString()} className="t-w-full t-mt-4 !t-h-[50px]"></Skeleton>
                })}
              </div>}
              {postSubCategoriesLoaded &&
                <div>
                  {postSubCategories.map((item, i) => {
                    return <div key={i.toString()} className="t-my-4 t-w-full">
                      <LabelActions
                        action={
                          <div className="t-flex">
                            {/* <Button
                              type="button"
                              onClick={() => {

                              }}
                              className="post-sub-list-delete-btn p-button-text p-button-rounded t-w-28"
                              label="Delete"
                              icon="pi pi-trash"
                            /> */}
                            <Button
                              type="button"
                              onClick={() => {
                                formFormikSubCategory.setValues(item);
                                refNewSubCategory.current?.focus();
                              }}
                              className="post-sub-list-edit-btn  p-button-text t-my-1 t-mx-1 p-button-rounded t-w-28"
                              label="Edit"
                              icon="pi pi-pencil"
                            />
                          </div>
                        }
                        label={item.name}
                      />
                    </div>
                  })}
                </div>

              }
            </div>
          </div>
        </form>
      </FormikProvider>
      <ErrorDialog show={showErrorDialog} errorMsg={errorMsg} onHide={() => { setShowErrorDialog(false) }} />
      <LoadingDialog show={formFormik.isSubmitting} />
      <Toast ref={toast} position="top-right"></Toast>
    </div>
  );
}

export default FormMainSubCategory;
