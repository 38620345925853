import { Accordion, AccordionTab } from "primereact/accordion";
import { StaffBioTextEditor } from "../../models";
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { editorConfiguration } from "../../editors/ckeditorjs/configure";
import { Button } from "primereact/button";

interface AccordionProps {
  removeFormFields: any;
  onChange(i: number, val: any, isTitle: boolean): void;
  bioData: StaffBioTextEditor[];
}
export default function BioAccordion({
  bioData,
  onChange,

  removeFormFields,
}: AccordionProps) {


  // const [editors, setEditors] = useState([{ id: 1, content: "" }]);
  const editorRef = useRef<any>(null);

  // const addEditor = () => {
  //   const newEditorId = editors[editors.length - 1].id + 1;
  //   setEditors([...editors, { id: newEditorId, content: "" }]);
  // };

  // const handleEditorChange = (id, data) => {
  //   setEditors((prevState) =>
  //     prevState.map((editor) =>
  //       editor.id === id ? { ...editor, content: data } : editor
  //     )
  //   );
  // };


  useEffect(() => {
    editorRef.current = ClassicEditor.create(
      document.querySelector(".editor") as any
    );
  }, []);

  return (
    <div className="card">
      <Accordion multiple activeIndex={[0]}>
        {(bioData.length > 0) && bioData.map((bio, i) => {
          return (
            <AccordionTab
              key={`accordionTab-${i.toString()}`}
              header={`${bio.title}`}
            >
              <div>
                <div className="flex t-items-center form-field t-mr-[40%] t-mb-2">
                  <span className="flex t-items-center p-input-filled">
                    <label className="t-font-bold t-mr-4">Title</label>
                    <InputText
                      placeholder="Enter Header"
                      className="t-w-full"
                      key={`title-${i.toString()}`}
                      name="title"
                      onChange={(e) => onChange(i, e, true)}
                      value={bio.title || ""}
                    />
                  </span>
                </div>
                <div className="t-my-4 bio-text-editor">
                  <CKEditor
                    key={`editor${i.toString()}`}
                    editor={ClassicEditor}
                    data={bio.body}
                    config={{ ...editorConfiguration }}
                    onReady={(editor: any) => {
                      editorRef.current = editor;
                    }}
                    onChange={(event: any, editor: any) => {
                      const data = editor.getData();
                      onChange(i, data, false);
                    }}
                  />
                </div>
                <div className="flex t-justify-end">
                  <Button
                    className="t-mt-1"
                    onClick={removeFormFields}
                    type="button"
                    label="Remove"
                    severity="success"
                    outlined
                  />
                </div>
              </div>
            </AccordionTab>
          );
        })}
      </Accordion>
    </div>
  );
}
