import { Button } from "primereact/button";


interface Props {
    label: string;
    onClick: VoidFunction;
}
const TableAddNewButton = (props: Props) => {
    return (
        <Button label={props.label} className="list-top-nav-add-button t-drop-shadow-md t-min-w-[152px] t-font-inter !t-font-semibold"
            onClick={props.onClick}
            icon="pi pi-plus" />
    );
}

export default TableAddNewButton;