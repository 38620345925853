import { useNavigate } from "react-router-dom";
import { FormActionType } from "../../../models";
import useRoutePaths from "../../../routes/route-path";
import TableAddNewButton from "../../../shared-components/button/TableAddNewButton";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import useDataCmsUsersForm from "../data/data-for-cms-users-form";
import TableCmsUsers from "../tables/table-for-cms-users";
import useDataForTextEditor from "../../../editors/data-for-editor";

function ListCmsUsers() {
  const { paths: route } = useRoutePaths();
  const navigation = useNavigate();
  const setFormActionType = useDataCmsUsersForm(
    (state) => state.setFormActionType
  );
  const editorReset = useDataForTextEditor((state) => state.reset);
  const resetForm = useDataCmsUsersForm((state) => state.reset);

  return (
    <div className="flex t-flex-col t-overflow-hidden t-justify-start t-h-fit t-rounded-lg t-shadow-lg t-m-3">
      <div className="">
        <CustomNavigation
          background={false}
          backArrow={false}
          title="CMS Users"
          buttons={
            <TableAddNewButton
              label="Add New"
              onClick={() => {
                editorReset();
                resetForm();
                setFormActionType(FormActionType.NEW);
                navigation(route.cmsUsers.details);
              }}
            />
          }
        />
      </div>

      <TableCmsUsers />
    </div>
  );
}

export default ListCmsUsers;
