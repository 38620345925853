import produce from "immer";
import { create } from "zustand";
import { EditorType, Page, PageType, RecordStatus } from "../../../../models";
import { PageDataInterfaceCRIGDivisionsSubStationsForm } from "./data-interface-for-divisions-sub-stations";


const defaultData = {
    pageDesignType: EditorType.TEXT_EDITOR,
    pageDesignTextEditor: '',
    pageType: PageType.DIVISION_SUB_STATION,
    pageDesignPageBuilder: '',
    title: '',
    coverImage: null,
    status: RecordStatus.DRAFT,
    datePublished: new Date().toISOString(),
    dateAdded: new Date().toISOString(),
};



const useDataDivisionSubStationsForm = create<PageDataInterfaceCRIGDivisionsSubStationsForm>()(
    (set, get) => {
        return {
            dataItem: undefined,
            setDataItem: async (item) => {
                set((_) => ({ dataItem: item }));
                return true;
            },
            showDialog: false,
            setFormData: async (item) => {
                set((_) => ({ formData: item }));
                return true;
            },
            formActionType: undefined,
            setShowDialog: async (value) => {
                set((_) => ({ showDialog: value }));
                return true;
            },
            formData: defaultData as unknown as Page,
            setFormActionType: async (item) => {
                set((_) => ({ formActionType: item }));
                return true;
            },
            reset: async () => {
                set((_) => ({ formData: defaultData as unknown as Page }));
                return true;
            },
            updateFormDataField: async (property, value) => {
                set(
                    produce((state: PageDataInterfaceCRIGDivisionsSubStationsForm) => {
                        state.formData![property] = value;
                    })
                );
                return true;
            },
        };
    }
);

export default useDataDivisionSubStationsForm;
