import FormActionButtonSet from "../../shared-components/button/FormActionButtonSet";
import CustomNavigation from "../../shared-components/navigation/CustomNavigation";
import useRoutePaths from "../../routes/route-path";
import SectionHeader from "../../shared-components/header/SectionHeader";
import { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import FormErrorMsg from "../../shared-components/form/ErrorMsg";
import { FormikProvider, useFormik } from "formik";
import { UserAccount, UserRole } from "../../models";
import { RecordStatus } from "../../models";
import { SchemaForCMSUsers } from "../page-cms-users/form-schemas/form-schema-for-cms-users";
import useDataCmsUsersForm from "../page-cms-users/data/data-for-cms-users-form";
import { Dropdown } from "primereact/dropdown";
import useDataUserRoleList from "../page-settings/page-user-roles/data/data-for-user-roles-list";
import isDevMode from "../../utils/check-dev-mode";
import {
  formatIsoStringToDate,
  useAccountStatusType,
} from "../../utils/forms/util-forms";
import { Calendar } from "primereact/calendar";
import { useServiceForUserRoleAPI } from "../page-settings/page-user-roles/services/services-for-user-roles";
import { DataLoadingState } from "../../models";
import useToaster from "../../shared-components/toasts/Toaster";
import { useServiceForCMSUsersAPI } from "../page-cms-users/services/services-for-cms-users";
import ErrorDialog from "../../shared-components/form/ErrorDialog";
import LoadingDialog from "../../shared-components/form/LoadingDialog";
import { Toast } from "primereact/toast";

export default function PageStaffProfiles() {
  const { paths: route } = useRoutePaths();
  const userRoleListItems = useDataUserRoleList((state) => state.listItems);
  const listUserRolesFromService = useServiceForUserRoleAPI().listAllData;
  const setStateLoadingStatus = useDataUserRoleList(
    (state) => state.setStatusForLoadingListItems
  );
  const { updateData } = useServiceForCMSUsersAPI();
  const [errorMsg, _setErrorMsg] = useState(
    "Something went wrong kindly try again."
  );

  const toast: any = useRef();
  const [validateForm, setValidateForm] = useState<boolean>(false);
  const { showError, showSuccess } = useToaster();
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  //   const formData = useDataCmsUsersForm((state) => state.formData);
  const userObject = JSON.parse(localStorage.getItem("userObject") ?? "");
  const [selectedUserRole, setSelectedUserRole] = useState<
    UserRole | null | false
  >();

  const loadingStatus = useDataUserRoleList(
    (state) => state.statusForLoadingListItems
  );

  const defaultData = {
    id: userObject?.id,
    contactEmail1: userObject?.email,
    contactPhoneNumber1: userObject?.phoneNumber,
    nameFirst: userObject?.nameFirst,
    nameLast: userObject?.nameLast,
    status: userObject?.status,
    userRoleID: userObject?.userRoleID,
    userRole: userObject?.userRole,
    dateAdded: userObject?.addedOn?.split('T')[0],
    dateLastLogin: userObject?.dateLastLogin?.split('T')[0] ?? new Date().toISOString(),
  };

  /*  async function saveForm(values: UserAccount) {
    let submittedValues = {
      ...values,
      nameFull: values.nameFirst + " " + values.nameLast,
      dateUpdated: new Date().toISOString(),
      deleted: false,
    };

    await updateData(submittedValues, false)
      .then(async (done) => {
        showSuccess(toast, "ATTENTION", "Successfully saved.");
        formFormik.setSubmitting(false);
      })
      .catch((error) => {
        console.log("err submitting updateData", error);
        showError(
          toast,
          "ATTENTION",
          "Something went wrong, kindly try again."
        );
        setShowErrorDialog(true);
        formFormik.setSubmitting(false);
      });
  }
 */
  const formFormik = useFormik({
    initialValues: defaultData,
    // initialValues: formData as UserAccount,
    validateOnBlur: false,
    validateOnChange: validateForm,
    validationSchema: SchemaForCMSUsers,

    validate: (values) => {
      setValidateForm(true);
      console.log("validate", values);
      console.log("errors", formFormik.errors);
    },
    onSubmit: async (values) => {
      console.log("submit", values);
    },
  });

  const refNameFirst = useRef(null);
  const refNameLast = useRef(null);
  const refContactEmail1 = useRef(null);
  const refContactPhoneNumber1 = useRef(null);
  const refAddedOn = useRef(null);
  const refLastLogin = useRef(null);
  const refStatus = useRef(null);
  const refUserRole = useRef(null);

  const fieldLabels = {
    nameFirst: {
      id: "nameFirst",
      ref: refNameFirst,
      label: "First Name",
    },
    nameLast: {
      id: "nameLast",
      ref: refNameLast,
      label: "Last Name",
    },
    email: {
      id: "contactEmail1",
      ref: refContactEmail1,
      label: "Email",
    },
    contactPhoneNumber1: {
      id: "contactPhoneNumber1",
      ref: refContactPhoneNumber1,
      label: "Phone Number",
    },
    addedOn: {
      id: "addedOn",
      ref: refAddedOn,
      label: "Added On",
    },
    lastLoggedInOn: {
      id: "lastLoggedInOn",
      ref: refLastLogin,
      label: "Last LoggedIn",
    },
    role: {
      id: "userRoleID",
      ref: refUserRole,
      label: "Role",
    },
    status: {
      id: "status",
      ref: refStatus,
      label: "Status",
    },
  };

  const firstName = (
    <div className="t-w-1/2 form-field">
      <p className="t-text-appgrey-800 t-mt-3 t-mb-4 login-input-label t-font-semibold">
        {fieldLabels.nameFirst.label}
      </p>
      <span className="t-mt-6"> {defaultData?.nameFirst}</span>
      {/*      <span className=" w-full p-input-filled">
        <InputText
          id={fieldLabels.nameFirst.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled
          value={formFormik.values.nameFirst!}
          ref={fieldLabels.nameFirst.ref}
        />
      </span> */}
    </div>
  );

  const lastName = (
    <div className="t-w-1/2 form-field">
      <p className="t-text-appgrey-800 t-mt-3 t-mb-4 login-input-label t-font-semibold">
        {fieldLabels.nameLast.label}
      </p>
      <span className="t-mt-6"> {defaultData?.nameLast}</span>

      {/*    <span className=" w-full p-input-filled">
        <InputText
          id={fieldLabels.nameLast.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled
          value={formFormik.values.nameLast}
          ref={fieldLabels.nameLast.ref}
        />
      </span> */}
    </div>
  );

  const email = (
    <div className="t-w-1/2 form-field">
      <p className="t-text-appgrey-800 t-mt-3 t-mb-4 login-input-label t-font-semibold">
        {fieldLabels.email.label}
      </p>
      <span className="t-mt-6"> {defaultData?.contactEmail1}</span>

      {/*     <span className=" w-full p-input-filled">
        <InputText
          id={fieldLabels.email.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled
          value={formFormik.values.contactEmail1}
          ref={fieldLabels.email.ref}
        />
      </span> */}
    </div>
  );

  const addedOn = (
    <div className="t-w-1/2 t-mr-4 form-field date-pick">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-4 login-input-label t-font-semibold">
        {fieldLabels.addedOn.label}
      </p>
      <span className="t-mt-6"> {defaultData?.dateAdded}</span>

      {/*       <Calendar
        id={fieldLabels.addedOn.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        disabled
        dateFormat="dd/mm/yy"
        value={formatIsoStringToDate(formFormik.values.dateAdded!)}
        ref={fieldLabels.addedOn.ref}
        className="w-full p-input-filled"
        showIcon
        iconPos="right"
      /> */}
    </div>
  );

  const phoneNumber = (
    <div className="t-w-1/2 form-field">
      <p className="t-text-appgrey-800 t-mt-3 t-mb-4 login-input-label t-font-semibold">
        {fieldLabels.contactPhoneNumber1.label}
      </p>
      <span className="t-mt-6"> {defaultData?.contactPhoneNumber1}</span>

      {/*       <span className=" w-full p-input-filled">
        <InputText
          id={fieldLabels.contactPhoneNumber1.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled
          value={formFormik.values.contactPhoneNumber1}
          ref={fieldLabels.contactPhoneNumber1.ref}
        />
      </span> */}
    </div>
  );

  const lastLoggedInOn = (
    <div className="t-w-1/2 t-mr-4 form-field date-pick">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-4 login-input-label t-font-semibold">
        {fieldLabels.lastLoggedInOn.label}
      </p>
      <span className="t-mt-6"> {defaultData?.dateLastLogin}</span>
      {/* 
      <Calendar
        id={fieldLabels.lastLoggedInOn.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        disabled
        dateFormat="dd/mm/yy"
        value={formatIsoStringToDate(formFormik.values.dateLastLogin!)}
        ref={fieldLabels.lastLoggedInOn.ref}
        className="w-full p-input-filled"
        showIcon
        iconPos="right"
      /> */}
    </div>
  );

  const role = (
    <div className="t-w-1/2 form-field">
      <p className="t-text-appgrey-800 t-mt-3 t-mb-4 login-input-label t-font-semibold">
        {fieldLabels.role.label}
      </p>
      <span className="t-mt-6"> {defaultData?.userRole}</span>
      {/* 
      <span className=" t-w-full">
        <Dropdown
          className="t-w-full t-mb-4 md:t-mb-0 p-input-filled"
          options={userRoleListItems}
          optionLabel="title"
          placeholder="Select"
          id={fieldLabels.role.id}
          onChange={(e) => {
            const selectedRole: UserRole = e.value;
            setSelectedUserRole(selectedRole);
            formFormik.setFieldValue("userRoleID", selectedRole.id);
          }}
          disabled
          value={selectedUserRole}
          ref={fieldLabels.role.ref}
        />
      </span> */}
    </div>
  );

  const status = (
    <div className="t-w-1/2 form-field">
      <p className="t-text-appgrey-800 t-mt-3 t-mb-4 login-input-label t-font-semibold">
        {fieldLabels.status.label}
      </p>
      <span className="t-mt-6"> {defaultData?.status}</span>
      {/* 
      <span className=" t-w-full">
        <Dropdown
          className="t-w-full t-mb-4 md:t-mb-0 p-input-filled"
          options={useAccountStatusType}
          optionLabel="name"
          placeholder="Select"
          id={fieldLabels.status.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled
          value={formFormik.values.status}
          ref={fieldLabels.status.ref}
        />
      </span> */}
    </div>
  );

  useEffect(() => {
    if (selectedUserRole == false) {
      setSelectedUserRole(null);
    }

    return () => {};
  }, [selectedUserRole]);

  useEffect(() => {
    if (
      selectedUserRole == null &&
      userRoleListItems.length > 0 &&
      (formFormik.values.userRoleID ?? "").length > 0
    ) {
      const foundItem = userRoleListItems.find(
        ({ id }) => id === defaultData.userRoleID
      );

      if (isDevMode()) {
        console.log("foundItem", foundItem);
      }

      if ((foundItem?.id ?? "").length > 0) {
        setSelectedUserRole(foundItem!);
      }
    }

    return () => {};
  }, [formFormik.values.userRoleID, userRoleListItems, selectedUserRole]);

  useEffect(() => {
    const fetchListData = async () => {
      await listUserRolesFromService();
    };

    if (
      userRoleListItems.length == 0 &&
      loadingStatus == DataLoadingState.PENDING
    ) {
      setStateLoadingStatus(DataLoadingState.LOADING);
      fetchListData();
    }
  }, [loadingStatus, userRoleListItems]);

  return (
    <div className="">
      <FormikProvider value={formFormik}>
        <form className="main-form" onSubmit={formFormik.handleSubmit}>
          <CustomNavigation
            title="Profile"
            background={true}
            useWhiteBackButton={true}
            backArrow={true}
            maxWidth={"t-max-w-[96rem]"}
            titleFont={"t-font-inter"}
            titleSize={"t-text-24px"}
            titleFontWeight={"t-font-medium"}
            yPadding={"t-py-1"}
        
            backArrowNavUrl={route.crigStaff.list}
          />
          <div
            className={`t-transition t-duration-150 t-ease-out t-h-full  t-overflow-auto md:t-max-w-[70rem] 
                            t-bg-white t-mx-auto t-flex-col t-rounded-lg t-p-2 t-my-0 md:t-my-5 md:t-p-[2.25rem]
                            md:t-max-h-[88vh] t-pb-[100px]`}
          >
            <div className="t-w-full t-mb-52">
              <div className="t-pt-4">
                <SectionHeader title="Basic Details" />
                <div className={"mb-6"}>
                  <div className="flex t-w-full gap-4 mb-4">
                    {firstName}
                    {lastName}
                  </div>
                  <div className="flex t-w-full gap-4">
                    {email}
                    {phoneNumber}
                  </div>
                </div>
                <SectionHeader title="Logon Details" />
                <div className={"mb-6"}>
                  <div className="flex t-w-full gap-4">
                    {addedOn}
                    {lastLoggedInOn}
                  </div>
                  <div className="flex t-w-full t-mt-6 gap-4">
                    {role}
                    {status}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormikProvider>
      <ErrorDialog
        show={showErrorDialog}
        errorMsg={errorMsg}
        onHide={() => {
          setShowErrorDialog(false);
        }}
      />
      <LoadingDialog show={formFormik.isSubmitting} />
      <Toast ref={toast} position="top-right"></Toast>
    </div>
  );
}
