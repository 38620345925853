import { Hub } from "aws-amplify";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import PrimeReact from "primereact/api";
import "primereact/resources/primereact.min.css";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { DataLoadingState } from "./models";
import ArticlesPublicationsDetails from "./pages/page-articles-publications/form/form-for-articles-publications";
import ArticlesPublicationList from "./pages/page-articles-publications/list/list-for-articles-publications";
import ConfirmSignIn from "./pages/page-authentication/page-confirm-sign-in";
import SignIn from "./pages/page-authentication/page-sign-in";
import FormContentFollowers from "./pages/page-content-followers/form/form-for-content-followers";
import ListContentFollowers from "./pages/page-content-followers/list/list-for-content-followers";
import FormEventsGallery from "./pages/page-events-gallery/form/form-for-events-gallery";
import FormMandatedCrops from "./pages/page-mandated-crops/form/form-for-mandated-crops";
import ListEventGallery from "./pages/page-events-gallery/list/list-for-event-gallery";
import ListAboutUs from "./pages/page-about-us/list/list-for-about-us";
import ListMandatedCrops from "./pages/page-mandated-crops/list/list-for-mandated-crops";
import FormAboutUs from "./pages/page-about-us/form/form-for-about-us";
import FormExternalService from "./pages/page-external-services/form/form-for-external-services";
import ListExternalService from "./pages/page-external-services/list/list-for-external-services";
import MainLayout from "./pages/page-main/components/layouts/MainLayout";
import EditorSwitch from "./pages/page-main/editor/editor-switch";
import EditorSwitcherLayoutSelection from "./pages/page-main/editor/editor-switcher-layout-selection";
import Dashboard from "./pages/page-main/page-dashboard/PageDashboard";
import FormNewsAnnouncement from "./pages/page-news-announcements/form/announcements";
import FormNewsLetter from "./pages/page-newsletters/form/form-for-newsletters";
import ListNotifications from "./pages/page-notifications/list/list-for-notifications";
import ListCmsUsers from "./pages/page-cms-users/list/list-for-cms-users";
import FormCmsUsers from "./pages/page-cms-users/form/form-for-cms-users";
import FormProducts from "./pages/page-products/form/form-for-products";
import FormStaffProfile from "./pages/page-staff-profiles/form/form-for-staff-profiles";
import ListStaffProfile from "./pages/page-staff-profiles/list/list-for-staff-profiles";
import ListProduct from "./pages/page-products/list/list-for-products";
import ListFileManager from "./pages/page-files-manager/list/list-for-file-manager";
import ListFileManagerFolder from "./pages/page-files-manager/list/list-for-file-manager-folder";
import ListFileManagerFiles from "./pages/page-files-manager/list/list-for-file-manager-files";
// import FormFileManager from "./pages/page-files-manager/form/form-for-file-manager";
import FormRecreationFacilities from "./pages/page-recreation-facilities/form/form-for-recreation-facilities";
import FormResearchActivities from "./pages/page-research-activities/form/form-for-research-activities";
import ListResearchActivities from "./pages/page-research-activities/list/list-for-research-activities";
import SettingsPage from "./pages/page-settings/index/PageSettings";
import FormDivisionSubStations from "./pages/page-settings/page-crig-divisions-sub-stations/form/form-for-divisions-sub-stations";
import FormMainCategory from "./pages/page-settings/page-main-sub-categories/form/form-for-main-sub-categories";
import ListMainSubCategories from "./pages/page-settings/page-main-sub-categories/list/list-for-main-sub-categories";
import FormStaffGroupings from "./pages/page-settings/page-staff-groupings/form/form-for-staff-groupings";
import ListStaffGroupings from "./pages/page-settings/page-staff-groupings/list/list-for-staff-groupings";
import FormUnitDepartment from "./pages/page-settings/page-staff-unit-departments/form/form-for-staff-unit-departments";
import ListStaffUnitDepartment from "./pages/page-settings/page-staff-unit-departments/list/list-for-staff-unit-departments";
import AuthSplash from "./pages/page-splash";
import useRoutePaths from "./routes/route-path";
import { useServiceAuthentication } from "./shared-services/use-service-authentication";
import ListHomeSlides from "./pages/page-home-slides/list/list-for-home-slide";
import ListDivisionsSubStations from "./pages/page-settings/page-crig-divisions-sub-stations/list/list-for-divisions-sub-stations";
import ListRecreationFacilities from "./pages/page-recreation-facilities/list/list-for-recreation-facilities";
import ListNewsAnnouncement from "./pages/page-news-announcements/list/list-for-news-announcments";
import FormUserRoles from "./pages/page-settings/page-user-roles/form/form-for-user-roles";
import ListUserRoles from "./pages/page-settings/page-user-roles/list/list-for-user-roles";
import PageStaffProfiles from "./pages/page-staff-profiles";
import PageAboutUs from "./pages/page-about-us";
import { ConfigCloud } from "./shared-services/cloud-config";
// import useDataForUserAuth from "./store/auth/data-for-auth";
// import useDataForDStoreEvents from "./store/db/data-db-status";

ConfigCloud();

PrimeReact.ripple = true;

function App() {
  const [loadingCredentials, setLoadingCredentials] = useState(
    DataLoadingState.PENDING
  );

  const { getUser } = useServiceAuthentication();

  const { paths: route } = useRoutePaths();

  // const { setHasNetwork,
  //   setReady, setSubscriptionsEstablished,
  //   setSyncQueriesOngoing, setSyncQueriesReady,
  // } = useDataForDStoreEvents();

  const authListener = () => {
    Hub.listen("auth", async (hubData) => {
      const { event } = hubData.payload;
      console.log("event", event);
      switch (event) {
        case "configured":
          console.log("the Auth module is configured");
          await getUser(false, null);
          break;
        case "signIn":
          console.log("user signed in");
          break;
        case "signIn_failure":
          console.error("user sign in failed");
          break;
        case "signUp":
          console.log("user signed up");
          break;
        case "signUp_failure":
          console.error("user sign up failed");
          break;
        case "confirmSignUp":
          console.log("user confirmation successful");
          break;
        case "completeNewPassword_failure":
          console.error("user did not complete new password flow");
          break;
        case "autoSignIn":
          console.log("auto sign in successful");
          break;
        case "autoSignIn_failure":
          console.error("auto sign in failed");
          break;
        case "forgotPassword":
          console.log("password recovery initiated");
          break;
        case "forgotPassword_failure":
          console.error("password recovery failed");
          break;
        case "forgotPasswordSubmit":
          console.log("password confirmation successful");
          break;
        case "forgotPasswordSubmit_failure":
          console.error("password confirmation failed");
          break;
        case "tokenRefresh":
          console.log("token refresh succeeded");
          break;
        case "tokenRefresh_failure":
          console.error("token refresh failed");
          break;
        case "cognitoHostedUI":
          console.log("Cognito Hosted UI sign in successful");
          break;
        case "cognitoHostedUI_failure":
          console.error("Cognito Hosted UI sign in failed");
          break;
        case "customOAuthState":
          console.log("custom state returned from CognitoHosted UI");
          break;
        case "customState_failure":
          console.error("custom state failure");
          break;
        case "parsingCallbackUrl":
          console.log("Cognito Hosted UI OAuth url parsing initiated");
          break;
        case "userDeleted":
          console.log("user deletion successful");
          break;
        case "signOut":
          console.log("user signed out");
          break;
      }
    });
  };

  useEffect(() => {
    // datastoreListener();

    (async () => {
      console.log("loadingCredentials", loadingCredentials);

      if (loadingCredentials == DataLoadingState.LOADING) return;
      if (loadingCredentials == DataLoadingState.LOADED) return;

      if (loadingCredentials == DataLoadingState.PENDING) {
        setLoadingCredentials(DataLoadingState.LOADING);
      }

      await getUser(false, null).then((_) => {
        authListener();
        setLoadingCredentials(DataLoadingState.LOADED);
      });
    })();

    return () => {};
  }, [loadingCredentials]);

  return (
    <MainLayout>
      <Routes>
        <Route path={route.splash} element={<AuthSplash />} />
        <Route path={route.authSignIn} element={<SignIn />} />
        <Route path={route.auth2fa} element={<ConfirmSignIn />} />
        <Route path={route.dashboard} element={<Dashboard />} />
        <Route path={route.profile.initial} element={<PageStaffProfiles />} />
        <Route path={route.editorSwitch} element={<EditorSwitch />} />
        <Route path={route.homeSlider.list} element={<ListHomeSlides />} />
        <Route
          path={route.articlesPublications.details}
          element={<ArticlesPublicationsDetails />}
        />
        <Route
          path={route.articlesPublications.list}
          element={<ArticlesPublicationList />}
        />
        <Route
          path={route.editorSwitchLayoutSelection}
          element={<EditorSwitcherLayoutSelection />}
        />
        <Route
          path={route.contentFollowers.details}
          element={<FormContentFollowers />}
        />
        <Route path={route.settings.initial} element={<SettingsPage />} />
        <Route
          path={route.settings.mainSubCategories.details}
          element={<FormMainCategory />}
        />
        <Route
          path={route.settings.mainSubCategories.list}
          element={<ListMainSubCategories />}
        />
        <Route
          path={route.settings.crigStaffGroups.details}
          element={<FormStaffGroupings />}
        />
        <Route
          path={route.settings.crigStaffGroups.list}
          element={<ListStaffGroupings />}
        />
        {/* <Route
          path={route.settings.pageTemplates.details}
          element={<FormForPageDesignTemplates />}
        /> */}
        <Route
          path={route.settings.crigStaffUnitDepartments.list}
          element={<ListStaffUnitDepartment />}
        />
        <Route
          path={route.settings.crigStaffUnitDepartments.details}
          element={<FormUnitDepartment />}
        />
        <Route
          path={route.settings.crigDivisionsSubStations.details}
          element={<FormDivisionSubStations />}
        />
        <Route
          path={route.settings.crigDivisionsSubStations.list}
          element={<ListDivisionsSubStations />}
        />
        <Route path={route.products.list} element={<ListProduct />} />
        <Route path={route.products.details} element={<FormProducts />} />
        <Route
          path={route.externalServices.details}
          element={<FormExternalService />}
        />
        <Route
          path={route.externalServices.list}
          element={<ListExternalService />}
        />
        <Route path={route.fileManager.list} element={<ListFileManager />} />
        <Route
          path={route.fileManager.listFiles}
          element={<ListFileManagerFiles />}
        />
        <Route
          path={route.fileManager.listFolder}
          element={<ListFileManagerFolder />}
        />
        {/* <Route path={route.fileManager.details} element={<FormFileManager />} /> */}
        {/* <Route path={route.newsletters.details} element={<FormNewsLetter />} /> */}
        <Route
          path={route.recreationalFacilities.details}
          element={<FormRecreationFacilities />}
        />
        <Route
          path={route.recreationalFacilities.list}
          element={<ListRecreationFacilities />}
        />
        <Route
          path={route.mandatedCrops.list}
          element={<ListMandatedCrops />}
        />
        <Route
          path={route.eventsGallery.details}
          element={<FormEventsGallery />}
        />
        <Route
          path={route.mandatedCrops.details}
          element={<FormMandatedCrops />}
        />
        <Route path={route.eventsGallery.list} element={<ListEventGallery />} />
        <Route path={route.aboutUs.list} element={<PageAboutUs />} />
        <Route path={route.aboutUs.details} element={<FormAboutUs />} />
        <Route path={route.cmsUsers.list} element={<ListCmsUsers />} />
        <Route path={route.crigStaff.details} element={<FormStaffProfile />} />
        <Route
          path={route.contentFollowers.list}
          element={<ListContentFollowers />}
        />
        <Route path={route.crigStaff.list} element={<ListStaffProfile />} />
        <Route path={route.cmsUsers.details} element={<FormCmsUsers />} />
        <Route
          path={route.newsAnnouncements.list}
          element={<ListNewsAnnouncement />}
        />
        <Route path={route.newsletters.list} element={<ListEventGallery />} />
        <Route
          path={route.newsletters.details}
          element={<FormEventsGallery />}
        />
        <Route
          path={route.contentFollowers.details}
          element={<FormContentFollowers />}
        />
        <Route
          path={route.researchActivities.list}
          element={<ListResearchActivities />}
        />
        <Route
          path={route.researchActivities.details}
          element={<FormResearchActivities />}
        />
        <Route path={route.notifications} element={<ListNotifications />} />
        <Route
          path={route.newsAnnouncements.details}
          element={<FormNewsAnnouncement />}
        />
        <Route
          path={route.settings.userRoles.details}
          element={<FormUserRoles />}
        />
        <Route
          path={route.settings.userRoles.list}
          element={<ListUserRoles />}
        />
      </Routes>
    </MainLayout>
  );
}

export default App;
