import { useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import useRoutePaths from "../../../routes/route-path";
import SectionHeader from "../../../shared-components/header/SectionHeader";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import { FormikProvider, useFormik } from "formik";
import useDataContentFollowersForm from "./../data/data-for-content-followers-form";
import { UserAccount } from "../../../models";
import { SchemaForContentFollowers } from "../form-schemas/form-schema-for-external-services";
import CustomChip from "../../../shared-components/labels/CustomChip";
import { Image } from "primereact/image";
import UserAvatar from "../../../../assets/images/user_avatar.png";
import {
  formatIsoStringToDate,
  selectPublishStatusType,
} from "../../../utils/forms/util-forms";
import FormActionButtonSet from "../../../shared-components/button/FormActionButtonSet";
import { Toast } from "primereact/toast";
import ErrorDialog from "../../../shared-components/form/ErrorDialog";
import LoadingDialog from "../../../shared-components/form/LoadingDialog";
import FormErrorMsg from "../../../shared-components/form/ErrorMsg";
import { contentUsersFollow } from "../../../utils/constants";


function FormContentFollowers() {
  const { paths: route } = useRoutePaths();
  const [errorMsg, setErrorMsg] = useState(
    "Something went wrong kindly try again."
  );
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const formData = useDataContentFollowersForm((state) => state.formData);
  const formFormik = useFormik({
    initialValues: formData as UserAccount,
    validateOnBlur: false,
    onSubmit: (values) => {
      console.log("submit", values);
    },
    validationSchema: SchemaForContentFollowers,
  });

  const toast = useRef(null);
  const refNameFirst = useRef(null);
  const refNameLast = useRef(null);
  const refStatus = useRef(null);
  const refDateAdded = useRef(null);
  const refContactPhoneNumber1 = useRef(null);
  const refContactEmail1 = useRef(null);
  const refCountryName = useRef(null);
  const refContentFollowing = useRef(null);
  const refDateLastLogin = useRef(null);
  const refPostViewsDownloads = useRef(null);

  const fieldLabels = {
    firstName: {
      id: "nameFirst",
      ref: refNameFirst,
      label: "First Name",
    },
    lastName: {
      id: "nameLast",
      ref: refNameLast,
      label: "Last Name",
    },
    email: {
      id: "contactEmail1",
      ref: refContactEmail1,
      label: "Email",
    },
    phoneNumber: {
      id: "contactPhoneNumber1",
      ref: refContactPhoneNumber1,
      label: "Phone Number",
    },
    countryName: {
      id: "countryName",
      ref: refCountryName,
      label: "Country",
    },
    joinedOn: {
      id: "dateAdded",
      ref: refDateAdded,
      label: "Joined On",
    },
    lastLogin: {
      id: "dateLastLogin",
      ref: refDateLastLogin,
      label: "Last Login",
    },
    status: {
      id: "status",
      ref: refStatus,
      label: "Status",
    },
    totalViews: {
      id: "postViewsDownloads",
      ref: refPostViewsDownloads,
      label: "Total Views",
    },
    totalDownloads: {
      id: "postViewsDownloads",
      ref: refPostViewsDownloads,
      label: "Total Downloads",
    },
  };

  const firstName = (
    <div className="t-w-1/2 t-mr-4">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.firstName.label}
      </p>
      <span className="p-input-icon-left w-full p-input-filled">
        <i className="pi pi-user pi-user" />
        <InputText
          disabled
          id={fieldLabels.firstName.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          value={formFormik.values.nameFirst ?? ""}
          ref={fieldLabels.firstName.ref}
        />
      </span>
    </div>
  );

  const empty = <div className="t-w-full t-ml-8"></div>;
  const lastName = (
    <div className="t-w-1/2 t-ml-4">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.lastName.label}
      </p>
      <span className="p-input-icon-left w-full p-input-filled">
        <i className="pi pi-user pi-user" />
        <InputText
          id={fieldLabels.lastName.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled
          value={formFormik.values.nameLast ?? ""}
          ref={fieldLabels.lastName.ref}
        />
      </span>
    </div>
  );
  const email = (
    <div className="t-w-1/2 t-mr-4">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.email.label}
      </p>
      <span className="p-input-icon-left w-full p-input-filled">
        <i className="pi pi-envelope pi-envelope" />
        <InputText
          id={fieldLabels.email.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          disabled
          value={formFormik.values.contactEmail1 ?? ""}
          ref={fieldLabels.email.ref}
          className="w-full p-input-filled"
        />
      </span>
    </div>
  );
  const phoneNumber = (
    <div className="t-w-1/2 t-ml-4">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.phoneNumber.label}
      </p>
      <span className="p-input-icon-left t-w-full p-input-filled">
        <i className="pi pi-phone pi-phone" />
        <InputText
          id={fieldLabels.phoneNumber.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          disabled
          value={formFormik.values.contactPhoneNumber1 ?? ""}
          ref={fieldLabels.phoneNumber.ref}
          className="w-full p-input-filled"
        />
      </span>
    </div>
  );

  const country = (
    <div className="t-w-full">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.countryName.label}
      </p>
      <span className="p-input-icon-left w-full p-input-filled">
        <i className="pi pi-globe pi-globe" />
        <InputText
          id={fieldLabels.countryName.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          disabled
          value={formFormik.values.countryName ?? ""}
          ref={fieldLabels.countryName.ref}
          className="w-full p-input-filled"
        />
      </span>
    </div>
  );

  const lastLogin = (
    <div className="t-w-1/2 t-ml-4 form-field date-pick">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.lastLogin.label}
      </p>
      <Calendar
        id={fieldLabels.lastLogin.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        disabled
        dateFormat="dd/mm/yy"
        value={formatIsoStringToDate(formFormik.values.dateLastLogin!)}
        ref={fieldLabels.lastLogin.ref}
        className="w-full p-input-filled"
        showIcon
        iconPos="right"
      />
      <FormErrorMsg
        fieldId={fieldLabels.lastLogin.id}
        errorMsg={formFormik.errors.dateLastLogin}
      />
    </div>
  );
  const joinedOn = (
    <div className="t-w-1/2 form-field t-mr-4 date-pick">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.lastLogin.label}
      </p>
      <Calendar
        id={fieldLabels.joinedOn.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        disabled
        dateFormat="dd/mm/yy"
        value={formatIsoStringToDate(formFormik.values.dateAdded!)}
        ref={fieldLabels.joinedOn.ref}
        className="w-full p-input-filled"
        showIcon
        iconPos="right"
      />
      <FormErrorMsg
        fieldId={fieldLabels.joinedOn.id}
        errorMsg={formFormik.errors.dateAdded}
      />
    </div>
  );

  const fieldStatus = (
    <div className="t-w-full form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.status.label}
      </p>
      <span className="p-input-icon-left t-w-full p-input-filled">
        <Dropdown
          className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
          options={selectPublishStatusType}
          optionLabel="name"
          placeholder="Select"
          id={fieldLabels.status.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.status}
          ref={fieldLabels.status.ref}
        />
      </span>
    </div>
  );

  const totalDownloads = (
    <div className="t-w-full">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.totalDownloads.label}
      </p>
      <span className="p-input-icon-left w-full p-input-filled">
        <i className="pi pi-cloud-download pi-cloud-download" />
        <InputText
          id={fieldLabels.totalDownloads.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          disabled
          value={"0"}
          ref={fieldLabels.totalDownloads.ref}
          className="w-full p-input-filled"
        />
      </span>
    </div>
  );

  return (
    <div className="t-w-full  t-m-auto t-bg-appmainview">
      <Toast ref={toast} position="top-right"></Toast>

      <FormikProvider value={formFormik}>
        <form
          className="main-form t-h-screen"
          onSubmit={formFormik.handleSubmit}
        >
          <CustomNavigation
            title="Content Followers Details"
            background={true}
            useWhiteBackButton={true}
            backArrow={true}
            titleFont={"t-font-inter"}
            titleSize={"t-text-24px"}
            titleFontWeight={"t-font-medium"}
            yPadding={"t-py-1"}
            buttons={
              <FormActionButtonSet
                onPressedPreview={() => { }}
                onPressedSave={() => { }}
                hasPageBuilders={false}
              />
            }
            backArrowNavUrl={route.contentFollowers.list}
          />
          <div
            className={`t-transition t-duration-150 t-ease-out t-h-full  
            t-overflow-auto md:t-max-w-[70rem] t-bg-white t-mx-auto 
            t-flex-col  t-rounded-lg t-p-2  t-my-0 md:t-my-5 md:t-p-[2.25rem]
             md:t-max-h-[86vh]  t-pb-[100px]`}
          >
            <div className="t-w-full t-mb-52">
              <div>
                {/*Basic Details From */}
                <SectionHeader title="Basic Details" />
                <Image
                  imageClassName="t-rounded-full flex t-m-auto t-shadow-2xl t-my-10 t-p-2 t-bg-appgrey-200"
                  src={
                    formFormik.values?.profileImage?.signedURL ||
                    "../../../../assets/images/user_avatar.png"
                  }
                  alt="Avatar"
                  imageStyle={{ width: 160, height: 160 }}
                />

                <div className="t-mb-10">
                  <div className="flex t-w-full">
                    {firstName}
                    {lastName}
                  </div>
                  <div className="flex t-w-full">
                    {email}
                    {phoneNumber}
                  </div>
                  <div className="flex t-w-full">
                    {country}
                    {empty}
                  </div>
                </div>
                <div>
                  {/* Logon Details*/}
                  <SectionHeader title="Logon Details" />
                  <div className="t-mb-10">
                    <div className="flex t-w-full">
                      {joinedOn}
                      {lastLogin}
                    </div>
                    <div className="flex t-w-full">
                      {fieldStatus}
                      {empty}
                    </div>
                  </div>
                </div>
                <div>
                  {/*Followings*/}
                  <SectionHeader title="Followings" />
                  <div className="t-mb-10">
                    <div className="flex t-w-full">
                      {/* {totalViews} */}
                      {totalDownloads}
                      {empty}
                    </div>
                  </div>
                </div>
                <p className="t-text-appgrey-700">Content Following</p>
                <div className="flex t-flex-wrap t-justify-between">
                  {contentUsersFollow.map((following) => (
                    <CustomChip label={following} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormikProvider>
      <ErrorDialog
        show={showErrorDialog}
        errorMsg={errorMsg}
        onHide={() => {
          setShowErrorDialog(false);
        }}
      />
      <LoadingDialog show={formFormik.isSubmitting} />
      <Toast ref={toast} position="top-right"></Toast>
    </div>
  );
}

export default FormContentFollowers;
