import { Dialog } from "primereact/dialog";
import Loader from "../loader/loader";



interface Props {
    show: boolean;
}
const LoadingDialog = ({ show }: Props) => {
    return (
        <Dialog closable={false} showHeader={false} resizable={false} className='loader surface-ground t-min-h-[200px]  
        t-h-full t-max-h-[250px] t-w-[90vw] md:t-max-w-[20vw] t-rounded-xl t-overflow-hidden
         t-flex t-flex-col t-justify-center t-items-center' visible={show} onHide={() => { }}>
            <div className="t-flex t-flex-col t-w-full t-justify-center t-pt-[30px] t-items-center">
                <div className="t-absolute t-pt-[10px]">
                    <Loader />
                </div>
            </div>
            <div className="t-mt-[50px] t-px-5 ">
                <p className="t-font-inter t-px-5 t-font-medium t-text-center"> Submitting...please wait</p>
            </div>
        </Dialog>
    )
}

export default LoadingDialog



