import { FormikProvider, useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { CrigStaffGroup, FormActionType } from "../../../../models";
import useRoutePaths from "../../../../routes/route-path";
import ErrorDialog from "../../../../shared-components/form/ErrorDialog";
import CustomNavigation from "../../../../shared-components/navigation/CustomNavigation";
import { slugify } from "../../../../utils/forms/util-forms";
import useDataStaffGroupingsForm from "../data/data-for-staff-groupings-form";
import { SchemaForStaffCategory } from "../form-schemas/form-schema-for-staff-groupings";
import LoadingDialog from "../../../../shared-components/form/LoadingDialog";
import { Toast } from "primereact/toast";
import FormErrorMsg from "../../../../shared-components/form/ErrorMsg";
import { InputTextarea } from "primereact/inputtextarea";
import { useStaffGroupingServiceAPI } from "../services/services-for-staff-groupings";
import useToaster from "../../../../shared-components/toasts/Toaster";
import { RecordStatus } from "../../../../models";
import { StatusTypeSelect } from "../../../../shared-components/toasts/forms/forms-interfaces";
import isDevMode from "../../../../utils/check-dev-mode";

const selectPublishStatusType: StatusTypeSelect[] = [
  { name: "Archive", value: RecordStatus.ARCHIVE },
  { name: "Draft", value: RecordStatus.DRAFT },
  { name: "Published", value: RecordStatus.PUBLISHED },
];

const FormStaffGroupings = () => {
  const refToast = useRef(null);

  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const formData = useDataStaffGroupingsForm((state) => state.formData);
  const showDialog = useDataStaffGroupingsForm((state) => state.showDialog);
  const { showError, showSuccess } = useToaster();
  const [validateForm, setValidateForm] = useState<boolean>(false);
  const [dataSet, setDataSet] = useState<boolean | null>(null);
  const { addNewData, updateData } = useStaffGroupingServiceAPI();
  const setShowDialog = useDataStaffGroupingsForm(
    (state) => state.setShowDialog
  );

  const formAction = useDataStaffGroupingsForm((state) => state.formActionType);
  const setFormActionType = useDataStaffGroupingsForm(
    (state) => state.setFormActionType
  );

  async function saveForm(values: CrigStaffGroup) {
    let newItem: CrigStaffGroup;
    console.log(formAction);

    switch (formAction ?? FormActionType.NEW) {
      case FormActionType.NEW:
        newItem = new CrigStaffGroup({
          name: values.name,
          shortDescription: values.shortDescription,
          status: values.status!,
          datePublished: new Date().toISOString(),
          dateUploaded: new Date().toISOString(),
          slug: values.slug,
          deleted: false,
        });

        await addNewData(newItem)
          .then(() => {
            showSuccess(refToast, "ATTENTION", "Successfully saved.");
            setFormActionType(FormActionType.EDIT);
            formFormik.setSubmitting(false);
            console.log("done addNewAboutUs");
            setShowDialog(false);
          })
          .catch((err) => {
            console.log("err submitting addNewAboutUs", err);
            showError(
              refToast,
              "ATTENTION",
              "Something went wrong, kindly try again."
            );
            setShowErrorDialog(true);
            formFormik.setSubmitting(false);
          });
        break;

      case FormActionType.EDIT:
        newItem = {
          ...values,
          dateUploaded: new Date().toISOString(),
          datePublished: new Date().toISOString(),
          id: formData!.id,
          deleted: false,
        };
        await updateData(newItem, false)
          .then(async (done) => {
            showSuccess(refToast, "ATTENTION", "Successfully saved.");
            formFormik.setSubmitting(false);
          })
          .catch((error) => {
            console.log("err submitting staffGroup", error);
            showError(
              refToast,
              "ATTENTION",
              "Something went wrong, kindly try again."
            );
            setShowErrorDialog(true);
            formFormik.setSubmitting(false);
          });
        break;

      default:
        break;
    }
  }

  const formFormik = useFormik({
    initialValues: formData as CrigStaffGroup,
    validateOnChange: validateForm,
    enableReinitialize: true,
    validationSchema: SchemaForStaffCategory,
    validateOnBlur: false,

    validate: (values) => {
      setValidateForm(true);
      console.log("validate", values);
      console.log("errors", formFormik.errors);
    },
    onSubmit: async (values) => {
      console.log("submit", values);

      await saveForm(values);
    },
  });

  const { paths: route } = useRoutePaths();
  const refName: any = useRef();
  const refDescription: any = useRef();
  const refStatus: any = useRef();
  const refSlug = useRef(null);

  const fieldLabels = {
    name: {
      id: "name",
      ref: refName,
      label: "Name",
    },
    shortDescription: {
      id: "shortDescription",
      ref: refDescription,
      label: "Short Description",
    },
    slug: {
      id: "slug",
      ref: refSlug,
      label: "Slug",
    },
    status: {
      id: "status",
      ref: refStatus,
      label: "Status",
    },
  };

  const onChangeSlug = (e: any) => {
    console.log("e", e.target.value);
    const slugged = slugify(e.target.value);
    console.log("slugged", slugged);
    formFormik.setFieldValue("slug", slugged);
    return;
  };

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setShowDialog(false)}
        className="p-button-text"
      />
      <Button
        onClick={async () => {
          await formFormik.validateForm();

          if (formFormik.isValid) {
            formFormik.handleSubmit();
          }
        }}
        className=""
        label="Save"
        icon="pi pi-check"
      />
    </div>
  );

  const fieldName = (
    <div className="form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.name.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.name.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.name}
          ref={fieldLabels.name.ref}
        />
      </span>
      <FormErrorMsg fieldId={"name"} errorMsg={formFormik.errors.name} />
    </div>
  );

  const fieldDescription = (
    <div className="t-w-full form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.shortDescription.label}
      </p>
      <span className="w-full p-input-filled">
        <InputTextarea
          className="t-w-full t-min-h-[80px] t-items-start"
          id={fieldLabels.shortDescription.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.shortDescription}
          ref={fieldLabels.shortDescription.ref}
        />
      </span>
      <FormErrorMsg
        fieldId={"shortDescription"}
        errorMsg={formFormik.errors.shortDescription}
      />
    </div>
  );
  const fieldStatus = (
    <div className="t-w-full form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        Status
      </p>
      <Dropdown
        className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
        options={selectPublishStatusType}
        optionLabel="name"
        placeholder="Select"
        id={fieldLabels.status.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        maxLength={100}
        disabled={formFormik.isSubmitting}
        value={formFormik.values.status}
        ref={fieldLabels.status.ref}
      />
      <FormErrorMsg fieldId={"status"} errorMsg={formFormik.errors.status} />
    </div>
  );

  const fieldSlug = (
    <div className="form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.slug.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.slug.id}
          onChange={onChangeSlug}
          onBlur={formFormik.handleBlur}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.slug ?? ""}
          ref={fieldLabels.slug.ref}
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.slug.id}
        errorMsg={formFormik.errors.slug}
      />
    </div>
  );

  useEffect(() => {
    if (formFormik.values.name.length != 0) {
      const slugged = slugify(formFormik.values.name);
      formFormik.setFieldValue("slug", slugged);
    }

    return () => {};
  }, [formFormik.values.name]);

  return (
    <FormikProvider value={formFormik}>
      <form className="main-form" onSubmit={formFormik.handleSubmit}>
        <Dialog
          className="form-dialog t-min-w-[90vw] md:t-max-w-[32vw] md:t-min-w-[32vw]"
          showHeader={false}
          visible={showDialog}
          onHide={() => setShowDialog(false)}
          footer={footerContent}
        >
          <div className="t-w-full md:t-max-w-[96rem] t-m-auto t-bg-white">
            <CustomNavigation
              title={`${
                formAction == FormActionType.NEW
                  ? "New Staff Group"
                  : formAction == FormActionType.EDIT
                  ? "Update Staff Group"
                  : "New Staff Group"
              }`}
              background={true}
              useWhiteBackButton={true}
              backArrow={true}
              backArrowNavUrl={route.settings.crigStaffGroups.list}
              showTopMargin={false}
            />
            <div className="t-px-8">
              <div className="t-mt-6">
                <div className="t-mb-10">
                  {fieldName}
                  {fieldDescription}
                  {fieldSlug}
                  <div className="flex t-w-full">{fieldStatus}</div>
                </div>
              </div>
            </div>
          </div>
          <ErrorDialog
            show={showErrorDialog}
            errorMsg={"Something went wrong, kindly try again."}
            onHide={() => {
              setShowErrorDialog(false);
            }}
          />
          <LoadingDialog show={formFormik.isSubmitting} />
          <Toast ref={refToast} position="top-right"></Toast>
        </Dialog>
      </form>
    </FormikProvider>
  );
};

export default FormStaffGroupings;
