export const createCrigUnitDepartmentGQL = /* GraphQL */ `
  mutation CreateCrigUnitDepartment(
    $input: CreateCrigUnitDepartmentInput!
    $condition: ModelCrigUnitDepartmentConditionInput
  ) {
    createCrigUnitDepartment(input: $input, condition: $condition) {
      _deleted
      deleted
      _lastChangedAt
      crigDivisionSubstationID
      _version
      createdAt
      datePublished
      dateUploaded
      id
      name
      shortDescription
      slug
      totalStaff
      updatedAt
    }
  }
`;

export const updateCrigUnitDepartmentGQL = /* GraphQL */ `
  mutation UpdateCrigUnitDepartment(
    $input: UpdateCrigUnitDepartmentInput!
    $condition: ModelCrigUnitDepartmentConditionInput
  ) {
    updateCrigUnitDepartment(input: $input, condition: $condition) {
      _deleted
      _lastChangedAt
      _version
      createdAt
      crigDivisionSubstationID
      datePublished
      dateUploaded
      id
      name
      shortDescription
      slug
      totalStaff
      updatedAt
    }
  }
`;

export const listCrigUnitDepartmentsGQL = /* GraphQL */ `
  query ListCrigUnitDepartments(
    $filter: ModelCrigUnitDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrigUnitDepartments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      startedAt
      items {
        _deleted
        _lastChangedAt
        _version
        createdAt
        datePublished
        dateUploaded
        id
        name
        shortDescription
        slug
        totalStaff
        updatedAt
        crigDivisionSubstationID
        crigDivisionSubstation {
          id
          title
        }
      }
    }
  }
`;

export const deleteCrigUnitDepartmentGQL = /* GraphQL */ `
  mutation DeleteCrigUnitDepartment(
    $input: DeleteCrigUnitDepartmentInput!
    $condition: ModelCrigUnitDepartmentConditionInput
  ) {
    deleteCrigUnitDepartment(input: $input, condition: $condition) {
      _deleted
      _lastChangedAt
      _version
      createdAt
      datePublished
      crigDivisionSubstationID
      dateUploaded
      id
      name
      shortDescription
      slug
      totalStaff
      updatedAt
    }
  }
`;
