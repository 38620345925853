import { BsPencilSquare, BsTextareaT } from "react-icons/bs";


import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import useRoutePaths from "../../routes/route-path";
import { EditorType } from "../../models";
import { useEffect, useState } from "react";
import useDataExternalServiceForm from "../../pages/page-external-services/data/data-for-external-service-form";
interface Props {
  hasPageBuilders?: boolean;
  editorType?: String;
  onPressedChangeEditorType?: VoidFunction;
  onPressedPreview?: VoidFunction;
  onPressedSave?: VoidFunction;
  showPreview?: boolean;
  showSave?: boolean;
  saveBtnWidth?: string;
}

function FormActionButtonSet({ hasPageBuilders, editorType, onPressedChangeEditorType, onPressedPreview, onPressedSave, showPreview, showSave, saveBtnWidth }: Props) {

  const [changeEditorButtonText, setChangeEditorButtonText] = useState('PAGE BUILDER');
  const [changeEditorButtonIcon, setChangeEditorButtonIcon] = useState(<BsTextareaT size={19.5}
    className="t-mr-[0.5rem]" />);

  function fxnChangeEditorButtonInfo() {

    switch (editorType) {
      case EditorType.TEXT_EDITOR:

        setChangeEditorButtonText((_) => 'PAGE BUILDER');
        setChangeEditorButtonIcon((_) => <BsTextareaT size={19.5}
          className="t-mr-[0.5rem]" />);
        break;

      case EditorType.PAGE_BUILDER:
        setChangeEditorButtonText((_) => 'DETAILS');
        setChangeEditorButtonIcon((_) => <BsPencilSquare size={19.5}
          className="t-mr-[0.5rem]" />);
        break;
      default:
        setChangeEditorButtonText((_) => 'PAGE BUILDER');
        setChangeEditorButtonIcon((_) => <BsTextareaT size={19.5}
          className="t-mr-[0.5rem]" />);
        break;
    }
  }

  useEffect(() => {
    if (hasPageBuilders) {
      // if (editorType == currentEditorType) return;
      fxnChangeEditorButtonInfo();
    }
    return () => {

    }
  }, [hasPageBuilders, editorType])


  return (
    <div className="flex form-action-button t-items-center t-bg-white
     t-rounded-md t-drop-shadow-xl t-justify-between">
      {hasPageBuilders && (
        <>
          {<Button
            className="p-button-rounded editor-switcher-button p-button-text p-button-info"
            label={changeEditorButtonText}
            type='button'
            onClick={onPressedChangeEditorType}
            icon={changeEditorButtonIcon}

          />}
          <div className="t-border-r-2 t-h-8"></div>
        </>
      )}

      {/* {(showPreview != undefined ? showPreview : true) && <Button
        label="PREVIEW"
        className="p-button-rounded preview-button p-button-text p-button-secondary"
        icon="pi pi-eye"
        type='button'
        onClick={onPressedPreview}
      />} */}
      {/* {(showSave != undefined ? !showSave : true) && <div className="t-border-r-2 t-h-8"></div>} */}
      {(showSave != undefined ? showSave : true) && <Button
        className={`p-button-rounded save-button p-button-text p-button-success ${saveBtnWidth ?? ''}`}
        label="SAVE"
        icon="pi pi-save"
        type="submit"
      // onClick={onPressedSave}
      />}
    </div>
  );
}

export default FormActionButtonSet;
