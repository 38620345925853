/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAppNotification = /* GraphQL */ `
  mutation CreateAppNotification(
    $input: CreateAppNotificationInput!
    $condition: ModelAppNotificationConditionInput
  ) {
    createAppNotification(input: $input, condition: $condition) {
      id
      name
      title
      body
      receiversUserAccountsViews {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAppNotification = /* GraphQL */ `
  mutation UpdateAppNotification(
    $input: UpdateAppNotificationInput!
    $condition: ModelAppNotificationConditionInput
  ) {
    updateAppNotification(input: $input, condition: $condition) {
      id
      name
      title
      body
      receiversUserAccountsViews {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAppNotification = /* GraphQL */ `
  mutation DeleteAppNotification(
    $input: DeleteAppNotificationInput!
    $condition: ModelAppNotificationConditionInput
  ) {
    deleteAppNotification(input: $input, condition: $condition) {
      id
      name
      title
      body
      receiversUserAccountsViews {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createArticlePublication = /* GraphQL */ `
  mutation CreateArticlePublication(
    $input: CreateArticlePublicationInput!
    $condition: ModelArticlePublicationConditionInput
  ) {
    createArticlePublication(input: $input, condition: $condition) {
      id
      title
      shortDescription
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      tags
      authorStaffProfiles {
        items {
          id
          articlePublicationId
          staffProfileId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      authorStaffProfilesIds
      publishersIds
      publishers {
        items {
          id
          articlePublicationId
          articlePublicationPublisherId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      deleted
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalDownloadsCount
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postSubCategoryID
      postSubCategory {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      volumeNumber
      editionNumber
      issueNumber
      issn
      userViewsDownloads {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      visibilityType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteArticlePublication = /* GraphQL */ `
  mutation DeleteArticlePublication(
    $input: DeleteArticlePublicationInput!
    $condition: ModelArticlePublicationConditionInput
  ) {
    deleteArticlePublication(input: $input, condition: $condition) {
      id
      title
      shortDescription
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      tags
      authorStaffProfiles {
        items {
          id
          articlePublicationId
          staffProfileId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      authorStaffProfilesIds
      publishersIds
      publishers {
        items {
          id
          articlePublicationId
          articlePublicationPublisherId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      deleted
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalDownloadsCount
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postSubCategoryID
      postSubCategory {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      volumeNumber
      editionNumber
      issueNumber
      issn
      userViewsDownloads {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      visibilityType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createArticlePublicationPublisher = /* GraphQL */ `
  mutation CreateArticlePublicationPublisher(
    $input: CreateArticlePublicationPublisherInput!
    $condition: ModelArticlePublicationPublisherConditionInput
  ) {
    createArticlePublicationPublisher(input: $input, condition: $condition) {
      id
      name
      status
      articlesPublicationsPublished {
        items {
          id
          articlePublicationId
          articlePublicationPublisherId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateArticlePublicationPublisher = /* GraphQL */ `
  mutation UpdateArticlePublicationPublisher(
    $input: UpdateArticlePublicationPublisherInput!
    $condition: ModelArticlePublicationPublisherConditionInput
  ) {
    updateArticlePublicationPublisher(input: $input, condition: $condition) {
      id
      name
      status
      articlesPublicationsPublished {
        items {
          id
          articlePublicationId
          articlePublicationPublisherId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteArticlePublicationPublisher = /* GraphQL */ `
  mutation DeleteArticlePublicationPublisher(
    $input: DeleteArticlePublicationPublisherInput!
    $condition: ModelArticlePublicationPublisherConditionInput
  ) {
    deleteArticlePublicationPublisher(input: $input, condition: $condition) {
      id
      name
      status
      articlesPublicationsPublished {
        items {
          id
          articlePublicationId
          articlePublicationPublisherId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateContactFormMsg = /* GraphQL */ `
  mutation UpdateContactFormMsg(
    $input: UpdateContactFormMsgInput!
    $condition: ModelContactFormMsgConditionInput
  ) {
    updateContactFormMsg(input: $input, condition: $condition) {
      id
      fullName
      emailAddress
      phoneNumber
      countryName
      countryIso3
      subject
      message
      status
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteContactFormMsg = /* GraphQL */ `
  mutation DeleteContactFormMsg(
    $input: DeleteContactFormMsgInput!
    $condition: ModelContactFormMsgConditionInput
  ) {
    deleteContactFormMsg(input: $input, condition: $condition) {
      id
      fullName
      emailAddress
      phoneNumber
      countryName
      countryIso3
      subject
      message
      status
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCrigStaffGroup = /* GraphQL */ `
  mutation CreateCrigStaffGroup(
    $input: CreateCrigStaffGroupInput!
    $condition: ModelCrigStaffGroupConditionInput
  ) {
    createCrigStaffGroup(input: $input, condition: $condition) {
      id
      deleted
      name
      shortDescription
      status
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalStaff
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCrigStaffGroup = /* GraphQL */ `
  mutation DeleteCrigStaffGroup(
    $input: DeleteCrigStaffGroupInput!
    $condition: ModelCrigStaffGroupConditionInput
  ) {
    deleteCrigStaffGroup(input: $input, condition: $condition) {
      id
      deleted
      name
      shortDescription
      status
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalStaff
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCrigUnitDepartment = /* GraphQL */ `
  mutation CreateCrigUnitDepartment(
    $input: CreateCrigUnitDepartmentInput!
    $condition: ModelCrigUnitDepartmentConditionInput
  ) {
    createCrigUnitDepartment(input: $input, condition: $condition) {
      id
      name
      shortDescription
      deleted
      slug
      dateUploaded
      datePublished
      totalStaff
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      crigDivisionSubstationID
      crigDivisionSubstation {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCrigUnitDepartment = /* GraphQL */ `
  mutation UpdateCrigUnitDepartment(
    $input: UpdateCrigUnitDepartmentInput!
    $condition: ModelCrigUnitDepartmentConditionInput
  ) {
    updateCrigUnitDepartment(input: $input, condition: $condition) {
      id
      name
      shortDescription
      deleted
      slug
      dateUploaded
      datePublished
      totalStaff
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      crigDivisionSubstationID
      crigDivisionSubstation {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCrigUnitDepartment = /* GraphQL */ `
  mutation DeleteCrigUnitDepartment(
    $input: DeleteCrigUnitDepartmentInput!
    $condition: ModelCrigUnitDepartmentConditionInput
  ) {
    deleteCrigUnitDepartment(input: $input, condition: $condition) {
      id
      name
      shortDescription
      deleted
      slug
      dateUploaded
      datePublished
      totalStaff
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      crigDivisionSubstationID
      crigDivisionSubstation {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFaq = /* GraphQL */ `
  mutation CreateFaq(
    $input: CreateFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    createFaq(input: $input, condition: $condition) {
      id
      title
      icon
      status
      faqItems {
        items {
          id
          title
          body
          status
          faqID
          dateAdded
          dateUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFaq = /* GraphQL */ `
  mutation UpdateFaq(
    $input: UpdateFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    updateFaq(input: $input, condition: $condition) {
      id
      title
      icon
      status
      faqItems {
        items {
          id
          title
          body
          status
          faqID
          dateAdded
          dateUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFaq = /* GraphQL */ `
  mutation DeleteFaq(
    $input: DeleteFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    deleteFaq(input: $input, condition: $condition) {
      id
      title
      icon
      status
      faqItems {
        items {
          id
          title
          body
          status
          faqID
          dateAdded
          dateUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFaqItem = /* GraphQL */ `
  mutation CreateFaqItem(
    $input: CreateFaqItemInput!
    $condition: ModelFaqItemConditionInput
  ) {
    createFaqItem(input: $input, condition: $condition) {
      id
      title
      body
      status
      faqID
      faq {
        id
        title
        icon
        status
        faqItems {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFaqItem = /* GraphQL */ `
  mutation UpdateFaqItem(
    $input: UpdateFaqItemInput!
    $condition: ModelFaqItemConditionInput
  ) {
    updateFaqItem(input: $input, condition: $condition) {
      id
      title
      body
      status
      faqID
      faq {
        id
        title
        icon
        status
        faqItems {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFaqItem = /* GraphQL */ `
  mutation DeleteFaqItem(
    $input: DeleteFaqItemInput!
    $condition: ModelFaqItemConditionInput
  ) {
    deleteFaqItem(input: $input, condition: $condition) {
      id
      title
      body
      status
      faqID
      faq {
        id
        title
        icon
        status
        faqItems {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFolder = /* GraphQL */ `
  mutation CreateFolder(
    $input: CreateFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    createFolder(input: $input, condition: $condition) {
      id
      title
      color
      status
      pageType
      slug
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFolder = /* GraphQL */ `
  mutation UpdateFolder(
    $input: UpdateFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    updateFolder(input: $input, condition: $condition) {
      id
      title
      color
      status
      pageType
      slug
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFolder = /* GraphQL */ `
  mutation DeleteFolder(
    $input: DeleteFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    deleteFolder(input: $input, condition: $condition) {
      id
      title
      color
      status
      pageType
      slug
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHelpCenterPage = /* GraphQL */ `
  mutation CreateHelpCenterPage(
    $input: CreateHelpCenterPageInput!
    $condition: ModelHelpCenterPageConditionInput
  ) {
    createHelpCenterPage(input: $input, condition: $condition) {
      id
      title
      icon
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      tags
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHelpCenterPage = /* GraphQL */ `
  mutation UpdateHelpCenterPage(
    $input: UpdateHelpCenterPageInput!
    $condition: ModelHelpCenterPageConditionInput
  ) {
    updateHelpCenterPage(input: $input, condition: $condition) {
      id
      title
      icon
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      tags
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHelpCenterPage = /* GraphQL */ `
  mutation DeleteHelpCenterPage(
    $input: DeleteHelpCenterPageInput!
    $condition: ModelHelpCenterPageConditionInput
  ) {
    deleteHelpCenterPage(input: $input, condition: $condition) {
      id
      title
      icon
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      tags
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPage = /* GraphQL */ `
  mutation CreatePage(
    $input: CreatePageInput!
    $condition: ModelPageConditionInput
  ) {
    createPage(input: $input, condition: $condition) {
      id
      title
      shortDescription
      details
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      status
      totalViewsCount
      totalStaffCount
      totalDownloadsCount
      totalDeliveriesCount
      pageType
      tags
      slug
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postSubCategoryID
      postSubCategory {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      unitDepartments {
        items {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      dateAdded
      dateSent
      dateUpdated
      datePublished
      deleted
      listPosition
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePage = /* GraphQL */ `
  mutation DeletePage(
    $input: DeletePageInput!
    $condition: ModelPageConditionInput
  ) {
    deletePage(input: $input, condition: $condition) {
      id
      title
      shortDescription
      details
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      status
      totalViewsCount
      totalStaffCount
      totalDownloadsCount
      totalDeliveriesCount
      pageType
      tags
      slug
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postSubCategoryID
      postSubCategory {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      unitDepartments {
        items {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      dateAdded
      dateSent
      dateUpdated
      datePublished
      deleted
      listPosition
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPageDesignTemplate = /* GraphQL */ `
  mutation CreatePageDesignTemplate(
    $input: CreatePageDesignTemplateInput!
    $condition: ModelPageDesignTemplateConditionInput
  ) {
    createPageDesignTemplate(input: $input, condition: $condition) {
      id
      title
      description
      templateDesign
      status
      type
      deleted
      dateAdded
      datePublished
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePageDesignTemplate = /* GraphQL */ `
  mutation UpdatePageDesignTemplate(
    $input: UpdatePageDesignTemplateInput!
    $condition: ModelPageDesignTemplateConditionInput
  ) {
    updatePageDesignTemplate(input: $input, condition: $condition) {
      id
      title
      description
      templateDesign
      status
      type
      deleted
      dateAdded
      datePublished
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePageDesignTemplate = /* GraphQL */ `
  mutation DeletePageDesignTemplate(
    $input: DeletePageDesignTemplateInput!
    $condition: ModelPageDesignTemplateConditionInput
  ) {
    deletePageDesignTemplate(input: $input, condition: $condition) {
      id
      title
      description
      templateDesign
      status
      type
      deleted
      dateAdded
      datePublished
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPostCategory = /* GraphQL */ `
  mutation CreatePostCategory(
    $input: CreatePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    createPostCategory(input: $input, condition: $condition) {
      id
      name
      shortDescription
      slug
      dateUploaded
      deleted
      datePublished
      totalPostsCount
      totalSubCategoriesCount
      articlesPublications {
        items {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pages {
        items {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      subCategories {
        items {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePostCategory = /* GraphQL */ `
  mutation UpdatePostCategory(
    $input: UpdatePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    updatePostCategory(input: $input, condition: $condition) {
      id
      name
      shortDescription
      slug
      dateUploaded
      deleted
      datePublished
      totalPostsCount
      totalSubCategoriesCount
      articlesPublications {
        items {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pages {
        items {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      subCategories {
        items {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePostCategory = /* GraphQL */ `
  mutation DeletePostCategory(
    $input: DeletePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    deletePostCategory(input: $input, condition: $condition) {
      id
      name
      shortDescription
      slug
      dateUploaded
      deleted
      datePublished
      totalPostsCount
      totalSubCategoriesCount
      articlesPublications {
        items {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pages {
        items {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      subCategories {
        items {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPostSubCategory = /* GraphQL */ `
  mutation CreatePostSubCategory(
    $input: CreatePostSubCategoryInput!
    $condition: ModelPostSubCategoryConditionInput
  ) {
    createPostSubCategory(input: $input, condition: $condition) {
      id
      name
      slug
      dateUploaded
      datePublished
      totalPosts
      deleted
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      articlesPublications {
        items {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pages {
        items {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePostSubCategory = /* GraphQL */ `
  mutation UpdatePostSubCategory(
    $input: UpdatePostSubCategoryInput!
    $condition: ModelPostSubCategoryConditionInput
  ) {
    updatePostSubCategory(input: $input, condition: $condition) {
      id
      name
      slug
      dateUploaded
      datePublished
      totalPosts
      deleted
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      articlesPublications {
        items {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pages {
        items {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePostSubCategory = /* GraphQL */ `
  mutation DeletePostSubCategory(
    $input: DeletePostSubCategoryInput!
    $condition: ModelPostSubCategoryConditionInput
  ) {
    deletePostSubCategory(input: $input, condition: $condition) {
      id
      name
      slug
      dateUploaded
      datePublished
      totalPosts
      deleted
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      articlesPublications {
        items {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pages {
        items {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPostUserAccountViewDownload = /* GraphQL */ `
  mutation CreatePostUserAccountViewDownload(
    $input: CreatePostUserAccountViewDownloadInput!
    $condition: ModelPostUserAccountViewDownloadConditionInput
  ) {
    createPostUserAccountViewDownload(input: $input, condition: $condition) {
      id
      userAccountID
      userAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      pageType
      deleted
      postType
      postID
      appNotificationID
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      appNotification {
        id
        name
        title
        body
        receiversUserAccountsViews {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      downloadCount
      deliveriesCount
      viewCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updatePostUserAccountViewDownload = /* GraphQL */ `
  mutation UpdatePostUserAccountViewDownload(
    $input: UpdatePostUserAccountViewDownloadInput!
    $condition: ModelPostUserAccountViewDownloadConditionInput
  ) {
    updatePostUserAccountViewDownload(input: $input, condition: $condition) {
      id
      userAccountID
      userAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      pageType
      deleted
      postType
      postID
      appNotificationID
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      appNotification {
        id
        name
        title
        body
        receiversUserAccountsViews {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      downloadCount
      deliveriesCount
      viewCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deletePostUserAccountViewDownload = /* GraphQL */ `
  mutation DeletePostUserAccountViewDownload(
    $input: DeletePostUserAccountViewDownloadInput!
    $condition: ModelPostUserAccountViewDownloadConditionInput
  ) {
    deletePostUserAccountViewDownload(input: $input, condition: $condition) {
      id
      userAccountID
      userAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      pageType
      deleted
      postType
      postID
      appNotificationID
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      appNotification {
        id
        name
        title
        body
        receiversUserAccountsViews {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      downloadCount
      deliveriesCount
      viewCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createSiteStats = /* GraphQL */ `
  mutation CreateSiteStats(
    $input: CreateSiteStatsInput!
    $condition: ModelSiteStatsConditionInput
  ) {
    createSiteStats(input: $input, condition: $condition) {
      id
      researchActivities {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      products {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      aboutUs {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      externalServices {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      recreationFacilities {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      divisionsSubStations {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      articleAndPublications {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      eventsAndGallery {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      newsAndAnnouncements {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      newsletters {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      staff {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSiteStats = /* GraphQL */ `
  mutation UpdateSiteStats(
    $input: UpdateSiteStatsInput!
    $condition: ModelSiteStatsConditionInput
  ) {
    updateSiteStats(input: $input, condition: $condition) {
      id
      researchActivities {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      products {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      aboutUs {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      externalServices {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      recreationFacilities {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      divisionsSubStations {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      articleAndPublications {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      eventsAndGallery {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      newsAndAnnouncements {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      newsletters {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      staff {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSiteStats = /* GraphQL */ `
  mutation DeleteSiteStats(
    $input: DeleteSiteStatsInput!
    $condition: ModelSiteStatsConditionInput
  ) {
    deleteSiteStats(input: $input, condition: $condition) {
      id
      researchActivities {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      products {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      aboutUs {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      externalServices {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      recreationFacilities {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      divisionsSubStations {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      articleAndPublications {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      eventsAndGallery {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      newsAndAnnouncements {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      newsletters {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      staff {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSlide = /* GraphQL */ `
  mutation CreateSlide(
    $input: CreateSlideInput!
    $condition: ModelSlideConditionInput
  ) {
    createSlide(input: $input, condition: $condition) {
      id
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postCategoryName
      postSubCategoryName
      slideTitle
      slidePosition
      slidePageURL
      pageType
      isSidePop
      postID
      postSlug
      postCoverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      status
      dateAdded
      dateUpdated
      deleted
      totalClicksCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSlide = /* GraphQL */ `
  mutation DeleteSlide(
    $input: DeleteSlideInput!
    $condition: ModelSlideConditionInput
  ) {
    deleteSlide(input: $input, condition: $condition) {
      id
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postCategoryName
      postSubCategoryName
      slideTitle
      slidePosition
      slidePageURL
      pageType
      isSidePop
      postID
      postSlug
      postCoverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      status
      dateAdded
      dateUpdated
      deleted
      totalClicksCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createStaffProfile = /* GraphQL */ `
  mutation CreateStaffProfile(
    $input: CreateStaffProfileInput!
    $condition: ModelStaffProfileConditionInput
  ) {
    createStaffProfile(input: $input, condition: $condition) {
      id
      listPosition
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      nameFirst
      nameLast
      deleted
      nameFull
      slug
      crigDivisionSubStationID
      crigDivisionSubStation {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      crigStaffGroupID
      crigStaffGroup {
        id
        deleted
        name
        shortDescription
        status
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      crigUnitDepartmentID
      crigUnitDepartment {
        id
        name
        shortDescription
        deleted
        slug
        dateUploaded
        datePublished
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        crigDivisionSubstationID
        crigDivisionSubstation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      position
      isAContentFollower
      userAccountID
      bioDesignType
      bioDesignPageBuilder
      bioDesignTextEditors {
        title
        body
      }
      status
      dateAdded
      dateUpdated
      articlesPublicationsAuthored {
        items {
          id
          articlePublicationId
          staffProfileId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      totalViewsCount
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteStaffProfile = /* GraphQL */ `
  mutation DeleteStaffProfile(
    $input: DeleteStaffProfileInput!
    $condition: ModelStaffProfileConditionInput
  ) {
    deleteStaffProfile(input: $input, condition: $condition) {
      id
      listPosition
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      nameFirst
      nameLast
      deleted
      nameFull
      slug
      crigDivisionSubStationID
      crigDivisionSubStation {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      crigStaffGroupID
      crigStaffGroup {
        id
        deleted
        name
        shortDescription
        status
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      crigUnitDepartmentID
      crigUnitDepartment {
        id
        name
        shortDescription
        deleted
        slug
        dateUploaded
        datePublished
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        crigDivisionSubstationID
        crigDivisionSubstation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      position
      isAContentFollower
      userAccountID
      bioDesignType
      bioDesignPageBuilder
      bioDesignTextEditors {
        title
        body
      }
      status
      dateAdded
      dateUpdated
      articlesPublicationsAuthored {
        items {
          id
          articlePublicationId
          staffProfileId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      totalViewsCount
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserAccount = /* GraphQL */ `
  mutation CreateUserAccount(
    $input: CreateUserAccountInput!
    $condition: ModelUserAccountConditionInput
  ) {
    createUserAccount(input: $input, condition: $condition) {
      id
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      category
      deleted
      nameFirst
      nameLast
      nameFull
      authId
      status
      contactPhoneNumber1
      contactPhoneNumber2
      contactEmail1
      contactEmail2
      countryIso
      countryName
      staffProfileID
      userRoleID
      userRole {
        id
        title
        shortDescription
        moreDetailsTextEditor
        status
        userAccounts {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        assignedAccess {
          id
          userRoleID
          tableName
          routePath
          menuItemTitle
          operations
        }
        creatorUserAccountID
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFollowing
      dateLastLogin
      dateAdded
      dateAddedYear
      dateAddedMonth
      dateAddedDay
      dateUpdated
      dateUpdatedYear
      dateUpdatedMonth
      dateUpdatedDay
      postViewsDownloads {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      totalViewsCount
      totalPostDownloadsCount
      totalPostViewsCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateUserAccount = /* GraphQL */ `
  mutation UpdateUserAccount(
    $input: UpdateUserAccountInput!
    $condition: ModelUserAccountConditionInput
  ) {
    updateUserAccount(input: $input, condition: $condition) {
      id
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      category
      deleted
      nameFirst
      nameLast
      nameFull
      authId
      status
      contactPhoneNumber1
      contactPhoneNumber2
      contactEmail1
      contactEmail2
      countryIso
      countryName
      staffProfileID
      userRoleID
      userRole {
        id
        title
        shortDescription
        moreDetailsTextEditor
        status
        userAccounts {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        assignedAccess {
          id
          userRoleID
          tableName
          routePath
          menuItemTitle
          operations
        }
        creatorUserAccountID
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFollowing
      dateLastLogin
      dateAdded
      dateAddedYear
      dateAddedMonth
      dateAddedDay
      dateUpdated
      dateUpdatedYear
      dateUpdatedMonth
      dateUpdatedDay
      postViewsDownloads {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      totalViewsCount
      totalPostDownloadsCount
      totalPostViewsCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteUserAccount = /* GraphQL */ `
  mutation DeleteUserAccount(
    $input: DeleteUserAccountInput!
    $condition: ModelUserAccountConditionInput
  ) {
    deleteUserAccount(input: $input, condition: $condition) {
      id
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      category
      deleted
      nameFirst
      nameLast
      nameFull
      authId
      status
      contactPhoneNumber1
      contactPhoneNumber2
      contactEmail1
      contactEmail2
      countryIso
      countryName
      staffProfileID
      userRoleID
      userRole {
        id
        title
        shortDescription
        moreDetailsTextEditor
        status
        userAccounts {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        assignedAccess {
          id
          userRoleID
          tableName
          routePath
          menuItemTitle
          operations
        }
        creatorUserAccountID
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFollowing
      dateLastLogin
      dateAdded
      dateAddedYear
      dateAddedMonth
      dateAddedDay
      dateUpdated
      dateUpdatedYear
      dateUpdatedMonth
      dateUpdatedDay
      postViewsDownloads {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      totalViewsCount
      totalPostDownloadsCount
      totalPostViewsCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createUserRole = /* GraphQL */ `
  mutation CreateUserRole(
    $input: CreateUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    createUserRole(input: $input, condition: $condition) {
      id
      title
      shortDescription
      moreDetailsTextEditor
      status
      userAccounts {
        items {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      assignedAccess {
        id
        userRoleID
        tableName
        routePath
        menuItemTitle
        operations
      }
      creatorUserAccountID
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserRole = /* GraphQL */ `
  mutation UpdateUserRole(
    $input: UpdateUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    updateUserRole(input: $input, condition: $condition) {
      id
      title
      shortDescription
      moreDetailsTextEditor
      status
      userAccounts {
        items {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      assignedAccess {
        id
        userRoleID
        tableName
        routePath
        menuItemTitle
        operations
      }
      creatorUserAccountID
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserRole = /* GraphQL */ `
  mutation DeleteUserRole(
    $input: DeleteUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    deleteUserRole(input: $input, condition: $condition) {
      id
      title
      shortDescription
      moreDetailsTextEditor
      status
      userAccounts {
        items {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      assignedAccess {
        id
        userRoleID
        tableName
        routePath
        menuItemTitle
        operations
      }
      creatorUserAccountID
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createArticlePublicationsAuthors = /* GraphQL */ `
  mutation CreateArticlePublicationsAuthors(
    $input: CreateArticlePublicationsAuthorsInput!
    $condition: ModelArticlePublicationsAuthorsConditionInput
  ) {
    createArticlePublicationsAuthors(input: $input, condition: $condition) {
      id
      articlePublicationId
      staffProfileId
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      staffProfile {
        id
        listPosition
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        nameFirst
        nameLast
        deleted
        nameFull
        slug
        crigDivisionSubStationID
        crigDivisionSubStation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigStaffGroupID
        crigStaffGroup {
          id
          deleted
          name
          shortDescription
          status
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalStaff
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigUnitDepartmentID
        crigUnitDepartment {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        position
        isAContentFollower
        userAccountID
        bioDesignType
        bioDesignPageBuilder
        bioDesignTextEditors {
          title
          body
        }
        status
        dateAdded
        dateUpdated
        articlesPublicationsAuthored {
          nextToken
          startedAt
        }
        totalViewsCount
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateArticlePublicationsAuthors = /* GraphQL */ `
  mutation UpdateArticlePublicationsAuthors(
    $input: UpdateArticlePublicationsAuthorsInput!
    $condition: ModelArticlePublicationsAuthorsConditionInput
  ) {
    updateArticlePublicationsAuthors(input: $input, condition: $condition) {
      id
      articlePublicationId
      staffProfileId
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      staffProfile {
        id
        listPosition
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        nameFirst
        nameLast
        deleted
        nameFull
        slug
        crigDivisionSubStationID
        crigDivisionSubStation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigStaffGroupID
        crigStaffGroup {
          id
          deleted
          name
          shortDescription
          status
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalStaff
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigUnitDepartmentID
        crigUnitDepartment {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        position
        isAContentFollower
        userAccountID
        bioDesignType
        bioDesignPageBuilder
        bioDesignTextEditors {
          title
          body
        }
        status
        dateAdded
        dateUpdated
        articlesPublicationsAuthored {
          nextToken
          startedAt
        }
        totalViewsCount
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteArticlePublicationsAuthors = /* GraphQL */ `
  mutation DeleteArticlePublicationsAuthors(
    $input: DeleteArticlePublicationsAuthorsInput!
    $condition: ModelArticlePublicationsAuthorsConditionInput
  ) {
    deleteArticlePublicationsAuthors(input: $input, condition: $condition) {
      id
      articlePublicationId
      staffProfileId
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      staffProfile {
        id
        listPosition
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        nameFirst
        nameLast
        deleted
        nameFull
        slug
        crigDivisionSubStationID
        crigDivisionSubStation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigStaffGroupID
        crigStaffGroup {
          id
          deleted
          name
          shortDescription
          status
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalStaff
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigUnitDepartmentID
        crigUnitDepartment {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        position
        isAContentFollower
        userAccountID
        bioDesignType
        bioDesignPageBuilder
        bioDesignTextEditors {
          title
          body
        }
        status
        dateAdded
        dateUpdated
        articlesPublicationsAuthored {
          nextToken
          startedAt
        }
        totalViewsCount
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createArticlePublicationsPublishers = /* GraphQL */ `
  mutation CreateArticlePublicationsPublishers(
    $input: CreateArticlePublicationsPublishersInput!
    $condition: ModelArticlePublicationsPublishersConditionInput
  ) {
    createArticlePublicationsPublishers(input: $input, condition: $condition) {
      id
      articlePublicationId
      articlePublicationPublisherId
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      articlePublicationPublisher {
        id
        name
        status
        articlesPublicationsPublished {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateArticlePublicationsPublishers = /* GraphQL */ `
  mutation UpdateArticlePublicationsPublishers(
    $input: UpdateArticlePublicationsPublishersInput!
    $condition: ModelArticlePublicationsPublishersConditionInput
  ) {
    updateArticlePublicationsPublishers(input: $input, condition: $condition) {
      id
      articlePublicationId
      articlePublicationPublisherId
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      articlePublicationPublisher {
        id
        name
        status
        articlesPublicationsPublished {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteArticlePublicationsPublishers = /* GraphQL */ `
  mutation DeleteArticlePublicationsPublishers(
    $input: DeleteArticlePublicationsPublishersInput!
    $condition: ModelArticlePublicationsPublishersConditionInput
  ) {
    deleteArticlePublicationsPublishers(input: $input, condition: $condition) {
      id
      articlePublicationId
      articlePublicationPublisherId
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      articlePublicationPublisher {
        id
        name
        status
        articlesPublicationsPublished {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateArticlePublication = /* GraphQL */ `
  mutation UpdateArticlePublication(
    $input: UpdateArticlePublicationInput!
    $condition: ModelArticlePublicationConditionInput
  ) {
    updateArticlePublication(input: $input, condition: $condition) {
      id
      title
      shortDescription
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      tags
      authorStaffProfiles {
        items {
          id
          articlePublicationId
          staffProfileId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      authorStaffProfilesIds
      publishersIds
      publishers {
        items {
          id
          articlePublicationId
          articlePublicationPublisherId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      deleted
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalDownloadsCount
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postSubCategoryID
      postSubCategory {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      volumeNumber
      editionNumber
      issueNumber
      issn
      userViewsDownloads {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      visibilityType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createContactFormMsg = /* GraphQL */ `
  mutation CreateContactFormMsg(
    $input: CreateContactFormMsgInput!
    $condition: ModelContactFormMsgConditionInput
  ) {
    createContactFormMsg(input: $input, condition: $condition) {
      id
      fullName
      emailAddress
      phoneNumber
      countryName
      countryIso3
      subject
      message
      status
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCrigStaffGroup = /* GraphQL */ `
  mutation UpdateCrigStaffGroup(
    $input: UpdateCrigStaffGroupInput!
    $condition: ModelCrigStaffGroupConditionInput
  ) {
    updateCrigStaffGroup(input: $input, condition: $condition) {
      id
      deleted
      name
      shortDescription
      status
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalStaff
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePage = /* GraphQL */ `
  mutation UpdatePage(
    $input: UpdatePageInput!
    $condition: ModelPageConditionInput
  ) {
    updatePage(input: $input, condition: $condition) {
      id
      title
      shortDescription
      details
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      status
      totalViewsCount
      totalStaffCount
      totalDownloadsCount
      totalDeliveriesCount
      pageType
      tags
      slug
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postSubCategoryID
      postSubCategory {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      unitDepartments {
        items {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      dateAdded
      dateSent
      dateUpdated
      datePublished
      deleted
      listPosition
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSlide = /* GraphQL */ `
  mutation UpdateSlide(
    $input: UpdateSlideInput!
    $condition: ModelSlideConditionInput
  ) {
    updateSlide(input: $input, condition: $condition) {
      id
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postCategoryName
      postSubCategoryName
      slideTitle
      slidePosition
      slidePageURL
      pageType
      isSidePop
      postID
      postSlug
      postCoverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      status
      dateAdded
      dateUpdated
      deleted
      totalClicksCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateStaffProfile = /* GraphQL */ `
  mutation UpdateStaffProfile(
    $input: UpdateStaffProfileInput!
    $condition: ModelStaffProfileConditionInput
  ) {
    updateStaffProfile(input: $input, condition: $condition) {
      id
      listPosition
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      nameFirst
      nameLast
      deleted
      nameFull
      slug
      crigDivisionSubStationID
      crigDivisionSubStation {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      crigStaffGroupID
      crigStaffGroup {
        id
        deleted
        name
        shortDescription
        status
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      crigUnitDepartmentID
      crigUnitDepartment {
        id
        name
        shortDescription
        deleted
        slug
        dateUploaded
        datePublished
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        crigDivisionSubstationID
        crigDivisionSubstation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      position
      isAContentFollower
      userAccountID
      bioDesignType
      bioDesignPageBuilder
      bioDesignTextEditors {
        title
        body
      }
      status
      dateAdded
      dateUpdated
      articlesPublicationsAuthored {
        items {
          id
          articlePublicationId
          staffProfileId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      totalViewsCount
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
