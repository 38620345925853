import { InputText } from "primereact/inputtext";
import { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { Checkbox } from "primereact/checkbox";
import { Image } from "primereact/image";


const data = [
  {
    id: "1",
    name: "John Doe",
    image:
      "https://img.freepik.com/free-photo/young-bearded-man-with-striped-shirt_273609-5677.jpg",
    title: "Sample Notifications Title from John",
    content:
      "In publishing an relying on meaningful content. Lorem ipsum may  publishing an relying on meaningful content. Lorem ipsum may  be used as a placeholder before final copy is available.",
    read: false,
    time: "1 hour ago",
  },

  {
    id: "2",
    name: "Sarah",
    image:
      "https://www.unesco.org/sites/default/files/styles/paragraph_medium_desktop/public/2022-04/godwin-angeline-benjo-An7LvVMb6rY-unsplash.jpeg?itok=3STf8JD9",
    title: "Sample Notifications Title from Sarah",
    content:
      "In publishing an relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
    read: true,
    time: "2 hour ago",
  },
  {
    id: "3",
    name: "Christopher",
    image: "https://www.cdc.gov/cancer/prostate/images/man-750.jpg?_=76964",
    title: "Sample Notifications Title from Christopher",
    content:
      "In publishing an relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
    read: true,
    time: "2 hour ago",
  },
  {
    id: "4",
    name: "Micheal Smith",
    image:
      "https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000",
    title: "Sample Notifications Title from Smith",
    content:
      "In publishing an relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
    read: false,
    time: "4 hour ago",
  },
];

interface NotificationDataProps {
  id: string;
  name: string;
  image: string;
  title: string;
  content: string;
  read: boolean;
  time: string;
}

function ListNotifications() {
  const actions = [
    { name: "Mark as Read", code: "MR" },
    { name: "Delete", code: "D" },
  ];

  const [selectedAction, setSelectedAction] = useState(null);
  const [notificationData, setNotificationData] =
    useState<NotificationDataProps>();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [selectNotification, setSelectNotification] = useState<any>([]);
  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    dateUpdated: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },

    activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
  });
  const onSearchKeywordChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const onSelectChange = (e: { value: any; checked: boolean }) => {
    let selectedCities = [...selectNotification];

    if (e.checked) selectedCities.push(e.value);
    else selectedCities.splice(selectedCities.indexOf(e.value), 1);

    setSelectNotification(selectedCities);
  };

  const onActionChange = (e) => {
    setSelectedAction(e.value);
  };

  const showNotificationDetails = (data) => {
    setNotificationData(data);
  };

  const showNextNotification = () => {
    setCurrentNotificationIndex(currentNotificationIndex + 1);
  };
  const showPreviousNotification = () => {
    setCurrentNotificationIndex(currentNotificationIndex - 1);
  };

  useEffect(() => {
    showNotificationDetails(data[currentNotificationIndex]);
  }, [currentNotificationIndex]);

  return (
    <div className="flex t-flex-col t-overflow-hidden t-justify-start t-h-fit t-rounded-lg t-shadow-lg t-m-3">
      <div>
        <CustomNavigation background={false} title="Notification List" />
      </div>

      <div
        className="t-grid grid-cols-1 t-w-full md:t-grid-cols-3
       justify-content-start align-items-center t-m-4"
      >
        <div className="list-column-select" style={{ textAlign: "left" }}>
          <Dropdown
            className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
            value={selectedAction}
            options={actions}
            onChange={onActionChange}
            optionLabel="name"
            placeholder="Select"
            style={{ width: "14.5em", borderRadius: "10px" }}
          />
        </div>
        <div className="list-search-box t-w-full t-pt-4 md:t-pt-0">
          <span className="p-input-icon-left p-input-border-none  t-font-inter p-input-filled  t-w-full">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onSearchKeywordChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </div>
      <div className="flex">
        <div className="t-h-[65vh] t-overflow-y-scroll t-w-[40%] ">
          {data.map((item, index) => (
            <div
              key={item.id}
              className="flex t-m-4 t-items-start t-cursor-pointer"
            >
              <div className="field-checkbox">
                <Checkbox
                  inputId={item.id}
                  name="notification"
                  value={item.id}
                  // onChange={onSelectChange}
                  checked={selectNotification.indexOf(item.id) !== -1}
                />
              </div>
              <div
                onClick={() => {
                  showNotificationDetails(item);
                  setCurrentNotificationIndex(index);
                }}
                className={`flex t-w-full t-h-28 t-bg-white t-rounded-xl t-p-2 t-ml-2
                 t-shadow-xl t-scale-90 hover:t-scale-100 t-ease-in t-duration-500
                 `}
              >
                <div className="">
                  <Avatar
                    image={item.image ?? ""}
                    className="mr-2"
                    size="large"
                    shape="circle"
                  />
                </div>
                <div className="t-w-full">
                  <div className="flex t-justify-between">
                    <div className="t-font-bold">{item?.name}</div>
                    <div className="t-text-appgrey-500 t-font-regular">
                      {item?.time}
                    </div>
                  </div>

                  <div>
                    <p className="t-font-semibold">{item?.title}</p>
                    <div className="t-font-regular t-text-appgrey-700 notification-component-content ">
                      {item?.content}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="t-w-[60%] t-bg-white">
          <div>
            <div className="flex t-w-full t-justify-between t-mt-2">
              <div className="flex">
                <span className="flex t-items-center t-bg-white p-2 t-rounded-xl t-h-8 t-justify-between">
                  <Button
                    disabled={currentNotificationIndex === 0}
                    onClick={showPreviousNotification}
                    className="p-button-rounded p-button-text p-button-secondary"
                    icon="pi pi-angle-left"
                  />
                </span>
                <span className="flex t-items-center t-bg-white p-2 t-rounded-xl t-h-8 t-justify-between">
                  <Button
                    disabled={currentNotificationIndex === data.length - 1}
                    onClick={showNextNotification}
                    className="p-button-rounded p-button-text p-button-secondary"
                    icon="pi pi-angle-right"
                  />
                </span>
              </div>
              <div className="flex">
                {!notificationData?.read && (
                  <span className="flex t-items-center t-bg-white p-2 t-rounded-xl t-h-8 t-justify-between">
                    <Button
                      className="p-button-rounded p-button-text p-button-secondary"
                      label="Mark as read"
                      icon="pi pi-eye"
                    />
                  </span>
                )}
                <span className="flex t-items-center t-bg-white p-2 t-rounded-xl t-h-8 t-justify-between">
                  <Button
                    className="p-button-rounded p-button-text p-button-error"
                    label="Delete"
                    icon="pi pi-trash"
                  />
                </span>
              </div>
            </div>
            <div className="flex t-w-full t-items-start">
              <div className="flex t-w-full t-h-32 t-bg-white t-rounded-xl t-p-4 t-ml-2">
                <div className="t-mr-4 t-w-[150px] t-h-[150px] t-rounded-full">
                  <Image
                    imageStyle={{
                      borderRadius: "50%",
                      width: 95,
                      height: 95,
                    }}
                    className="mr-2"
                    src={notificationData?.image ?? ""}
                    alt="Image"
                    preview
                  />
                </div>
                <div className="t-w-full">
                  <div className="flex t-justify-between">
                    <div className="t-font-regular">
                      {notificationData?.name}
                    </div>
                    <div className="t-text-appgrey-500 t-font-regular">
                      {notificationData?.time}
                    </div>
                  </div>

                  <div>
                    <p className="t-font-bold t-my-4 t-text-xl">
                      {notificationData?.title}
                    </p>
                    <div className="t-w-full t-text-ellipsis t-font-regular">
                      {notificationData?.content}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListNotifications;
