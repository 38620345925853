import { Alignment } from "@ckeditor/ckeditor5-alignment";
import { Autoformat } from "@ckeditor/ckeditor5-autoformat";
import { Bold, Code, Italic, Strikethrough, Subscript, Superscript, Underline } from "@ckeditor/ckeditor5-basic-styles";
import { BlockQuote } from "@ckeditor/ckeditor5-block-quote";
import { CodeBlock } from "@ckeditor/ckeditor5-code-block";
import { Essentials } from "@ckeditor/ckeditor5-essentials";
import { FontBackgroundColor, FontColor, FontFamily, FontSize } from "@ckeditor/ckeditor5-font";
import { Heading } from "@ckeditor/ckeditor5-heading";
import { Highlight } from "@ckeditor/ckeditor5-highlight";
import { ImageCaption, ImageInsert, ImageResize, ImageStyle, ImageEditing, ImageToolbar, ImageUpload } from "@ckeditor/ckeditor5-image";
import { Indent, IndentBlock } from "@ckeditor/ckeditor5-indent";
import ImageBlock from '@ckeditor/ckeditor5-image/src/imageblock';
import { AutoLink, LinkImage } from "@ckeditor/ckeditor5-link";
import Link from '@ckeditor/ckeditor5-link/src/link';
import { List, ListProperties, TodoList } from "@ckeditor/ckeditor5-list";
import { MediaEmbed, MediaEmbedToolbar } from "@ckeditor/ckeditor5-media-embed";
import { Mention } from "@ckeditor/ckeditor5-mention";
import { Paragraph } from "@ckeditor/ckeditor5-paragraph";
import { PasteFromOffice } from "@ckeditor/ckeditor5-paste-from-office";
import { StandardEditingMode } from "@ckeditor/ckeditor5-restricted-editing";
import { Table, TableCaption, TableCellProperties, TableProperties, TableToolbar } from "@ckeditor/ckeditor5-table";
import { TextTransformation } from "@ckeditor/ckeditor5-typing";
import { WordCount } from "@ckeditor/ckeditor5-word-count";
import TableColumnResize from '@ckeditor/ckeditor5-table/src/tablecolumnresize';
import DataFilter from '@ckeditor/ckeditor5-html-support/src/datafilter';
import DataSchema from '@ckeditor/ckeditor5-html-support/src/dataschema';
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace';
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import HtmlComment from '@ckeditor/ckeditor5-html-support/src/htmlcomment';
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed';
// import Markdown from '@ckeditor/ckeditor5-markdown-gfm/src/markdown';
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import SpecialCharactersArrows from '@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows';
import SpecialCharactersCurrency from '@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency';
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';
import SpecialCharactersLatin from '@ckeditor/ckeditor5-special-characters/src/specialcharacterslatin';
import SpecialCharactersMathematical from '@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical';
import SpecialCharactersText from '@ckeditor/ckeditor5-special-characters/src/specialcharacterstext'
import Style from '@ckeditor/ckeditor5-style/src/style';
import '@ckeditor/ckeditor5-build-classic/build/translations/en-gb';

export const editorConfiguration = {
    plugins: [
        Alignment,
        Autoformat,
        AutoLink,
        BlockQuote,
        Bold,
        Code,
        CodeBlock,
        DataFilter,
        DataSchema,
        Essentials,
        FindAndReplace,
        FontBackgroundColor,
        FontColor,
        FontFamily,
        FontSize,
        GeneralHtmlSupport,
        Heading,
        Highlight,
        HorizontalLine,
        HtmlComment,
        HtmlEmbed,
        Image,
        ImageCaption,
        ImageInsert,
        ImageBlock,

        ImageResize,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
        ImageEditing,
        Indent,
        IndentBlock,
        Italic,
        Link,
        LinkImage,
        List,
        ListProperties,
        // Markdown,
        MediaEmbed,
        MediaEmbedToolbar,
        Mention,
        PageBreak,
        Paragraph,
        PasteFromOffice,
        RemoveFormat,
        SourceEditing,
        SpecialCharacters,
        SpecialCharactersArrows,
        SpecialCharactersCurrency,
        SpecialCharactersEssentials,
        SpecialCharactersLatin,
        SpecialCharactersMathematical,
        SpecialCharactersText,
        StandardEditingMode,
        Strikethrough,
        Style,
        Subscript,
        Superscript,
        Table,
        TableCaption,
        TableCellProperties,
        TableColumnResize,
        TableProperties,
        TableToolbar,
        // TextPartLanguage,
        TextTransformation,
        Underline,
        WordCount
    ],
    toolbar: {
        items: [
            'undo',
            'redo',
            '|',
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'alignment',
            'fontColor',
            // 'fontFamily',
            'fontSize',
            'fontBackgroundColor',
            'outdent',
            'indent',
            '|',
            'imageInsert',
            // 'imageUpload',
            '|',
            'blockQuote',
            'insertTable',
            'mediaEmbed',

            'findAndReplace',
            '|',

            'code',
            'codeBlock',
            'removeFormat',
            '|',
            'highlight',
            'horizontalLine',
            'htmlEmbed',
            'pageBreak',
            'sourceEditing',
            'specialCharacters',
            'restrictedEditingException',
            'strikethrough',
            'style',
            'subscript',
            'superscript',
            'textPartLanguage',
            'todoList',
            'underline'
        ]
    },
    language: 'en-gb',
    image: {
        toolbar: [
            'imageTextAlternative',
            'toggleImageCaption',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            'linkImage'
        ]
    },
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableCellProperties',
            'tableProperties'
        ]
    }
};
