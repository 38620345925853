import { encode } from "blurhash";
import { useEffect, useState } from "react";
import { PropsEncode } from "../toasts/forms/forms-interfaces";
import isDevMode from "../../utils/check-dev-mode";



const encodeImageToBlurhash = async ({ file }: PropsEncode) => {

    const componentX = 4;
    const componentY = 4;

    const loadImage = async (src: string): Promise<HTMLImageElement> =>
        new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = (...args) => reject(args);
            img.src = src;
        });

    const getImageData = (image: HTMLImageElement, resolutionX: number, resolutionY: number) => {
        const canvas = document.createElement('canvas');
        canvas.width = resolutionX;
        canvas.height = resolutionY;
        const context = canvas.getContext('2d');
        context!.drawImage(image, 0, 0, resolutionX, resolutionY);
        return context!.getImageData(0, 0, resolutionX, resolutionY);
    };

    const getClampedSize = (
        width: number,
        height: number,
        max: number,
    ): { width: number; height: number } => {
        if (width >= height && width > max) {
            return { width: max, height: Math.round((height / width) * max) };
        }

        if (height > width && height > max) {
            return { width: Math.round((width / height) * max), height: max };
        }

        return { width, height };
    };

    try {
        let blurhash: string | undefined = undefined;


        await (async () => {
            const imageUrl = URL.createObjectURL(file);
            const img = await loadImage(imageUrl);
            const clampedSize = getClampedSize(img.width, img.height, 64);
            const imageData = getImageData(img, clampedSize.width, clampedSize.height);

            if (imageData) {

                blurhash = encode(
                    imageData.data,
                    imageData.width,
                    imageData.height,
                    componentX,
                    componentY,
                );

                return blurhash;
            }


        })();

        return blurhash;


    } catch (error) {
        console.log('encoded blur error', error)
    }

}

export { encodeImageToBlurhash }

