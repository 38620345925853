import { useNavigate } from "react-router-dom";
import useDataForTextEditor from "../../../editors/data-for-editor";
import { FormActionType } from "../../../models";
import useRoutePaths from "../../../routes/route-path";
import TableAddNewButton from "../../../shared-components/button/TableAddNewButton";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataForGalleryImagesFile from "../../../shared-components/images/data-gallery-images";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import useDataEventsGalleryForm from "../data/data-for-mandated-crops-form";
import TableMandatedCrops from "../tables/table-for-mandated-crops";

function ListMandatedCrops() {
  const { paths: route } = useRoutePaths();
  const navigation = useNavigate();
  const setFormActionType = useDataEventsGalleryForm(
    (state) => state.setFormActionType
  );
  const galleryImageReset = useDataForGalleryImagesFile((state) => state.reset);
  const coverImageReset = useDataForCoverImageFile((state) => state.reset);
  const editorReset = useDataForTextEditor((state) => state.reset);
  const resetForm = useDataEventsGalleryForm(
    (state) => state.reset
  );
  return (
    <div className="flex t-flex-col t-overflow-hidden t-justify-start t-h-fit t-rounded-lg t-shadow-lg t-m-10">
      <div className="">
        <CustomNavigation
          background={false}
          title="Mandated Crops"
          buttons={
            <TableAddNewButton label='Add New' onClick={() => {
              resetForm()
              coverImageReset()
              editorReset()
              galleryImageReset()
              setFormActionType(FormActionType.NEW);
              navigation(route.mandatedCrops.details);
            }} />
          }
        />
      </div>

      <TableMandatedCrops />
    </div>
  );
}

export default ListMandatedCrops;