import { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import useRoutePaths from "../../../routes/route-path";
import SectionHeader from "../../../shared-components/header/SectionHeader";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import { FormikProvider, useFormik } from "formik";
import useDataStaffProfileForm from "../data/data-for-staff-profiles-form";
import {
  CrigStaffGroup,
  CrigUnitDepartment,
  Folder,
  Page,
  S3UploadedItem,
  StaffBioTextEditor,
  StaffProfile,
} from "../../../models";
import { SchemaForStaffProfile } from "../form-schemas/form-schema-for-staff-profiles";
import FormErrorMsg from "../../../shared-components/form/ErrorMsg";
import { formatDateToIsoString, formatIsoStringToDate, selectPublishStatusType, slugify } from "../../../utils/forms/util-forms";
import FormActionButtonSet from "../../../shared-components/button/FormActionButtonSet";
import BioAccordion from "../../../shared-components/accordion/Accordion";
import { useServiceForDivisionsSubstationsAPI } from "../../page-settings/page-crig-divisions-sub-stations/services/services-for-divisions-sub-stations";
import useDataDivisionAndSubstationsList from "../../page-settings/page-crig-divisions-sub-stations/data/data-for-divisions-sub-stations-list";
import { PageDataInterfaceCRIGDivisionsSubstationsList } from "../../page-settings/page-crig-divisions-sub-stations/data/data-interface-for-divisions-sub-stations";
import { useStaffGroupingServiceAPI } from "../../page-settings/page-staff-groupings/services/services-for-staff-groupings";
import useDataStaffGroupList from "../../page-settings/page-staff-groupings/data/data-for-staff-groupings-list";
import { useCrigUnitDepartmentServiceAPI } from "../../page-settings/page-staff-unit-departments/services/services-for-staff-unit-departments";
import useDataStaffUnitDepartmentList from "../../page-settings/page-staff-unit-departments/data/data-for-staff-unit-departments-list";
import useDataCmsUsersForm from "../data/data-for-staff-profiles-form";
import useToaster from "../../../shared-components/toasts/Toaster";
import { FormActionType } from "../../../models";
import { useServiceForStaffProfileAPI } from "../services/services-for-staff-profiles";
import { Button } from "primereact/button";
import ErrorDialog from "../../../shared-components/form/ErrorDialog";
import LoadingDialog from "../../../shared-components/form/LoadingDialog";
import { Toast } from "primereact/toast";

import isDevMode from "../../../utils/check-dev-mode";

import { encodeImageToBlurhash } from "../../../shared-components/images/blurhash-images";
import useDataForProfileImageFile from "../../../shared-components/images/data-profile-image";
import { onUploadInterface, PropsEncode, StatusTypeSelect } from "../../../shared-components/toasts/forms/forms-interfaces";

import { ProgressSpinner } from 'primereact/progressspinner';
import { TbCameraPlus } from "react-icons/tb";
import { Image } from 'primereact/image';

import { Calendar } from "primereact/calendar";



interface Props {
  // imageFile?: File | Blob;
  s3Item?: S3UploadedItem | null;
  handleOnUploadCompleteFxn: (s3Item: S3UploadedItem) => Promise<boolean>;
}


function FormStaffProfile() {
  const refToast = useRef(null);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [doneFetchingDivisions, setDoneFetchingDivisions] = useState<boolean | null>(null);

  const [doneFetchingStaffGroups, setDoneFetchingStaffGroups] = useState<boolean | null>(null);
  const [doneFetchingStaffDpts, setDoneFetchingStaffDpts] = useState<boolean | null>(null);
  const [doneSettingBioData, setDoneSettingBioData] = useState<boolean | null>(null);

  const { showError, showSuccess } = useToaster();
  const [validateForm, setValidateForm] = useState<boolean>(false);
  const { addNewData, updateData } = useServiceForStaffProfileAPI();
  const setShowDialog = useDataCmsUsersForm((state) => state.setShowDialog);
  const { paths: route } = useRoutePaths();
  // const [file, setFile] = useState<any>();

  const formData = useDataStaffProfileForm((state) => state.formData);

  //here
  const [file, setFileToUpload] = useState<File | null>(null);
  const [fileToUploadURL, setFileToUploadURL] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean | null>(null);
  const onUpload = useDataForProfileImageFile((state) => state.onUpload);
  const getS3URL = useDataForProfileImageFile((state) => state.getS3URL);
  const profileImageS3Item = useDataForProfileImageFile((state) => state.newS3Item);
  const setProfileImageS3Item = useDataForProfileImageFile((state) => state.setNewS3Item);
  const [encodedBlurhash, setEncodedBlurHash] = useState<string | null>(null);
  const refToast2 = useRef<any>(null);
  // const [staffProfileId, setStaffProfileId] = useState('');


  const [bioDataState, setBioDataState] = useState<StaffBioTextEditor[]>([]);

  let handleAddNewBiography = (e: any) => {

    e.preventDefault();

    setBioDataState([...bioDataState, { title: "", body: "" }]);
  };

  let handleChangeBio = (i: number, e: any, isTitle: boolean) => {

    if (isDevMode()) {
      console.log("bioDataState", i, e, isTitle);
    }

    if (isTitle) {
      let newFormValues = [...bioDataState];
      newFormValues[i][e.target.name] = e.target.value;
      setBioDataState(newFormValues);
      return;
    }
    let newFormValues = [...bioDataState];
    let newData = newFormValues[i];
    newData = { ...newData, body: e };
    newFormValues[i] = newData;

    if (isDevMode()) {
      console.log("new bio", newFormValues[i]);
    }
    setBioDataState(newFormValues);
    if (isDevMode()) {
      console.log("newFormValues", newFormValues);
    }
  };

  let removeFormFields = (i) => {
    let newFormValues = [...bioDataState];
    newFormValues.splice(i, 1);
    setBioDataState(newFormValues);
  };

  //Hooks and services for listing CrigDivisionSubstation
  const crigDivisionSubstationList = useDataDivisionAndSubstationsList(
    (state) => state.listItems
  );
  const listItemsFromDivisionsSubstationsService =
    useServiceForDivisionsSubstationsAPI().listAllData;

  const [
    selectedCrigDivisionSubstation,
    setSelectedCrigDivisionSubstation,
  ] = useState<Page | null | false>(null);

  //Hooks and services for listing CrigStaffGroup
  const staffGroupList = useDataStaffGroupList((state) => state.listItems);

  const listItemsFromCrigStaffGroupService =
    useStaffGroupingServiceAPI().listAllData;

  const [selectedGroup, setSelectedGroup] = useState<CrigStaffGroup | null | boolean>(
    null
  );

  //Hooks and services for listing UnitDepartment
  const unitDepartmentList = useDataStaffUnitDepartmentList(
    (state) => state.listItems
  );

  const listItemsFromUnitDepartmentService =
    useCrigUnitDepartmentServiceAPI().listAllData;

  const [selectedUnitDepartment, setSelectedUnitDepartment] =
    useState<CrigUnitDepartment | null | boolean>(null);

  const [formItemStatus, setFormItemStatus] = useState<StatusTypeSelect | null>(
    selectPublishStatusType[0]
  );

  const formAction = useDataCmsUsersForm((state) => state.formActionType);
  const setFormActionType = useDataCmsUsersForm(
    (state) => state.setFormActionType
  );

  async function saveForm(values: StaffProfile) {
    let newItem: StaffProfile;

    switch (formAction ?? FormActionType.NEW) {
      case FormActionType.NEW:
        newItem = new StaffProfile({
          profileImage: profileImageS3Item,
          nameFirst: values.nameFirst,
          nameLast: values.nameLast,
          nameFull: values.nameFull,
          crigStaffGroupID: values.crigStaffGroupID,
          position: values.position,
          crigUnitDepartmentID: values.crigUnitDepartmentID,
          crigDivisionSubStationID: values.crigDivisionSubStationID,
          slug: values.slug,
          deleted: false,
          dateAdded: formatDateToIsoString(values.dateAdded!)!,
          dateUpdated: new Date().toISOString(),
          status: values.status,
          totalViewsCount: 0,
          bioDesignTextEditors: bioDataState,
        });

        newItem = { ...newItem, id: values.id }

        await addNewData(newItem)
          .then((done) => {

            if (done) {
              showSuccess(refToast, 'ATTENTION', 'Successfully saved.');
              setFormActionType(FormActionType.EDIT)
              formFormik.setSubmitting(false);
              console.log('done addNewData');
            } else {
              console.log('err submitting addNewData',);
              showError(refToast, 'ATTENTION', 'Something went wrong, kindly try again.');
              setShowErrorDialog(true);
              formFormik.setSubmitting(false);
            }


          })
          .catch((err) => {
            console.log("err submitting addNewAboutUs", err);
            showError(
              refToast,
              "ATTENTION",
              "Something went wrong, kindly try again."
            );
            setShowErrorDialog(true);
            formFormik.setSubmitting(false);
          });
        break;

      case FormActionType.EDIT:
        newItem = {
          ...values,
          profileImage: profileImageS3Item,
          crigDivisionSubStationID: values.crigDivisionSubStationID,
          crigStaffGroupID: values.crigStaffGroupID,
          crigUnitDepartmentID: values.crigUnitDepartmentID,
          deleted: false,
          position: values.position,
          dateAdded: formatDateToIsoString(values.dateAdded!)!,
          dateUpdated: new Date().toISOString(),
          status: values.status,
          bioDesignTextEditors: bioDataState,
        }
        await updateData(newItem, false)
          .then(async (done) => {
            showSuccess(refToast, "ATTENTION", "Successfully saved.");
            formFormik.setSubmitting(false);
          })
          .catch((error) => {
            console.log("err submitting staffUnits", error);
            showError(
              refToast,
              "ATTENTION",
              "Something went wrong, kindly try again."
            );
            setShowErrorDialog(true);
            formFormik.setSubmitting(false);
          });
        break;

      default:
        break;
    }
  }

  const formFormik = useFormik({
    initialValues: formData as StaffProfile,
    validateOnChange: validateForm,
    enableReinitialize: true,
    validationSchema: SchemaForStaffProfile,
    validateOnBlur: false,
    validate: (values) => {
      setValidateForm(true);
      console.log("validate", values);
      console.log("errors", formFormik.errors);
    },

    onSubmit: async (values) => {
      // const submittedValues = {
      //   ...values,
      //   bioDesignTextEditors: bioDataState,
      // };
      console.log("submit", values);
      await saveForm(values);
    },
  });

  const refNameFirst = useRef(null);
  const refNameLast = useRef(null);
  const refNameFull = useRef(null);
  const refCrigDivisionSubstation = useRef(null);
  const refCrigUnitDepartment = useRef(null);
  const refPosition = useRef(null);
  const refStaffGroup = useRef(null);
  const refSlug = useRef(null);
  const refDatePublished = useRef(null);
  const refStatus = useRef(null);

  const getStatus = (value: string) => {
    function findIndexFxn(item: StatusTypeSelect) {
      return item.value === value;
    }
    const index = selectPublishStatusType.findIndex(findIndexFxn);

    const item = selectPublishStatusType[index];

    return item;
  };

  const fieldLabels = {
    nameFirst: {
      id: "nameFirst",
      ref: refNameFirst,
      label: "First Name",
    },
    nameLast: {
      id: "nameLast",
      ref: refNameLast,
      label: "Last Name",
    },
    nameFull: {
      id: "nameFull",
      ref: refNameFull,
      label: "Full Name",
    },
    crigDivisionSubstation: {
      id: "crigDivisionSubStationID",
      ref: refCrigDivisionSubstation,
      label: "CRIG Division/Sub Station (optional)",
    },
    crigUnitDepartment: {
      id: "crigUnitDepartmentID",
      ref: refCrigUnitDepartment,
      label: "Unit/Department  (optional)",
    },
    position: {
      id: "position",
      ref: refPosition,
      label: "Position",
    },
    slug: {
      id: "slug",
      ref: refSlug,
      label: "Slug",
    },
    crigStaffGroup: {
      id: "crigStaffGroupID",
      ref: refStaffGroup,
      label: "Staff Group",
    },
    dateAdded: {
      id: "dateAdded",
      ref: refDatePublished,
      label: "Date Published",
    },

    status: {
      id: "status",
      ref: refStatus,
      label: "Status",
    },
  };

  const fieldDatePublished = (
    <div className="  form-field t-w-full date-pick">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.dateAdded.label}
      </p>
      <Calendar
        id={fieldLabels.dateAdded.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        dateFormat="dd/mm/yy"
        disabled={formFormik.isSubmitting}
        value={formatIsoStringToDate(formFormik.values.dateAdded!)}
        ref={fieldLabels.dateAdded.ref}
        className="w-full p-input-filled"
        showIcon
        iconPos="right"
      />
      <FormErrorMsg
        fieldId={fieldLabels.dateAdded.id}
        errorMsg={formFormik.errors.dateAdded}
      />
    </div>
  );

  const fieldStatus = (
    <div className="t-w-full form-field">
      <p className="t-text-appgrey-800 t-mt-8 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.status.label}
      </p>
      <Dropdown
        className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
        options={selectPublishStatusType}
        optionLabel="name"
        placeholder="Select"
        id={fieldLabels.status.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        disabled={formFormik.isSubmitting}
        value={formItemStatus?.value}
        ref={fieldLabels.status.ref}
      />
      <FormErrorMsg
        fieldId={fieldLabels.status.id}
        errorMsg={formFormik.errors.status}
      />
    </div>
  );

  function handleChange(e: any) {
    let image: File = e.target.files[0];

    if (image) {

      if (image.size > 700000) {
        refToast2.current.show({
          severity: "error",
          summary: "ATTENTION",
          detail: "File size is larger than 700KB",
        });
        return;
      }

      //setEncodedBlurHash(null)
      setIsUploading(null)
      setFileToUpload(image)
      setFileToUploadURL(URL?.createObjectURL(e.target.files[0]))


    }
  }

  useEffect(() => {


    if (formData?.profileImage != null && profileImageS3Item == null) {
      formFormik.setFieldValue('profileImage', profileImageS3Item);
      setProfileImageS3Item(formData.profileImage);
    }



    return () => { };
  }, [profileImageS3Item, formData?.profileImage]);

  useEffect(() => {
    setFormItemStatus(getStatus(formFormik.values.status ?? ""));
    return () => { };
  }, [formFormik.values.status]);

  useEffect(() => {
    const fetchData = async () => {
      await listItemsFromDivisionsSubstationsService();
      setDoneFetchingDivisions(true)
    }

    if (crigDivisionSubstationList.length == 0 && doneFetchingDivisions == null) {
      fetchData();
    }
    return () => {

    };
  }, [crigDivisionSubstationList, doneFetchingDivisions]);

  useEffect(() => {

    const fetchData = async () => {
      await listItemsFromCrigStaffGroupService();
      setDoneFetchingStaffGroups(true)
    }

    if (staffGroupList.length == 0 && doneFetchingStaffGroups == null) {
      fetchData();
    }
    return () => { };
  }, [doneFetchingStaffGroups, staffGroupList]);


  // useEffect(() => {
  //   if (staffProfileId.length == 0) {
  //     if ((formData?.id ?? "").length > 0) {
  //       setStaffProfileId(formData!.id);
  //     } else {
  //       setStaffProfileId(uuid());
  //     }
  //   }
  //   return () => {
  //     if (staffProfileId.length != 0) return;
  //   };
  // }, [staffProfileId, formData]);


  useEffect(() => {
    let uploading = true;

    const uploadImage = async () => {
      // let file = file as File;
      let info: onUploadInterface = {
        file: file!,
        encodedBlurhash: encodedBlurhash,
        staffProfileId: formFormik.values.id,
      }


      setIsUploading(true)

      await onUpload(info).then(async (doneUploading) => {

        if (doneUploading?.newS3Item != null) {
          // await handleOnUploadCompleteFxn(doneUploading?.newS3Item);
          setIsUploading(false)
          setFileToUpload(null)
          setEncodedBlurHash(null)
        }

        setIsUploading(false)
        setFileToUpload(null)
        setEncodedBlurHash(null)

      }).catch((e) => {
        setIsUploading(false)
        setFileToUpload(null)
        setEncodedBlurHash(null)
      });
    }

    if (isUploading == null && encodedBlurhash != null) {
      uploadImage()
        .catch((e) => {
          if (isDevMode()) {
            console.log("err uploadImage is", e);
          }
          setIsUploading(false)
        });
    }

    return () => {
      uploading = false
    }
  }, [encodedBlurhash, file, isUploading, onUpload])

  useEffect(() => {

    let uploading = true;

    const encodeURL = async () => {
      let blurInfo: PropsEncode = {
        file: file!

      }
      const newEncoded = await encodeImageToBlurhash(blurInfo)

      if (newEncoded != null || newEncoded != undefined) {
        setEncodedBlurHash(newEncoded)
        if (isDevMode()) {
          console.log('newEc', newEncoded)
        }

        // await uploadImage(newEncoded)
      }
    }


    if (file != null || file != undefined) {
      encodeURL();
    }

    return () => {
      uploading = false
    }
  }, [file, onUpload])

  useEffect(() => {

    let uploading = true;

    const fetchURL = async () => {
      let url = await getS3URL(profileImageS3Item!.key!)

      if (isDevMode()) {
        console.log('url', url)
      }

      setFileToUploadURL(url);
      setIsUploading(false)
    }


    if (((profileImageS3Item?.key) ?? ''.length > 0) && fileToUploadURL == null) {
      setIsUploading(true)
      fetchURL();
    }

    return () => {
      uploading = false
    }
  }, [fileToUploadURL, getS3URL, profileImageS3Item])

  //


  useEffect(() => {

    const fetchData = async () => {
      await listItemsFromUnitDepartmentService();
      setDoneFetchingStaffDpts(true)
    }

    if (unitDepartmentList.length == 0 && doneFetchingStaffDpts == null) {
      fetchData();
    }

    return () => { };
  }, [unitDepartmentList, doneFetchingStaffDpts]);




  useEffect(() => {
    let crigDivisionSubStationID = formFormik.values.crigDivisionSubStationID ?? '';

    if (selectedCrigDivisionSubstation == null &&
      crigDivisionSubstationList.length > 0 && crigDivisionSubStationID.length > 0) {


      const foundItem = crigDivisionSubstationList.find(({ id }) => id === crigDivisionSubStationID);

      if (isDevMode()) {
        console.log('foundItem', foundItem)
      }

      if ((foundItem?.id ?? '').length > 0) {
        setSelectedCrigDivisionSubstation(foundItem!)
      }
    }

    return () => { };
  }, [formFormik.values.crigDivisionSubStationID, crigDivisionSubstationList, selectedCrigDivisionSubstation]);


  useEffect(() => {
    let crigStaffGroupID = formFormik.values.crigStaffGroupID ?? '';
    if (selectedGroup == null &&
      staffGroupList.length > 0 && crigStaffGroupID.length > 0) {


      const foundItem = staffGroupList.find(({ id }) => id === crigStaffGroupID);

      if (isDevMode()) {
        console.log('foundItem selectedGroup', foundItem)
      }

      if ((foundItem?.id ?? '').length > 0) {
        setSelectedGroup(foundItem!)
      }
    }

    return () => { };
  }, [formFormik.values.crigStaffGroupID, staffGroupList, selectedGroup]);


  useEffect(() => {
    let crigUnitDepartmentID = formFormik.values.crigUnitDepartmentID ?? '';
    if (selectedUnitDepartment == null &&
      unitDepartmentList.length > 0 && crigUnitDepartmentID.length > 0) {


      const foundItem = unitDepartmentList.find(({ id }) => id === crigUnitDepartmentID);

      if (isDevMode()) {
        console.log('foundItem selectedUnitDepartment', foundItem)
      }

      if ((foundItem?.id ?? '').length > 0) {
        setSelectedUnitDepartment(foundItem!)
      }
    }

    return () => { };
  }, [formFormik.values.crigUnitDepartmentID, unitDepartmentList, selectedUnitDepartment]);


  useEffect(() => {

    if (((formFormik.values.bioDesignTextEditors) ?? [].length > 0) && doneSettingBioData == null) {

      if (isDevMode()) {
        console.log('formFormik.values.bioDesignTextEditors', formFormik.values.bioDesignTextEditors)
      }

      setBioDataState(formFormik.values.bioDesignTextEditors as StaffBioTextEditor[])
      setDoneSettingBioData(true)

    }

    return () => { };
  }, [formFormik.values.bioDesignTextEditors, bioDataState, doneSettingBioData]);

  useEffect(() => {
    const fullName =
      formFormik.values.nameFirst + " " + formFormik.values.nameLast;
    formFormik.setFieldValue("nameFull", fullName);

    return () => { };
  }, [formFormik.values.nameFirst, formFormik.values.nameLast]);


  useEffect(() => {
    if (
      formFormik.values.nameFirst?.length != 0 ||
      formFormik.values.nameLast?.length != 0
    ) {
      const slugged = slugify(
        formFormik.values.nameFirst! + " " + formFormik.values.nameLast!
      );

      if (isDevMode()) {
        console.log("slugged", slugged);
      }

      formFormik.setFieldValue("slug", slugged);
    }

    return () => { };
  }, [formFormik.values.nameFirst, formFormik.values.nameLast]);

  const firstName = (
    <div className="t-w-1/2 t-mr-4 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.nameFirst.label}
      </p>
      <span className="p-input-icon-left w-full p-input-filled">
        <i className="pi pi-user pi-user" />
        <InputText
          id={fieldLabels.nameFirst.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.nameFirst ?? ""}
          ref={fieldLabels.nameFirst.ref}
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.nameFirst.id}
        errorMsg={formFormik.errors.nameFirst}
      />
    </div>
  );

  const empty = <div className="t-w-full t-ml-8"></div>;
  const lastName = (
    <div className="t-w-1/2 t-ml-4 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.nameLast.label}
      </p>
      <span className="p-input-icon-left w-full p-input-filled">
        <i className="pi pi-user pi-user" />
        <InputText
          id={fieldLabels.nameLast.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.nameLast ?? ""}
          ref={fieldLabels.nameLast.ref}
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.nameLast.id}
        errorMsg={formFormik.errors.nameLast}
      />
    </div>
  );

  const crigDivisionsSubStations = (
    <div className="t-w-full t-mr-0 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.crigDivisionSubstation.label}
      </p>

      <div className="t-flex t-items-center t-justify-center t-gap-2">
        <Dropdown
          className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
          options={crigDivisionSubstationList}

          optionLabel="title"
          placeholder="Select"
          id={fieldLabels.crigDivisionSubstation.id}

          onChange={(e) => {
            const selectedValue = e.value as Page;
            setSelectedCrigDivisionSubstation(selectedValue);
            formFormik.setFieldValue(
              "crigDivisionSubStationID",
              selectedValue.id
            );
          }}

          onBlur={formFormik.handleBlur}
          disabled={formFormik.isSubmitting}
          value={selectedCrigDivisionSubstation}
          ref={fieldLabels.crigDivisionSubstation.ref}
        />
        <Button icon="pi pi-times" type="button" onClick={() => {
          formFormik.setFieldValue('crigDivisionSubStationID', 'none')
          setSelectedCrigDivisionSubstation(false)
        }} className="t-flex-grow !t-w-[30px] !t-h-[30px]" rounded outlined severity="danger" aria-label="Cancel" />
      </div>


    </div>
  );

  const staffGroup = (
    <div className="t-w-full t-mr-0 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.crigStaffGroup.label}
      </p>
      <div className="t-flex t-items-center t-justify-center t-gap-2">
        <Dropdown
          className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
          options={staffGroupList}

          optionLabel="name"
          placeholder="Select"
          id={fieldLabels.crigStaffGroup.id}
          onChange={(e) => {
            const selectedCrigStaffGroupID = e.value;
            setSelectedGroup(selectedCrigStaffGroupID);
            formFormik.setFieldValue(
              "crigStaffGroupID",
              selectedCrigStaffGroupID.id
            );
          }}
          onBlur={formFormik.handleBlur}
          disabled={formFormik.isSubmitting}
          value={selectedGroup}
          ref={fieldLabels.crigStaffGroup.ref}
        />
        <Button icon="pi pi-times" type="button" onClick={() => {
          formFormik.setFieldValue('crigStaffGroupID', 'none')
          setSelectedGroup(false)
        }} className="t-flex-grow !t-w-[30px] !t-h-[30px]" rounded outlined severity="danger" aria-label="Cancel" />
      </div>

    </div>
  );

  const unitDepartment = (
    <div className="t-w-full t-mr-0 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.crigUnitDepartment.label}
      </p>
      <div className="t-flex t-items-center t-justify-center t-gap-2">

        <Dropdown
          className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
          options={unitDepartmentList}
          optionLabel="name"
          placeholder="Select"
          id={fieldLabels.crigUnitDepartment.id}
          onChange={(e) => {
            const selectedUnitDepartmentID = e.value;
            setSelectedUnitDepartment(selectedUnitDepartmentID);
            formFormik.setFieldValue(
              "crigUnitDepartmentID",
              selectedUnitDepartmentID.id
            );
          }}
          onBlur={formFormik.handleBlur}
          disabled={formFormik.isSubmitting}
          value={selectedUnitDepartment}
          ref={fieldLabels.crigUnitDepartment.ref}
        />
        <Button icon="pi pi-times" type="button" onClick={() => {
          formFormik.setFieldValue('crigUnitDepartmentID', 'none')
          setSelectedUnitDepartment(false)
        }} className="t-flex-grow !t-w-[30px] !t-h-[30px]" rounded outlined severity="danger" aria-label="Cancel" />
      </div>
    </div>
  );


  const position = (
    <div className="t-w-full t-mr-0 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.position.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.position.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.position ?? ""}
          ref={fieldLabels.position.ref}
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.position.id}
        errorMsg={formFormik.errors.position}
      />
    </div>
  );

  function FileInputContainer() {

    return (
      <div className="t-flex-col t-items-center t-justify-center">

        <div className='t-bg-[#b5bac4]/25 t-w-[200px] t-h-[250px] t-rounded-md t-relative t-overflow-hidden t-m-2 t-border-4 t-border-white/30 t-mt-5 t-mb-[40px]'>

          <div className='p-ripple t-top-[50%] t-left-[50%] t-h-[50px] t-w-[50px]  hover:t-bg-yellow-400 t-group t-transition-all t-ease-in-out t-duration-[3ms] black-ripple 
           t-cursor-pointer t-translate-x-[-50%] profile-img-btn t-translate-y-[-50%] t-absolute t-z-[2] hover:t-cursor-pointer group-hover:t-cursor-pointer t-bg-black/20 t-rounded-full t-flex-col t-flex t-justify-center t-items-center t-text-appgrey-400 no-image'>
            <input
              className="t-h-[50px]  t-w-[50px] t-opacity-1 t-top-0 t-left-0 t-absolute hover:t-cursor-pointer group-hover:t-cursor-pointer"
              id="photo-upload"
              type="file"
              placeholder=""
              accept="image/png, image/jpg, image/jpeg"
              onChange={handleChange}
            />
            <TbCameraPlus className="t-text-yellow-300 group-hover:t-text-appgreen-700 t-transition-all t-ease-in-out t-duration-[3ms]" size={28} />
            {isUploading && <ProgressSpinner className="!t-h-[56px] !t-w-[56px] !t-absolute t-z-[3]" />}
          </div>
          {(fileToUploadURL ?? '').length > 0 &&
            <Image
              src={fileToUploadURL!}
              alt="Image"
              imageStyle={{ height: "100%", objectFit: 'cover', objectPosition: 'center' }}
              className='t-absolute t-z-[1] t-h-full t-object-cover'
            />
            // <img src={fileToUploadURL!}
            //     alt="Image"
            //    className='absolute z-[1] object-cover' 
            //    />
          }


          {/* {((s3Item?.blurHash != null && s3Item?.blurHash.length >= 6)) && hasPlaceholder &&
                    <Blurhash
                        hash={s3Item?.blurHash}
                        width={'150px'}
                        height={'150px'}
                        resolutionX={32}
                        resolutionY={32}
                        className={'!contents object-cover'}
                        punch={1}
                    />
                } */}
        </div>
      </div>

    );
  }


  return (
    <div className="t-w-full  t-m-auto t-bg-appmainview">
      <FormikProvider value={formFormik}>
        <form className="main-form" onSubmit={formFormik.handleSubmit}>
          <CustomNavigation
            title={`${formAction == FormActionType.NEW
              ? "Add Staff Details"
              : formAction == FormActionType.EDIT
                ? "Update Staff Details"
                : "Add Staff Details"
              }`}

            background={true}
            useWhiteBackButton={true}
            backArrow={true}
            maxWidth={"t-max-w-[96rem]"}
            titleFont={"t-font-inter"}
            titleSize={"t-text-24px"}
            titleFontWeight={"t-font-medium"}
            yPadding={"t-py-1"}
            buttons={
              <FormActionButtonSet
                onPressedSave={() => { }}
                showSave={true}
                showPreview={false}
                saveBtnWidth={"t-w-[150px]"}
                hasPageBuilders={false}
              />
            }
            backArrowNavUrl={route.crigStaff.list}
          />
          <div
            className={` 
            t-transition t-duration-150 t-ease-out t-h-full 
            t-overflow-auto md:t-max-w-[70rem] 
            t-bg-white t-mx-auto t-flex-col 
            t-font-inter
            t-rounded-lg t-p-2  t-my-0 md:t-my-5 md:t-p-[2.25rem] md:t-max-h-[88vh]  t-pb-[100px]`}
          >
            <div className="t-w-full t-mb-52">
              <div>
                {/*Basic Details From */}
                <SectionHeader title="Basic Details" />

                <div className="flex t-w-full t-items-center t-justify-center mt-4">

                  <FileInputContainer />
                  <Toast ref={refToast2} position="top-right"></Toast>
                </div>

                <div className="t-mb-10">
                  <div className="flex t-w-full">
                    {firstName}
                    {lastName}
                  </div>
                  <div className="flex t-w-full">{empty}</div>
                </div>
                <div>
                  {/* Division Details*/}
                  <SectionHeader title="Division Details" />
                  <div className="t-mb-10">
                    <div className="flex t-w-full t-gap-8">
                      {position}
                      {crigDivisionsSubStations}

                      {/* {empty} */}
                    </div>

                    <div className="flex t-w-full t-gap-8">
                      {staffGroup}
                      {unitDepartment}

                      {/* {empty} */}
                    </div>


                    <div className="mt-6">
                      {/* Dates and  Status*/}
                      <SectionHeader title="Date / Status" />
                      <div className="t-mb-10">
                        <div className="flex t-w-full t-justify-evenly t-items-center t-gap-10">
                          {fieldDatePublished}
                          {fieldStatus}
                        </div>
                      </div>
                    </div>

                    <div className="mt-6 ">
                      {/* Biography*/}
                      <div className="flex t-items-center t-gap-4 t-mb-[13px] t-justify-between">
                        <div>
                          <Button
                            label="ADD"
                            icon='pi pi-plus'
                            className="t-font-bold t-min-w-[100px]"
                            onClick={handleAddNewBiography}
                          />
                        </div>

                        <div className="t-flex-grow">
                          <SectionHeader title="Biography" />
                        </div>




                      </div>

                      <div></div>
                      <BioAccordion
                        removeFormFields={removeFormFields}
                        bioData={bioDataState}
                        onChange={handleChangeBio}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ErrorDialog
            show={showErrorDialog}
            errorMsg={"Something went wrong, kindly try again."}
            onHide={() => {
              setShowErrorDialog(false);
            }}
          />
          <LoadingDialog show={formFormik.isSubmitting} />
          <Toast ref={refToast} position="top-right"></Toast>
        </form>
      </FormikProvider>
    </div >
  );
}

export default FormStaffProfile;
