export const createUserRoleGQL = /* GraphQL */ `
  mutation CreateUserRole(
    $input: CreateUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    createUserRole(input: $input, condition: $condition) {
      _deleted
      deleted
      _lastChangedAt
      _version
      createdAt
      creatorUserAccountID
      dateAdded
      dateUpdated
      id
      assignedAccess {
        id
        menuItemTitle
        operations
        routePath
        tableName
        userRoleID
      }
      moreDetailsTextEditor
      shortDescription
      status
      title
      updatedAt
    }
  }
`;

export const updateUserRoleGQL = /* GraphQL */ `
  mutation UpdateUserRole(
    $input: UpdateUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    updateUserRole(input: $input, condition: $condition) {
      _deleted
      deleted
      _lastChangedAt
      _version
      createdAt
      creatorUserAccountID
      dateAdded
      dateUpdated
      id
      assignedAccess {
        id
        menuItemTitle
        operations
        routePath
        tableName
        userRoleID
      }
      moreDetailsTextEditor
      shortDescription
      status
      title
      updatedAt
    }
  }
`;

export const listUserRolesGQL = /* GraphQL */ `
  query ListUserRoles(
    $filter: ModelUserRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      startedAt
      items {
        _deleted
        deleted
        _lastChangedAt
        _version
        createdAt
        assignedAccess {
          id
          menuItemTitle
          operations
          routePath
          tableName
          userRoleID
        }
        # creatorUserAccount {
        #   nextToken
        #   startedAt
        # }
        creatorUserAccountID
        dateAdded
        dateUpdated
        id
        moreDetailsTextEditor
        shortDescription
        status
        title
        updatedAt
        userAccounts {
          nextToken
          startedAt
        }
      }
    }
  }
`;

export const deleteUserRole = /* GraphQL */ `
  mutation DeleteUserRole(
    $input: DeleteUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    deleteUserRole(input: $input, condition: $condition) {
      _deleted
      deleted
      _lastChangedAt
      _version
      createdAt
      creatorUserAccountID
      dateAdded
      dateUpdated
      id
      moreDetailsTextEditor
      shortDescription
      status
      title
      updatedAt
    }
  }
`;
