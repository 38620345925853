import produce from "immer";
import { create } from "zustand";
import { CrigUnitDepartment } from "../../../../models";
import { PageDataInterfaceStaffUnitDepartmentForm } from "./data-interface-for-staff-unit-departments";

const defaultData = {
  name: "",
  shortDescription: "",
  slug: "",
  datePublished: new Date().toISOString(),
  dateUploaded: new Date().toISOString(),
};

const useDataStaffUnitDepartmentForm =
  create<PageDataInterfaceStaffUnitDepartmentForm>()((set, get) => {
    return {
      showDialog: false,
      setFormData: async (item) => {
        set((_) => ({ formData: item }));
        return true;
      },
      formActionType: undefined,
      setShowDialog: async (value) => {
        set((_) => ({ showDialog: value }));
        return true;
      },
      formData: defaultData as unknown as CrigUnitDepartment,
      setFormActionType: async (item) => {
        set((_) => ({ formActionType: item }));
        return true;
      },
      reset: async () => {
        set((_) => ({
          formData: defaultData as unknown as CrigUnitDepartment,
        }));
        return true;
      },
      updateFormDataField: async (property, value) => {
        set(
          produce((state: PageDataInterfaceStaffUnitDepartmentForm) => {
            state.formData![property] = value;
          })
        );
        return true;
      },
    };
  });

export default useDataStaffUnitDepartmentForm;
