import produce from "immer";
import { create } from "zustand";
import { DataLoadingState, StaffProfile } from "../../../models";
import isDevMode from "../../../utils/check-dev-mode";
import { PageDataInterfaceStaffProfileList } from "./data-interface-for-staff-profiles";

const useDataStaffProfileList = create<PageDataInterfaceStaffProfileList>()(
  (set, get) => {
    return {
      statusForLoadingListItems: DataLoadingState.PENDING as
        | DataLoadingState
        | undefined,
      listItems: [],

      setStatusForLoadingListItems: async (newValue) => {
        set((_) => ({ statusForLoadingListItems: newValue }));
        return true;
      },
      setListItems: async (items) => {
        set((_) => ({ listItems: items }));
        return true;
      },
      updateItem: async (newItem) => {
        let newItemRecord = newItem as StaffProfile;
        set(
          produce((state: PageDataInterfaceStaffProfileList) => {

            function findIndexFxn(item: StaffProfile) {
              return item.id === newItemRecord.id;
            }
            const index = state.listItems.findIndex(findIndexFxn);

            if (isDevMode()) {
              console.log('updateItem staffpro index', index, newItemRecord,)
            }

            if (index != -1) {

              let oldItem = state.listItems[index];

              // if((newItemRecord.crigDivisionSubStationID ?? '').length > 0 
              // || (newItemRecord.crigDivisionSubStationID ?? '') !=null){
              //   newItemRecord = {
              //     ...newItemRecord,
              //     crigDivisionSubStation: oldItem.crigDivisionSubStation,
              //   };
              // }

              // if((newItemRecord.crigStaffGroupID ?? '').length > 0 
              // || (newItemRecord.crigStaffGroupID ?? '') !=null){
              //   newItemRecord = {
              //     ...newItemRecord,
              //     crigStaffGroup: oldItem.crigStaffGroup,
              //   };
              // }

              // newItemRecord = {
              //   ...newItemRecord,
              //   crigDivisionSubStation: oldItem.crigDivisionSubStation,
              //   crigStaffGroup: oldItem.crigStaffGroup,
              //   crigUnitDepartment: oldItem.crigUnitDepartment,
              // };

              // if (isDevMode()) {
              //   console.log('updateItem staffpro newItemRecord', index, newItemRecord,)
              // }

              state.listItems[index] = newItemRecord;
            }
          })
        );

        return true;
      },

      deleteItem: async (newItem) => {
        set(
          produce((state: PageDataInterfaceStaffProfileList) => {
            function findIndexFxn(item: StaffProfile) {
              return item.id === newItem.id;
            }

            const index = get().listItems.findIndex(findIndexFxn);
            state.listItems.splice(index, 1);
          })
        );

        return true;
      },

      addItem: async (newItem) => {
        let newItemRecord = newItem as StaffProfile;
        set(
          produce((state: PageDataInterfaceStaffProfileList) => {
            state.listItems.unshift(newItemRecord);
          })
        );

        return true;
      },
    };
  }
);

export default useDataStaffProfileList;
