const useRoutePaths = () => {
  const paths = {
    splash: "/",
    authSignIn: "/sign-in",
    auth2fa: "/confirm-sign-in",
    dashboard: "/dashboard",
    pageBuilder: "/page-builder",
    notifications: "/notifications",
    editorSwitch: "/editor-switch",
    editorSwitchLayoutSelection: "/editor-switcher-layout-selection",
    sidePop: "/side-pop",
    homeSlider: {
      details: "/home-slides-details",
      list: "/home-slides",
    },
    articlesPublications: {
      details: "/articles-publications-details",
      list: "/articles-publications",
    },
    contentFollowers: {
      details: "/contentFollowers-details",
      list: "/contentFollowers",
    },
    cmsUsers: {
      details: "/cms-users-details",
      list: "/cms-users",
    },
    eventsGallery: {
      details: "/events-gallery-details",
      list: "/events-gallery",
    },
    mandatedCrops: {
      details: "/mandated-crops-details",
      list: "/mandated-crops",
    },
    externalServices: {
      details: "/external-services-details",
      list: "/external-services",
    },
    newsAnnouncements: {
      details: "/news-announcements-details",
      list: "/news-announcements",
    },
    newsletters: {
      details: "/newsletter-details",
      list: "/newsletters",
    },
    products: {
      details: "/product-details",
      list: "/product-list",
    },
    researchActivities: {
      details: "/research-activities-details",
      list: "/research-activities",
    },
    recreationalFacilities: {
      details: "/recreation-facilities-details",
      list: "/recreational-facilities",
    },
    crigStaff: {
      details: "/crig-staff-details",
      list: "/crig-staff",
    },
    fileManager: {
      details: "/file-manager-details",
      list: "/file-manager",
      listFolder: "/folder-list",
      listFiles: "/file-list",
    },
    settings: {
      initial: "/settings",
      mainSubCategories: {
        details: "/settings/main-sub-categories-details",
        list: "/settings/main-sub-categories",
      },
      userRoles: {
        details: "/settings/user-roles-details",
        list: "/settings/user-roles",
      },
      crigStaffGroups: {
        details: "/settings/crig-staff-groups-details",
        list: "/settings/crig-staff-groups",
      },
      crigDivisionsSubStations: {
        details: "/settings/crig-divisions-sub-stations-details",
        list: "/settings/crig-divisions-sub-stations",
      },
      crigStaffUnitDepartments: {
        details: "/settings/crig-staff-unit-departments-details",
        list: "/settings/crig-staff-unit-departments",
      },
      pageTemplates: {
        details: "/settings/page-templates-details",
        list: "/settings/page-templates",
      },
    },

    helpCenter: {
      faq: "/help-center",
      support: "/help-center/support",
    },

    aboutUs: {

      details: "/about-us-details",
      list: "/about-us",
    },
    profile: {
      initial: "my-profile",
    },
    externalLinks: {
      chatLogs: "https://dashboard.tawk.to/#/dashboard",
      siteAnalytics:
        "https://analytics.google.com/analytics/web/?authuser=2#/p346897316/reports/intelligenthome",
    },
  };

  return {
    paths,
  };
};

export default useRoutePaths;
