

const useToaster = () => {
    function showSuccess(ref: any, summary: string, detail: string,) {
        ref.current.show({ severity: 'success', summary: `${summary}`, detail: `${detail}` });
    }

    function showInfo(ref: any, summary: string, detail: string,) {
        ref.current.show({ severity: 'info', summary: `${summary}`, detail: `${detail}` });
    }

    function showWarn(ref: any, summary: string, detail: string,) {
        ref.current.show({ severity: 'warn', summary: `${summary}`, detail: `${detail}` });
    }

    function showError(ref: any, summary: string, detail: string,) {
        ref.current.show({ severity: 'error', summary: `${summary}`, detail: `${detail}` });
    }
    return {
        showSuccess,
        showInfo,
        showWarn,
        showError
    }
}

export default useToaster;

