import React from 'react'

const Loader = () => {
    return (
        <div className="loadingio-spinner-pulse-ngjc9gyda3 t-flex  t-justify-center t-items-center t-w-full md:t-w-auto t-m-auto">
            <div className="ldio-2qwtyc9p8zy  t-flex t-flex-row t-scale-[0.5] md:t-scale-[0.6]  t-justify-center t-items-center t-h-full t-w-full md:t-w-auto  t-m-auto ">
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
            </div></div>
    )
}

export default Loader