import * as Yup from "yup";
import { RecordStatus } from "../../../models";
import { EditorType, PageType } from "../../../models";

export const SchemaForStaffProfile = Yup.object().shape({
  nameFirst: Yup.string().min(1, "Too Short!").required("Required"),
  nameLast: Yup.string().min(1, "Too Short!").required("Required"),
  email: Yup.string(),
  phoneNumber: Yup.string(),
  role: Yup.string(),
  status: Yup.string()
  .required("Required").default('draft'),
  position: Yup.string().min(1, "Too Short!").required(),
  lastLoggedInOn: Yup.string(),
  addedOn: Yup.date().required("Required").default(new Date()),

  slug: Yup.string().optional(),
  pageDesignTextEditor: Yup.string().optional(),
  pageDesignPageBuilder: Yup.string().optional(),
  pageDesignType: Yup.mixed<EditorType>().optional(),
  tags: Yup.lazy((val) =>
    (Array.isArray(val)
      ? Yup.array().of(Yup.string())
      : Yup.array().of(Yup.string())
    ).optional()
  ),
  // status: Yup.string().optional().default(RecordStatus.DRAFT),
});
