import React from "react";
import { HiDotsVertical } from "react-icons/hi";
interface FileManagerListItemProps {
  name: string;
  size: number;
  fileExtension: string;
}

enum fileExtension {
  PPT = "ppt",
  XLS = "xls",
  DOC = "doc",
  PDF = "pdf",
  PNG = "png",
  JPG = "jpeg",
  MP4 = "mp4",
  OTHER = "other",
}

export default function FileManagerFile(props: FileManagerListItemProps) {
  const wordImage = "../../../../assets/images/folder/word.png";
  const pptImage = "../../../../assets/images/folder/powerpoint.png";
  const pdfImage = "../../../../assets/images/folder/pdf.png";
  const mediaImage = "../../../../assets/images/folder/play.png";
  let fileType;

  switch (props.fileExtension) {
    case fileExtension.DOC:
      fileType = wordImage;
      break;
    case fileExtension.PDF:
      fileType = pdfImage;
      break;
    case fileExtension.PPT:
      fileType = pptImage;
      break;
    case fileExtension.MP4:
      fileType = mediaImage;
      break;
  }
  return (
    <div
      className="t-w-[180px] t-h-[220px] t-bg-white t-rounded-xl t-m-4
t-shadow-xl p-3 t-cursor-pointer
"
    >
      <div className="flex w-full t-justify-end">
        <div>
          <HiDotsVertical />
        </div>
      </div>
      <div className="flex t-items-center justify-content-center">
        <img src={fileType} width={80} height={80} alt="icon" />
      </div>
      <div className="t-mt-2">
        <p>{props.name}</p>
        <hr className="t-my-1" />
        <p className="t-font-bold">File Size:</p>
        <p>{props.size} MB</p>
      </div>
    </div>
  );
}
