import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { PostSubCategory, OpType, DataLoadingState } from "../../../../models";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../../../graphql/mutations";
import * as queries from "../../../../graphql/queries";
import * as subscriptions from "../../../../graphql/subscriptions";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import useDataPostSubCategoryList from "../data/data-for-sub-post-categories-list";
import useDataForUserAuth from "../../../../store/auth/data-for-auth";
import { createPostSubCategoryGQL, getPostSubCategoryGQL, listPostSubCategoriesGQL, updatePostSubCategoryGQL } from "../../../../custom-graphql/custom-gql-main-post-sub-category";
import useDataPostCategoryList from "../data/data-for-post-categories-list";


interface ServicesForPostSubCategoriesInterface {
    addNewPostSubCategory: (item: PostSubCategory) => Promise<boolean>;

    updatePostSubCategory: (item: PostSubCategory) => Promise<boolean>;

    updatePostSubCategoryField: (
        id: string,
        key: string,
        value: any
    ) => Promise<boolean>;

    deletePostSubCategory: (item: PostSubCategory) => Promise<boolean>;

    findOnePostSubCategory: (id: string) => Promise<PostSubCategory | null>;

    findAPostsSubCategories: (
        postCategoryID: string
    ) => Promise<PostSubCategory[] | null>;

    listAllPostSubCategories: () => Promise<[PostSubCategory] | null>;
}

export function usePostSubCategoryServiceAPI(): ServicesForPostSubCategoriesInterface {


    const postSubCategoryDataAdd = useDataPostSubCategoryList(
        (state) => state.addItem
    );
    const loggedUser = useDataForUserAuth((state) => state.loggedUser);

    const postSubCategoryDataSetList = useDataPostSubCategoryList(
        (state) => state.setListItems
    );

    const postSubCategoryDataUpdate = useDataPostSubCategoryList(
        (state) => state.updateItem
    );

    const postSubCategoryDataDelete = useDataPostSubCategoryList(
        (state) => state.deleteItem
    );


    const postCategoryStatusForLoadingListItems = useDataPostCategoryList(
        (state) => state.statusForLoadingListItems
    );
    const postCategorySetStatusForLoadingListItems = useDataPostCategoryList(
        (state) => state.setStatusForLoadingListItems
    );


    const [addingNewItem, setAddingNewItem] = useState(false);

    const [updatingNewItem, setUpdatingNewItem] = useState(false);

    const [deletingNewItem, setDeletingNewItem] = useState(false);


    const addNewPostSubCategory = async (item: PostSubCategory) => {
        let newItem: PostSubCategory;
        let promise: Promise<GraphQLResult<any>>;
        let dataResult: PostSubCategory;
        newItem = {
            ...item,
            dateUploaded: new Date().toISOString(),
        };
        console.log("subCategories item", newItem);



        delete (newItem as any).pages;
        delete (newItem as any).articlesPublications;
        delete (newItem as any).createdAt;
        delete (newItem as any).creatorUserAccount;
        delete (newItem as any).updatedAt;
        delete (newItem as any).postCategory;
        delete (newItem as any)._lastChangedAt;
        delete (newItem as any)._deleted;


        promise = API.graphql(
            graphqlOperation(createPostSubCategoryGQL, { input: newItem })
        );

        await promise
            .then((results) => {
                dataResult = results.data.createPostSubCategory;
                console.log("addNewPostSubCategory dataResult", results);
                // postSubCategoryDataAdd(dataResult);
                return true;
            })
            .catch((e) => {
                console.log("error addNewPostSubCategory", e);
                return false;
            });
        return false;
    };

    const updatePostSubCategory = async (item: PostSubCategory) => {
        let newItem: PostSubCategory;
        let promise: Promise<GraphQLResult<any>>;
        let dataResult: PostSubCategory;
        newItem = {
            ...item,

            dateUploaded: new Date().toISOString(),
        };

        delete (newItem as any).pages;
        delete (newItem as any).createdAt;
        delete (newItem as any).updatedAt;
        delete (newItem as any)._lastChangedAt;
        delete (newItem as any)._deleted;
        delete (newItem as any).postCategory;
        delete (newItem as any).creatorUserAccount;
        delete (newItem as any).articlesPublications;

        promise = API.graphql(
            graphqlOperation(updatePostSubCategoryGQL, { input: newItem })
        );

        await promise
            .then((results) => {
                dataResult = results.data as PostSubCategory;
                console.log("updatePostSubCategory dataResult", dataResult);
                // postSubCategoryDataUpdate(dataResult);
                return true;
            })

            .catch((e) => {
                console.log("error updatePostSubCategory", e);
                return false;
            });
        return false;
    };

    const listAllPostSubCategories = async () => {
        let foundItems: PostSubCategory[] | null | undefined;
        let promise: Promise<GraphQLResult<any>>;

        promise = API.graphql({
            query: listPostSubCategoriesGQL,
        });

        await promise
            .then((results) => {
                foundItems = results.data.listPostSubCategories
                    .items as PostSubCategory[];

                const sortFxn = ((a: PostSubCategory, b: PostSubCategory) => {
                    return new Date(a.dateUploaded!).getTime() - new Date(a.dateUploaded!).getTime();
                });

                console.log("listAllPostSubCategories foundItems", foundItems);
                postSubCategoryDataSetList(foundItems.sort(sortFxn));
                postCategorySetStatusForLoadingListItems(DataLoadingState.LOADED)
                return foundItems;
            })
            .catch((e) => {
                console.log("error listPostSubCategories", e);
                postCategorySetStatusForLoadingListItems(DataLoadingState.ERROR)
                return null;
            });
        return null;
    };

    const findOnePostSubCategory = async (id: string) => {
        let foundItem: PostSubCategory | null | undefined;
        let promise: Promise<GraphQLResult<any>>;

        promise = API.graphql(
            graphqlOperation(getPostSubCategoryGQL, { id: id })
        );

        await promise
            .then((results) => {
                console.log("findOnePostSubCategory foundItem", results);
                foundItem = results.data.getPostSubCategory as PostSubCategory;
                console.log("findOnePostSubCategory foundItem", foundItem);
                return foundItem;
            })
            .catch((e) => {
                console.log("error findOnePostSubCategory", e);
                return null;
            });

        return null;
    };

    const findAPostsSubCategories = async (postCategoryID: string) => {
        let foundItems: PostSubCategory[] | null | undefined;
        let promise: Promise<GraphQLResult<any>>;

        promise = API.graphql({
            query: queries.listPostSubCategories,
            variables: {
                postCategoryID: postCategoryID,
            },
        });

        await promise
            .then((results) => {
                foundItems = results.data.listPostSubCategories
                    .items as PostSubCategory[];
                console.log("foundItems findAPostsSubCategories", foundItems);
                return foundItems;
            })
            .catch((e) => {
                console.log("error findAPostsSubCategories", e);
                return null;
            });
        return null;
    };

    const deletePostSubCategory = async (item: PostSubCategory) => {
        let promise: Promise<GraphQLResult<any>>;

        promise = API.graphql(
            graphqlOperation(mutations.deletePostSubCategory, { input: item })
        );

        await promise
            .then((_) => {
                postSubCategoryDataDelete(item);
                return true;
            })
            .catch((e) => {
                console.log("error deletePostSubCategory", e);
                return false;
            });
        return false;
    };

    //FIGURE OUT API
    const updatePostSubCategoryField = async (
        id: string,
        key: string,
        value: any
    ) => {
        return false;
    };

    useEffect(() => {
        const createSubscription = (
            API.graphql(
                graphqlOperation(subscriptions.onCreatePostSubCategory)
            ) as unknown as any
        ).subscribe({
            next: ({ provider, value }) => {
                console.log('onCreatePostSubCategory', { provider, value });

                if (addingNewItem) return;

                setAddingNewItem(true);

                const newItem = value.data.onCreatePostSubCategory as PostSubCategory;

                postSubCategoryDataAdd(newItem);

                setAddingNewItem(false);

            },
            error: (error: any) => {
                console.warn(error);
            },
        });

        const updateSubscription = (
            API.graphql(
                graphqlOperation(subscriptions.onUpdatePostSubCategory)
            ) as unknown as any
        ).subscribe({
            next: ({ provider, value }) => {
                console.log('onUpdatePostSubCategory', { provider, value });

                if (updatingNewItem) return;

                setUpdatingNewItem(true);

                const newItem = value.data.onUpdatePostSubCategory as PostSubCategory;

                postSubCategoryDataUpdate(newItem);

                setUpdatingNewItem(false);

            },
            error: (error: any) => {
                console.warn(error);
            },
        });

        const deleteSubscription = (
            API.graphql(
                graphqlOperation(subscriptions.onDeletePostSubCategory)
            ) as unknown as any
        ).subscribe({
            next: ({ provider, value }) => {
                console.log('onDeletePostSubCategory', { provider, value });

                if (deletingNewItem) return;

                setDeletingNewItem(true);

                const newItem = value.data.onDeletePostSubCategory as PostSubCategory;

                postSubCategoryDataDelete(newItem);

                setDeletingNewItem(false);

            },
            error: (error: any) => {
                console.warn(error);
            },
        });

        return () => {
            const cleanupSubscriptions = () => {
                createSubscription.unsubscribe();
                updateSubscription.unsubscribe();
                deleteSubscription.unsubscribe();
            };
            cleanupSubscriptions();
        };
    }, []);

    return {
        addNewPostSubCategory,
        updatePostSubCategory,
        updatePostSubCategoryField,
        deletePostSubCategory,
        findOnePostSubCategory,
        findAPostsSubCategories,
        listAllPostSubCategories,
    };
}
