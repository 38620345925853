import produce from "immer";
import { create } from "zustand";
import { EditorType, Page, PageType, RecordStatus } from "../../../models";
import { PageDataInterfaceResearchActivitiesForm } from "./data-interface-for-research-activities";


const defaultData = {
  pageDesignType: EditorType.TEXT_EDITOR,
  pageDesignTextEditor: '',
  pageType: PageType.RESEARCH_ACTIVITY,
  pageDesignPageBuilder: '',
  title: '',
  coverImage: null,
  status: RecordStatus.DRAFT,
  datePublished: new Date().toISOString(),
  dateUpdated: new Date().toISOString(),
  dateAdded: new Date().toISOString(),
};


const useDataResearchActivitiesForm =
  create<PageDataInterfaceResearchActivitiesForm>()((set, get) => {
    return {
      showDialog: false,
      setFormData: async (item) => {
        set((_) => ({ formData: item }));
        return true;
      },
      formActionType: undefined,
      setShowDialog: async (value) => {
        set((_) => ({ showDialog: value }));
        return true;
      },
      formData: defaultData as unknown as Page,
      setFormActionType: async (item) => {
        set((_) => ({ formActionType: item }));
        return true;
      },
      reset: async () => {
        set((_) => ({ formData: defaultData as unknown as Page }));
        return true;
      },
      updateFormDataField: async (property, value) => {
        set(
          produce((state: PageDataInterfaceResearchActivitiesForm) => {
            state.formData![property] = value;
          })
        );
        return true;
      },
    };
  });

export default useDataResearchActivitiesForm;
