import { VscNotebookTemplate } from "react-icons/vsc";
import { MdTextFields } from "react-icons/md";

function EditorSwitch() {
  return (
    <div className="flex t-flex-col t-items-center t-justify-center t-h-screen">
      <p className="t-font-bold t-text-center t-text-2xl t-mb-12 t-m-4">
        Which editing tool do you want to use?
      </p>
      <div className="flex t-flex-col md:flex-row t-items-center t-justify-between">
        <div
          className="flex t-flex-col t-items-center t-m-10 t-shadow-md t-p-10 
        t-rounded-md t-cursor-pointer t-scale-90 hover:t-scale-100 t-ease-in t-duration-300"
        >
          <MdTextFields color="#2196f3" size={36} />
          <p className="t-font-semibold">Text Editor</p>
        </div>
        <p className="t-font-medium">or</p>
        <div
          className="flex t-flex-col t-items-center t-m-10 t-shadow-md t-p-10
         t-rounded-md t-cursor-pointer t-scale-90 hover:t-scale-100 t-ease-in t-duration-300"
        >
          <VscNotebookTemplate color="#2196f3" size={36} />
          <p className="t-font-semibold">Page Builder</p>
        </div>
      </div>
    </div>
  );
}

export default EditorSwitch;
