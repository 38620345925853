import { format } from "date-fns";
import {
  ArticlePublicationPublisher,
  DbOpAccessType,
  EditorType,
  ObjectStatus,
  StaffProfile,
} from "../../models";
import { RecordStatus } from "../../models";
import {
  DbOpAccessTypeSelect,
  PageDesignTypeSelect,
  VisibilityTypeSelect,
} from "../../shared-components/toasts/forms/forms-interfaces";



const deleteFieldsForSave = ({ pageModelName, newItem }) => {

  delete (newItem as any).createdAt;
  delete (newItem as any).staffProfiles;
  delete (newItem as any).postCategory;
  delete (newItem as any).postSubCategory;
  delete (newItem as any).updatedAt;
  delete (newItem as any).creatorUserAccount;
  delete (newItem as any)._lastChangedAt;
  delete (newItem as any)._deleted;
  delete (newItem as any).files;
  delete (newItem as any).unitDepartments;
  delete (newItem as any).authorStaffProfiles;
  delete (newItem as any).publishers;
  delete (newItem as any).userViewsDownloads;

  return newItem;
}

interface StatusTypeSelect extends VisibilityTypeSelect { }

const defaultDataBuilder =
  '{"assets":[],"styles":[{"selectors":["#i655"],"style":{"padding":"10px","font-size":"50px"}}],"pages":[{"frames":[{"component":{"type":"wrapper","stylable":["background","background-color","background-image","background-repeat","background-attachment","background-position","background-size"],"attributes":{"id":"i2yq"},"components":[{"type":"text","attributes":{"id":"i655"},"components":[{"type":"textnode","content":"Start Building your page here..."}]}]}}],"type":"main","id":"BDMadYyNYicHLAxK"}]}';

const formatDateString = (value: Date) => {
  return format(value, `do LLL yyyy, h:mm aaa`);
};

const formatDateToIsoString = (value: Date | string) => {
  if (typeof value === "string") {
    // console.log('isoDate', 'string');
    return value;
  } else if (typeof value === "object") {
    // console.log('isoDate', 'object');
    return value.toISOString();
  }
};

const formatIsoStringToDate = (value: Date | string) => {
  if (typeof value === "string") {
    // console.log('isoDate', 'string', new Date(value));

    return new Date(value);
  } else if (typeof value === "object") {
    // console.log('isoDate', 'object');
    return value;
  }
};

const visibility: VisibilityTypeSelect[] = [
  { name: "Public", value: "public" },
  { name: "Private", value: "private" },
];

const publishers = [
  {
    id: "1",
    name: "CRIG",
    status: "Active",
    articlesPublicationsPublished: [],
  },
  {
    id: "2",
    name: "PPP Publication",
    status: "Active",
    articlesPublicationsPublished: [],
  },
] as unknown as ArticlePublicationPublisher[];

const authors = [
  {
    id: "15",
    nameFull: "George Sam",
    status: "Active",
  },
] as unknown as StaffProfile[];

const statusType: StatusTypeSelect[] = [
  { name: "Draft", value: "draft" },
  { name: "Pending Approval", value: "pendingApproval" },
  { name: "Published", value: "published" },
];

const selectPublishStatusType: StatusTypeSelect[] = [
  { name: "Archive", value: RecordStatus.ARCHIVE },
  { name: "Draft", value: RecordStatus.DRAFT },
  { name: "Published", value: RecordStatus.PUBLISHED },
];
const useAccountStatusType: StatusTypeSelect[] = [
  { name: "active", value: ObjectStatus.ACTIVE },
  { name: "inactive", value: ObjectStatus.INACTIVE },
  { name: "pending", value: ObjectStatus.PENDING },
  { name: "suspended", value: ObjectStatus.SUSPENDED },
  { name: "none", value: ObjectStatus.NONE },
];

const selectPageDesignTypes: PageDesignTypeSelect[] = [
  { name: "Text Editor", value: EditorType.TEXT_EDITOR },
  { name: "Page Builder", value: EditorType.PAGE_BUILDER },
];
const userRoleType: StatusTypeSelect[] = [
  { name: "Active", value: "active" },
  { name: "Inactive", value: "inactive" },
];

const selectDbOpAccessTypes: DbOpAccessTypeSelect[] = [
  { name: "Add Item", value: DbOpAccessType.CREATE },
  { name: "Read Item", value: DbOpAccessType.READ },
  { name: "Update Item", value: DbOpAccessType.UPDATE },
  { name: "Delete Item", value: DbOpAccessType.DELETE },
];

const slugify = (text: string) => {
  if (text.length != 0) {
    return text
      .toString() // Cast to string (optional)
      .normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string (optional)
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\_/g, "-") // Replace _ with -
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/\-$/g, "") // Remove trailing -
      .replace(/[\u0300-\u036f]/g, "");
  }
  return "";
};

export {
  visibility,
  useAccountStatusType,
  statusType,
  selectPublishStatusType,
  slugify,
  formatDateToIsoString,
  formatIsoStringToDate,
  formatDateString,
  defaultDataBuilder,
  deleteFieldsForSave,
  selectPageDesignTypes,
  publishers,
  authors,
  userRoleType,
};
