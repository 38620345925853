
export const createStaffProfileGQL = /* GraphQL */ `
  mutation CreateStaffProfile(
    $input: CreateStaffProfileInput!
    $condition: ModelStaffProfileConditionInput
  ) {
    createStaffProfile(input: $input, condition: $condition) {
      id
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      nameFirst
      nameLast
      deleted
      nameFull
      slug
      crigDivisionSubStationID
      crigStaffGroupID
      crigUnitDepartmentID
      position
      isAContentFollower
      userAccountID
      bioDesignType
      bioDesignPageBuilder
      bioDesignTextEditors {
        title
        body
      }
      status
      dateAdded
      dateUpdated
      totalViewsCount
      creatorUserAccountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteStaffProfileGQL = /* GraphQL */ `
  mutation DeleteStaffProfile(
    $input: DeleteStaffProfileInput!
    $condition: ModelStaffProfileConditionInput
  ) {
    deleteStaffProfile(input: $input, condition: $condition) {
      id
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      nameFirst
      nameLast
      deleted
      nameFull
      slug
      crigDivisionSubStationID
      crigStaffGroupID
      crigUnitDepartmentID
      position
      isAContentFollower
      userAccountID
      bioDesignType
      bioDesignPageBuilder
      bioDesignTextEditors {
        title
        body
      }
      status
      dateAdded
      dateUpdated
      totalViewsCount
      creatorUserAccountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateStaffProfileGQL = /* GraphQL */ `
  mutation UpdateStaffProfile(
    $input: UpdateStaffProfileInput!
    $condition: ModelStaffProfileConditionInput
  ) {
    updateStaffProfile(input: $input, condition: $condition) {
      id
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      nameFirst
      nameLast
      deleted
      nameFull
      slug
      crigDivisionSubStationID
      crigStaffGroupID
      crigUnitDepartmentID
      position
      isAContentFollower
      userAccountID
      bioDesignType
      bioDesignPageBuilder
      bioDesignTextEditors {
        title
        body
      }
      status
      dateAdded
      dateUpdated
      totalViewsCount
      creatorUserAccountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;


export const getStaffProfileGQL = /* GraphQL */ `
  query GetStaffProfile($id: ID!) {
    getStaffProfile(id: $id) {
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
        _deleted
        deleted
        _lastChangedAt
        _version
        bioDesignPageBuilder
        bioDesignType
        bioDesignTextEditors {
        title
        body
      }
        createdAt
        creatorUserAccountID
        crigDivisionSubStationID
        crigDivisionSubStation {
          id
          title
        }
        crigStaffGroupID
        crigStaffGroup {
          name
          shortDescription
          id
        }
        dateUpdated
        dateAdded
        id
        isAContentFollower
        nameFirst
        nameFull
        nameLast
        position
        slug
        status
        totalViewsCount
        updatedAt
        userAccountID
        crigUnitDepartmentID
        crigUnitDepartment {
          name
          shortDescription
          id
        }
    }
  }
`;

export const listStaffProfilesGQL = /* GraphQL */ `
  query ListStaffProfiles(
    $filter: ModelStaffProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      startedAt
      items {
        profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
        _deleted
        deleted
        _lastChangedAt
        _version
        bioDesignPageBuilder
        bioDesignType
        bioDesignTextEditors {
        title
        body
      }
        createdAt
        creatorUserAccountID
        crigDivisionSubStationID
        crigDivisionSubStation {
          id
          title
        }
        crigStaffGroupID
        crigStaffGroup {
          name
          shortDescription
          id
        }
        dateUpdated
        dateAdded
        id
        isAContentFollower
        nameFirst
        nameFull
        nameLast
        position
        slug
        status
        totalViewsCount
        updatedAt
        userAccountID
        crigUnitDepartmentID
        crigUnitDepartment {
          name
          shortDescription
          id
        }
      }
    }
  }
`;


