import { Ripple } from "primereact/ripple";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuItemInterface } from "./interfaces";

const MainLayoutMenuItem = (props: MenuItemInterface) => {
  const hasBadge: boolean = (props.badgeNumber ?? 0) > 0;
  const borderRadius: string = props.borderRadius ?? "t-rounded-[15px]";
  const ref = useRef(null);
  const nav = useNavigate();
  const { pathname } = useLocation();

  const onClick = () => {
    nav(`${props.routePath}`);
  };
  return (
    <li ref={ref} className={`t-mt-1`} onClick={onClick}>
      <a
        href={props.externalLink}
        target={props.externalLink && "_blank"}
        className={`p-ripple t-justify-between flex align-items-center cursor-pointer t-group
            t-py-3 t-px-3 border-transparent
            
               t-transition-all t-ease-in-out  t-duration-[3ms]
              hover:t-bg-appdarkblue-600
              hover:t-text-appyellow-600
                ${pathname == props.routePath
            ? "t-text-green-700"
            : "t-text-slate-800"
          } ${borderRadius}`}
      >
        <div className="t-flex">
          {props.leftIcon && (
            <span
              className="mr-3 t-text-appgrey-600 t-text-[24px] 
          group-hover:t-text-appyellow-600"
            >
              {props.leftIcon}

            </span>
          )}
          <span className="font-medium t-mr-2">{props.title}</span>
        </div>


        {hasBadge && (
          <span
            className="inline-flex align-items-center t-min-w-[1.5rem] t-h-[1.5rem]
           justify-content-center ml-auto bg-indigo-500 text-0 border-circle"
          >
            {props.badgeNumber}
          </span>
        )}
        {props.rightIcon && (
          <span className="mr-3 -text-appgrey-600">{props.rightIcon}</span>
        )}
        <Ripple />
      </a>
    </li>
  );
};

export default MainLayoutMenuItem;
