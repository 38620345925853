import {
  Amplify,
  API,
  Auth,
  AuthModeStrategyType,
  DataStore,
  PubSub,
  Storage
} from "aws-amplify";
import awsconfig from "../aws-exports";

export const ConfigCloud = async () => {
  Amplify.configure({
    ...awsconfig,
    // DataStore: {
    //   authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
    // },
  });

  Auth.configure({ ...awsconfig, authenticationFlowType: "USER_PASSWORD_AUTH" });
  // DataStore.configure({ authModeStrategyType: AuthModeStrategyType.MULTI_AUTH });
  PubSub.configure(awsconfig);
  API.configure(awsconfig);
  Storage.configure(awsconfig);

  console.log("aws configured");
};
