import { DashboardComponentContentDetailProps } from "../../shared-interfaces/dashboard/dashboard-interfaces";

function DashboardComponentContentDetail({
  icon,
  title,
  counter,
  counterUpdate,
}: DashboardComponentContentDetailProps) {
  return (
    <div className="t-flex t-w-full t-px-4 md:px-2 t-mb-4">
      <div className="t-mr-6 t-mt-2">{icon}</div>
      <div>
        <p className="t-font-bold t-text-appgrey-800">{title}</p>
        <p className="t-font-bold">{counter}</p>
        {counterUpdate && (
          <p className="t-text-appgrey-400">{counterUpdate} Last Week</p>
        )}
      </div>
    </div>
  );
}

export default DashboardComponentContentDetail;
