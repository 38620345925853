import { type } from "os";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

interface Props {
  background: boolean;
  title: string;
  subTitle?: string;
  buttons?: any;
  titleSize?: string;
  titleFont?: string;
  titleFontWeight?: string;
  yPadding?: string;
  maxWidth?: string;
  backArrow?: boolean;
  backArrowNavUrl?: string;
  useWhiteBackButton?: boolean;
  showTopMargin?: boolean;
}
function CustomNavigation({
  background = false,
  title,
  subTitle,
  buttons,
  backArrow,
  backArrowNavUrl,
  titleFont,
  maxWidth,
  titleSize,
  titleFontWeight,
  yPadding,
  useWhiteBackButton,
  showTopMargin = true,
}: Props) {
  const navigate = useNavigate();

  let newTitleSize = titleSize ?? 't-text-[27px]';
  let newYPadding = yPadding ?? ' t-py-3';
  let newTitleFont = titleFont ?? 't-font-playfair';
  let newTitleFontWeight = titleFontWeight ?? 't-font-bold';
  let hasSubTitle = (subTitle != undefined && subTitle.length != 0);
  let newMaxWidth = maxWidth ?? '';

  return (
    <nav
      className={`t-flex t-flex-col  t-w-full ${showTopMargin && "t-sticky t-top-[60px]"}  
      t-transition-all t-ease-in-out t-z-10 
       md:t-rounded-0 t-items-center 
          ${newYPadding} t-px-6 t-overflow-hidden 
        t-mb-0 ${(background == false) ? 't-bg-white ' : (background == true) ? "t-bg-appgreen-700" : 't-bg-white'}`}
    >
      <div className={`flex t-flex-col t-w-full md:t-flex-row  
      t-transition-all t-ease-in-out  ${newMaxWidth}
       t-items-center t-justify-between
           t-overflow-hidden 
        t-mb-0`}
      >
        <div>
          <div
            className={`t-font-bold t-flex
           t-items-center  t-flex-row t-text-2xl
            ${background ? "t-text-appgreen-50" : "text-primary-500"
              }`}
          >
            <div className="t-flex t-flex-row custom-nav t-items-start">
              {backArrow && (
                <Button type='button' onClick={() => {
                  navigate(backArrowNavUrl || "#");

                }} icon="pi pi-arrow-left" className={`p-button-rounded  ${useWhiteBackButton ? 'use-white' : ''}  p-button-text`}
                />
              )}
              <div className="t-flex t-flex-col">
                <div className="flex t-items-center">
                  <span
                    className={`t-ml-2 ${newTitleFont} t-pb-2 
                t-pt-2 ${newTitleSize} t-text-${background ? "white" : "applisttitlebrown"
                      }  ${newTitleFontWeight}`}
                  >
                    {title}
                  </span>
                </div>
                {/* Component for adding sub title */}
                {hasSubTitle && <p className="t-text-base t-font-medium t-text-gray-500 t-ml-2 t-pb-2 t-font-inter">
                  {subTitle}
                </p>}
              </div>
            </div>


          </div>
        </div>
        {/* Component for adding buttons */}
        <div className="t-mt-6 md:t-mt-0 t-mb-4 md:mb-0">{buttons}</div>
      </div>
    </nav>
  );
}

export default CustomNavigation;
