import useDataForTextEditor from "../data-for-editor";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import { editorConfiguration } from "./configure";

//props
type Props = {
  // onChange(val: OutputData): void;
  holder: string;
};

const TextEditorCK = ({ holder }: Props) => {

  function uploadAdapter(loader) {
    return {
      upload: () => {
        console.log('loader', loader.file)
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);

          });
        });
      }
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const textEditorDataString = useDataForTextEditor((state) => state.textEditorDataString);

  const setTextEditorDataString = useDataForTextEditor((state) => state.setTextEditorDataString);




  return (
    <div className="!t-font-inter">
      <CKEditor
        editor={ClassicEditor}
        config={{ ...editorConfiguration, extraPlugins: [uploadPlugin], }}
        data={textEditorDataString}
        onReady={editor => {
          // You can store the "editor" and use when it is needed.
          // console.log('Editor is ready to use!', editor);

        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setTextEditorDataString(data);
        }}
      // onBlur={(event, editor) => {
      //   console.log('Blur.', editor);
      // }}
      // onFocus={(event, editor) => {
      //   console.log('Focus.', editor);
      // }}
      />
    </div>
  );

};


export default TextEditorCK;
