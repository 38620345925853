import { useNavigate } from "react-router-dom";
import useDataForTextEditor from "../../../editors/data-for-editor";
import { FormActionType } from "../../../models";
import useRoutePaths from "../../../routes/route-path";
import TableAddNewButton from "../../../shared-components/button/TableAddNewButton";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import useDataResearchActivitiesForm from "../data/data-for-research-activities-form";
import TableResearchActivities from "../tables/table-for-research-activities";

function ListResearchActivities() {
  const { paths: route } = useRoutePaths();
  const navigation = useNavigate();
  const setFormActionType = useDataResearchActivitiesForm(
    (state) => state.setFormActionType
  );
  const resetForm = useDataResearchActivitiesForm(
    (state) => state.reset
  );

  const coverImageReset = useDataForCoverImageFile((state) => state.reset);
  const editorReset = useDataForTextEditor((state) => state.reset);

  return (
    <div className="flex t-flex-col t-overflow-hidden t-justify-start t-h-fit t-rounded-lg t-shadow-lg t-m-10">
      <div className="">
        <CustomNavigation
          background={false}
          title="Research Activities"
          buttons={
            <TableAddNewButton label='Add New' onClick={() => {
              coverImageReset()
              editorReset()
              setFormActionType(FormActionType.NEW);
              resetForm()
              navigation(route.researchActivities.details);
            }} />
          }

        />
      </div>
      <TableResearchActivities />
    </div>
  );
}

export default ListResearchActivities;
