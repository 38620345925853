
import { DataStore } from "aws-amplify";
import { useEffect } from "react";
import { BsNewspaper } from "react-icons/bs";
import { FaArtstation, FaMicroscope } from "react-icons/fa";
import { MdEmail, MdEvent, MdGroup, MdRadio } from "react-icons/md";
import DashboardComponent from "../../../shared-components/dashboard/DashboardComponent";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import { DashboardDataProps } from "../../../shared-interfaces/dashboard/dashboard-interfaces";

const followersStatisticsData: DashboardDataProps = {
  col1: {
    upperIcon: <FaMicroscope color="red" />,
    lowerIcon: <BsNewspaper color="#4c7a34" />,
    upperTitle: "Research Activities",
    lowerTitle: "Articles and Publications",
    upperCount: 250,
    lowerCount: 250,
    upperCountUpdate: 13,
    lowerCountUpdate: 13,
  },
  col2: {
    upperIcon: <FaArtstation color="#0c59cf" />,
    lowerIcon: <MdRadio color="#b38df7" />,
    upperTitle: "Products",
    lowerTitle: "News/Announcement",
    upperCount: 250,
    lowerCount: 250,
    upperCountUpdate: 13,
    lowerCountUpdate: 13,
  },
  col3: {
    upperIcon: <BsNewspaper color="#4c7a34" />,
    lowerIcon: <MdEmail color="#ffa834" />,
    upperTitle: "Articles and Publications",
    lowerTitle: "Newsletters",
    upperCount: 250,
    lowerCount: 250,
    upperCountUpdate: 13,
    lowerCountUpdate: 13,
  },
  col4: {
    upperIcon: <MdEvent />,
    lowerIcon: <MdGroup color="#008db1" />,
    upperTitle: "Events/Gallery",
    lowerTitle: "Total Staff",
    upperCount: 250,
    lowerCount: 250,
    upperCountUpdate: 13,
    lowerCountUpdate: 13,
  },
};




const Dashboard = () => {

  useEffect(() => {

    DataStore.start();
    return () => {

    }
  }, [])


  return (
    <div>
      <CustomNavigation background={false} title="Dashboard" />
      <div className="t-px-6 t-mb-12">
        {/* <DashboardComponent
          title="Followers Statistics"
          col1={followersStatisticsData.col1}
          col2={followersStatisticsData.col2}
          col3={followersStatisticsData.col3}
          col4={followersStatisticsData.col4}
        /> */}
        <div className="t-my-10"></div>
        <DashboardComponent
          title="Views & Downloads  Statistics"
          col1={followersStatisticsData.col1}
          col2={followersStatisticsData.col2}
          col3={followersStatisticsData.col3}
          col4={followersStatisticsData.col4}
        />
        <div className="t-my-10"></div>
        <DashboardComponent
          title="Total Posts"
          col1={followersStatisticsData.col1}
          col2={followersStatisticsData.col2}
          col3={followersStatisticsData.col3}
          col4={followersStatisticsData.col4}
        />
      </div>
    </div>
  );
}

export default Dashboard;
