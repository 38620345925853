import { create } from "zustand";
import produce from "immer";
import { CrigUnitDepartment, DataLoadingState } from "../../../../models";
import { PageDataInterfaceCrigUnitDepartmentList } from "./data-interface-for-staff-unit-departments";

const useDataStaffUnitDepartmentList =
  create<PageDataInterfaceCrigUnitDepartmentList>()((set, get) => {
    return {
      statusForLoadingListItems: DataLoadingState.PENDING as
        | DataLoadingState
        | undefined,
      listItems: [],

      setStatusForLoadingListItems: async (newValue) => {
        set((_) => ({ statusForLoadingListItems: newValue }));
        return true;
      },
      setListItems: async (items) => {
        set((_) => ({ listItems: items }));
        return true;
      },
      updateItem: async (newItem) => {
        const newItemRecord = newItem as CrigUnitDepartment;

        set(
          produce((state: PageDataInterfaceCrigUnitDepartmentList) => {
            function findIndexFxn(item: CrigUnitDepartment) {
              return item.id === newItemRecord.id;
            }
            const index = state.listItems.findIndex(findIndexFxn);
            if (index >= 0) {
              state.listItems[index] = newItemRecord;
            }
          })
        );

        return true;
      },

      deleteItem: async (newItem) => {
        set(
          produce((state: PageDataInterfaceCrigUnitDepartmentList) => {
            function findIndexFxn(item: CrigUnitDepartment) {
              return item.id === newItem.id;
            }

            const index = get().listItems.findIndex(findIndexFxn);
            if (index >= 0) {
              state.listItems.splice(index, 1);
            }
          })
        );

        return true;
      },

      addItem: async (newItem) => {
        let newItemRecord = newItem as CrigUnitDepartment;
        set(
          produce((state: PageDataInterfaceCrigUnitDepartmentList) => {
            function findIndexFxn(item: CrigUnitDepartment) {
              return item.id === newItem.id;
            }
            const index = get().listItems.findIndex(findIndexFxn);
            if (index >= 0) {
              state.listItems[index] = newItemRecord;
            } else {
              state.listItems.unshift(newItemRecord);
            }
          })
        );

        return true;
      },
    };
  });

export default useDataStaffUnitDepartmentList;
