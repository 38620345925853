import { create } from "zustand";
import produce from "immer";
import { PageDataInterfaceUserRoleForm } from "./data-interface-for-user-roles";
import { UserRole } from "../../../../models";
import { RecordStatus } from "../../../../models";

/*   readonly id: string;
  readonly title: string;
  readonly shortDescription: string;
  readonly moreDetailsTextEditor?: string | null;
  readonly status: string;
  readonly userAccounts: AsyncCollection<UserAccount>;
  readonly dateAdded?: string | null;
  readonly dateUpdated?: string | null;
  readonly assignedAccess?: (UserRoleAccess | null)[] | null;
  readonly creatorUserAccountID: string;
  readonly deleted?: boolean | null;
  readonly creatorUserAccount: AsyncCollection<CreatorUserRoleUserAccounts>;
  readonly createdAt?: string | null;
  readonly updatedAt?: string | null; */

const defaultData = {
  updatedAt: new Date().toISOString(),
  dateAdded: new Date().toISOString(),
  createdAt: new Date().toISOString(),
  dateUpdated: new Date().toISOString(),
  status: RecordStatus.DRAFT,
};

const useDataUserRolesForm = create<PageDataInterfaceUserRoleForm>()(
  (set, get) => {
    return {
      dataItem: undefined,
      setDataItem: async (item) => {
        set((_) => ({ dataItem: item }));
        return true;
      },
      showDialog: false,
      setFormData: async (item) => {
        set((_) => ({ formData: item }));
        return true;
      },
      formActionType: undefined,
      setShowDialog: async (value) => {
        set((_) => ({ showDialog: value }));
        return true;
      },
      reset: async () => {
        set((_) => ({ formData: defaultData as unknown as UserRole }));
        return true;
      },
      formData: null,
      setFormActionType: async (item) => {
        set((_) => ({ formActionType: item }));
        return true;
      },
      updateFormDataField: async (property, value) => {
        set(
          produce((state: PageDataInterfaceUserRoleForm) => {
            state.formData![property] = value;
          })
        );
        return true;
      },
    };
  }
);

export default useDataUserRolesForm;
