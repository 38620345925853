import produce from "immer";
import { create } from "zustand";
import { DataLoadingState, ArticlePublication } from "../../../models";
import { PageDataInterfaceArticlesPublicationsList } from "./data-interface-for-articles-publications";

const useDataEventsGalleryList =
  create<PageDataInterfaceArticlesPublicationsList>()((set, get) => {
    return {
      statusForLoadingListItems: DataLoadingState.PENDING as
        | DataLoadingState
        | undefined,
      listItems: [],

      setStatusForLoadingListItems: async (newValue) => {
        set((_) => ({ statusForLoadingListItems: newValue }));
        return true;
      },
      setListItems: async (items) => {
        set((_) => ({ listItems: items }));
        return true;
      },
      updateItem: async (newItem) => {
        const newItemRecord = newItem as ArticlePublication;
        set(
          produce((state: PageDataInterfaceArticlesPublicationsList) => {
            function findIndexFxn(item: ArticlePublication) {
              return item.id === newItemRecord.id;
            }
            const index = state.listItems.findIndex(findIndexFxn);
            if (index >= 0) {
              state.listItems[index] = newItemRecord;
            }
          })
        );

        return true;
      },

      deleteItem: async (newItem) => {
        set(
          produce((state: PageDataInterfaceArticlesPublicationsList) => {
            function findIndexFxn(item: ArticlePublication) {
              return item.id === newItem.id;
            }

            const index = get().listItems.findIndex(findIndexFxn);
            if (index >= 0) {
              state.listItems.splice(index, 1);
            }
          })
        );

        return true;
      },

      addItem: async (newItem) => {
        let newItemRecord = newItem as ArticlePublication;
        set(
          produce((state: PageDataInterfaceArticlesPublicationsList) => {
            function findIndexFxn(item: ArticlePublication) {
              return item.id === newItem.id;
            }

            const index = get().listItems.findIndex(findIndexFxn);

            if (index >= 0) {
              state.listItems[index] = newItemRecord;
            } else {
              state.listItems.unshift(newItemRecord);
            }
          })
        );

        return true;
      },
    };
  });

export default useDataEventsGalleryList;
