
import { create } from "zustand";
import produce from "immer";

interface EditorDataInterface {
  textEditorDataEncoded?: string | {};
  textEditorDataString: string;
  imageFiles: File[];
  textEditorIsLoading: boolean;
  setTextEditorIsLoading: (loadingStatus: boolean) => Promise<boolean>;
  setTextEditorDataEncoded: (data: string) => Promise<boolean>;
  setTextEditorDataString: (data: string) => Promise<boolean>;
  pageBuilderDataEncoded?: Object | {};
  pageBuilderDataString: string;
  setPageBuilderDataEncoded: (data: Object) => Promise<boolean>;
  setPageBuilderDataString: (data: string) => Promise<boolean>;
  pageBuilderIsLoading: boolean;
  setPageBuilderIsLoading: (loadingStatus: boolean) => Promise<boolean>;
  reset: () => Promise<boolean>;
  addImageFile: (file: File) => Promise<boolean>;
}

const useDataForTextEditor =
  create<EditorDataInterface>()((set, get) => {
    return {
      textEditorDataEncoded: {},
      imageFiles: [],
      reset: async () => {
        set((_) => ({
          textEditorDataEncoded: {},
          textEditorDataString: '',
          pageBuilderDataEncoded: {},
          pageBuilderDataString: '',
          pageBuilderIsLoading: true,
          textEditorIsLoading: true,
          imageFiles: [],
        }));
        return true;
      },
      addImageFile: async (file: File) => {
        set(
          produce((state: EditorDataInterface) => {
            state.imageFiles.push(file);
          })
        );
        return true;
      },
      removeImageFile: async (file: File) => {
        set(
          produce((state: EditorDataInterface) => {
            state.imageFiles.push(file);
          })
        );
        return true;
      },
      pageBuilderDataEncoded: {},
      textEditorDataString: "",
      pageBuilderDataString: `{}`,
      pageBuilderIsLoading: true,
      textEditorIsLoading: true,
      setTextEditorDataEncoded: async (data) => {
        set((_) => ({ textEditorDataEncoded: data }));
        return true;
      },
      setPageBuilderDataEncoded: async (data) => {
        set((_) => ({ pageBuilderDataEncoded: data }));
        return true;
      },
      setPageBuilderDataString: async (data) => {
        set((_) => ({ pageBuilderDataString: data }));
        return true;
      },
      setTextEditorDataString: async (data) => {
        set((_) => ({ textEditorDataString: data }));
        return true;
      },
      setTextEditorIsLoading: async (loadingValue) => {
        set((_) => ({ textEditorIsLoading: loadingValue }));
        return true;
      },
      setPageBuilderIsLoading: async (loadingValue) => {
        set((_) => ({ pageBuilderIsLoading: loadingValue }));
        return true;
      },
    }
  });

export default useDataForTextEditor;