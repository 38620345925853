import { create } from "zustand";
import produce from "immer";
import { PageDataInterfaceStaffGroupForm } from "./data-interface-for-staff-groupings";
import { CrigStaffGroup } from "../../../../models";
import { RecordStatus } from "../../../../models";

const defaultData = {
  name: "",
  shortDescription: "",
  status: RecordStatus.DRAFT,
  slug: "",
  datePublished: new Date().toISOString(),
  dateUploaded: new Date().toISOString(),
};

const useDataStaffGroupingsForm = create<PageDataInterfaceStaffGroupForm>()(
  (set, get) => {
    return {
      showDialog: false,
      setFormData: async (item) => {
        set((_) => ({ formData: item }));
        return true;
      },
      formActionType: undefined,
      setShowDialog: async (value) => {
        set((_) => ({ showDialog: value }));
        return true;
      },
      formData: defaultData as unknown as CrigStaffGroup,
      setFormActionType: async (item) => {
        set((_) => ({ formActionType: item }));
        return true;
      },
      reset: async () => {
        set((_) => ({ formData: defaultData as unknown as CrigStaffGroup }));
        return true;
      },
      updateFormDataField: async (property, value) => {
        set(
          produce((state: PageDataInterfaceStaffGroupForm) => {
            state.formData![property] = value;
          })
        );
        return true;
      },
    };
  }
);

export default useDataStaffGroupingsForm;
