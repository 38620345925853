import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";


interface Props {
    show: boolean;
    onDelete: () => void;
    message: string;
    onSetVisible: (value: boolean) => void;

}
const DeleteDialog = ({ show, onSetVisible, onDelete, message }: Props) => {

    const [submitting, setSubmitting] = useState(false);

    const footerContent = (
        <div>
            {!submitting && <Button label="No" icon="pi pi-times" onClick={() => onSetVisible(false)} className="p-button-text" />}
            <Button label="Yes" className="delete-dialog-button" severity="danger" icon={`${submitting ? 'pi pi-spin pi-spinner' : ' pi pi-check'}`} onClick={() => {
                setSubmitting(true)
                onDelete();
            }} autoFocus />
        </div>
    );

    return (
        <Dialog closable={true} footer={footerContent} showHeader={true} header='ATTENTION' resizable={false} className='surface-ground t-min-h-[300px]  
      t-w-[90vw] md:t-max-w-[20vw] t-rounded-xl t-overflow-hidden
         ' visible={show} onHide={() => { onSetVisible(false) }}>
            <p className="m-0">
                {message}
            </p>
        </Dialog>
    )
}

export default DeleteDialog



