


const DateTimeDisplay = ({ value, isDanger, classes }) => {
  return (
    <div className={`t-font-inter ${isDanger ? 'countdown danger' : 'countdown'} ${classes}`}>
      <p>{value}</p>
    </div>
  );
};

export default DateTimeDisplay;