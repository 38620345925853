import { create } from "zustand";
import { DataLoadingState, Folder, S3UploadedItem } from "../../models";
import { Storage } from "aws-amplify";
import { GalleryFile } from "../toasts/forms/forms-interfaces";
import isDevMode from "../../utils/check-dev-mode";

interface OnUploadInterface {
  galleryFiles: GalleryFile[] | null;
  folder: Folder;
  totalFileSize: number;
  postId: string;
}

interface GalleryImagesDataInterface {
  onUpload: ({
    galleryFiles,
    folder,
    totalFileSize,
    postId,
  }: OnUploadInterface) => Promise<DataLoadingState | undefined>;
  onSelect: (file: File[] | null) => Promise<boolean>;
  onError: () => Promise<String>;
  onClear: () => Promise<boolean>;
  files: File[] | null | undefined;
  filesGallery: GalleryFile[] | null | undefined;
  findS3Object: (key: string) => Promise<Blob | null>;
  getS3URL: (key: string) => Promise<string | null>;
  s3Items: S3UploadedItem[] | null | undefined;
  setS3Items: (s3Items: S3UploadedItem[]) => Promise<boolean>;
  uploadStatus: DataLoadingState | undefined;
  reset: () => Promise<boolean>;
}

const useDataForGalleryImagesFile = create<GalleryImagesDataInterface>()(
  (set, get) => {
    return {
      files: null,
      filesGallery: null,
      s3Items: null,
      uploadStatus: DataLoadingState.PENDING,
      setS3Items: async (s3Items) => {
        set((_) => ({ s3Items: s3Items }));
        return true;
      },
      findS3Object: async (key) => {
        var blob: any = null;
        await Storage.get(key, {
          level: "public",
          download: true,
          contentType: "image/*",
        })
          .then((result) => {
            blob = result.Body!;
            return blob;
          })
          .catch((err) => {
            console.log(err);
            blob = null;
            return blob;
          });

        return blob;
      },
      getS3URL: async (key) => {
        var url: any = null;
        await Storage.get(key, {
          level: "public",
          contentType: "image/*",
        })
          .then((result) => {
            url = result;
            return url;
          })
          .catch((err) => {
            console.log(err);
            url = null;
            return url;
          });

        return url;
      },
      onUpload: async ({
        galleryFiles,
        folder,
        totalFileSize,
        postId,
      }: OnUploadInterface) => {
        if (isDevMode()) {
          console.log("gall", galleryFiles);
        }

        let s3Items: S3UploadedItem[] = [];
        let hasError = false;

        const uploadToBucket = async (galleryFile: GalleryFile) => {
          try {
            const result = await Storage.put(
              `${folder.slug}/${postId}/gallery/${galleryFile.file.name}`,
              galleryFile.file,
              {
                level: "public",
                contentType: galleryFile.file.type,
                completeCallback: (event) => {
                  console.log(
                    `Successfully uploaded ${galleryFile.file.name} event ${event}`
                  );
                  console.log(`Successfully uploaded ${event.key}`);
                },
                progressCallback: (progress) => {
                  console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                },
                errorCallback: (err) => {
                  console.error("Unexpected error while uploading", err);
                },
              }
            );
            if (result != null) {
              if (result.key.length > 0) {
                const url = await get().getS3URL(result.key);

                const newS3Item: S3UploadedItem = {
                  key: result.key,
                  size: galleryFile.file.size,
                  lastModified: new Date().toISOString(),
                  signedURL: url,
                  blurHash: galleryFile.encodedBlurhash,
                  eTag: `${folder.slug}/${galleryFile.file.name}`,
                };
                console.log("onUpload results with key", result);
                return newS3Item;
              }
            }
            console.log("onUpload results", result);
            return null;
          } catch (error) {
            console.error("Unexpected error while uploading", error);
            return null;
          }
        };

        for (var item of galleryFiles!) {
          if (isDevMode()) {
            console.log("gallery item to upload", item);
          }

          const s3Item = await uploadToBucket(item);
          if (s3Item == null) {
            hasError = true;
          } else {
            s3Items.push(s3Item);
          }
        }
        set((_) => ({ s3Items: s3Items }));

        if (hasError) {
          return DataLoadingState.ERROR;
        }

        return DataLoadingState.LOADED;
      },
      onSelect: async (items) => {
        set((_) => ({ files: items }));
        return true;
      },
      onError: async () => {
        return "error Message";
      },
      onClear: async () => {
        set((_) => ({ files: null, s3Items: null, filesGallery: null }));
        return true;
      },

      reset: async () => {
        set((_) => ({
          files: null,
          filesGallery: null,
          s3Items: null,
        }));
        return true;
      },
    };
  }
);

export default useDataForGalleryImagesFile;
