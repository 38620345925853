import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { FormActionType } from "../../../../models";
import useRoutePaths from "../../../../routes/route-path";
import CustomNavigation from "../../../../shared-components/navigation/CustomNavigation";
import useDataStaffUnitDepartmentForm from "../data/data-for-staff-unit-departments-form";
import FormUnitsDepartment from "../form/form-for-staff-unit-departments";
import TableStaffUnitDepartment from "../tables/table-for-staff-unit-departments";

function ListStaffUnitDepartment() {
  const { paths: route } = useRoutePaths();
  const navigation = useNavigate();
  const setFormActionType = useDataStaffUnitDepartmentForm(
    (state) => state.setFormActionType
  );

  const setShowDialog = useDataStaffUnitDepartmentForm(
    (state) => state.setShowDialog
  );


  const showDialog = useDataStaffUnitDepartmentForm(
    (state) => state.showDialog
  );


  return (
    <div className="flex t-flex-col t-overflow-hidden t-justify-start t-h-fit t-rounded-lg t-shadow-lg t-m-3">
      <div className="">
        <CustomNavigation
          background={false}
          backArrow={true}
          backArrowNavUrl={route.settings.initial}
          subTitle="Settings"
          title="CRIG Staff Unit/Departments"
          buttons={
            <Button
              label="Add New"
              className="list-top-nav-add-button t-font-inter !t-font-semibold"
              onClick={() => {
                setFormActionType(FormActionType.NEW);
                setShowDialog(true);
              }}
              icon="pi pi-plus"
            />
          }
        />
      </div>
      {showDialog && <FormUnitsDepartment />}
      <TableStaffUnitDepartment />
    </div>
  );
}

export default ListStaffUnitDepartment;
