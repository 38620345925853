import { FormikProvider, useFormik } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useRoutePaths from "../../routes/route-path";
import FormErrorMsg from "../../shared-components/form/ErrorMsg";
import { useServiceAuthentication } from "../../shared-services/use-service-authentication";
import { SignInInfo } from "../../store/auth/data-interface-for-user-auth";
import { useDataAuthPages } from "./data/data-for-auth-pages";
import { SchemaForAuthSignIn } from "./form-schemas/form-schema-for-auth-sign-in";
import Logo from "/img/crig-logo.png";
import BgImg from "/img/sign-in-bg.jpg";
import useToaster from "../../shared-components/toasts/Toaster";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import isDevMode from "../../utils/check-dev-mode";

const pw = "IdcCrig@2k23";

function SignIn() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();

  const { paths: route } = useRoutePaths();

  const [validateForm, setValidateForm] = useState<boolean>(false);
  const toast = useRef(null);

  const { showError } = useToaster();

  const setSignInInfo = useDataAuthPages((state) => state.setSignInInfo);
  const setNumberSentTo = useDataAuthPages((state) => state.setNumberSentTo);

  const { signIn, signOut } = useServiceAuthentication();

  const formFormik = useFormik({
    initialValues: { password: "", username: "" },
    validateOnBlur: false,
    validateOnChange: validateForm,

    validate: (values) => {
      setValidateForm(true);
      console.log("validate", values);
      console.log("errors", formFormik.errors);
    },
    onSubmit: async (values) => {
      console.log("submit", values);
      await authSignUserIn(values);
    },

    validationSchema: SchemaForAuthSignIn,
  });

  const authSignUserIn = async (values: SignInInfo) => {
    try {
      await signOut().then(async () => {
        setSignInInfo(values);
        await signIn(values).then((results) => {
          
          if (results != null) {
            let errorMsg = (results as Error).message ?? "";

            if (errorMsg.includes("OtpCreated")) {
              let numberSentTo = errorMsg.substring(
                errorMsg.lastIndexOf("NumberSentTo") + 14
              );
              setNumberSentTo(numberSentTo);
              navigate(route.auth2fa);
              return;
            }

            // showError(toast, 'ATTENTION', 'Kindly recheck credentials and try again.');
            setShowDialog(true);
            return;
          }
          setShowDialog(true);
          // showError(toast, 'ATTENTION', 'Kindly recheck credentials and try again.');
          return;
        });
      });
    } catch (error) {
      if (isDevMode()) {
        console.log("errSignIn");
      }
    }
  };

  const dialog = (
    <Dialog
      className="md:t-max-w-[30vw] t-mx-4 t-w-full"
      header="ATTENTION"
      visible={showDialog}
      onHide={() => setShowDialog(false)}
      footer={
        <Button
          label="OKAY"
          icon=""
          onClick={() => setShowDialog(false)}
          autoFocus
        />
      }
    >
      <p className="m-0">
        Something went wrong, kindly check your credentials and try again.
      </p>
    </Dialog>
  );

  return (
    <FormikProvider value={formFormik}>
      <form onSubmit={formFormik.handleSubmit}>
        <div
          className="main-container t-flex t-bg-appbrown-500
     t-items-center t-h-screen md:t-w-full"
        >
          <div
            className=" t-bg-white t-flex-col  t-rounded-[0px] md:t-rounded-[10px]   
      t-scale-100  2xl:t-scale-125  md:t-flex-row login-container"
          >
            {/* Login Image */}
            <div className="t-flex t-h-inherit t-rounded-[10px] md:t-flex-1 ">
              <div className="t-flex t-h-inherit t-rounded-[10px] md:t-flex-1 ">
                <Image
                  style={{ height: "100% !important" }}
                  imageStyle={{
                    height: "100%",
                    objectFit: "cover",
                    overflow: "hidden",
                    objectPosition: "left",
                  }}
                  className="!t-h-full t-object-cover  t-rounded-[10px] t-overflow-hidden t-relative t-z-[0] t-flex"
                  src={BgImg}
                />
              </div>
              <div className="t-absolute t-p-4 t-w-[80px] t-h-[80px] t-z-[2]">
                <Image src={Logo} />
                {/* <img
                  src={Logo}
                  alt="Logo"
                  width={80}
                  height={80}
                  className="login-logo"
                /> */}
              </div>
              {/* <div className="t-absolute t-bg-slate-300 t-flex t-h-full t-w-full t-rounded-[10px] t-flex-1  t-z-[0]" /> */}
            </div>
            {/* Sign in Form */}
            <div className="login-form-container t-z-[3] t-rounded-[0px] md:t-rounded-[10px] t-bg-white">
              <p className="text-primary-500 login-header">Sign In</p>
              <p className="t-text-appbrown-300 mb-5 t-font-normal t-font-inter">
                Approved users only
              </p>
              <div className="login-field">
                <p className="t-text-appgrey-800 login-input-label t-font-medium t-font-inter">
                  Your Email
                </p>
                <span className="p-input-icon-left login-field w-full p-input-filled">
                  <i className="pi pi-envelope pi-envelope" />
                  <InputText
                    id="username"
                    readOnly={formFormik.isSubmitting}
                    autoComplete={"username"}
                    value={formFormik.values.username}
                    onChange={formFormik.handleChange}
                  />
                </span>
                <FormErrorMsg
                  fieldId={"username"}
                  errorMsg={formFormik.errors.username}
                />
              </div>
              <div className="mt-4 w-full login-field">
                <p className="t-text-appgrey-800 t-font-medium t-font-inter">
                  Password
                </p>
                <span className="p-input-icon-left w-full  p-input-icon-right p-input-filled">
                  <i className="pi pi-lock pi-pi-lock" />
                  <InputText
                    id="password"
                    readOnly={formFormik.isSubmitting}
                    value={formFormik.values.password}
                    autoComplete={"password"}
                    onChange={formFormik.handleChange}
                    type={`${passwordShown ? "text" : "password"}`}
                  />
                  <i
                    className={`pi t-transition-all t-ease-in-out ${
                      passwordShown ? "pi-eye-slash" : "pi-eye"
                    } p-ripple hover:t-cursor-pointer`}
                    onClick={() => {
                      setPasswordShown((prev) => !prev);
                    }}
                  />
                </span>
                <FormErrorMsg
                  fieldId={"password"}
                  errorMsg={formFormik.errors.password}
                />
              </div>
              {/* <div className="t-w-full t-flex t-items-center t-justify-center t-mt-4">
                <Captcha siteKey="YOUR_SITE_KEY" onResponse={() => { }} />
              </div> */}
              {!formFormik.isSubmitting && (
                <Button
                  type="submit"
                  className="p-button-secondary p-button-rounded login-submit w-full mt-4 mb-3"
                  label="SUBMIT"
                  icon={`pi ${
                    formFormik.isSubmitting
                      ? "pi-spin pi-spinner"
                      : "pi-angle-right"
                  }`}
                  iconPos="right"
                />
              )}
              {formFormik.isSubmitting && (
                <Button
                  type="button"
                  className="p-button-secondary p-button-rounded login-submit w-full mt-4 mb-3"
                  label="..."
                  icon={`pi pi-spin pi-spinner`}
                  iconPos="right"
                />
              )}
            </div>
          </div>
        </div>
      </form>
      <Toast ref={toast} position="top-right"></Toast>
      {dialog}
    </FormikProvider>
  );
}

export default SignIn;
