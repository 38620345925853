import { useEffect } from "react";
import {
  DataLoadingState,
  LazyUserAccount,
  UserAccount,
  UserCategoryType,
} from "../../../models";
import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../../graphql/subscriptions";
import useDataForUserAuth from "../../../store/auth/data-for-auth";
import isDevMode from "../../../utils/check-dev-mode";
import useDataCmsUsersList from "../data/data-for-cms-users-list";
import useDataCmsUsersForm from "../data/data-for-cms-users-form";
import {
  createUserAccountGQL,
  deleteUserAccountGQL,
  getUserAccountGQL,
  listUserAccountsGQL,
  updateUserAccountGQL,
} from "../../../custom-graphql/custom-gql-user-account";
import { deleteFieldsForSave } from "../../../utils/forms/util-forms";
import {
  UpdateUserAccountInput,
  UpdateUserAccountMutation,
} from "../../../API";

import * as mutations from "../../../graphql/mutations";
import { GraphQLQuery } from "@aws-amplify/api";
import { listUserAccounts } from "../../../graphql/queries";
interface ServiceInterface {
  addNewData: (item: UserAccount) => Promise<LazyUserAccount | null>;
  updateData: (item: LazyUserAccount, isDelete: boolean, isSignUp: boolean) => Promise<LazyUserAccount | null>;
  updateDataField: (id: string, key: string, value: any) => Promise<boolean>;
  deleteData: (item: LazyUserAccount) => Promise<boolean>;
  findOneData: (id: string) => Promise<LazyUserAccount | null>;
  listAllData: () => Promise<boolean | null>;
}
const pageModelName = "cms-users";

export function useServiceForCMSUsersAPI(): ServiceInterface {
  const pageDataAdd = useDataCmsUsersList((state) => state.addItem);
  const loggedUser = useDataForUserAuth((state) => state.loggedUser);
  const setFormData = useDataCmsUsersForm((state) => state.setFormData);
  const formData = useDataCmsUsersForm((state) => state.formData);
  const pageDataSetStatusForLoading = useDataCmsUsersList(
    (state) => state.setStatusForLoadingListItems
  );

  const pageDataSetList = useDataCmsUsersList((state) => state.setListItems);
  const pageDataUpdate = useDataCmsUsersList((state) => state.updateItem);
  const pageDataDelete = useDataCmsUsersList((state) => state.deleteItem);

  const addNewData = async (item: UserAccount) => {
    let newItem: UserAccount;
    let dataResult: UserAccount;

    newItem = {
      ...item,
      category: UserCategoryType.CMS_USER,
      dateUpdated: new Date().toISOString(),
    };

    newItem = deleteFieldsForSave({ pageModelName, newItem });

    if (isDevMode()) {
      console.log(`addNew ${pageModelName} data`, newItem);
    }
    try {
      const results: any = await API.graphql(
        graphqlOperation(createUserAccountGQL, { input: newItem })
      );
      console.log(`addNew ${pageModelName} results`, results);
      dataResult = results.data.createUserAccount;

      return dataResult;
    } catch (error) {
      console.log(`addNew ${pageModelName} onError`, error);
      return null;
    }
  };

  const updateData = async (item: LazyUserAccount, isDelete: boolean, isSignUp: boolean) => {
    let newItem: any;
 
    newItem = {
      ...item,
      dateUpdated: new Date().toISOString(),
    } as unknown as LazyUserAccount;

    if (isDelete) {
      newItem = {
        ...item,
        deleted: true,
      };
    }

   

    console.log("newItem", newItem);

    try {
      const updateInput: any = {
        id: newItem.id,
        _version: newItem._version,
        profileImage: newItem.profileImage,
        category: newItem.category,
        deleted: newItem.deleted,
        nameFirst: newItem.nameFirst,
        nameLast: newItem.nameLast,
        nameFull: newItem.nameFull,
        authId: newItem.authId,
        status: newItem.status,
        contactPhoneNumber1: newItem.contactPhoneNumber1,
        contactPhoneNumber2: newItem.contactPhoneNumber2,
        contactEmail1: newItem.contactEmail1,
        contactEmail2: newItem.contactEmail2,
        countryIso: newItem.countryIso,
        countryName: newItem.countryName,
        staffProfileID: newItem.staffProfileID,
        userRoleID: newItem.userRoleID,
        contentFollowing: newItem.contentFollowing,
        dateLastLogin: newItem.dateLastLogin,
        dateAdded: newItem.dateAdded,
        dateAddedYear: newItem.dateAddedYear,
        dateAddedMonth: newItem.dateAddedMonth,
        dateAddedDay: newItem.dateAddedDay,
        dateUpdated: newItem.dateUpdated,
        dateUpdatedYear: newItem.dateUpdatedYear,
        dateUpdatedMonth: newItem.dateUpdatedMonth,
        dateUpdatedDay: newItem.dateUpdatedDay,
        totalViewsCount: newItem.totalViewsCount,
        totalPostDownloadsCount: newItem.totalPostDownloadsCount,
        totalPostViewsCount: newItem.totalPostViewsCount,
      };

      const savedDetails = await API.graphql<
        GraphQLQuery<UpdateUserAccountMutation>
      >({
        query: mutations.updateUserAccount,
        variables: { input: updateInput },
      });


      if (isDevMode()) {
        console.log(`update ${pageModelName} results`, savedDetails);
      }

      return savedDetails.data?.updateUserAccount as unknown as LazyUserAccount;

      // const results: any = await API.graphql(
      //   graphqlOperation(updateUserAccountGQL, { input: newItem })
      // );
      // console.log(`update ${pageModelName} results`, results);
      // dataResult = results.data.updateUserAccount;
      // if (isDelete) {
      //   return dataResult;
      // } else {
      //   return true;
      // }
    } catch (error) {
      console.log(`update ${pageModelName} onError`, error);
      return null;
    }
  };

  const listAllData = async () => {
    let foundItems: UserAccount[] | null | undefined;

    const variables = {
      filter: {
        // or: [
        //   { category: { eq: UserCategoryType.CMS_USER } },
        //   // { category: { eq: UserCategoryType.BOTH } },
        // ],
        and: [
          { category: { eq: UserCategoryType.CMS_USER } },
          {
            deleted: {
              eq: false,
            },
          },
        ],
      },
    };

    try {
      const results: any = await API.graphql({
        query: listUserAccounts,
        variables: variables,
      });
      foundItems = results.data.listUserAccounts.items as UserAccount[];

      if (isDevMode()) {
        console.log(`listAll ${pageModelName} foundItems`, foundItems);
      }

      foundItems.sort(
        (a, b) =>
          new Date(b.dateAdded!).getTime() - new Date(a.dateAdded!).getTime()
      );
      pageDataSetList(foundItems);
      pageDataSetStatusForLoading(DataLoadingState.LOADED);
      return true;
    } catch (error) {
      if (isDevMode()) {
        pageDataSetStatusForLoading(DataLoadingState.LOADED);
        console.log(`listAll ${pageModelName} error`, error);
      }

      return false;
    }
  };

  const findOneData = async (id: string) => {
    let foundItem: UserAccount | null;
    try {
      const results: any = await API.graphql(
        graphqlOperation(getUserAccountGQL, { id: id })
      );

      if (isDevMode()) {
        console.log(`findOneData ${pageModelName} results`, results);
      }

      foundItem = results.data.getUserAccount;

      return foundItem;
    } catch (error) {
      if (isDevMode()) {
        console.log(`findOneData ${pageModelName} onError`, error);
      }

      return null;
    }
  };

  const deleteData = async (item: any) => {
    let dataResult: UserAccount;
    console.log(item);
    try {
      item = { ...item, deleted: true };

      const results: any = await API.graphql(
        graphqlOperation(deleteUserAccountGQL, {
          input: { id: item.id, _version: item._version },
        })
      );

      if (isDevMode()) {
        console.log(`delete ${pageModelName} results`, results);
      }

      dataResult = results.data.deleteUserAccount;

      await updateData(dataResult, true, false);

      return true;
    } catch (error) {
      if (isDevMode()) {
        console.log(`update ${pageModelName} onError`, error);
      }

      return false;
    }
  };

  //FIGURE OUT API
  const updateDataField = async (id: string, key: string, value: any) => {
    return false;
  };

  useEffect(() => {
    const createSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onCreateUserAccount, {
          filter: {
            or: [
              { category: { eq: UserCategoryType.CMS_USER } },
              // { category: { eq: UserCategoryType.BOTH } },
            ],
          },
        })
      ) as unknown as any
    ).subscribe({
      next: async ({ provider, value }) => {
        console.log({ provider, value });
        let data = value.data.onCreateUserAccount as UserAccount;
        let url: string | null = "";

        pageDataAdd(data);
        if (formData?.id == data.id) {
          setFormData(data);
        }
      },
      error: (error: any) => {
        console.warn(error);
      },
    });

    const updateSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onUpdateUserAccount, {
          filter: {
            or: [
              { category: { eq: UserCategoryType.CMS_USER } },
              // { category: { eq: UserCategoryType.BOTH } },
            ],
          },
        })
      ) as unknown as any
    ).subscribe({
      next: async ({ provider, value }) => {
        console.log({ provider, value });
        let data = value.data.onUpdateUserAccount as UserAccount;
        let url: string | null = "";

        pageDataUpdate(data);
        if (formData?.id == data.id) {
          setFormData(data);
        }
      },
      error: (error: any) => {
        console.warn(error);
      },
    });

    const deleteSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onDeleteUserAccount, {
          filter: {
            or: [
              { category: { eq: UserCategoryType.CMS_USER } },
              { category: { eq: UserCategoryType.BOTH } },
            ],
          },
        })
      ) as unknown as any
    ).subscribe({
      next: ({ provider, value }) => {
        console.log({ provider, value });
        pageDataDelete(value.data.onDeleteUserAccount as UserAccount);
      },
      error: (error: any) => {
        console.warn(error);
      },
    });

    return () => {
      const cleanupSubscriptions = () => {
        createSubscription.unsubscribe();
        updateSubscription.unsubscribe();
        deleteSubscription.unsubscribe();
      };
      cleanupSubscriptions();
    };
  }, []);

  return {
    addNewData,
    updateData,
    updateDataField,
    deleteData,
    findOneData,
    listAllData,
  };
}
