import { create } from "zustand";
import { DataLoadingState } from "../../models";
import { LoggedUser } from "./data-interface-for-user-auth";

interface DataForUserAuthInterface {
    loggedUser: LoggedUser | null;
    setLoggedUser: (item: LoggedUser) => Promise<boolean>;
    loadingCredentials: string,
    setLoadingCredentials: (newVal: DataLoadingState) => Promise<boolean>;
}

const useDataForUserAuth =
    create<DataForUserAuthInterface>()((set, get) => {
        return {
            loggedUser: null,
            setLoggedUser: async (item) => {
                set((_) => ({ loggedUser: item }));
                return true;
            },
            loadingCredentials: DataLoadingState.PENDING,
            setLoadingCredentials: async (newVal) => {
                set((_) => ({ loadingCredentials: newVal }));
                return true;
            },
        }
    });

export default useDataForUserAuth;