import * as Yup from "yup";

export const SchemaForUserRole = Yup.object().shape({
  title: Yup.string()
    .min(1, "Too Short!")
    .max(100, "Too Long!")
    .required("Required"),
  shortDescription: Yup.string()
    .min(1, "Too Short!")
    .max(1000, "Too Long!")
    .required("Required"),
  pageDesignTextEditor: Yup.string().optional(),
  dateAdded: Yup.date().required("Required").default(new Date()),
  status: Yup.string().required("Required").default("draft"),
});
