import { FormikProvider, useFormik } from "formik";
import { EditorType, Folder, FormActionType, Page, PageType, S3UploadedItem } from "../../../models";
import useRoutePaths from "../../../routes/route-path";
import FormActionButtonSet from "../../../shared-components/button/FormActionButtonSet";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import useDataRecreationFacilitiesForm from "../data/data-for-recreation-facilities-form";
import { SchemaForRecreationFacilities } from "../form-schemas/form-schema-for-recreation-facilities";
import { useCallback, useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Chips } from 'primereact/chips';
import { Dropdown } from 'primereact/dropdown';
import { formatDateToIsoString, formatIsoStringToDate, selectPageDesignTypes, selectPublishStatusType, slugify } from "../../../utils/forms/util-forms";
import { Calendar } from 'primereact/calendar';
import { CoverImageUploader } from '../../../shared-components/images/CoverImageUploader';
import SectionHeader from '../../../shared-components/header/SectionHeader';
import TextEditorCK from "../../../editors/ckeditorjs/TextEditorCKEditor";
import { StatusTypeSelect } from "../../../shared-components/toasts/forms/forms-interfaces";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataForTextEditor from "../../../editors/data-for-editor";
import useDataFilesManagerList from "../../page-files-manager/data/data-for-folders-list";
import useToaster from "../../../shared-components/toasts/Toaster";
import { useServiceForRecreationFacilitiesAPI } from "../services/services-for-recreation-facilities";
import FormErrorMsg from "../../../shared-components/form/ErrorMsg";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import PageBuilder from "../../../editors/grapejs/PageBuilderGrape";
import ErrorDialog from "../../../shared-components/form/ErrorDialog";
import LoadingDialog from "../../../shared-components/form/LoadingDialog";
import { GalleryImagesUploader } from "../../../shared-components/images/GalleryImagesUploader";
import { v4 as uuid } from 'uuid';
import useDataForGalleryImagesFile from "../../../shared-components/images/data-gallery-images";


function FormRecreationFacilities() {
  const { paths: route } = useRoutePaths();


  const formData = useDataRecreationFacilitiesForm((state) => state.formData);

  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const [postId, setPostId] = useState('');

  const [errorMsg, _setErrorMsg] = useState('Something went wrong kindly try again.');

  const formAction = useDataRecreationFacilitiesForm(
    (state) => state.formActionType
  );

  const formActionSetState = useDataRecreationFacilitiesForm(
    (state) => state.setFormActionType
  );

  const [pageDesignView, pageDesignViewSetState] = useState<string>(formData?.pageDesignType ?? EditorType.TEXT_EDITOR);

  const [validateForm, setValidateForm] = useState<boolean>(false);

  const [folder, setFolder] = useState<Folder | null | undefined>(null);

  const [formItemStatus, setFormItemStatus] = useState<StatusTypeSelect | null>(selectPublishStatusType[0]);

  const [_formItemPageDesignSelect, setFormItemPageDesignSelect] = useState<StatusTypeSelect | null>(selectPageDesignTypes[0]);

  const coverImageFile = useDataForCoverImageFile((state) => state.file);

  const coverImageS3Item = useDataForCoverImageFile((state) => state.s3Item);

  const coverImageSetS3Item = useDataForCoverImageFile((state) => state.setS3Item);

  const setTextEditorIsLoading = useDataForTextEditor((state) => state.setTextEditorIsLoading);

  const setTextEditorDataString = useDataForTextEditor((state) => state.setTextEditorDataString);

  const setPageBuilderIsLoading = useDataForTextEditor((state) => state.setPageBuilderIsLoading);

  const setPageBuilderDataString = useDataForTextEditor((state) => state.setPageBuilderDataString);

  const setPageBuilderDataEncoded = useDataForTextEditor((state) => state.setPageBuilderDataEncoded);

  const { addNewData, updateData } = useServiceForRecreationFacilitiesAPI();

  const findOneFolderByPageType = useDataFilesManagerList((state) => state.findOneFolderByPageType);

  const listItemsFolders = useDataFilesManagerList((state) => state.listItemsFolders);

  const textEditorIsLoading = useDataForTextEditor((state) => state.textEditorIsLoading);

  const textEditorDataString = useDataForTextEditor((state) => state.textEditorDataString);

  const pageBuilderDataString = useDataForTextEditor((state) => state.pageBuilderDataString);

  const { showError, showSuccess } = useToaster();

  const galleryImagesS3Items = useDataForGalleryImagesFile((state) => state.s3Items) ?? [];

  const galleryImagesS3ItemsSet = useDataForGalleryImagesFile((state) => state.setS3Items) ?? [];

  const galleryImagesFiles = useDataForGalleryImagesFile((state) => state.files);


  const toast = useRef(null);
  const refTitle = useRef(null);
  const refSlug = useRef(null);
  const refCoverImage = useRef(null);
  const refPageDesignType = useRef(null);
  const refDetails = useRef(null);
  const refTags = useRef(null);
  const refDateUploaded = useRef(null);
  const refDatePublished = useRef(null);
  const refStatus = useRef(null);
  const refGalleryImages = useRef(null)


  const formFormik = useFormik({
    initialValues: formData as Page,
    validateOnBlur: false,
    validateOnChange: validateForm,

    validate: (values) => {
      setValidateForm(true);
      console.log("validate", values);
      console.log("errors", formFormik.errors);
    },
    onSubmit: async (values) => {
      console.log("submit", values);


      await saveForm(values);

    },

    validationSchema: SchemaForRecreationFacilities,
  });

  const getStatus = (value: string) => {
    function findIndexFxn(item: StatusTypeSelect) {
      return item.value === value;
    }
    const index = selectPublishStatusType.findIndex(findIndexFxn);

    const item = selectPublishStatusType[index];

    return item;
  }

  const getPageDesignType = (value: string) => {
    function findIndexFxn(item: StatusTypeSelect) {
      return item.value === value;
    }
    const index = selectPageDesignTypes.findIndex(findIndexFxn);

    const item = selectPageDesignTypes[index];

    return item;
  }


  async function saveForm(values: Page) {
    let newItem: Page;
    console.log(formAction);

    switch (formAction ?? FormActionType.NEW) {
      case FormActionType.NEW:

        newItem = new Page({
          title: values.title,
          pageType: values.pageType!,
          status: values.status!,
          coverImage: coverImageS3Item,
          galleryImages: galleryImagesS3Items,
          dateAdded: formatDateToIsoString(values.dateAdded!)!,
          datePublished: formatDateToIsoString(values.datePublished!)!,
          dateSent: new Date().toISOString(),
          dateUpdated: new Date().toISOString(),
          pageDesignPageBuilder: pageBuilderDataString,
          pageDesignTextEditor: textEditorDataString,
          pageDesignType: values.pageDesignType,
          details: values.details,
          slug: values.slug,
          tags: values.tags,
          deleted: false
        });


        await addNewData(newItem).then(() => {
          showSuccess(toast, 'ATTENTION', 'Successfully saved.');
          formActionSetState(FormActionType.EDIT)
          formFormik.setSubmitting(false);
          console.log('done addNewRecreationFacility');
        }).catch((err) => {
          console.log('err submitting addNewRecreationFacility', err);
          showError(toast, 'ATTENTION', 'Something went wrong, kindly try again.');
          setShowErrorDialog(true);
          formFormik.setSubmitting(false);
        })
        break;

      case FormActionType.EDIT:
        newItem = {
          ...values,
          coverImage: coverImageS3Item,
          galleryImages: galleryImagesS3Items,
          id: postId,
          dateAdded: formatDateToIsoString(values.dateAdded!)!,
          datePublished: formatDateToIsoString(values.datePublished!)!,
          deleted: false
        }

        await updateData(newItem, false).then(async (done) => {
          showSuccess(toast, 'ATTENTION', 'Successfully saved.');
          formFormik.setSubmitting(false);

        }).catch((error) => {
          console.log('err submitting updateRecreationFacility', error);
          showError(toast, 'ATTENTION', 'Something went wrong, kindly try again.');
          setShowErrorDialog(true);
          formFormik.setSubmitting(false);
        });
        break;

      default:
        break;
    }
  }

  const changePageDesignView = useCallback(async () => {
    switch (pageDesignView) {
      case EditorType.TEXT_EDITOR:
        pageDesignViewSetState((_) => EditorType.PAGE_BUILDER);

        break;

      case EditorType.PAGE_BUILDER:
        pageDesignViewSetState((_) => EditorType.TEXT_EDITOR);
        break;

      default:
        pageDesignViewSetState((_) => EditorType.TEXT_EDITOR);
        break;
    }
  }, [pageDesignView]);

  const onChangeSlug = (e: any) => {

    const slugged = slugify(e.target.value);

    formFormik.setFieldValue('slug', slugged);
    return;
  }

  useEffect(() => {
    if (postId.length == 0) {
      setPostId(uuid());
    } else if ((formData?.id ?? '').length > 0) {
      setPostId(formData!.id);
    }

    return () => {
      if (postId.length != 0) return;
    }
  }, [postId, formData])

  useEffect(() => {

    formFormik.setValues(formData!)

    return () => { }
  }, [formData])

  useEffect(() => {

    if (formFormik.values.title.length != 0) {
      const slugged = slugify(formFormik.values.title);
      formFormik.setFieldValue('slug', slugged);
    }

    return () => { }
  }, [formFormik.values.title])

  useEffect(() => {
    if (pageDesignView === EditorType.PAGE_BUILDER) {
      const dataStringBuilder = formFormik.values.pageDesignPageBuilder ?? '';

      if (dataStringBuilder.length == 0) {
        setPageBuilderDataEncoded(JSON.parse('{}'));
        setPageBuilderDataString('');
        setPageBuilderIsLoading(false);
        return;
      };

      const dataJsonBuilder = JSON.parse(dataStringBuilder);
      setPageBuilderDataEncoded(dataJsonBuilder);
      setPageBuilderDataString(dataStringBuilder);
      setPageBuilderIsLoading(false);
    }

    if (pageDesignView === EditorType.TEXT_EDITOR) {
      const dataStringTextEditor = formFormik.values.pageDesignTextEditor ?? '';

      if (dataStringTextEditor.length == 0) {
        setTextEditorDataString('');
        setTextEditorIsLoading(false);
        return;
      };
      setTextEditorDataString(dataStringTextEditor);
      setTextEditorIsLoading(false);
    }

    return () => { }
  }, [pageDesignView])


  //GALLERY IMAGES SET
  useEffect(() => {


    if (formData?.galleryImages != null && galleryImagesS3Items.length == 0) {
      formFormik.setFieldValue('galleryImages', galleryImagesS3Items);
      galleryImagesS3ItemsSet(formData.galleryImages as S3UploadedItem[]);
    }

    return () => { };
  }, [galleryImagesFiles, galleryImagesS3Items, formData?.galleryImages]);


  useEffect(() => {
    setFormItemStatus(getStatus(formFormik.values.status));
    return () => { }
  }, [formFormik.values.status])

  useEffect(() => {
    formFormik.setFieldValue('pageDesignTextEditor', textEditorDataString);
    return () => { }
  }, [textEditorDataString])

  useEffect(() => {
    formFormik.setFieldValue('pageDesignPageBuilder', pageBuilderDataString);
    return () => { }
  }, [pageBuilderDataString])

  useEffect(() => {
    setFormItemPageDesignSelect(getPageDesignType(formFormik.values.pageDesignType!));
    return () => { }
  }, [formFormik.values.pageDesignType])


  useEffect(() => {
    formFormik.setFieldValue('coverImage', coverImageS3Item);

    if (formData?.coverImage != null && coverImageS3Item == null) {
      coverImageSetS3Item(formData.coverImage);
    }



    return () => { };
  }, [coverImageFile, coverImageS3Item, formData?.coverImage]);

  useEffect(() => {
    if (folder != null) return;

    const fetchData = async () => {
      const folderFound = await findOneFolderByPageType(PageType.RECREATION_FACILITY);
      setFolder(folderFound);
    }



    fetchData().catch(console.error);;

    return () => {
      if (folder != null) return;
    }
  }, [folder, listItemsFolders])


  const fieldLabels = {
    coverImage: {
      id: "coverImage",
      ref: refCoverImage,
      label: "Cover Image",
    },
    pageDesignType: {
      id: "pageDesignType",
      ref: refPageDesignType,
      label: "Page Design Type",
    },
    title: {
      id: "title",
      ref: refTitle,
      label: "Title",
    },
    slug: {
      id: "slug",
      ref: refSlug,
      label: "Slug",
    },
    details: {
      id: "details",
      ref: refDetails,
      label: "Details (optional)",
    },
    tags: {
      id: "tags",
      ref: refTags,
      label: "Tags (optional)",
    },
    dateUploaded: {
      id: "dateAdded",
      ref: refDateUploaded,
      label: "Date Uploaded",
    },
    datePublished: {
      id: "datePublished",
      ref: refDatePublished,
      label: "Date Published",
    },
    status: {
      id: "status",
      ref: refStatus,
      label: "Status",
    },
    galleryImages: {
      id: "galleryImages",
      ref: refGalleryImages,
      label: "Gallery Images",
    },
  };

  const fieldSlug = (
    <div className="form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.slug.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.slug.id}
          onChange={onChangeSlug}
          onBlur={formFormik.handleBlur}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.slug ?? ''}
          ref={fieldLabels.slug.ref}
        />
      </span>
      <FormErrorMsg fieldId={fieldLabels.slug.id} errorMsg={formFormik.errors.title} />
    </div>
  );

  const fieldTitle = (
    <div className="form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.title.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.title.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.title}
          ref={fieldLabels.title.ref}
        />
      </span>
      <FormErrorMsg fieldId={fieldLabels.title.id} errorMsg={formFormik.errors.title} />
    </div>
  );

  const fieldDetails = (
    <div className="t-pl-[0rem] t-w-full ">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.details.label}
      </p>

      <div className={` w-full 
       p-input-filled t-border-[2px] t-shadow-none    
       t-pt-[0px] t-rounded-lg t-h-full form-text-editor t-overflow-hidden 
       ${textEditorIsLoading ? 't-hidden' : 't-flex'}
        t-border-gray-300 t-flex-col t-h-full`}>
        <TextEditorCK holder="editorjs-container" />
      </div>
      <div className={`w-full 
        t-border-[2px] 
       t-pt-[0px] form-field t-rounded-lg
       ${textEditorIsLoading ? 't-flex' : 't-hidden'}
        t-border-gray-300 t-flex-col t-h-full`}>

        <Skeleton width="100%" height="500px" />
      </div>
    </div>
  );

  const fieldTags = (
    <div className="t-w-full form-field">
      <p className="t-text-appgrey-800 t-mt-10 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.tags.label}
      </p>
      <span className="t-w-full  p-input-filled">
        <Chips
          id={fieldLabels.tags.id}
          value={formFormik.values.tags ?? []}
          ref={fieldLabels.tags.ref}
          className="t-w-full t-min-h-[100px] tags-input t-items-start"
          disabled={formFormik.isSubmitting}
          // onBlur={formFormik.handleBlur}
          onChange={formFormik.handleChange}
        ></Chips>
      </span>
    </div>
  );

  const fieldDateUploaded = (
    <div className="t-w-1/2 t-mr-4 form-field date-pick">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.dateUploaded.label}
      </p>
      <Calendar
        id={fieldLabels.dateUploaded.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        disabled={formFormik.isSubmitting}
        dateFormat="dd/mm/yy"
        value={formatIsoStringToDate(formFormik.values.dateAdded!)}
        ref={fieldLabels.dateUploaded.ref}
        className="w-full p-input-filled"
        showIcon
        iconPos="right"
      />
      <FormErrorMsg fieldId={fieldLabels.dateUploaded.id} errorMsg={formFormik.errors.dateAdded} />
    </div>
  );

  const fieldDatePublished = (
    <div className="t-w-1/2 t-ml-4 form-field date-pick">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.datePublished.label}
      </p>
      <Calendar
        id={fieldLabels.datePublished.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        disabled={formFormik.isSubmitting}
        dateFormat="dd/mm/yy"
        value={formatIsoStringToDate(formFormik.values.datePublished!)}
        ref={fieldLabels.datePublished.ref}
        className="w-full p-input-filled"
        showIcon
        iconPos="right"
      />
      <FormErrorMsg fieldId={fieldLabels.datePublished.id} errorMsg={formFormik.errors.datePublished} />
    </div>
  );


  const fieldPageDesignType = (
    <div className="t-w-full form-field">
      <p className="t-text-appgrey-800 t-mt-8 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.pageDesignType.label}{" "}
      </p>
      <Dropdown
        className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
        options={selectPageDesignTypes}
        optionLabel="name"
        placeholder="Select"
        id={fieldLabels.pageDesignType.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        disabled={formFormik.isSubmitting}
        value={formFormik.values.pageDesignType}
        ref={fieldLabels.pageDesignType.ref}
      />
      <FormErrorMsg fieldId={fieldLabels.pageDesignType.id} errorMsg={formFormik.errors.pageDesignType} />
    </div>
  );

  const fieldStatus = (
    <div className="t-w-full form-field">
      <p className="t-text-appgrey-800 t-mt-8 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.status.label}{" "}
      </p>
      <Dropdown
        className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
        options={selectPublishStatusType}
        optionLabel="name"
        placeholder="Select"
        id={fieldLabels.status.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        disabled={formFormik.isSubmitting}
        value={formItemStatus?.value}
        ref={fieldLabels.status.ref}
      />
      <FormErrorMsg fieldId={fieldLabels.status.id} errorMsg={formFormik.errors.status} />
    </div>
  );

  const fieldGalleryImages = (
    <div className="t-mt-6 t-mb-10">
      <GalleryImagesUploader postId={postId} folder={folder} title="Gallery Images" id={fieldLabels.galleryImages.id} />
    </div>
  );


  const textEditorForm = (
    <div
      className={` 
    ${pageDesignView == EditorType.TEXT_EDITOR ? "t-flex" : "t-hidden"} 
    t-transition t-duration-150 t-ease-out t-h-full 
     t-overflow-auto md:t-max-w-[70rem] 
    t-bg-white t-mx-auto t-flex-col 
    t-rounded-lg t-p-2  t-my-0 md:t-my-5 md:t-p-[2.25rem] md:t-max-h-[86vh]  t-pb-[100px]`}
    >
      {/* {fieldCoverImage} */}
      <div className=" t-mt-0">
        {/*Basic Details From */}
        <SectionHeader title="Basic Details" />
        <div className="t-mb-14">
          {fieldTitle}
          {fieldSlug}
          <div className="t-pt-10">
            <SectionHeader title="Text Editor" />
          </div>
          {fieldDetails}
          {fieldTags}
        </div>
        <div>{fieldGalleryImages}</div>
        <div>
          {/* Dates and  Status*/}
          <SectionHeader title="Dates / Design / Status" />
          <div className="t-mb-10">
            <div className="flex t-w-full">
              {fieldDateUploaded}
              {fieldDatePublished}
            </div>
            <div className="t-flex t-justify-between t-items-stretch">
              <div className="flex t-w-full t-mr-4">{fieldPageDesignType}</div>
              <div className="flex t-w-full t-ml-4">{fieldStatus}</div>
            </div>

          </div>
        </div>
      </div>

      <div className="t-mb-[150px]"></div>
    </div>
  );


  return (
    <div className="t-w-full  t-m-auto t-bg-appmainview">

      <Toast ref={toast} position="top-right"></Toast>
      <FormikProvider value={formFormik}>
        <form
          className="main-form t-h-screen"
          onSubmit={formFormik.handleSubmit}
        >
          <CustomNavigation
            title={`${formAction == FormActionType.NEW ? "New Recreation/Facility" : formAction == FormActionType.EDIT ? "Update Recreation/Facility" : "New Recreation Facility"}`}
            background={true}
            useWhiteBackButton={true}
            backArrow={true}
            titleFont={"t-font-inter"}
            titleSize={"t-text-24px"}
            titleFontWeight={"t-font-medium"}
            yPadding={"t-py-1"}
            buttons={
              <FormActionButtonSet
                onPressedPreview={() => { }}
                onPressedSave={() => { }}
                onPressedChangeEditorType={changePageDesignView}
                editorType={pageDesignView}
                hasPageBuilders={true}
              />
            }
            backArrowNavUrl={route.recreationalFacilities.list}
          />
          {textEditorForm}
          {
            <div
              className={`t-h-screen
           ${pageDesignView == EditorType.PAGE_BUILDER ? "t-block" : "t-hidden"
                } 
           t-transition t-duration-150 t-ease-out t-sticky`}
            >
              <PageBuilder isSelected={pageDesignView == EditorType.PAGE_BUILDER} />

            </div>
          }
        </form>
      </FormikProvider>
      <ErrorDialog show={showErrorDialog} errorMsg={errorMsg} onHide={() => { setShowErrorDialog(false) }} />
      <LoadingDialog show={formFormik.isSubmitting} />
      <Toast ref={toast} position="top-right"></Toast>
    </div>
  );
}


export default FormRecreationFacilities;
