import { useNavigate } from "react-router-dom";
import ArticlesAndPublicationsTable from "../tables/table-for-articles-publications";
import { Button } from "primereact/button";
import useRoutePaths from "../../../routes/route-path";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import useDataForTextEditor from "../../../editors/data-for-editor";
import { FormActionType } from "../../../models";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataForDocumentFile from "../../../shared-components/images/data-document-file";
import useDataArticlesPublicationsForm from "./../data/data-for-articles-publications-form";
import useDataForGalleryImagesFile from "../../../shared-components/images/data-gallery-images";

function ListArticlesPublications() {
  const { paths: route } = useRoutePaths();
  const navigation = useNavigate();
  const galleryImageReset = useDataForGalleryImagesFile((state) => state.reset);
  const coverImageReset = useDataForCoverImageFile((state) => state.reset);
  const documentFileReset = useDataForDocumentFile((state) => state.reset);
  const editorReset = useDataForTextEditor((state) => state.reset);
  const setFormActionType = useDataArticlesPublicationsForm(
    (state) => state.setFormActionType
  );

  const resetForm = useDataArticlesPublicationsForm(
    (state) => state.reset
  );

  return (
    <div className="t-flex t-flex-col  t-justify-start t-rounded-lg t-shadow-lg md:t-m-10 t-m-3 t-min-h-0 t-flex-grow t-overflow-auto t-relative ">

      <div className="t-absolute t-w-full t-rounded-lg t-overflow-hidden">
        <CustomNavigation
          background={false}
          title="Articles/Publications"
          buttons={
            <Button
              label="Add New"
              className="list-top-nav-add-button t-font-inter !t-font-semibold"
              onClick={() => {
                resetForm()
                galleryImageReset();
                coverImageReset()
                editorReset()
                documentFileReset()
                setFormActionType(FormActionType.NEW);
                navigation(route.articlesPublications.details);
              }}
              icon="pi pi-plus"
            />
          }
        />
      </div>

      <ArticlesAndPublicationsTable />
    </div>
  );
}

export default ListArticlesPublications;
