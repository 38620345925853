import { create } from "zustand";
import { DataLoadingState, Folder, S3UploadedItem } from "../../models";
import { Storage } from 'aws-amplify';
import isDevMode from "../../utils/check-dev-mode";

interface onUploadInterface {
    file: File;
    folder: Folder;
    fileSize: number;
    postId: string;
}

interface DataInterface {
    onUpload: ({ file, folder, fileSize, postId }: onUploadInterface) => Promise<DataLoadingState | undefined>;
    onSelect: (file: File | null) => Promise<boolean>;
    onError: () => Promise<String>;
    onClear: () => Promise<boolean>;
    onCrop: (croppedFile: File) => Promise<boolean | undefined>;
    file: File | null | undefined;
    findS3Object: (key: string) => Promise<Blob | null>;
    getS3URL: (key: string) => Promise<string | null>;
    s3Item: S3UploadedItem | null | undefined;
    setS3Item: (s3Item: S3UploadedItem) => Promise<boolean>;
    uploadStatus: DataLoadingState | undefined,
    reset: () => Promise<boolean>;
    // uploadProgress: 
}


const useDataForDocumentFile =
    create<DataInterface>()((set, get) => {
        return {
            file: null,
            s3Item: null,
            uploadStatus: DataLoadingState.PENDING,
            setS3Item: async (s3Item) => {
                set((_) => ({ s3Item: s3Item }));
                return true;
            },
            findS3Object: async (key) => {
                var blob: any = null;
                await Storage.get(key, {
                    level: 'public', download: true,
                    // contentType: ".doc, .docx, .pdf, .csv., .xls"
                })
                    .then((result) => {
                        blob = result.Body!;
                        return blob;
                    })
                    .catch((err) => {
                        console.log(err);
                        blob = null;
                        return blob;
                    });

                return blob;
            },
            getS3URL: async (key) => {
                var url: any = null;
                await Storage.get(key, {
                    level: 'public',
                    // contentType: ".doc, .docx, .pdf, .csv., .xls"
                })
                    .then((result) => {
                        url = result;
                        return url;
                    })
                    .catch((err) => {
                        if (isDevMode()) {
                            console.log(err);
                        }

                        url = null;
                        return url;
                    });

                return url;
            },
            onUpload: async ({ file, folder, fileSize, postId }: onUploadInterface) => {
                try {
                    if (isDevMode()) {
                        console.log(`onUpload file type ${file.type}`);
                    }
                    const result = await Storage.put(`${folder.slug}/${postId}/document/${file.name}`, file, {
                        level: "public",
                        contentType: file.type,
                        completeCallback: (event) => {
                            if (isDevMode()) {
                                console.log(`Successfully uploaded event ${event}`);
                                console.log(`Successfully uploaded ${event.key}`);
                            }
                        },
                        progressCallback: (progress) => {
                            if (isDevMode()) {
                                console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                            }


                        },
                        errorCallback: (err) => {
                            if (isDevMode()) {
                                console.error('Unexpected error while uploading', err);
                            }

                        }
                    });
                    if (result != null) {
                        if (result.key.length > 0) {

                            const url = await get().getS3URL(result.key);

                            const newS3Item: S3UploadedItem = {
                                key: result.key,
                                size: fileSize,
                                lastModified: new Date().toISOString(),
                                signedURL: url,
                                blurHash: null,
                                eTag: `${file.name}`
                            }

                            set((_) => ({ s3Item: newS3Item }));
                            if (isDevMode()) {
                                console.log('onUpload results with key', newS3Item);
                            }

                        }
                        set((_) => ({ uploadStatus: DataLoadingState.LOADED }));
                        return DataLoadingState.LOADED;
                    }

                    set((_) => ({ s3Item: null }));
                    if (isDevMode()) {
                        console.log('onUpload results', result);
                    }

                    set((_) => ({ uploadStatus: DataLoadingState.ERROR }));
                    return DataLoadingState.ERROR;


                } catch (error) {
                    if (isDevMode()) {
                        console.error('Unexpected error while uploading', error);
                    }

                    set((_) => ({ uploadStatus: DataLoadingState.ERROR }));
                    return DataLoadingState.ERROR;
                }
            },
            onSelect: async (item) => {
                set((_) => ({ file: item }));
                return true;
            },
            onError: async () => {
                return 'error Message';
            },
            onClear: async () => {
                set((_) => ({ file: null }));
                return true;
            },
            onCrop: async (item) => {
                set((_) => ({ file: item }));
                return true;
            },
            reset: async () => {
                set((_) => ({ file: null, s3Item: null }));
                return true;
            }
        }
    })

export default useDataForDocumentFile;