import { API, DataStore, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { CrigStaffGroup, DataLoadingState, OpType } from "../../../../models";
import useDataStaffGroupingList from "../../page-staff-groupings/data/data-for-staff-groupings-list";
import useDataForUserAuth from "../../../../store/auth/data-for-auth";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import {
  createCrigStaffGroupGQL,
  listCrigStaffGroupsGQL,
  updateCrigStaffGroupGQL,
} from "../../../../custom-graphql/custom-gql-crig-staff-group";
import * as queries from "../../../../graphql/queries";
import * as mutations from "../../../../graphql/mutations";
import * as subscriptions from "../../../../graphql/subscriptions";

interface ServicesInterfaceForCrigStaffGroup {
  addNewData: (item: CrigStaffGroup) => Promise<boolean>;
  updateData: (item: CrigStaffGroup, isDelete: boolean) => Promise<boolean>;
  updateDataField: (id: string, key: string, value: any) => Promise<boolean>;
  deleteData: (item: CrigStaffGroup) => Promise<boolean>;
  findOneData: (id: string) => Promise<CrigStaffGroup | null>;
  listAllData: () => Promise<[CrigStaffGroup] | null>;
}

export function useStaffGroupingServiceAPI(): ServicesInterfaceForCrigStaffGroup {
  const staffGroupingDataAdd = useDataStaffGroupingList(
    (state) => state.addItem
  );

  const loggedUser = useDataForUserAuth((state) => state.loggedUser);

  const staffGroupingDataSetList = useDataStaffGroupingList(
    (state) => state.setListItems
  );

  const staffGroupingDataUpdate = useDataStaffGroupingList(
    (state) => state.updateItem
  );

  const staffGroupingDataDelete = useDataStaffGroupingList(
    (state) => state.deleteItem
  );

  const staffGroupingSetStatusForLoadingListItems = useDataStaffGroupingList(
    (state) => state.setStatusForLoadingListItems
  );

  const [addingNewItem, setAddingNewItem] = useState(false);

  const [updatingNewItem, setUpdatingNewItem] = useState(false);

  const [deletingNewItem, setDeletingNewItem] = useState(false);

  const addNewData = async (item: CrigStaffGroup) => {
    let newItem: CrigStaffGroup;
    let promise: Promise<GraphQLResult<any>>;
    let dataResult: CrigStaffGroup;
    newItem = {
      ...item,
      dateUploaded: new Date().toISOString(),
    };
    console.log("CrigStaffGroup item", newItem);

    promise = API.graphql(
      graphqlOperation(createCrigStaffGroupGQL, { input: newItem })
    );

    await promise
      .then((results) => {
        dataResult = results.data.createCrigStaffGroup;
        console.log("addNewCrigStaffGroup dataResult", results);
        return true;
      })
      .catch((e) => {
        console.log("error addNewCrigStaffGroup", e);
        return false;
      });
    return false;
  };

  const updateData = async (item: CrigStaffGroup) => {
    let newItem: CrigStaffGroup;
    let promise: Promise<GraphQLResult<any>>;
    let dataResult: CrigStaffGroup;
    newItem = {
      ...item,

      dateUploaded: new Date().toISOString(),
    };

    delete (newItem as any).pages;
    delete (newItem as any).createdAt;
    delete (newItem as any).staffProfiles;
    delete (newItem as any).updatedAt;
    delete (newItem as any)._lastChangedAt;
    delete (newItem as any)._deleted;
    delete (newItem as any).postCategory;
    delete (newItem as any).articlesPublications;
    delete (newItem as any).creatorUserAccount;
    delete (newItem as any).files;
    delete (newItem as any).unitDepartments;

    promise = API.graphql(
      graphqlOperation(updateCrigStaffGroupGQL, { input: newItem })
    );

    await promise
      .then((results) => {
        dataResult = results.data as CrigStaffGroup;
        console.log("updateCrigStaffGroup dataResult", dataResult);
        return true;
      })

      .catch((e) => {
        console.log("error updateCrigStaffGroup", e);
        return false;
      });
    return false;
  };

  const listAllData = async () => {
    let foundItems: CrigStaffGroup[] | null | undefined;
    let promise: Promise<GraphQLResult<any>>;

    promise = API.graphql({
      query: listCrigStaffGroupsGQL,
    });

    await promise
      .then((results) => {
        foundItems = results.data.listCrigStaffGroups.items as CrigStaffGroup[];

        const sortFxn = (a: CrigStaffGroup, b: CrigStaffGroup) => {
          return (
            new Date(a.dateUploaded!).getTime() -
            new Date(a.dateUploaded!).getTime()
          );
        };

        console.log("listAllCrigStaffCategories foundItems", foundItems);
        staffGroupingDataSetList(foundItems.sort(sortFxn));
        staffGroupingSetStatusForLoadingListItems(DataLoadingState.LOADED);
        return foundItems;
      })
      .catch((e) => {
        console.log("error listAllCrigStaffCategories", e);
        staffGroupingSetStatusForLoadingListItems(DataLoadingState.ERROR);
        return null;
      });
    return null;
  };

  const findOneData = async (id: string) => {
    let foundItem: CrigStaffGroup | null | undefined;
    let promise: Promise<GraphQLResult<any>>;

    promise = API.graphql(
      graphqlOperation(queries.getCrigStaffGroup, { id: id })
    );

    await promise
      .then((results) => {
        foundItem = results.data as CrigStaffGroup;
        console.log("findOneCrigStaffGroup foundItem", foundItem);
        return foundItem;
      })
      .catch((e) => {
        console.log("error findOneCrigStaffGroup", e);
        return null;
      });

    return null;
  };

  //   const findACrigStaffGroup = async (crigStaffGroupID: string) => {
  //     let foundItems: CrigStaffGroup[] | null | undefined;
  //     let promise: Promise<GraphQLResult<any>>;

  //     promise = API.graphql({
  //       query: queries.listCrigStaffGroups,
  //       variables: {
  //         crigStaffGroupID: crigStaffGroupID,
  //       },
  //     });

  //     await promise
  //       .then((results) => {
  //         foundItems = results.data.listCrigStaffGroups.items as CrigStaffGroup[];
  //         console.log("foundItems findACrigStaffGroups", foundItems);
  //         return foundItems;
  //       })
  //       .catch((e) => {
  //         console.log("error findACrigStaffGroups", e);
  //         return null;
  //       });
  //     return null;
  //   };

  const deleteData = async (item: CrigStaffGroup) => {
    let promise: Promise<GraphQLResult<any>>;

    promise = API.graphql(
      graphqlOperation(mutations.deleteCrigStaffGroup, { input: item })
    );

    await promise
      .then((_) => {
        staffGroupingDataDelete(item);
        return true;
      })
      .catch((e) => {
        console.log("error deleteStaffGrouping", e);
        return false;
      });
    return false;
  };

  //FIGURE OUT API
  const updateDataField = async (id: string, key: string, value: any) => {
    return false;
  };

  useEffect(() => {
    const createSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onCreateCrigStaffGroup)
      ) as unknown as any
    ).subscribe({
      next: ({ provider, value }) => {
        console.log("onCreateCrigStaffGroup", { provider, value });

        if (addingNewItem) return;

        setAddingNewItem(true);

        const newItem = value.data.onCreateCrigStaffGroup as CrigStaffGroup;

        staffGroupingDataAdd(newItem);

        setAddingNewItem(false);
      },
      error: (error: any) => {
        console.warn(error);
      },
    });

    const updateSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onUpdateCrigStaffGroup)
      ) as unknown as any
    ).subscribe({
      next: ({ provider, value }) => {
        console.log("onUpdateCrigStaffGroup", { provider, value });

        if (updatingNewItem) return;

        setUpdatingNewItem(true);

        const newItem = value.data.onUpdateCrigStaffGroup as CrigStaffGroup;

        staffGroupingDataUpdate(newItem);

        setUpdatingNewItem(false);
      },
      error: (error: any) => {
        console.warn(error);
      },
    });

    const deleteSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onDeleteCrigStaffGroup)
      ) as unknown as any
    ).subscribe({
      next: ({ provider, value }) => {
        console.log("onDeleteCrigStaffGroup", { provider, value });

        if (deletingNewItem) return;

        setDeletingNewItem(true);

        const newItem = value.data.onDeleteCrigStaffGroup as CrigStaffGroup;

        staffGroupingDataDelete(newItem);

        setDeletingNewItem(false);
      },
      error: (error: any) => {
        console.warn(error);
      },
    });

    return () => {
      const cleanupSubscriptions = () => {
        createSubscription.unsubscribe();
        updateSubscription.unsubscribe();
        deleteSubscription.unsubscribe();
      };
      cleanupSubscriptions();
    };
  }, []);

  return {
    addNewData,
    updateData,
    updateDataField,
    deleteData,
    findOneData,
    listAllData,
  };
}
