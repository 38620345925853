import grapesjs from 'grapesjs'
import 'grapesjs/dist/grapes.min.js'
import { Skeleton } from "primereact/skeleton";
import webPlugin from 'grapesjs-preset-webpage'
import formPlugin from 'grapesjs-plugin-forms'
import basicPlugin from 'grapesjs-blocks-basic'
import countdownPlugin from 'grapesjs-component-countdown'
import pluginCustomCode from 'grapesjs-custom-code'
import pluginNavBar from 'grapesjs-navbar'
import pluginExport from 'grapesjs-plugin-export'
import pluginRulers from 'grapesjs-rulers'
import pluginStyleBg from 'grapesjs-style-bg'
import pluginStyleFilter from 'grapesjs-style-filter'
import pluginStyleGradient from 'grapesjs-style-gradient'
import pluginTabs from 'grapesjs-tabs'
import pluginToolTip from 'grapesjs-tooltip'
import pluginImageEditor from 'grapesjs-tui-image-editor'
import pluginTyped from 'grapesjs-typed'
// import ReactText from "./simple-react-text";

// import BaseReactComponent from './base-react-component';
// import ReactComponents from './react-components';
// import MuiComponents from './epos';
import flexbox from 'grapesjs-blocks-flexbox'
import { useEffect, useRef } from 'react';
import './GrapeJsStyles.css';
import useDataForTextEditor from '../data-for-editor'

//props
type Props = {
    // onChange(val: Object): void;
    isSelected: boolean;
};

const PageBuilder = ({ isSelected }: Props) => {
    const setPageBuilderDataEncoded = useDataForTextEditor((state) => state.setPageBuilderDataEncoded);
    const setPageBuilderIsLoading = useDataForTextEditor((state) => state.setPageBuilderIsLoading);
    const pageBuilderIsLoading = useDataForTextEditor((state) => state.pageBuilderIsLoading);
    const pageBuilderDataEncoded = useDataForTextEditor((state) => state.pageBuilderDataEncoded);
    const pageBuilderDataString = useDataForTextEditor((state) => state.pageBuilderDataString);
    const setPageBuilderDataString = useDataForTextEditor((state) => state.setPageBuilderDataString);
    const ref = useRef(null);


    useEffect(() => {

        if (pageBuilderIsLoading) {
            console.log('pageBuilder isLoading', pageBuilderIsLoading);
            return;
        }

        const editor = grapesjs.init({
            container: '#gjs',
            width: '100%',

            plugins: [
                pluginImageEditor,
                pluginToolTip,
                pluginStyleGradient,
                basicPlugin,
                countdownPlugin,
                formPlugin,
                webPlugin,
                // BaseReactComponent, ReactComponents, MuiComponents,
                pluginTabs,
                flexbox,
                // pluginStyleFilter,
                // pluginExport,
                // pluginStyleBg,
                // pluginRulers,
                pluginCustomCode,
                pluginNavBar,
                // pluginTyped,
            ],
            // storageManager: {
            //   id: 'gjs-',
            //   type: 'local',
            //   autosave: true,
            //   storeComponents: true,
            //   storeStyles: true,
            //   storeHtml: true,
            //   storeCss: true,
            // },
            deviceManager: {
                devices:
                    [
                        {
                            id: 'desktop',
                            name: 'Desktop',
                            width: '',
                        },
                        {
                            id: 'tablet',
                            name: 'Tablet',
                            width: '768px',
                            widthMedia: '992px',
                        },
                        {
                            id: 'mobilePortrait',
                            name: 'Mobile portrait',
                            width: '320px',
                            widthMedia: '575px',
                        },
                    ]
            },
            pluginsOpts: {
                'grapesjs-preset-webpage': {
                    blocks: ['column1', 'column2', 'column3', 'column3-7', 'text', 'link', 'image', 'video', 'map'],
                },

                'grapesjs-tui-image-editor': {
                    config: {
                        includeUI: {
                            initMenu: 'filter',
                        },
                    },
                }
            }
        })


        //   editor.BlockManager.add('my-block-id', {
        //     label: 'ReactText',
        //     category: 'ePOS',
        //     components: ReactText,
        //     activate: true

        //   })

        // Get current project data
        // const projectData = editor.getProjectData();
        // console.log('projectData', projectData);

        if (isSelected) {
            editor.loadProjectData(pageBuilderDataEncoded);
            editor.Storage.store(pageBuilderDataEncoded!, {});
            editor.on('storage:store', (data) => {
                console.log('storage:store', data);
                setPageBuilderDataEncoded(data);
                setPageBuilderDataString(JSON.stringify(data));
            });
        } else {
            editor.Storage.store({}, {});
        }

        return () => {
            if (isSelected == false) {
                editor.destroy();
            }
        }

    }, [pageBuilderIsLoading, isSelected])


    const skeletonLoading = (
        <div className={`${pageBuilderIsLoading ? 't-flex' : 't-hidden'} t-flex-col t-max-h-screen !t-h-[100vh] t-pb-[110px]`}
            id="loading-skeleton">
            <Skeleton className="mb-2 t-mt-2  !t-w-[98%] t-bg-gray-200 t-flex t-justify-center t-mx-auto !t-h-[40px]"></Skeleton>

            <div className='t-flex   !t-w-[98%]    t-justify-center t-mx-auto'>
                <Skeleton className="mb-2 t-mt-2 t-mr-10    !t-w-[85%] t-bg-gray-200 t-flex t-justify-start !t-h-[80vh]"></Skeleton>
                <div>

                </div>
                <Skeleton className="mb-2 t-mt-2  !t-w-[15%] t-bg-gray-200 t-flex t-justify-start  !t-h-[80vh]"></Skeleton>
            </div>
        </div>
    );

    return (
        <>
            <div ref={ref} className={`${pageBuilderIsLoading ? 't-flex' : 't-flex'}
             t-flex-col t-max-h-screen !t-h-[98vh] t-pb-[110px]`}
                id="gjs">
            </div>
            {skeletonLoading}
        </>
    );

}


export default PageBuilder;
