import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import PinInput from "react-pin-input";
import { useNavigate } from "react-router-dom";
import { useServiceAuthentication } from "../../shared-services/use-service-authentication";
import { useWindowSize } from "../../utils/window-size";
import CountdownTimer from "./components/CountDownTimer";
import { useCountdown } from "./components/useCountdown";
import { useDataAuthPages } from "./data/data-for-auth-pages";
import Logo from '/img/crig-logo.png';
import { Dialog } from 'primereact/dialog';
import useRoutePaths from "../../routes/route-path";
import useToaster from "../../shared-components/toasts/Toaster";
import { Toast } from "primereact/toast";

const THREE_MINUTES_IN_MS = 180000;
const NOW_IN_MS = new Date().getTime();
const dateTimeAfterThreeMinutes = NOW_IN_MS + THREE_MINUTES_IN_MS;


function ConfirmSignIn() {
  const navigate = useNavigate();
  const { paths: route } = useRoutePaths();
  const refPinInput: any = useRef();
  const [pin, pinSetState] = useState<string>("");
  const size = useWindowSize();
  const signInInfo = useDataAuthPages((state) => state.signInInfo);
  const numberSentTo = useDataAuthPages((state) => state.numberSentTo);


  const [timeStarted, timeStartedSetState] = useState<boolean>(false);
  const [resend, resendSetState] = useState<boolean>(false);
  const [time, setTime] = useState<number>(dateTimeAfterThreeMinutes);

  const [submitting, submittingSetState] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState(false);
  const { confirmOtp, getUser, signIn } = useServiceAuthentication();
  const [disableResendBtn, setDisableShowResendBtn] = useState<boolean>(true);

  const setTimedOut = useDataAuthPages((state) => state.setTimedOut);
  const timedOut = useDataAuthPages((state) => state.timedOut);

  const { showError, showSuccess } = useToaster();
  const toast = useRef(null);

  useEffect(() => {
    if (!timeStarted) {
      const THREE_MINUTES_IN_MS = 180000;
      const NOW_IN_MS = new Date().getTime();
      const dateTimeAfterThreeMinutes = NOW_IN_MS + THREE_MINUTES_IN_MS;
      setTime((_) => dateTimeAfterThreeMinutes);
      setTimedOut(false)
      timeStartedSetState((_) => true);
      refPinInput.current.focus();
    }
    return () => { };
  }, []);



  useEffect(() => {

    if (signInInfo.username.length == 0) {
      setDisableShowResendBtn(true)
      return;
    }

    if (submitting == true) {
      setDisableShowResendBtn(true)
      return;
    }




    if (timedOut == true) {

      setDisableShowResendBtn(false)
      return;
    }



    return () => {

    }
  }, [signInInfo.username, submitting, resend, timedOut])

  const checkTimedOut = (() => {
    let counter = THREE_MINUTES_IN_MS / 1000;
    return () => {
      counter--;
      return counter;
    };
  })();

  useEffect(() => {
    var refreshCountDownId = setInterval(function () {
      var currentTime = checkTimedOut();
      if (currentTime <= 0) {
        console.log('t', currentTime)
        clearInterval(refreshCountDownId);
        setTimedOut(true)
      }
    }, 1000);

    return () => {
      clearInterval(refreshCountDownId);
    }
  }, [])




  const handleSubmit = async () => {
    try {

      console.log(pin, signInInfo);


      await confirmOtp(signInInfo, pin).then(async (done) => {

        if (!done) {
          setShowDialog(true);
          submittingSetState(false);
          return;
        }

        await getUser(true, signInInfo).then((_) => {
          navigate(route.dashboard, { replace: true });
        });

        return;
      });
    } catch (error) {
      console.log('handleSubmit', error);
      setShowDialog(true);
      submittingSetState(false);
    }
  };


  const fieldPinInput = (
    <div className="t-flex t-w-full t-mt-5 t-px-5 t-justify-center t-items-center">
      <PinInput
        length={6}
        initialValue=""
        secret
        ref={refPinInput}
        onChange={(value, index) => {
          console.log(value);
          pinSetState(value);
        }}
        type="numeric"
        inputMode="number"
        style={{ padding: "0px", display: "contents" }}
        inputStyle={{
          width: `${(size.width ?? 20) / 6}px`,
          minWidth: "20px",
          maxWidth: "50px",
          margin: "5px",
          height: "47px",
          borderRadius: "10px",
          background: "#f3f6fb",
          borderWidth: "2px",
          borderColor: "#e5e7eb",
        }}
        inputFocusStyle={{ borderColor: "#22c55e" }}
        onComplete={(value, index) => {
          pinSetState(value);
        }}
        autoSelect={true}
        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
      />
    </div>
  );



  const dialog = (
    <Dialog className="md:t-max-w-[30vw] t-mx-4 t-w-full" header="ATTENTION" visible={showDialog} onHide={() => setShowDialog(false)}
      footer={<Button label="OKAY" icon="" onClick={() => setShowDialog(false)} autoFocus />}
    >
      <p className="m-0">
        Something went wrong, kindly check your code or click resend to receive a new OTP.
      </p>
    </Dialog>
  );

  return (
    <div className="confirm-sign-in-container">
      <div className="t-bg-appbrown-700 t-opacity-95 confirm-sign-in-sub-container">
        <div className="t-flex t-flex-col t-items-center t-justify-center t-m-auto  t-scale-100  2xl:t-scale-125 ">
          <div className="t-flex t-w-full t-items-center custom-nav t-justify-between">
            <Button onClick={() => {
              navigate('/sign-in', { replace: true });
            }} icon="pi pi-arrow-left" className={`p-button-rounded use-white t-mt-2 p-button-text`}
            />
            <div>
              <img
                src={Logo}
                alt="Logo"
                width={60}
                height={60}
                className="login-logo  t-pr-2"
              />
            </div>
            <div className="t-w-100"></div>
          </div>
          <div className="t-flex t-w-full t-flex-col text-center t-bg-white  t-mx-4 t-h-full t-min-h-80 t-rounded-xl opacity-100 t-p-2">
            <div className="t-w-full">
              <p className="text-primary-500  t-font-playfair t-pt-4 t-font-bold t-text-3xl">
                Verify Access
              </p>
              <div className="t-max-w-[90%] t-flex t-m-auto ">
                <p className="t-mt-2 t-font-inter">{`Enter the six (6) digit code sent 
               to your phone number ${numberSentTo}`}</p>
              </div>
            </div>
            <div className="t-mt-1">
              {fieldPinInput}
            </div>
            <div className="t-w-full t-py-4">
              <div className="timer">
                <div id="time-left">
                  {timeStarted && <CountdownTimer targetDate={time} />}
                </div>
              </div>
            </div>
            <div className="t-flex t-w-full t-mb-4 t-mx-auto t-px-4 t-justify-between">
              <Button
                className="t-w-2/5 p-button-rounded"
                label="RESEND"
                icon={`${resend ? 'pi pi-spin pi-spinner' : 'pi pi-replay'}  `}
                iconPos="left"
                disabled={disableResendBtn}
                onClick={async (e) => {
                  e.preventDefault();
                  if (resend) return;

                  resendSetState(true);
                  await signIn(signInInfo).then((results) => {

                    if (results != null) {
                      let errorMsg = (results as Error).message ?? '';

                      if ((errorMsg).includes('OtpCreated')) {

                        resendSetState(false);
                        showSuccess(toast, 'ATTENTION', 'Access code has been resent!');

                        const THREE_MINUTES_IN_MS = 180000;
                        const NOW_IN_MS = new Date().getTime();
                        const dateTimeAfterThreeMinutes = NOW_IN_MS + THREE_MINUTES_IN_MS;
                        setTime((_) => dateTimeAfterThreeMinutes);
                        timeStartedSetState((_) => true);
                        setTimedOut(false)
                        refPinInput.current.focus();

                        return;
                      }

                      // showError(toast, 'ATTENTION', 'Kindly recheck credentials and try again.');
                      setShowDialog(true);
                      return;
                    }
                    setShowDialog(true);
                    // showError(toast, 'ATTENTION', 'Kindly recheck credentials and try again.');

                    return;
                  });
                }}
              />
              {!submitting && <Button
                className="p-button-secondary p-button-rounded t-w-2/5"
                label="SUBMIT"
                icon="pi pi-angle-right"
                iconPos="right"
                onClick={(e) => {
                  e.preventDefault();
                  submittingSetState((prev) => !prev);
                  handleSubmit();
                }}
                disabled={(signInInfo.username.length == 0) ? true : (pin.length < 6) ? true : (pin.length === 6) ? false : submitting ? true : true}
              />}
              {submitting && <Button
                type='button'
                className="p-button-secondary p-button-rounded t-w-2/5"
                label="..."
                onClick={(e) => {
                  e.preventDefault();
                  submittingSetState((prev) => !prev);

                }}
                icon={`pi pi-spin pi-spinner`}
                iconPos="right"
              />}

            </div>
          </div>
        </div>
      </div>
      {dialog}
      <Toast ref={toast} position="top-right"></Toast>
    </div>
  );
}

export default ConfirmSignIn;
