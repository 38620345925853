import { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import useRoutePaths from "../../../routes/route-path";
import SectionHeader from "../../../shared-components/header/SectionHeader";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import { FormikProvider, useFormik } from "formik";
import useDataCmsUsersForm from "../data/data-for-cms-users-form";
import {
  UserAccount,
  UserRole,
  UserCategoryType,
  FormActionType,
  DataLoadingState,
} from "../../../models";
import { SchemaForCMSUsers } from "../form-schemas/form-schema-for-cms-users";
import {
  formatDateToIsoString,
  useAccountStatusType,
} from "../../../utils/forms/util-forms";
import { format } from "date-fns";
import FormErrorMsg from "../../../shared-components/form/ErrorMsg";
import FormActionButtonSet from "../../../shared-components/button/FormActionButtonSet";
import useToaster from "../../../shared-components/toasts/Toaster";
import { useServiceForCMSUsersAPI } from "../services/services-for-cms-users";
import ErrorDialog from "../../../shared-components/form/ErrorDialog";
import LoadingDialog from "../../../shared-components/form/LoadingDialog";
import { Toast } from "primereact/toast";
import { useServiceForUserRoleAPI } from "../../page-settings/page-user-roles/services/services-for-user-roles";
import useDataUserRoleList from "../../page-settings/page-user-roles/data/data-for-user-roles-list";
import isDevMode from "../../../utils/check-dev-mode";
import { useServiceAuthentication } from "../../../shared-services/use-service-authentication";

const selectUserCategory = [
  { title: "CMS User", value: UserCategoryType.CMS_USER },
  // { title: "CMS User/Content Follower", value: UserCategoryType.BOTH },
];

function FormCmsUsers() {
  const { paths: route } = useRoutePaths();
  const [file, setFile] = useState<any>();
  const [userPassword, setUserPassword] = useState("");
  const toast: any = useRef();
  const formData = useDataCmsUsersForm((state) => state.formData);
  const { addNewData, updateData } = useServiceForCMSUsersAPI();
  const { showError, showSuccess } = useToaster();
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMsg, _setErrorMsg] = useState(
    "Something went wrong kindly try again."
  );

  const formatDate = (value: Date) => {
    return format(value, `do LLL yyyy, h:mm aaa`);
  };

  const dateBodyTemplate = (rowData: any) => {
    const valueAsDate = new Date(rowData);
    const formatted = formatDate(valueAsDate);
    return <p>{formatted}</p>;
  };

  const loadingStatus = useDataUserRoleList(
    (state) => state.statusForLoadingListItems
  );

  const setStateLoadingStatus = useDataUserRoleList(
    (state) => state.setStatusForLoadingListItems
  );

  const [validateForm, setValidateForm] = useState<boolean>(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const formAction = useDataCmsUsersForm((state) => state.formActionType);
  const listUserRolesFromService = useServiceForUserRoleAPI().listAllData;
  const userRoleListItems = useDataUserRoleList((state) => state.listItems);

  const { signUpCMS } = useServiceAuthentication();

  const [selectedUserRole, setSelectedUserRole] = useState<UserRole | null>();

  const formActionSetState = useDataCmsUsersForm(
    (state) => state.setFormActionType
  );

  async function saveForm(values: UserAccount) {
    let newItem: UserAccount;
    let userCredentialsCreated: boolean | Error = false;

    switch (formAction ?? FormActionType.NEW) {
      case FormActionType.NEW:
        newItem = new UserAccount({
          nameFirst: values?.nameFirst,
          nameLast: values.nameLast,
          contactPhoneNumber1: `+233${values.contactPhoneNumber1?.substring(
            1,
            10
          )}`,
          contactEmail1: values.contactEmail1,
          userRoleID: values?.userRoleID,
          status: values.status,
          dateAdded: values?.dateAdded,
        });

        let submittedValues = {
          ...newItem,
          deleted: false,
          countryIso: "GH",
          countryName: "Ghana",
          nameFull: values.nameFirst + " " + values.nameLast,
        };

        await addNewData(submittedValues)
          .then(async (newUserAccount) => {
            if (newUserAccount != null) {
              if (userPassword.length > 0) {
                userCredentialsCreated = await signUpCMS({
                  info: newUserAccount,
                  password: userPassword,
                  userRole: selectedUserRole?.title!,
                });

                if (userCredentialsCreated == true) {
                  showSuccess(toast, "ATTENTION", "Successfully saved.");
                  formFormik.setSubmitting(false);
                  console.log("done addNewDataWithUser");
                  formActionSetState(FormActionType.EDIT);
                } else {
                  showError(
                    toast,
                    "ATTENTION",
                    "Something went wrong creating account, kindly try again."
                  );
                  setShowErrorDialog(true);
                  formFormik.setSubmitting(false);
                  formActionSetState(FormActionType.EDIT);
                }

                return;
              }

              showSuccess(toast, "ATTENTION", "Successfully saved.");
              formFormik.setSubmitting(false);
              console.log("done addNewData");

              return;
            }

            showError(
              toast,
              "ATTENTION",
              "Something went wrong creating account, kindly try again."
            );
            setShowErrorDialog(true);
            formFormik.setSubmitting(false);
          })
          .catch((err) => {
            console.log("err submitting addNewData", err);
            showError(
              toast,
              "ATTENTION",
              "Something went wrong, kindly try again."
            );
            setShowErrorDialog(true);
            formFormik.setSubmitting(false);
          });
        break;

      case FormActionType.EDIT:
        newItem = {
          ...values,
          nameFull: values.nameFirst + " " + values.nameLast,
          dateUpdated: new Date().toISOString(),
          dateAdded: formatDateToIsoString(values.dateAdded!),
          id: formData!.id,
          deleted: false,
        };
        console.log("newItem update", newItem);

        await updateData(newItem!, false, false)
          .then(async (newUserAccount) => {
            if (newUserAccount != null) {
              const createAccountNeeded =
                (newItem.authId == null || newItem.authId?.length == 0) &&
                userPassword.length > 0;
              if (createAccountNeeded) {
                userCredentialsCreated = await signUpCMS({
                  info: newUserAccount,
                  password: userPassword,
                  userRole: selectedUserRole?.title!,
                });

                console.log(
                  "done userCredentialsCreated",
                  userCredentialsCreated
                );
                if (userCredentialsCreated == true) {
                  showSuccess(toast, "ATTENTION", "Successfully saved.");
                  formFormik.setSubmitting(false);
                  console.log("done addNewDataWithUser");
                  return;
                }

                showError(
                  toast,
                  "ATTENTION",
                  "Something went wrong creating account, kindly try again."
                );
                setShowErrorDialog(true);
                formFormik.setSubmitting(false);
                return;
              }

              showSuccess(toast, "ATTENTION", "Successfully saved.");
              formFormik.setSubmitting(false);
              console.log("done addNewData");

              return;
            }

            showError(
              toast,
              "ATTENTION",
              "Something went wrong creating account, kindly try again."
            );
            setShowErrorDialog(true);
            formFormik.setSubmitting(false);
          })

          .catch((error) => {
            console.log("err submitting updateData", error);
            showError(
              toast,
              "ATTENTION",
              "Something went wrong, kindly try again."
            );
            setShowErrorDialog(true);
            formFormik.setSubmitting(false);
          });
        break;
      default:
        break;
    }
  }
  const formFormik = useFormik({
    initialValues: formData as UserAccount,
    validationSchema: SchemaForCMSUsers,
    validateOnChange: validateForm,
    validateOnBlur: false,

    validate: (values) => {
      setValidateForm(true);
      console.log("validate", values);
      console.log("errors", formFormik.errors);
    },

    onSubmit: async (values) => {
      console.log("submit", values);
      await saveForm(values);
    },
  });

  useEffect(() => {
    if (
      selectedUserRole == null &&
      userRoleListItems.length > 0 &&
      (formFormik.values.userRoleID ?? "").length > 0
    ) {
      if (
        !selectedUserRole &&
        userRoleListItems.length > 0 &&
        formFormik.values.userRoleID
      ) {
        const foundItem = userRoleListItems.find(
          ({ id }) => id === formData?.userRoleID
        );
        if (isDevMode()) {
          console.log("foundItem", foundItem);
        }
        if (foundItem?.id) {
          setSelectedUserRole(foundItem);
        }
      }
    }

    return () => {};
  }, [formFormik.values.userRoleID, userRoleListItems, selectedUserRole]);

  const refNameFirst = useRef(null);
  const refNameLast = useRef(null);
  const refNameFull = useRef(null);
  const refEmail = useRef(null);
  const refPassword = useRef(null);
  const refPhoneNumber = useRef(null);
  const refAddedOn = useRef(null);
  const refLastLogin = useRef(null);
  const refStatus = useRef(null);
  const refUserRole = useRef(null);
  const refUserCategory = useRef(null);
  const refSlug = useRef(null);

  const fieldLabels = {
    firstName: {
      id: "nameFirst",
      ref: refNameFirst,
      label: "First Name",
    },
    lastName: {
      id: "nameLast",
      ref: refNameLast,
      label: "Last Name",
    },
    nameFull: {
      id: "nameFull",
      ref: refNameFull,
      label: "full Name",
    },
    email: {
      id: "contactEmail1",
      ref: refEmail,
      label: "Email",
    },
    password: {
      id: "password",
      ref: refPassword,
      label: "Password",
    },
    phoneNumber: {
      id: "contactPhoneNumber1",
      ref: refPhoneNumber,
      label: "Phone Number",
    },
    addedOn: {
      id: "addedOn",
      ref: refAddedOn,
      label: "Added On",
    },
    lastLogin: {
      id: "lastLogin",
      ref: refLastLogin,
      label: "Last Login",
    },
    status: {
      id: "status",
      ref: refStatus,
      label: "Status",
    },
    role: {
      id: "userRoleID",
      ref: refUserRole,
      label: "Role",
    },
    userCategory: {
      id: "category",
      ref: refUserCategory,
      label: "User Category",
    },
    slug: {
      id: "slug",
      ref: refSlug,
      label: "Slug",
    },
  };

  const onBasicUpload = () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "File Uploaded with Basic Mode",
    });
  };

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  useEffect(() => {
    const fetchListData = async () => {
      await listUserRolesFromService();
    };

    if (
      userRoleListItems.length == 0 &&
      loadingStatus == DataLoadingState.PENDING
    ) {
      setStateLoadingStatus(DataLoadingState.LOADING);
      fetchListData();
    }
  }, [loadingStatus, userRoleListItems]);

  const firstName = (
    <div className="t-w-1/2 t-mr-4 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.firstName.label}
      </p>
      <span className="p-input-icon-left w-full p-input-filled">
        <i className="pi pi-user pi-user" />
        <InputText
          id={fieldLabels.firstName.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.nameFirst ?? ""}
          ref={fieldLabels.firstName.ref}
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.firstName.id}
        errorMsg={formFormik.errors.nameFirst}
      />
    </div>
  );

  const empty = <div className="t-w-full t-ml-8"></div>;
  const lastName = (
    <div className="t-w-1/2 t-ml-4 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.lastName.label}
      </p>
      <span className="p-input-icon-left w-full p-input-filled">
        <i className="pi pi-user pi-user" />
        <InputText
          id={fieldLabels.lastName.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.nameLast ?? ""}
          ref={fieldLabels.lastName.ref}
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.lastName.id}
        errorMsg={formFormik.errors.nameLast}
      />
    </div>
  );
  const email = (
    <div className="t-w-1/2 t-mr-4 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.email.label}
      </p>
      <span className="p-input-icon-left w-full p-input-filled">
        <i className="pi pi-envelope pi-envelope" />
        <InputText
          id={fieldLabels.email.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.contactEmail1 ?? ""}
          ref={fieldLabels.email.ref}
          className="w-full p-input-filled"
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.email.id}
        errorMsg={formFormik.errors.contactEmail1}
      />
    </div>
  );
  const phoneNumber = (
    <div className="t-w-1/2 t-ml-4 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.phoneNumber.label}
      </p>
      <span className="p-input-icon-left t-w-full p-input-filled">
        <i className="pi pi-phone pi-phone" />
        <InputText
          id={fieldLabels.phoneNumber.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.contactPhoneNumber1 ?? ""}
          ref={fieldLabels.phoneNumber.ref}
          className="w-full p-input-filled"
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.phoneNumber.id}
        errorMsg={formFormik.errors.contactPhoneNumber1}
      />
    </div>
  );

  const lastLogin = (
    <div className="t-w-1/2 t-mr-4 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.lastLogin.label}
      </p>
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {dateBodyTemplate(formData?.dateLastLogin ?? new Date())}
      </p>
    </div>
  );
  const addedOn = (
    <div className="t-w-1/2 t-mr-4 form-field">
      <p
        className="t-text-appgrey-800 t-mt-6 
      t-mb-2 login-input-label t-font-semibold"
      >
        {fieldLabels.addedOn.label}
      </p>
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {dateBodyTemplate(formData?.dateAdded ?? new Date())}
      </p>
    </div>
  );

  const fieldStatus = (
    <div className="t-w-full t-mr-4 form-field">
      <p
        className="t-text-appgrey-800 t-mt-6 t-mb-2 
      login-input-label t-font-semibold"
      >
        {fieldLabels.status.label}
      </p>
      <span className="p-input-icon-left t-w-full">
        <Dropdown
          className="t-w-full t-mb-4 md:t-mb-0 p-input-filled"
          options={useAccountStatusType}
          optionLabel="name"
          placeholder="Select"
          id={fieldLabels.status.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.status}
          ref={fieldLabels.status.ref}
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.status.id}
        errorMsg={formFormik.errors.status}
      />
    </div>
  );

  const fieldSubCategory = (
    <div className="t-w-full t-ml-4 form-field">
      <p
        className="t-text-appgrey-800 t-mt-6 t-mb-2 
      login-input-label t-font-semibold"
      >
        {fieldLabels.userCategory.label}
      </p>
      <span className="p-input-icon-left t-w-full">
        <Dropdown
          className="t-w-full t-mb-4 md:t-mb-0 p-input-filled"
          options={selectUserCategory}
          optionLabel="title"
          placeholder="Select"
          id={fieldLabels.userCategory.id}
          // onChange={(e) => {
          //   const selectedCategory = e.value;
          //   setSelectedCategory(selectedCategory);
          //   formFormik.setFieldValue(
          //     "UserCategoryType",
          //     selectedCategory.categoryType
          //   );
          // }}
          onChange={formFormik.handleChange}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.category}
          // value={selectedCategory}
          ref={fieldLabels.userCategory.ref}
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.userCategory.id}
        errorMsg={formFormik.errors.category}
      />
    </div>
  );

  const fieldRole = (
    <div className="t-w-full t-ml-4 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.role.label}
      </p>
      <span className="p-input-icon-left t-w-full">
        <Dropdown
          className="t-w-full t-mb-4 md:t-mb-0 p-input-filled"
          options={userRoleListItems}
          optionLabel="title"
          placeholder="Select"
          id={fieldLabels.role.id}
          onChange={(e) => {
            const selectedRole: UserRole = e.value;
            setSelectedUserRole(selectedRole);
            formFormik.setFieldValue("userRoleID", selectedRole.id);
          }}
          disabled={formFormik.isSubmitting}
          value={selectedUserRole}
          ref={fieldLabels.role.ref}
        />
      </span>
    </div>
  );

  const password = (
    <div className="t-w-full t-mr-4 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        Password
      </p>
      <span className="p-input-icon-left w-full p-input-filled p-input-icon-right">
        <i className="pi pi-lock pi-lock" />
        <InputText
          id={fieldLabels.password.id}
          onChange={(e) => setUserPassword(e.target.value)}
          type={`${passwordShown ? "text" : "password"}`}
          disabled={formFormik.isSubmitting}
          value={userPassword}
        />
        <i
          className={`pi t-transition-all t-ease-in-out ${
            passwordShown ? "pi-eye-slash" : "pi-eye"
          } p-ripple hover:t-cursor-pointer`}
          onClick={() => {
            setPasswordShown((prev) => !prev);
          }}
        />
      </span>
    </div>
  );
  return (
    <div className="t-w-full  t-m-auto t-bg-appmainview">
      <FormikProvider value={formFormik}>
        <form className="main-form" onSubmit={formFormik.handleSubmit}>
          <CustomNavigation
            title={`${
              formAction == FormActionType.NEW
                ? "Add New CMS Users"
                : formAction == FormActionType.EDIT
                ? "Update CMS Users"
                : "New CMS Users"
            }`}
            background={true}
            useWhiteBackButton={true}
            backArrow={true}
            maxWidth={"t-max-w-[96rem]"}
            titleFont={"t-font-inter"}
            titleSize={"t-text-24px"}
            titleFontWeight={"t-font-medium"}
            yPadding={"t-py-1"}
            buttons={
              <FormActionButtonSet
                onPressedSave={() => {
                  formFormik.handleSubmit;
                }}
                showSave={true}
                showPreview={false}
                saveBtnWidth={"t-w-[150px]"}
                hasPageBuilders={false}
              />
            }
            backArrowNavUrl={route.cmsUsers.list}
          />
          <div
            className={` 
            t-transition t-duration-150 t-ease-out t-h-full 
            t-overflow-auto md:t-max-w-[70rem] 
            t-bg-white t-mx-auto t-flex-col 
            t-rounded-lg t-p-2  t-my-0 md:t-my-5 md:t-p-[2.25rem] md:t-max-h-[88vh]  t-pb-[100px]`}
          >
            <div className="t-w-full t-mb-52">
              <div>
                {/*Basic Details From */}
                <SectionHeader title="Basic Details" />

                {/* <div className="flex t-w-full t-items-center t-justify-center mt-4">
                  <input
                    className="cms-user-image-upload"
                    id="photo-upload"
                    type="file"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="photo-upload"
                    className="custom-file-upload t-shadow"
                  >
                    <div className="img-wrap img-upload">
                      {file && (
                        <img style={{ width: 150, height: 150 }} src={file} />
                      )}
                    </div>
                  </label>
                </div> */}

                <div className="t-mb-10">
                  <div className="flex t-w-full">
                    {firstName}
                    {lastName}
                  </div>
                  <div className="flex t-w-full">
                    {email}
                    {phoneNumber}
                  </div>
                  <div className="flex t-w-full">{empty}</div>
                </div>
                <div>
                  {/* Logon Details*/}
                  <SectionHeader title="Logon Details" />
                  <div className="t-mb-10">
                    {/* <div className="flex t-w-full">
                      {addedOn}
                      {lastLogin}
                    </div> */}
                    <div className="flex t-w-full">
                      {fieldStatus}
                      {fieldRole}
                    </div>
                    <div className="flex t-w-full">
                      {password}
                      {fieldSubCategory}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormikProvider>
      <ErrorDialog
        show={showErrorDialog}
        errorMsg={errorMsg}
        onHide={() => {
          setShowErrorDialog(false);
        }}
      />
      <LoadingDialog show={formFormik.isSubmitting} />
      <Toast ref={toast} position="top-right"></Toast>
    </div>
  );
}

export default FormCmsUsers;
