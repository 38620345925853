export const createCrigStaffGroupGQL = /* GraphQL */ `
  mutation CreateCrigStaffGroup(
    $input: CreateCrigStaffGroupInput!
    $condition: ModelCrigStaffGroupConditionInput
  ) {
    createCrigStaffGroup(input: $input, condition: $condition) {
      _deleted
      deleted
      _lastChangedAt
      _version
      createdAt
      datePublished
      dateUploaded
      id
      name
      shortDescription
      slug
      status
      totalStaff
      totalViewsCount
      updatedAt
    }
  }
`;

export const updateCrigStaffGroupGQL = /* GraphQL */ `
  mutation UpdateCrigStaffGroup(
    $input: UpdateCrigStaffGroupInput!
    $condition: ModelCrigStaffGroupConditionInput
  ) {
    updateCrigStaffGroup(input: $input, condition: $condition) {
      _deleted
      deleted
      _lastChangedAt
      _version
      createdAt
      datePublished
      dateUploaded
      id
      name
      shortDescription
      slug
      status
      totalStaff
      totalViewsCount
      updatedAt
    }
  }
`;

export const listCrigStaffGroupsGQL = /* GraphQL */ `
  query ListCrigStaffGroups(
    $filter: ModelCrigStaffGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrigStaffGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      startedAt
      items {
        _deleted
        deleted
        _lastChangedAt
        _version
        createdAt
        datePublished
        dateUploaded
        id
        name
        shortDescription
        slug
        status
        totalViewsCount
        totalStaff
        updatedAt
    }
  }
  }
`;

export const deleteCrigStaffGroupGQL = /* GraphQL */ `
  mutation DeleteCrigStaffGroup(
    $input: DeleteCrigStaffGroupInput!
    $condition: ModelCrigStaffGroupConditionInput
  ) {
    deleteCrigStaffGroup(input: $input, condition: $condition) {
      _deleted
      deleted
      _lastChangedAt
      _version
      createdAt
      datePublished
      dateUploaded
      id
      name
      shortDescription
      slug
      status
      totalStaff
      totalViewsCount
      updatedAt
    }
  }
`;
