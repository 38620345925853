export const createUserAccountGQL = /* GraphQL */ `
  mutation CreateUserAccount(
    $input: CreateUserAccountInput!
    $condition: ModelUserAccountConditionInput
  ) {
    createUserAccount(input: $input, condition: $condition) {
      _deleted
      deleted
      _lastChangedAt
      _version
      contactEmail1
      contactEmail2
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
      }
      contactPhoneNumber1
      contactPhoneNumber2
      contentFollowing
      countryName
      countryIso
      createdAt
      dateAdded
      dateAddedDay
      dateAddedMonth
      dateAddedYear
      dateLastLogin
      dateUpdated
      dateUpdatedDay
      dateUpdatedMonth
      dateUpdatedYear
      id
      nameFirst
      nameFull
      nameLast
      owner
      staffProfileID
      status
      updatedAt
      category
      userRoleID
    }
  }
`;

export const updateUserAccountGQL = /* GraphQL */ `
  mutation UpdateUserAccount(
    $input: UpdateUserAccountInput!
    $condition: ModelUserAccountConditionInput
  ) {
    updateUserAccount(input: $input, condition: $condition) {
      _deleted
      deleted
      _lastChangedAt
      _version
      contactEmail1
      contactEmail2
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
      }
      contactPhoneNumber1
      contactPhoneNumber2
      contentFollowing
      countryName
      countryIso
      createdAt
      dateAdded
      dateAddedDay
      dateAddedMonth
      dateAddedYear
      dateLastLogin
      dateUpdated
      dateUpdatedDay
      dateUpdatedMonth
      dateUpdatedYear
      id
      nameFirst
      nameFull
      nameLast
      owner
      staffProfileID
      status
      updatedAt
      category
      userRoleID
    }
  }
`;
 


export const listUserAccountsGQL = /* GraphQL */ `
  query ListUserAccounts(
    $filter: ModelUserAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      startedAt
      items {
        deleted
        _deleted
        _lastChangedAt
        _version
        contactEmail1
        contactEmail2
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
        }
        contactPhoneNumber1
        contactPhoneNumber2
        contentFollowing
        countryName
        countryIso
        createdAt
        dateAdded
        dateAddedDay
        dateAddedMonth
        dateAddedYear
        dateLastLogin
        dateUpdated
        dateUpdatedDay
        dateUpdatedMonth
        dateUpdatedYear
        id
        nameFirst
        nameFull
        nameLast
        owner
        staffProfileID
        status
        updatedAt
        category
        userRole {
          id
          title
        }
        userRoleID
      }
    }
  }
`;

export const deleteUserAccountGQL = /* GraphQL */ `
  mutation DeleteUserAccount(
    $input: DeleteUserAccountInput!
    $condition: ModelUserAccountConditionInput
  ) {
    deleteUserAccount(input: $input, condition: $condition) {
      _deleted
      deleted
      _lastChangedAt
      _version
      contactEmail1
      contactEmail2
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
      }
      contactPhoneNumber1
      contactPhoneNumber2
      contentFollowing
      countryName
      countryIso
      createdAt
      dateAdded
      dateAddedDay
      dateAddedMonth
      dateAddedYear
      dateLastLogin
      dateUpdated
      dateUpdatedDay
      dateUpdatedMonth
      dateUpdatedYear
      id
      nameFirst
      nameFull
      nameLast
      owner
      staffProfileID
      status
      updatedAt
      category
      userRoleID
    }
  }
`;

export const getUserAccountGQL = /* GraphQL */ `
  query GetUserAccount($id: ID!) {
    getUserAccount(id: $id) {
      id
      category
      deleted
      nameFirst
      nameLast
      nameFull
      status
      contactPhoneNumber1
      contactPhoneNumber2
      contactEmail1
      contactEmail2
      countryID
      countryIso3
      staffProfileID
      userRoleID
      contentFollowing
      dateLastLogin
      dateAdded
      dateAddedYear
      dateAddedMonth
      dateAddedDay
      dateUpdated
      dateUpdatedYear
      dateUpdatedMonth
      dateUpdatedDay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
