import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useCallback, useEffect, useRef, useState } from "react";
import { DataLoadingState } from "../../../models";
import { RecordStatus } from "../../../models";
import { ArticlePublication, Page, PageType, PostCategory, Slide } from "../../../models";
import ErrorDialog from "../../../shared-components/form/ErrorDialog";
import FormErrorMsg from "../../../shared-components/form/ErrorMsg";
import LoadingDialog from "../../../shared-components/form/LoadingDialog";
import useToaster from "../../../shared-components/toasts/Toaster";
import { StatusTypeSelect } from "../../../shared-components/toasts/forms/forms-interfaces";
import isDevMode from "../../../utils/check-dev-mode";
import { homeSlideArticlesPostCategoryId, homeSlideEventPostCategoryId, homeSlideMandatedCropsPostCategoryId, homeSlideNewsPostCategoryId } from "../../../utils/constants";
import { selectPublishStatusType } from "../../../utils/forms/util-forms";
import { useServiceForArticlePublicationsAPI } from "../../page-articles-publications/services/services-for-articles-publications";
import useDataEventsGalleryList from "../../page-events-gallery/data/data-for-event-gallery-list";
import { useServiceForEventsGalleryAPI } from "../../page-events-gallery/services/services-for-events-gallery";
import useDataNewsAnnouncementList from "../../page-news-announcements/data/data-for-news-announcement-list";
import { useServiceForNewsAnnouncementAPI } from "../../page-news-announcements/services/services-for-news-announcements";
import useDataPostCategoryList from "../../page-settings/page-main-sub-categories/data/data-for-post-categories-list";
import { useServiceForHomeSlidesAPI } from "../services/services-for-home-slide";
import useDataMandatedCropsList from "../../page-mandated-crops/data/data-for-mandated-crops-list";

interface Props {
    visible: boolean;
    onSetVisible: (value: boolean) => void;
    onSetFormAwaiting: (value: boolean) => void;
    reset: () => void;
    slide: Slide | null;
    awaiting: boolean;
}

const FormForHomeSlide = ({ visible, onSetVisible, reset, awaiting, slide, onSetFormAwaiting }: Props) => {

    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const postCategories = useDataPostCategoryList((state) => state.listItems);
    const postsNewsAnnouncement = useDataNewsAnnouncementList((state) => state.listItems);
    const postsEventsGallery = useDataEventsGalleryList((state) => state.listItems);
    const postsMandatedCrops = useDataMandatedCropsList((state) => state.listItems);
    // const postsArticlesPublications = useDataEventsGalleryList((state) => state.listItems);
    // const postsArticlesPublicationsLoading = useDataEventsGalleryList((state) => state.statusForLoadingListItems);
    const { updateHomeSlide } = useServiceForHomeSlidesAPI();
    const [selectedPostCat, setSelectedPostCat] = useState<PostCategory | null>(null);
    const [selectedPostCatName, setSelectedPostCatName] = useState<string | null>(null);
    const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
    const [selectedPageType, setSelectedPageType] = useState<PageType | null>(null);
    const [selectedPost, setSelectedPost] = useState<ArticlePublication | Page | null>(null);
    const [submitting, setSubmitting] = useState(false);

    const [isSidePop, setIsSidePop] = useState<string | null>(null);

    const [url, setUrl] = useState('');
    const slideTitleRef = useRef(null);
    const statusRef = useRef(null);
    const [slideTitle, setSlideTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [filteredPosts, setFilteredSelectedPosts] = useState<any[]>([]);
    const [slideItem, setSlideItem] = useState<Slide | null>(slide);
    const refToast = useRef(null);
    const [formItemStatus, setFormItemStatus] = useState<StatusTypeSelect | null>(null);
    const { showError, showSuccess } = useToaster();

    // const listItemsFromServiceArticles = useServicesForArticlesPublications().listAllData;

    const getStatus = (value: string) => {
        function findIndexFxn(item: StatusTypeSelect) {
            return item.value === value;
        }
        const index = selectPublishStatusType.findIndex(findIndexFxn);

        const item = selectPublishStatusType[index];

        return item;
    }

    useEffect(() => {
        setFormItemStatus(getStatus(selectedStatus!));
        return () => { }
    }, [selectedStatus])

    useEffect(() => {
        console.log(awaiting, slide)
        if (slide != null && !awaiting) {


            if (slideTitle.length == 0) {
                setSlideTitle(slide.slideTitle)
            }

            if (selectedPostCat == null && postCategories.length > 0) {
                const findFxn = (listItem: PostCategory) => {
                    return listItem.id == slide.postCategoryID;
                }

                const foundPostCat = postCategories.find(findFxn);

                console.log(foundPostCat)
                setSelectedPostCat(foundPostCat!)
                const filterFxn = (listItem: Page | ArticlePublication) => {
                    return listItem.status == RecordStatus.PUBLISHED;
                }

                const findSelectedFxn = (listItem: Page | ArticlePublication) => {
                    return listItem.id == slide.postID;
                }

                //FOR EVENTS/GALLERY
                if (foundPostCat!.id == homeSlideEventPostCategoryId && postsEventsGallery.length > 0) {
                    console.log(postsEventsGallery[0])
                    setFilteredSelectedPosts(postsEventsGallery.filter(filterFxn))
                    setSelectedPageType(PageType.EVENT_GALLERY)
                    const foundPost = postsEventsGallery.find(findSelectedFxn)
                    setSelectedPost(foundPost!)
                    setUrl(`events-gallery/${foundPost!.slug}`)
                }

                //FOR MANDATED CROPS
                if (foundPostCat!.id == homeSlideMandatedCropsPostCategoryId && postsMandatedCrops.length > 0) {
                    console.log(postsMandatedCrops[0])
                    setFilteredSelectedPosts(postsMandatedCrops.filter(filterFxn))
                    setSelectedPageType(PageType.MANDATED_CROPS)
                    const foundPost = postsMandatedCrops.find(findSelectedFxn)

                    setSelectedPost(foundPost!)
                    setUrl(`mandated-crops/${foundPost!.slug}`)
                }

                //FOR NEWS
                if (foundPostCat!.id == homeSlideNewsPostCategoryId && postsNewsAnnouncement.length > 0) {
                    setFilteredSelectedPosts(postsNewsAnnouncement.filter(filterFxn))
                    setSelectedPageType(PageType.NEWS_ANNOUNCEMENT)
                    const foundPost = postsNewsAnnouncement.find(findSelectedFxn)
                    console.log('foundPost', foundPost)
                    setSelectedPost(foundPost!)
                    setUrl(`news-announcements/${foundPost?.slug}`)
                }

                //FOR ARTICLES
                if (foundPostCat!.id == homeSlideArticlesPostCategoryId) {
                    // setFilteredSelectedPosts(postsArticlesPublications.filter(filterFxn))
                    setSelectedPageType(PageType.ARTICLE_PUBLICATION)
                    // setUrl(`news-announcements/${foundPost!.slug}`)
                }

            }

            if (selectedStatus == null) {
                setSelectedStatus(slide.status)
            }

            if (isSidePop == null) {
                setIsSidePop(slide.isSidePop)
            }


        }

        return () => { }
    }, [awaiting])




    const handleValidation = async () => {


        if (selectedPost == null || slideTitle.length == 0) {
            showError(refToast, 'ATTENTION', 'Select a Post')
            return false;
        }
        if (selectedPostCat == null) {
            showError(refToast, 'ATTENTION', 'Select a Post Category')
            return false;
        }

        if (selectedStatus == null) {
            showError(refToast, 'ATTENTION', 'Select a Status')
            return false;
        }

        if (selectedPost?.coverImage == null || selectedPost?.coverImage.key.length == 0) {

            showError(refToast, 'ATTENTION', 'Selected post should have a cover image.');
            return;
        }

        return true;

    }

    const handleSelectedPost = async (e: any) => {
        const value = e.value as ArticlePublication | Page;
        try {

            setSelectedPost(value);
            setSlideTitle(value.title!)

            //FOR EVENTS/GALLERY
            if (selectedPostCat?.id == homeSlideEventPostCategoryId) {
                setUrl(`events-gallery/${value.slug}`)
            }

            //FOR MANDATED CROPS
            if (selectedPostCat?.id == homeSlideMandatedCropsPostCategoryId) {
                setUrl(`mandated-crops/${value.slug}`)
            }

            //FOR NEWS
            if (selectedPostCat?.id == homeSlideNewsPostCategoryId) {
                setUrl(`news-announcements/${value.slug}`)

            }

            //FOR ARTICLES
            if (selectedPostCat?.id == homeSlideArticlesPostCategoryId) {
                setUrl(`articles-publications/${value.slug}`)
            }

        } catch (error) {
            return false;
        }
    }

    const handleSave = async () => {
        try {
            if (submitting) return;

            await handleValidation().then(async (valid) => {
                if (valid) {
                    setSubmitting(true);

                    const postSubCat = await selectedPost?.postSubCategory;

                    if (selectedPost?.coverImage == null || selectedPost?.coverImage.key.length == 0) {
                        setSubmitting(false);
                        showError(refToast, 'ATTENTION', 'Selected post should have a cover image.');
                        return;
                    }

                    const itemToSave: Slide = {
                        ...slide!,
                        dateUpdated: new Date().toISOString(),
                        isSidePop: isSidePop ?? 'no',
                        slidePageURL: url,
                        pageType: selectedPageType!,
                        postCategoryID: selectedPostCat!.id,
                        postCoverImage: selectedPost!.coverImage!,
                        postID: selectedPost!.id,
                        postSlug: selectedPost!.slug!,
                        slideTitle: slideTitle,
                        status: formItemStatus!.value,
                        postSubCategoryName: postSubCat?.name,
                        postCategoryName: selectedPostCat!.name,
                    }


                    if (isDevMode()) {
                        console.log('itemToSave', itemToSave, postSubCat)
                    }


                    const saved = await updateHomeSlide(itemToSave)


                    if (saved) {
                        showSuccess(refToast, 'ATTENTION', 'Successfully saved.');
                        onSetVisible(false);
                        onSetFormAwaiting(true)
                    }

                    // else {
                    //     showError(refToast, 'ATTENTION', 'Something went wrong, kindly try again.');
                    //     setSubmitting(false);
                    // }
                }
            });

        } catch (error) {
            console.log('error', error)
            setSubmitting(false);
            showError(refToast, 'ATTENTION', 'Something went wrong, kindly try again.');
        }
    }


    const fieldSidePop = (
        <div className="t-w-full md:ml-2  md:t-mt-[63px]">
            <div key={'sidePop'} className="flex align-items-center t-bg-[#000000]/[.04] t-rounded-md t-py-3 t-px-2 t-border-2">
                <Checkbox inputId={'sidePop'} name="sidePop" value={isSidePop} onChange={
                    (e) => {
                        if (isSidePop == 'yes') { setIsSidePop('no'); return }
                        setIsSidePop('yes')
                    }
                } checked={isSidePop == 'yes'} />
                <label htmlFor="sidePop" className="ml-2">Show on Side Pop</label>
            </div>
        </div>
    );

    const fieldStatus = (
        <div className="t-w-full form-field">
            <p className="t-text-appgrey-800 t-mt-8 t-mb-2 login-input-label t-font-semibold">
                {'Status'}
            </p>
            <Dropdown
                className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
                options={selectPublishStatusType}
                optionLabel="name"
                placeholder="Select"
                id={'status'}
                onChange={(e) => { setSelectedStatus(e.value) }}
                disabled={submitting}
                value={formItemStatus?.value}
                ref={statusRef}
            />
            {/* <FormErrorMsg fieldId={'status'} errorMsg={'Required'} /> */}
        </div>
    );

    const footerContent = (
        <div>
            <Button label="Cancel" icon="pi pi-times" onClick={() => {
                onSetVisible(false)
                onSetFormAwaiting(true)
            }} className="p-button-text" />
            <Button label="Save" icon={`pi ${submitting ? 'pi-spin pi-spinner' : 'pi-check'} `} onClick={
                async () => {
                    await handleSave()
                }} autoFocus />
        </div>
    );

    const title = (
        <div className="form-field">
            <p className="t-text-black t-mt-6 t-font-inter t-mb-2 login-input-label t-font-medium">
                Slide Title
            </p>
            <span className="w-full p-input-filled">
                <InputText
                    id='slideTitle'
                    onChange={(e: any) => {
                        setSlideTitle(e.target.value)
                    }}
                    maxLength={500}
                    disabled={submitting}
                    value={slideTitle}
                    ref={slideTitleRef}
                />
            </span>
            <FormErrorMsg fieldId={'slideTitle'} errorMsg={errorMessage} />
        </div>
    );

    return (
        <Dialog
            header={`Update ${slide?.slidePosition != 6 ? `Slide ${slide?.slidePosition}` : 'Top Bar Banner'}`} visible={visible} footer={footerContent}
            style={{ width: '30vw' }} onHide={() => {
                onSetVisible(false)
                onSetFormAwaiting(true)
            }}>
            <div className="flex t-flex-col  justify-content-center">
                <p className="pb-1 t-text-black font-medium">Select Post Category</p>
                <Dropdown value={selectedPostCat}
                    onChange={(e) => {
                        const value = e.value as PostCategory;
                        setSelectedPostCat(value)
                        const filterFxn = (listItem: Page | ArticlePublication) => {
                            return listItem.status == RecordStatus.PUBLISHED;
                        }
                        //FOR EVENTS/GALLERY
                        if (value.id == homeSlideEventPostCategoryId) {
                            // console.log(postsEventsGallery[0])
                            setFilteredSelectedPosts(postsEventsGallery.filter(filterFxn))
                            setSelectedPageType(PageType.EVENT_GALLERY)
                        }

                        //FOR MANDATED CROPS
                        if (value.id == homeSlideMandatedCropsPostCategoryId) {
                            // console.log(postsEventsGallery[0])
                            setFilteredSelectedPosts(postsMandatedCrops.filter(filterFxn))
                            setSelectedPageType(PageType.MANDATED_CROPS)
                        }

                        //FOR NEWS
                        if (value.id == homeSlideNewsPostCategoryId) {
                            setFilteredSelectedPosts(postsNewsAnnouncement.filter(filterFxn))
                            setSelectedPageType(PageType.NEWS_ANNOUNCEMENT)
                        }

                        //FOR ARTICLES
                        if (value.id == homeSlideArticlesPostCategoryId) {
                            // setFilteredSelectedPosts(postsArticlesPublications.filter(filterFxn))
                            setSelectedPageType(PageType.ARTICLE_PUBLICATION)
                        }

                    }}
                    options={postCategories} optionLabel="name"
                    filter placeholder="" className="w-full md:w-full" />
            </div>
            <div className="flex t-flex-col t-mt-5 justify-content-center">
                <p className="pb-1 t-text-black font-medium">Select A Post</p>
                <Dropdown value={selectedPost} onChange={handleSelectedPost} options={filteredPosts} optionLabel="title"
                    filter placeholder="" className="w-full md:w-full" />
            </div>
            <div className="flex t-flex-col t-mt-0 justify-content-center">
                {title}
            </div>
            <div className="t-flex t-items-center t-justify-items-stretch">
                {fieldStatus}
                {/* {slide?.slidePosition == 6 && fieldSidePop} */}
            </div>
            <ErrorDialog show={showErrorDialog} errorMsg={'Something went wrong, kindly try again.'} onHide={() => { setShowErrorDialog(false) }} />
            <LoadingDialog show={submitting} />
            <Toast ref={refToast} position="top-right"></Toast>
        </Dialog>
    )
}

export default FormForHomeSlide;