import { ColProps } from "../../shared-interfaces/dashboard/dashboard-interfaces";
import DashboardComponentContentDetail from "./DashboardComponentContentDetail";

function DashboardComponentContent({
  border,
  upperIcon,
  lowerIcon,
  upperTitle,
  lowerTitle,
  upperCount,
  lowerCount,
  upperCountUpdate,
  lowerCountUpdate,
}: ColProps) {
  return (
    <div className={`t-w-full t-m-1 ${border && "t-border-r"}`}>
      <DashboardComponentContentDetail
        icon={upperIcon}
        title={upperTitle}
        counter={upperCount}
        counterUpdate={upperCountUpdate}
      />
      <DashboardComponentContentDetail
        icon={lowerIcon}
        title={lowerTitle}
        counter={lowerCount}
        counterUpdate={lowerCountUpdate}
      />
    </div>
  );
}

export default DashboardComponentContent;
