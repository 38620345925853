import { useNavigate } from "react-router-dom";
import useDataForTextEditor from "../../../editors/data-for-editor";
import { FormActionType } from "../../../models";
import useRoutePaths from "../../../routes/route-path";
import TableAddNewButton from "../../../shared-components/button/TableAddNewButton";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import useDataExternalServicesForm from "../data/data-for-external-service-form";
import TableExternalServices from "../tables/table-for-external-services";

function ListExternalServices() {
  const { paths: route } = useRoutePaths();
  const setFormActionType = useDataExternalServicesForm(
    (state) => state.setFormActionType
  );

  const navigation = useNavigate();
  const coverImageReset = useDataForCoverImageFile((state) => state.reset);
  const editorReset = useDataForTextEditor((state) => state.reset);
  const resetForm = useDataExternalServicesForm(
    (state) => state.reset
  );

  return (
    <div className="flex t-flex-col t-overflow-hidden t-justify-start t-h-fit t-rounded-lg t-shadow-lg t-m-10">
      <div className="">
        <CustomNavigation
          background={false}
          title="External Services"
          buttons={
            <TableAddNewButton label='Add New' onClick={() => {
              coverImageReset()
              editorReset()
              setFormActionType(FormActionType.NEW);
              resetForm()
              navigation(route.externalServices.details);
            }} />
          }

        />
      </div>

      <TableExternalServices />
    </div>
  );
}

export default ListExternalServices;
