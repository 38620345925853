import * as Yup from "yup";

export const SchemaForStaffCategory = Yup.object().shape({
    name: Yup.string()
        .min(1, "Too Short!")
        .max(100, "Too Long!")
        .required("Required"),
    slug: Yup.string()
        .required("Required"),
    shortDescription: Yup.string()
        .min(1, "Too Short!")
        .max(1000, "Too Long!")
        .required("Required"),
    status: Yup.string()
        .required("Required")
});
