import { useEffect } from "react";
import {
  DataLoadingState,
  UserAccount,
  UserCategoryType,
} from "../../../models";
import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../../graphql/subscriptions";
import useDataForUserAuth from "../../../store/auth/data-for-auth";
import isDevMode from "../../../utils/check-dev-mode";
import useDataContentFollowersList from "../data/data-for-content-followers-list";
import useDataContentFollowersForm from "../data/data-for-content-followers-form";
import {
  createUserAccountGQL,
  deleteUserAccountGQL,
  getUserAccountGQL,
  listUserAccountsGQL,
  updateUserAccountGQL,
} from "../../../custom-graphql/custom-gql-user-account";

interface ServiceInterface {
  addNewData: (item: UserAccount) => Promise<boolean>;
  updateData: (
    item: UserAccount,
    isDelete: boolean
  ) => Promise<boolean | UserAccount>;
  updateDataField: (id: string, key: string, value: any) => Promise<boolean>;
  deleteData: (item: UserAccount) => Promise<boolean>;
  findOneData: (id: string) => Promise<UserAccount | null>;
  listAllData: () => Promise<boolean | null>;
}
const pageModelName = "cms-contentFollowers";

export function useServiceForContentFollowersAPI(): ServiceInterface {
  const pageDataAdd = useDataContentFollowersList((state) => state.addItem);
  const loggedUser = useDataForUserAuth((state) => state.loggedUser);
  const formData = useDataContentFollowersForm((state) => state.formData);
  const setFormData = useDataContentFollowersForm((state) => state.setFormData);
  const pageDataSetStatusForLoading = useDataContentFollowersList(
    (state) => state.setStatusForLoadingListItems
  );

  const pageDataSetList = useDataContentFollowersList(
    (state) => state.setListItems
  );
  const pageDataUpdate = useDataContentFollowersList(
    (state) => state.updateItem
  );
  const pageDataDelete = useDataContentFollowersList(
    (state) => state.deleteItem
  );

  const addNewData = async (item: UserAccount) => {
    let newItem: UserAccount;
    let dataResult: UserAccount;

    newItem = {
      ...item,
      category: UserCategoryType.CONTENT_FOLLOWER,
      dateUpdated: new Date().toISOString(),
    };

    try {
      const results: any = await API.graphql(
        graphqlOperation(createUserAccountGQL, { input: newItem })
      );
      console.log(`addNew ${pageModelName} results`, results);
      dataResult = results.data.createUserAccount;

      ;
      return true;
    } catch (error) {
      console.log("dataResult", error);
      console.log(`addNew ${pageModelName} onError`, error);
      return false;
    }
  };

  const updateData = async (item: UserAccount, isDelete: boolean) => {
    let newItem: UserAccount;
    let dataResult: UserAccount;
    newItem = {
      ...item,
      dateUpdated: new Date().toISOString(),
    };

    if (isDelete) {
      newItem = {
        ...item,
        deleted: true,
      };
    }

    delete (newItem as any).createdAt;
    delete (newItem as any).staffProfiles;
    delete (newItem as any).postCategory;
    delete (newItem as any).postSubCategory;
    delete (newItem as any).updatedAt;
    delete (newItem as any).creatorUserAccount;
    delete (newItem as any)._lastChangedAt;
    delete (newItem as any)._deleted;
    delete (newItem as any).files;
    delete (newItem as any).unitDepartments;

    try {
      const results: any = await API.graphql(
        graphqlOperation(updateUserAccountGQL, { input: newItem })
      );
      console.log(`update ${pageModelName} results`, results);
      dataResult = results.data.updateUserAccount;
      if (isDelete) {
        return dataResult;
      } else {
        ;
        return true;
      }
    } catch (error) {
      console.log(`update ${pageModelName} onError`, error);
      return false;
    }
  };

  const listAllData = async () => {
    let foundItems: UserAccount[] | null | undefined;

    const variables = {
      filter: {
        or: [{ category: { eq: UserCategoryType.CONTENT_FOLLOWER } }],
      },
    };

    try {
      const results: any = await API.graphql({
        query: listUserAccountsGQL,
        variables: variables,
      });
      foundItems = results.data.listUserAccounts.items as UserAccount[];

      if (isDevMode()) {
        console.log(`listAll ${pageModelName} foundItems`, foundItems);
      }

      foundItems.sort(
        (a, b) =>
          new Date(b.dateAdded!).getTime() - new Date(a.dateAdded!).getTime()
      );
      pageDataSetList(foundItems);
      pageDataSetStatusForLoading(DataLoadingState.LOADED);
      return true;
    } catch (error) {
      if (isDevMode()) {
        console.log(`listAll ${pageModelName} error`, error);
      }
      pageDataSetStatusForLoading(DataLoadingState.LOADED);
      return false;
    }
  };

  const findOneData = async (id: string) => {
    let foundItem: UserAccount | null;
    try {
      const results: any = await API.graphql(
        graphqlOperation(getUserAccountGQL, { id: id })
      );

      if (isDevMode()) {
        console.log(`findOneData ${pageModelName} results`, results);
      }

      foundItem = results.data.getUserAccount;

      return foundItem;
    } catch (error) {
      if (isDevMode()) {
        console.log(`findOneData ${pageModelName} onError`, error);
      }

      return null;
    }
  };

  const deleteData = async (item: any) => {
    let dataResult: UserAccount;
    console.log(item);
    try {
      item = { ...item, deleted: true };

      const results: any = await API.graphql(
        graphqlOperation(deleteUserAccountGQL, {
          input: { id: item.id, _version: item._version },
        })
      );

      if (isDevMode()) {
        console.log(`delete ${pageModelName} results`, results);
      }

      dataResult = results.data.deleteUserAccount;

      await updateData(dataResult, true);

      return true;
    } catch (error) {
      if (isDevMode()) {
        console.log(`update ${pageModelName} onError`, error);
      }

      return false;
    }
  };

  //FIGURE OUT API
  const updateDataField = async (id: string, key: string, value: any) => {
    return false;
  };

  useEffect(() => {
    const createSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onCreateUserAccount, {
          filter: {
            or: [{ category: { eq: UserCategoryType.CONTENT_FOLLOWER } }],
          },
        })
      ) as unknown as any
    ).subscribe({
      next: async ({ provider, value }) => {
        console.log({ provider, value });
        let data = value.data.onCreateUserAccount as UserAccount;
        let url: string | null = "";

        pageDataAdd(data);
        if (formData?.id == data.id) {
          setFormData(data);
        }
      },
      error: (error: any) => {
        console.warn(error);
      },
    });

    const updateSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onUpdateUserAccount, {
          filter: {
            or: [{ category: { eq: UserCategoryType.CONTENT_FOLLOWER } }],
          },
        })
      ) as unknown as any
    ).subscribe({
      next: async ({ provider, value }) => {
        console.log({ provider, value });
        let data = value.data.onUpdateUserAccount as UserAccount;
        let url: string | null = "";

        pageDataUpdate(data);
        if (formData?.id == data.id) {
          setFormData(data);
        }
      },
      error: (error: any) => {
        console.warn(error);
      },
    });

    const deleteSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onDeleteUserAccount, {
          filter: {
            or: [{ category: { eq: UserCategoryType.CONTENT_FOLLOWER } }],
          },
        })
      ) as unknown as any
    ).subscribe({
      next: ({ provider, value }) => {
        console.log({ provider, value });
        pageDataDelete(value.data.onDeletePage as UserAccount);
      },
      error: (error: any) => {
        console.warn(error);
      },
    });

    return () => {
      const cleanupSubscriptions = () => {
        createSubscription.unsubscribe();
        updateSubscription.unsubscribe();
        deleteSubscription.unsubscribe();
      };
      cleanupSubscriptions();
    };
  }, []);

  return {
    addNewData,
    updateData,
    updateDataField,
    deleteData,
    findOneData,
    listAllData,
  };
}
