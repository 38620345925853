import { useEffect, useRef, useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { useCrigUnitDepartmentServiceAPI } from "../services/services-for-staff-unit-departments";
import { format } from "date-fns";
import TableActionButtonSet from "../../../../shared-components/button/TableActionButtonSet";
import useDataStaffUnitDepartmentList from "../data/data-for-staff-unit-departments-list";
import { CrigUnitDepartment, DataLoadingState } from "../../../../models";
import DeleteDialog from "../../../../shared-components/form/DeleteDialog";
import useToaster from "../../../../shared-components/toasts/Toaster";
import { ListLoader } from "../../../../shared-components/list-tables/list-loader";
import useDataStaffUnitDepartmentForm from "../data/data-for-staff-unit-departments-form";
import { FormActionType } from "../../../../models";

const TableStaffUnitDepartment = () => {
  const listItems = useDataStaffUnitDepartmentList((state) => state.listItems);
  const setStateLoadingStatus = useDataStaffUnitDepartmentList(
    (state) => state.setStatusForLoadingListItems
  );
  const setShowDialog = useDataStaffUnitDepartmentForm(
    (state) => state.setShowDialog
  );
  const setFormData = useDataStaffUnitDepartmentForm(
    (state) => state.setFormData
  );

  const listItemsFromService = useCrigUnitDepartmentServiceAPI().listAllData;
  const deleteItemFromService = useCrigUnitDepartmentServiceAPI().deleteData;
  const refToast = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const { showError, showSuccess } = useToaster();
  const setFormActionType = useDataStaffUnitDepartmentForm(
    (state) => state.setFormActionType
  );

  const loadingStatus = useDataStaffUnitDepartmentList(
    (state) => state.statusForLoadingListItems
  );
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // "country.name": {
    //   operator: FilterOperator.AND,
    //   constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    // },
    // representative: { value: null, matchMode: FilterMatchMode.IN },
    dateUpdated: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },

    // status: {
    //   operator: FilterOperator.OR,
    //   constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    // },
    activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
  });

  const columnsAll: any = [
    { field: "id", header: "ID" },
    { field: "name", header: "Name" },
    {
      field: "crigDivisionSubstation.title",
      align: "center",
      header: "GRIG Divisions",
    },
    { field: "totalStaff", align: "center", header: "Total Staff" },
  ];

  const columnsSelected: any = [
    { field: "name", header: "Name" },
    {
      field: "crigDivisionSubstation.title",
      align: "left",
      header: "GRIG Divisions",
    },
    { field: "totalStaff", align: "center", header: "Total Staff" },
  ];

  const [selectedColumns, setSelectedColumns] = useState(columnsSelected);

  const [itemToDelete, setItemToDelete] = useState<CrigUnitDepartment | null>(
    null
  );

  const deleteOnClick = (rowData: any) => {
    setItemToDelete(rowData as CrigUnitDepartment);
  };

  const handleDeleteItem = async () => {
    try {
      let _itemToDelete: CrigUnitDepartment = {
        ...itemToDelete!,
        deleted: true,
      };
      await deleteItemFromService(_itemToDelete).then((done) => {
        setItemToDelete(null);
      });
    } catch (error) {
      console.log(`err handleDeleteItem research`, error);
      showError(
        refToast,
        "ATTENTION",
        "Something went wrong, kindly try again."
      );
    }
  };

  const onColumnToggle = (event: any) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columnsAll.filter((col: { field: any }) =>
      selectedColumns.some((sCol: { field: any }) => sCol.field === col.field)
    );
    setSelectedColumns(orderedSelectedColumns);
  };

  const onSearchKeywordChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    return () => {
      const fetchListData = async () => {
        await listItemsFromService();
      };
      if (listItems.length == 0 && loadingStatus == DataLoadingState.PENDING) {
        setStateLoadingStatus(DataLoadingState.LOADING);
        fetchListData();
      }
      //  else if (listItems.length == 0 && loadingStatus == DataLoadingState.LOADED) {
      //   setStateLoadingStatus(DataLoadingState.LOADING)
      //   fetchListData();
      // }
    };
  }, [loadingStatus, listItems]);

  const editOnClick = (rowData: any) => {
    setFormActionType(FormActionType.EDIT);
    setFormData(rowData as CrigUnitDepartment);
    setShowDialog(true);
  };

  const actionsBodyTemplate = (rowData: any) => {
    return (
      <TableActionButtonSet
        editOnClick={() => {
          editOnClick(rowData);
        }}
        viewOnClick={() => { }}
        deleteOnClick={() => {
          deleteOnClick(rowData);
        }}
      />
    );
  };
  const header = (
    <div
      className="flex t-flex-col  md:t-flex-row
       justify-content-between align-items-center "
    >
      <div className="list-column-select" style={{ textAlign: "left" }}>
        <MultiSelect
          fixedPlaceholder={true}
          placeholder="Columns"
          value={selectedColumns}
          options={columnsAll}
          optionLabel="header"
          onChange={onColumnToggle}
          style={{ width: "14.5em", borderRadius: "10px" }}
        />
      </div>
      <div className="list-search-box">
        <span className="p-input-icon-left p-input-border-none t-font-inter p-input-filled md:t-w-80 t-w-full">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onSearchKeywordChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    </div>
  );

  const columnComponents = selectedColumns.map((col) => {
    return (
      <Column
        key={col.field}
        align={col.align}
        field={col.field}
        header={col.header}
      />
    );
  });

  const formatDate = (value: Date) => {
    return format(value, `do LLL yyyy, h:mm aaa`);
  };

  const dateBodyTemplate = (rowData: any) => {
    const value: any = rowData["dateUploaded"];
    const valueAsDate = new Date(value);
    const formatted = formatDate(valueAsDate);
    return <p>{formatted}</p>;
  };

  return (
    <div className=" t-bg-white">
      {/* loader */}
      {(loadingStatus == DataLoadingState.LOADING ||
        loadingStatus == DataLoadingState.PENDING) && (
          <ListLoader skelsNumber={7} />
        )}
      {loadingStatus == DataLoadingState.LOADED && (
        <DataTable
          scrollHeight="70vh"
          scrollable
          value={listItems}
          filters={filters}
          header={header}
          responsiveLayout="scroll"
        >
          {columnComponents}
          <Column
            field="dateUploaded"
            align={"left"}
            header="Date Updated"
            showFilterMatchModes={false}
            style={{ minWidth: "9rem" }}
            body={dateBodyTemplate}
          />
          <Column
            field="activity"
            header="Actions"
            showFilterMatchModes={false}
            style={{ minWidth: "11rem" }}
            body={actionsBodyTemplate}
          />
        </DataTable>
      )}

      {itemToDelete != null && (
        <DeleteDialog
          message={`Are you sure you want to delete ${itemToDelete.name}? This action cannot be reversed!`}
          onDelete={handleDeleteItem}
          onSetVisible={() => {
            setItemToDelete(null);
          }}
          show={itemToDelete != null}
        />
      )}
    </div>
  );
};

export default TableStaffUnitDepartment;
