import { Avatar } from "primereact/avatar";
interface Props {
  label: string;
}

function CustomChip(props: Props) {
  return (
    <div className="flex t-my-4 t-bg-appgrey-400 t-p-2 t-rounded-full t-w-[30%] t-flex-wrap">
      <Avatar
        shape="circle"
        label={props.label.charAt(0).toUpperCase()}
        className="mr-2"
        style={{ backgroundColor: "#428bca", color: "#ffffff" }}
      />
      <p className="t-ml-1 t-text-appgrey-50 t-font-bold t-mt-1">
        {props.label}
      </p>
    </div>
  );
}

export default CustomChip;
