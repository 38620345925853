import { useNavigate } from "react-router-dom";
import { DataLoadingState, FormActionType } from "../../../../models";
import useRoutePaths from "../../../../routes/route-path";
import TableAddNewButton from "../../../../shared-components/button/TableAddNewButton";
import CustomNavigation from "../../../../shared-components/navigation/CustomNavigation";
import useDataMainSubCategoryForm from "../data/data-for-post-categories-form";
import useDataPostCategoryList from "../data/data-for-post-categories-list";
import TableMainSubCategories from "../tables/table-for-main-sub-categories";

function ListMainSubCategories() {
    const { paths: route } = useRoutePaths();
    const navigation = useNavigate();
    const setFormActionType = useDataMainSubCategoryForm((state) => state.setFormActionType);
    const loadingStatus = useDataPostCategoryList((state) => state.statusForLoadingListItems);

    return (
        <div className="flex t-flex-col t-overflow-hidden t-m-10 t-justify-start t-h-fit t-rounded-lg t-shadow-lg">
            <div className="">
                <CustomNavigation
                    background={false}
                    backArrowNavUrl={route.settings.initial}
                    backArrow={true}
                    title="Main/Sub Categories"
                    subTitle="Settings"
                    buttons={
                        <div>
                            {(loadingStatus == DataLoadingState.LOADED) &&
                                <TableAddNewButton label='Add New' onClick={() => {
                                    setFormActionType(FormActionType.NEW);
                                    navigation(route.settings.mainSubCategories.details);
                                }} />}
                        </div>

                    }
                />
            </div>

            <TableMainSubCategories />
        </div>
    );
}

export default ListMainSubCategories;
