import { useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { useRef, useState } from "react";
import TableActionButtonSet from "../../../shared-components/button/TableActionButtonSet";
import useDataCmsUsersList from "../data/data-for-content-followers-list";
import {
  DataLoadingState,
  FormActionType,
  UserAccount,
  S3UploadedItem,
} from "../../../models";
import useRoutePaths from "../../../routes/route-path";
import { useNavigate } from "react-router-dom";
import useDataCmsUsersForm from "../../page-cms-users/data/data-for-cms-users-form";
import useDataForTextEditor from "../../../editors/data-for-editor";
import { useServiceForContentFollowersAPI } from "../services/services-for-content-followers";
import useToaster from "../../../shared-components/toasts/Toaster";
import { ListLoader } from "../../../shared-components/list-tables/list-loader";
import DeleteDialog from "../../../shared-components/form/DeleteDialog";

import useDataContentFollowersList from "../data/data-for-content-followers-list";

const TableContentFollowers = () => {
  const listItems = useDataContentFollowersList((state) => state.listItems);
  const loadingStatus = useDataContentFollowersList(
    (state) => state.statusForLoadingListItems
  );

  const [itemToDelete, setItemToDelete] = useState<UserAccount | null>(null);
  const deleteItemFromService = useServiceForContentFollowersAPI().deleteData;
  const { showError, showSuccess } = useToaster();
  const refToast = useRef(null);
  const listItemsFromService = useServiceForContentFollowersAPI().listAllData;
  const setStateLoadingStatus = useDataCmsUsersList(
    (state) => state.setStatusForLoadingListItems
  );
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // "country.name": {
    //   operator: FilterOperator.AND,
    //   constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    // },
    // representative: { value: null, matchMode: FilterMatchMode.IN },
    dateUpdated: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },

    // status: {
    //   operator: FilterOperator.OR,
    //   constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    // },
    activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
  });
  const columnsAll: any = [
    { field: "id", header: "ID" },
    { field: "nameFull", header: "Full Name" },
    { field: "contactEmail1", header: "Email" },
    { field: "countryName", header: "Country" },
    { field: "refContentFollowing", header: "Following" },
    { field: "dateAdded", header: "Joined On" },
    { field: "dateLastLogin", header: "Last Login" },
    { field: "status", align: "center", header: "Status" },
  ];

  const columnsSelected: any = [
    { field: "nameFull", header: "Full Name" },
    { field: "contactEmail1", header: "Email" },
    { field: "countryName", header: "Country" },
    { field: "refContentFollowing", header: "Following" },
    { field: "dateAdded", header: "Joined On" },
    { field: "dateLastLogin", header: "Last Login" },
    { field: "status", align: "center", header: "Status" },
  ];

  const [selectedColumns, setSelectedColumns] = useState(columnsSelected);

  const onColumnToggle = (event: any) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columnsAll.filter((col: { field: any }) =>
      selectedColumns.some((sCol: { field: any }) => sCol.field === col.field)
    );
    setSelectedColumns(orderedSelectedColumns);
  };

  const onSearchKeywordChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleDeleteItem = async () => {
    try {
      let _itemToDelete: UserAccount = { ...itemToDelete!, deleted: true };
      await deleteItemFromService(_itemToDelete).then((done) => {
        setItemToDelete(null);
      });
    } catch (error) {
      console.log(`err handleDeleteItem research`, error);
      showError(
        refToast,
        "ATTENTION",
        "Something went wrong, kindly try again."
      );
    }
  };
  const header = (
    <div
      className="t-grid grid-cols-1 t-w-full  md:t-grid-cols-3
   justify-content-start align-items-center "
    >
      <div className="list-column-select" style={{ textAlign: "left" }}>
        <MultiSelect
          fixedPlaceholder={true}
          placeholder="Columns"
          value={selectedColumns}
          options={columnsAll}
          optionLabel="header"
          onChange={onColumnToggle}
          style={{ width: "14.5em", borderRadius: "10px" }}
        />
      </div>
      <div className="list-search-box t-w-full t-pt-4 md:t-pt-0">
        <span className="p-input-icon-left p-input-border-none  t-font-inter p-input-filled  t-w-full">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onSearchKeywordChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    </div>
  );

  const columnComponents = selectedColumns.map((col) => {
    return (
      <Column
        key={col.field}
        align={col.align}
        field={col.field}
        header={col.header}
      />
    );
  });

  const actionsBodyTemplate = (rowData) => {
    return (
      <TableActionButtonSet
        deleteOnClick={() => { }}
        editOnClick={() => { }}
        viewOnClick={() => { }}
      />
    );
  };

  useEffect(() => {

    let fetching = true;

    const fetchListData = async () => {
      await listItemsFromService();
    }

    if (fetching) {
      if (listItems.length == 0 && loadingStatus == DataLoadingState.PENDING) {
        setStateLoadingStatus(DataLoadingState.LOADING)
        fetchListData();
      }
    }

    return () => {
      fetching = false;
    }
  }, [loadingStatus, listItems])

  console.log("loadingStatus", loadingStatus);
  return (
    <div className="t-bg-white">
      {/* loader */}
      {(loadingStatus == DataLoadingState.LOADING ||
        loadingStatus == DataLoadingState.PENDING) && (
          <ListLoader skelsNumber={7} />
        )}
      {loadingStatus == DataLoadingState.LOADED && (
        <DataTable
          scrollable scrollHeight="62vh"
          value={listItems}
          filters={filters}
          header={header}
          responsiveLayout="scroll"
        >
          {columnComponents}
          <Column
            field="activity"
            header="Actions"
            showFilterMatchModes={false}
            style={{ minWidth: "11rem" }}
            body={actionsBodyTemplate}
          />
        </DataTable>
      )}
      {itemToDelete != null && (
        <DeleteDialog
          message={`Are you sure you want to delete ${itemToDelete.nameFull}? This action cannot be reversed!`}
          onDelete={handleDeleteItem}
          onSetVisible={() => {
            setItemToDelete(null);
          }}
          show={itemToDelete != null}
        />
      )}
    </div>
  );
};

export default TableContentFollowers;
