import { useNavigate } from "react-router-dom";
import { FormActionType } from "../../../models";
import useRoutePaths from "../../../routes/route-path";
import TableAddNewButton from "../../../shared-components/button/TableAddNewButton";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import useDataContentFollowersForm from "../data/data-for-content-followers-form";
import TableContentFollowers from "../tables/table-for-content-followers";

function ListContentFollowers() {
  const { paths: route } = useRoutePaths();
  const navigation = useNavigate();
  const setFormActionType = useDataContentFollowersForm(
    (state) => state.setFormActionType
  );

  return (
    <div className="flex t-flex-col t-overflow-hidden t-justify-start t-h-fit t-rounded-lg t-shadow-lg t-m-3">
      <div className="">
        <CustomNavigation
          background={false}
          backArrow={false}
          title="Content Followers"
          buttons={
            <TableAddNewButton
              label="Add New"
              onClick={() => {
                setFormActionType(FormActionType.NEW);
                navigation(route.contentFollowers.details);
              }}
            />
          }
        />
      </div>

      <TableContentFollowers />
    </div>
  );
}

export default ListContentFollowers;
