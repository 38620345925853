import { Ripple } from "primereact/ripple";
import { useRef } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { MenuItemInterface } from "./interfaces";

const MainLayoutMenuItemTopNav = (props: MenuItemInterface) => {
  const hasBadge: boolean = (props.badgeNumber ?? 0) > 0;
  const hasIcon: boolean = (props.icon ?? "").length > 0;
  const hasIconOnRight: boolean = props.iconOnRight ?? false;
  const borderRadius: string = props.borderRadius ?? "t-rounded-[15px]";
  const iconColor: string = props.iconColor ?? "text-appgrey-600";
  const ref = useRef(null);
  const nav = useNavigate();
  const { pathname } = useLocation();

  // console.log(pathname);

  const onClick = () => {
    nav(`${props.routePath}`);
  };
  return (
    <li onClick={onClick}>
      <a
        className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
transition-duration-150 transition-colors"
      >
        {hasIcon && !hasIconOnRight && (
          <i
            className={`pi ${props.icon} ${iconColor}  text-base lg:text-2xl mr-2 lg:mr-2`}
          ></i>
        )}

        <span className="font-medium t-mr-2">{props.title}</span>
        {hasBadge && (
          <span className="inline-flex align-items-center t-min-w-[1.5rem] t-h-[1.5rem] 
          justify-content-center ml-auto t-bg-apporange-500 text-0 border-circle">
            {props.badgeNumber}
          </span>
        )}
        {hasIcon && hasIconOnRight && (
          <i className={`pi ${props.icon} mr-2`}></i>
        )}
        <Ripple />
      </a>
    </li>
  );
};

export default MainLayoutMenuItemTopNav;
