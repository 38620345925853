import { useNavigate } from "react-router-dom";
import { FormActionType } from "../../../models";
import useRoutePaths from "../../../routes/route-path";
import TableAddNewButton from "../../../shared-components/button/TableAddNewButton";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import useDataStaffProfileForm from "../data/data-for-staff-profiles-form";
import TableStaffProfile from "../tables/table-for-staff-profiles";
import useDataForTextEditor from "../../../editors/data-for-editor";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataForGalleryImagesFile from "../../../shared-components/images/data-gallery-images";
import useDataForProfileImageFile from "../../../shared-components/images/data-profile-image";



function ListStaffProfile() {
  const { paths: route } = useRoutePaths();
  const navigation = useNavigate();

  const galleryImageReset = useDataForGalleryImagesFile((state) => state.reset);

  const profileImageReset = useDataForProfileImageFile((state) => state.reset);

  const coverImageReset = useDataForCoverImageFile((state) => state.reset);

  const editorReset = useDataForTextEditor((state) => state.reset);

  const formReset = useDataStaffProfileForm((state) => state.reset);

  const setFormActionType = useDataStaffProfileForm(
    (state) => state.setFormActionType
  );

  // const setShowDialog = useDataStaffProfileForm(
  //   (state) => state.setShowDialog
  // );


  // const showDialog = useDataStaffProfileForm(
  //   (state) => state.showDialog
  // );

  return (
    <div className="flex t-flex-col t-overflow-hidden t-justify-start t-h-fit t-rounded-lg t-shadow-lg md:t-m-10 t-m-3">
      <div className="">
        <CustomNavigation
          background={false}
          backArrow={false}
          title="CRIG Staff"
          subTitle="Manage Staff Profiles"

          buttons={
            <TableAddNewButton
              label="Add New"
              onClick={() => {
                setFormActionType(FormActionType.NEW);
                editorReset()
                profileImageReset()
                coverImageReset()
                galleryImageReset()
                formReset()
                navigation(route.crigStaff.details);

              }}
            />
          }
        />
      </div>

      <TableStaffProfile />
    </div>
  );
}

export default ListStaffProfile;
