import { useEffect } from "react";
import { DataLoadingState, PageType } from "../../../models";
import { Page } from "../../../models";
import useDataResearchActivitiesList from "../data/data-for-research-activities-list";
import { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../../../graphql/subscriptions';
import { createPageGQL, deletePageGQL, getPageGQL, listPagesGQL, updatePageGQL } from "../../../custom-graphql/custom-gql-page";
import useDataForUserAuth from "../../../store/auth/data-for-auth";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataResearchActivitiesForm from "../data/data-for-research-activities-form";
import isDevMode from "../../../utils/check-dev-mode";

interface ServiceInterfaceForResearchActivities {
    addNewData: (item: Page) => Promise<boolean>;
    updateData: (item: Page, isDelete: boolean) => Promise<boolean | Page>;
    updateDataField: (
        id: string,
        key: string,
        value: any
    ) => Promise<boolean>;
    deleteData: (item: Page) => Promise<boolean>;
    findOneData: (id: string) => Promise<Page | null>;
    listAllData: () => Promise<boolean | null>;
}
const pageModelName = 'research-activities';


export function useServiceForResearchActivitiesAPI(): ServiceInterfaceForResearchActivities {
    const pageDataAdd = useDataResearchActivitiesList((state) => state.addItem);
    const loggedUser = useDataForUserAuth((state) => state.loggedUser);
    const formData = useDataResearchActivitiesForm((state) => state.formData);
    const setFormData = useDataResearchActivitiesForm((state) => state.setFormData);
    const pageDataSetStatusForLoading = useDataResearchActivitiesList((state) => state.setStatusForLoadingListItems);
    const coverImageGetS3URL = useDataForCoverImageFile((state) => state.getS3URL);
    const pageDataSetList = useDataResearchActivitiesList(
        (state) => state.setListItems
    );
    const pageDataUpdate = useDataResearchActivitiesList(
        (state) => state.updateItem
    );
    const pageDataDelete = useDataResearchActivitiesList(
        (state) => state.deleteItem
    );

    const addNewData = async (item: Page) => {
        let newItem: Page;
        let dataResult: Page;

        newItem = {
            ...item,
            dateUpdated: new Date().toISOString(),
            creatorUserAccountID: loggedUser!.userAccount!.id
        };

        delete (newItem as any).createdAt;
        delete (newItem as any).staffProfiles;
        delete (newItem as any).postCategory;
        delete (newItem as any).postSubCategory;
        delete (newItem as any).updatedAt;
        delete (newItem as any).creatorUserAccount;
        delete (newItem as any)._lastChangedAt;
        delete (newItem as any)._deleted;


        try {
            const results: any = await API.graphql(graphqlOperation(createPageGQL, { input: newItem }));
            console.log(`addNew ${pageModelName} results`, results);
            dataResult = results.data.createPage;

            return true;
        } catch (error) {
            console.log(`addNew ${pageModelName} onError`, error);
            return false;
        }
    };

    const updateData = async (item: Page, isDelete: boolean) => {
        let newItem: Page;
        let dataResult: Page;

        newItem = {
            ...item,
            dateUpdated: new Date().toISOString(),
        };

        if (isDelete) {
            newItem = {
                ...item,
                deleted: true,
            };
        }

        delete (newItem as any).createdAt;
        delete (newItem as any).staffProfiles;
        delete (newItem as any).postCategory;
        delete (newItem as any).postSubCategory;
        delete (newItem as any).updatedAt;
        delete (newItem as any).creatorUserAccount;
        delete (newItem as any)._lastChangedAt;
        delete (newItem as any)._deleted;
        delete (newItem as any).unitDepartments;

        if (isDevMode()) {
            console.log(`update ${pageModelName} newItem`, newItem);
        }

        try {
            const results: any = await API.graphql(graphqlOperation(updatePageGQL, { input: newItem }));
            console.log(`update ${pageModelName} results`, results);
            dataResult = results.data.updatePage;
            if (isDelete) {
                return dataResult;
            } else {
                return true;
            }

        } catch (error) {
            console.log(`update ${pageModelName} onError`, error);
            return false;
        }


    };


    const listAllData = async () => {

        let foundItems: Page[] | null | undefined;

        const variables = {
            filter: {
                and: [
                    { pageType: { eq: PageType.RESEARCH_ACTIVITY } },
                    {
                        deleted: {
                            eq: false,
                        },
                    },
                ],

            }
        };


        try {
            const results: any = await API.graphql({
                query: listPagesGQL,
                variables: variables
            });
            foundItems = results.data.listPages.items as Page[]


            if (foundItems.length > 0) {
                for (var item of foundItems) {
                    let index = -1;
                    const coverImage = item.coverImage;
                    const galleryImages = item.galleryImages;
                    let coverImageUrl: string | null = '';

                    function findItemIndex(listItem: Page) {
                        return listItem.id == item.id;
                    }


                    index = foundItems.findIndex(findItemIndex)

                    if (coverImage != null) {
                        coverImageUrl = await coverImageGetS3URL(coverImage.key);
                        if (coverImageUrl != null) {
                            item = { ...item, coverImage: { ...item.coverImage!, signedURL: coverImageUrl } }
                            foundItems[index] = item;
                        }
                    }


                }
            }

            if (isDevMode()) {
                console.log(`listAll ${pageModelName} foundItems`, foundItems);
            }

            foundItems.sort((a, b) => new Date(b.datePublished!).getTime() - new Date(a.datePublished!).getTime())
            pageDataSetList(foundItems)
            pageDataSetStatusForLoading(DataLoadingState.LOADED)
            return true

        } catch (error) {
            if (isDevMode()) {
                console.log(`listAll ${pageModelName} error`, error);
            }
            pageDataSetStatusForLoading(DataLoadingState.LOADED)
            return false;
        }


    };


    const findOneData = async (id: string) => {
        let foundItem: Page | null;
        try {



            const results: any = await API.graphql(graphqlOperation(getPageGQL, { id: id }));

            if (isDevMode()) {
                console.log(`findOneData ${pageModelName} results`, results);
            }


            foundItem = results.data.getPage;

            return foundItem;

        } catch (error) {
            if (isDevMode()) {
                console.log(`findOneData ${pageModelName} onError`, error);
            }

            return null;
        }

    };



    const deleteData = async (item: any) => {
        let dataResult: Page;
        console.log(item)
        try {
            item = { ...item, deleted: true };


            const results: any = await API.graphql(graphqlOperation(deletePageGQL, { input: { id: item.id, _version: item._version, } }));

            if (isDevMode()) {
                console.log(`delete ${pageModelName} results`, results);
            }


            dataResult = results.data.deletePage;

            await updateData(dataResult, true);

            return true;

        } catch (error) {
            if (isDevMode()) {
                console.log(`update ${pageModelName} onError`, error);
            }

            return false;
        }

    };

    //FIGURE OUT API
    const updateDataField = async (
        id: string,
        key: string,
        value: any
    ) => {
        return false;
    };



    useEffect(() => {

        const createSubscription = (API.graphql(
            graphqlOperation(subscriptions.onCreatePage, {
                filter: {
                    pageType: { eq: PageType.RESEARCH_ACTIVITY }
                }
            })
        ) as unknown as any).subscribe({
            next: async ({ provider, value }) => {
                console.log({ provider, value })
                let data = value.data.onCreatePage as Page;
                let url: string | null = ''
                if (data.coverImage != null) {
                    url = await coverImageGetS3URL(data.coverImage.key);
                    if (url != null) {
                        data = { ...data, coverImage: { ...data.coverImage!, signedURL: url } }
                        // console.log('onCreatePage next ', data)
                    }
                }

                pageDataAdd(data);
                if (formData?.id == data.id) {
                    setFormData(data);
                }
            },
            error: (error: any) => {
                console.warn(error)
            }
        });

        const updateSubscription = (API.graphql(
            graphqlOperation(subscriptions.onUpdatePage, {
                filter: {
                    pageType: { eq: PageType.RESEARCH_ACTIVITY }
                }
            })
        ) as unknown as any).subscribe({
            next: async ({ provider, value }) => {
                console.log({ provider, value })
                let data = value.data.onUpdatePage as Page;
                let url: string | null = ''
                if (data.coverImage != null) {
                    url = await coverImageGetS3URL(data.coverImage.key);
                    if (url != null) {
                        data = { ...data, coverImage: { ...data.coverImage!, signedURL: url } }
                        // console.log('onUpdatePage next ', data)
                    }
                }

                pageDataUpdate(data);
                if (formData?.id == data.id) {
                    setFormData(data);
                }
            },
            error: (error: any) => {
                console.warn(error)
            }
        });


        const deleteSubscription = (API.graphql(
            graphqlOperation(subscriptions.onDeletePage, {
                filter: {
                    pageType: { eq: PageType.RESEARCH_ACTIVITY }
                }
            })
        ) as unknown as any).subscribe({
            next: ({ provider, value }) => {
                console.log({ provider, value })
                pageDataDelete(value.data.onDeletePage as Page);
            },
            error: (error: any) => {
                console.warn(error)
            }
        });


        return () => {
            const cleanupSubscriptions = () => {
                createSubscription.unsubscribe();
                updateSubscription.unsubscribe();
                deleteSubscription.unsubscribe();
            }
            cleanupSubscriptions();

        }
    }, []);

    return {
        addNewData,
        updateData,
        updateDataField,
        deleteData,
        findOneData,
        listAllData,
    };
}
