import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Fragment, useEffect, useRef, useState } from "react";
import TableActionButtonSet from "../../../shared-components/button/TableActionButtonSet";
import useDataStaffProfileList from "../data/data-for-staff-profiles-list";
import { useServiceForStaffProfileAPI } from "../services/services-for-staff-profiles";
import { DataLoadingState, S3UploadedItem, StaffProfile } from "../../../models";
import { format } from "date-fns";
import { ListLoader } from "../../../shared-components/list-tables/list-loader";
import DeleteDialog from "../../../shared-components/form/DeleteDialog";
import useRoutePaths from "../../../routes/route-path";
import useDataStaffProfileForm from "../data/data-for-staff-profiles-form";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataForTextEditor from "../../../editors/data-for-editor";
import { useNavigate } from "react-router-dom";
import useToaster from "../../../shared-components/toasts/Toaster";
import { FormActionType } from "../../../models";
import useDataForGalleryImagesFile from "../../../shared-components/images/data-gallery-images";
import useDataForProfileImageFile from "../../../shared-components/images/data-profile-image";

const TableStaffProfile = () => {
  const { paths: route } = useRoutePaths();
  const navigation = useNavigate();
  const listItems = useDataStaffProfileList((state) => state.listItems);
  const listItemsFromService = useServiceForStaffProfileAPI().listAllData;
  const setFormActionType = useDataStaffProfileForm(
    (state) => state.setFormActionType
  );
  const setFormData = useDataStaffProfileForm((state) => state.setFormData);

  console.log("staff profile listItems", listItems);
  const deleteItemFromService = useServiceForStaffProfileAPI().deleteData;
  const loadingStatus = useDataStaffProfileList(
    (state) => state.statusForLoadingListItems
  );
  const setStateLoadingStatus = useDataStaffProfileList(
    (state) => state.setStatusForLoadingListItems
  );
  const galleryImageReset = useDataForGalleryImagesFile((state) => state.reset);

  const profileImageReset = useDataForProfileImageFile((state) => state.reset);

  const coverImageReset = useDataForCoverImageFile((state) => state.reset);

  const editorReset = useDataForTextEditor((state) => state.reset);

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },

    dateUpdated: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },

    activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
  });

  const columnsAll: any = [
    { field: "id", header: "ID" },
    { field: "nameFull", header: "Full Name" },
    { field: "position", header: "Position" },
    { field: "crigStaffGroup.name", header: "Group" },
    { field: "crigDivisionSubStation.title", header: "Division/SubStation" },
    { field: "status", align: "center", header: "Status" },
  ];

  const columnsSelected: any = [
    { field: "nameFull", header: "Full Name" },
    { field: "position", header: "Position" },
    { field: "crigDivisionSubStation.title", header: "Division/SubStation" },
    { field: "status", align: "center", header: "Status" },
    // { field: "crigStaffGroup.name", header: "Group" },
  ];

  const [selectedColumns, setSelectedColumns] = useState(columnsSelected);
  const { showError, showSuccess } = useToaster();

  const refToast = useRef(null);

  const [itemToDelete, setItemToDelete] = useState<StaffProfile | null>(null);

  const onColumnToggle = (event: any) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columnsAll.filter((col: { field: any }) =>
      selectedColumns.some((sCol: { field: any }) => sCol.field === col.field)
    );
    setSelectedColumns(orderedSelectedColumns);
  };

  const onSearchKeywordChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = (
    <div
      className="t-grid grid-cols-1 t-w-full  md:t-grid-cols-3
   justify-content-start align-items-center "
    >
      <div className="list-column-select" style={{ textAlign: "left" }}>
        <MultiSelect
          fixedPlaceholder={true}
          placeholder="Columns"
          value={selectedColumns}
          options={columnsAll}
          optionLabel="header"
          onChange={onColumnToggle}
          style={{ width: "14.5em", borderRadius: "10px" }}
        />
      </div>
      <div className="list-search-box t-w-full t-pt-4 md:t-pt-0">
        <span className="p-input-icon-left p-input-border-none  t-font-inter p-input-filled  t-w-full">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onSearchKeywordChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    </div>
  );

  const columnComponents = selectedColumns.map((col) => {
    return (
      <Column
        key={col.field}
        align={col.align}
        field={col.field}
        header={col.header}
      />
    );
  });

  useEffect(() => {

    let fetching = true;

    const fetchListData = async () => {
      await listItemsFromService();
    }

    if (fetching) {
      if (listItems.length == 0 && loadingStatus == DataLoadingState.PENDING) {
        setStateLoadingStatus(DataLoadingState.LOADING)
        fetchListData();
      }
    }

    return () => {
      fetching = false;
    }
  }, [loadingStatus, listItems])

  const formatDate = (value: Date) => {
    return format(value, `do LLL yyyy, h:mm aaa`);
  };

  const dateBodyTemplate = (rowData: any) => {
    const value: any = rowData["dateUpdated"];
    const valueAsDate = new Date(value);
    const formatted = formatDate(valueAsDate);
    return <p>{formatted}</p>;
  };

  const profileImageTemplate = (rowData: any) => {
    const image: S3UploadedItem = rowData['profileImage'];
    let id = rowData['id'];
    let src = '';
    if (image != null) {
      src = image.signedURL ?? ''
    }
    return <div className="t-rounded-md t-object-cover t-h-[70px] t-bg-slate-200 t-w-[80px] t-overflow-hidden">
      {(src.length > 0) && <img className="t-object-cover t-h-[70px]  t-w-[120px]" id={id} src={src} />}
    </div>;
  };


  const editOnClick = (rowData: any) => {
    coverImageReset();
    editorReset();
    galleryImageReset();
    profileImageReset();
    setFormActionType(FormActionType.EDIT);
    setFormData(rowData as StaffProfile);
    navigation(route.crigStaff.details);
  };

  const deleteOnClick = (rowData: any) => {
    setItemToDelete(rowData as StaffProfile);
  };

  const handleDeleteItem = async () => {
    try {
      let _itemToDelete: StaffProfile = { ...itemToDelete!, deleted: true };
      await deleteItemFromService(_itemToDelete).then((done) => {
        setItemToDelete(null);
      });
    } catch (error) {
      console.log(`err handleDeleteItem news`, error);
      showError(
        refToast,
        "ATTENTION",
        "Something went wrong, kindly try again."
      );
    }
  };

  const actionsBodyTemplate = (rowData: any) => {
    return (
      <TableActionButtonSet
        editOnClick={() => {
          editOnClick(rowData);
        }}
        viewOnClick={() => { }}
        deleteOnClick={() => {
          deleteOnClick(rowData);
        }}
      />
    );
  };

  const calculatePostTotal = (groupFieldID: string) => {
    let total = 0;

    if (listItems) {
      for (let post of listItems) {
        if (post.crigStaffGroupID === groupFieldID) {
          total++;
        }
      }
    }

    return total;
  };


  const groupHeaderTemplate = (data: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span className="font-bold">{data.crigStaffGroup.name}</span>
      </div>
    );
  };

  const groupFooterTemplate = (data: any) => {
    return (
      <Fragment>
        <td colSpan={5}>
          <div className="flex justify-content-end font-bold w-full">Total Posts: {calculatePostTotal(data.crigStaffGroupID)}</div>
        </td>
      </Fragment>
    );
  };


  return (
    <div className=" t-bg-white">
      {/* loader */}
      {(loadingStatus == DataLoadingState.LOADING ||
        loadingStatus == DataLoadingState.PENDING) && (
          <ListLoader skelsNumber={7} />
        )}
      {loadingStatus == DataLoadingState.LOADED && (
        <DataTable
          scrollHeight="71vh"
          scrollable
          value={listItems}
          filters={filters}
          header={header}
          responsiveLayout="scroll"
          sortOrder={1}
          sortField="crigStaffGroup.name"
          rowGroupMode="subheader"
          groupRowsBy="crigStaffGroup.name"
          sortMode="single"
          rowGroupHeaderTemplate={groupHeaderTemplate}
          rowGroupFooterTemplate={groupFooterTemplate}
        >
          <Column
            field="profileImage"
            align={'left'}
            header="Profile Image"
            showFilterMatchModes={false}
            // style={{ minWidth: "100px", maxWidth: '100px' }}
            body={profileImageTemplate}
          />
          {columnComponents}
          <Column
            field="dateUpdated"
            align={"left"}
            header="Date Updated"
            showFilterMatchModes={false}
            style={{ minWidth: "9rem" }}
            body={dateBodyTemplate}
          />
          <Column
            field="activity"
            header="Actions"
            showFilterMatchModes={false}
            style={{ minWidth: "11rem" }}
            body={actionsBodyTemplate}
          />
        </DataTable>
      )}

      {itemToDelete != null && (
        <DeleteDialog
          message={`Are you sure you want to delete ${itemToDelete.nameFull}? This action cannot be reversed!`}
          onDelete={handleDeleteItem}
          onSetVisible={() => {
            setItemToDelete(null);
          }}
          show={itemToDelete != null}
        />
      )}
    </div>
  );
};

export default TableStaffProfile;
