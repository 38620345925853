import { Ripple } from "primereact/ripple";
import { useNavigate } from "react-router-dom";
import useRoutePaths from "../../../routes/route-path";

const SettingsPage = () => {
  interface GridItemProps {
    title: string;
    subTitle: string;
    url: string;
  }

  const { paths: route } = useRoutePaths();

  const GridItem = (props: GridItemProps) => {
    const nav = useNavigate();

    const onClick = () => {
      nav(`${props.url}`);
    };

    return (
      <div
        className="p-ripple  gray settings-grid t-rounded-[10px] t-transition-all
             t-ease-in-out t-bg-white  
             hover:t-cursor-pointer t-py-2 t-px-6 t-items-center 
             t-justify-center t-min-h-[85px] t-flex t-border-[1px] hover:t-border-[1.5px] t-border-slate-200  hover:t-border-slate-500"
        onClick={onClick}
      >
        <div className="t-flex-grow">
          <p className="t-font-semibold t-text-xl">{props.title}</p>
          <p className="t-font-normal t-text-slate-400 t-font-inter">
            {props.subTitle}
          </p>
        </div>
        <span>
          <i className={`pi pi-chevron-right ml-2`}></i>
        </span>
        <Ripple />
      </div>
    );
  };

  return (
    <div className="t-flex t-flex-col t-p-10 t-w-full t-m-auto t-max-w-[96rem] t-justify-start t-items-center t-h-[100%]">
      <div className="t-w-full">
        <div className="t-flex t-flex-col t-pb-6">
          <p className="t-font-playfair t-text-4xl t-font-semibold t-text-applisttitlebrown">
            Settings
          </p>
          <p className="t-font-inter t-text-lg t-py-2 t-font-medium t-text-slate-500">
            Manage the settings for CRIG CMS
          </p>
        </div>
        <div className="t-grid t-grid-cols-1 md:t-grid-cols-2 gap-4 ">
          <GridItem
            title="Main/Sub Categories"
            subTitle="Manage categories for articles, events, news, newsletters, website pages"
            url={route.settings.mainSubCategories.list}
            key={"0"}
          />
          <GridItem
            title="User Roles"
            subTitle="Manage access privileges for CRIG CMS users"
            url={route.settings.userRoles.list}
            key={"1"}
          />
          <GridItem
            title="CRIG Staff Groups"
            subTitle="Manage groupings of CRIG Staff listing"
            url={route.settings.crigStaffGroups.list}
            key={"2"}
          />
          <GridItem
            title="CRIG Divisions/Sub-Stations"
            subTitle="Manage CRIG Divisions/Sub-Stations"
            url={route.settings.crigDivisionsSubStations.list}
            key={"3"}
          />
          <GridItem
            title="CRIG Staff Unit/Departments"
            subTitle="Manage CRIG Unit/Departments"
            url={route.settings.crigStaffUnitDepartments.list}
            key={"4"}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
