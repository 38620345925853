import { Ripple } from "primereact/ripple";
import { Image } from "primereact/image";
import { StyleClass } from "primereact/styleclass";
import { useEffect, useRef, useState } from "react";
import useRoutePaths from "../../../../routes/route-path";
import MainLayoutMenuItemTopNav from "./MainLayoutMenuItemTopNav";
import useDataForDStoreEvents from "../../../../store/db/data-db-status";

const MainLayoutTopNav = () => {
  const { paths: route } = useRoutePaths();
  const btnRef52 = useRef(null);
  const btnRef53 = useRef(null);

  const [beStatusText, setBeText] = useState("");
  const [beStatusBgColor, setBeStatusBgColor] = useState("");
  const [beStatusVisibility, setBeStatusVisibility] = useState(false);
  const [beStatusTextColor, setBeStatusTextColor] = useState("t-text-white");

  const { hasNetwork, syncQueriesReady, syncQueriesOngoing, ready } =
    useDataForDStoreEvents();

  const setNetworkStatus = () => {
    if (hasNetwork !== null) {
      if (!hasNetwork && !beStatusVisibility) {
        setBeStatusVisibility((_) => true);
      }

      if (!hasNetwork) {
        setBeStatusBgColor((_) => "t-bg-red-900");
        setBeText((_) => "working offline");
        setBeStatusTextColor("t-text-white");
      } else if (syncQueriesReady) {
        console.log("ready1");
        setBeStatusBgColor((_) => "t-bg-green-900");
        setBeText((_) => "working online");
        setBeStatusVisibility((_) => false);
        setBeStatusTextColor("t-text-white");
      }
    }
  };

  const setDatastoreStatus = () => {
    if (hasNetwork && !beStatusVisibility) {
      setBeStatusVisibility((_) => true);
    }

    if (syncQueriesOngoing) {
      setBeStatusBgColor((_) => "bg-amber-500");
      setBeText((_) => "syncing online");
      setBeStatusTextColor("t-text-amber");
    } else if (syncQueriesReady) {
      console.log("ready2");
      setBeStatusBgColor((_) => "t-bg-green-900");
      setBeText((_) => "Datastore ready");
      setBeStatusVisibility((_) => false);
    }
  };

  useEffect(() => {
    setNetworkStatus();
    setDatastoreStatus();

    return () => {};
  }, [hasNetwork, syncQueriesReady, syncQueriesOngoing, ready]);

  return (
    <div className="t-flex t-flex-col t-w-full">
      <div
        className="flex justify-content-between t-h-[60px] t-min-h-[60px] t-bg-applightgreen-100 
      align-items-center px-5 border-bottom-1 surface-border relative lg:static"
      >
        <StyleClass
          nodeRef={btnRef52}
          selector="#app-sidebar-11"
          enterClassName="hidden"
          enterActiveClassName="fadeinleft"
          leaveToClassName="hidden"
          leaveActiveClassName="fadeoutleft"
          hideOnOutsideClick
        >
          <a
            ref={btnRef52}
            className="p-ripple cursor-pointer block lg:hidden text-700 mr-3"
          >
            <i className="pi pi-bars text-4xl"></i>
            <Ripple />
          </a>
        </StyleClass>
        <StyleClass
          nodeRef={btnRef53}
          selector="@next"
          enterClassName="hidden"
          enterActiveClassName="fadein"
          leaveToClassName="hidden"
          leaveActiveClassName="fadeout"
          hideOnOutsideClick
        >
          <a
            ref={btnRef53}
            className="p-ripple cursor-pointer block lg:hidden text-700"
          >
            <i className="pi pi-ellipsis-v text-2xl"></i>
            <Ripple />
          </a>
        </StyleClass>
        <ul
          className="list-none p-0 m-0 hidden lg:flex lg:justify-content-between lg:align-items-center select-none lg:flex-row w-auto lg:w-full
    border-1  lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static t-bg-applightgreen-100"
        >
          <div className="hidden lg:flex lg:align-items-center ">
            <MainLayoutMenuItemTopNav
              title="Settings"
              routePath={route.settings.initial}
              icon={"pi-cog"}
            />
            {/* <MainLayoutMenuItemTopNav
              title="Help Center"
              routePath={route.helpCenter.faq}
              icon={"pi-question-circle"}
            /> */}
          </div>
          <div className="hidden lg:flex lg:align-items-center">
            <div>
              <Image src="/img/crig-logo.png" width="40px" height="40px" />
            </div>
            <p className="t-font-bold t-font-playfair t-ml-2 t-text-xl t-text-applightgreen-500">
              CRIG CMS
            </p>
          </div>
          <div className="hidden lg:flex lg:align-items-center">
            {/* <MainLayoutMenuItemTopNav
              title="Notifications"
              routePath={route.notifications}
              icon={"pi-bell"}
              badgeNumber={3}
            /> */}
            <MainLayoutMenuItemTopNav
              title="My Profile"
              routePath={route.profile.initial}
              icon={"pi-user"}
            />
          </div>
        </ul>
      </div>
      {beStatusVisibility && (
        <div className={`t-h-[0px] ${beStatusBgColor}`}>
          <div>{beStatusText}</div>
        </div>
      )}
    </div>
  );
};

export default MainLayoutTopNav;
