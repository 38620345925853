import { FormikProvider, useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import { Chips } from "primereact/chips";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { useCallback, useEffect, useRef, useState } from "react";
import TextEditorCK from "../../../editors/ckeditorjs/TextEditorCKEditor";
import useDataForTextEditor from "../../../editors/data-for-editor";
import PageBuilder from "../../../editors/grapejs/PageBuilderGrape";
import { DataLoadingState, EditorType, Folder, FormActionType, Page, PageType, PostCategory, PostSubCategory, S3UploadedItem, UploadCategory } from "../../../models";
import useRoutePaths from "../../../routes/route-path";
import FormActionButtonSet from "../../../shared-components/button/FormActionButtonSet";
import FormErrorMsg from "../../../shared-components/form/ErrorMsg";
import SectionHeader from "../../../shared-components/header/SectionHeader";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataForGalleryImagesFile from "../../../shared-components/images/data-gallery-images";
import { GalleryImagesUploader } from "../../../shared-components/images/GalleryImagesUploader";
import { CoverImageUploader } from "../../../shared-components/images/CoverImageUploader";
import useToaster from "../../../shared-components/toasts/Toaster";
import { StatusTypeSelect } from "../../../shared-components/toasts/forms/forms-interfaces";
import { formatDateToIsoString, formatIsoStringToDate, selectPageDesignTypes, selectPublishStatusType, slugify } from "../../../utils/forms/util-forms";
import useDataFilesManagerList from "../../page-files-manager/data/data-for-folders-list";
import useDataPostCategoryList from "../../page-settings/page-main-sub-categories/data/data-for-post-categories-list";
import useDataPostSubCategoryList from "../../page-settings/page-main-sub-categories/data/data-for-sub-post-categories-list";
import { SchemaForMandatedCrops } from "../form-schemas/form-schema-for-mandated-crops";
import { useServiceForMandatedCropsAPI } from "../services/services-for-mandated-crops";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import useDataMandatedCropsForm from "../data/data-for-mandated-crops-form";
import ErrorDialog from "../../../shared-components/form/ErrorDialog";
import LoadingDialog from "../../../shared-components/form/LoadingDialog";
import isDevMode from "../../../utils/check-dev-mode";
import { v4 as uuid } from 'uuid';

function FormMandatedCrops() {

  const { paths: route } = useRoutePaths();
  const findOneFolderByPageType = useDataFilesManagerList((state) => state.findOneFolderByPageType);

  const formData = useDataMandatedCropsForm((state) => state.formData);

  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const [postId, setPostId] = useState('');

  const [errorMsg, setErrorMsg] = useState('Something went wrong kindly try again.');

  const formAction = useDataMandatedCropsForm(
    (state) => state.formActionType
  );

  const formActionSetState = useDataMandatedCropsForm(
    (state) => state.setFormActionType
  );


  const [pageDesignView, pageDesignViewSetState] = useState<string>(formData?.pageDesignType ?? EditorType.TEXT_EDITOR);

  const [validateForm, setValidateForm] = useState<boolean>(false);

  const [folder, setFolder] = useState<Folder | null | undefined>(null);

  const [formItemStatus, setFormItemStatus] = useState<StatusTypeSelect | null>(selectPublishStatusType[0]);

  const [_formItemPageDesignSelect, setFormItemPageDesignSelect] = useState<StatusTypeSelect | null>(selectPageDesignTypes[0]);

  const coverImageFile = useDataForCoverImageFile((state) => state.file);

  const coverImageS3Item = useDataForCoverImageFile((state) => state.s3Item);

  const coverImageSetS3Item = useDataForCoverImageFile((state) => state.setS3Item);

  const setTextEditorIsLoading = useDataForTextEditor((state) => state.setTextEditorIsLoading);

  const setTextEditorDataString = useDataForTextEditor((state) => state.setTextEditorDataString);


  const setPageBuilderIsLoading = useDataForTextEditor((state) => state.setPageBuilderIsLoading);

  const setPageBuilderDataString = useDataForTextEditor((state) => state.setPageBuilderDataString);

  const setPageBuilderDataEncoded = useDataForTextEditor((state) => state.setPageBuilderDataEncoded);


  const listItemsFolders = useDataFilesManagerList((state) => state.listItemsFolders);

  const textEditorIsLoading = useDataForTextEditor((state) => state.textEditorIsLoading);

  const textEditorDataString = useDataForTextEditor((state) => state.textEditorDataString);

  const pageBuilderDataString = useDataForTextEditor((state) => state.pageBuilderDataString);


  const postCategories = useDataPostCategoryList((state) => state.listItems);

  const { addNewData, updateData } = useServiceForMandatedCropsAPI();

  const galleryImagesS3Items = useDataForGalleryImagesFile((state) => state.s3Items) ?? [];

  const galleryImagesS3ItemsSet = useDataForGalleryImagesFile((state) => state.setS3Items) ?? [];

  const galleryImagesFiles = useDataForGalleryImagesFile((state) => state.files);


  const postSubCategories = useDataPostSubCategoryList((state) => state.listItems);


  const [selectedPostCat, setSelectedPostCat] = useState<PostCategory | null>(null);

  const [selectedPostSubCat, setSelectedPostSubCat] = useState<PostSubCategory | null>(null);

  const [filteredPostSubCats, setFilteredPostSubCats] = useState<PostSubCategory[]>([]);

  const { showError, showSuccess } = useToaster();

  const formFormik = useFormik({
    initialValues: formData as Page,
    validateOnBlur: false,
    validateOnChange: validateForm,

    validate: (values) => {
      setValidateForm(true);
      console.log("validate", values);
      console.log("errors", formFormik.errors);
    },
    onSubmit: async (values) => {
      await saveForm(values);
    },
    validationSchema: SchemaForMandatedCrops,
  });

  const toast = useRef(null);
  const refTitle = useRef(null);
  const refSlug = useRef(null);
  const refCoverImage = useRef(null);
  const refGalleryImages = useRef(null);
  const refPageDesignType = useRef(null);
  const refDetails = useRef(null);
  const refTags = useRef(null);
  const refDateUploaded = useRef(null);
  const refDatePublished = useRef(null);
  const refMainCategory = useRef(null);
  const refSubCategory = useRef(null);
  const refStatus = useRef(null);

  useEffect(() => {
    if (postId.length == 0) {
      setPostId(uuid());
    } else if ((formData?.id ?? '').length > 0) {
      setPostId(formData!.id);
    }

    return () => {
      if (postId.length != 0) return;
    }
  }, [postId, formData])

  const fieldLabels = {
    coverImage: {
      id: "coverImage",
      ref: refCoverImage,
      label: "Cover Image",
    },
    galleryImages: {
      id: "galleryImages",
      ref: refGalleryImages,
      label: "Gallery Images",
    },
    title: {
      id: "title",
      ref: refTitle,
      label: "Title",
    },
    slug: {
      id: "slug",
      ref: refSlug,
      label: "Slug",
    },
    details: {
      id: "details",
      ref: refDetails,
      label: "Details (optional)",
    },
    pageDesignType: {
      id: "pageDesignType",
      ref: refPageDesignType,
      label: "Page Design Type",
    },
    moreDetails: {
      id: "details",
      ref: refDetails,
      label: "Details (Optional)",
    },
    mainCategory: {
      id: "mainCategory",
      ref: refMainCategory,
      label: "Main Category",
    },
    subCategory: {
      id: "subCategory",
      ref: refSubCategory,
      label: "Sub Category",
    },
    tags: {
      id: "tags",
      ref: refTags,
      label: "Tags (optional)",
    },

    dateUploaded: {
      id: "dateAdded",
      ref: refDateUploaded,
      label: "Date Uploaded",
    },
    datePublished: {
      id: "datePublished",
      ref: refDatePublished,
      label: "Date Published",
    },
    status: {
      id: "status",
      ref: refStatus,
      label: "Status",
    },
  };

  const getStatus = (value: string) => {
    function findIndexFxn(item: StatusTypeSelect) {
      return item.value === value;
    }
    const index = selectPublishStatusType.findIndex(findIndexFxn);

    const item = selectPublishStatusType[index];

    return item;
  }

  const getPageDesignType = (value: string) => {
    function findIndexFxn(item: StatusTypeSelect) {
      return item.value === value;
    }
    const index = selectPageDesignTypes.findIndex(findIndexFxn);

    const item = selectPageDesignTypes[index];

    return item;
  }

  const changePageDesignView = useCallback(async () => {
    switch (pageDesignView) {
      case EditorType.TEXT_EDITOR:
        pageDesignViewSetState((_) => EditorType.PAGE_BUILDER);

        break;

      case EditorType.PAGE_BUILDER:
        pageDesignViewSetState((_) => EditorType.TEXT_EDITOR);
        break;

      default:
        pageDesignViewSetState((_) => EditorType.TEXT_EDITOR);
        break;
    }
  }, [pageDesignView]);

  async function saveForm(values: Page) {
    let newItem: Page;

    switch (formAction ?? FormActionType.NEW) {
      case FormActionType.NEW:
        newItem = new Page({
          title: values.title,
          pageType: values.pageType!,
          status: values.status!,
          coverImage: coverImageS3Item,
          galleryImages: galleryImagesS3Items,
          dateAdded: formatDateToIsoString(values.dateAdded!)!,
          datePublished: formatDateToIsoString(values.datePublished!)!,
          dateSent: new Date().toISOString(),
          dateUpdated: new Date().toISOString(),
          pageDesignPageBuilder: pageBuilderDataString,
          pageDesignTextEditor: textEditorDataString,
          pageDesignType: values.pageDesignType,
          details: values.details,
          postCategoryID: values.postCategoryID,
          postSubCategoryID: values.postSubCategoryID,
          slug: values.slug,
          tags: values.tags,
          deleted: false,
        });

        newItem = { ...newItem, id: postId }

        await addNewData(newItem).then((done) => {

          if (done) {
            showSuccess(toast, 'ATTENTION', 'Successfully saved.');
            formActionSetState(FormActionType.EDIT)
            formFormik.setSubmitting(false);
            console.log('done addNewData');
          } else {
            console.log('err submitting addNewData',);
            showError(toast, 'ATTENTION', 'Something went wrong, kindly try again.');
            setShowErrorDialog(true);
            formFormik.setSubmitting(false);
          }

        }).catch((err) => {
          console.log('err submitting addNewData', err);
          showError(toast, 'ATTENTION', 'Something went wrong, kindly try again.');
          setShowErrorDialog(true);
          formFormik.setSubmitting(false);
        })
        break;

      case FormActionType.EDIT:
        // if (isDevMode()) {
        //   console.log('isoDate', isoDate)
        // }
        newItem = {
          ...values,
          coverImage: coverImageS3Item,
          galleryImages: galleryImagesS3Items,
          id: postId,
          dateAdded: formatDateToIsoString(values.dateAdded!)!,
          datePublished: formatDateToIsoString(values.datePublished!)!,
          tags: values.tags,
          deleted: false,
        }

        await updateData(newItem, false).then(async (done) => {
          if (done) {
            showSuccess(toast, 'ATTENTION', 'Successfully saved.');
            formFormik.setSubmitting(false);
            console.log('done updateData');
          } else {
            console.log('err submitting updateData',);
            showError(toast, 'ATTENTION', 'Something went wrong, kindly try again.');
            setShowErrorDialog(true);
            formFormik.setSubmitting(false);
          }

        }).catch((error) => {
          console.log('err submitting updateData', error);
          showError(toast, 'ATTENTION', 'Something went wrong, kindly try again.');
          setShowErrorDialog(true);
          formFormik.setSubmitting(false);
        });
        break;

      default:
        break;
    }
  }

  const onChangeSlug = (e: any) => {

    const slugged = slugify(e.target.value);

    formFormik.setFieldValue('slug', slugged);
    return;
  }

  useEffect(() => {

    if (formFormik.values.title.length != 0) {
      const slugged = slugify(formFormik.values.title);
      formFormik.setFieldValue('slug', slugged);
    }

    return () => { }
  }, [formFormik.values.title])


  useEffect(() => {

    if (formFormik.values.tags == null) {
      formFormik.setFieldValue('tags', []);
    }

    return () => { }
  }, [formFormik.values.tags])

  useEffect(() => {
    if (pageDesignView === EditorType.PAGE_BUILDER) {
      const dataStringBuilder = formFormik.values.pageDesignPageBuilder ?? '';

      if (dataStringBuilder.length == 0) {
        setPageBuilderDataEncoded(JSON.parse('{}'));
        setPageBuilderDataString('');
        setPageBuilderIsLoading(false);
        return;
      };

      const dataJsonBuilder = JSON.parse(dataStringBuilder);
      setPageBuilderDataEncoded(dataJsonBuilder);
      setPageBuilderDataString(dataStringBuilder);
      setPageBuilderIsLoading(false);
    }

    if (pageDesignView === EditorType.TEXT_EDITOR) {
      const dataStringTextEditor = formFormik.values.pageDesignTextEditor ?? '';

      if (dataStringTextEditor.length == 0) {
        setTextEditorDataString('');
        setTextEditorIsLoading(false);
        return;
      };
      setTextEditorDataString(dataStringTextEditor);
      setTextEditorIsLoading(false);
    }

    return () => { }
  }, [pageDesignView])


  useEffect(() => {
    setFormItemStatus(getStatus(formFormik.values.status));
    return () => { }
  }, [formFormik.values.status])

  useEffect(() => {
    formFormik.setFieldValue('pageDesignTextEditor', textEditorDataString);
    return () => { }
  }, [textEditorDataString])

  useEffect(() => {
    formFormik.setFieldValue('pageDesignPageBuilder', pageBuilderDataString);
    return () => { }
  }, [pageBuilderDataString])

  useEffect(() => {
    setFormItemPageDesignSelect(getPageDesignType(formFormik.values.pageDesignType!));
    return () => { }
  }, [formFormik.values.pageDesignType])


  useEffect(() => {
    const findIndexFxnPostCat = (item: PostCategory) => {
      return item.id == formFormik.values.postCategoryID
    }

    const findIndexFxnPostSubCat = (item: PostSubCategory) => {
      return item.id == formFormik.values.postSubCategoryID
    }

    const indexPostCat = [...postCategories].findIndex(findIndexFxnPostCat);

    const indexPostSubCat = [...postSubCategories].findIndex(findIndexFxnPostSubCat);

    if (indexPostCat >= 0 && selectedPostCat == null) {
      const selectedPostC = postCategories[indexPostCat];
      setSelectedPostCat(selectedPostC)

      const filterFxn = (item: PostSubCategory) => {
        return item.postCategoryID == selectedPostC.id;
      }

      const selectedPostSubC = postSubCategories[indexPostSubCat];
      setSelectedPostSubCat(selectedPostSubC)

      const filteredSubPostCats = [...postSubCategories].filter(filterFxn)
      setFilteredPostSubCats(filteredSubPostCats)
    }


    return () => { }
  }, [formFormik.values.postCategoryID, postCategories, postSubCategories])

  useEffect(() => {

    if (formData?.coverImage != null && coverImageS3Item == null) {
      formFormik.setFieldValue('coverImage', coverImageS3Item);
      coverImageSetS3Item(formData.coverImage);
    }

    return () => { };
  }, [coverImageFile, coverImageS3Item, formData?.coverImage]);

  //GALLERY IMAGES SET
  useEffect(() => {

    if (formData?.galleryImages != null && galleryImagesS3Items.length == 0) {
      formFormik.setFieldValue('galleryImages', galleryImagesS3Items);
      galleryImagesS3ItemsSet(formData.galleryImages as S3UploadedItem[]);
    }

    return () => { };
  }, [galleryImagesFiles, galleryImagesS3Items, formData?.galleryImages]);

  useEffect(() => {
    if (folder != null) return;

    const fetchData = async () => {
      const folderFound = await findOneFolderByPageType(PageType.MANDATED_CROPS);
      setFolder(folderFound);
    }

    fetchData().catch(console.error);;

    return () => {
      if (folder != null) return;
    }
  }, [folder, listItemsFolders])

  const fieldSlug = (
    <div className="form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.slug.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.slug.id}
          onChange={onChangeSlug}
          onBlur={formFormik.handleBlur}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.slug ?? ''}
          ref={fieldLabels.slug.ref}
        />
      </span>
      <FormErrorMsg fieldId={fieldLabels.slug.id} errorMsg={formFormik.errors.title} />
    </div>
  );

  const fieldTitle = (
    <div className="form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.title.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.title.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          // maxLength={100}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.title}
          ref={fieldLabels.title.ref}
        />
      </span>
      <FormErrorMsg fieldId={fieldLabels.title.id} errorMsg={formFormik.errors.title} />
    </div>
  );

  const fieldDetails = (
    <div className="t-pl-[0rem] t-w-full ">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.details.label}
      </p>

      <div className={` w-full 
       p-input-filled t-border-[2px] t-shadow-none    
       t-pt-[0px] t-rounded-lg t-h-full form-text-editor t-overflow-hidden 
       ${textEditorIsLoading ? 't-hidden' : 't-flex'}
        t-border-gray-300 t-flex-col t-h-full`}>
        <TextEditorCK holder="editorjs-container" />
      </div>
      <div className={`w-full 
        t-border-[2px] 
       t-pt-[0px] form-field t-rounded-lg
       ${textEditorIsLoading ? 't-flex' : 't-hidden'}
        t-border-gray-300 t-flex-col t-h-full`}>

        <Skeleton width="100%" height="500px" />
      </div>
    </div>
  );

  const fieldTags = (
    <div className="t-w-full form-field">
      <p className="t-text-appgrey-800 t-mt-10 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.tags.label}
      </p>
      <span className="t-w-full  p-input-filled">
        <Chips
          id={fieldLabels.tags.id}
          value={formFormik.values.tags ?? []}
          ref={fieldLabels.tags.ref}
          className="t-w-full t-min-h-[100px] tags-input t-items-start"
          disabled={formFormik.isSubmitting}
          onBlur={formFormik.handleBlur}
          onChange={formFormik.handleChange}
        ></Chips>
      </span>
    </div>
  );

  const fieldDateUploaded = (
    <div className="t-w-1/2 t-mr-4 form-field date-pick">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.dateUploaded.label}
      </p>
      <Calendar
        id={fieldLabels.dateUploaded.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        dateFormat="dd/mm/yy"
        disabled={formFormik.isSubmitting}
        value={formatIsoStringToDate(formFormik.values.dateAdded!)}
        ref={fieldLabels.dateUploaded.ref}
        className="w-full p-input-filled"
        showIcon
        iconPos="right"
      />
      <FormErrorMsg fieldId={fieldLabels.dateUploaded.id} errorMsg={formFormik.errors.dateAdded} />
    </div>
  );

  const fieldDatePublished = (
    <div className="t-w-1/2 t-ml-4 form-field date-pick">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.datePublished.label}
      </p>
      <Calendar
        id={fieldLabels.datePublished.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        dateFormat="dd/mm/yy"
        disabled={formFormik.isSubmitting}
        value={formatIsoStringToDate(formFormik.values.datePublished!)}
        ref={fieldLabels.datePublished.ref}
        className="w-full p-input-filled"
        showIcon
        iconPos="right"
      />
      <FormErrorMsg fieldId={fieldLabels.datePublished.id} errorMsg={formFormik.errors.datePublished} />
    </div>
  );

  const fieldPageDesignType = (
    <div className="t-w-full form-field">
      <p className="t-text-appgrey-800 t-mt-8 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.pageDesignType.label}{" "}
      </p>
      <Dropdown
        className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
        options={selectPageDesignTypes}
        optionLabel="name"
        placeholder="Select"
        id={fieldLabels.pageDesignType.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        disabled={formFormik.isSubmitting}
        value={formFormik.values.pageDesignType}
        ref={fieldLabels.pageDesignType.ref}
      />
      <FormErrorMsg fieldId={fieldLabels.pageDesignType.id} errorMsg={formFormik.errors.pageDesignType} />
    </div>
  );

  const fieldStatus = (
    <div className="t-w-full form-field">
      <p className="t-text-appgrey-800 t-mt-8 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.status.label}
      </p>
      <Dropdown
        className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
        options={selectPublishStatusType}
        optionLabel="name"
        placeholder="Select"
        id={fieldLabels.status.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        disabled={formFormik.isSubmitting}
        value={formItemStatus?.value}
        ref={fieldLabels.status.ref}
      />
      <FormErrorMsg fieldId={fieldLabels.status.id} errorMsg={formFormik.errors.status} />
    </div>
  );

  const fieldGalleryImages = (
    <div className="t-mt-6 t-mb-10">
      <GalleryImagesUploader postId={postId} folder={folder} title="Gallery Images" id={fieldLabels.galleryImages.id} />
    </div>
  );

  const fieldMainCategory = (
    <div className="t-w-1/2 t-mr-4 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.mainCategory.label}
      </p>
      <Dropdown
        className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
        options={postCategories}
        optionLabel="name"
        filter
        placeholder="Select"
        id={fieldLabels.mainCategory.id}
        onChange={(e) => {
          const selectedPostC: PostCategory = e.value;
          setSelectedPostCat(selectedPostC)
          const filterFxn = (item: PostSubCategory) => {
            return item.postCategoryID == selectedPostC.id;
          }
          const filteredSubPostCats = [...postSubCategories].filter(filterFxn)
          setFilteredPostSubCats(filteredSubPostCats)
          formFormik.setFieldValue('postCategoryID', selectedPostC.id)
          formFormik.setFieldValue('postSubCategoryID', '')
        }}
        disabled={formFormik.isSubmitting}
        value={selectedPostCat}
        ref={fieldLabels.mainCategory.ref}
      />
    </div>
  );

  const fieldSubCategory = (
    <div className="t-w-1/2 t-mr-4 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.subCategory.label}
      </p>
      <Dropdown
        className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
        options={filteredPostSubCats}
        optionLabel="name"
        filter
        placeholder="Select"
        id={fieldLabels.subCategory.id}
        onChange={(e) => {
          const selectedPostSubC: PostSubCategory = e.value;
          setSelectedPostSubCat(selectedPostSubC)
          formFormik.setFieldValue('postSubCategoryID', selectedPostSubC.id)
        }}

        disabled={formFormik.isSubmitting}
        value={selectedPostSubCat}
        ref={fieldLabels.subCategory.ref}
      />
    </div>
  );

  const fieldCoverImage = (
    <div>
      <CoverImageUploader
        title="Cover Image"
        folder={folder}
        id={fieldLabels.coverImage.id}
        postId={postId}

      />
      {/* <FormErrorMsg fieldId={fieldLabels.coverImage.id} errorMsg={(validateForm && coverImageFile == null) ? 'Upload a cover image' : ''} /> */}
    </div>
  );

  const textEditorForm = (
    <div
      className={` 
    ${pageDesignView == EditorType.TEXT_EDITOR ? "t-flex" : "t-hidden"} 
    t-transition t-duration-150 t-ease-out t-h-full 
     t-overflow-auto md:t-max-w-[70rem] 
    t-bg-white t-mx-auto t-flex-col 
    t-rounded-lg t-p-2  t-my-0 md:t-my-5 md:t-p-[2.25rem] md:t-max-h-[86vh]  t-pb-[100px]`}
    >
      {fieldCoverImage}

      <div className=" t-mt-12">
        {/*Basic Details From */}
        <SectionHeader title="Basic Details" />
        <div className="t-mb-14">
          {fieldTitle}
          {fieldSlug}
          <div className="t-pt-10">
            <SectionHeader title="Text Editor" />
          </div>
          {fieldDetails}
        </div>
        <div>
          {/* Categories and  Tags*/}
          <SectionHeader title="Category / Tags" />
          <div className="t-mb-10">
            <div className="flex t-w-full">
              {fieldMainCategory}
              {fieldSubCategory}
            </div>
            <div className="flex t-w-full">{fieldTags}</div>
          </div>
          <div>{fieldGalleryImages}</div>
        </div>
        <div>
          {/* Dates and  Status*/}
          <SectionHeader title="Dates / Design / Status" />
          <div className="t-mb-10">
            <div className="flex t-w-full">
              {fieldDateUploaded}
              {fieldDatePublished}
            </div>
            <div className="t-flex t-justify-between t-items-stretch">
              <div className="flex t-w-full t-mr-4">{fieldPageDesignType}</div>
              <div className="flex t-w-full t-ml-4">{fieldStatus}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="t-mb-[150px]"></div>
    </div>
  );

  return (
    <div className="t-w-full  t-m-auto t-bg-appmainview">

      <Toast ref={toast} position="top-right"></Toast>
      <FormikProvider value={formFormik}>
        <form
          className="main-form t-h-screen"
          onSubmit={formFormik.handleSubmit}
        >
          <CustomNavigation
            title={`${formAction == FormActionType.NEW ? "Add New Mandated Crop" : formAction == FormActionType.EDIT ? "Update Mandated Crops" : "New Mandated Crops"}`}
            background={true}
            useWhiteBackButton={true}
            backArrow={true}
            titleFont={"t-font-inter"}
            titleSize={"t-text-24px"}
            titleFontWeight={"t-font-medium"}
            yPadding={"t-py-1"}
            buttons={
              <FormActionButtonSet
                onPressedPreview={() => { }}
                onPressedSave={() => { }}
                onPressedChangeEditorType={changePageDesignView}
                editorType={pageDesignView}
                hasPageBuilders={true}
              />
            }
            backArrowNavUrl={route.mandatedCrops.list}
          />
          {textEditorForm}
          {
            <div
              className={`t-h-screen
         ${pageDesignView == EditorType.PAGE_BUILDER ? "t-block" : "t-hidden"
                } 
         t-transition t-duration-150 t-ease-out t-sticky`}
            >
              <PageBuilder isSelected={pageDesignView == EditorType.PAGE_BUILDER} />

            </div>
          }
        </form>
      </FormikProvider>
      <ErrorDialog show={showErrorDialog} errorMsg={errorMsg} onHide={() => { setShowErrorDialog(false) }} />
      <LoadingDialog show={formFormik.isSubmitting} />
      <Toast ref={toast} position="top-right"></Toast>
    </div>
  );
}

export default FormMandatedCrops;
