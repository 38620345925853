import { DbOpAccessType, EditorType } from "../../../models";

export interface VisibilityTypeSelect {
  name: string;
  value: string;
}


export interface GalleryFile {
  file: File;
  encodedBlurhash?: string | null;
}

export interface PageDesignTypeSelect {
  name: string;
  value: EditorType;
}

export interface DbOpAccessTypeSelect{
  name: string;
  value: DbOpAccessType;
}

export interface onUploadInterface {
  file: File;
  encodedBlurhash: string | null;
  staffProfileId: string
}

export interface PropsEncode {
  file: File;
}

export interface StatusTypeSelect extends VisibilityTypeSelect { }

export enum AppTableNames {
  APP_NOTIFICATION = "AppNotification",
  ARTICLE_PUBLICATION = "ArticlePublication",
  POST_CATEGORY = "PostCategory",
  USER_ACCOUNT = "UserAccount",
  USER_ROLE = "UserRole",
  STAFF_PROFILE = "StaffProfile",
  CRIG_DIVISION_SUB_STATION = "CrigDivisionSubStation",
  CRIG_STAFF_GROUP = "CrigStaffGroup",
  CRIG_UNIT_DEPARTMENT = "CrigUnitDepartment",
  FILE_MANAGER = "FileManager",
  POST_SUB_CATEGORY = "PostSubCategory",
  FOLDER = "Folder",
  FILE = "File",
  FAQ = "Faq",
  FAQ_ITEM = "FaqItem",
  PAGE = "Page",
  NEWS = "News",
  NEWSLETTER = "Newsletter",
  PAGE_DESIGN_TEMPLATE = "PageDesignTemplate",
  POST_USER_ACCOUNT_VIEW_DOWNLOAD = "PostUserAccountViewDownload",
  HELP_CENTER_PAGE = "HelpCenterPage",
  SETTINGS = "Settings",
  CONTENT_FOLLOWER = "ContentFollower",
  NONE = "none",
  UNDEFINED = "undefined"
}
