import { useNavigate } from "react-router-dom";
import useDataForTextEditor from "../../../editors/data-for-editor";
import { FormActionType } from "../../../models";
import useRoutePaths from "../../../routes/route-path";
import TableAddNewButton from "../../../shared-components/button/TableAddNewButton";
import FileManagerFile from "../../../shared-components/file/file-manager/FileManagerFile";
import FileManagerListItem from "../../../shared-components/file/file-manager/FileManagerListItem";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataForGalleryImagesFile from "../../../shared-components/images/data-gallery-images";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import useDataFileManger from "../data/data-for-folder-file-form";

function ListFileManagerFiles() {
  const { paths: route } = useRoutePaths();
  const navigation = useNavigate();
  const setFormActionType = useDataFileManger(
    (state) => state.setFormActionType
  );
  const galleryImageReset = useDataForGalleryImagesFile((state) => state.reset);
  const coverImageReset = useDataForCoverImageFile((state) => state.reset);
  const editorReset = useDataForTextEditor((state) => state.reset);
  // const resetForm = useDataFileManger((state) => state.reset);
  return (
    <div className="flex t-flex-col t-overflow-hidden t-justify-start t-h-fit t-rounded-lg t-shadow-lg t-m-4">
      <div className="">
        <CustomNavigation
          background={false}
          subTitle="Articles/Publications/December 2020"
          title="File Manager"
        />
      </div>
      <div className="t-flex t-flex-wrap folder-manager t-py-6">
        <FileManagerFile name="Sample Title 1" size={12} fileExtension="ppt" />
        <FileManagerFile name="Sample Title 2" size={3} fileExtension="pdf" />
        <FileManagerFile name="Sample Title 3" size={10} fileExtension="doc" />
        <FileManagerFile name="Sample Title 4" size={1} fileExtension="mp4" />
      </div>
      {/* <TableFileManager /> */}
    </div>
  );
}

export default ListFileManagerFiles;
