import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataLoadingState, FormActionType, PostCategory } from "../../../../models";
import useRoutePaths from "../../../../routes/route-path";
import TableActionButtonSet from "../../../../shared-components/button/TableActionButtonSet";
import useDataPostCategoryForm from "../data/data-for-post-categories-form";
import useDataMainSubCategoryList from "../data/data-for-post-categories-list";
import { format } from "date-fns";
import { ListLoader } from "../../../../shared-components/list-tables/list-loader";
import useDataPostCategoryList from "../data/data-for-post-categories-list";

const TableMainSubCategories = () => {
    const setFormActionType = useDataPostCategoryForm((state) => state.setFormActionType);
    const setFormData = useDataPostCategoryForm((state) => state.setFormData);
    const { paths: route } = useRoutePaths();
    const listItems = useDataMainSubCategoryList((state) => state.listItems);
    const loadingStatus = useDataPostCategoryList((state) => state.statusForLoadingListItems);

    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // "country.name": {
        //   operator: FilterOperator.AND,
        //   constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        // },
        // representative: { value: null, matchMode: FilterMatchMode.IN },
        dateUpdated: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },

        // status: {
        //   operator: FilterOperator.OR,
        //   constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        // },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
    });

    const columnsAll: any = [
        { field: 'id', header: 'ID' },
        { field: 'name', header: 'Name' },
        { field: 'shortDescription', header: 'Description' },
        { field: 'totalSubCategoriesCount', align: 'center', header: 'Total Sub-Categories' },
        { field: 'totalPostsCount', align: 'center', header: 'Total Posts' },
        { field: 'dateUploaded', header: 'Date Updated' },
    ];


    const columnsSelected: any = [
        { field: 'name', header: 'Name' },
        // { field: 'shortDescription', header: 'Description' },
        { field: 'totalSubCategoriesCount', align: 'center', header: 'Total Sub-Categories' },
        { field: 'totalPostsCount', align: 'center', header: 'Total Posts' },
        // { field: 'datePublished', header: 'Date Updated' },
    ];

    const [selectedColumns, setSelectedColumns] = useState(columnsSelected);

    const navigation = useNavigate();

    const onColumnToggle = (event: any) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columnsAll.filter((col: { field: any; }) => selectedColumns.some((sCol: { field: any; }) => sCol.field === col.field));
        setSelectedColumns(orderedSelectedColumns);
    }

    const onSearchKeywordChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const header = (
        <div
            className="t-grid grid-cols-1 t-w-full  md:t-grid-cols-3
       justify-content-start align-items-center "
        >
            <div className="list-column-select" style={{ textAlign: 'left' }}>
                <MultiSelect fixedPlaceholder={true} placeholder="Columns" value={selectedColumns} options={columnsAll} optionLabel="header" onChange={onColumnToggle} style={{ width: '14.5em', borderRadius: '10px' }} />
            </div>
            <div className="list-search-box t-w-full t-pt-4 md:t-pt-0">
                <span className="p-input-icon-left p-input-border-none  t-font-inter p-input-filled  t-w-full">
                    <i className="pi pi-search" />
                    <InputText
                        value={globalFilterValue}
                        onChange={onSearchKeywordChange}
                        placeholder="Keyword Search"
                    />
                </span>
            </div>
        </div>
    );

    const columnComponents = selectedColumns.map(col => {
        return <Column key={col.field} align={col.align} field={col.field} header={col.header} />;
    });

    const editOnClick = (rowData: any) => {
        setFormActionType(FormActionType.EDIT);
        setFormData(rowData as PostCategory);
        navigation(route.settings.mainSubCategories.details);

    }

    const actionsBodyTemplate = (rowData) => {
        return <TableActionButtonSet editOnClick={() => { editOnClick(rowData) }} viewOnClick={() => { }} deleteOnClick={() => { }} />;
    };

    const formatDate = (value: Date) => {
        return format(value, `do LLL yyyy, h:mm aaa`);
    };


    const dateBodyTemplate = (rowData: any) => {
        const value: any = rowData['dateUploaded'];
        const valueAsDate = new Date(value);
        const formatted = formatDate(valueAsDate);
        return <p>{formatted}</p>;
    };



    return (
        <div className=" t-bg-white">
            {/* loader */}
            {(loadingStatus == DataLoadingState.LOADING || loadingStatus == DataLoadingState.PENDING) && <ListLoader skelsNumber={7} />
            }

            {/* table list */}
            {(loadingStatus == DataLoadingState.LOADED) &&
                <DataTable scrollable scrollHeight="70vh" value={listItems} stripedRows filters={filters} header={header} responsiveLayout="scroll">
                    {columnComponents}
                    <Column
                        field="dateUploaded"
                        align={'left'}
                        header="Date Updated"
                        showFilterMatchModes={false}
                        style={{ minWidth: "9rem" }}
                        body={dateBodyTemplate}
                    />
                    <Column
                        field="activity"
                        header="Actions"
                        showFilterMatchModes={false}
                        style={{ minWidth: "11rem" }}
                        body={actionsBodyTemplate}
                    />
                </DataTable>
            }
        </div>
    );
}

export default TableMainSubCategories;