import * as Yup from "yup";

export const SchemaForCMSUsers = Yup.object().shape({
  nameFirst: Yup.string()
    .min(1, "Too Short!")
    .max(100, "Too Long!")
    .label("First Name")
    .required("Required"),
  nameLast: Yup.string()
    .min(1, "Too Short!")
    .max(100, "Too Long!")
    .label("Last Name")
    .required("Required"),
  nameFull: Yup.string(),
  category: Yup.string(),
  password: Yup.string(),
  contactPhoneNumber1: Yup.string()
    .min(1, "Too Short!")
    .max(100, "Too Long!")
    .label("Phone Number")
    .required("Required"),
  contactEmail1: Yup.string()
    .min(1, "Too Short!")
    .max(100, "Too Long!")
    .label("Email")
    .required("Required"),
  userRoleID: Yup.string()
    .min(1, "Too Short!")
    .max(100, "Too Long!")
    .label("user Role")
    .required("Required"),

  status: Yup.string().required("Required"),
});
