import produce from "immer";
import { create } from "zustand";
import { DataLoadingState, Page } from "../../../models";
import { PageDataInterfaceResearchActivitiesList } from "./data-interface-for-research-activities";


const useDataResearchActivitiesList =
  create<PageDataInterfaceResearchActivitiesList>()((set, get) => {
    return {
      statusForLoadingListItems: DataLoadingState.PENDING as
        | DataLoadingState
        | undefined,
      listItems: [],

      setStatusForLoadingListItems: async (newValue) => {
        set((_) => ({ statusForLoadingListItems: newValue }));
        return true;
      },
      setListItems: async (items) => {
        set((_) => ({ listItems: items }));
        return true;
      },
      updateItem: async (newItem) => {
        const newItemRecord = newItem as Page;
        set(
          produce((state: PageDataInterfaceResearchActivitiesList) => {
            function findIndexFxn(item: Page) {
              return item.id === newItemRecord.id;
            }
            // console.log('index', state.listItems[0])
            const index = state.listItems.findIndex(findIndexFxn);
            state.listItems[index] = newItemRecord;
          })
        );

        return true;
      },

      deleteItem: async (newItem) => {
        set(
          produce((state: PageDataInterfaceResearchActivitiesList) => {
            function findIndexFxn(item: Page) {
              return item.id === newItem.id;
            }

            const index = get().listItems.findIndex(findIndexFxn);
            state.listItems.splice(index, 1);
          })
        );

        return true;
      },

      addItem: async (newItem) => {
        let newItemRecord = newItem as Page;
        set(
          produce((state: PageDataInterfaceResearchActivitiesList) => {
            state.listItems.unshift(newItemRecord);
          })
        );

        return true;
      },
    };
  });

export default useDataResearchActivitiesList;
