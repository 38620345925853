import { create } from "zustand";

interface DataForDStoreEventInterface {
    hasNetwork: boolean | null;
    ready: boolean | null;
    subscriptionsEstablished: boolean | null;
    syncQueriesOngoing: boolean | null;
    syncQueriesReady: boolean | null;
    setHasNetwork: (newVal: boolean) => Promise<boolean>;
    setReady: (newVal: boolean) => Promise<boolean>;
    setSyncQueriesOngoing: (newVal: boolean) => Promise<boolean>;
    setSyncQueriesReady: (newVal: boolean) => Promise<boolean>;
    setSubscriptionsEstablished: (newVal: boolean) => Promise<boolean>;
}

const useDataForDStoreEvents =
    create<DataForDStoreEventInterface>()((set, get) => {
        return {
            hasNetwork: null,
            ready: null,
            subscriptionsEstablished: null,
            syncQueriesOngoing: null,
            syncQueriesReady: null,
            setHasNetwork: async (newVal) => {
                set((_) => ({ hasNetwork: newVal }));
                return true;
            },
            setReady: async (newVal) => {
                set((_) => ({ ready: newVal }));
                return true;
            },
            setSubscriptionsEstablished: async (newVal) => {
                set((_) => ({ ready: newVal }));
                return true;
            },
            setSyncQueriesOngoing: async (newVal) => {
                set((_) => ({ ready: newVal }));
                return true;
            },
            setSyncQueriesReady: async (newVal) => {
                set((_) => ({ ready: newVal }));
                return true;
            },

        }
    });

export default useDataForDStoreEvents;