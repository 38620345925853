import { useEffect } from "react";
import { DataLoadingState, PageType, StaffProfile } from "../../../models";
import { GraphQLResult } from "@aws-amplify/api-graphql";

import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataForUserAuth from "../../../store/auth/data-for-auth";
import useDataStaffProfileForm from "../data/data-for-staff-profiles-form";
import useDataStaffProfileList from "../data/data-for-staff-profiles-list";
import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../../graphql/subscriptions";
import {
  createStaffProfileGQL,
  deleteStaffProfileGQL,
  getStaffProfileGQL,
  listStaffProfilesGQL,
  updateStaffProfileGQL,
} from "../../../custom-graphql/custom-gql-staff-profile";
import isDevMode from "../../../utils/check-dev-mode";

interface ServiceInterfaceForStaffProfile {
  addNewData: (item: StaffProfile) => Promise<boolean>;
  updateData: (
    item: StaffProfile,
    isDelete: boolean
  ) => Promise<boolean | StaffProfile>;
  updateDataField: (id: string, key: string, value: any) => Promise<boolean>;
  deleteData: (item: StaffProfile) => Promise<boolean>;
  findOneData: (id: string) => Promise<StaffProfile | null>;
  listAllData: () => Promise<boolean | null>;
}

const pageModelName = "staff profiles";

export function useServiceForStaffProfileAPI(): ServiceInterfaceForStaffProfile {
  const pageDataAdd = useDataStaffProfileList((state) => state.addItem);
  const loggedUser = useDataForUserAuth((state) => state.loggedUser);
  const formData = useDataStaffProfileForm((state) => state.formData);
  const setFormData = useDataStaffProfileForm((state) => state.setFormData);
  const pageDataSetStatusForLoading = useDataStaffProfileList(
    (state) => state.setStatusForLoadingListItems
  );
  const coverImageGetS3URL = useDataForCoverImageFile(
    (state) => state.getS3URL
  );

  const staffProfileDataSetList = useDataStaffProfileList(
    (state) => state.setListItems
  );
  const staffProfileSetStatusForLoadingListItems = useDataStaffProfileList(
    (state) => state.setStatusForLoadingListItems
  );

  const pageDataUpdate = useDataStaffProfileList((state) => state.updateItem);
  const pageDataDelete = useDataStaffProfileList((state) => state.deleteItem);

  const addNewData = async (item: StaffProfile) => {
    let newItem: StaffProfile;
    let dataResult: StaffProfile;



    newItem = {
      ...item,
      dateUpdated: new Date().toISOString(),
      creatorUserAccountID: loggedUser!.userAccount!.id,
    };

    delete (newItem as any).createdAt;
    delete (newItem as any).updatedAt;
    delete (newItem as any).creatorUserAccount;
    delete (newItem as any)._lastChangedAt;
    delete (newItem as any)._deleted;
    delete (newItem as any).crigDivisionSubStation;
    delete (newItem as any).crigStaffGroup;
    delete (newItem as any).crigUnitDepartment;
    delete (newItem as any).articlesPublicationsAuthored;


    if (isDevMode()) {
      console.log(`addNew ${pageModelName} newItem`, newItem);
    }

    try {
      const results: any = await API.graphql(
        graphqlOperation(createStaffProfileGQL, { input: newItem })
      );
      console.log(`addNew ${pageModelName} results`, results);
      dataResult = results.data.createStaffProfile;
      return true;
    } catch (error) {
      console.log(`addNew ${pageModelName} onError`, error);
      return false;
    }
  };

  const updateData = async (item: StaffProfile, isDelete: boolean) => {
    let newItem: StaffProfile;
    let dataResult: StaffProfile;
    newItem = {
      ...item,
      dateUpdated: new Date().toISOString(),
    };

    if (isDelete) {
      newItem = {
        ...item,
        deleted: true,
      };
    }

    delete (newItem as any).createdAt;
    delete (newItem as any).updatedAt;
    delete (newItem as any).creatorUserAccount;
    delete (newItem as any)._lastChangedAt;
    delete (newItem as any)._deleted;
    delete (newItem as any).crigDivisionSubStation;
    delete (newItem as any).crigStaffGroup;
    delete (newItem as any).crigUnitDepartment;
    delete (newItem as any).articlesPublicationsAuthored;

    if (isDevMode()) {
      console.log('update item to save', newItem)
    }

    try {
      const results: any = await API.graphql(
        graphqlOperation(updateStaffProfileGQL, { input: newItem })
      );
      if (isDevMode()) {
        console.log(`update ${pageModelName} results`, results);
      }


      dataResult = results.data.updateStaffProfile;
      if (isDelete) {
        return dataResult;
      } else {
        return true;
      }
    } catch (error) {
      if (isDevMode()) {
        console.log(`update ${pageModelName} onError`, error);
      }

      return false;
    }
  };

  const listAllData = async () => {
    let foundItems: StaffProfile[] | null | undefined;
    let promise: Promise<GraphQLResult<any>>;

    promise = API.graphql({
      query: listStaffProfilesGQL,
    });

    await promise
      .then(async (results) => {
        foundItems = results.data.listStaffProfiles.items as StaffProfile[];

        if (foundItems.length > 0) {
          for (var item of foundItems) {
            let index = -1;
            const profileImage = item.profileImage;
            let profileImageUrl: string | null = '';

            function findItemIndex(listItem: StaffProfile) {
              return listItem.id == item.id;
            }


            index = foundItems.findIndex(findItemIndex)

            if (profileImage != null) {
              profileImageUrl = await coverImageGetS3URL(profileImage.key);
              if (profileImageUrl != null) {
                item = { ...item, profileImage: { ...item.profileImage!, signedURL: profileImageUrl } }
                foundItems[index] = item;
              }
            }
          }
        }

        const sortFxn = (a: StaffProfile, b: StaffProfile) => {
          return (
            new Date(a.dateAdded!).getTime() - new Date(a.dateAdded!).getTime()
          );
        };

        if (isDevMode()) {
          console.log("listAllCrigStaff", foundItems);
        }

        staffProfileDataSetList(foundItems.sort(sortFxn));
        staffProfileSetStatusForLoadingListItems(DataLoadingState.LOADED);
        return foundItems;
      })
      .catch((e) => {
        if (isDevMode()) {
          console.log("error listAllCrigStaff", e);
        }

        staffProfileSetStatusForLoadingListItems(DataLoadingState.ERROR);
        return null;
      });
    return null;
  };

  const findOneData = async (id: string) => {
    let foundItem: StaffProfile | null;
    try {
      const results: any = await API.graphql(
        graphqlOperation(getStaffProfileGQL, { id: id })
      );

      if (isDevMode()) {
        console.log(`findOneData ${pageModelName} results`, results);
      }

      foundItem = results.data.getStaffProfile;

      return foundItem;
    } catch (error) {
      if (isDevMode()) {
        console.log(`findOneData ${pageModelName} onError`, error);
      }

      return null;
    }
  };

  const deleteData = async (item: any) => {
    let dataResult: StaffProfile;
    console.log(item);
    try {
      item = { ...item, deleted: true };

      const results: any = await API.graphql(
        graphqlOperation(deleteStaffProfileGQL, {
          input: { id: item.id, _version: item._version },
        })
      );

      if (isDevMode()) {
        console.log(`delete ${pageModelName} results`, results);
      }

      dataResult = results.data.deleteStaffProfile;

      await updateData(dataResult, true);

      return true;
    } catch (error) {
      if (isDevMode()) {
        console.log(`update ${pageModelName} onError`, error);
      }

      return false;
    }
  };

  //FIGURE OUT API
  const updateDataField = async (id: string, key: string, value: any) => {
    return false;
  };

  useEffect(() => {
    const createSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onCreateStaffProfile, {
          filter: {
            deleted: { eq: false },
          },
        })

      ) as unknown as any
    ).subscribe({
      next: async ({ provider, value }) => {


        let data = value.data.onCreateStaffProfile as StaffProfile;

        let url: string | null = "";
        let newData: StaffProfile | null = null;
        await findOneData(data.id).then(async (foundData) => {
          newData = foundData;
          if (isDevMode()) {
            console.log("newData", newData);
          }

          if (newData != null) {
            data = newData;
          }
          // if (data.coverImage != null) {
          //   await coverImageGetS3URL(data.coverImage.key).then(
          //     async (newURL) => {
          //       url = newURL;
          //       if (url != null) {
          //         data = {
          //           ...data,
          //           coverImage: { ...data.coverImage!, signedURL: url },
          //         };
          //         if (newData != null) {
          //           data = {
          //             ...(newData as ArticlePublication),
          //             coverImage: { ...data.coverImage!, signedURL: url },
          //           };
          //         }
          //       }
          //     }
          //   );
          // }
        });

        if (newData) {

          pageDataAdd(newData);

          if (formData?.id == data.id) {
            setFormData(data);
          }

          return;
        }

        pageDataAdd(data);

        if (formData?.id == data.id) {
          setFormData(data);
        }

      },
      error: (error: any) => {
        if (isDevMode()) {
          console.warn(error);
        }
      },
    });

    const updateSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onUpdateStaffProfile, {
          filter: {
            deleted: { eq: false },
          },
        })
      ) as unknown as any
    ).subscribe({
      next: async ({ provider, value }) => {
        console.log({ provider, value });
        let data = value.data.onUpdateStaffProfile as StaffProfile;

        let newData: StaffProfile | null = null;

        await findOneData(data.id).then(async (foundData) => {
          newData = foundData;
          if (isDevMode()) {
            console.log("newData", newData);
          }

          if (newData != null) {
            data = newData;
          }
          // if (data.coverImage != null) {
          //   await coverImageGetS3URL(data.coverImage.key).then(
          //     async (newURL) => {
          //       url = newURL;
          //       if (url != null) {
          //         data = {
          //           ...data,
          //           coverImage: { ...data.coverImage!, signedURL: url },
          //         };
          //         if (newData != null) {
          //           data = {
          //             ...(newData as ArticlePublication),
          //             coverImage: { ...data.coverImage!, signedURL: url },
          //           };
          //         }
          //       }
          //     }
          //   );
          // }
        });

        if (newData) {

          pageDataUpdate(newData);

          if (formData?.id == data.id) {
            setFormData(data);
          }

          return;
        }

        pageDataUpdate(data);

        if (formData?.id == data.id) {
          setFormData(data);
        }

      },
      error: (error: any) => {
        console.warn(error);
      },
    });

    const deleteSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onDeleteStaffProfile, {
          filter: {
            deleted: { eq: false },
          },
        })
      ) as unknown as any
    ).subscribe({
      next: ({ provider, value }) => {
        console.log({ provider, value });
        pageDataDelete(value.data.onDeleteStaffProfile as StaffProfile);
      },
      error: (error: any) => {
        console.warn(error);
      },
    });

    return () => {
      const cleanupSubscriptions = () => {
        createSubscription.unsubscribe();
        updateSubscription.unsubscribe();
        deleteSubscription.unsubscribe();
      };
      cleanupSubscriptions();
    };
  }, []);

  return {
    addNewData,
    updateData,
    updateDataField,
    deleteData,
    findOneData,
    listAllData,
  };
}
