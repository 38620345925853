import { useNavigate } from "react-router-dom";
import useDataForTextEditor from "../../../editors/data-for-editor";
import { FormActionType } from "../../../models";
import useRoutePaths from "../../../routes/route-path";
import TableAddNewButton from "../../../shared-components/button/TableAddNewButton";
import FileManagerListItem from "../../../shared-components/file/file-manager/FileManagerListItem";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataForGalleryImagesFile from "../../../shared-components/images/data-gallery-images";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import useDataFileManager from "../data/data-for-folder-file-form";

function ListFileManagerFolder() {
  const { paths: route } = useRoutePaths();
  const navigation = useNavigate();
  const setFormActionType = useDataFileManager(
    (state) => state.setFormActionType
  );
  const galleryImageReset = useDataForGalleryImagesFile((state) => state.reset);
  const coverImageReset = useDataForCoverImageFile((state) => state.reset);
  const editorReset = useDataForTextEditor((state) => state.reset);
  // const resetForm = useDataFileManager((state) => state.reset);
  return (
    <div className="flex t-flex-col t-overflow-hidden t-justify-start t-h-fit t-rounded-lg t-shadow-lg t-m-4">
      <div className="">
        <CustomNavigation
          background={false}
          subTitle="Articles/Publications"
          title="File Manager"
          buttons={
            <TableAddNewButton
              label="Add New"
              onClick={() => {
                // resetForm();
                coverImageReset();
                editorReset();
                galleryImageReset();
                setFormActionType(FormActionType.NEW);
                navigation(route.fileManager.details);
              }}
            />
          }
        />
      </div>
      <div className="t-flex t-flex-wrap folder-manager t-py-6">
        <FileManagerListItem
          name="December 2022"
          // count={20}
          iconColor="#ad691f"
          onClick={() => {
            navigation(route.fileManager.listFiles);
          }}
        />
        <FileManagerListItem
          name="October 2021"
          // count={290}
          iconColor="#000000"
          onClick={() => {
            navigation(route.fileManager.listFiles);
          }}
        />
        <FileManagerListItem
          name="June 2020"
          // count={290}
          iconColor="#ff6624"
          onClick={() => {
            navigation(route.fileManager.listFiles);
          }}
        />
      
      </div>
    </div>
  );
}

export default ListFileManagerFolder;
