import produce from "immer";
import { create } from "zustand";
import { RecordStatus } from "../../../models";
import { StaffProfile } from "../../../models";
import { PageDataInterfaceStaffProfileForm } from "./data-interface-for-staff-profiles";
import { v4 as getId } from "uuid";

const defaultData = {
  nameFirst: "",
  id: getId(),
  nameLast: "",
  nameFull: "",
  slug: "",
  status: RecordStatus.DRAFT,
  position: "",
  dateAdded: new Date().toISOString(),
  dateUpdated: new Date().toISOString(),
  crigStaffGroupID: "none",
  crigUnitDepartmentID: "none",
  crigDivisionSubStationID: "none",
  bioDesignTextEditors: [],
};

const useDataStaffProfileForm = create<PageDataInterfaceStaffProfileForm>()(
  (set, get) => {
    return {
      showDialog: false,
      setFormData: async (item) => {
        set((_) => ({ formData: item }));
        return true;
      },
      formActionType: undefined,
      setShowDialog: async (value) => {
        set((_) => ({ showDialog: value }));
        return true;
      },
      reset: async () => {
        const newData = { ...defaultData, id: getId() }
        set((_) => ({
          formData: newData as unknown as StaffProfile,
        }));
        return true;
      },
      formData: defaultData as unknown as StaffProfile,
      setFormActionType: async (item) => {
        set((_) => ({ formActionType: item }));
        return true;
      },
      updateFormDataField: async (property, value) => {
        set(
          produce((state: PageDataInterfaceStaffProfileForm) => {
            state.formData![property] = value;
          })
        );
        return true;
      },
    };
  }
);

export default useDataStaffProfileForm;
