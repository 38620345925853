
interface Props {
    errorMsg: string | undefined;
    fieldId: string | undefined;
}

const FormErrorMsg = ({ errorMsg, fieldId }: Props) => {
    return (
        <span className="">
            {errorMsg && (
                <p id={`${fieldId}-error`} className="t-pt-1 t-relative p-error t-text-red-500 t-text-[0.85rem]">
                    {errorMsg}
                </p>
            )}
        </span>
    );
}

export default FormErrorMsg;