import { Ripple } from "primereact/ripple";
import { useRef } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import { BiBriefcase, BiBuildings, BiUserCheck } from "react-icons/bi";
import { TfiLayoutSliderAlt } from "react-icons/tfi";
import { FiBox, FiUsers } from "react-icons/fi";
import { RiProfileLine, RiSeedlingLine } from "react-icons/ri";
import {
  MdOutlineAssignmentInd,
  MdOutlineChat,
  MdOutlineDashboard,
  MdOutlineLocalActivity,
  MdOutlineMail,
  MdOutlineRadio,
} from "react-icons/md";
import {
  TbDeviceAnalytics,
  TbGolf,
  TbMicroscope,
  TbSlideshow,
} from "react-icons/tb";
import useRoutePaths from "../../../../routes/route-path";
import MainLayoutMenuItem from "./MainLayoutMenuItem";
import { HiOutlineNewspaper } from "react-icons/hi";

const MainLayoutSideBarNav = () => {
  const { paths: route } = useRoutePaths();
  return (
    <div
      id="app-sidebar-11"
      className="surface-section t-font-inter h-screen hidden 
      lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1
         border-right-1 surface-border select-none"
      style={{ width: "294px" }}
    >
      <div className="flex flex-column h-full">
        <div
          className="flex align-items-center t-border-b px-5 flex-shrink-0 t-bg-applightgreen-100"
          style={{ height: "60px" }}
        >
          <p className="t-font-bold t-font-playfair text-xl t-text-applightgreen-500">
            Menu
          </p>
        </div>
        <div className="overflow-y-auto ">
          <ul className="list-none p-3 m-0">
            <li>
              <ul className="list-none p-0 m-0 overflow-hidden ">
                {/* <MainLayoutMenuItem
                  title={"Dashboard"}
                  leftIcon={<MdOutlineDashboard />}
                  routePath={route.dashboard}
                /> */}
                <MainLayoutMenuItem
                  title={"Articles/Publications"}
                  leftIcon={<HiOutlineNewspaper />}
                  routePath={route.articlesPublications.list}
                />

                <MainLayoutMenuItem
                  title={"Events/Gallery"}
                  leftIcon={<MdOutlineLocalActivity />}
                  routePath={route.eventsGallery.list}
                />
                <MainLayoutMenuItem
                  title={"News/Announcements"}
                  leftIcon={<MdOutlineRadio />}
                  routePath={route.newsAnnouncements.list}
                />
                <MainLayoutMenuItem
                  title={"Research Activities"}
                  leftIcon={<TbMicroscope />}
                  routePath={route.researchActivities.list}
                />
                {/* <MainLayoutMenuItem
                  title={"Site Analytics"}
                  rightIcon={<BsArrowUpRight />}
                  leftIcon={<TbDeviceAnalytics />}
                  routePath={""}
                  externalLink={route.externalLinks.siteAnalytics}
                /> */}
                <MainLayoutMenuItem
                  title={"Chat Logs"}
                  rightIcon={<BsArrowUpRight />}
                  leftIcon={<MdOutlineChat />}
                  routePath={""}
                  externalLink={route.externalLinks.chatLogs}
                />
                {/* <MainLayoutMenuItem
                  title={"Newsletters"}
                  leftIcon={<MdOutlineMail />}
                  routePath={route.newsletters.list}
                /> */}
                <MainLayoutMenuItem
                  title={"Content Followers"}
                  leftIcon={<BiUserCheck />}
                  routePath={route.contentFollowers.list}
                />
                {/* <MainLayoutMenuItem
                  title={"Files Manager"}
                  leftIcon={<RiProfileLine />}
                  routePath={route.fileManager.list}
                /> */}
                <MainLayoutMenuItem
                  title={"CRIG Staff"}
                  leftIcon={<FiUsers />}
                  routePath={route.crigStaff.list}
                />
                <MainLayoutMenuItem
                  title={"Products"}
                  leftIcon={<FiBox />}
                  routePath={route.products.list}
                />
                <MainLayoutMenuItem
                  title={"External Services"}
                  leftIcon={<BiBriefcase />}
                  routePath={route.externalServices.list}
                />
                <MainLayoutMenuItem
                  title={"Recreation Facilities"}
                  leftIcon={<TbGolf />}
                  routePath={route.recreationalFacilities.list}
                />
                <MainLayoutMenuItem
                  title={"About CRIG"}
                  leftIcon={<BiBuildings />}
                  routePath={route.aboutUs.list}
                />
                <MainLayoutMenuItem
                  title={"CMS Users"}
                  leftIcon={<MdOutlineAssignmentInd />}
                  routePath={route.cmsUsers.list}
                />
                <MainLayoutMenuItem
                  title={"Mandated Crops"}
                  leftIcon={<RiSeedlingLine />}
                  routePath={route.mandatedCrops.list}
                />
                <MainLayoutMenuItem
                  title={"Home Slider"}
                  leftIcon={<TbSlideshow />}
                  routePath={route.homeSlider.list}
                />
              </ul>
            </li>
          </ul>
        </div>
        {/* <div className="mt-auto">
                    <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
                    <a className="p-ripple m-3 flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{ borderRadius: '12px' }}>

                        <span className="font-medium">Amy Elsner</span>
                        <Ripple />
                    </a>
                </div> */}
      </div>
    </div>
  );
};

export default MainLayoutSideBarNav;
