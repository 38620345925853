import * as Yup from "yup";

const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
const regexForEmail = RegExp(
  /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/
);

export const SchemaForAuthSignUp = Yup.object().shape({
  nameFirst: Yup.string()
    .min(1, "Too Short!")
    .max(80, "Too Long!")
    .required("Required"),
  nameLast: Yup.string()
    .min(2, "Too Short!")
    .max(100, "Too Long!")
    .required("Required"),
  email: Yup.string()
    .email()
    .min(10, "Enter Valid Email Address")
    .max(300, "Enter Valid Email Address")
    .matches(regexForEmail, "Enter Valid Email Address")
    .required("Required"),
  phoneNumber: Yup.string()
    .min(10, "Enter Valid 10 Digits")
    .max(10, "Enter Valid 10 Digits")
    .matches(phoneRegex, "Enter Valid 10 Digits")
    .required("Required"),
  password: Yup.string()
    .min(8, "Too Short!")
    .max(250, "Too Long!")
    .required("Required"),
});

export const SchemaForAuthSignIn = Yup.object().shape({
  // phoneNumber: Yup.string()
  //   .min(10, "Enter Valid Phone Number")
  //   .max(10, "Enter Valid Phone Number")
  //   .matches(phoneRegex, "Enter Valid 10 Digits")
  //   .required("Required"),
  username: Yup.string()
    .email()
    .min(10, "Enter Valid Email Address")
    .max(300, "Enter Valid Email Address")
    .matches(regexForEmail, "Enter Valid Email Address")
    .required("Required").default(''),
  password: Yup.string()
    .min(8, "Too Short!")
    .max(250, "Too Long!")
    .required("Required").default(''),
});

export const SchemaForAuthRetrievePasswordPhoneNumber = Yup.object().shape({
  phoneNumber: Yup.string()
    .min(10, "Enter Valid 10 Digits")
    .max(10, "Enter Valid 10 Digits")
    .matches(phoneRegex, "Enter Valid 10 Digits")
    .required("Required"),
});

export const SchemaForAuthRetrievePasswordField = Yup.object().shape({
  password: Yup.string()
    .min(8, "Too Short!")
    .max(250, "Too Long!")
    .required("Required"),
});

export const SchemaForAuth2fa = Yup.object().shape({
  phoneNumber: Yup.string()
    .min(10, "Enter Valid 10 Digits")
    .max(10, "Enter Valid 10 Digits")
    .matches(phoneRegex, "Enter Valid 10 Digits")
    .required("Required"),
  otp: Yup.string()
    .min(6, "Enter Valid 6 Digits")
    .max(6, "Enter Valid 6 Digits")
    .required("Required"),
});
