import { useEffect } from "react";
import {
  ArticlePublicationsAuthors,
  DataLoadingState,
  StaffProfile,
} from "../../../models";
import { ArticlePublication } from "../../../models";
import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../../graphql/subscriptions";
import useDataForUserAuth from "../../../store/auth/data-for-auth";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataArticlePublicationsList from "../data/data-for-articles-publications-list";
import useDataForGalleryImagesFile from "../../../shared-components/images/data-gallery-images";
import useDataArticlePublicationsForm from "../data/data-for-articles-publications-form";
import isDevMode from "../../../utils/check-dev-mode";
import {
  createArticlePublicationGQL,
  deleteArticlePublicationGQL,
  getArticlePublicationGQL,
  listArticlePublicationsGQL,
  updateArticlePublicationGQL,
} from "../../../custom-graphql/custom-gql-article-publications";
import { getArticlePublication } from "../../../graphql/queries";
import { createArticlePublicationsAuthorsGQL } from "../../../custom-graphql/custom-gql-article-publications-authors";
import { v4 as uuid } from "uuid";
import { deleteFieldsForSave } from "../../../utils/forms/util-forms";

interface ServiceInterfaceForArticlePublications {
  addNewData: (
    item: ArticlePublication,
    staffProfiles: StaffProfile[]
  ) => Promise<boolean>;
  updateData: (
    item: ArticlePublication,
    isDelete: boolean,
    staffProfiles: StaffProfile[],
  ) => Promise<boolean | ArticlePublication>;
  updateDataField: (id: string, key: string, value: any) => Promise<boolean>;
  deleteData: (item: ArticlePublication) => Promise<boolean>;
  findOneData: (id: string) => Promise<ArticlePublication | null>;
  listAllData: () => Promise<boolean | null>;
}

const pageModelName = "articles-publications";

export function useServiceForArticlePublicationsAPI(): ServiceInterfaceForArticlePublications {
  const pageDataAdd = useDataArticlePublicationsList((state) => state.addItem);
  const setFormData = useDataArticlePublicationsForm(
    (state) => state.setFormData
  );
  const formData = useDataArticlePublicationsForm((state) => state.formData);
  const loggedUser = useDataForUserAuth((state) => state.loggedUser);
  const pageDataSetStatusForLoading = useDataArticlePublicationsList(
    (state) => state.setStatusForLoadingListItems
  );
  const coverImageGetS3URL = useDataForCoverImageFile(
    (state) => state.getS3URL
  );
  const galleryImageGetS3URL = useDataForGalleryImagesFile(
    (state) => state.getS3URL
  );
  const pageDataSetList = useDataArticlePublicationsList(
    (state) => state.setListItems
  );
  const pageDataUpdate = useDataArticlePublicationsList(
    (state) => state.updateItem
  );
  const pageDataDelete = useDataArticlePublicationsList(
    (state) => state.deleteItem
  );

  const addNewData = async (
    item: ArticlePublication,
    staffProfiles: StaffProfile[]
  ) => {
    let newItem: ArticlePublication;
    let dataResult: ArticlePublication;

    newItem = {
      ...item,
      creatorUserAccountID: loggedUser!.userAccount!.id,
    };

    newItem = deleteFieldsForSave({ pageModelName, newItem });


    console.log(`addNew ${pageModelName} newItem`, newItem);
    try {
      const results: any = await API.graphql(
        graphqlOperation(createArticlePublicationGQL, { input: newItem })
      );
      console.log(`addNew ${pageModelName} results`, results);
      dataResult = results.data.createArticlePublication;

      // if (staffProfiles.length > 0) {
      //   for (var sp of staffProfiles) {
      //     let newArticleAuthor = {
      //       id: uuid(),
      //       staffProfileId: sp.id,
      //       articlePublicationId: item.id,
      //     };
      //     const resultsAuthors: any = await API.graphql(
      //       graphqlOperation(createArticlePublicationsAuthorsGQL, {
      //         input: newArticleAuthor,
      //       })
      //     );

      //     if (isDevMode()) {
      //       console.log(`addNew newArticleAuthors results`, resultsAuthors);
      //     }
      //   }
      // }

      return true;
    } catch (error) {
      console.log(`addNew ${pageModelName} onError`, error);
      return false;
    }
  };

  const updateData = async (item: ArticlePublication, isDelete: boolean, staffProfiles: StaffProfile[]) => {
    let newItem: ArticlePublication;
    let dataResult: ArticlePublication;

    newItem = {
      ...item,
    };

    if (isDelete) {
      newItem = {
        ...item,
        deleted: true,
      };
    }

    newItem = deleteFieldsForSave({ pageModelName, newItem });



    if (isDevMode()) {
      console.log(`update ${pageModelName} newItem`, newItem);
    }

    try {
      const results: any = await API.graphql(
        graphqlOperation(updateArticlePublicationGQL, { input: newItem })
      );
      console.log(`update ${pageModelName} results`, results);
      dataResult = results.data.updateArticlePublication;


      // if (staffProfiles.length > 0) {
      //   for (var sp of staffProfiles) {
      //     let newArticleAuthor = {
      //       id: uuid(),
      //       staffProfileId: sp.id,
      //       articlePublicationId: item.id,
      //     };
      //     const resultsAuthors: any = await API.graphql(
      //       graphqlOperation(createArticlePublicationsAuthorsGQL, {
      //         input: newArticleAuthor,
      //       })
      //     );

      //     if (isDevMode()) {
      //       console.log(`addNew newArticleAuthors results`, resultsAuthors);
      //     }
      //   }
      // }

      if (isDelete) {
        return dataResult;
      } else {
        return true;
      }
    } catch (error) {
      console.log(`update ${pageModelName} onError`, error);
      return false;
    }
  };

  const listAllData = async () => {
    let foundItems: ArticlePublication[] | null | undefined;
    const variables = {
      filter: {
        and: [
          {
            deleted: {
              eq: false,
            },
          },
        ],
      },
    };

    try {
      const results: any = await API.graphql({
        query: listArticlePublicationsGQL,
        variables: variables,
      });
      foundItems = results.data.listArticlePublications
        .items as ArticlePublication[];

      if (foundItems.length > 0) {
        for (var item of foundItems) {
          let index = -1;
          const coverImage = item.coverImage;
          const galleryImages = item.galleryImages;
          let coverImageUrl: string | null = "";

          function findItemIndex(listItem: ArticlePublication) {
            return listItem.id == item.id;
          }

          index = foundItems.findIndex(findItemIndex);

          if (coverImage != null) {
            coverImageUrl = await coverImageGetS3URL(coverImage.key);
            if (coverImageUrl != null) {
              item = {
                ...item,
                coverImage: { ...item.coverImage!, signedURL: coverImageUrl },
              };
              foundItems[index] = item;
            }
          }
          if (galleryImages != null) {
            for (var galleryItem of galleryImages) {
              let galleryURL: string | null = "";
              let galleryItemIndex = galleryImages.indexOf(galleryItem);
              galleryURL = await galleryImageGetS3URL(galleryItem!.key);

              if (galleryURL != null) {
                galleryItem = { ...galleryItem!, signedURL: galleryURL };
                galleryImages[galleryItemIndex] = galleryItem;
              }
            }
            item = { ...item, galleryImages: galleryImages };
            foundItems[index] = item;
          }
        }
      }

      if (isDevMode()) {
        console.log(`listAll ${pageModelName} foundItems`, foundItems);
      }

      foundItems.sort(
        (a, b) =>
          new Date(b.datePublished!).getTime() -
          new Date(a.datePublished!).getTime()
      );
      pageDataSetList(foundItems);
      pageDataSetStatusForLoading(DataLoadingState.LOADED);
      return true;
    } catch (error) {
      if (isDevMode()) {
        console.log(`listAll ${pageModelName} error`, error);
      }
      pageDataSetStatusForLoading(DataLoadingState.LOADED);
      return false;
    }
  };

  const findOneData = async (id: string) => {
    let foundItem: ArticlePublication | null;
    try {
      const results: any = await API.graphql(
        graphqlOperation(getArticlePublicationGQL, { id: id })
      );

      if (isDevMode()) {
        console.log(`findOneData ${pageModelName} results`, results);
      }

      foundItem = results.data.getArticlePublication;

      return foundItem;
    } catch (error) {
      if (isDevMode()) {
        console.log(`findOneData ${pageModelName} onError`, error);
      }

      return null;
    }
  };

  const deleteData = async (item: any) => {
    let dataResult: ArticlePublication;
    console.log(item);
    try {
      item = { ...item, deleted: true };

      const results: any = await API.graphql(
        graphqlOperation(deleteArticlePublicationGQL, {
          input: { id: item.id, _version: item._version },
        })
      );

      if (isDevMode()) {
        console.log(`delete ${pageModelName} results`, results);
      }

      dataResult = results.data.deleteArticlePublication;

      await updateData(dataResult, true, []);

      return true;
    } catch (error) {
      if (isDevMode()) {
        console.log(`update ${pageModelName} onError`, error);
      }

      return false;
    }
  };

  //FIGURE OUT API
  const updateDataField = async (id: string, key: string, value: any) => {
    return false;
  };

  useEffect(() => {
    const createSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onCreateArticlePublication)
      ) as unknown as any
    ).subscribe({
      next: async ({ provider, value }) => {
        if (isDevMode()) {
          console.log({ provider, value });
        }

        let data = value.data.onCreateArticlePublication as ArticlePublication;
        let url: string | null = "";
        let newData: ArticlePublication | null = null;
        await findOneData(data.id).then(async (foundData) => {
          newData = foundData;
          console.log("newData", newData);
          if (newData != null) {
            data = newData;
          }
          if (data.coverImage != null) {
            await coverImageGetS3URL(data.coverImage.key).then(
              async (newURL) => {
                url = newURL;
                if (url != null) {
                  data = {
                    ...data,
                    coverImage: { ...data.coverImage!, signedURL: url },
                  };
                  if (newData != null) {
                    data = {
                      ...(newData as ArticlePublication),
                      coverImage: { ...data.coverImage!, signedURL: url },
                    };
                  }
                }
              }
            );
          }
        });

        pageDataAdd(data);
        if (formData?.id == data.id) {
          setFormData(data);
        }
      },
      error: (error: any) => {
        if (isDevMode()) {
          console.warn(error);
        }
      },
    });

    const updateSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onUpdateArticlePublication)
      ) as unknown as any
    ).subscribe({
      next: async ({ provider, value }) => {
        if (isDevMode()) {
          console.log({ provider, value });
        }

        let data = value.data.onUpdateArticlePublication as ArticlePublication;
        let url: string | null = "";
        let newData: ArticlePublication | null = null;
        await findOneData(data.id).then(async (foundData) => {
          newData = foundData;
          console.log("newData", newData);
          if (newData != null) {
            data = newData;
          }
          if (data.coverImage != null) {
            await coverImageGetS3URL(data.coverImage.key).then(
              async (newURL) => {
                url = newURL;
                if (url != null) {
                  data = {
                    ...data,
                    coverImage: { ...data.coverImage!, signedURL: url },
                  };
                  if (newData != null) {
                    data = {
                      ...(newData as ArticlePublication),
                      coverImage: { ...data.coverImage!, signedURL: url },
                    };
                  }
                }
              }
            );
          }
        });

        pageDataUpdate(data);
        if (formData?.id == data.id) {
          setFormData(data);
        }
      },
      error: (error: any) => {
        if (isDevMode()) {
          console.warn(error);
        }
      },
    });

    const deleteSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onDeleteArticlePublication)
      ) as unknown as any
    ).subscribe({
      next: ({ provider, value }) => {
        if (isDevMode()) {
          console.log({ provider, value });
        }
        pageDataDelete(
          value.data.onDeleteArticlePublication as ArticlePublication
        );
      },
      error: (error: any) => {
        if (isDevMode()) {
          console.warn(error);
        }
      },
    });

    return () => {
      const cleanupSubscriptions = () => {
        createSubscription.unsubscribe();
        updateSubscription.unsubscribe();
        deleteSubscription.unsubscribe();
      };
      cleanupSubscriptions();
    };
  }, []);

  return {
    addNewData,
    updateData,
    updateDataField,
    deleteData,
    findOneData,
    listAllData,
  };
}
