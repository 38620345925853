// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const UserCategoryType = {
  "CMS_USER": "cmsUser",
  "CONTENT_FOLLOWER": "contentFollower",
  "BOTH": "both",
  "NONE": "none",
  "UNDEFINED": "undefined"
};

const VisibilityType = {
  "PUBLIC": "public",
  "PRIVATE": "private",
  "NONE": "none",
  "UNDEFINED": "undefined"
};

const EditorType = {
  "PAGE_BUILDER": "pageBuilder",
  "TEXT_EDITOR": "textEditor",
  "NONE": "none",
  "UNDEFINED": "undefined"
};

const DataLoadingState = {
  "LOADING": "loading",
  "LOADED": "loaded",
  "ERROR": "error",
  "PENDING": "pending",
  "HAS_DATA": "hasData"
};

const FormActionType = {
  "NEW": "new",
  "EDIT": "edit",
  "READ_ONLY": "readOnly",
  "NONE": "none",
  "UNDEFINED": "undefined"
};

const ObjectStatus = {
  "ACTIVE": "active",
  "INACTIVE": "inactive",
  "PENDING": "pending",
  "SUSPENDED": "suspended",
  "NONE": "none"
};

const RecordStatus = {
  "ACTIVE": "active",
  "INACTIVE": "inactive",
  "DRAFT": "draft",
  "ARCHIVE": "archive",
  "PUBLISHED": "published",
  "NONE": "none"
};

const DbOpAccessType = {
  "CREATE": "create",
  "READ": "read",
  "DELETE": "delete",
  "UPDATE": "update",
  "NONE": "none",
  "UNDEFINED": "undefined"
};

const AppTableNames = {
  "APP_NOTIFICATION": "AppNotification",
  "ARTICLE_PUBLICATION": "ArticlePublication",
  "POST_CATEGORY": "PostCategory",
  "USER_ACCOUNT": "UserAccount",
  "USER_ROLE": "UserRole",
  "STAFF_PROFILE": "StaffProfile",
  "CRIG_DIVISION_SUB_STATION": "CrigDivisionSubStation",
  "CRIG_STAFF_GROUP": "CrigStaffGroup",
  "CRIG_UNIT_DEPARTMENT": "CrigUnitDepartment",
  "FILE_MANAGER": "FileManager",
  "POST_SUB_CATEGORY": "PostSubCategory",
  "FOLDER": "Folder",
  "FILE": "File",
  "FAQ": "Faq",
  "FAQ_ITEM": "FaqItem",
  "PAGE": "Page",
  "NEWS": "News",
  "NEWSLETTER": "Newsletter",
  "PAGE_DESIGN_TEMPLATE": "PageDesignTemplate",
  "POST_USER_ACCOUNT_VIEW_DOWNLOAD": "PostUserAccountViewDownload",
  "HELP_CENTER_PAGE": "HelpCenterPage",
  "SETTINGS": "Settings",
  "CONTENT_FOLLOWER": "ContentFollower",
  "NONE": "none",
  "UNDEFINED": "undefined"
};

const AppEnumNames = {
  "EDITOR_TYPE": "EditorType",
  "USER_CATEGORY_TYPE": "UserCategoryType",
  "DB_OP_ACCESS_TYPE": "DbOpAccessType",
  "POST_TYPE": "PostType",
  "VISIBILITY_TYPE": "VisibilityType",
  "DATA_LOADING_STATE": "DataLoadingState",
  "FORM_ACTION_TYPE": "FormActionType",
  "OBJECT_STATUS": "ObjectStatus",
  "RECORD_STATUS": "RecordStatus",
  "OP_TYPE": "OpType",
  "PAGE_TYPE": "PageType",
  "CONTENT_FOLLOW_TYPE": "ContentFollowType",
  "NONE": "none",
  "UNDEFINED": "undefined"
};

const OpType = {
  "INSERT": "INSERT",
  "UPDATE": "UPDATE",
  "DELETE": "DELETE",
  "READ": "READ",
  "NONE": "NONE",
  "UNDEFINED": "UNDEFINED"
};

const ContentFollowType = {
  "RESEARCH_ACTIVITIES": "researchActivities",
  "PRODUCTS": "products",
  "ARTICLES_PUBLICATIONS": "articlesPublications",
  "ARTICLES_PUBLICATIONS_MAIN_CATEGORIES": "articlesPublicationsMainCategories",
  "EVENTS_GALLERY": "eventsGallery",
  "NEWSLETTERS": "newsletters",
  "STAFF_PROFILES": "staffProfiles",
  "NONE": "none",
  "UNDEFINED": "undefined"
};

const PostType = {
  "NEWS_ANNOUNCEMENT": "newsAnnouncement",
  "ARTICLE_PUBLICATION": "articlePublication",
  "NEWSLETTER": "newsletter",
  "APP_NOTIFICATION": "appNotification",
  "NONE": "none",
  "UNDEFINED": "undefined"
};

const PageType = {
  "NEWS_ANNOUNCEMENT": "newsAnnouncement",
  "ARTICLE_PUBLICATION": "articlePublication",
  "NEWSLETTER": "newsletter",
  "EVENT_GALLERY": "eventGallery",
  "RECREATION_FACILITY": "recreationFacility",
  "PRODUCT": "product",
  "EXTERNAL_SERVICE": "externalService",
  "RESEARCH_ACTIVITY": "researchActivity",
  "DIVISION_SUB_STATION": "divisionSubStation",
  "ABOUT_US": "aboutUs",
  "STAFF": "staff",
  "MANDATED_CROPS": "mandatedCrops",
  "APP_NOTIFICATION": "appNotification",
  "NONE": "none",
  "UNDEFINED": "undefined"
};

const UploadCategory = {
  "GALLERY_IMAGE": "galleryImage",
  "COVER_IMAGE": "coverImage",
  "DOCUMENT": "document",
  "NONE": "none",
  "UNDEFINED": "undefined"
};

const { AppNotification, ArticlePublication, ArticlePublicationPublisher, ContactFormMsg, CrigStaffGroup, CrigUnitDepartment, Faq, FaqItem, Folder, HelpCenterPage, Page, PageDesignTemplate, PostCategory, PostSubCategory, PostUserAccountViewDownload, SiteStats, Slide, StaffProfile, UserAccount, UserRole, ArticlePublicationsAuthors, ArticlePublicationsPublishers, S3UploadedItem, ContentViewed, StaffBioTextEditor, UserRoleAccess, PageStat } = initSchema(schema);

export {
  AppNotification,
  ArticlePublication,
  ArticlePublicationPublisher,
  ContactFormMsg,
  CrigStaffGroup,
  CrigUnitDepartment,
  Faq,
  FaqItem,
  Folder,
  HelpCenterPage,
  Page,
  PageDesignTemplate,
  PostCategory,
  PostSubCategory,
  PostUserAccountViewDownload,
  SiteStats,
  Slide,
  StaffProfile,
  UserAccount,
  UserRole,
  ArticlePublicationsAuthors,
  ArticlePublicationsPublishers,
  UserCategoryType,
  VisibilityType,
  EditorType,
  DataLoadingState,
  FormActionType,
  ObjectStatus,
  RecordStatus,
  DbOpAccessType,
  AppTableNames,
  AppEnumNames,
  OpType,
  ContentFollowType,
  PostType,
  PageType,
  UploadCategory,
  S3UploadedItem,
  ContentViewed,
  StaffBioTextEditor,
  UserRoleAccess,
  PageStat
};