import React, { useEffect, useRef, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import { UserRoleAccess, UserRole } from "../../../../models";
import useRoutePaths from "../../../../routes/route-path";
import CustomNavigation from "../../../../shared-components/navigation/CustomNavigation";
import { SchemaForUserRole } from "../form-schemas/form-schema-for-user-roles";
import { InputText } from "primereact/inputtext";
import SectionHeader from "../../../../shared-components/header/SectionHeader";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import {
  formatIsoStringToDate,
  userRoleType,
} from "../../../../utils/forms/util-forms";
import useDataUserRolesForm from "../data/data-for-user-roles-form";
import { Checkbox } from "primereact/checkbox";
import FormErrorMsg from "../../../../shared-components/form/ErrorMsg";
import { Toast } from "primereact/toast";
import ErrorDialog from "../../../../shared-components/form/ErrorDialog";
import LoadingDialog from "../../../../shared-components/form/LoadingDialog";
import { FormActionType } from "../../../../models";
import { useServiceForUserRoleAPI } from "../services/services-for-user-roles";
import useToaster from "../../../../shared-components/toasts/Toaster";
import useDataForUserAuth from "../../../../store/auth/data-for-auth";
import FormActionButtonSet from "../../../../shared-components/button/FormActionButtonSet";
import { AppTableNames } from "../../../../shared-components/toasts/forms/forms-interfaces";

function FormUserRoles() {
  const { paths: route } = useRoutePaths();

  const defaultUserAccessRoles = [
    {
      id: "1",
      userRoleID: "1",
      tableName: AppTableNames.ARTICLE_PUBLICATION,
      routePath: route.articlesPublications.list,
      menuItemTitle: "Articles/Publications",
      operations: [],
    },
    {
      id: "2",
      userRoleID: "2",
      tableName: AppTableNames.PAGE,
      routePath: route.eventsGallery.list,
      menuItemTitle: "Events/Gallery",
      operations: [],
    },
    {
      id: "3",
      userRoleID: "3",
      tableName: AppTableNames.PAGE,
      routePath: route.newsAnnouncements.list,
      menuItemTitle: "News/Announcement",
      operations: [],
    },

    {
      id: "4",
      userRoleID: "4",
      tableName: AppTableNames.PAGE,
      routePath: route.contentFollowers.list,
      menuItemTitle: "Research Activities",
      operations: [],
    },
    {
      id: "5",
      userRoleID: "5",
      tableName: AppTableNames.PAGE,
      routePath: route.contentFollowers.list,
      menuItemTitle: "Site Analytics",
      operations: [],
    },
    {
      id: "6",
      userRoleID: "6",
      tableName: AppTableNames.PAGE,
      routePath: route.contentFollowers.list,
      menuItemTitle: "Chart Logs",
      operations: [],
    },

    {
      id: "7",
      userRoleID: "7",
      tableName: AppTableNames.USER_ACCOUNT,
      routePath: route.contentFollowers.list,
      menuItemTitle: "Content Followers",
      operations: [],
    },
    {
      id: "8",
      userRoleID: "8",
      tableName: AppTableNames.PAGE,
      routePath: route.newsletters.list,
      menuItemTitle: "News Letters",
      operations: [],
    },

    // {
    //   id: "9",
    //   userRoleID: "9",
    //   tableName: AppTableNames.USER_ACCOUNT,
    //   routePath: route.contentFollowers.list,
    //   menuItemTitle: "file Manager",
    //   operations: [],
    // },

    {
      id: "10",
      userRoleID: "10",
      tableName: AppTableNames.STAFF_PROFILE,
      routePath: route.crigStaff.list, //get staff route
      menuItemTitle: "Staff",
      operations: [],
    },
    {
      id: "11",
      userRoleID: "11",
      tableName: AppTableNames.PAGE,
      routePath: route.products.list, //get staff route
      menuItemTitle: "Product",
      operations: [],
    },
    {
      id: "12",
      userRoleID: "12",
      tableName: AppTableNames.PAGE,
      routePath: route.externalServices.list, //get staff route
      menuItemTitle: "External Services",
      operations: [],
    },
    {
      id: "13",
      userRoleID: "13",
      tableName: AppTableNames.PAGE,
      routePath: route.recreationalFacilities.list, //get staff route
      menuItemTitle: "Recreational Facilities",
      operations: [],
    },
    {
      id: "14",
      userRoleID: "14",
      tableName: AppTableNames.PAGE,
      routePath: route.aboutUs.list, //get staff route
      menuItemTitle: "About CRIG",
      operations: [],
    },
    {
      id: "15",
      userRoleID: "15",
      tableName: AppTableNames.USER_ACCOUNT,
      routePath: route.cmsUsers.list, //get media route
      menuItemTitle: "CMS Users",
      operations: [],
    },
    {
      id: "16",
      userRoleID: "16",
      tableName: AppTableNames.ARTICLE_PUBLICATION,
      routePath: route.homeSlider.list, //get media route
      menuItemTitle: "Home Slider",
      operations: [],
    },
    {
      id: "17",
      userRoleID: "17",
      tableName: AppTableNames.SETTINGS,
      routePath: route.settings.initial, //get staff route
      menuItemTitle: "Settings",
      operations: [],
    },
  ];
  const [isCheck, setIsCheck] = useState<boolean>(false);
  const { addNewData, updateData } = useServiceForUserRoleAPI();
  const formActionSetState = useDataUserRolesForm(
    (state) => state.setFormActionType
  );
  const { showError, showSuccess } = useToaster();
  const loggedUser = useDataForUserAuth((state) => state.loggedUser);
  const [validateForm, setValidateForm] = useState<boolean>(false);

  const [errorMsg, _setErrorMsg] = useState(
    "Something went wrong kindly try again."
  );

  const formData = useDataUserRolesForm((state) => state.formData);

  const [editedUserAccessRoles, setEditedUserAccessRoles] = useState<
    boolean | null
  >(null);

  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [privUserAccess, setPrivUserAccess] = useState<UserRoleAccess[]>(
    defaultUserAccessRoles
  );
  const formAction = useDataUserRolesForm((state) => state.formActionType);

  useEffect(() => {
    if (editedUserAccessRoles == null) {
      setPrivUserAccess(
        (formData?.assignedAccess as UserRoleAccess[]) ?? defaultUserAccessRoles
      );
    }
  }, [formData?.assignedAccess]);

  console.log("formAction", formAction);
  const onCheckboxOptionChange = (
    e: any,
    priv: UserRoleAccess,
    operations: string,
    userRoleAccessIndex: number
  ) => {
    const newOperationsList = [...priv.operations];
    let newUserAccess = { ...priv };
    let newPrivList = [...privUserAccess];

    if (e.checked) {
      newOperationsList.push(operations);
      newUserAccess = { ...priv, operations: newOperationsList };
      newPrivList[userRoleAccessIndex] = newUserAccess;
      setPrivUserAccess(newPrivList);
    } else {
      let index = newOperationsList.findIndex((listItem) => {
        return listItem == operations;
      });
      newOperationsList.splice(index, 1);
      newUserAccess = { ...priv, operations: newOperationsList };
      newPrivList[userRoleAccessIndex] = newUserAccess;
      setPrivUserAccess(newPrivList);
    }
  };

  const findOperations = (operations: string, listItem: string) => {
    return listItem == operations;
  };

  const formFormik = useFormik({
    initialValues: formData as UserRole,
    validateOnBlur: false,
    validateOnChange: validateForm,

    validate: (values) => {
      setValidateForm(true);
      console.log("validate", values);
      console.log("errors", formFormik.errors);
    },
    onSubmit: async (values) => {
      let newValues = {
        ...values,
        assignedAccess: privUserAccess,
      };
      console.log("newValues", newValues);
      await saveForm(newValues);
    },
    validationSchema: SchemaForUserRole,
  });

  const toast = useRef(null);
  const refTitle = useRef(null);
  const refShortDescription = useRef(null);
  const refDetails = useRef(null);
  const refDateAdded = useRef(null);
  const refStatus = useRef(null);

  const fieldLabels = {
    title: {
      id: "title",
      ref: refTitle,
      label: "Title",
    },
    shortDescription: {
      id: "shortDescription",
      ref: refShortDescription,
      label: "Short Description",
    },

    details: {
      id: "details",
      ref: refDetails,
      label: "More Details (Optional)",
    },
    dateAdded: {
      id: "dateAdded",
      ref: refDateAdded,
      label: "Date Added",
    },

    status: {
      id: "status",
      ref: refStatus,
      label: "Status",
    },
  };

  interface OperationCheckboxInterface {
    label: string;
    isChecked: boolean;
    onChanged: (val: any) => void;
    classes?: string;
  }

  const OperationCheckbox = ({
    label,
    isChecked,
    onChanged,
    classes,
  }: OperationCheckboxInterface) => {
    return (
      <div className={`form-field flex items-center px-4  t-w-full ${classes}`}>
        <Checkbox name="options" checked={isChecked} onChange={onChanged} />
        <label className="p-checkbox-label ml-3 t-font-inter">{label}</label>
      </div>
    );
  };
  async function saveForm(values: UserRole) {
    let newItem: UserRole;
    switch (formAction ?? FormActionType.NEW) {
      case FormActionType.NEW:
        newItem = new UserRole({
          title: values.title,
          assignedAccess: values.assignedAccess,
          creatorUserAccountID: loggedUser!.userAccount!.id,
          status: values.status!,
          dateAdded: values.dateAdded,
          shortDescription: values.shortDescription,
          deleted: false,
        });

        await addNewData(newItem)
          .then(() => {
            showSuccess(toast, "ATTENTION", "Successfully saved.");
            formActionSetState(FormActionType.EDIT);
            formFormik.setSubmitting(false);
            console.log("done addNewData");
          })
          .catch((err) => {
            console.log("err submitting addNewData", err);
            showError(
              toast,
              "ATTENTION",
              "Something went wrong, kindly try again."
            );
            setShowErrorDialog(true);
            formFormik.setSubmitting(false);
          });
        break;

      case FormActionType.EDIT:
        // if (isDevMode()) {
        //   console.log('isoDate', isoDate)
        // }

        newItem = {
          ...values,
          assignedAccess: privUserAccess,
          deleted: false,
        };

        console.log("rolesnewItem", newItem);
        await updateData(newItem, false)
          .then(async (done) => {
            showSuccess(toast, "ATTENTION", "Successfully saved.");
            formFormik.setSubmitting(false);
          })
          .catch((error) => {
            console.log("err submitting updateData", error);
            showError(
              toast,
              "ATTENTION",
              "Something went wrong, kindly try again."
            );
            setShowErrorDialog(true);
            formFormik.setSubmitting(false);
          });
        break;

      default:
        break;
    }
  }

  const fieldTitle = (
    <div className="form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.title.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.title.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          maxLength={100}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.title}
          ref={fieldLabels.title.ref}
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.title.id}
        errorMsg={formFormik.errors.title}
      />
    </div>
  );

  const fieldShortDescription = (
    <div className="form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.shortDescription.label}
      </p>
      <span className="w-full p-input-filled">
        <InputText
          id={fieldLabels.shortDescription.id}
          onChange={formFormik.handleChange}
          onBlur={formFormik.handleBlur}
          disabled={formFormik.isSubmitting}
          value={formFormik.values.shortDescription ?? ""}
          ref={fieldLabels.shortDescription.ref}
        />
      </span>
      <FormErrorMsg
        fieldId={fieldLabels.shortDescription.id}
        errorMsg={formFormik.errors.shortDescription}
      />
    </div>
  );

  const fieldDateAdded = (
    <div className="t-w-1/2 t-mr-4 form-field date-pick">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.dateAdded.label}
      </p>
      <Calendar
        id={fieldLabels.dateAdded.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        disabled={formFormik.isSubmitting}
        dateFormat="dd/mm/yy"
        value={formatIsoStringToDate(formFormik.values.dateAdded!)}
        ref={fieldLabels.dateAdded.ref}
        className="w-full p-input-filled"
        showIcon
        iconPos="right"
      />
      <FormErrorMsg
        fieldId={fieldLabels.dateAdded.id}
        errorMsg={formFormik.errors.dateAdded}
      />
    </div>
  );

  const fieldStatus = (
    <div className="t-w-1/2 t-ml-4 form-field">
      <p className="t-text-appgrey-800 t-mt-6 t-mb-2 login-input-label t-font-semibold">
        {fieldLabels.status.label}
      </p>
      <Dropdown
        className="p-input-filled t-w-full t-mb-4 md:t-mb-0"
        options={userRoleType}
        optionLabel="name"
        placeholder="Select"
        id={fieldLabels.status.id}
        onChange={formFormik.handleChange}
        onBlur={formFormik.handleBlur}
        disabled={formFormik.isSubmitting}
        value={formFormik.values.status}
        ref={fieldLabels.status.ref}
      />
      <FormErrorMsg
        fieldId={fieldLabels.status.id}
        errorMsg={formFormik.errors.status}
      />
    </div>
  );

  const textEditorForm = (
    <div
      className={` 
    t-transition t-duration-150 t-ease-out t-h-full 
     t-overflow-auto md:t-max-w-[70rem] 
    t-bg-white t-mx-auto t-flex-col 
    t-rounded-lg t-p-2  t-my-0 md:t-my-5 md:t-p-[2.25rem] md:t-max-h-[86vh]  t-pb-[100px]`}
    >
      <div className=" t-mt-0">
        {/*Basic Details From */}
        <SectionHeader title="Basic Details" />
        <div className="t-mb-14">
          {fieldTitle}
          {fieldShortDescription}
        </div>
        <div>
          {/* Dates and  Status*/}
          <SectionHeader title="Dates and Status" />
          <div className="t-mb-10">
            <div className="flex t-w-full">
              {fieldDateAdded}
              {fieldStatus}
            </div>
          </div>
        </div>
        <SectionHeader title="Access Privileges" />
        <div className="flex t-mb-6 t-my-4 t-bg-[#428bca] t-p-2 t-rounded-xl t-justify-center sm:t-w-[20%]  lg:t-w-[15%] t-flex-wrap">
          <div className="flex align-items-center">
            <Checkbox
              inputId="select"
              name="selectAll"
              checked={isCheck}
              onChange={() => setIsCheck(!isCheck)}
            />
            <label htmlFor="ingredient1" className="ml-2 text-white t-text-lg">
              Select All
            </label>
          </div>
        </div>
        <div className="t-w-full t-grid lg:t-grid-cols-3 sm:t-grid-cols-2 gap-2">
          {privUserAccess.map((priv, i) => {
            let hasCreate =
              priv.operations.findIndex((val) =>
                findOperations("create", val)
              ) >= 0;

            let hasRead =
              priv.operations.findIndex((val) => findOperations("read", val)) >=
              0;

            let hasUpdate =
              priv.operations.findIndex((val) =>
                findOperations("update", val)
              ) >= 0;

            let hasDelete =
              priv.operations.findIndex((val) =>
                findOperations("delete", val)
              ) >= 0;

            let allSelected =
              (hasCreate && hasUpdate && hasDelete && hasRead) || isCheck;

            return (
              <div
                key={i.toString()}
                className="t-border-2 t-rounded-md t-mr-4 t-mb-8"
              >
                <div className="t-bg-gray-200 px-4 py-1 w-full ">
                  <div className="form-field t-flex t-items-center t-py-2">
                    <Checkbox inputId={priv.id} checked={allSelected} />
                    <label className="ml-2 t-text-lg t-font-inter t-font-semibold">
                      {priv.menuItemTitle}
                    </label>
                  </div>
                </div>

                <OperationCheckbox
                  isChecked={hasCreate}
                  label="Add New"
                  classes="t-pt-5"
                  onChanged={(val) => {
                    onCheckboxOptionChange(val, priv, "create", i);
                  }}
                />

                <OperationCheckbox
                  isChecked={hasRead}
                  label="Read Items"
                  classes="t-pt-3"
                  onChanged={(val) => {
                    onCheckboxOptionChange(val, priv, "read", i);
                  }}
                />

                <OperationCheckbox
                  isChecked={hasUpdate}
                  label="Update Items"
                  classes="t-pt-3"
                  onChanged={(val) => {
                    onCheckboxOptionChange(val, priv, "update", i);
                  }}
                />

                <OperationCheckbox
                  isChecked={hasDelete}
                  label="Delete Items"
                  classes="t-pt-3 t-pb-5"
                  onChanged={(val) => {
                    onCheckboxOptionChange(val, priv, "delete", i);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="t-mb-[150px]"></div>
    </div>
  );

  return (
    <div className="t-w-full  t-m-auto t-bg-appmainview">
      <Toast ref={toast} position="top-right"></Toast>
      <FormikProvider value={formFormik}>
        <form
          className="main-form t-h-screen"
          onSubmit={formFormik.handleSubmit}
        >
          <CustomNavigation
            title={`${
              formAction == FormActionType.NEW
                ? "Add New User Role"
                : formAction == FormActionType.EDIT
                ? "Update User Role"
                : "New User Role"
            }`}
            background={true}
            useWhiteBackButton={true}
            backArrow={true}
            titleFont={"t-font-inter"}
            titleSize={"t-text-24px"}
            titleFontWeight={"t-font-medium"}
            yPadding={"t-py-1"}
            buttons={
              <FormActionButtonSet
                onPressedPreview={() => {}}
                onPressedSave={() => {}}
                hasPageBuilders={false}
              />
            }
            backArrowNavUrl={route.settings.userRoles.list}
          />
          {textEditorForm}
        </form>
      </FormikProvider>
      <ErrorDialog
        show={showErrorDialog}
        errorMsg={errorMsg}
        onHide={() => {
          setShowErrorDialog(false);
        }}
      />
      <LoadingDialog show={formFormik.isSubmitting} />
      <Toast ref={toast} position="top-right"></Toast>
    </div>
  );
}

export default FormUserRoles;
