import { useNavigate } from "react-router-dom";
import useDataForTextEditor from "../../../editors/data-for-editor";
import { FormActionType } from "../../../models";
import useRoutePaths from "../../../routes/route-path";
import TableAddNewButton from "../../../shared-components/button/TableAddNewButton";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataForGalleryImagesFile from "../../../shared-components/images/data-gallery-images";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import useDataAboutUsForm from "../data/data-for-about-us-form";
import TableAboutUs from "../tables/table-for-about-us";


function ListAboutUs() {
  const { paths: route } = useRoutePaths();
  const navigation = useNavigate();
  const setFormActionType = useDataAboutUsForm(
    (state) => state.setFormActionType
  );
  const resetForm = useDataAboutUsForm((state) => state.reset);
  const galleryImageReset = useDataForGalleryImagesFile((state) => state.reset);

  const coverImageReset = useDataForCoverImageFile((state) => state.reset);
  const editorReset = useDataForTextEditor((state) => state.reset);

  return (
    <div className="flex t-flex-col t-overflow-hidden t-justify-start t-h-fit t-rounded-lg t-shadow-lg t-m-10">
      <div className="">
        <CustomNavigation
          background={false}
          backArrow={true}
          backArrowNavUrl={route.settings.initial}
          title="About CRIG"
          buttons={
            <TableAddNewButton
              label="Add New"
              onClick={() => {
                coverImageReset();
                editorReset();
                galleryImageReset()
                setFormActionType(FormActionType.NEW);
                resetForm();
                navigation(route.aboutUs.details);
              }}
            />
          }
        />
      </div>
      <TableAboutUs />
    </div>
  );
}

export default ListAboutUs;
