export const createPostCategoryGQL = /* GraphQL */ `
  mutation CreatePostCategory(
    $input: CreatePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    createPostCategory(input: $input, condition: $condition) {
      id
      name
      shortDescription
      slug
      deleted
      dateUploaded
      datePublished
      totalPostsCount
      totalSubCategoriesCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updatePostCategoryGQL = /* GraphQL */ `
  mutation UpdatePostCategory(
    $input: UpdatePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    updatePostCategory(input: $input, condition: $condition) {
      id
      name
      shortDescription
      slug
      dateUploaded
      datePublished
      totalPostsCount
      totalSubCategoriesCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createPostSubCategoryGQL = /* GraphQL */ `
  mutation CreatePostSubCategory(
    $input: CreatePostSubCategoryInput!
    $condition: ModelPostSubCategoryConditionInput
  ) {
    createPostSubCategory(input: $input, condition: $condition) {
      id
      name
      slug
      dateUploaded
      datePublished
      totalPosts
      postCategoryID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updatePostSubCategoryGQL = /* GraphQL */ `
  mutation UpdatePostSubCategory(
    $input: UpdatePostSubCategoryInput!
    $condition: ModelPostSubCategoryConditionInput
  ) {
    updatePostSubCategory(input: $input, condition: $condition) {
      id
      name
      slug
      dateUploaded
      datePublished
      totalPosts
      postCategoryID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listPostCategoriesGQL = /* GraphQL */ `
  query ListPostCategories(
    $filter: ModelPostCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        shortDescription
        slug
        dateUploaded
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;


export const listPostSubCategoriesGQL = /* GraphQL */ `
  query ListPostSubCategories(
    $filter: ModelPostSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostSubCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        postCategoryID
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;


export const getPostSubCategoryGQL = /* GraphQL */ `
  query GetPostSubCategory($id: ID!) {
    getPostSubCategory(id: $id) {
      id
      name
      slug
      dateUploaded
      datePublished
      totalPosts
      deleted
      postCategoryID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

