import { Button } from "primereact/button";
import {
  FileUpload,
  FileUploadRemoveEvent,
  ItemTemplateOptions,
} from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useRef, useState } from "react";

import { Folder, S3UploadedItem } from "../../models";
import "./style.css";
import { ProgressBar } from "primereact/progressbar";

import useDataForGalleryImagesFile from "./data-gallery-images";
import { Tag } from "primereact/tag";
import { encodeImageToBlurhash } from "./blurhash-images";
import { GalleryFile } from "../toasts/forms/forms-interfaces";
import isDevMode from "../../utils/check-dev-mode";

interface Props {
  title: string;
  id: string;
  ref?: any;
  folder: Folder | null | undefined;
  postId: string;
  onClear?: () => void;
  onRemove?: (file: File | S3UploadedItem) => void;
}

export const GalleryImagesUploader = ({ title, id, folder, postId }: Props) => {
  const [totalFileSize, setTotalSize] = useState(0);
  const toast = useRef<any>(null);
  const fileUploadRef = useRef<any>(null);
  const cropImgRef = useRef<HTMLImageElement>(null);
  const [filesToUpload, setFilesToUpload] = useState<File[] | null>(null);
  const [galleryFiles, setGalleryFiles] = useState<GalleryFile[] | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const files = useDataForGalleryImagesFile((state) => state.files);
  const onUpload = useDataForGalleryImagesFile((state) => state.onUpload);
  const onSelect = useDataForGalleryImagesFile((state) => state.onSelect);
  const onError = useDataForGalleryImagesFile((state) => state.onError);
  const onClear = useDataForGalleryImagesFile((state) => state.onClear);
  const s3Items = useDataForGalleryImagesFile((state) => state.s3Items) ?? [];
  const galleryImageFindS3Object = useDataForGalleryImagesFile(
    (state) => state.findS3Object
  );
  const galleryImageSetS3Items = useDataForGalleryImagesFile(
    (state) => state.setS3Items
  );

  const [imagesEncoded, setImagesEncoded] = useState<boolean | null>(null);

  function convertBlobToFile(blob: any, fileName: string) {
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
  }

  useEffect(() => {
    if (s3Items?.length > 0 && filesToUpload == null) {
      (async () => {
        let newFiles: Blob | File[] = [];
        let newGalleryFiles: GalleryFile[] = [];
        let _totalSize = 0;

        for (var item of s3Items) {
          let blob = await galleryImageFindS3Object(item!.key);
          if (blob != null) {
            var name = item!.key.split("/")[3];

            blob = convertBlobToFile(blob, name);
            newGalleryFiles.push({
              file: blob as File,
              encodedBlurhash: item.blurHash,
            });
            newFiles.push(blob as File);
            _totalSize += blob!.size;
          }
        }
        if (fileUploadRef && fileUploadRef.current) {
          if (isDevMode()) {
            console.log("newFiles", newFiles);
          }

          fileUploadRef.current.setFiles(newFiles);
          setTotalSize(_totalSize);
          setFilesToUpload(newFiles);
          setGalleryFiles(newGalleryFiles);
          setImagesEncoded(true);
        }
      })();
    }

    return () => {};
  }, [files, s3Items]);

  useEffect(() => {
    if (
      (galleryFiles != null || galleryFiles != undefined) &&
      galleryFiles.length > 0 &&
      imagesEncoded == null
    ) {
      (async () => {
        let newList = [...galleryFiles];

        for (var item of newList) {
          const indexToUpdate = newList.findIndex(
            (listItem) => listItem === item
          );

          const newEncoded = await encodeImageToBlurhash({ file: item.file });

          if (newEncoded != null || newEncoded != undefined) {
            let newItem: GalleryFile = { ...item, encodedBlurhash: newEncoded };
            newList[indexToUpdate] = newItem;
            if (isDevMode()) {
              console.log("filesToUploadGallery newEc", newEncoded);
            }
          }
        }

        setGalleryFiles(newList);
        setImagesEncoded(true);
      })();
    }

    return () => {};
  }, [galleryFiles, imagesEncoded]);

  // function showCropArea() {

  //   if (cropImgRef.current !== null) {
  //     // create a CropArea
  //     const cropArea = new cropro.CropArea(cropImgRef.current);
  //     // attach an event handler to assign cropped image back to our image element
  //     cropArea.addRenderEventListener(async dataUrl => {
  //       if (cropImgRef.current) {
  //         cropImgRef.current.src = dataUrl;

  //         const dataURLtoBlob = (dataURL: string) => {
  //           fetch(dataURL)
  //             .then(res => res.blob())
  //             .then(blob => {
  //               const fileName = `${fileToUpload?.name}`
  //               const fileExt = fileName.substring(fileName.lastIndexOf(".") + 1)
  //               const newFile: File = new File([blob], `${fileToUpload?.name}`,
  //                 { type: `${fileToUpload?.type}` });
  //               setFileToUpload(newFile)

  //             })
  //             .catch(err => console.log('err', err))
  //         }

  //         dataURLtoBlob(dataUrl);

  //       }
  //     });

  //     // launch CROPRO
  //     cropArea.displayMode = 'popup';
  //     cropArea.show();
  //   }
  // }

  const onImageSelect = (e) => {
    const files = e.files;
    let _totalSize = totalFileSize;
    let _filesToUpload = [...(filesToUpload ?? [])];
    let _filesToUploadGallery = [...(galleryFiles ?? [])];

    [...e.files].forEach((file) => {
      console.log("files[key].size", file.size);
      if (file.size > 1000000) {
        toast.current.show({
          severity: "error",
          summary: "ATTENTION",
          detail: "File size is larger than 800KB",
        });
        return;
      }
      _filesToUpload.push(file);
      _filesToUploadGallery.push({ file: file });
      _totalSize += file.size || 0;
    });

    setFilesToUpload(_filesToUpload);
    setGalleryFiles(_filesToUploadGallery);
    setTotalSize(_totalSize);
    setImagesEncoded(null);

    // Object.keys(files).forEach((key) => {
    //   console.log('files[key].size', files[key].size)
    //   if (files[key].size > 600000) {
    //     toast.current.show({
    //       severity: "error",
    //       summary: "ATTENTION",
    //       detail: "File size is larger than 500KB",
    //     });
    //     return;
    //   }
    //   _totalSize += files[key].size || 0;
    //   _filesToUpload.push(files[key]);
    // });

    // setFilesToUpload(_filesToUpload);
    // setGalleryFiles(_filesToUploadGallery);
    // setTotalSize(_totalSize);
    // setImagesEncoded(null)
  };

  const onImageUpload = async (e) => {
    setIsUploading(true);

    // const files = e.files;
    // let _totalSize = 0;
    // filesToUpload.forEach((file: File) => {
    //   _totalSize += file.size || 0;
    // });

    // setTotalSize(_totalSize);

    // if (_totalSize > 7000000) {
    //   toast.current.show({
    //     severity: "error",
    //     summary: "ATTENTION",
    //     detail: "File size is larger than 500KB",
    //   });
    //   setIsUploading(false);
    //   return;
    // }

    if (filesToUpload != null) {
      if (filesToUpload.length == 0) {
        toast.current.show({
          severity: "error",
          summary: "ATTENTION",
          detail: "No files to upload",
        });
        setIsUploading(false);
        return;
      }

      if (
        folder != null ||
        (folder != undefined && (galleryFiles ?? []).length > 0)
      ) {
        await onUpload({ galleryFiles, folder, totalFileSize, postId })
          .then((doneUploading) => {
            if (isDevMode()) {
              console.log("onUpload done:", doneUploading);
            }

            toast.current.show({
              severity: "info",
              summary: "Success",
              detail: "File Uploaded",
            });
          })
          .catch((e) => {
            console.log("onUpload failed", e);

            toast.current.show({
              severity: "error",
              summary: "ATTENTION",
              detail: "File Upload Failed",
            });
          });

        setIsUploading(false);
        return;
      }
      if (isDevMode()) {
        console.log("isempty");
      }

      return;
    }

    setIsUploading(false);
  };

  const onImageRemove = (e: FileUploadRemoveEvent) => {
    const removedFile = e.file;

    if (galleryFiles) {
      const newGalleryFiles = galleryFiles.filter(
        (galleryFile) => galleryFile.file.name !== removedFile.name
      );
      console.log("onImageRemovegalleryFiles", e);
      setGalleryFiles(newGalleryFiles);
    }
    if (filesToUpload) {
      const newFiles = filesToUpload.filter(
        (fileToUp) => fileToUp.name !== removedFile.name
      );

      setFilesToUpload(newFiles);
      console.log("onImageRemovefilesToUpload", e);
    }

    console.log("onImageRemove", e);
  };

  const onImageClear = () => {
    setTotalSize(0);
    setFilesToUpload(null);
    setGalleryFiles(null);
    onClear();
  };

  // const cropButton = (
  //   <Button type="button" icon={<BsCrop />} onClick={() => {
  //     showCropArea();
  //   }} className="custom-crop-btn p-button-rounded p-button-info p-button-outlined" aria-label="User" />
  // );

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const formattedValue: string =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalFileSize)
        : "0 B";

    return (
      <div
        className={`${className}`}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div className="t-flex t-flex-col t-w-full t-justify-between t-h-full">
          <div className="t-flex t-w-full t-justify-between t-items-center">
            <p className="t-font-bold t-font-playfair t-text-[26px]">
              {title}
              <span className="t-text-xs t-font-inter t-text-gray-300 t-ml-[10px]">
                {`(${formattedValue.substring(
                  0,
                  formattedValue.length - 2
                )} / 10000 KB)`}
              </span>
            </p>
            <div>
              {chooseButton}
              {uploadButton}
              {/* {cropButton} */}
              {cancelButton}
            </div>
          </div>
        </div>
        {isUploading && (
          <div className="t-flex t-justify-end t-flex-col t-w-full t-mt-[1.5rem]">
            <div className="image-upload">
              <ProgressBar
                mode="indeterminate"
                style={{ height: "6px" }}
              ></ProgressBar>
            </div>
          </div>
        )}
      </div>
    );
  };
  const onTemplateRemove = (file, callback) => {
    console.log("onTemplateRemove", file, callback);
    const currentSize = totalFileSize - file.size;

    if (currentSize < 0) {
      setTotalSize(0);
    } else {
      setTotalSize(currentSize);
    }

    callback();
  };

  const itemTemplate3 = (file, props) => {
    // console.log('itemTemplate file', file, props)
    let newFile: File | Blob | null = null;
    let newFiles: File[] | Blob[] | null = null;
    let url = "";
    let isArray = false;
    // let formatSize: string = props.formatSize;

    interface GalleryItemProps {
      theFile: any;
    }

    // const GalleryItem = (file, props) => {
    let itemUrl = URL.createObjectURL(file as File);
    let formatSize = String(`${(file!.size / 1000).toFixed(3)} KB`);

    //   return (
    //     <div className="flex align-items-center flex-wrap">
    //       <div className="flex align-items-center" style={{ width: '40%' }}>
    //         <img alt={file?.name} role="presentation" src={GalleryItemUrl} width={100} />
    //         <span className="flex flex-column text-left ml-3">
    //           {file?.name}
    //           <small>{new Date().toLocaleDateString()}</small>
    //         </span>
    //       </div>
    //       <Tag value={formatSize} severity="warning" className="px-3 py-2" />
    //       <Button type="button" icon="pi pi-times" className="p-button-outlined t-mr-[1.65rem] p-button-rounded p-button-danger ml-auto"
    //         onClick={() => {
    //           onTemplateRemove(file, props.onRemove)
    //         }} />
    //     </div>
    //   )
    // }

    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <img alt={file?.name} role="presentation" src={itemUrl} width={100} />
          <span className="flex flex-column text-left ml-3">
            {file?.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={formatSize} severity="warning" className="px-3 py-2" />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined t-mr-[1.65rem] p-button-rounded p-button-danger ml-auto"
          onClick={() => {
            onTemplateRemove(file, props.onRemove);
          }}
        />
      </div>
    );

    // try {
    //   isArray = Array.isArray(file);
    //   if (isArray) {
    //     newFiles = file!;
    //     // console.log(' newFiles', newFiles)
    //   } else {
    //     newFile = file
    //     url = URL.createObjectURL(file as File);
    //   }
    // } catch (error) {
    //   console.log('error gen url', error)
    // }

    // return (
    //   <>{!isArray && <GalleryItem theFile={newFile} />}

    //     {isArray && <div className="t-flex t-flex-col">
    //       {newFiles?.map((theFile: any, i: number) => {
    //         return (
    //           <GalleryItem key={i.toString()} theFile={theFile} />
    //         )
    //       })}
    //     </div>
    //     }
    //   </>
    // );
  };

  const itemTemplate2 = (file: any, _props: ItemTemplateOptions) => {
    const url = URL.createObjectURL(file as File);
    return (
      <div className="t-flex align-items-center flex-wrap t-flex-col">
        <div
          className="flex t-flex-row align-items-center cover-image-file"
          style={{ width: "100%", border: "none" }}
        >
          <img
            id="cropImage"
            ref={cropImgRef}
            alt={file?.name}
            className={
              "t-flex-grow t-h-[400px] t-overflow-hidden t-rounded-b-[0.375rem] t-object-cover t-aspect-video"
            }
            src={url}
            style={{ maxWidth: "100%" }}
          />
        </div>

        {isUploading && (
          <div className="t-absolute t-w-full t-h-full">
            <div className="image-upload">
              <ProgressBar
                mode="indeterminate"
                style={{ height: "6px" }}
              ></ProgressBar>
            </div>
          </div>
        )}
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "4em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop Images Here
        </span>
      </div>
    );
  };

  const customBase64Uploader = async (event) => {
    // convert file to base64 encoded
    const file = event.files[0];
    const reader = new FileReader();
    let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      console.log(base64data);
    };
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  return (
    <div>
      <Toast ref={toast}></Toast>
      <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
      <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
      <Tooltip target=".custom-crop-btn" content="Crop" position="bottom" />
      <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

      <div className="card form-file-uploader">
        <FileUpload
          ref={fileUploadRef}
          name="galleryImages[]"
          id={id}
          multiple={true}
          accept="image/*"
          maxFileSize={10000000}
          customUpload={true}
          uploadHandler={onImageUpload}
          onSelect={onImageSelect}
          onError={onImageClear}
          contentClassName=" !t-bg-slate-50/40"
          onClear={onImageClear}
          onRemove={onImageRemove}
          headerTemplate={headerTemplate}
          itemTemplate={itemTemplate3}
          emptyTemplate={emptyTemplate}
          chooseOptions={chooseOptions}
          uploadOptions={uploadOptions}
          cancelOptions={cancelOptions}
        />
      </div>
    </div>
  );
};
