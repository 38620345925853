import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { format } from "date-fns";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  ArticlePublication,
  DataLoadingState,
  S3UploadedItem,
} from "../../../models";
import useDataArticlePublicationsForm from "../data/data-for-articles-publications-form";
import { ScrollTop } from "primereact/scrolltop";
import TableActionButtonSet from "../../../shared-components/button/TableActionButtonSet";
import DeleteDialog from "../../../shared-components/form/DeleteDialog";
import { ListLoader } from "../../../shared-components/list-tables/list-loader";
import useToaster from "../../../shared-components/toasts/Toaster";
import { useServiceForArticlePublicationsAPI } from "../services/services-for-articles-publications";
import useDataArticlesPublicationsList from "../data/data-for-articles-publications-list";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataForTextEditor from "../../../editors/data-for-editor";
import useDataForGalleryImagesFile from "../../../shared-components/images/data-gallery-images";
import { useNavigate } from "react-router-dom";
import { FormActionType } from "../../../models";
import useRoutePaths from "../../../routes/route-path";
import useDataForDocumentFile from "../../../shared-components/images/data-document-file";

const TableArticlesPublications = () => {
  const { paths: route } = useRoutePaths();
  const listItems = useDataArticlesPublicationsList((state) => state.listItems);
  const documentFileReset = useDataForDocumentFile((state) => state.reset);
  const coverImageReset = useDataForCoverImageFile((state) => state.reset);
  const editorReset = useDataForTextEditor((state) => state.reset);
  const galleryImageReset = useDataForGalleryImagesFile((state) => state.reset);
  const navigation = useNavigate();
  const setFormActionType = useDataArticlePublicationsForm(
    (state) => state.setFormActionType
  );
  const setFormData = useDataArticlePublicationsForm(
    (state) => state.setFormData
  );
  const loadingStatus = useDataArticlesPublicationsList(
    (state) => state.statusForLoadingListItems
  );
  const { showError, showSuccess } = useToaster();
  const refToast = useRef(null);
  const deleteItemFromService =
    useServiceForArticlePublicationsAPI().deleteData;
  const [itemToDelete, setItemToDelete] = useState<ArticlePublication | null>(
    null
  );
  const listItemsFromService =
    useServiceForArticlePublicationsAPI().listAllData;
  const setStateLoadingStatus = useDataArticlesPublicationsList(
    (state) => state.setStatusForLoadingListItems
  );
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    title: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    dateUpdated: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },

    activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
  });
  const columnsAll: any = [
    { field: "id", header: "ID" },
    // { field: "coverImage", header: "Cover Image" },
    { field: "title", header: "Title" },
    { field: "postSubCategory.name", header: "Sub Category" },
    { field: "visibilityType", header: "Visibility" },
    { field: "status", align: "center", header: "Status" },
    { field: "totalViewsCount", header: "Total Views" },
  ];

  const columnsSelected: any = [
    { field: "title", header: "Title" },
    { field: "postSubCategory.name", header: "Main Category" },
    { field: "visibilityType", header: "Visibility" },
    { field: "status", align: "center", header: "Status" },
    { field: "totalViewsCount", header: "Total Views" },
  ];

  const [selectedColumns, setSelectedColumns] = useState(columnsSelected);

  const onColumnToggle = (event: any) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columnsAll.filter((col: { field: any }) =>
      selectedColumns.some((sCol: { field: any }) => sCol.field === col.field)
    );
    setSelectedColumns(orderedSelectedColumns);
  };

  const onSearchKeywordChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = (
    <div
      className="t-grid grid-cols-1 t-w-full  md:t-grid-cols-3
   justify-content-start align-items-center "
    >
      <div className="list-column-select" style={{ textAlign: "left" }}>
        <MultiSelect
          fixedPlaceholder={true}
          placeholder="Columns"
          value={selectedColumns}
          options={columnsAll}
          optionLabel="header"
          onChange={onColumnToggle}
          style={{ width: "14.5em", borderRadius: "10px" }}
        />
      </div>
      <div className="list-search-box t-w-full t-pt-4 md:t-pt-0">
        <span className="p-input-icon-left p-input-border-none  t-font-inter p-input-filled  t-w-full">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onSearchKeywordChange}
            placeholder="Keyword Search"
    
          />
        </span>
      </div>
    </div>
  );

  const columnComponents = selectedColumns.map((col) => {
    return (
      <Column
        key={col.field}
        align={col.align}
        field={col.field}
        header={col.header}
      />
    );
  });

  const actionsBodyTemplate = (rowData: any) => {
    return (
      <TableActionButtonSet
        editOnClick={() => {
          editOnClick(rowData);
        }}
        viewOnClick={() => {}}
        deleteOnClick={() => {
          deleteOnClick(rowData);
        }}
      />
    );
  };

  const handleDeleteItem = async () => {
    try {
      let _itemToDelete: ArticlePublication = {
        ...itemToDelete!,
        deleted: true,
      };
      await deleteItemFromService(_itemToDelete).then((done) => {
        setItemToDelete(null);
      });
    } catch (error) {
      console.log(`err handleDeleteItem research`, error);
      showError(
        refToast,
        "ATTENTION",
        "Something went wrong, kindly try again."
      );
    }
  };
  const coverImageTemplate = (rowData: any) => {
    const coverImage: S3UploadedItem = rowData["coverImage"];
    let id = rowData["id"];
    let src = "";
    if (coverImage != null) {
      src = coverImage.signedURL ?? "";
    }
    return (
      <div className="t-rounded-md t-object-cover t-h-[70px] t-bg-slate-200 t-w-[120px] t-overflow-hidden">
        {src.length > 0 && (
          <img
            className="t-object-cover t-h-[70px]  t-w-[120px]"
            id={id}
            src={src}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    let fetching = true;

    const fetchListData = async () => {
      await listItemsFromService();
    };

    if (fetching) {
      if (listItems.length == 0 && loadingStatus == DataLoadingState.PENDING) {
        setStateLoadingStatus(DataLoadingState.LOADING);
        fetchListData();
      }
    }

    return () => {
      fetching = false;
    };
  }, [loadingStatus, listItems]);

  const editOnClick = (rowData: any) => {
    coverImageReset();
    editorReset();
    galleryImageReset();
    documentFileReset();
    setFormActionType(FormActionType.EDIT);
    setFormData(rowData as ArticlePublication);
    navigation(route.articlesPublications.details);
  };

  const deleteOnClick = (rowData: any) => {
    setItemToDelete(rowData as ArticlePublication);
  };

  const formatDate = (value: Date, addTime: boolean) => {
    return format(value, addTime ? `do LLL yyyy, h:mm aaa` : "do LLL yyyy");
  };

  const dateBodyTemplate = (field: string, addTime: boolean) => {
    const valueAsDate = new Date(field);
    const formatted = formatDate(valueAsDate, addTime);
    return <p>{formatted}</p>;
  };

  const calculatePostTotal = (postSubCategoryID: string) => {
    let total = 0;

    if (listItems) {
      for (let post of listItems) {
        if (post.postSubCategoryID === postSubCategoryID) {
          total++;
        }
      }
    }

    return total;
  };

  const headerTemplate = (data: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span className="font-bold">{data.postSubCategory.name}</span>
      </div>
    );
  };

  const footerTemplate = (data: any) => {
    return (
      <Fragment>
        <td colSpan={5}>
          <div className="flex justify-content-end font-bold w-full">
            Total Posts: {calculatePostTotal(data.postSubCategoryID)}
          </div>
        </td>
      </Fragment>
    );
  };

  return (
    
    <div className="t-bg-white t-flex-grow t-h-full t-pt-[72px]">       {/* update this fro responsiveness */}
      {/* loader */}
      {(loadingStatus == DataLoadingState.LOADING ||
        loadingStatus == DataLoadingState.PENDING) && (
        <ListLoader skelsNumber={7} />
      )}
      {/* update this datatable and scroll height for responsiveness */}
      {loadingStatus == DataLoadingState.LOADED && (
        <>
          <DataTable
            scrollable
            dataKey="id"
            emptyMessage="No items found."
            stateStorage="session"
            stateKey="dt-state-articles-publications"
            // scrollHeight="79vh"
            scrollHeight="flex"
            value={listItems}
            filters={filters}
            paginator
            rows={50}
            rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
            header={header}
            sortOrder={1}
            sortField="postSubCategory.name"
            rowGroupMode="subheader"
            groupRowsBy="postSubCategory.name"
            sortMode="single"
            rowGroupHeaderTemplate={headerTemplate}
            rowGroupFooterTemplate={footerTemplate}
            responsiveLayout="scroll"
          >
            {/* <Column
            field="coverImage"
            align={"left"}
            header="Cover Image"
            showFilterMatchModes={false}
            // style={{ minWidth: "100px", maxWidth: '100px' }}
            body={coverImageTemplate}
          /> */}
            {columnComponents}
            <Column
              field="updatedAt"
              align={"left"}
              header="Date Updated"
              showFilterMatchModes={false}
              style={{ minWidth: "9rem" }}
              body={(rowData, _options) => {
                return dateBodyTemplate(rowData["updatedAt"], false);
              }}
            />
            <Column
              field="activity"
              header="Actions"
              showFilterMatchModes={false}
              style={{ minWidth: "11rem" }}
              body={actionsBodyTemplate}
            />
          </DataTable>
          <ScrollTop />
        </>
      )}
      {itemToDelete != null && (
        <DeleteDialog
          message={`Are you sure you want to delete ${itemToDelete.title}? This action cannot be reversed!`}
          onDelete={handleDeleteItem}
          onSetVisible={() => {
            setItemToDelete(null);
          }}
          show={itemToDelete != null}
        />
      )}
    </div>
  );
};

export default TableArticlesPublications;
