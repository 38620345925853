import { FaFolder } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useRoutePaths from "../../../routes/route-path";

interface FileManagerListItemProps {
  iconColor: string;
  name: string;
  onClick: () => void;
}
function FileManagerListItem(props: FileManagerListItemProps) {
  const navigation = useNavigate();
  const { paths: route } = useRoutePaths();

  return (
    // <div
    //   className="folder t-shadow-2xl p-2 t-cursor-pointer"
    //   onClick={() => {
    //     navigation(route.fileManager.listFolder);
    //   }}
    // >
    //   <div className="t-font-bold t-my-2">{props.name}</div>
    //   <p className="t-text-appgrey-700">{props.count} Files</p>
    // </div>
    <div
      onClick={() => {
        console.log("this was clicked");
        props.onClick();
      }}
      className="t-w-[300px] t-h-[194px] t-bg-white t-rounded-xl t-m-4
    t-shadow-xl p-4 t-cursor-pointer flex t-flex-col t-justify-between
    "
    >
      <div
        className="flex t-items-center t-justify-center t-rounded-full
      t-h-10 t-w-10 t-bg-appgrey-300"
      >
        <FaFolder color={props.iconColor} size={24} />
      </div>
      <div className="t-font-bold t-my-4">{props.name}</div>
    </div>
  );
}

export default FileManagerListItem;
