import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { PostCategory, OpType, PostSubCategory, DataLoadingState } from "../../../../models";

import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../../../graphql/subscriptions";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import useDataForUserAuth from "../../../../store/auth/data-for-auth";
import { usePostSubCategoryServiceAPI } from "./services-for-sub-post-categories";
import { createPostCategoryGQL, listPostCategoriesGQL, updatePostCategoryGQL } from "../../../../custom-graphql/custom-gql-main-post-sub-category";
import useDataPostCategoryList from "../data/data-for-post-categories-list";

interface ServicesForPostCategoriesInterface {
    addNewPostCategory: (item: PostCategory, subCategories: PostSubCategory[]) => Promise<boolean>;

    updatePostCategory: (item: PostCategory, subCategories: PostSubCategory[]) => Promise<boolean>;

    updatePostCategoryField: (
        id: string,
        key: string,
        value: any
    ) => Promise<boolean>;

    deletePostCategory: (item: PostCategory) => Promise<boolean>;

    findOnePostCategory: (id: string) => Promise<PostCategory | null>;

    listAllPostCategories: () => Promise<[PostCategory] | null>;
}

export function usePostCategoryServiceAPI(): ServicesForPostCategoriesInterface {

    const postCategoryDataAdd = useDataPostCategoryList(
        (state) => state.addItem
    );

    const { addNewPostSubCategory, updatePostSubCategory } = usePostSubCategoryServiceAPI();

    const postCategoryDataSetList = useDataPostCategoryList(
        (state) => state.setListItems
    );

    const postCategoryDataUpdate = useDataPostCategoryList(
        (state) => state.updateItem
    );

    const postCategoryDataDelete = useDataPostCategoryList(
        (state) => state.deleteItem
    );

    const listItems = useDataPostCategoryList(
        (state) => state.listItems
    );

    const [addingNewItem, setAddingNewItem] = useState(false);

    const [updatingNewItem, setUpdatingNewItem] = useState(false);

    const [deletingNewItem, setDeletingNewItem] = useState(false);

    const loggedUser = useDataForUserAuth((state) => state.loggedUser);

    const postCategoryStatusForLoadingListItems = useDataPostCategoryList(
        (state) => state.statusForLoadingListItems
    );
    const postCategorySetStatusForLoadingListItems = useDataPostCategoryList(
        (state) => state.setStatusForLoadingListItems
    );


    const addNewPostCategory = async (item: PostCategory, subCategories: PostSubCategory[]) => {
        let newItem: PostCategory;
        let promise: any;

        newItem = {
            ...item,
            dateUploaded: new Date().toISOString(),
            totalSubCategoriesCount: subCategories.length,
        };

        delete (newItem as any).pages;
        delete (newItem as any).articlesPublications;
        delete (newItem as any).subCategories;
        delete (newItem as any).createdAt;
        delete (newItem as any).updatedAt;
        delete (newItem as any)._lastChangedAt;
        delete (newItem as any)._deleted;

        console.log("addNewPostCategory newItemToSave", newItem);
        console.log("addNewPostCategory subCategories", subCategories);


        // try {
        //     const savedItem = API.graphql(
        //         graphqlOperation(createPostCategoryGQL, { input: newItem })
        //     );
        // } catch (error) {
        //     console.log("addNewPostCategory results onError", error);

        //     return false;
        // }

        promise = new Promise((resolve, reject) => {
            const savedItem = API.graphql(
                graphqlOperation(createPostCategoryGQL, { input: newItem })
            );
            console.log("addNewPostCategory savedItem", savedItem);
            resolve(savedItem);
            reject(savedItem);
        });

        async function onFulfilled(results: any) {
            console.log("addNewPostCategory results onFulfilled", results);
            if (subCategories.length > 0) {
                for (var item of subCategories) {
                    await addNewPostSubCategory(item);
                }
            }
            return true;
        }
        function onError(results: any) {
            console.log("addNewPostCategory results onError", results);

            return false;
        }

        promise.then(onFulfilled).catch(onError);

        return false;

    };

    const updatePostCategory = async (item: PostCategory, subCategories: PostSubCategory[]) => {
        let newItem: PostCategory;
        let promise: any;
        let newSubCat: PostSubCategory;

        newItem = {
            ...item,
            dateUploaded: new Date().toISOString(),
            totalSubCategoriesCount: subCategories.length,
        };

        delete (newItem as any).pages;
        delete (newItem as any).articlesPublications;
        delete (newItem as any).subCategories;
        delete (newItem as any).createdAt;
        delete (newItem as any).updatedAt;
        delete (newItem as any)._lastChangedAt;
        delete (newItem as any)._deleted;



        console.log("updatePostCategory newItemToUpdate", newItem);
        console.log("updatePostCategory subCategories", subCategories);


        promise = new Promise((resolve, reject) => {
            const savedItem = API.graphql(
                graphqlOperation(updatePostCategoryGQL, { input: newItem })
            );
            console.log("updatePostCategory savedItem", savedItem);
            resolve(savedItem);
            reject(savedItem);
        });

        async function onFulfilled(results: any) {
            console.log("updatePostCategory results onFulfilled", results);
            if (subCategories.length > 0) {
                for (var subCatItem of subCategories) {
                    if (subCatItem.postCategoryID == 'setOnSave') {
                        newSubCat = { ...subCatItem, postCategoryID: item.id, slug: `${newItem.slug}-${subCatItem.slug}` }
                        await addNewPostSubCategory(newSubCat);
                        return;
                    }
                    newSubCat = { ...subCatItem, slug: `${newItem.slug}-${subCatItem.slug}` }
                    await updatePostSubCategory(newSubCat);
                }
            }
            return true;
        }
        function onError(results: any) {
            console.log("updatePostCategory results onError", results);

            return false;
        }

        promise.then(onFulfilled).catch(onError);

        return false;

    };

    const findOnePostCategory = async (id: string) => {
        let foundItem: PostCategory | null | undefined;
        let promise: Promise<GraphQLResult<any>>;

        promise = API.graphql(
            //fix this later
            graphqlOperation(findOnePostCategory, { id: id })
        );

        await promise
            .then((results) => {
                foundItem = results.data as PostCategory;
                console.log("findOnePostCategory foundItem", foundItem);
                return foundItem;
            })
            .catch((e) => {
                console.log("error findOnePostCategory", e);
                return null;
            });

        return null;
    };

    const listAllPostCategories = async () => {
        let foundItems: PostCategory[] | null | undefined;
        let promise: Promise<GraphQLResult<any>>;

        promise = API.graphql({
            query: listPostCategoriesGQL,
        });

        await promise
            .then((results) => {


                if (postCategoryStatusForLoadingListItems == DataLoadingState.LOADING) return
                if (postCategoryStatusForLoadingListItems == DataLoadingState.LOADED) return

                if (postCategoryStatusForLoadingListItems == DataLoadingState.PENDING) {
                    postCategorySetStatusForLoadingListItems(DataLoadingState.LOADING);
                }
                foundItems = results.data.listPostCategories.items as PostCategory[];
                console.log("listAllPostCategories", foundItems);
                postCategoryDataSetList(foundItems);
                postCategorySetStatusForLoadingListItems(DataLoadingState.LOADED)
                return foundItems;
            })
            .catch((e) => {
                console.log("error listPostCategories", e);
                postCategorySetStatusForLoadingListItems(DataLoadingState.ERROR)
                return null;
            });
        return null;
    };

    const deletePostCategory = async (item: PostCategory) => {
        let promise: Promise<GraphQLResult<any>>;
        //fix this later
        promise = API.graphql(
            graphqlOperation(deletePostCategory, { input: item })
        );

        await promise
            .then((_) => {
                postCategoryDataDelete(item);
                return true;
            })
            .catch((e) => {
                console.log("error deletePostCategory", e);
                return false;
            });
        return false;
    };

    //FIGURE OUT API
    const updatePostCategoryField = async (
        id: string,
        key: string,
        value: any
    ) => {
        return false;
    };

    useEffect(() => {
        const createSubscription = (
            API.graphql(
                graphqlOperation(subscriptions.onCreatePostCategory)
            ) as unknown as any
        ).subscribe({
            next: ({ provider, value }) => {
                console.log("onCreatePostCategory", { provider, value });

                if (addingNewItem) return;

                setAddingNewItem(true);

                const newItem = value.data.onCreatePostCategory as PostCategory;

                postCategoryDataAdd(newItem);

                setAddingNewItem(false);

            },
            error: (error: any) => {
                console.warn(error);
            },
        });

        const updateSubscription = (
            API.graphql(
                graphqlOperation(subscriptions.onUpdatePostCategory)
            ) as unknown as any
        ).subscribe({
            next: ({ provider, value }) => {
                console.log("onUpdatePostCategory", { provider, value });


                if (updatingNewItem) return;

                setUpdatingNewItem(true);

                const newItem = value.data.onUpdatePostCategory as PostCategory;

                postCategoryDataUpdate(newItem);

                setUpdatingNewItem(false);

            },
            error: (error: any) => {
                console.warn(error);
            },
        });

        const deleteSubscription = (
            API.graphql(
                graphqlOperation(subscriptions.onDeletePostCategory)
            ) as unknown as any
        ).subscribe({
            next: ({ provider, value }) => {
                console.log("onDeletePostCategory", { provider, value });


                if (deletingNewItem) return;

                setDeletingNewItem(true);

                const newItem = value.data.onDeletePostCategory as PostCategory;

                postCategoryDataDelete(newItem);

                setDeletingNewItem(false);

            },
            error: (error: any) => {
                console.warn(error);
            },
        });

        return () => {
            const cleanupSubscriptions = () => {
                createSubscription.unsubscribe();
                updateSubscription.unsubscribe();
                deleteSubscription.unsubscribe();
            };
            cleanupSubscriptions();
        };
    }, []);

    return {
        addNewPostCategory,
        updatePostCategory,
        updatePostCategoryField,
        deletePostCategory,
        findOnePostCategory,
        listAllPostCategories,
    };
}
