import * as Yup from "yup";
import { PageType } from "../../../models";
import { EditorType } from "../../../models";

export const SchemaForResearchActivities = Yup.object().shape({
  title: Yup.string()
    .min(1, "Too Short!")
    .max(500, "Too Long!")
    .label('Title')
    .required("Required").default(''),
  // coverImage: Yup.mixed<any>().optional(),
  slug: Yup.string().required("Required").default(''),
  pageDesignTextEditor: Yup.string()
    .optional().default(``),
  pageDesignPageBuilder: Yup.string()
    .optional().default(`{}`),
  pageDesignType: Yup.mixed<EditorType>().required().default(EditorType.TEXT_EDITOR),
  // tags: Yup.lazy(val => (Array.isArray(val) ? Yup.array().of(Yup.string()) : Yup.array().of(Yup.string())).optional()),
  // tags: Yup.array().optional(),
  status: Yup.string()
    .required("Required").default('draft'),
  datePublished: Yup.date().required("Required").default(new Date()),
  dateAdded: Yup.date().required("Required").default(new Date()),
  pageType: Yup.mixed<PageType>().required().default(PageType.RESEARCH_ACTIVITY)
});
