import produce from "immer";
import { create } from "zustand";
import { FormActionType, PostCategory } from "../../../../models";
import { PageDataInterfacePostCategoryForm } from "./data-interface-for-post-main-sub-categories";




const defaultData = {
  id: "",
  name: "",
  shortDescription: "",
  slug: "",
  // subCategories: [],
};


const useDataPostCategoryForm =
  create<PageDataInterfacePostCategoryForm>()((set, get) => {
    return {
      showDialog: false,
      setFormData: async (item) => {
        set((_) => ({ formData: item }));
        return true;
      },
      formActionType: undefined,
      setShowDialog: async (value) => {
        set((_) => ({ showDialog: value }));
        return true;
      },
      formData: defaultData as PostCategory,
      setFormActionType: async (item) => {
        if (item == FormActionType.NEW) {
          set((_) => ({ formData: defaultData as PostCategory }));
        }
        set((_) => ({ formActionType: item }));
        return true;
      },
      updateFormDataField: async (property, value) => {
        set(
          produce((state: PageDataInterfacePostCategoryForm) => {
            state.formData![property] = value;
          })
        );
        return true;
      },
    };
  });

export default useDataPostCategoryForm;
