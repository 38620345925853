/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateAppNotification = /* GraphQL */ `
  subscription OnCreateAppNotification(
    $filter: ModelSubscriptionAppNotificationFilterInput
  ) {
    onCreateAppNotification(filter: $filter) {
      id
      name
      title
      body
      receiversUserAccountsViews {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAppNotification = /* GraphQL */ `
  subscription OnUpdateAppNotification(
    $filter: ModelSubscriptionAppNotificationFilterInput
  ) {
    onUpdateAppNotification(filter: $filter) {
      id
      name
      title
      body
      receiversUserAccountsViews {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAppNotification = /* GraphQL */ `
  subscription OnDeleteAppNotification(
    $filter: ModelSubscriptionAppNotificationFilterInput
  ) {
    onDeleteAppNotification(filter: $filter) {
      id
      name
      title
      body
      receiversUserAccountsViews {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateContactFormMsg = /* GraphQL */ `
  subscription OnCreateContactFormMsg(
    $filter: ModelSubscriptionContactFormMsgFilterInput
  ) {
    onCreateContactFormMsg(filter: $filter) {
      id
      fullName
      emailAddress
      phoneNumber
      countryName
      countryIso3
      subject
      message
      status
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateContactFormMsg = /* GraphQL */ `
  subscription OnUpdateContactFormMsg(
    $filter: ModelSubscriptionContactFormMsgFilterInput
  ) {
    onUpdateContactFormMsg(filter: $filter) {
      id
      fullName
      emailAddress
      phoneNumber
      countryName
      countryIso3
      subject
      message
      status
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteContactFormMsg = /* GraphQL */ `
  subscription OnDeleteContactFormMsg(
    $filter: ModelSubscriptionContactFormMsgFilterInput
  ) {
    onDeleteContactFormMsg(filter: $filter) {
      id
      fullName
      emailAddress
      phoneNumber
      countryName
      countryIso3
      subject
      message
      status
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateHelpCenterPage = /* GraphQL */ `
  subscription OnCreateHelpCenterPage(
    $filter: ModelSubscriptionHelpCenterPageFilterInput
  ) {
    onCreateHelpCenterPage(filter: $filter) {
      id
      title
      icon
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      tags
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateHelpCenterPage = /* GraphQL */ `
  subscription OnUpdateHelpCenterPage(
    $filter: ModelSubscriptionHelpCenterPageFilterInput
  ) {
    onUpdateHelpCenterPage(filter: $filter) {
      id
      title
      icon
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      tags
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteHelpCenterPage = /* GraphQL */ `
  subscription OnDeleteHelpCenterPage(
    $filter: ModelSubscriptionHelpCenterPageFilterInput
  ) {
    onDeleteHelpCenterPage(filter: $filter) {
      id
      title
      icon
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      tags
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePostUserAccountViewDownload = /* GraphQL */ `
  subscription OnCreatePostUserAccountViewDownload(
    $filter: ModelSubscriptionPostUserAccountViewDownloadFilterInput
    $owner: String
  ) {
    onCreatePostUserAccountViewDownload(filter: $filter, owner: $owner) {
      id
      userAccountID
      userAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      pageType
      deleted
      postType
      postID
      appNotificationID
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      appNotification {
        id
        name
        title
        body
        receiversUserAccountsViews {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      downloadCount
      deliveriesCount
      viewCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onUpdatePostUserAccountViewDownload = /* GraphQL */ `
  subscription OnUpdatePostUserAccountViewDownload(
    $filter: ModelSubscriptionPostUserAccountViewDownloadFilterInput
    $owner: String
  ) {
    onUpdatePostUserAccountViewDownload(filter: $filter, owner: $owner) {
      id
      userAccountID
      userAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      pageType
      deleted
      postType
      postID
      appNotificationID
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      appNotification {
        id
        name
        title
        body
        receiversUserAccountsViews {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      downloadCount
      deliveriesCount
      viewCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onDeletePostUserAccountViewDownload = /* GraphQL */ `
  subscription OnDeletePostUserAccountViewDownload(
    $filter: ModelSubscriptionPostUserAccountViewDownloadFilterInput
    $owner: String
  ) {
    onDeletePostUserAccountViewDownload(filter: $filter, owner: $owner) {
      id
      userAccountID
      userAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      pageType
      deleted
      postType
      postID
      appNotificationID
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      appNotification {
        id
        name
        title
        body
        receiversUserAccountsViews {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      downloadCount
      deliveriesCount
      viewCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onCreateUserAccount = /* GraphQL */ `
  subscription OnCreateUserAccount(
    $filter: ModelSubscriptionUserAccountFilterInput
    $owner: String
  ) {
    onCreateUserAccount(filter: $filter, owner: $owner) {
      id
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      category
      deleted
      nameFirst
      nameLast
      nameFull
      authId
      status
      contactPhoneNumber1
      contactPhoneNumber2
      contactEmail1
      contactEmail2
      countryIso
      countryName
      staffProfileID
      userRoleID
      userRole {
        id
        title
        shortDescription
        moreDetailsTextEditor
        status
        userAccounts {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        assignedAccess {
          id
          userRoleID
          tableName
          routePath
          menuItemTitle
          operations
        }
        creatorUserAccountID
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFollowing
      dateLastLogin
      dateAdded
      dateAddedYear
      dateAddedMonth
      dateAddedDay
      dateUpdated
      dateUpdatedYear
      dateUpdatedMonth
      dateUpdatedDay
      postViewsDownloads {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      totalViewsCount
      totalPostDownloadsCount
      totalPostViewsCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onUpdateUserAccount = /* GraphQL */ `
  subscription OnUpdateUserAccount(
    $filter: ModelSubscriptionUserAccountFilterInput
    $owner: String
  ) {
    onUpdateUserAccount(filter: $filter, owner: $owner) {
      id
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      category
      deleted
      nameFirst
      nameLast
      nameFull
      authId
      status
      contactPhoneNumber1
      contactPhoneNumber2
      contactEmail1
      contactEmail2
      countryIso
      countryName
      staffProfileID
      userRoleID
      userRole {
        id
        title
        shortDescription
        moreDetailsTextEditor
        status
        userAccounts {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        assignedAccess {
          id
          userRoleID
          tableName
          routePath
          menuItemTitle
          operations
        }
        creatorUserAccountID
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFollowing
      dateLastLogin
      dateAdded
      dateAddedYear
      dateAddedMonth
      dateAddedDay
      dateUpdated
      dateUpdatedYear
      dateUpdatedMonth
      dateUpdatedDay
      postViewsDownloads {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      totalViewsCount
      totalPostDownloadsCount
      totalPostViewsCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onDeleteUserAccount = /* GraphQL */ `
  subscription OnDeleteUserAccount(
    $filter: ModelSubscriptionUserAccountFilterInput
    $owner: String
  ) {
    onDeleteUserAccount(filter: $filter, owner: $owner) {
      id
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      category
      deleted
      nameFirst
      nameLast
      nameFull
      authId
      status
      contactPhoneNumber1
      contactPhoneNumber2
      contactEmail1
      contactEmail2
      countryIso
      countryName
      staffProfileID
      userRoleID
      userRole {
        id
        title
        shortDescription
        moreDetailsTextEditor
        status
        userAccounts {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        assignedAccess {
          id
          userRoleID
          tableName
          routePath
          menuItemTitle
          operations
        }
        creatorUserAccountID
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFollowing
      dateLastLogin
      dateAdded
      dateAddedYear
      dateAddedMonth
      dateAddedDay
      dateUpdated
      dateUpdatedYear
      dateUpdatedMonth
      dateUpdatedDay
      postViewsDownloads {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      totalViewsCount
      totalPostDownloadsCount
      totalPostViewsCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onCreateUserRole = /* GraphQL */ `
  subscription OnCreateUserRole($filter: ModelSubscriptionUserRoleFilterInput) {
    onCreateUserRole(filter: $filter) {
      id
      title
      shortDescription
      moreDetailsTextEditor
      status
      userAccounts {
        items {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      assignedAccess {
        id
        userRoleID
        tableName
        routePath
        menuItemTitle
        operations
      }
      creatorUserAccountID
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUserRole = /* GraphQL */ `
  subscription OnUpdateUserRole($filter: ModelSubscriptionUserRoleFilterInput) {
    onUpdateUserRole(filter: $filter) {
      id
      title
      shortDescription
      moreDetailsTextEditor
      status
      userAccounts {
        items {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      assignedAccess {
        id
        userRoleID
        tableName
        routePath
        menuItemTitle
        operations
      }
      creatorUserAccountID
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUserRole = /* GraphQL */ `
  subscription OnDeleteUserRole($filter: ModelSubscriptionUserRoleFilterInput) {
    onDeleteUserRole(filter: $filter) {
      id
      title
      shortDescription
      moreDetailsTextEditor
      status
      userAccounts {
        items {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      assignedAccess {
        id
        userRoleID
        tableName
        routePath
        menuItemTitle
        operations
      }
      creatorUserAccountID
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateArticlePublication = /* GraphQL */ `
  subscription OnCreateArticlePublication(
    $filter: ModelSubscriptionArticlePublicationFilterInput
  ) {
    onCreateArticlePublication(filter: $filter) {
      id
      title
      shortDescription
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      tags
      authorStaffProfiles {
        items {
          id
          articlePublicationId
          staffProfileId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      authorStaffProfilesIds
      publishersIds
      publishers {
        items {
          id
          articlePublicationId
          articlePublicationPublisherId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      deleted
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalDownloadsCount
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postSubCategoryID
      postSubCategory {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      volumeNumber
      editionNumber
      issueNumber
      issn
      userViewsDownloads {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      visibilityType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateArticlePublication = /* GraphQL */ `
  subscription OnUpdateArticlePublication(
    $filter: ModelSubscriptionArticlePublicationFilterInput
  ) {
    onUpdateArticlePublication(filter: $filter) {
      id
      title
      shortDescription
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      tags
      authorStaffProfiles {
        items {
          id
          articlePublicationId
          staffProfileId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      authorStaffProfilesIds
      publishersIds
      publishers {
        items {
          id
          articlePublicationId
          articlePublicationPublisherId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      deleted
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalDownloadsCount
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postSubCategoryID
      postSubCategory {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      volumeNumber
      editionNumber
      issueNumber
      issn
      userViewsDownloads {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      visibilityType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteArticlePublication = /* GraphQL */ `
  subscription OnDeleteArticlePublication(
    $filter: ModelSubscriptionArticlePublicationFilterInput
  ) {
    onDeleteArticlePublication(filter: $filter) {
      id
      title
      shortDescription
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      tags
      authorStaffProfiles {
        items {
          id
          articlePublicationId
          staffProfileId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      authorStaffProfilesIds
      publishersIds
      publishers {
        items {
          id
          articlePublicationId
          articlePublicationPublisherId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      deleted
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalDownloadsCount
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postSubCategoryID
      postSubCategory {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      volumeNumber
      editionNumber
      issueNumber
      issn
      userViewsDownloads {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      visibilityType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateArticlePublicationPublisher = /* GraphQL */ `
  subscription OnCreateArticlePublicationPublisher(
    $filter: ModelSubscriptionArticlePublicationPublisherFilterInput
  ) {
    onCreateArticlePublicationPublisher(filter: $filter) {
      id
      name
      status
      articlesPublicationsPublished {
        items {
          id
          articlePublicationId
          articlePublicationPublisherId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateArticlePublicationPublisher = /* GraphQL */ `
  subscription OnUpdateArticlePublicationPublisher(
    $filter: ModelSubscriptionArticlePublicationPublisherFilterInput
  ) {
    onUpdateArticlePublicationPublisher(filter: $filter) {
      id
      name
      status
      articlesPublicationsPublished {
        items {
          id
          articlePublicationId
          articlePublicationPublisherId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteArticlePublicationPublisher = /* GraphQL */ `
  subscription OnDeleteArticlePublicationPublisher(
    $filter: ModelSubscriptionArticlePublicationPublisherFilterInput
  ) {
    onDeleteArticlePublicationPublisher(filter: $filter) {
      id
      name
      status
      articlesPublicationsPublished {
        items {
          id
          articlePublicationId
          articlePublicationPublisherId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateCrigStaffGroup = /* GraphQL */ `
  subscription OnCreateCrigStaffGroup(
    $filter: ModelSubscriptionCrigStaffGroupFilterInput
  ) {
    onCreateCrigStaffGroup(filter: $filter) {
      id
      deleted
      name
      shortDescription
      status
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalStaff
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateCrigStaffGroup = /* GraphQL */ `
  subscription OnUpdateCrigStaffGroup(
    $filter: ModelSubscriptionCrigStaffGroupFilterInput
  ) {
    onUpdateCrigStaffGroup(filter: $filter) {
      id
      deleted
      name
      shortDescription
      status
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalStaff
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteCrigStaffGroup = /* GraphQL */ `
  subscription OnDeleteCrigStaffGroup(
    $filter: ModelSubscriptionCrigStaffGroupFilterInput
  ) {
    onDeleteCrigStaffGroup(filter: $filter) {
      id
      deleted
      name
      shortDescription
      status
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalStaff
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateCrigUnitDepartment = /* GraphQL */ `
  subscription OnCreateCrigUnitDepartment(
    $filter: ModelSubscriptionCrigUnitDepartmentFilterInput
  ) {
    onCreateCrigUnitDepartment(filter: $filter) {
      id
      name
      shortDescription
      deleted
      slug
      dateUploaded
      datePublished
      totalStaff
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      crigDivisionSubstationID
      crigDivisionSubstation {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateCrigUnitDepartment = /* GraphQL */ `
  subscription OnUpdateCrigUnitDepartment(
    $filter: ModelSubscriptionCrigUnitDepartmentFilterInput
  ) {
    onUpdateCrigUnitDepartment(filter: $filter) {
      id
      name
      shortDescription
      deleted
      slug
      dateUploaded
      datePublished
      totalStaff
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      crigDivisionSubstationID
      crigDivisionSubstation {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteCrigUnitDepartment = /* GraphQL */ `
  subscription OnDeleteCrigUnitDepartment(
    $filter: ModelSubscriptionCrigUnitDepartmentFilterInput
  ) {
    onDeleteCrigUnitDepartment(filter: $filter) {
      id
      name
      shortDescription
      deleted
      slug
      dateUploaded
      datePublished
      totalStaff
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      crigDivisionSubstationID
      crigDivisionSubstation {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateFaq = /* GraphQL */ `
  subscription OnCreateFaq($filter: ModelSubscriptionFaqFilterInput) {
    onCreateFaq(filter: $filter) {
      id
      title
      icon
      status
      faqItems {
        items {
          id
          title
          body
          status
          faqID
          dateAdded
          dateUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateFaq = /* GraphQL */ `
  subscription OnUpdateFaq($filter: ModelSubscriptionFaqFilterInput) {
    onUpdateFaq(filter: $filter) {
      id
      title
      icon
      status
      faqItems {
        items {
          id
          title
          body
          status
          faqID
          dateAdded
          dateUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteFaq = /* GraphQL */ `
  subscription OnDeleteFaq($filter: ModelSubscriptionFaqFilterInput) {
    onDeleteFaq(filter: $filter) {
      id
      title
      icon
      status
      faqItems {
        items {
          id
          title
          body
          status
          faqID
          dateAdded
          dateUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateFaqItem = /* GraphQL */ `
  subscription OnCreateFaqItem($filter: ModelSubscriptionFaqItemFilterInput) {
    onCreateFaqItem(filter: $filter) {
      id
      title
      body
      status
      faqID
      faq {
        id
        title
        icon
        status
        faqItems {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateFaqItem = /* GraphQL */ `
  subscription OnUpdateFaqItem($filter: ModelSubscriptionFaqItemFilterInput) {
    onUpdateFaqItem(filter: $filter) {
      id
      title
      body
      status
      faqID
      faq {
        id
        title
        icon
        status
        faqItems {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteFaqItem = /* GraphQL */ `
  subscription OnDeleteFaqItem($filter: ModelSubscriptionFaqItemFilterInput) {
    onDeleteFaqItem(filter: $filter) {
      id
      title
      body
      status
      faqID
      faq {
        id
        title
        icon
        status
        faqItems {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateFolder = /* GraphQL */ `
  subscription OnCreateFolder($filter: ModelSubscriptionFolderFilterInput) {
    onCreateFolder(filter: $filter) {
      id
      title
      color
      status
      pageType
      slug
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateFolder = /* GraphQL */ `
  subscription OnUpdateFolder($filter: ModelSubscriptionFolderFilterInput) {
    onUpdateFolder(filter: $filter) {
      id
      title
      color
      status
      pageType
      slug
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteFolder = /* GraphQL */ `
  subscription OnDeleteFolder($filter: ModelSubscriptionFolderFilterInput) {
    onDeleteFolder(filter: $filter) {
      id
      title
      color
      status
      pageType
      slug
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePage = /* GraphQL */ `
  subscription OnCreatePage($filter: ModelSubscriptionPageFilterInput) {
    onCreatePage(filter: $filter) {
      id
      title
      shortDescription
      details
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      status
      totalViewsCount
      totalStaffCount
      totalDownloadsCount
      totalDeliveriesCount
      pageType
      tags
      slug
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postSubCategoryID
      postSubCategory {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      unitDepartments {
        items {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      dateAdded
      dateSent
      dateUpdated
      datePublished
      deleted
      listPosition
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePage = /* GraphQL */ `
  subscription OnUpdatePage($filter: ModelSubscriptionPageFilterInput) {
    onUpdatePage(filter: $filter) {
      id
      title
      shortDescription
      details
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      status
      totalViewsCount
      totalStaffCount
      totalDownloadsCount
      totalDeliveriesCount
      pageType
      tags
      slug
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postSubCategoryID
      postSubCategory {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      unitDepartments {
        items {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      dateAdded
      dateSent
      dateUpdated
      datePublished
      deleted
      listPosition
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePage = /* GraphQL */ `
  subscription OnDeletePage($filter: ModelSubscriptionPageFilterInput) {
    onDeletePage(filter: $filter) {
      id
      title
      shortDescription
      details
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      status
      totalViewsCount
      totalStaffCount
      totalDownloadsCount
      totalDeliveriesCount
      pageType
      tags
      slug
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postSubCategoryID
      postSubCategory {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      unitDepartments {
        items {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      dateAdded
      dateSent
      dateUpdated
      datePublished
      deleted
      listPosition
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePageDesignTemplate = /* GraphQL */ `
  subscription OnCreatePageDesignTemplate(
    $filter: ModelSubscriptionPageDesignTemplateFilterInput
  ) {
    onCreatePageDesignTemplate(filter: $filter) {
      id
      title
      description
      templateDesign
      status
      type
      deleted
      dateAdded
      datePublished
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePageDesignTemplate = /* GraphQL */ `
  subscription OnUpdatePageDesignTemplate(
    $filter: ModelSubscriptionPageDesignTemplateFilterInput
  ) {
    onUpdatePageDesignTemplate(filter: $filter) {
      id
      title
      description
      templateDesign
      status
      type
      deleted
      dateAdded
      datePublished
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePageDesignTemplate = /* GraphQL */ `
  subscription OnDeletePageDesignTemplate(
    $filter: ModelSubscriptionPageDesignTemplateFilterInput
  ) {
    onDeletePageDesignTemplate(filter: $filter) {
      id
      title
      description
      templateDesign
      status
      type
      deleted
      dateAdded
      datePublished
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePostCategory = /* GraphQL */ `
  subscription OnCreatePostCategory(
    $filter: ModelSubscriptionPostCategoryFilterInput
  ) {
    onCreatePostCategory(filter: $filter) {
      id
      name
      shortDescription
      slug
      dateUploaded
      deleted
      datePublished
      totalPostsCount
      totalSubCategoriesCount
      articlesPublications {
        items {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pages {
        items {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      subCategories {
        items {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePostCategory = /* GraphQL */ `
  subscription OnUpdatePostCategory(
    $filter: ModelSubscriptionPostCategoryFilterInput
  ) {
    onUpdatePostCategory(filter: $filter) {
      id
      name
      shortDescription
      slug
      dateUploaded
      deleted
      datePublished
      totalPostsCount
      totalSubCategoriesCount
      articlesPublications {
        items {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pages {
        items {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      subCategories {
        items {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePostCategory = /* GraphQL */ `
  subscription OnDeletePostCategory(
    $filter: ModelSubscriptionPostCategoryFilterInput
  ) {
    onDeletePostCategory(filter: $filter) {
      id
      name
      shortDescription
      slug
      dateUploaded
      deleted
      datePublished
      totalPostsCount
      totalSubCategoriesCount
      articlesPublications {
        items {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pages {
        items {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      subCategories {
        items {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePostSubCategory = /* GraphQL */ `
  subscription OnCreatePostSubCategory(
    $filter: ModelSubscriptionPostSubCategoryFilterInput
  ) {
    onCreatePostSubCategory(filter: $filter) {
      id
      name
      slug
      dateUploaded
      datePublished
      totalPosts
      deleted
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      articlesPublications {
        items {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pages {
        items {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePostSubCategory = /* GraphQL */ `
  subscription OnUpdatePostSubCategory(
    $filter: ModelSubscriptionPostSubCategoryFilterInput
  ) {
    onUpdatePostSubCategory(filter: $filter) {
      id
      name
      slug
      dateUploaded
      datePublished
      totalPosts
      deleted
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      articlesPublications {
        items {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pages {
        items {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePostSubCategory = /* GraphQL */ `
  subscription OnDeletePostSubCategory(
    $filter: ModelSubscriptionPostSubCategoryFilterInput
  ) {
    onDeletePostSubCategory(filter: $filter) {
      id
      name
      slug
      dateUploaded
      datePublished
      totalPosts
      deleted
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      articlesPublications {
        items {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pages {
        items {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSiteStats = /* GraphQL */ `
  subscription OnCreateSiteStats(
    $filter: ModelSubscriptionSiteStatsFilterInput
  ) {
    onCreateSiteStats(filter: $filter) {
      id
      researchActivities {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      products {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      aboutUs {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      externalServices {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      recreationFacilities {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      divisionsSubStations {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      articleAndPublications {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      eventsAndGallery {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      newsAndAnnouncements {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      newsletters {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      staff {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSiteStats = /* GraphQL */ `
  subscription OnUpdateSiteStats(
    $filter: ModelSubscriptionSiteStatsFilterInput
  ) {
    onUpdateSiteStats(filter: $filter) {
      id
      researchActivities {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      products {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      aboutUs {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      externalServices {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      recreationFacilities {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      divisionsSubStations {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      articleAndPublications {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      eventsAndGallery {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      newsAndAnnouncements {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      newsletters {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      staff {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSiteStats = /* GraphQL */ `
  subscription OnDeleteSiteStats(
    $filter: ModelSubscriptionSiteStatsFilterInput
  ) {
    onDeleteSiteStats(filter: $filter) {
      id
      researchActivities {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      products {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      aboutUs {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      externalServices {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      recreationFacilities {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      divisionsSubStations {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      articleAndPublications {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      eventsAndGallery {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      newsAndAnnouncements {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      newsletters {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      staff {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSlide = /* GraphQL */ `
  subscription OnCreateSlide($filter: ModelSubscriptionSlideFilterInput) {
    onCreateSlide(filter: $filter) {
      id
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postCategoryName
      postSubCategoryName
      slideTitle
      slidePosition
      slidePageURL
      pageType
      isSidePop
      postID
      postSlug
      postCoverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      status
      dateAdded
      dateUpdated
      deleted
      totalClicksCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSlide = /* GraphQL */ `
  subscription OnUpdateSlide($filter: ModelSubscriptionSlideFilterInput) {
    onUpdateSlide(filter: $filter) {
      id
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postCategoryName
      postSubCategoryName
      slideTitle
      slidePosition
      slidePageURL
      pageType
      isSidePop
      postID
      postSlug
      postCoverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      status
      dateAdded
      dateUpdated
      deleted
      totalClicksCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSlide = /* GraphQL */ `
  subscription OnDeleteSlide($filter: ModelSubscriptionSlideFilterInput) {
    onDeleteSlide(filter: $filter) {
      id
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postCategoryName
      postSubCategoryName
      slideTitle
      slidePosition
      slidePageURL
      pageType
      isSidePop
      postID
      postSlug
      postCoverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      status
      dateAdded
      dateUpdated
      deleted
      totalClicksCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateStaffProfile = /* GraphQL */ `
  subscription OnCreateStaffProfile(
    $filter: ModelSubscriptionStaffProfileFilterInput
  ) {
    onCreateStaffProfile(filter: $filter) {
      id
      listPosition
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      nameFirst
      nameLast
      deleted
      nameFull
      slug
      crigDivisionSubStationID
      crigDivisionSubStation {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      crigStaffGroupID
      crigStaffGroup {
        id
        deleted
        name
        shortDescription
        status
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      crigUnitDepartmentID
      crigUnitDepartment {
        id
        name
        shortDescription
        deleted
        slug
        dateUploaded
        datePublished
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        crigDivisionSubstationID
        crigDivisionSubstation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      position
      isAContentFollower
      userAccountID
      bioDesignType
      bioDesignPageBuilder
      bioDesignTextEditors {
        title
        body
      }
      status
      dateAdded
      dateUpdated
      articlesPublicationsAuthored {
        items {
          id
          articlePublicationId
          staffProfileId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      totalViewsCount
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateStaffProfile = /* GraphQL */ `
  subscription OnUpdateStaffProfile(
    $filter: ModelSubscriptionStaffProfileFilterInput
  ) {
    onUpdateStaffProfile(filter: $filter) {
      id
      listPosition
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      nameFirst
      nameLast
      deleted
      nameFull
      slug
      crigDivisionSubStationID
      crigDivisionSubStation {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      crigStaffGroupID
      crigStaffGroup {
        id
        deleted
        name
        shortDescription
        status
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      crigUnitDepartmentID
      crigUnitDepartment {
        id
        name
        shortDescription
        deleted
        slug
        dateUploaded
        datePublished
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        crigDivisionSubstationID
        crigDivisionSubstation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      position
      isAContentFollower
      userAccountID
      bioDesignType
      bioDesignPageBuilder
      bioDesignTextEditors {
        title
        body
      }
      status
      dateAdded
      dateUpdated
      articlesPublicationsAuthored {
        items {
          id
          articlePublicationId
          staffProfileId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      totalViewsCount
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteStaffProfile = /* GraphQL */ `
  subscription OnDeleteStaffProfile(
    $filter: ModelSubscriptionStaffProfileFilterInput
  ) {
    onDeleteStaffProfile(filter: $filter) {
      id
      listPosition
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      nameFirst
      nameLast
      deleted
      nameFull
      slug
      crigDivisionSubStationID
      crigDivisionSubStation {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      crigStaffGroupID
      crigStaffGroup {
        id
        deleted
        name
        shortDescription
        status
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      crigUnitDepartmentID
      crigUnitDepartment {
        id
        name
        shortDescription
        deleted
        slug
        dateUploaded
        datePublished
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        crigDivisionSubstationID
        crigDivisionSubstation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      position
      isAContentFollower
      userAccountID
      bioDesignType
      bioDesignPageBuilder
      bioDesignTextEditors {
        title
        body
      }
      status
      dateAdded
      dateUpdated
      articlesPublicationsAuthored {
        items {
          id
          articlePublicationId
          staffProfileId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      totalViewsCount
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateArticlePublicationsAuthors = /* GraphQL */ `
  subscription OnCreateArticlePublicationsAuthors(
    $filter: ModelSubscriptionArticlePublicationsAuthorsFilterInput
  ) {
    onCreateArticlePublicationsAuthors(filter: $filter) {
      id
      articlePublicationId
      staffProfileId
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      staffProfile {
        id
        listPosition
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        nameFirst
        nameLast
        deleted
        nameFull
        slug
        crigDivisionSubStationID
        crigDivisionSubStation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigStaffGroupID
        crigStaffGroup {
          id
          deleted
          name
          shortDescription
          status
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalStaff
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigUnitDepartmentID
        crigUnitDepartment {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        position
        isAContentFollower
        userAccountID
        bioDesignType
        bioDesignPageBuilder
        bioDesignTextEditors {
          title
          body
        }
        status
        dateAdded
        dateUpdated
        articlesPublicationsAuthored {
          nextToken
          startedAt
        }
        totalViewsCount
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateArticlePublicationsAuthors = /* GraphQL */ `
  subscription OnUpdateArticlePublicationsAuthors(
    $filter: ModelSubscriptionArticlePublicationsAuthorsFilterInput
  ) {
    onUpdateArticlePublicationsAuthors(filter: $filter) {
      id
      articlePublicationId
      staffProfileId
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      staffProfile {
        id
        listPosition
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        nameFirst
        nameLast
        deleted
        nameFull
        slug
        crigDivisionSubStationID
        crigDivisionSubStation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigStaffGroupID
        crigStaffGroup {
          id
          deleted
          name
          shortDescription
          status
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalStaff
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigUnitDepartmentID
        crigUnitDepartment {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        position
        isAContentFollower
        userAccountID
        bioDesignType
        bioDesignPageBuilder
        bioDesignTextEditors {
          title
          body
        }
        status
        dateAdded
        dateUpdated
        articlesPublicationsAuthored {
          nextToken
          startedAt
        }
        totalViewsCount
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteArticlePublicationsAuthors = /* GraphQL */ `
  subscription OnDeleteArticlePublicationsAuthors(
    $filter: ModelSubscriptionArticlePublicationsAuthorsFilterInput
  ) {
    onDeleteArticlePublicationsAuthors(filter: $filter) {
      id
      articlePublicationId
      staffProfileId
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      staffProfile {
        id
        listPosition
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        nameFirst
        nameLast
        deleted
        nameFull
        slug
        crigDivisionSubStationID
        crigDivisionSubStation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigStaffGroupID
        crigStaffGroup {
          id
          deleted
          name
          shortDescription
          status
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalStaff
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigUnitDepartmentID
        crigUnitDepartment {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        position
        isAContentFollower
        userAccountID
        bioDesignType
        bioDesignPageBuilder
        bioDesignTextEditors {
          title
          body
        }
        status
        dateAdded
        dateUpdated
        articlesPublicationsAuthored {
          nextToken
          startedAt
        }
        totalViewsCount
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateArticlePublicationsPublishers = /* GraphQL */ `
  subscription OnCreateArticlePublicationsPublishers(
    $filter: ModelSubscriptionArticlePublicationsPublishersFilterInput
  ) {
    onCreateArticlePublicationsPublishers(filter: $filter) {
      id
      articlePublicationId
      articlePublicationPublisherId
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      articlePublicationPublisher {
        id
        name
        status
        articlesPublicationsPublished {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateArticlePublicationsPublishers = /* GraphQL */ `
  subscription OnUpdateArticlePublicationsPublishers(
    $filter: ModelSubscriptionArticlePublicationsPublishersFilterInput
  ) {
    onUpdateArticlePublicationsPublishers(filter: $filter) {
      id
      articlePublicationId
      articlePublicationPublisherId
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      articlePublicationPublisher {
        id
        name
        status
        articlesPublicationsPublished {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteArticlePublicationsPublishers = /* GraphQL */ `
  subscription OnDeleteArticlePublicationsPublishers(
    $filter: ModelSubscriptionArticlePublicationsPublishersFilterInput
  ) {
    onDeleteArticlePublicationsPublishers(filter: $filter) {
      id
      articlePublicationId
      articlePublicationPublisherId
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      articlePublicationPublisher {
        id
        name
        status
        articlesPublicationsPublished {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
