import produce from "immer";
import { create } from "zustand";
import { EditorType, Page, PageType, RecordStatus } from "../../../models";
import { PageDataInterfaceAboutUsForm } from "./data-interface-for-about-us";
import { v4 as getId } from "uuid";


const defaultData = {
  pageDesignType: EditorType.TEXT_EDITOR,
  pageDesignTextEditor: "",
  id: getId(),
  pageType: PageType.ABOUT_US,
  pageDesignPageBuilder: "",
  title: "",
  coverImage: null,
  status: RecordStatus.DRAFT,
  datePublished: new Date().toISOString(),
  dateUpdated: new Date().toISOString(),
  dateAdded: new Date().toISOString(),
};

const useDataAboutUsForm = create<PageDataInterfaceAboutUsForm>()(
  (set, get) => {
    return {
      dataItem: undefined,
      setDataItem: async (item) => {
        set((_) => ({ dataItem: item }));
        return true;
      },
      showDialog: false,
      setFormData: async (item) => {
        set((_) => ({ formData: item }));
        return true;
      },
      formActionType: undefined,
      setShowDialog: async (value) => {
        set((_) => ({ showDialog: value }));
        return true;
      },
      formData: defaultData as unknown as Page,
      setFormActionType: async (item) => {
        set((_) => ({ formActionType: item }));
        return true;
      },
      reset: async () => {
        const newData = { ...defaultData, id: getId() }
        set((_) => ({ formData: newData as unknown as Page }));
        return true;
      },
      updateFormDataField: async (property, value) => {
        set(
          produce((state: PageDataInterfaceAboutUsForm) => {
            state.formData![property] = value;
          })
        );
        return true;
      },
    };
  }
);

export default useDataAboutUsForm;
