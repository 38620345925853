import produce from "immer";
import { create } from "zustand";
import { Page } from "../../../models";
import { PageDataInterfaceNewsAnnouncementForm } from "./data-interface-for-news-announcement";
import { EditorType, PageType, RecordStatus } from "../../../models";

const defaultData = {
  pageDesignType: EditorType.TEXT_EDITOR,
  pageDesignTextEditor: '',
  pageType: PageType.NEWS_ANNOUNCEMENT,
  pageDesignPageBuilder: '',
  title: '',
  postSubCategoryID: '',
  postCategoryID: '',
  galleryImages: null,
  coverImage: null,
  status: RecordStatus.DRAFT,
  datePublished: new Date().toISOString(),
  dateAdded: new Date().toISOString(),
};

const useDataNewsAnnouncementForm =
  create<PageDataInterfaceNewsAnnouncementForm>()((set, get) => {
    return {
      showDialog: false,
      setFormData: async (item) => {
        set((_) => ({ formData: item }));
        return true;
      },
      formActionType: undefined,
      setShowDialog: async (value) => {
        set((_) => ({ showDialog: value }));
        return true;
      },
      formData: defaultData as unknown as Page,
      setFormActionType: async (item) => {
        set((_) => ({ formActionType: item }));
        return true;
      },
      reset: async () => {
        set((_) => ({ formData: defaultData as unknown as Page }));
        return true;
      },
      updateFormDataField: async (property, value) => {
        set(
          produce((state: PageDataInterfaceNewsAnnouncementForm) => {
            state.formData![property] = value;
          })
        );
        return true;
      },
    };
  });

export default useDataNewsAnnouncementForm;
