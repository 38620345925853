import { useEffect } from "react";
import { DataLoadingState, Page, PageType } from "../../../models";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataForUserAuth from "../../../store/auth/data-for-auth";
import useDataAboutUsForm from "../data/data-for-about-us-form";
import useDataAboutUsList from "../data/data-for-about-us-list";
import { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../../../graphql/subscriptions';
import { createPageGQL, deletePageGQL, getPageGQL, listPagesGQL, updatePageGQL } from "../../../custom-graphql/custom-gql-page";
import isDevMode from "../../../utils/check-dev-mode";
import useDataForGalleryImagesFile from "../../../shared-components/images/data-gallery-images";
import { deleteFieldsForSave } from "../../../utils/forms/util-forms";

interface ServiceInterfaceForAboutUs {
    addNewData: (item: Page) => Promise<boolean>;
    updateData: (item: Page, isDelete: boolean) => Promise<boolean | Page>;
    updateDataField: (
        id: string,
        key: string,
        value: any
    ) => Promise<boolean>;
    deleteData: (item: Page) => Promise<boolean>;
    findOneData: (id: string) => Promise<Page | null>;
    listAllData: () => Promise<boolean | null>;
}

const pageModelName = 'about-us';

export function useServiceForAboutUsAPI(): ServiceInterfaceForAboutUs {
    const pageDataAdd = useDataAboutUsList((state) => state.addItem);
    const loggedUser = useDataForUserAuth((state) => state.loggedUser);
    const setFormData = useDataAboutUsForm((state) => state.setFormData);
    const formData = useDataAboutUsForm((state) => state.formData);
    const pageDataSetStatusForLoading = useDataAboutUsList((state) => state.setStatusForLoadingListItems);
    const coverImageGetS3URL = useDataForCoverImageFile((state) => state.getS3URL);
    const galleryImageGetS3URL = useDataForGalleryImagesFile((state) => state.getS3URL);


    const pageDataSetList = useDataAboutUsList(
        (state) => state.setListItems
    );
    const pageDataUpdate = useDataAboutUsList(
        (state) => state.updateItem
    );
    const pageDataDelete = useDataAboutUsList(
        (state) => state.deleteItem
    );

    const addNewData = async (item: Page) => {
        let newItem: Page;
        let dataResult: Page;

        newItem = {
            ...item,
            dateUpdated: new Date().toISOString(),
            creatorUserAccountID: loggedUser!.userAccount!.id,
        };


        newItem = deleteFieldsForSave({ pageModelName, newItem });

        if (isDevMode()) {
            console.log(`addNew ${pageModelName} newItem to save`, newItem);
        }

        try {
            const results: any = await API.graphql(graphqlOperation(createPageGQL, { input: newItem }));
            console.log(`addNew ${pageModelName} results`, results);
            dataResult = results.data.createPage;

            return true;
        } catch (error) {
            console.log(`addNew ${pageModelName} onError`, error);
            return false;
        }
    };

    const updateData = async (item: Page, isDelete: boolean) => {
        let newItem: Page;
        let dataResult: Page;
        newItem = {
            ...item,
            dateUpdated: new Date().toISOString(),
        };

        if (isDelete) {
            newItem = {
                ...item,
                deleted: true,
            };
        }

        newItem = deleteFieldsForSave({ pageModelName, newItem });

        if (isDevMode()) {
            console.log(`addNew ${pageModelName} newItem to update`, newItem);
        }

        try {
            const results: any = await API.graphql(graphqlOperation(updatePageGQL, { input: newItem }));
            console.log(`update ${pageModelName} results`, results);
            dataResult = results.data.updatePage;
            if (isDelete) {
                return dataResult;
            } else {

                return true;
            }

        } catch (error) {
            console.log(`update ${pageModelName} onError`, error);
            return false;
        }


    };


    const listAllData = async () => {

        let foundItems: Page[] | null | undefined;

        const variables = {
            filter: {
                and: [
                    { pageType: { eq: PageType.ABOUT_US } },
                    {
                        deleted: {
                            eq: false,
                        },
                    },
                ],

            }
        };


        try {
            const results: any = await API.graphql({
                query: listPagesGQL,
                variables: variables
            });
            foundItems = results.data.listPages.items as Page[]


            if (foundItems.length > 0) {
                for (var item of foundItems) {
                    let index = -1;
                    const coverImage = item.coverImage;
                    const galleryImages = item.galleryImages;
                    let coverImageUrl: string | null = '';

                    function findItemIndex(listItem: Page) {
                        return listItem.id == item.id;
                    }


                    index = foundItems.findIndex(findItemIndex)

                    if (coverImage != null) {
                        coverImageUrl = await coverImageGetS3URL(coverImage.key);
                        if (coverImageUrl != null) {
                            item = { ...item, coverImage: { ...item.coverImage!, signedURL: coverImageUrl } }
                            foundItems[index] = item;
                        }
                    }
                    if (galleryImages != null) {
                        for (var galleryItem of galleryImages) {
                            let galleryURL: string | null = '';
                            let galleryItemIndex = galleryImages.indexOf(galleryItem);
                            galleryURL = await galleryImageGetS3URL(galleryItem!.key);

                            if (galleryURL != null) {
                                galleryItem = { ...galleryItem!, signedURL: galleryURL }
                                galleryImages[galleryItemIndex] = galleryItem;
                            }
                        }
                        item = { ...item, galleryImages: galleryImages }
                        foundItems[index] = item;

                    }

                }

            }

            if (isDevMode()) {
                console.log(`listAll ${pageModelName} foundItems`, foundItems);
            }

            foundItems.sort((a, b) => (a.listPosition ?? 0) - (b.listPosition ?? 0))
            pageDataSetList(foundItems)
            pageDataSetStatusForLoading(DataLoadingState.LOADED)
            return true

        } catch (error) {
            if (isDevMode()) {
                console.log(`listAll ${pageModelName} error`, error);
            }

            return false;
        }


    };


    const findOneData = async (id: string) => {
        let foundItem: Page | null;
        try {
            const results: any = await API.graphql(graphqlOperation(getPageGQL, { id: id }));

            if (isDevMode()) {
                console.log(`findOneData ${pageModelName} results`, results);
            }

            foundItem = results.data.getPage;

            return foundItem;

        } catch (error) {
            if (isDevMode()) {
                console.log(`findOneData ${pageModelName} onError`, error);
            }

            return null;
        }

    };

    const deleteData = async (item: any) => {
        let dataResult: Page;
        console.log(item)
        try {
            item = { ...item, deleted: true };

            const results: any = await API.graphql(graphqlOperation(deletePageGQL, { input: { id: item.id, _version: item._version, } }));

            if (isDevMode()) {
                console.log(`delete ${pageModelName} results`, results);
            }


            dataResult = results.data.deletePage;

            await updateData(dataResult, true);

            return true;

        } catch (error) {
            if (isDevMode()) {
                console.log(`update ${pageModelName} onError`, error);
            }

            return false;
        }

    };


    //FIGURE OUT API
    const updateDataField = async (
        id: string,
        key: string,
        value: any
    ) => {
        return false;
    };



    useEffect(() => {

        const createSubscription = (API.graphql(
            graphqlOperation(subscriptions.onCreatePage, {
                filter: {
                    pageType: { eq: PageType.ABOUT_US }
                }
            })
        ) as unknown as any).subscribe({
            next: async ({ provider, value }) => {
                if (isDevMode()) {
                    console.log({ provider, value })
                }


                let data = value.data.onCreatePage as Page;
                let url: string | null = ''
                if (data.coverImage != null) {
                    url = await coverImageGetS3URL(data.coverImage.key);
                    if (url != null) {
                        data = { ...data, coverImage: { ...data.coverImage!, signedURL: url } }
                    }
                }

                pageDataAdd(data);
                if (formData?.id == data.id) {
                    setFormData(data);
                }

            },
            error: (error: any) => {
                if (isDevMode()) {
                    console.warn(error)
                }


            }
        });

        const updateSubscription = (API.graphql(
            graphqlOperation(subscriptions.onUpdatePage, {
                filter: {
                    pageType: { eq: PageType.ABOUT_US }
                }
            })
        ) as unknown as any).subscribe({
            next: async ({ provider, value }) => {
                console.log({ provider, value })
                let data = value.data.onUpdatePage as Page;
                let url: string | null = ''
                if (data.coverImage != null) {
                    url = await coverImageGetS3URL(data.coverImage.key);
                    if (url != null) {
                        data = { ...data, coverImage: { ...data.coverImage!, signedURL: url } }
                    }
                }

                pageDataUpdate(data);
                if (formData?.id == data.id) {
                    setFormData(data);
                }
            },
            error: (error: any) => {
                console.warn(error)
            }
        });


        const deleteSubscription = (API.graphql(
            graphqlOperation(subscriptions.onDeletePage, {
                filter: {
                    pageType: { eq: PageType.ABOUT_US }
                }
            })
        ) as unknown as any).subscribe({
            next: ({ provider, value }) => {
                console.log({ provider, value })
                pageDataDelete(value.data.onDeletePage as Page);
            },
            error: (error: any) => {
                console.warn(error)
            }
        });


        return () => {
            const cleanupSubscriptions = () => {
                createSubscription.unsubscribe();
                updateSubscription.unsubscribe();
                deleteSubscription.unsubscribe();
            }
            cleanupSubscriptions();

        }
    }, []);

    return {
        addNewData,
        updateData,
        updateDataField,
        deleteData,
        findOneData,
        listAllData,
    };
}
