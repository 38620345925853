import { Skeleton } from "primereact/skeleton";

interface Props {
    skelsNumber: number;
    addTopPadding?: boolean;
}

export const ListLoader = ({ skelsNumber, addTopPadding }: Props) => {
    return (
        <div className={`t-min-h-[370px] t-h-full t-bg-white t-px-4 t-pb-5 ${(addTopPadding ?? false) ? 't-pt-3' : 't-pt-0'}`}>
            {[...Array(skelsNumber).keys()].map((number) => {
                return <Skeleton key={number.toString()} className="t-w-full t-mt-4 !t-h-[50px]"></Skeleton>
            })}
        </div>
    );
}