import * as Yup from "yup";
import { EditorType, PageType, VisibilityType } from "../../../models";

export const SchemaForArticlesPublications = Yup.object().shape({
  title: Yup.string()
    .min(1, "Too Short!")
    .label("Title")
    .required("Required")
    .default(""),
  // publishers: Yup.array().optional(),
  // authorStaffProfiles: Yup.array().optional(),
  shortDescription: Yup.string()
    .label("Short Description")
    .optional()
    .default(""),
  volumeNumber: Yup.string()
    // .min(1, "Too Short!")
    .label("Volume Number")
    .default("")
    .optional(),
  editionNumber: Yup.string()
    // .min(1, "Too Short!")
    .label("Edition Number")
    .default("")
    .optional(),
  issueNumber: Yup.string()
    // .min(1, "Too Short!")
    .label("Issue Number")
    .default("")
    .optional(),
  issn: Yup.string()
    // .min(1, "Too Short!")
    .label("ISSN")
    .default(""),
  // coverImage: Yup.mixed<any>().optional(),
  slug: Yup.string().required("Required").default(""),
  pageDesignTextEditor: Yup.string().optional().default(``),
  pageDesignPageBuilder: Yup.string().optional().default(`{}`),
  pageDesignType: Yup.mixed<EditorType>()
    .required()
    .default(EditorType.TEXT_EDITOR),
  visibilityType: Yup.mixed<VisibilityType>()
    .required()
    .default(VisibilityType.NONE),
  // tags: Yup.lazy(val => (Array.isArray(val) ? Yup.array().of(Yup.string()) : Yup.array().of(Yup.string())).optional()),
  tags: Yup.array().optional(),
  status: Yup.string().required("Required").default("draft"),
  datePublished: Yup.date().required("Required").default(new Date()),
  dateAdded: Yup.date().required("Required").default(new Date()),
  postSubCategoryID: Yup.string().required("Required").default(""),
  postCategoryID: Yup.string().required("Required").default(""),
  // galleryImages: Yup.mixed<any>().optional(),
  pageType: Yup.mixed<PageType>()
    .required()
    .default(PageType.ARTICLE_PUBLICATION),
});
