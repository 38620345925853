import { useEffect } from "react";
import { UserRole } from "../../../../models";
import { DataLoadingState, PageType } from "../../../../models";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import useDataForCoverImageFile from "../../../../shared-components/images/data-cover-image";
import useDataForUserAuth from "../../../../store/auth/data-for-auth";
import useDataUserRoleList from "../data/data-for-user-roles-list";
import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../../../graphql/subscriptions";
import {
  createUserRoleGQL,
  deleteUserRole,
  listUserRolesGQL,
  updateUserRoleGQL,
} from "../../../../custom-graphql/custom-gql-user-role";
import isDevMode from "../../../../utils/check-dev-mode";
import useDataUserRolesForm from "../data/data-for-user-roles-form";

interface ServiceInterfaceForUserRole {
  addNewData: (item: UserRole) => Promise<boolean>;
  updateData: (
    item: UserRole,
    isDelete: boolean
  ) => Promise<boolean | UserRole>;
  updateDataField: (id: string, key: string, value: any) => Promise<boolean>;
  deleteData: (item: UserRole) => Promise<boolean>;
  // findOneData: (id: string) => Promise<UserRole | null>;
  listAllData: () => Promise<boolean | null>;
}

const pageModelName = "User Role";

export function useServiceForUserRoleAPI(): ServiceInterfaceForUserRole {
  const pageDataAdd = useDataUserRoleList((state) => state.addItem);
  const loggedUser = useDataForUserAuth((state) => state.loggedUser);
  const formData = useDataUserRolesForm((state) => state.formData);
  const setFormData = useDataUserRolesForm((state) => state.setFormData);
  const pageDataSetStatusForLoading = useDataUserRoleList(
    (state) => state.setStatusForLoadingListItems
  );
  const coverImageGetS3URL = useDataForCoverImageFile(
    (state) => state.getS3URL
  );

  const UserRoleDataSetList = useDataUserRoleList(
    (state) => state.setListItems
  );
  const UserRoleSetStatusForLoadingListItems = useDataUserRoleList(
    (state) => state.setStatusForLoadingListItems
  );

  const pageDataUpdate = useDataUserRoleList((state) => state.updateItem);
  const pageDataDelete = useDataUserRoleList((state) => state.deleteItem);

  const addNewData = async (item: UserRole) => {
    let newItem: UserRole;
    let dataResult: UserRole;

    newItem = {
      ...item,
      dateUpdated: new Date().toISOString(),
      creatorUserAccountID: loggedUser!.userAccount!.id,
    };
    delete (newItem as any).createdAt;
    delete (newItem as any).updatedAt;
    delete (newItem as any).creatorUserAccount;
    delete (newItem as any)._lastChangedAt;
    delete (newItem as any)._deleted;
    delete (newItem as any).crigDivisionSubStation;
    delete (newItem as any).crigStaffGroup;
    delete (newItem as any).crigUnitDepartment;
    delete (newItem as any).articlesPublicationsAuthored;

    if (isDevMode()) {
      console.log(`addNew ${pageModelName} newItem`, newItem);
    }

    try {
      const results: any = await API.graphql(
        graphqlOperation(createUserRoleGQL, { input: newItem })
      );
      console.log(`addNew ${pageModelName} results`, results);
      dataResult = results.data.createUserRole;
      return true;
    } catch (error) {
      console.log(`addNew ${pageModelName} onError`, error);
      return false;
    }
  };

  const updateData = async (item: UserRole, isDelete: boolean) => {
    let newItem: UserRole;
    let dataResult: UserRole;
    newItem = {
      ...item,
      dateUpdated: new Date().toISOString(),
    };
    console.log("rolesnewItem", newItem);

    if (isDelete) {
      newItem = {
        ...item,
        deleted: true,
      };
    }

    delete (newItem as any).createdAt;
    delete (newItem as any).updatedAt;
    delete (newItem as any).creatorUserAccount;
    delete (newItem as any)._lastChangedAt;
    delete (newItem as any)._deleted;
    delete (newItem as any).crigDivisionSubStation;
    delete (newItem as any).crigUnitDepartment;
    delete (newItem as any).articlesPublicationsAuthored;
    delete (newItem as any).userAccounts;

    if (isDevMode()) {
      console.log("update item to save", newItem);
    }

    try {
      const results: any = await API.graphql(
        graphqlOperation(updateUserRoleGQL, { input: newItem })
      );
      if (isDevMode()) {
        console.log(`update ${pageModelName} results`, results);
      }

      dataResult = results.data.updateUserRole;
      if (isDelete) {
        return dataResult;
      } else {
        return true;
      }
    } catch (error) {
      if (isDevMode()) {
        console.log(`update ${pageModelName} onError`, error);
      }

      return false;
    }
  };

  const listAllData = async () => {
    let foundItems: UserRole[] | null | undefined;
    let promise: Promise<GraphQLResult<any>>;
    const variables = {
      filter: {
        and: [
          {
            deleted: {
              eq: false,
            },
          },
        ],
      },
    };

    promise = API.graphql({
      query: listUserRolesGQL,
      variables: variables,
    });

    await promise
      .then((results) => {
        foundItems = results.data.listUserRoles.items as UserRole[];

        const sortFxn = (a: UserRole, b: UserRole) => {
          return (
            new Date(a.dateAdded!).getTime() - new Date(a.dateAdded!).getTime()
          );
        };

        if (isDevMode()) {
          console.log("listAllUserRoles", foundItems);
        }

        UserRoleDataSetList(foundItems.sort(sortFxn));
        UserRoleSetStatusForLoadingListItems(DataLoadingState.LOADED);
        return foundItems;
      })
      .catch((e) => {
        if (isDevMode()) {
          console.log("error listAllUserRoles", e);
        }

        UserRoleSetStatusForLoadingListItems(DataLoadingState.ERROR);
        return null;
      });
    return null;
  };

  // const findOneData = async (id: string) => {
  //   let foundItem: UserRole | null;
  //   try {
  //     const results: any = await API.graphql(
  //       graphqlOperation(getUserRoleGQL, { id: id })
  //     );

  //     if (isDevMode()) {
  //       console.log(`findOneData ${pageModelName} results`, results);
  //     }

  //     foundItem = results.data.getUserRole;

  //     return foundItem;
  //   } catch (error) {
  //     if (isDevMode()) {
  //       console.log(`findOneData ${pageModelName} onError`, error);
  //     }

  //     return null;
  //   }
  // };

  const deleteData = async (item: any) => {
    let dataResult: UserRole;
    console.log(item);
    try {
      item = { ...item, deleted: true };

      const results: any = await API.graphql(
        graphqlOperation(deleteUserRole, {
          input: { id: item.id, _version: item._version },
        })
      );

      if (isDevMode()) {
        console.log(`delete ${pageModelName} results`, results);
      }

      dataResult = results.data.deleteUserRole;

      await updateData(dataResult, true);

      return true;
    } catch (error) {
      if (isDevMode()) {
        console.log(`update ${pageModelName} onError`, error);
      }

      return false;
    }
  };

  //FIGURE OUT API
  const updateDataField = async (id: string, key: string, value: any) => {
    return false;
  };

  useEffect(() => {
    const createSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onCreateUserRole, {
          filter: {
            deleted: { eq: false },
          },
        })
      ) as unknown as any
    ).subscribe({
      next: async ({ provider, value }) => {
        if (isDevMode()) {
          console.log({ provider, value });
        }

        let data = value.data.onCreateUserRole as UserRole;

        pageDataAdd(data);
        if (formData?.id == data.id) {
          setFormData(data);
        }
      },
      error: (error: any) => {
        if (isDevMode()) {
          console.warn(error);
        }
      },
    });

    const updateSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onUpdateUserRole, {
          filter: {
            deleted: { eq: false },
          },
        })
      ) as unknown as any
    ).subscribe({
      next: async ({ provider, value }) => {
        console.log({ provider, value });
        let data = value.data.onUpdateUserRole as UserRole;

        pageDataUpdate(data);
        if (formData?.id == data.id) {
          setFormData(data);
        }
      },
      error: (error: any) => {
        console.warn(error);
      },
    });

    const deleteSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onDeleteUserRole, {
          filter: {
            deleted: { eq: false },
          },
        })
      ) as unknown as any
    ).subscribe({
      next: ({ provider, value }) => {
        console.log({ provider, value });
        pageDataDelete(value.data.onDeleteUserRole as UserRole);
      },
      error: (error: any) => {
        console.warn(error);
      },
    });

    return () => {
      const cleanupSubscriptions = () => {
        createSubscription.unsubscribe();
        updateSubscription.unsubscribe();
        deleteSubscription.unsubscribe();
      };
      cleanupSubscriptions();
    };
  }, []);

  return {
    addNewData,
    updateData,
    updateDataField,
    deleteData,
    // findOneData,
    listAllData,
  };
}
