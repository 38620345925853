import { format } from "date-fns";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDataForTextEditor from "../../../editors/data-for-editor";
import {
  DataLoadingState,
  FormActionType,
  Page,
  S3UploadedItem,
} from "../../../models";
import useRoutePaths from "../../../routes/route-path";
import TableActionButtonSet from "../../../shared-components/button/TableActionButtonSet";
import DeleteDialog from "../../../shared-components/form/DeleteDialog";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataForGalleryImagesFile from "../../../shared-components/images/data-gallery-images";
import { ListLoader } from "../../../shared-components/list-tables/list-loader";
import useToaster from "../../../shared-components/toasts/Toaster";
import useDataNewsAnnouncementForm from "../data/data-for-news-announcement-form";
import useDataNewsAnnouncementList from "../data/data-for-news-announcement-list";
import { useServiceForNewsAnnouncementAPI } from "../services/services-for-news-announcements";

const TableNewsAnnouncement = () => {
  const { paths: route } = useRoutePaths();
  const navigation = useNavigate();
  const setFormActionType = useDataNewsAnnouncementForm(
    (state) => state.setFormActionType
  );
  const setFormData = useDataNewsAnnouncementForm((state) => state.setFormData);
  const coverImageReset = useDataForCoverImageFile((state) => state.reset);
  const editorReset = useDataForTextEditor((state) => state.reset);
  const listItems = useDataNewsAnnouncementList((state) => state.listItems);
  const setStateLoadingStatus = useDataNewsAnnouncementList(
    (state) => state.setStatusForLoadingListItems
  );
  const listItemsFromService = useServiceForNewsAnnouncementAPI().listAllData;
  const deleteItemFromService = useServiceForNewsAnnouncementAPI().deleteData;
  const loadingStatus = useDataNewsAnnouncementList(
    (state) => state.statusForLoadingListItems
  );
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // "country.name": {
    //   operator: FilterOperator.AND,
    //   constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    // },
    // representative: { value: null, matchMode: FilterMatchMode.IN },
    dateUpdated: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },

    // status: {
    //   operator: FilterOperator.OR,
    //   constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    // },
    activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
  });
  const galleryImageReset = useDataForGalleryImagesFile((state) => state.reset);

  const columnsAll: any = [
    { field: "id", header: "ID" },
    { field: "title", header: "Title" },
    { field: "postSubCategory.name", header: "Post Sub Category" },
    { field: "pageDesignType", align: "center", header: "Page Design Type" },
    { field: "status", align: "center", header: "Status" },
    { field: "totalViewsCount", align: "center", header: "Total Views" },
  ];

  const columnsSelected: any = [
    { field: "title", header: "Title" },
    { field: "postSubCategory.name", header: "Post Sub Category" },
    { field: "status", align: "center", header: "Status" },
    { field: "totalViewsCount", align: "center", header: "Total Views" },
  ];

  const [selectedColumns, setSelectedColumns] = useState(columnsSelected);

  const { showError, showSuccess } = useToaster();

  const refToast = useRef(null);

  const [itemToDelete, setItemToDelete] = useState<Page | null>(null);

  const onColumnToggle = (event: any) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columnsAll.filter((col: { field: any }) =>
      selectedColumns.some((sCol: { field: any }) => sCol.field === col.field)
    );
    setSelectedColumns(orderedSelectedColumns);
  };

  const onSearchKeywordChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = (
    <div
      className="t-grid grid-cols-1 t-w-full  md:t-grid-cols-3
   justify-content-start align-items-center "
    >
      <div className="list-column-select" style={{ textAlign: "left" }}>
        <MultiSelect
          fixedPlaceholder={true}
          placeholder="Columns"
          value={selectedColumns}
          options={columnsAll}
          optionLabel="header"
          onChange={onColumnToggle}
          style={{ width: "14.5em", borderRadius: "10px" }}
        />
      </div>
      <div className="list-search-box t-w-full t-pt-4 md:t-pt-0">
        <span className="p-input-icon-left p-input-border-none  t-font-inter p-input-filled  t-w-full">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onSearchKeywordChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    </div>
  );

  const columnComponents = selectedColumns.map((col) => {
    return (
      <Column
        key={col.field}
        align={col.align}
        field={col.field}
        header={col.header}
      />
    );
  });

  useEffect(() => {
    let fetching = true;

    const fetchListData = async () => {
      await listItemsFromService();
    };

    if (fetching) {
      if (listItems.length == 0 && loadingStatus == DataLoadingState.PENDING) {
        setStateLoadingStatus(DataLoadingState.LOADING);
        fetchListData();
      }
    }

    return () => {
      fetching = false;
    };
  }, [loadingStatus, listItems]);

  const editOnClick = (rowData: any) => {
    coverImageReset();
    editorReset();
    galleryImageReset();
    setFormActionType(FormActionType.EDIT);
    setFormData(rowData as Page);
    navigation(route.newsAnnouncements.details);
  };

  const deleteOnClick = (rowData: any) => {
    setItemToDelete(rowData as Page);
  };

  const handleDeleteItem = async () => {
    try {
      let _itemToDelete: Page = { ...itemToDelete!, deleted: true };
      await deleteItemFromService(_itemToDelete).then((done) => {
        setItemToDelete(null);
      });
    } catch (error) {
      console.log(`err handleDeleteItem news`, error);
      showError(
        refToast,
        "ATTENTION",
        "Something went wrong, kindly try again."
      );
    }
  };

  const actionsBodyTemplate = (rowData: any) => {
    return (
      <TableActionButtonSet
        editOnClick={() => {
          editOnClick(rowData);
        }}
        viewOnClick={() => {}}
        deleteOnClick={() => {
          deleteOnClick(rowData);
        }}
      />
    );
  };

  const coverImageTemplate = (rowData: any) => {
    const coverImage: S3UploadedItem = rowData["coverImage"];
    let id = rowData["id"];
    let src = "";
    if (coverImage != null) {
      src = coverImage.signedURL ?? "";
    }
    return (
      <div className="t-rounded-md t-object-cover t-h-[70px] t-bg-slate-200 t-w-[120px] t-overflow-hidden">
        {src.length > 0 && (
          <img
            className="t-object-cover t-h-[70px]  t-w-[120px]"
            id={id}
            src={src}
          />
        )}
      </div>
    );
  };

  const formatDate = (value: Date, addTime: boolean) => {
    return format(value, addTime ? `do LLL yyyy, h:mm aaa` : "do LLL yyyy");
  };

  const dateBodyTemplate = (field: string, addTime: boolean) => {
    // const value: any = rowData['dateUpdated'];
    const valueAsDate = new Date(field);
    const formatted = formatDate(valueAsDate, addTime);
    return <p>{formatted}</p>;
  };

  return (
    <div className=" t-bg-white">
      {/* loader */}
      {(loadingStatus == DataLoadingState.LOADING ||
        loadingStatus == DataLoadingState.PENDING) && (
        <ListLoader skelsNumber={7} />
      )}

      {/* table list */}
      {loadingStatus == DataLoadingState.LOADED && (
        <DataTable
          scrollHeight="70vh"
          scrollable
          value={listItems}
          stripedRows
          filters={filters}
          header={header}
          responsiveLayout="scroll"
        >
          <Column
            field="coverImage"
            align={"left"}
            header="Cover Image"
            showFilterMatchModes={false}
            // style={{ minWidth: "100px", maxWidth: '100px' }}
            body={coverImageTemplate}
          />
          {columnComponents}
          <Column
            field="datePublished"
            align={"left"}
            header="Date Published"
            showFilterMatchModes={false}
            style={{ minWidth: "9rem" }}
            body={(rowData, _options) => {
              return dateBodyTemplate(rowData["datePublished"], false);
            }}
          />
          <Column
            field="dateUpdated"
            align={"left"}
            header="Date Updated"
            showFilterMatchModes={false}
            style={{ minWidth: "9rem" }}
            body={(rowData, _options) => {
              return dateBodyTemplate(rowData["dateUpdated"], true);
            }}
          />
          <Column
            field="activity"
            header="Actions"
            showFilterMatchModes={false}
            style={{ minWidth: "11rem" }}
            body={actionsBodyTemplate}
          />
        </DataTable>
      )}
      {itemToDelete != null && (
        <DeleteDialog
          message={`Are you sure you want to delete ${itemToDelete.title}? This action cannot be reversed!`}
          onDelete={handleDeleteItem}
          onSetVisible={() => {
            setItemToDelete(null);
          }}
          show={itemToDelete != null}
        />
      )}
    </div>
  );
};

export default TableNewsAnnouncement;
