export const schema = {
    "models": {
        "AppNotification": {
            "name": "AppNotification",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "receiversUserAccountsViews": {
                    "name": "receiversUserAccountsViews",
                    "isArray": true,
                    "type": {
                        "model": "PostUserAccountViewDownload"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "appNotification"
                        ]
                    }
                },
                "dateAdded": {
                    "name": "dateAdded",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "dateUpdated": {
                    "name": "dateUpdated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "AppNotifications",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins",
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ArticlePublication": {
            "name": "ArticlePublication",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "shortDescription": {
                    "name": "shortDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "pageDesignType": {
                    "name": "pageDesignType",
                    "isArray": false,
                    "type": {
                        "enum": "EditorType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "pageDesignTextEditor": {
                    "name": "pageDesignTextEditor",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "pageDesignPageBuilder": {
                    "name": "pageDesignPageBuilder",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "coverImage": {
                    "name": "coverImage",
                    "isArray": false,
                    "type": {
                        "nonModel": "S3UploadedItem"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "galleryImages": {
                    "name": "galleryImages",
                    "isArray": true,
                    "type": {
                        "nonModel": "S3UploadedItem"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "documents": {
                    "name": "documents",
                    "isArray": true,
                    "type": {
                        "nonModel": "S3UploadedItem"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "tags": {
                    "name": "tags",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "authorStaffProfiles": {
                    "name": "authorStaffProfiles",
                    "isArray": true,
                    "type": {
                        "model": "ArticlePublicationsAuthors"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "articlePublication"
                        ]
                    }
                },
                "authorStaffProfilesIds": {
                    "name": "authorStaffProfilesIds",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "publishersIds": {
                    "name": "publishersIds",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "publishers": {
                    "name": "publishers",
                    "isArray": true,
                    "type": {
                        "model": "ArticlePublicationsPublishers"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "articlePublication"
                        ]
                    }
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "slug": {
                    "name": "slug",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dateUploaded": {
                    "name": "dateUploaded",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "datePublished": {
                    "name": "datePublished",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "totalViewsCount": {
                    "name": "totalViewsCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "totalDownloadsCount": {
                    "name": "totalDownloadsCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "postCategoryID": {
                    "name": "postCategoryID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "postCategory": {
                    "name": "postCategory",
                    "isArray": false,
                    "type": {
                        "model": "PostCategory"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "postCategoryID"
                        ]
                    }
                },
                "postSubCategoryID": {
                    "name": "postSubCategoryID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "postSubCategory": {
                    "name": "postSubCategory",
                    "isArray": false,
                    "type": {
                        "model": "PostSubCategory"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "postSubCategoryID"
                        ]
                    }
                },
                "volumeNumber": {
                    "name": "volumeNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "editionNumber": {
                    "name": "editionNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "issueNumber": {
                    "name": "issueNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "issn": {
                    "name": "issn",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "userViewsDownloads": {
                    "name": "userViewsDownloads",
                    "isArray": true,
                    "type": {
                        "model": "PostUserAccountViewDownload"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "articlePublication"
                        ]
                    }
                },
                "creatorUserAccountID": {
                    "name": "creatorUserAccountID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "creatorUserAccount": {
                    "name": "creatorUserAccount",
                    "isArray": false,
                    "type": {
                        "model": "UserAccount"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "id"
                        ],
                        "targetNames": [
                            "creatorUserAccountID"
                        ]
                    }
                },
                "visibilityType": {
                    "name": "visibilityType",
                    "isArray": false,
                    "type": {
                        "enum": "VisibilityType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ArticlePublications",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTitle",
                        "queryField": "articlePublicationByTitleAndStatus",
                        "fields": [
                            "title",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySlug",
                        "queryField": "articlePublicationBySlugAndStatus",
                        "fields": [
                            "slug",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPostCategory",
                        "queryField": "articlePublicationByPostCategoryAndStatus",
                        "fields": [
                            "postCategoryID",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPostSubCategory",
                        "queryField": "articlePublicationByPostSubCategoryAndStatus",
                        "fields": [
                            "postSubCategoryID",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byVolumeNumber",
                        "queryField": "articlePublicationByVolumeNumberAndStatus",
                        "fields": [
                            "volumeNumber",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byEditionNumber",
                        "queryField": "articlePublicationByEditionNumberAndStatus",
                        "fields": [
                            "editionNumber",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byIssueNumber",
                        "queryField": "articlePublicationByIssueNumberAndStatus",
                        "fields": [
                            "issueNumber",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byIssn",
                        "queryField": "articlePublicationByIssnAndStatus",
                        "fields": [
                            "issn",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCreatorUserAccount",
                        "queryField": "articlePublicationByCreatorUserAccountAndStatus",
                        "fields": [
                            "creatorUserAccountID",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byVisibilityType",
                        "queryField": "articlePublicationByVisibilityTypeAndStatus",
                        "fields": [
                            "visibilityType",
                            "status"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins",
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ArticlePublicationPublisher": {
            "name": "ArticlePublicationPublisher",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "articlesPublicationsPublished": {
                    "name": "articlesPublicationsPublished",
                    "isArray": true,
                    "type": {
                        "model": "ArticlePublicationsPublishers"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "articlePublicationPublisher"
                        ]
                    }
                },
                "dateAdded": {
                    "name": "dateAdded",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "dateUpdated": {
                    "name": "dateUpdated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ArticlePublicationPublishers",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins",
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ContactFormMsg": {
            "name": "ContactFormMsg",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "fullName": {
                    "name": "fullName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "emailAddress": {
                    "name": "emailAddress",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "phoneNumber": {
                    "name": "phoneNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "countryName": {
                    "name": "countryName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "countryIso3": {
                    "name": "countryIso3",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "subject": {
                    "name": "subject",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "message": {
                    "name": "message",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "dateAdded": {
                    "name": "dateAdded",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "dateUpdated": {
                    "name": "dateUpdated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ContactFormMsgs",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CrigStaffGroup": {
            "name": "CrigStaffGroup",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "shortDescription": {
                    "name": "shortDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "slug": {
                    "name": "slug",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "dateUploaded": {
                    "name": "dateUploaded",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "datePublished": {
                    "name": "datePublished",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "totalViewsCount": {
                    "name": "totalViewsCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "totalStaff": {
                    "name": "totalStaff",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "staffProfiles": {
                    "name": "staffProfiles",
                    "isArray": true,
                    "type": {
                        "model": "StaffProfile"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "crigStaffGroup"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "CrigStaffGroups",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byName",
                        "queryField": "crigStaffGroupByNameAndStatus",
                        "fields": [
                            "name",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySlug",
                        "queryField": "crigStaffGroupBySlug",
                        "fields": [
                            "slug",
                            "status"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins",
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CrigUnitDepartment": {
            "name": "CrigUnitDepartment",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "shortDescription": {
                    "name": "shortDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "slug": {
                    "name": "slug",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "dateUploaded": {
                    "name": "dateUploaded",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "datePublished": {
                    "name": "datePublished",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "totalStaff": {
                    "name": "totalStaff",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "staffProfiles": {
                    "name": "staffProfiles",
                    "isArray": true,
                    "type": {
                        "model": "StaffProfile"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "crigUnitDepartment"
                        ]
                    }
                },
                "crigDivisionSubstationID": {
                    "name": "crigDivisionSubstationID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "crigDivisionSubstation": {
                    "name": "crigDivisionSubstation",
                    "isArray": false,
                    "type": {
                        "model": "Page"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "crigDivisionSubstationID"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "CrigUnitDepartments",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byName",
                        "queryField": "crigUnitDepartmentByNameAndStatus",
                        "fields": [
                            "name"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySlug",
                        "queryField": "crigUnitDepartmentBySlug",
                        "fields": [
                            "slug"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCrigDivisionSubstation",
                        "queryField": "crigUnitDepartmentByDivisionSubstation",
                        "fields": [
                            "crigDivisionSubstationID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins",
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Faq": {
            "name": "Faq",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "icon": {
                    "name": "icon",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "faqItems": {
                    "name": "faqItems",
                    "isArray": true,
                    "type": {
                        "model": "FaqItem"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "faq"
                        ]
                    }
                },
                "dateAdded": {
                    "name": "dateAdded",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "dateUpdated": {
                    "name": "dateUpdated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Faqs",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins",
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "FaqItem": {
            "name": "FaqItem",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "faqID": {
                    "name": "faqID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "faq": {
                    "name": "faq",
                    "isArray": false,
                    "type": {
                        "model": "Faq"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "faqID"
                        ]
                    }
                },
                "dateAdded": {
                    "name": "dateAdded",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "dateUpdated": {
                    "name": "dateUpdated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "FaqItems",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byFaq",
                        "queryField": "faqItemByFaqAndStatus",
                        "fields": [
                            "faqID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins",
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Folder": {
            "name": "Folder",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "color": {
                    "name": "color",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "pageType": {
                    "name": "pageType",
                    "isArray": false,
                    "type": {
                        "enum": "PageType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "slug": {
                    "name": "slug",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dateAdded": {
                    "name": "dateAdded",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "dateUpdated": {
                    "name": "dateUpdated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Folders",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byFolderType",
                        "queryField": "folderByTypeAndStatus",
                        "fields": [
                            "pageType"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySlug",
                        "queryField": "folderBySlugAndStatus",
                        "fields": [
                            "slug"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins",
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "HelpCenterPage": {
            "name": "HelpCenterPage",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "icon": {
                    "name": "icon",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "pageDesignType": {
                    "name": "pageDesignType",
                    "isArray": false,
                    "type": {
                        "enum": "EditorType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "pageDesignTextEditor": {
                    "name": "pageDesignTextEditor",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "pageDesignPageBuilder": {
                    "name": "pageDesignPageBuilder",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "tags": {
                    "name": "tags",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "slug": {
                    "name": "slug",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "HelpCenterPages",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Page": {
            "name": "Page",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "shortDescription": {
                    "name": "shortDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "details": {
                    "name": "details",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "pageDesignType": {
                    "name": "pageDesignType",
                    "isArray": false,
                    "type": {
                        "enum": "EditorType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "pageDesignTextEditor": {
                    "name": "pageDesignTextEditor",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "pageDesignPageBuilder": {
                    "name": "pageDesignPageBuilder",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "totalViewsCount": {
                    "name": "totalViewsCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "totalStaffCount": {
                    "name": "totalStaffCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "totalDownloadsCount": {
                    "name": "totalDownloadsCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "totalDeliveriesCount": {
                    "name": "totalDeliveriesCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "pageType": {
                    "name": "pageType",
                    "isArray": false,
                    "type": {
                        "enum": "PageType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "tags": {
                    "name": "tags",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "slug": {
                    "name": "slug",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "postCategoryID": {
                    "name": "postCategoryID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "postCategory": {
                    "name": "postCategory",
                    "isArray": false,
                    "type": {
                        "model": "PostCategory"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "postCategoryID"
                        ]
                    }
                },
                "postSubCategoryID": {
                    "name": "postSubCategoryID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "postSubCategory": {
                    "name": "postSubCategory",
                    "isArray": false,
                    "type": {
                        "model": "PostSubCategory"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "postSubCategoryID"
                        ]
                    }
                },
                "unitDepartments": {
                    "name": "unitDepartments",
                    "isArray": true,
                    "type": {
                        "model": "CrigUnitDepartment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "crigDivisionSubstation"
                        ]
                    }
                },
                "coverImage": {
                    "name": "coverImage",
                    "isArray": false,
                    "type": {
                        "nonModel": "S3UploadedItem"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "galleryImages": {
                    "name": "galleryImages",
                    "isArray": true,
                    "type": {
                        "nonModel": "S3UploadedItem"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "documents": {
                    "name": "documents",
                    "isArray": true,
                    "type": {
                        "nonModel": "S3UploadedItem"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "staffProfiles": {
                    "name": "staffProfiles",
                    "isArray": true,
                    "type": {
                        "model": "StaffProfile"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "crigDivisionSubStation"
                        ]
                    }
                },
                "creatorUserAccountID": {
                    "name": "creatorUserAccountID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "creatorUserAccount": {
                    "name": "creatorUserAccount",
                    "isArray": false,
                    "type": {
                        "model": "UserAccount"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "id"
                        ],
                        "targetNames": [
                            "creatorUserAccountID"
                        ]
                    }
                },
                "dateAdded": {
                    "name": "dateAdded",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "dateSent": {
                    "name": "dateSent",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "dateUpdated": {
                    "name": "dateUpdated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "datePublished": {
                    "name": "datePublished",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "listPosition": {
                    "name": "listPosition",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Pages",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPageType",
                        "queryField": "pageByTypeAndStatus",
                        "fields": [
                            "pageType",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySlug",
                        "queryField": "pageBySlugAndStatus",
                        "fields": [
                            "slug",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPostCategory",
                        "queryField": "pageByPostCategoryAndStatus",
                        "fields": [
                            "postCategoryID",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPostSubCategory",
                        "queryField": "pageByPageSubCategoryAndStatus",
                        "fields": [
                            "postSubCategoryID",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCreatorUserAccount",
                        "queryField": "pageByCreatorUserAccountAndStatus",
                        "fields": [
                            "creatorUserAccountID",
                            "status"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins",
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "PageDesignTemplate": {
            "name": "PageDesignTemplate",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "templateDesign": {
                    "name": "templateDesign",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "EditorType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "dateAdded": {
                    "name": "dateAdded",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "datePublished": {
                    "name": "datePublished",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "dateUpdated": {
                    "name": "dateUpdated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "PageDesignTemplates",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins",
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "PostCategory": {
            "name": "PostCategory",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "shortDescription": {
                    "name": "shortDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "slug": {
                    "name": "slug",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "dateUploaded": {
                    "name": "dateUploaded",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "datePublished": {
                    "name": "datePublished",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "totalPostsCount": {
                    "name": "totalPostsCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "totalSubCategoriesCount": {
                    "name": "totalSubCategoriesCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "articlesPublications": {
                    "name": "articlesPublications",
                    "isArray": true,
                    "type": {
                        "model": "ArticlePublication"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "postCategory"
                        ]
                    }
                },
                "pages": {
                    "name": "pages",
                    "isArray": true,
                    "type": {
                        "model": "Page"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "postCategory"
                        ]
                    }
                },
                "subCategories": {
                    "name": "subCategories",
                    "isArray": true,
                    "type": {
                        "model": "PostSubCategory"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "postCategory"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "PostCategories",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byName",
                        "queryField": "postCategoryByName",
                        "fields": [
                            "name"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySlug",
                        "queryField": "postCategoryBySlug",
                        "fields": [
                            "slug"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins",
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "PostSubCategory": {
            "name": "PostSubCategory",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "slug": {
                    "name": "slug",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "dateUploaded": {
                    "name": "dateUploaded",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "datePublished": {
                    "name": "datePublished",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "totalPosts": {
                    "name": "totalPosts",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "postCategoryID": {
                    "name": "postCategoryID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "postCategory": {
                    "name": "postCategory",
                    "isArray": false,
                    "type": {
                        "model": "PostCategory"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "postCategoryID"
                        ]
                    }
                },
                "articlesPublications": {
                    "name": "articlesPublications",
                    "isArray": true,
                    "type": {
                        "model": "ArticlePublication"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "postSubCategory"
                        ]
                    }
                },
                "pages": {
                    "name": "pages",
                    "isArray": true,
                    "type": {
                        "model": "Page"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "postSubCategory"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "PostSubCategories",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byName",
                        "queryField": "postSubCategoryByName",
                        "fields": [
                            "name"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySlug",
                        "queryField": "postSubCategoryBySlug",
                        "fields": [
                            "slug"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPostCategory",
                        "queryField": "postSubCategoryByParentCategory",
                        "fields": [
                            "postCategoryID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins",
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "PostUserAccountViewDownload": {
            "name": "PostUserAccountViewDownload",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "userAccountID": {
                    "name": "userAccountID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "userAccount": {
                    "name": "userAccount",
                    "isArray": false,
                    "type": {
                        "model": "UserAccount"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "userAccountID"
                        ]
                    }
                },
                "pageType": {
                    "name": "pageType",
                    "isArray": false,
                    "type": {
                        "enum": "PageType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "postType": {
                    "name": "postType",
                    "isArray": false,
                    "type": {
                        "enum": "PostType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "postID": {
                    "name": "postID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "appNotificationID": {
                    "name": "appNotificationID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "articlePublication": {
                    "name": "articlePublication",
                    "isArray": false,
                    "type": {
                        "model": "ArticlePublication"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "postID"
                        ]
                    }
                },
                "appNotification": {
                    "name": "appNotification",
                    "isArray": false,
                    "type": {
                        "model": "AppNotification"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "appNotificationID"
                        ]
                    }
                },
                "downloadCount": {
                    "name": "downloadCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "deliveriesCount": {
                    "name": "deliveriesCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "viewCount": {
                    "name": "viewCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "PostUserAccountViewDownloads",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUserAccount",
                        "queryField": "postUserAccountViewDownloadByUserAccount",
                        "fields": [
                            "userAccountID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPost",
                        "queryField": "postUserAccountViewDownloadByPost",
                        "fields": [
                            "postID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAppNotification",
                        "queryField": "postUserAccountViewDownloadByAppNotification",
                        "fields": [
                            "appNotificationID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "identityClaim": "cognito:username"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SiteStats": {
            "name": "SiteStats",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "researchActivities": {
                    "name": "researchActivities",
                    "isArray": false,
                    "type": {
                        "nonModel": "PageStat"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "products": {
                    "name": "products",
                    "isArray": false,
                    "type": {
                        "nonModel": "PageStat"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "aboutUs": {
                    "name": "aboutUs",
                    "isArray": false,
                    "type": {
                        "nonModel": "PageStat"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "externalServices": {
                    "name": "externalServices",
                    "isArray": false,
                    "type": {
                        "nonModel": "PageStat"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "recreationFacilities": {
                    "name": "recreationFacilities",
                    "isArray": false,
                    "type": {
                        "nonModel": "PageStat"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "divisionsSubStations": {
                    "name": "divisionsSubStations",
                    "isArray": false,
                    "type": {
                        "nonModel": "PageStat"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "articleAndPublications": {
                    "name": "articleAndPublications",
                    "isArray": false,
                    "type": {
                        "nonModel": "PageStat"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "eventsAndGallery": {
                    "name": "eventsAndGallery",
                    "isArray": false,
                    "type": {
                        "nonModel": "PageStat"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "newsAndAnnouncements": {
                    "name": "newsAndAnnouncements",
                    "isArray": false,
                    "type": {
                        "nonModel": "PageStat"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "newsletters": {
                    "name": "newsletters",
                    "isArray": false,
                    "type": {
                        "nonModel": "PageStat"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "staff": {
                    "name": "staff",
                    "isArray": false,
                    "type": {
                        "nonModel": "PageStat"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "SiteStats",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins",
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Slide": {
            "name": "Slide",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "postCategoryID": {
                    "name": "postCategoryID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "postCategory": {
                    "name": "postCategory",
                    "isArray": false,
                    "type": {
                        "model": "PostCategory"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "id"
                        ],
                        "targetNames": [
                            "postCategoryID"
                        ]
                    }
                },
                "postCategoryName": {
                    "name": "postCategoryName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "postSubCategoryName": {
                    "name": "postSubCategoryName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "slideTitle": {
                    "name": "slideTitle",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "slidePosition": {
                    "name": "slidePosition",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "slidePageURL": {
                    "name": "slidePageURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "pageType": {
                    "name": "pageType",
                    "isArray": false,
                    "type": {
                        "enum": "PageType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "isSidePop": {
                    "name": "isSidePop",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "postID": {
                    "name": "postID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "postSlug": {
                    "name": "postSlug",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "postCoverImage": {
                    "name": "postCoverImage",
                    "isArray": false,
                    "type": {
                        "nonModel": "S3UploadedItem"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "dateAdded": {
                    "name": "dateAdded",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "dateUpdated": {
                    "name": "dateUpdated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "totalClicksCount": {
                    "name": "totalClicksCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Slides",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPageType",
                        "queryField": "slideByPageType",
                        "fields": [
                            "pageType"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySidePop",
                        "queryField": "slideBySidePop",
                        "fields": [
                            "isSidePop"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPost",
                        "queryField": "slideByPost",
                        "fields": [
                            "postID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPostSlug",
                        "queryField": "slideByPostSlug",
                        "fields": [
                            "postSlug"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStatus",
                        "queryField": "slideByStatus",
                        "fields": [
                            "status"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins",
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "StaffProfile": {
            "name": "StaffProfile",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "listPosition": {
                    "name": "listPosition",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "profileImage": {
                    "name": "profileImage",
                    "isArray": false,
                    "type": {
                        "nonModel": "S3UploadedItem"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "nameFirst": {
                    "name": "nameFirst",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "nameLast": {
                    "name": "nameLast",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "nameFull": {
                    "name": "nameFull",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "slug": {
                    "name": "slug",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "crigDivisionSubStationID": {
                    "name": "crigDivisionSubStationID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "crigDivisionSubStation": {
                    "name": "crigDivisionSubStation",
                    "isArray": false,
                    "type": {
                        "model": "Page"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "crigDivisionSubStationID"
                        ]
                    }
                },
                "crigStaffGroupID": {
                    "name": "crigStaffGroupID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "crigStaffGroup": {
                    "name": "crigStaffGroup",
                    "isArray": false,
                    "type": {
                        "model": "CrigStaffGroup"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "crigStaffGroupID"
                        ]
                    }
                },
                "crigUnitDepartmentID": {
                    "name": "crigUnitDepartmentID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "crigUnitDepartment": {
                    "name": "crigUnitDepartment",
                    "isArray": false,
                    "type": {
                        "model": "CrigUnitDepartment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "crigUnitDepartmentID"
                        ]
                    }
                },
                "position": {
                    "name": "position",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "isAContentFollower": {
                    "name": "isAContentFollower",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "userAccountID": {
                    "name": "userAccountID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "bioDesignType": {
                    "name": "bioDesignType",
                    "isArray": false,
                    "type": {
                        "enum": "EditorType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "bioDesignPageBuilder": {
                    "name": "bioDesignPageBuilder",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "bioDesignTextEditors": {
                    "name": "bioDesignTextEditors",
                    "isArray": true,
                    "type": {
                        "nonModel": "StaffBioTextEditor"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dateAdded": {
                    "name": "dateAdded",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "dateUpdated": {
                    "name": "dateUpdated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "articlesPublicationsAuthored": {
                    "name": "articlesPublicationsAuthored",
                    "isArray": true,
                    "type": {
                        "model": "ArticlePublicationsAuthors"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "staffProfile"
                        ]
                    }
                },
                "totalViewsCount": {
                    "name": "totalViewsCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "creatorUserAccountID": {
                    "name": "creatorUserAccountID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "creatorUserAccount": {
                    "name": "creatorUserAccount",
                    "isArray": false,
                    "type": {
                        "model": "UserAccount"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "id"
                        ],
                        "targetNames": [
                            "creatorUserAccountID"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "StaffProfiles",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySlug",
                        "queryField": "staffProfileBySlugAndStatus",
                        "fields": [
                            "slug",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCrigDivisionSubStation",
                        "queryField": "staffProfileByCrigDivisionSubStationAndStatus",
                        "fields": [
                            "crigDivisionSubStationID",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCrigStaffGroup",
                        "queryField": "staffProfileByCrigStaffGroupAndStatus",
                        "fields": [
                            "crigStaffGroupID",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCrigUnitDepartment",
                        "queryField": "staffProfileByCrigUnitDepartmentAndStatus",
                        "fields": [
                            "crigUnitDepartmentID",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCreatorUserAccount",
                        "queryField": "staffProfileByCreatorUserAccountAndStatus",
                        "fields": [
                            "creatorUserAccountID",
                            "status"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins",
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserAccount": {
            "name": "UserAccount",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "profileImage": {
                    "name": "profileImage",
                    "isArray": false,
                    "type": {
                        "nonModel": "S3UploadedItem"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "category": {
                    "name": "category",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "nameFirst": {
                    "name": "nameFirst",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "nameLast": {
                    "name": "nameLast",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "nameFull": {
                    "name": "nameFull",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "authId": {
                    "name": "authId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "contactPhoneNumber1": {
                    "name": "contactPhoneNumber1",
                    "isArray": false,
                    "type": "AWSPhone",
                    "isRequired": false,
                    "attributes": []
                },
                "contactPhoneNumber2": {
                    "name": "contactPhoneNumber2",
                    "isArray": false,
                    "type": "AWSPhone",
                    "isRequired": false,
                    "attributes": []
                },
                "contactEmail1": {
                    "name": "contactEmail1",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": false,
                    "attributes": []
                },
                "contactEmail2": {
                    "name": "contactEmail2",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": false,
                    "attributes": []
                },
                "countryIso": {
                    "name": "countryIso",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "countryName": {
                    "name": "countryName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "staffProfileID": {
                    "name": "staffProfileID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "userRoleID": {
                    "name": "userRoleID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "userRole": {
                    "name": "userRole",
                    "isArray": false,
                    "type": {
                        "model": "UserRole"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "userRoleID"
                        ]
                    }
                },
                "contentFollowing": {
                    "name": "contentFollowing",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "dateLastLogin": {
                    "name": "dateLastLogin",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "dateAdded": {
                    "name": "dateAdded",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "dateAddedYear": {
                    "name": "dateAddedYear",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dateAddedMonth": {
                    "name": "dateAddedMonth",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dateAddedDay": {
                    "name": "dateAddedDay",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dateUpdated": {
                    "name": "dateUpdated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "dateUpdatedYear": {
                    "name": "dateUpdatedYear",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dateUpdatedMonth": {
                    "name": "dateUpdatedMonth",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dateUpdatedDay": {
                    "name": "dateUpdatedDay",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "postViewsDownloads": {
                    "name": "postViewsDownloads",
                    "isArray": true,
                    "type": {
                        "model": "PostUserAccountViewDownload"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "userAccount"
                        ]
                    }
                },
                "totalViewsCount": {
                    "name": "totalViewsCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "totalPostDownloadsCount": {
                    "name": "totalPostDownloadsCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "totalPostViewsCount": {
                    "name": "totalPostViewsCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "UserAccounts",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUserAccountCategory",
                        "queryField": "userAccountByCategoryAndStatus",
                        "fields": [
                            "category",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUserAccountNameFull",
                        "queryField": "userAccountByFullNameAndStatus",
                        "fields": [
                            "nameFull",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAuth",
                        "queryField": "userAccountByAuthAndStatus",
                        "fields": [
                            "authId",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUserAccountContactPhoneNumber1",
                        "queryField": "userAccountByContactPhoneNumber1AndStatus",
                        "fields": [
                            "contactPhoneNumber1",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUserAccountContactEmail1",
                        "queryField": "userAccountByContactEmail1AndStatus",
                        "fields": [
                            "contactEmail1",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStaffProfile",
                        "queryField": "userAccountByStaffProfileAndStatus",
                        "fields": [
                            "staffProfileID",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUserRole",
                        "queryField": "userAccountByUserRoleAndStatus",
                        "fields": [
                            "userRoleID",
                            "status"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "cognito:username"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserRole": {
            "name": "UserRole",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "shortDescription": {
                    "name": "shortDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "moreDetailsTextEditor": {
                    "name": "moreDetailsTextEditor",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "userAccounts": {
                    "name": "userAccounts",
                    "isArray": true,
                    "type": {
                        "model": "UserAccount"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "userRole"
                        ]
                    }
                },
                "dateAdded": {
                    "name": "dateAdded",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "dateUpdated": {
                    "name": "dateUpdated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "assignedAccess": {
                    "name": "assignedAccess",
                    "isArray": true,
                    "type": {
                        "nonModel": "UserRoleAccess"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "creatorUserAccountID": {
                    "name": "creatorUserAccountID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "UserRoles",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCreatorAccount",
                        "queryField": "userRoleByCreatorUserAccountAndStatus",
                        "fields": [
                            "creatorUserAccountID",
                            "status"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "SuperAdmins"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "CmsUsers"
                                ],
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ArticlePublicationsAuthors": {
            "name": "ArticlePublicationsAuthors",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "articlePublicationId": {
                    "name": "articlePublicationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "staffProfileId": {
                    "name": "staffProfileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "articlePublication": {
                    "name": "articlePublication",
                    "isArray": false,
                    "type": {
                        "model": "ArticlePublication"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "articlePublicationId"
                        ]
                    }
                },
                "staffProfile": {
                    "name": "staffProfile",
                    "isArray": false,
                    "type": {
                        "model": "StaffProfile"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "staffProfileId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ArticlePublicationsAuthors",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byArticlePublication",
                        "fields": [
                            "articlePublicationId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStaffProfile",
                        "fields": [
                            "staffProfileId"
                        ]
                    }
                }
            ]
        },
        "ArticlePublicationsPublishers": {
            "name": "ArticlePublicationsPublishers",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "articlePublicationId": {
                    "name": "articlePublicationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "articlePublicationPublisherId": {
                    "name": "articlePublicationPublisherId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "articlePublication": {
                    "name": "articlePublication",
                    "isArray": false,
                    "type": {
                        "model": "ArticlePublication"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "articlePublicationId"
                        ]
                    }
                },
                "articlePublicationPublisher": {
                    "name": "articlePublicationPublisher",
                    "isArray": false,
                    "type": {
                        "model": "ArticlePublicationPublisher"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "articlePublicationPublisherId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ArticlePublicationsPublishers",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byArticlePublication",
                        "fields": [
                            "articlePublicationId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byArticlePublicationPublisher",
                        "fields": [
                            "articlePublicationPublisherId"
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "UserCategoryType": {
            "name": "UserCategoryType",
            "values": [
                "cmsUser",
                "contentFollower",
                "both",
                "none",
                "undefined"
            ]
        },
        "VisibilityType": {
            "name": "VisibilityType",
            "values": [
                "public",
                "private",
                "none",
                "undefined"
            ]
        },
        "EditorType": {
            "name": "EditorType",
            "values": [
                "pageBuilder",
                "textEditor",
                "none",
                "undefined"
            ]
        },
        "DataLoadingState": {
            "name": "DataLoadingState",
            "values": [
                "loading",
                "loaded",
                "error",
                "pending",
                "hasData"
            ]
        },
        "FormActionType": {
            "name": "FormActionType",
            "values": [
                "new",
                "edit",
                "readOnly",
                "none",
                "undefined"
            ]
        },
        "ObjectStatus": {
            "name": "ObjectStatus",
            "values": [
                "active",
                "inactive",
                "pending",
                "suspended",
                "none"
            ]
        },
        "RecordStatus": {
            "name": "RecordStatus",
            "values": [
                "active",
                "inactive",
                "draft",
                "archive",
                "published",
                "none"
            ]
        },
        "DbOpAccessType": {
            "name": "DbOpAccessType",
            "values": [
                "create",
                "read",
                "delete",
                "update",
                "none",
                "undefined"
            ]
        },
        "AppTableNames": {
            "name": "AppTableNames",
            "values": [
                "AppNotification",
                "ArticlePublication",
                "PostCategory",
                "UserAccount",
                "UserRole",
                "StaffProfile",
                "CrigDivisionSubStation",
                "CrigStaffGroup",
                "CrigUnitDepartment",
                "FileManager",
                "PostSubCategory",
                "Folder",
                "File",
                "Faq",
                "FaqItem",
                "Page",
                "News",
                "Newsletter",
                "PageDesignTemplate",
                "PostUserAccountViewDownload",
                "HelpCenterPage",
                "Settings",
                "ContentFollower",
                "none",
                "undefined"
            ]
        },
        "AppEnumNames": {
            "name": "AppEnumNames",
            "values": [
                "EditorType",
                "UserCategoryType",
                "DbOpAccessType",
                "PostType",
                "VisibilityType",
                "DataLoadingState",
                "FormActionType",
                "ObjectStatus",
                "RecordStatus",
                "OpType",
                "PageType",
                "ContentFollowType",
                "none",
                "undefined"
            ]
        },
        "OpType": {
            "name": "OpType",
            "values": [
                "INSERT",
                "UPDATE",
                "DELETE",
                "READ",
                "NONE",
                "UNDEFINED"
            ]
        },
        "ContentFollowType": {
            "name": "ContentFollowType",
            "values": [
                "researchActivities",
                "products",
                "articlesPublications",
                "articlesPublicationsMainCategories",
                "eventsGallery",
                "newsletters",
                "staffProfiles",
                "none",
                "undefined"
            ]
        },
        "PostType": {
            "name": "PostType",
            "values": [
                "newsAnnouncement",
                "articlePublication",
                "newsletter",
                "appNotification",
                "none",
                "undefined"
            ]
        },
        "PageType": {
            "name": "PageType",
            "values": [
                "newsAnnouncement",
                "articlePublication",
                "newsletter",
                "eventGallery",
                "recreationFacility",
                "product",
                "externalService",
                "researchActivity",
                "divisionSubStation",
                "aboutUs",
                "staff",
                "mandatedCrops",
                "appNotification",
                "none",
                "undefined"
            ]
        },
        "UploadCategory": {
            "name": "UploadCategory",
            "values": [
                "galleryImage",
                "coverImage",
                "document",
                "none",
                "undefined"
            ]
        }
    },
    "nonModels": {
        "S3UploadedItem": {
            "name": "S3UploadedItem",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "size": {
                    "name": "size",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "lastModified": {
                    "name": "lastModified",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "eTag": {
                    "name": "eTag",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "blurHash": {
                    "name": "blurHash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "signedURL": {
                    "name": "signedURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ContentViewed": {
            "name": "ContentViewed",
            "fields": {
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "ContentFollowType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "date": {
                    "name": "date",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": true,
                    "attributes": []
                },
                "userAccountID": {
                    "name": "userAccountID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "StaffBioTextEditor": {
            "name": "StaffBioTextEditor",
            "fields": {
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "UserRoleAccess": {
            "name": "UserRoleAccess",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "userRoleID": {
                    "name": "userRoleID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tableName": {
                    "name": "tableName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "routePath": {
                    "name": "routePath",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "menuItemTitle": {
                    "name": "menuItemTitle",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "operations": {
                    "name": "operations",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "PageStat": {
            "name": "PageStat",
            "fields": {
                "totalViewsCount": {
                    "name": "totalViewsCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "totalDownloadsCount": {
                    "name": "totalDownloadsCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "totalFollowersCount": {
                    "name": "totalFollowersCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        }
    },
    "codegenVersion": "3.4.2",
    "version": "b0cf66a9d2ef0b6d32b0911be71e5e3e"
};