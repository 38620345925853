import {
  SignUpResponse,
  SignInInfo,
  SignUpInfo,
  LoggedUser,
} from "../store/auth/data-interface-for-user-auth";
import { CognitoUser } from "@aws-amplify/auth";
import { API, Auth } from "aws-amplify";
import * as queries from "../graphql/queries";
import { DataLoadingState, UserAccount, UserRole } from "../models";
import useDataForUserAuth from "../store/auth/data-for-auth";
import { useNavigate } from "react-router-dom";
import useRoutePaths from "../routes/route-path";
import { usePostCategoryServiceAPI } from "../pages/page-settings/page-main-sub-categories/services/services-for-post-categories";
import { usePostSubCategoryServiceAPI } from "../pages/page-settings/page-main-sub-categories/services/services-for-sub-post-categories";
import { useFoldersFilesServiceAPI } from "../pages/page-files-manager/services/services-for-folders-files";
import isDevMode from "../utils/check-dev-mode";
import { useServiceForCMSUsersAPI } from "../pages/page-cms-users/services/services-for-cms-users";

interface UseServiceAuthenticationInterface {
  signIn: (info: SignInInfo) => Promise<Error | null>;
  signUpCMS: (params: {
    info: UserAccount;
    password: string;
    userRole: string;
  }) => Promise<boolean | Error>;
  getUser: (
    useSignInfo: boolean,
    signInInfo: SignInInfo | null
  ) => Promise<boolean>;
  confirmOtp: (info: SignInInfo, code: string) => Promise<boolean>;
  signOut: () => Promise<void>;
}

const AuthAction = {
  signInWebsite: "signInWebsite",
  signInCMS: "signInCMS",
  signUpCMS: "signUpCMS",
  confirmOtp: "confirmOtp",
  otpCodeResend: "otpCodeResend",
  none: "none",
};

const AppOrigins = {
  cms: "CMS",
  website: "WEBSITE",
};

export function useServiceAuthentication(): UseServiceAuthenticationInterface {
  const setLoadingCredentials = useDataForUserAuth(
    (state) => state.setLoadingCredentials
  );
  const setLoggedUser = useDataForUserAuth((state) => state.setLoggedUser);
  const { listAllPostCategories } = usePostCategoryServiceAPI();
  const { listAllPostSubCategories } = usePostSubCategoryServiceAPI();
  const { listAllFolders } = useFoldersFilesServiceAPI();

  const { updateData } = useServiceForCMSUsersAPI();

  const loggedInUser = useDataForUserAuth((state) => state.loggedUser);

  var loggedUser: LoggedUser = {
    isInSession: false,
    opStatus: DataLoadingState.LOADING,
    staffProfile: null,
    userAccount: null,
    userInfoAttributes: null,
    userRole: null,
    currentSession: null,
    currentUser: null,
    currentUserInfo: null,
  };

  async function signIn(info: SignInInfo) {
    try {
      await Auth.signIn({
        username: info.username,
        password: info.password,
        validationData: {
          action: AuthAction.signInCMS,
          appOrigin: AppOrigins.cms,
        },
      });
      return null;
    } catch (error) {
      console.log("error signing in", error);
      return error as Error;
    }
  }

  async function addUserToGroup(params: { username: string }) {
    let success: boolean = false;
    try {
      const apiName = "AdminQueries";
      const path = "/addUserToGroup";
      const myInit = {
        body: {
          username: params.username,
          groupname: "CmsUsers",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
      };

      await API.post(apiName, path, myInit)
        .then((response) => {
          success = true;
        })
        .catch((error) => {
          console.log(error.response);
          success = false;
        });

      return success;
    } catch (error) {
      console.log(error);
      success = false;
      return success;
    }
  }

  async function signUpCMS(params: {
    info: UserAccount;
    password: string;
    userRole: string;
  }) {
    const { info, password, userRole } = params;
    let success = false;
    try {
      if (isDevMode()) {
        console.log("loggedUser", loggedInUser);
      }

      await Auth.signUp({
        username: info.contactEmail1!,
        password: password,
        attributes: {
          email: info.contactEmail1!,
          phone_number: info.contactPhoneNumber1,
          given_name: info.nameFirst,
          family_name: info.nameLast,
          name: info.nameFull,
        },
        validationData: {
          action: AuthAction.signUpCMS,
          otpCode: "",
          userRole: userRole,
          loggedInUsername: loggedInUser?.currentUserInfo?.attributes?.sub,
          userTemporaryPassword: password,
        },
      }).then(async (results) => {
        if (isDevMode()) {
          console.log("results signUpCMS is", results);
        }
        const userSub = results.userSub;

        let infoUpdated = { ...info, authId: userSub, owner: userSub };

        if (isDevMode()) {
          console.log("results signUpCMS infoUpdated is:", infoUpdated);
        }

        await addUserToGroup({ username: userSub })
          .then(async (results) => {
            if (results == true) {
              await updateData(infoUpdated!, false, true).then(
                async (infoUpdatedDb) => {
                  if (isDevMode()) {
                    console.log("infoUpdatedDb:", infoUpdatedDb != null);
                  }

                  if (infoUpdatedDb != null) {
                    success = true;
                  } else {
                    success = false;
                  }
                }
              );
              return;
            }

            success = false;
          })
          .catch((error) => {
            console.log("error signUpCMS addUserToGroup", error);
            const err = error as Error;
            return err;
          });
      });

      return success;
    } catch (error) {
      console.log("error signUpCMS", error);
      const err = error as Error;
      return err;
    }
  }

  async function signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  async function confirmOtp(info: SignInInfo, code: string) {
    const username: string = info.username.trim();
    const password: string = info.password.trim();
    console.log(username, password);
    try {
      await Auth.signIn({
        username: username,
        password: info.password,
        validationData: {
          action: AuthAction.confirmOtp,
          otpCode: code,
        },
      });
      return true;
    } catch (error) {
      console.log("error confirmOtp is", error);
      return false;
    }
  }

  const getAppUserAccount = async (
    useSignInfo: boolean,
    signInInfo: SignInInfo | null
  ) => {
    let email: string | undefined = "";

    try {
      if (useSignInfo) {
        email = signInInfo?.username;
      } else {
        email = loggedUser.currentUserInfo?.attributes?.email;
      }

      const variables = {
        filter: {
          and: [
            { contactEmail1: { eq: email } },
            // {
            //   deleted: {
            //     eq: false,
            //   },
            // },
          ],
        },
      };
      console.log("getAppUserAccount email is", email);

      const findUserAccounts: any = await API.graphql({
        query: queries.listUserAccounts,
        variables: variables,
      });
      console.log("findUserAccounts is", findUserAccounts);

      if (findUserAccounts.data != undefined) {
        let foundAccounts: UserAccount[] =
          findUserAccounts.data.listUserAccounts.items;
        const foundAccount: UserAccount | null = foundAccounts[0];
        const foundUserRole: UserRole | null =
          foundAccount.userRole as unknown as UserRole;
        console.log("foundUserAccount is", foundAccount);
        console.log("foundUserRole is", foundUserRole);

        if (foundAccount == null) {
          loggedUser = { ...loggedUser, userAccount: null, userRole: null };
          console.log("foundLoggedUser is null", foundAccount);
        } else {
          console.log("foundLoggedUser is not null", foundAccount);
          loggedUser = {
            ...loggedUser,
            userAccount: foundAccount,
            userRole: foundUserRole,
          };
        }
      } else {
        loggedUser = { ...loggedUser, userAccount: null, userRole: null };
        console.log("error1 getCurrentUserAccount is", loggedUser);
      }
    } catch (error) {
      loggedUser = { ...loggedUser, userAccount: null, userRole: null };
      console.log("foundLoggedUser error", error);
    }
  };

  const getUser = async (
    useSignInfo: boolean,
    signInInfo: SignInInfo | null
  ) => {
    const getCurrentUser = new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then((user: CognitoUser | any) => {
          console.log("  c.user is", user);
          loggedUser = { ...loggedUser, currentUser: user };
          resolve(user);
        })
        .catch((e) => {
          console.log("error getting c.user is", e);
          loggedUser = { ...loggedUser, currentUser: null };
          reject(null);
        });
    });

    const getCurrentSession = new Promise((resolve, reject) => {
      Auth.currentSession()
        .then((session: any) => {
          console.log("  c.session is", session);
          loggedUser = {
            ...loggedUser,
            currentSession: session,
            isInSession: true,
          };
          resolve(session);
        })
        .catch((e) => {
          console.log("error getting c.session is", e);
          loggedUser = { ...loggedUser, currentSession: null };
          reject(null);
        });
    });

    const getCurrentUserInfo = new Promise(async (resolve, reject) => {
      Auth.currentUserInfo()
        .then((currentUserInfo: any) => {
          console.log("currentUserInfo is", currentUserInfo);
          loggedUser = { ...loggedUser, currentUserInfo: currentUserInfo };
          resolve(currentUserInfo);
        })
        .catch((e) => {
          console.log("error getting currentUser is", e);
          reject(null);
        });
    });

    const findAuthCredentials = async () => {
      return await Promise.all([
        getCurrentUser,
        getCurrentSession,
        getCurrentUserInfo,
      ]).catch((_) => {
        // console.log('err with fxn', err)
      });
    };

    await findAuthCredentials().then(async (_) => {
      await getAppUserAccount(useSignInfo, signInInfo).then((_) => {
        console.log("loggedUser", loggedUser);

        const userObject = {
          id: loggedUser?.userAccount?.id,
          email: loggedUser?.userAccount?.contactEmail1,
          nameFirst: loggedUser?.userAccount?.nameFirst,
          nameLast: loggedUser?.userAccount?.nameLast,
          phoneNumber: loggedUser?.userAccount?.contactPhoneNumber1,
          addedOn: loggedUser?.userAccount?.dateAdded,
          userRoleID: loggedUser?.userAccount?.userRoleID,
          status: loggedUser?.userAccount?.status,
          userRole: loggedUser?.userRole?.title,
          dateLastLogin: loggedUser?.userAccount?.dateLastLogin,
        };

        localStorage.setItem("userObject", JSON.stringify(userObject));

        loggedUser = { ...loggedUser, opStatus: DataLoadingState.LOADED };

        setLoggedUser(loggedUser);

        setLoadingCredentials(DataLoadingState.LOADED);

        // TODO:BRING THIS BACK LATER
        // if (loggedUser.isInSession == false) {
        //   navigation(route.authSignIn, { replace: true });
        //   return;
        // }

        listAllPostCategories();
        listAllPostSubCategories();
        listAllFolders();
      });
    });

    return true;
  };

  return {
    signIn,
    signOut,
    signUpCMS,
    getUser,
    confirmOtp,
  };
}
