import * as Yup from "yup";

export const SchemaForStaffUnitDepartment = Yup.object().shape({
  name: Yup.string()
    .min(1, "Too Short!")
    .max(100, "Too Long!")
    .required("Required"),
  slug: Yup.string().required("Required"),
  crigDivisionSubstationID: Yup.string().required("Required"),
  shortDescription: Yup.string()
    .min(1, "Too Short!")
    .max(1000, "Too Long!")
    .optional(),
});
