import produce from "immer";
import { create } from "zustand";
import { ArticlePublication } from "../../../models";
import { PageDataInterfaceArticlesPublicationsForm } from "./data-interface-for-articles-publications";
import { EditorType, PageType, RecordStatus } from "../../../models";
import { VisibilityType } from "../../../models";
import { v4 as getId } from "uuid";


const defaultData = {
  title: "",
  id: getId(),
  shortDescription: "",
  pageDesignType: EditorType.TEXT_EDITOR,
  pageDesignTextEditor: "",
  pageDesignPageBuilder: "",
  pageType: PageType.ARTICLE_PUBLICATION,
  postSubCategoryID: "",
  postCategoryID: "",
  galleryImages: null,
  coverImage: null,
  documents: null,
  tags: null,
  volumeNumber: "NA",
  editionNumber: "NA",
  authorStaffProfilesIds: [],
  issueNumber: "NA",
  issn: "NA",
  slug: "",
  visibilityType: VisibilityType.NONE,
  status: RecordStatus.DRAFT,
  datePublished: new Date().toISOString(),
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  dateUploaded: new Date().toISOString(),
};

const useDataArticlePublicationsForm =
  create<PageDataInterfaceArticlesPublicationsForm>()((set, get) => {
    return {
      showDialog: false,
      setFormData: async (item) => {
        set((_) => ({ formData: item }));
        return true;
      },
      formActionType: undefined,
      setShowDialog: async (value) => {
        set((_) => ({ showDialog: value }));
        return true;
      },
      formData: defaultData as unknown as ArticlePublication,
      setFormActionType: async (item) => {
        set((_) => ({ formActionType: item }));
        return true;
      },
      reset: async () => {
        const newData = { ...defaultData, id: getId() }
        set((_) => ({
          formData: newData as unknown as ArticlePublication,
        }));
        return true;
      },
      updateFormDataField: async (property, value) => {
        set(
          produce((state: PageDataInterfaceArticlesPublicationsForm) => {
            state.formData![property] = value;
          })
        );
        return true;
      },
    };
  });

export default useDataArticlePublicationsForm;
