import DateTimeDisplay from "./DateTimeDisplay";
import { useCountdown } from "./useCountdown";



const ShowCounter = ({ minutes, seconds, isDanger }) => {
  const classes = "t-text-md md:t-text-lg";
  return (
    <div className="show-counter">
      <div className="countdown-link t-flex t-text-right t-justify-center t-items-center">
        <DateTimeDisplay
          classes={classes}
          value={minutes}
          isDanger={isDanger}
        />
        <DateTimeDisplay
          classes={classes}
          value=':'
          isDanger={isDanger}
        />
        <DateTimeDisplay
          classes={classes}
          value={seconds}
          isDanger={isDanger}
        />
      </div>
    </div>
  );
};

const CountdownTimer = ({ targetDate }) => {

  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (Number(days) + Number(hours) + Number(minutes) + Number(seconds) <= 0) {
    return <ShowCounter minutes={"00"} seconds={"00"} isDanger={true} />;
  } else {
    let minutesNew = "00";
    let secondsNew = `${seconds}`;
    let isDanger = false;
    minutesNew = `0${minutes}`;

    if (Number(seconds) < 10) {
      secondsNew = `0${seconds}`;
    }

    if (minutes == 0 && seconds <= 30) {
      isDanger = true;
    }

    return (
      <ShowCounter
        minutes={minutesNew}
        seconds={secondsNew}
        isDanger={isDanger}
      />
    );
  }
};

export default CountdownTimer;
