import { ColProps } from "../../shared-interfaces/dashboard/dashboard-interfaces";
import SectionHeader from "../header/SectionHeader";
import DashboardComponentContent from "./DashboardComponentContent";

interface Props {
  title: string;
  col1: ColProps;
  col2: ColProps;
  col3: ColProps;
  col4: ColProps;
}
function DashboardComponent(props: Props) {
  return (
    <div>
      <SectionHeader title={props.title} />
      <div
        className="t-flex t-flex-col md:flex-row t-items-center 
      t-justify-evenly t-w-full t-h-2/4 t-bg-white t-rounded t-my-4 t-shadow-md "
      >
        <DashboardComponentContent
          border={true}
          upperIcon={props.col1.upperIcon}
          lowerIcon={props.col1.lowerIcon}
          upperTitle={props.col1.upperTitle}
          lowerTitle={props.col1.lowerTitle}
          upperCount={props.col1.upperCount}
          lowerCount={props.col1.lowerCount}
          upperCountUpdate={props.col1.upperCountUpdate}
          lowerCountUpdate={props.col1.lowerCountUpdate}
        />
        <DashboardComponentContent
          border={true}
          upperIcon={props.col2.upperIcon}
          lowerIcon={props.col2.lowerIcon}
          upperTitle={props.col2.upperTitle}
          lowerTitle={props.col2.lowerTitle}
          upperCount={props.col2.upperCount}
          lowerCount={props.col2.lowerCount}
          upperCountUpdate={props.col2.upperCountUpdate}
          lowerCountUpdate={props.col2.lowerCountUpdate}
        />
        <DashboardComponentContent
          border={true}
          upperIcon={props.col3.upperIcon}
          lowerIcon={props.col3.lowerIcon}
          upperTitle={props.col3.upperTitle}
          lowerTitle={props.col3.lowerTitle}
          upperCount={props.col3.upperCount}
          lowerCount={props.col3.lowerCount}
          upperCountUpdate={props.col3.upperCountUpdate}
          lowerCountUpdate={props.col3.lowerCountUpdate}
        />
        <DashboardComponentContent
          border={false}
          upperIcon={props.col4.upperIcon}
          lowerIcon={props.col4.lowerIcon}
          upperTitle={props.col4.upperTitle}
          lowerTitle={props.col4.lowerTitle}
          upperCount={props.col4.upperCount}
          lowerCount={props.col4.lowerCount}
          upperCountUpdate={props.col4.upperCountUpdate}
          lowerCountUpdate={props.col4.lowerCountUpdate}
        />
      </div>
    </div>
  );
}

export default DashboardComponent;
