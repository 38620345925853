import { create } from "zustand";
import { SignInInfo } from "../../../store/auth/data-interface-for-user-auth";

export interface AuthPagesDataInterface {
    signInInfo: SignInInfo;
    numberSentTo: string;
    setSignInInfo: (info: SignInInfo) => Promise<boolean>,
    setNumberSentTo: (sentTo: string) => Promise<boolean>,
    timedOut: boolean,
    time: number,
    setTime: (value: number) => Promise<boolean>,
    setTimedOut: (value: boolean) => Promise<boolean>,
}



export const useDataAuthPages =
    create<AuthPagesDataInterface>()((set, get) => {
        return {
            signInInfo: {
                password: '',
                username: '',
            },
            timedOut: false,
            time: 0,
            setTimedOut: async (value) => {
                set((_) => ({ timedOut: value }));
                return true;
            },
            setTime: async (value) => {
                set((_) => ({ time: value }));
                return true;
            },
            numberSentTo: '',
            setNumberSentTo: async (sentTo) => {
                set((_) => ({ numberSentTo: sentTo }));
                return true;
            },
            setSignInInfo: async (info) => {
                set((_) => ({ signInInfo: info }));
                return true;
            },
        }
    });
