import { create } from "zustand";
import { DataLoadingState, Folder, S3UploadedItem } from "../../models";
import { Storage } from "aws-amplify";
import isDevMode from "../../utils/check-dev-mode";

interface onUploadInterface {
  file: File;
  folder: Folder;
  fileSize: number;
  postId: string;
  encodedBlurhash: string | null;
}

interface CoverImageDataInterface {
  onUpload: ({
    file,
    folder,
    fileSize,
    postId,
    encodedBlurhash,
  }: onUploadInterface) => Promise<DataLoadingState | undefined>;
  onSelect: (file: File | null) => Promise<boolean>;
  onError: () => Promise<String>;
  onClear: () => Promise<boolean>;
  onCrop: (croppedFile: File) => Promise<boolean | undefined>;
  file: File | null | undefined;
  findS3Object: (key: string) => Promise<Blob | null>;
  getS3URL: (key: string) => Promise<string | null>;
  s3Item: S3UploadedItem | null | undefined;
  setS3Item: (s3Item: S3UploadedItem) => Promise<boolean>;
  uploadStatus: DataLoadingState | undefined;
  reset: () => Promise<boolean>;
  // uploadProgress:
}

const useDataForCoverImageFile = create<CoverImageDataInterface>()(
  (set, get) => {
    return {
      file: null,
      s3Item: null,
      uploadStatus: DataLoadingState.PENDING,
      setS3Item: async (s3Item) => {
        set((_) => ({ s3Item: s3Item }));
        return true;
      },
      findS3Object: async (key) => {
        var blob: any = null;
        await Storage.get(key, {
          level: "public",
          download: true,
          // contentType: 'image/*'
        })
          .then((result) => {
            blob = result.Body!;
            return blob;
          })
          .catch((err) => {
            console.log(err);
            blob = null;
            return blob;
          });

        return blob;
      },
      getS3URL: async (key) => {
        var url: any = null;
        await Storage.get(key, {
          level: "public",
          // contentType: 'image/*'
        })
          .then((result) => {
            url = result;
            return url;
          })
          .catch((err) => {
            console.log(err);
            url = null;
            return url;
          });

        return url;
      },
      onUpload: async ({
        file,
        folder,
        fileSize,
        postId,
        encodedBlurhash,
      }: onUploadInterface) => {
        try {
          if (isDevMode()) {
            console.log(
              `onUpload file type ${file.type}, ${file}, ${folder}, ${fileSize}, ${postId}, ${encodedBlurhash} `
            );
          }
          const result = await Storage.put(`${folder.slug}/${postId}`, file, {
            level: "public",
            contentType: file.type,
            completeCallback: (event) => {
              console.log(`Successfully uploaded event ${event}`);
              console.log(`Successfully uploaded ${event.key}`);
            },
            progressCallback: (progress) => {
              console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            },
            errorCallback: (err) => {
              console.error("Unexpected error while uploading", err);
            },
          });
          if (result != null) {
            if (result.key.length > 0) {
              const url = await get().getS3URL(result.key);

              const newS3Item: S3UploadedItem = {
                key: result.key,
                size: fileSize,
                lastModified: new Date().toISOString(),
                signedURL: url,
                blurHash: encodedBlurhash,
                eTag: `${folder.slug}/${file.name}`,
              };

              set((_) => ({ s3Item: newS3Item }));
              console.log("onUpload results with key", newS3Item);
            }
            set((_) => ({ uploadStatus: DataLoadingState.LOADED }));
            return DataLoadingState.LOADED;
          }

          set((_) => ({ s3Item: null }));
          console.log("onUpload results", result);
          set((_) => ({ uploadStatus: DataLoadingState.ERROR }));
          return DataLoadingState.ERROR;
        } catch (error) {
          console.error("Unexpected error while uploading", error);
          set((_) => ({ uploadStatus: DataLoadingState.ERROR }));
          return DataLoadingState.ERROR;
        }
      },
      onSelect: async (item) => {
        set((_) => ({ file: item }));
        return true;
      },
      onError: async () => {
        return "error Message";
      },
      onClear: async () => {
        set((_) => ({ file: null }));
        return true;
      },
      onCrop: async (item) => {
        set((_) => ({ file: item }));
        return true;
      },
      reset: async () => {
        set((_) => ({ file: null, s3Item: null }));
        return true;
      },
    };
  }
);

export default useDataForCoverImageFile;
