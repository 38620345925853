import { useNavigate } from "react-router-dom";
import useDataForTextEditor from "../../../editors/data-for-editor";
import { FormActionType } from "../../../models";
import useRoutePaths from "../../../routes/route-path";
import TableAddNewButton from "../../../shared-components/button/TableAddNewButton";
import FileManagerListItem from "../../../shared-components/file/file-manager/FileManagerListItem";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataForGalleryImagesFile from "../../../shared-components/images/data-gallery-images";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import useDataFilesManagerFile from "../data/data-for-folder-file-form";
import { useFoldersFilesServiceAPI } from "../services/services-for-folders-files";
import { useEffect } from "react";
import useDataFilesManagerList from "../data/data-for-folders-list";
import { DataLoadingState } from "../../../models";
import { getFolderColor } from "../../../utils/folerColors";

function ListFileManager() {
  const { listAllFolders } = useFoldersFilesServiceAPI();
  const listItemsFolders = useDataFilesManagerList(
    (state) => state.listItemsFolders
  );

  const loadingStatus = useDataFilesManagerList(
    (state) => state.statusForLoadingListItemsFolders
  );

  const setStateLoadingStatus = useDataFilesManagerList(
    (state) => state.setStatusForLoadingListItemsFolders
  );

  console.log("listItemsFolders", listItemsFolders);

  const { paths: route } = useRoutePaths();
  const navigation = useNavigate();
  const setFormActionType = useDataFilesManagerFile(
    (state) => state.setFormActionType
  );
  const galleryImageReset = useDataForGalleryImagesFile((state) => state.reset);
  const coverImageReset = useDataForCoverImageFile((state) => state.reset);
  const editorReset = useDataForTextEditor((state) => state.reset);

  useEffect(() => {
    let fetching = true;

    const fetchListData = async () => {
      await listAllFolders();
    };

    if (fetching) {
      if (
        listItemsFolders.length == 0 &&
        loadingStatus == DataLoadingState.PENDING
      ) {
        setStateLoadingStatus(DataLoadingState.LOADING);
        fetchListData();
      }
    }

    return () => {
      fetching = false;
    };
  }, [loadingStatus, listItemsFolders]);

  return (
    <div
      className="flex t-flex-col t-overflow-hidden t-justify-start 
     t-h-fit t-rounded-lg t-shadow-lg t-m-10"
    >
      <div className="">
        <CustomNavigation background={false} title="File Manager" />
      </div>
      <div
        className="t-overflow-x-scroll t-flex t-flex-wrap 
      folder-manager t-py-6"
      >
        {listItemsFolders?.map((item) => (
          <div id={item.id}>
            <FileManagerListItem
              name={item.title}
              iconColor={getFolderColor(item.title)}
              onClick={() => {
                navigation(route.fileManager.listFiles);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ListFileManager;
