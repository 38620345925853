export const getFolderColor = (title: string) => {
  // program using switch statement
  let color = "";

  switch (title) {
    case "Articles/Publications":
      return (color = "#ad691f");

    case "Division/Sub-Stations":
      return (color = "#bb785c");

    case "Recreation Facilities":
      return (color = "#ad692f");

    case "News/Announcements":
      return (color = "#ff0624");

    case "Products":
      return (color = "#ad693f");

    case "Newsletters":
      return (color = "#ad091f");

    case "CRIG Staff":
      return (color = "#bb285c");

    case "Mandated Crops":
      return (color = "#ad694f");

    case "Events/Gallery":
      return (color = "#000000");

    case "External Services":
      return (color = "#ad695f");

    case "Research Activities":
      return (color = "#ad699f");

    case "About CRIG":
      return (color = "#ad696f");

    default:
      return (color = "#1464f6");
  }
  //   console.log(`The value is ${a}`);
};
