import { useNavigate } from "react-router-dom";
import useDataForTextEditor from "../../../../editors/data-for-editor";
import { FormActionType } from "../../../../models";
import useRoutePaths from "../../../../routes/route-path";
import TableAddNewButton from "../../../../shared-components/button/TableAddNewButton";
import useDataForCoverImageFile from "../../../../shared-components/images/data-cover-image";
import CustomNavigation from "../../../../shared-components/navigation/CustomNavigation";
import useDataDivisionSubStationsForm from "../data/data-for-divisions-sub-stations-form";
import TableDivisionsSubStations from "../tables/table-for-divisions-sub-stations";

function ListDivisionsSubStations() {
    const { paths: route } = useRoutePaths();
    const navigation = useNavigate();
    const setFormActionType = useDataDivisionSubStationsForm(
        (state) => state.setFormActionType
    );
    const resetForm = useDataDivisionSubStationsForm(
        (state) => state.reset
    );

    const coverImageReset = useDataForCoverImageFile((state) => state.reset);
    const editorReset = useDataForTextEditor((state) => state.reset);

    return (
        <div className="flex t-flex-col t-overflow-hidden t-justify-start t-h-fit t-rounded-lg t-shadow-lg t-m-10">
            <div className="">
                <CustomNavigation
                    background={false}
                    backArrow={true}
                    backArrowNavUrl={route.settings.initial}
                    title="CRIG Divisions/Sub-Stations"
                    subTitle="Settings"
                    buttons={
                        <TableAddNewButton label='Add New' onClick={() => {
                            coverImageReset()
                            editorReset()
                            setFormActionType(FormActionType.NEW);
                            resetForm()
                            navigation(route.settings.crigDivisionsSubStations.details);
                        }} />
                    }

                />
            </div>
            <TableDivisionsSubStations />
        </div>
    );
}

export default ListDivisionsSubStations;
