import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

interface Props {
    show: boolean;
    errorMsg: string;
    onHide: () => void;
}
const ErrorDialog = ({ show, errorMsg, onHide }: Props) => {

    const footer = (
        <div>
            <Button label="Okay" onClick={onHide} />

        </div>
    );

    return (
        <Dialog closable={false} footer={footer} showHeader={true} header={'ATTENTION'} resizable={false}
            className='error-dialog surface-ground t-min-h-[200px]
          t-h-full t-max-h-[250px] t-w-[90vw] md:t-max-w-[20vw] t-rounded-xl t-overflow-hidden
           t-flex t-flex-col t-justify-start t-items-center' visible={show} onHide={() => { }}>
            <div className="t-mt-[0px] t-px-5">
                <p className="t-font-inter t-font-medium t-text-center">{errorMsg}</p>
            </div>
        </Dialog>
    )
}

export default ErrorDialog



