import { create } from "zustand";
import produce from "immer";
import { DataLoadingState, Page } from "../../../../models";
import { PageDataInterfaceCRIGDivisionsSubstationsList } from "./data-interface-for-divisions-sub-stations";

const useDataDivisionAndSubstationsList =
  create<PageDataInterfaceCRIGDivisionsSubstationsList>()((set, get) => {
    return {
      statusForLoadingListItems: DataLoadingState.PENDING as
        | DataLoadingState
        | undefined,
      listItems: [],

      setStatusForLoadingListItems: async (newValue) => {
        set((_) => ({ statusForLoadingListItems: newValue }));
        return true;
      },
      setListItems: async (items) => {
        set((_) => ({ listItems: items }));
        return true;
      },
      updateItem: async (newItem) => {
        const newItemRecord = newItem as Page;
        set(
          produce((state: PageDataInterfaceCRIGDivisionsSubstationsList) => {
            function findIndexFxn(item: Page) {
              return item.id === newItemRecord.id;
            }

            const index = state.listItems.findIndex(findIndexFxn);
            console.log('index updateItem useDataDivisionAndSubstationsList', index, newItemRecord)
            state.listItems[index] = newItemRecord;
          })
        );

        return true;
      },

      deleteItem: async (newItem) => {
        set(
          produce((state: PageDataInterfaceCRIGDivisionsSubstationsList) => {
            function findIndexFxn(item: Page) {
              return item.id === newItem.id;
            }

            const index = get().listItems.findIndex(findIndexFxn);
            state.listItems.splice(index, 1);
          })
        );

        return true;
      },

      addItem: async (newItem) => {
        let newItemRecord = newItem as Page;
        set(
          produce((state: PageDataInterfaceCRIGDivisionsSubstationsList) => {
            state.listItems.unshift(newItemRecord);
          })
        );

        return true;
      },
    };
  });

export default useDataDivisionAndSubstationsList;
