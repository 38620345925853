import { useNavigate } from "react-router-dom";
import useRoutePaths from "../../../../routes/route-path";
import { FormActionType } from "../../../../models";
import TableAddNewButton from "../../../../shared-components/button/TableAddNewButton";
import CustomNavigation from "../../../../shared-components/navigation/CustomNavigation";
import FormUserRole from "../form/form-for-user-roles";
import TableStaffProfile from "../tables/table-for-user-roles";
import useDataUserRolesForm from "../data/data-for-user-roles-form";
import useDataForTextEditor from "../../../../editors/data-for-editor";

function ListStaffProfile() {
  const { paths: route } = useRoutePaths();
  const navigation = useNavigate();
  const setFormActionType = useDataUserRolesForm(
    (state) => state.setFormActionType
  );

  const setShowDialog = useDataUserRolesForm((state) => state.setShowDialog);
  const showDialog = useDataUserRolesForm((state) => state.showDialog);
  const editorReset = useDataForTextEditor((state) => state.reset);
  const resetForm = useDataUserRolesForm((state) => state.reset);

  return (
    <div className="flex t-flex-col t-overflow-hidden t-justify-start t-h-fit t-rounded-lg t-shadow-lg md:t-m-10 t-m-3">
      <div className="">
        <CustomNavigation
          background={false}
          title="User Roles"
          buttons={
            <TableAddNewButton
              label="Add New"
              onClick={() => {
                editorReset();
                setFormActionType(FormActionType.NEW);
                resetForm();
                navigation(route.settings.userRoles.details);
              }}
            />
          }
        />
      </div>
      {showDialog && <FormUserRole />}
      <TableStaffProfile />
    </div>
  );
}

export default ListStaffProfile;
