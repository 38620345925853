import { Fragment, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import useRoutePaths from "../../../../routes/route-path";

import MainLayoutSideBarNav from "./MainLayoutSideNavBar";
import MainLayoutTopNav from "./MainLayoutTopNav";

type Props = {
  children?: ReactNode;
};

const MainLayout = ({ children }: Props) => {
  let location = useLocation();
  let currentPage = location.pathname;
  const { paths: route } = useRoutePaths();

  const routesToNotByPass = [
    "/",
    route.authSignIn,
    route.auth2fa,
    // route.dashboard,
    // route.editorSwitch,
    // route.editorSwitchLayoutSelection,
    // route.articlesPublications.list,
    // route.articlesPublications.details,
  ];

  if (routesToNotByPass.includes(currentPage)) {
    return (
      <Fragment>
        <main>{<div className="">{children}</div>}</main>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="min-h-screen t-flex relative lg:static surface-ground">
        <MainLayoutSideBarNav />
        <div className=" t-flex t-flex-col t-flex-grow">
          <div className="t-sticky t-top-0 t-z-10">
            <MainLayoutTopNav />
          </div>
          <main className="t-bg-appmainview t-flex-grow t-flex t-h-[calc(100vh-60px)]">
            <div className="flex flex-column flex-auto">{children}</div>
          </main>
        </div>
      </div>
    </Fragment>
  );
};

export default MainLayout;
