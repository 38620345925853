import produce from "immer";
import { create } from "zustand";
import { DataLoadingState, Folder, S3UploadedItem, } from "../../../models";
import { PageDataInterfaceFoldersFilesList } from "./data-interface-for-files-manager";

const useDataFilesManagerList =
  create<PageDataInterfaceFoldersFilesList>()((set, get) => {
    return {
      statusForLoadingListItemsFolders: DataLoadingState.PENDING as
        | DataLoadingState
        | undefined,
      statusForLoadingListItemsFiles: DataLoadingState.PENDING as
        | DataLoadingState
        | undefined,
      listItemsFolders: [],
      listItemsFiles: [],
      setStatusForLoadingListItemsFolders: async (newValue) => {
        set((_) => ({ statusForLoadingListItemsFolders: newValue }));
        return true;
      },
      setListItemsFiles: async (newValue) => {
        set((_) => ({ listItemsFiles: newValue }));
        return true;
      },
      setListItemsFolders: async (newValue) => {
        set((_) => ({ listItemsFolders: newValue }));
        return true;
      },
      setStatusForLoadingListItemsFiles: async (newValue) => {
        set((_) => ({ statusForLoadingListItemsFiles: newValue }));
        return true;
      },

      updateItemFolder: async (newItem) => {
        const newItemRecord = newItem as Folder;
        set(
          produce((state: PageDataInterfaceFoldersFilesList) => {
            function findIndexFxn(item: Folder) {
              return item.id === newItemRecord.id;
            }
            const index = state.listItemsFolders.findIndex(findIndexFxn);
            state.listItemsFolders[index] = newItemRecord;
          })
        );

        return true;
      },
      findOneFolderByPageType: async (pageType) => {
        function findIndexFxn(item: Folder) {
          return item.pageType === pageType;
        }
        const index = get().listItemsFolders.findIndex(findIndexFxn);
        const foundItem = get().listItemsFolders[index]

        return foundItem;
      },
      updateItemFile: async (newItem) => {
        const newItemRecord = newItem as S3UploadedItem;
        set(
          produce((state: PageDataInterfaceFoldersFilesList) => {
            function findIndexFxn(item: S3UploadedItem) {
              return item.key === newItemRecord.key;
            }
            const index = state.listItemsFiles.findIndex(findIndexFxn);
            state.listItemsFiles[index] = newItemRecord;
          })
        );

        return true;
      },

      deleteItemFolder: async (newItem) => {
        set(
          produce((state: PageDataInterfaceFoldersFilesList) => {
            function findIndexFxn(item: Folder) {
              return item.id === newItem.id;
            }

            const index = get().listItemsFolders.findIndex(findIndexFxn);
            state.listItemsFolders.splice(index);
          })
        );

        return true;
      },
      deleteItemFile: async (newItem) => {
        set(
          produce((state: PageDataInterfaceFoldersFilesList) => {
            function findIndexFxn(item: S3UploadedItem) {
              return item.key === newItem.key;
            }

            const index = get().listItemsFiles.findIndex(findIndexFxn);
            state.listItemsFiles.splice(index);
          })
        );

        return true;
      },

      addItemFolder: async (newItem) => {
        let newItemRecord = newItem as Folder;
        set(
          produce((state: PageDataInterfaceFoldersFilesList) => {
            state.listItemsFolders.push(newItemRecord);
          })
        );

        return true;
      },

      addItemFile: async (newItem) => {
        let newItemRecord = newItem as S3UploadedItem;
        set(
          produce((state: PageDataInterfaceFoldersFilesList) => {
            state.listItemsFiles.push(newItemRecord);
          })
        );

        return true;
      },
    };
  });

export default useDataFilesManagerList;
