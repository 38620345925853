export const createArticlePublicationGQL = /* GraphQL */ `
  mutation CreateArticlePublication(
    $input: CreateArticlePublicationInput!
    $condition: ModelArticlePublicationConditionInput
  ) {
    createArticlePublication(input: $input, condition: $condition) {
      id
      title
      shortDescription
      pageDesignType
      authorStaffProfilesIds
      pageDesignTextEditor
      publishersIds
      pageDesignPageBuilder
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      tags
      status
      deleted
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalDownloadsCount
      postCategoryID
      postSubCategoryID
      volumeNumber
      editionNumber
      issueNumber
      issn
      creatorUserAccountID
      visibilityType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const deleteArticlePublicationGQL = /* GraphQL */ `
  mutation DeleteArticlePublication(
    $input: DeleteArticlePublicationInput!
    $condition: ModelArticlePublicationConditionInput
  ) {
    deleteArticlePublication(input: $input, condition: $condition) {
      id
      title
      shortDescription
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      tags
      status
      deleted
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalDownloadsCount
      postCategoryID
      postSubCategoryID
      volumeNumber
      editionNumber
      authorStaffProfilesIds
      issueNumber
      issn
      creatorUserAccountID
      visibilityType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateArticlePublicationGQL = /* GraphQL */ `
  mutation UpdateArticlePublication(
    $input: UpdateArticlePublicationInput!
    $condition: ModelArticlePublicationConditionInput
  ) {
    updateArticlePublication(input: $input, condition: $condition) {
      id
      title
      shortDescription
      pageDesignType
      authorStaffProfilesIds
      pageDesignTextEditor
      publishersIds
      pageDesignPageBuilder
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      tags
      status
      deleted
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalDownloadsCount
      postCategoryID
      postSubCategoryID
      volumeNumber
      editionNumber
      issueNumber
      issn
      creatorUserAccountID
      visibilityType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listArticlePublicationsGQL = /* GraphQL */ `
  query ListArticlePublications(
    $filter: ModelArticlePublicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticlePublications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        authorStaffProfilesIds
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        publishers {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
        }
        postSubCategoryID
        postSubCategory {
          id
          name
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getArticlePublicationGQL = /* GraphQL */ `
  query GetArticlePublication($id: ID!) {
    getArticlePublication(id: $id) {
      id
      title
      shortDescription
      authorStaffProfilesIds
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      tags
      authorStaffProfiles {
        nextToken
        startedAt
      }
      publishers {
        nextToken
        startedAt
      }
      authorStaffProfilesIds
      publishersIds
      status
      deleted
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalDownloadsCount
      postCategoryID
      postCategory {
        id
        name
      }
      postSubCategoryID
      postSubCategory {
        id
        name
      }
      volumeNumber
      editionNumber
      issueNumber
      issn
      userViewsDownloads {
        nextToken
        startedAt
      }
      creatorUserAccountID
      visibilityType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
