import * as cropro from "cropro";
import { Button } from "primereact/button";
import { FileUpload, ItemTemplateOptions } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useRef, useState } from "react";
import { BsCrop } from "react-icons/bs";
import { Folder, S3UploadedItem } from "../../models";
import useDataForCoverImageFile from "./data-cover-image";
import "./style.css";
import { ProgressBar } from "primereact/progressbar";
import { encode } from "blurhash";
import { Image } from "primereact/image";
import { encodeImageToBlurhash } from "./blurhash-images";
import isDevMode from "../../utils/check-dev-mode";

interface Props {
  title: string;
  id: string;
  ref?: any;
  folder: Folder | null | undefined;
  postId: string;
}

export const CoverImageUploader = ({ title, id, folder, postId }: Props) => {
  const [totalSize, setTotalSize] = useState(0);
  const toast = useRef<any>(null);
  const fileUploadRef = useRef<any>(null);
  const cropImgRef = useRef<HTMLImageElement>(null);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const file = useDataForCoverImageFile((state) => state.file);
  const onUpload = useDataForCoverImageFile((state) => state.onUpload);
  const onSelect = useDataForCoverImageFile((state) => state.onSelect);
  const onError = useDataForCoverImageFile((state) => state.onError);
  const onClear = useDataForCoverImageFile((state) => state.onClear);
  const onCrop = useDataForCoverImageFile((state) => state.onCrop);
  const s3Item = useDataForCoverImageFile((state) => state.s3Item);
  const coverImageFindS3Object = useDataForCoverImageFile(
    (state) => state.findS3Object
  );
  const [encodedBlurhash, setEncodedBlurHash] = useState<string | null>(null);

  function convertBlobToFile(blob: any, fileName: string) {
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
  }

  useEffect(() => {
    if (s3Item?.key && fileToUpload == null) {
      (async () => {
        let blob = await coverImageFindS3Object(s3Item!.key);
        if (blob != null) {
          var name = s3Item!.key.split("/")[1];
          blob = convertBlobToFile(blob, name);
          if (fileUploadRef && fileUploadRef.current) {
            fileUploadRef.current.setFiles([blob]);
            setTotalSize(blob!.size);
          }
        }
      })();
    }

    return () => {};
  }, [file, s3Item]);

  function showCropArea() {
    if (cropImgRef.current !== null) {
      // create a CropArea
      const cropArea = new cropro.CropArea(cropImgRef.current);
      // attach an event handler to assign cropped image back to our image element
      cropArea.addRenderEventListener(async (dataUrl) => {
        if (cropImgRef.current) {
          cropImgRef.current.src = dataUrl;

          const dataURLtoBlob = (dataURL: string) => {
            fetch(dataURL)
              .then((res) => res.blob())
              .then((blob) => {
                const fileName = `${fileToUpload?.name}`;
                const fileExt = fileName.substring(
                  fileName.lastIndexOf(".") + 1
                );
                const newFile: File = new File(
                  [blob],
                  `${fileToUpload?.name}`,
                  { type: `${fileToUpload?.type}` }
                );
                setFileToUpload(newFile);
                onCrop(newFile);
              })
              .catch((err) => console.log("err", err));
          };

          dataURLtoBlob(dataUrl);
        }
      });

      // launch CROPRO
      cropArea.displayMode = "popup";
      cropArea.show();
    }
  }

  const onImageSelect = async (e) => {
    const file = e.files[0];
    setFileToUpload(file);
    onSelect(file);

    let _totalSize = totalSize;
    [...e.files].forEach((file) => {
      // console.log(file);
      // _totalSize += file.size;
      _totalSize = file.size;
    });

    setTotalSize(_totalSize);
  };

  useEffect(() => {
    if (fileToUpload != null || fileToUpload != undefined) {
      (async () => {
        const newEncoded = await encodeImageToBlurhash({ file: fileToUpload });

        if (newEncoded != null || newEncoded != undefined) {
          setEncodedBlurHash(newEncoded);
          if (isDevMode()) {
            console.log("newEc", newEncoded);
          }
        }
      })();
    }

    return () => {};
  }, [fileToUpload]);

  const onImageUpload = async (e) => {
    setIsUploading(true);

    const file = e.files[0];
    let fileSize = 0;
    e.files.forEach((file: File) => {
      // _totalSize += file.size || 0;
      fileSize = file.size;
    });

    setTotalSize(fileSize);

    if (fileSize > 900000) {
      toast.current.show({
        severity: "error",
        summary: "ATTENTION",
        detail: "File size is larger than 800KB",
      });
      setIsUploading(false);
      return;
    }

    if (folder != null || folder != undefined) {
      await onUpload({ file, folder, fileSize, postId, encodedBlurhash })
        .then((doneUploading) => {
          console.log("onUpload done");

          toast.current.show({
            severity: "info",
            summary: "Success",
            detail: "File Uploaded",
          });
        })
        .catch((e) => {
          console.log("onUpload failed", e);

          toast.current.show({
            severity: "error",
            summary: "ATTENTION",
            detail: "File Upload Failed",
          });
        });

      setIsUploading(false);
    }
  };

  const onImageRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onImageClear = () => {
    setTotalSize(0);
    setFileToUpload(null);
    onClear();
  };

  const cropButton = (
    <Button
      type="button"
      icon={<BsCrop />}
      onClick={() => {
        showCropArea();
      }}
      className="custom-crop-btn p-button-rounded p-button-info p-button-outlined"
      aria-label="User"
    />
  );

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formattedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";

    return (
      <div
        className={`${className}`}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <p className="t-font-bold t-font-playfair t-text-[26px]">
          {title}
          <span className="t-text-xs t-font-inter t-text-gray-300 t-ml-[10px]">
            {`(${formattedValue})`}
          </span>
        </p>
        <div>
          {chooseButton}
          {uploadButton}
          {cropButton}
          {cancelButton}
        </div>
      </div>
    );
  };

  const itemTemplate = (file: any, _props: ItemTemplateOptions) => {
    const url = URL.createObjectURL(file);
    return (
      <div className="t-flex align-items-center flex-wrap t-flex-col">
        <div
          className="flex t-flex-row align-items-center cover-image-file"
          style={{ width: "100%", border: "none" }}
        >
          <img
            id="cropImage"
            ref={cropImgRef}
            alt={file?.name}
            className={
              "t-flex-grow t-h-[400px] t-overflow-hidden t-rounded-b-[0.375rem] t-object-cover t-aspect-video"
            }
            src={url}
            style={{ maxWidth: "100%" }}
          />
        </div>

        {isUploading && (
          <div className="t-absolute t-w-full t-h-full">
            <div className="image-upload">
              <ProgressBar
                mode="indeterminate"
                style={{ height: "6px" }}
              ></ProgressBar>
            </div>
          </div>
        )}
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "4em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const customBase64Uploader = async (event) => {
    // convert file to base64 encoded
    const file = event.files[0];
    const reader = new FileReader();
    let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      console.log(base64data);
    };
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  return (
    <div>
      <Toast ref={toast}></Toast>
      <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
      <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
      <Tooltip target=".custom-crop-btn" content="Crop" position="bottom" />
      <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

      <div className="card form-file-uploader">
        <FileUpload
          ref={fileUploadRef}
          name="coverImage"
          id={id}
          multiple={false}
          accept="image/*"
          maxFileSize={900000}
          customUpload={true}
          uploadHandler={onImageUpload}
          onSelect={onImageSelect}
          onError={onImageClear}
          onClear={onImageClear}
          headerTemplate={headerTemplate}
          itemTemplate={itemTemplate}
          emptyTemplate={emptyTemplate}
          contentClassName=" !t-bg-slate-50/40"
          chooseOptions={chooseOptions}
          uploadOptions={uploadOptions}
          cancelOptions={cancelOptions}
        />
      </div>
    </div>
  );
};
