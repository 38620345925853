import produce from "immer";
import { create } from "zustand";
import { UserAccount, RecordStatus } from "../../../models";
import { PageDataInterfaceContentFollowersForm } from "./data-interface-for-content-followers";

const defaultData = {
  nameFirst: "",
  nameLast: "",
  slug: "",
  datePublished: new Date().toISOString(),
  dateLastLogin: new Date().toISOString(),
  status: RecordStatus.DRAFT,
  dateAdded: new Date().toISOString(),
};

const useDataContentFollowersForm =
  create<PageDataInterfaceContentFollowersForm>()((set, get) => {
    return {
      showDialog: false,
      setFormData: async (item) => {
        set((_) => ({ formData: item }));
        return true;
      },
      formActionType: undefined,
      setShowDialog: async (value) => {
        set((_) => ({ showDialog: value }));
        return true;
      },
      formData: defaultData as unknown as UserAccount,
      setFormActionType: async (item) => {
        set((_) => ({ formActionType: item }));
        return true;
      },
      updateFormDataField: async (property, value) => {
        set(
          produce((state: PageDataInterfaceContentFollowersForm) => {
            state.formData![property] = value;
          })
        );
        return true;
      },
    };
  });

export default useDataContentFollowersForm;
