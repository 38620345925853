import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { useEffect, useRef, useState } from "react";
import TableActionButtonSet from "../../../../shared-components/button/TableActionButtonSet";
import useDataUserRoleList from "../data/data-for-user-roles-list";
import { useServiceForUserRoleAPI } from "../services/services-for-user-roles";
import { DataLoadingState, UserRole } from "../../../../models";
import { format } from "date-fns";
import { ListLoader } from "../../../../shared-components/list-tables/list-loader";
import DeleteDialog from "../../../../shared-components/form/DeleteDialog";
import useRoutePaths from "../../../../routes/route-path";
import useDataForCoverImageFile from "../../../../shared-components/images/data-cover-image";
import useDataForTextEditor from "../../../../editors/data-for-editor";
import { useNavigate } from "react-router-dom";
import useToaster from "../../../../shared-components/toasts/Toaster";
import { FormActionType } from "../../../../models";
import useDataForGalleryImagesFile from "../../../../shared-components/images/data-gallery-images";
import useDataUserRolesForm from "../data/data-for-user-roles-form";


const TableUserRole = () => {
  const { paths: route } = useRoutePaths();
  const navigation = useNavigate();
  const listItems = useDataUserRoleList((state) => state.listItems);
  const listItemsFromService = useServiceForUserRoleAPI().listAllData;
  const setFormActionType = useDataUserRolesForm(
    (state) => state.setFormActionType
  );
  const setFormData = useDataUserRolesForm((state) => state.setFormData);
  const coverImageReset = useDataForCoverImageFile((state) => state.reset);
  const editorReset = useDataForTextEditor((state) => state.reset);
  console.log("user role listItems", listItems);
  const deleteItemFromService = useServiceForUserRoleAPI().deleteData;
  const loadingStatus = useDataUserRoleList(
    (state) => state.statusForLoadingListItems
  );
  const setStateLoadingStatus = useDataUserRoleList(
    (state) => state.setStatusForLoadingListItems
  );
  const galleryImageReset = useDataForGalleryImagesFile((state) => state.reset);

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },

    dateUpdated: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },

    activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
  });
  const columnsAll: any = [
    { field: "id", header: "ID" },
    { field: "title", header: "Title" },
    { field: "shortDescription", header: "Short Description" },
    { field: "status", header: "Status" },
  ];

  const columnsSelected: any = [
    { field: "title", header: "Title" },
    { field: "shortDescription", header: "Short Description" },
    { field: "status", header: "Status" },
  ];

  const [selectedColumns, setSelectedColumns] = useState(columnsSelected);
  const { showError, showSuccess } = useToaster();

  const refToast = useRef(null);

  const [itemToDelete, setItemToDelete] = useState<UserRole | null>(null);

  const onColumnToggle = (event: any) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columnsAll.filter((col: { field: any }) =>
      selectedColumns.some((sCol: { field: any }) => sCol.field === col.field)
    );
    setSelectedColumns(orderedSelectedColumns);
  };

  const onSearchKeywordChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = (
    <div
      className="t-grid grid-cols-1 t-w-full  md:t-grid-cols-3
   justify-content-start align-items-center "
    >
      <div className="list-column-select" style={{ textAlign: "left" }}>
        <MultiSelect
          fixedPlaceholder={true}
          placeholder="Columns"
          value={selectedColumns}
          options={columnsAll}
          optionLabel="header"
          onChange={onColumnToggle}
          style={{ width: "14.5em", borderRadius: "10px" }}
        />
      </div>
      <div className="list-search-box t-w-full t-pt-4 md:t-pt-0">
        <span className="p-input-icon-left p-input-border-none  t-font-inter p-input-filled  t-w-full">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onSearchKeywordChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    </div>
  );

  const columnComponents = selectedColumns.map((col) => {
    return (
      <Column
        key={col.field}
        align={col.align}
        field={col.field}
        header={col.header}
      />
    );
  });

  useEffect(() => {
    const fetchListData = async () => {
      await listItemsFromService();
    };

    if (listItems.length == 0 && loadingStatus == DataLoadingState.PENDING) {
      setStateLoadingStatus(DataLoadingState.LOADING);
      fetchListData();
    }
  }, [loadingStatus, listItems]);

  const formatDate = (value: Date) => {
    return format(value, `do LLL yyyy, h:mm aaa`);
  };

  const dateBodyTemplate = (rowData: any) => {
    const value: any = rowData["dateUpdated"];
    const valueAsDate = new Date(value);
    const formatted = formatDate(valueAsDate);
    return <p>{formatted}</p>;
  };

  const editOnClick = (rowData: any) => {
    coverImageReset();
    editorReset();
    galleryImageReset();
    setFormActionType(FormActionType.EDIT);
    setFormData(rowData as UserRole);
    navigation(route.settings.userRoles.details);
  };

  const deleteOnClick = (rowData: any) => {
    setItemToDelete(rowData as UserRole);
  };

  const handleDeleteItem = async () => {
    try {
      let _itemToDelete: UserRole = { ...itemToDelete!, deleted: true };
      await deleteItemFromService(_itemToDelete).then((done) => {
        setItemToDelete(null);
      });
    } catch (error) {
      console.log(`err handleDeleteItem news`, error);
      showError(
        refToast,
        "ATTENTION",
        "Something went wrong, kindly try again."
      );
    }
  };

  const actionsBodyTemplate = (rowData: any) => {
    return (
      <TableActionButtonSet
        editOnClick={() => {
          editOnClick(rowData);
        }}
        viewOnClick={() => {}}
        deleteOnClick={() => {
          deleteOnClick(rowData);
        }}
      />
    );
  };
  return (
    <div className=" t-bg-white">
      {/* loader */}
      {(loadingStatus == DataLoadingState.LOADING ||
        loadingStatus == DataLoadingState.PENDING) && (
        <ListLoader skelsNumber={7} />
      )}
      {loadingStatus == DataLoadingState.LOADED && (
        <DataTable
          scrollHeight="70vh"
          scrollable
          value={listItems}
          filters={filters}
          header={header}
          responsiveLayout="scroll"
        >
          {columnComponents}
          <Column
            field="dateUpdated"
            align={"left"}
            header="Date Updated"
            showFilterMatchModes={false}
            style={{ minWidth: "9rem" }}
            body={dateBodyTemplate}
          />
          <Column
            field="activity"
            header="Actions"
            showFilterMatchModes={false}
            style={{ minWidth: "11rem" }}
            body={actionsBodyTemplate}
          />
        </DataTable>
      )}

      {itemToDelete != null && (
        <DeleteDialog
          message={`Are you sure you want to delete ${itemToDelete.title}? This action cannot be reversed!`}
          onDelete={handleDeleteItem}
          onSetVisible={() => {
            setItemToDelete(null);
          }}
          show={itemToDelete != null}
        />
      )}
    </div>
  );
};

export default TableUserRole;
