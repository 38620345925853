import { API, DataStore, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import useDataCrigUnitDepartmentList from "../../page-staff-unit-departments/data/data-for-staff-unit-departments-list";
import {
  CrigUnitDepartment,
  DataLoadingState,
  OpType,
} from "../../../../models";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import {
  createCrigUnitDepartmentGQL,
  deleteCrigUnitDepartmentGQL,
  listCrigUnitDepartmentsGQL,
  updateCrigUnitDepartmentGQL,
} from "../../../../custom-graphql/custom-gql-crig-unit-department";

import * as queries from "../../../../graphql/queries";
import * as mutations from "../../../../graphql/mutations";
import * as subscriptions from "../../../../graphql/subscriptions";
import useDataForUserAuth from "../../../../store/auth/data-for-auth";
import useDataStaffUnitDepartmentForm from "../data/data-for-staff-unit-departments-form";
import isDevMode from "../../../../utils/check-dev-mode";


const pageModelName = 'unit-dpt';

interface ServicesInterfaceForCrigUnitDepartment {
  addNewData: (item: CrigUnitDepartment) => Promise<boolean>;
  updateData: (item: CrigUnitDepartment, isDelete: boolean) => Promise<CrigUnitDepartment | boolean>;
  updateDataField: (id: string, key: string, value: any) => Promise<boolean>;
  deleteData: (item: CrigUnitDepartment) => Promise<boolean>;
  findOneData: (id: string) => Promise<CrigUnitDepartment | null>;
  listAllData: () => Promise<[CrigUnitDepartment] | null>;
}

export function useCrigUnitDepartmentServiceAPI(): ServicesInterfaceForCrigUnitDepartment {
  const crigUnitDepartmentDataAdd = useDataCrigUnitDepartmentList(
    (state) => state.addItem
  );
  const loggedUser = useDataForUserAuth((state) => state.loggedUser);
  const crigUnitDepartmentDataSetList = useDataCrigUnitDepartmentList(
    (state) => state.setListItems
  );
  const crigUnitDepartmentDataUpdate = useDataCrigUnitDepartmentList(
    (state) => state.updateItem
  );
  const crigUnitDepartmentDataDelete = useDataCrigUnitDepartmentList(
    (state) => state.deleteItem
  );
  const crigUnitDepartmentSetStatusForLoadingListItems =
    useDataCrigUnitDepartmentList(
      (state) => state.setStatusForLoadingListItems
    );
  const [addingNewItem, setAddingNewItem] = useState(false);
  const [updatingNewItem, setUpdatingNewItem] = useState(false);
  const [deletingNewItem, setDeletingNewItem] = useState(false);

  const setFormData = useDataStaffUnitDepartmentForm((state) => state.setFormData);
  const formData = useDataStaffUnitDepartmentForm((state) => state.formData);

  const addNewData = async (item: CrigUnitDepartment) => {
    let newItem: CrigUnitDepartment;
    let promise: Promise<GraphQLResult<any>>;
    let dataResult: CrigUnitDepartment;
    newItem = {
      ...item,
      dateUploaded: new Date().toISOString(),

    };
    console.log("CrigUnitDepartment item", newItem);

    delete (newItem as any).pages;
    delete (newItem as any).createdAt;
    delete (newItem as any).staffProfiles;
    delete (newItem as any).updatedAt;
    delete (newItem as any)._lastChangedAt;
    delete (newItem as any)._deleted;
    delete (newItem as any).articlesPublications;
    delete (newItem as any).crigDivisionSubstation;



    promise = API.graphql(
      graphqlOperation(createCrigUnitDepartmentGQL, { input: newItem })
    );
    await promise
      .then((results) => {
        dataResult = results.data.createCrigUnitDepartment;
        console.log("addNewData dataResult", results);
        return true;
      })
      .catch((e) => {
        console.log("error addNewData", e);
        return false;
      });

    return false;
  };


  const listAllData = async () => {
    let foundItems: CrigUnitDepartment[] | null | undefined;
    let promise: Promise<GraphQLResult<any>>;

    const variables = {
      filter: {
        and: [
          {
            deleted: {
              eq: false,
            },
          },
        ],

      }
    };

    promise = API.graphql({
      query: listCrigUnitDepartmentsGQL,
      variables: variables
    });
    await promise
      .then((results) => {
        foundItems = results.data.listCrigUnitDepartments
          .items as CrigUnitDepartment[];
        const sortFxn = (a: CrigUnitDepartment, b: CrigUnitDepartment) => {
          return (
            new Date(a.dateUploaded!).getTime() -
            new Date(a.dateUploaded!).getTime()
          );
        };
        crigUnitDepartmentDataSetList(foundItems.sort(sortFxn));
        crigUnitDepartmentSetStatusForLoadingListItems(DataLoadingState.LOADED);
        return foundItems;
      })
      .catch((e) => {
        console.log("error listAllCrigUnitDepartments", e);
        crigUnitDepartmentSetStatusForLoadingListItems(DataLoadingState.ERROR);
        return null;
      });
    return null;
  };

  const findOneData = async (id: string) => {
    let foundItem: CrigUnitDepartment | null | undefined;
    let promise: Promise<GraphQLResult<any>>;
    promise = API.graphql(
      graphqlOperation(queries.getCrigUnitDepartment, { id: id })
    );
    await promise
      .then((results) => {
        foundItem = results.data as CrigUnitDepartment;
        console.log("findOneCrigUnitDepartment foundItem", foundItem);
        return foundItem;
      })
      .catch((e) => {
        console.log("error findOneCrigUnitDepartment", e);
        return null;
      });
    return null;
  };

  const findACrigUnitDepartment = async (CrigUnitDepartmentID: string) => {
    let foundItems: CrigUnitDepartment[] | null | undefined;
    let promise: Promise<GraphQLResult<any>>;
    promise = API.graphql({
      query: queries.listCrigUnitDepartments,
      variables: {
        CrigUnitDepartmentID: CrigUnitDepartmentID,
      },
    });
    await promise
      .then((results) => {
        foundItems = results.data.listCrigUnitDepartments
          .items as CrigUnitDepartment[];
        console.log("foundItems findACrigUnitDepartments", foundItems);
        return foundItems;
      })
      .catch((e) => {
        console.log("error findACrigUnitDepartments", e);
        return null;
      });
    return null;
  };

  const updateData = async (item: CrigUnitDepartment, isDelete: boolean) => {
    let newItem: CrigUnitDepartment;
    let dataResult: CrigUnitDepartment;

    newItem = {
      ...item,
      dateUploaded: new Date().toISOString(),
    };

    if (isDelete) {
      newItem = {
        ...item,
        deleted: true,
      };
    }


    delete (newItem as any).pages;
    delete (newItem as any).createdAt;
    delete (newItem as any).staffProfiles;
    delete (newItem as any).updatedAt;
    delete (newItem as any)._lastChangedAt;
    delete (newItem as any)._deleted;
    delete (newItem as any).postCategory;
    delete (newItem as any).articlesPublications;
    delete (newItem as any).crigDivisionSubstation;


    try {
      const results: any = await API.graphql(graphqlOperation(updateCrigUnitDepartmentGQL, { input: newItem }));
      console.log(`update ${pageModelName} results`, results);
      dataResult = results.data.updateCrigUnitDepartment;
      if (isDelete) {
        return dataResult;
      } else {

        return true;
      }

    } catch (error) {
      console.log(`update ${pageModelName} onError`, error);
      return false;
    }
  };

  const deleteData = async (item: any) => {
    let dataResult: CrigUnitDepartment;
    console.log(item)
    try {
      item = { ...item, deleted: true };

      const results: any = await API.graphql(graphqlOperation(deleteCrigUnitDepartmentGQL, { input: { id: item.id, _version: item._version, } }));

      if (isDevMode()) {
        console.log(`delete ${pageModelName} results`, results);
      }


      dataResult = results.data.deleteCrigUnitDepartment;

      await updateData(dataResult, true);

      return true;

    } catch (error) {
      if (isDevMode()) {
        console.log(`update ${pageModelName} onError`, error);
      }

      return false;
    }

  };



  //FIGURE OUT API
  const updateDataField = async (id: string, key: string, value: any) => {
    return false;
  };

  useEffect(() => {
    const createSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onCreateCrigUnitDepartment)
      ) as unknown as any
    ).subscribe({
      next: ({ provider, value }) => {
        console.log("onCreateCrigUnitDepartment", { provider, value });
        // if (addingNewItem) return;
        // setAddingNewItem(true);
        const newItem = value.data
          .onCreateCrigUnitDepartment as CrigUnitDepartment;

        crigUnitDepartmentDataAdd(newItem);

        if (formData?.id == newItem.id) {
          setFormData(newItem);
        }

        // setAddingNewItem(false);
      },
      error: (error: any) => {
        console.warn(error);
      },
    });
    const updateSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onUpdateCrigUnitDepartment)
      ) as unknown as any
    ).subscribe({
      next: ({ provider, value }) => {
        console.log("onUpdateCrigUnitDepartment", { provider, value });
        // if (updatingNewItem) return;
        // setUpdatingNewItem(true);
        const newItem = value.data
          .onUpdateCrigUnitDepartment as CrigUnitDepartment;

        crigUnitDepartmentDataUpdate(newItem);

        if (formData?.id == newItem.id) {
          setFormData(newItem);
        }

        // setUpdatingNewItem(false);
      },
      error: (error: any) => {
        console.warn(error);
      },
    });
    const deleteSubscription = (
      API.graphql(
        graphqlOperation(subscriptions.onDeleteCrigUnitDepartment)
      ) as unknown as any
    ).subscribe({
      next: ({ provider, value }) => {
        console.log("onDeleteCrigUnitDepartment", { provider, value });
        // if (deletingNewItem) return;
        // setDeletingNewItem(true);
        const newItem = value.data
          .onDeleteCrigUnitDepartment as CrigUnitDepartment;
        crigUnitDepartmentDataDelete(newItem);
        // setDeletingNewItem(false);
      },
      error: (error: any) => {
        console.warn(error);
      },
    });
    return () => {
      const cleanupSubscriptions = () => {
        createSubscription.unsubscribe();
        updateSubscription.unsubscribe();
        deleteSubscription.unsubscribe();
      };
      cleanupSubscriptions();
    };
  }, []);
  return {
    addNewData,
    updateData,
    updateDataField,
    deleteData,
    findOneData,
    listAllData,
  };
}
