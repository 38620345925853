import { Ripple } from "primereact/ripple";
import { useCallback, useEffect, useState } from "react";
import { DataLoadingState, Slide } from "../../../models";
import { ListLoader } from "../../../shared-components/list-tables/list-loader";
import { formatDateString } from "../../../utils/forms/util-forms";
import useDataEventsGalleryList from "../../page-events-gallery/data/data-for-event-gallery-list";
import { useServiceForEventsGalleryAPI } from "../../page-events-gallery/services/services-for-events-gallery";
import useDataNewsAnnouncementList from "../../page-news-announcements/data/data-for-news-announcement-list";
import { useServiceForNewsAnnouncementAPI } from "../../page-news-announcements/services/services-for-news-announcements";
import useDataHomeSlidesList from "../data/data-for-home-slides-list";
import FormForHomeSlide from "../form/form-for-home-slide";
import { useServiceForHomeSlidesAPI } from "../services/services-for-home-slide";
import useDataMandatedCropsList from "../../page-mandated-crops/data/data-for-mandated-crops-list";
import { useServiceForMandatedCropsAPI } from "../../page-mandated-crops/services/services-for-mandated-crops";


interface GridItemProps {
  // title: string;
  // subTitle: string;
  // stats: string;
  // url: string;
  slide: Slide | null;
}


function ListHomeSlides() {

  const { listAllHomeSlides } = useServiceForHomeSlidesAPI();
  const listItems = useDataHomeSlidesList((state) => state.listItems);
  const loadingStatus = useDataHomeSlidesList((state) => state.statusForLoadingListItems);
  const listItemsFromServiceNews = useServiceForNewsAnnouncementAPI().listAllData;
  const listItemsFromServiceEvents = useServiceForEventsGalleryAPI().listAllData;
  const listItemsFromServiceMandatedCrops = useServiceForMandatedCropsAPI().listAllData;
  const [loadingPosts, setLoadingPost] = useState<boolean | null>(null);
  const postsNewsAnnouncement = useDataNewsAnnouncementList((state) => state.listItems);
  const postsNewsAnnouncementLoading = useDataNewsAnnouncementList((state) => state.statusForLoadingListItems);
  const postsEventsGallery = useDataEventsGalleryList((state) => state.listItems);
  const postsMandatedCrops = useDataMandatedCropsList((state) => state.listItems);
  const postsEventsGalleryLoading = useDataEventsGalleryList((state) => state.statusForLoadingListItems);
  const postsMandatedCropsLoading = useDataMandatedCropsList((state) => state.statusForLoadingListItems);
  const [visible, setVisible] = useState(false);

  const [selectedSlide, setSelectedSlide] = useState<Slide | null>(null);

  const [formAwaiting, setFormAwaiting] = useState(true);
  const reset = () => {
    setSelectedSlide(null)
  }

  const fetchDataNews = useCallback(async () => {
    await listItemsFromServiceNews();
  }, [])


  const fetchDataEvents = useCallback(async () => {
    await listItemsFromServiceEvents();
  }, [])

  const fetchMandatedCrops = useCallback(async () => {
    await listItemsFromServiceMandatedCrops();
  }, [])


  useEffect(() => {

    if (loadingPosts == null) {
      setLoadingPost(true)
      if (postsNewsAnnouncement.length == 0) {
        if (postsNewsAnnouncementLoading == DataLoadingState.PENDING) {
          fetchDataNews()
            // make sure to catch any error
            .catch(console.error);;
        }
      }

      if (postsEventsGallery.length == 0) {
        if (postsEventsGalleryLoading == DataLoadingState.PENDING) {
          fetchDataEvents()
            // make sure to catch any error
            .catch(console.error);
        }
      }

      if (postsMandatedCrops.length == 0) {
        if (postsMandatedCropsLoading == DataLoadingState.PENDING) {
          fetchMandatedCrops()
            // make sure to catch any error
            .catch(console.error);
        }
      }

      setLoadingPost(false)

    }

    return () => { }
  }, [postsNewsAnnouncement, postsEventsGallery, postsMandatedCrops, loadingPosts])

  const GridItem = ({ slide, }: GridItemProps) => {

    const onClick = () => {
      setSelectedSlide(slide)
      setVisible(true)
      setFormAwaiting(false)
    };

    return (
      <div className="p-ripple gray settings-grid t-rounded-[10px] t-transition-all
         t-ease-in-out t-bg-white  
         hover:t-cursor-pointer t-py-2 t-px-2 md:t-px-6 t-items-start md:t-items-start 
         t-justify-center t-min-h-[85px] t-flex t-flex-col md:t-flex-row t-border-[1px] hover:t-border-[1.5px] t-border-slate-200  hover:t-border-slate-500"  onClick={onClick}>
        <div className="t-w-full md:t-w-[250px] t-overflow-hidden t-bg-gray-100 t-rounded-md md:t-mr-3 t-mb-3 t-mt-0 md:t-mt-0 md:t-mb-0  md:t-h-[150px] t-h-[200px]">
          <img className="t-overflow-hidden t-w-full t-object-cover md:t-h-[150px] t-h-[200px]" src={slide?.postCoverImage.signedURL!} />
        </div>
        <div className="t-flex-grow t-mb-3 t-mt-1 t-font-inter md:t-mb-0">
          <div className="flex md:t-flex-row t-flex-row t-items-center">
            {slide?.slidePosition == 6 && <p className="t-font-semibold t-text-xl t-mr-1">{`Top Bar Banner`}</p>}
            {slide?.slidePosition != 6 && <p className="t-font-semibold t-text-xl t-mr-1">{`Slide ${slide?.slidePosition}`}</p>}
            <p className="t-text-slate-500 t-hidden md:flex">&#183;</p>
            <p className="t-font-normal t-text-slate-400 t-ml-1">{`  ${slide?.status.toUpperCase()}`}</p>
          </div>

          <p className="t-font-semibold  t-text-xl">{slide?.slideTitle}</p>
          <div className="flex md:t-flex-row t-flex-col mt-4">
            <p className="t-font-normal t-text-slate-400   md:mr-2">{`${formatDateString(new Date(slide!.dateUpdated))}`}</p>
          </div>
          <p className="t-font-normal t-text-slate-500 ">{`Total Clicks: ${slide?.totalClicksCount}`}</p>
        </div>
        <span className="hidden md:visible">
          <i className={`pi pi-chevron-right ml-2`}></i>
        </span>
        <Ripple />
      </div>
    );
  }


  useEffect(() => {
    if (loadingStatus == DataLoadingState.PENDING) {
      listAllHomeSlides();
      return;
    }

    if (loadingStatus == DataLoadingState.LOADING) {
      return;
    }

    return () => {

    }
  }, [loadingStatus])

  return (
    <div className="t-flex t-flex-col   t-p-4 md:t-p-10 t-w-full t-m-auto t-max-w-[96rem] t-justify-start t-max-h-[95vh] t-items-center">
      <div className="t-w-full t-h-full t-overflow-y-auto t-relative t-pb-10">
        <div className="t-flex t-flex-col  t-pb-6">
          <p className="t-font-playfair t-text-4xl t-font-semibold t-text-applisttitlebrown">Home Page Slides</p>
          <p className="t-font-inter t-text-lg t-py-2 t-font-medium t-text-slate-500">Manage the items on the home page slider</p>
        </div>
        {/* loader */}
        {(loadingStatus == DataLoadingState.LOADING || loadingStatus == DataLoadingState.PENDING) && <ListLoader addTopPadding={true} skelsNumber={5} />}
        {(loadingStatus == DataLoadingState.LOADED) &&
          <div className="t-grid t-grid-cols-1 t-pb-10 t-max-w-[76rem]   t-w-full md:t-grid-cols-1 gap-4">
            {listItems.map((item, i) => {
              return <GridItem slide={item} key={i.toString()} />
            })}

          </div>
        }
      </div>
      {(visible && selectedSlide && !loadingPosts) && <FormForHomeSlide onSetFormAwaiting={setFormAwaiting} awaiting={formAwaiting} onSetVisible={setVisible} slide={selectedSlide} visible={visible} reset={reset} />}
    </div>
  );
}

export default ListHomeSlides;
