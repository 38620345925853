import { useEffect } from "react";
import { DataLoadingState, Slide, PageType } from "../../../models";
import useDataHomeSlidesList from "../data/data-for-home-slides-list";
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import * as subscriptions from '../../../graphql/subscriptions';
import { GraphQLResult } from "@aws-amplify/api-graphql"
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";

interface ServiceInterfaceForHomeSlides {
    addNewHomeSlide: (item: Slide) => Promise<boolean>;
    updateHomeSlide: (item: Slide) => Promise<boolean>;
    updateHomeSlideField: (
        id: string,
        key: string,
        value: any
    ) => Promise<boolean>;
    deleteHomeSlide: (item: Slide) => Promise<boolean>;
    findOneHomeSlide: (id: string) => Promise<Slide | null>;
    // findGroupExternalService: (groupIds: [string]) => Promise<[HomeSlider] | null>;
    listAllHomeSlides: () => Promise<Slide[] | null>;
}


export function useServiceForHomeSlidesAPI(): ServiceInterfaceForHomeSlides {

    const pageDataAdd = useDataHomeSlidesList((state) => state.addItem);
    const coverImageGetS3URL = useDataForCoverImageFile((state) => state.getS3URL);
    const pageDataSetStatusForLoading = useDataHomeSlidesList((state) => state.setStatusForLoadingListItems);

    const pageDataSetList = useDataHomeSlidesList(
        (state) => state.setListItems
    );
    const pageDataUpdate = useDataHomeSlidesList(
        (state) => state.updateItem
    );
    const pageDataDelete = useDataHomeSlidesList(
        (state) => state.deleteItem
    );

    const addNewHomeSlide = async (item: Slide) => {
        let newItem: Slide;
        let promise: Promise<GraphQLResult<any>>;
        let dataResult: Slide;
        newItem = { ...item, dateUpdated: new Date().toISOString() };


        promise = API.graphql(graphqlOperation(mutations.createSlide, { input: newItem }));

        await promise.then((results) => {
            dataResult = results.data;
            console.log('createSlide dataResult', dataResult)
            return true

        }).catch((e) => {
            console.log('error addNewHomeSlide', e)
            return false
        });
        console.log('createSlide dataResult false first')
        return false
    };

    const updateHomeSlide = async (item: Slide) => {

        let newItem: Slide;

        newItem = { ...item, dateUpdated: new Date().toISOString() };


        delete (newItem as any).postCategory;
        delete (newItem as any).createdAt;
        delete (newItem as any).updatedAt;
        delete (newItem as any).creatorUserAccount;
        delete (newItem as any)._lastChangedAt;
        delete (newItem as any)._deleted;

        console.log("updateHomeSlide newItem", newItem);


        try {
            const savedItem = await API.graphql(graphqlOperation(mutations.updateSlide, { input: newItem }));
            console.log("updateSlide savedItem", savedItem);
            return true;
        } catch (error) {
            console.log("updateSlide results onError", error);
            return false;
        }

    };

    const findOneHomeSlide = async (id: string) => {
        let foundItem: Slide | null | undefined;
        let promise: Promise<GraphQLResult<any>>;

        promise = API.graphql(graphqlOperation(queries.getSlide, { id: id }));

        await promise.then((results) => {
            foundItem = results.data as Slide;
            console.log('getSlide foundItem', foundItem)
            return foundItem

        }).catch((e) => {
            console.log('error findOneHomeSlide', e)
            return null
        });

        return null
    };

    const listAllHomeSlides = async () => {

        let foundItems: Slide[] | null | undefined;
        let promise: Promise<GraphQLResult<any>>;

        promise = API.graphql({
            query: queries.listSlides,
        })

        await promise.then(async (results) => {
            foundItems = results.data.listSlides.items as Slide[]
            console.log('listSlides foundItem', foundItems)
            if (foundItems.length > 0) {
                for (var item of foundItems) {
                    let index = -1;
                    const coverImage = item.postCoverImage;
                    let coverImageUrl: string | null = '';

                    function findItemIndex(listItem: Slide) {
                        return listItem.id == item.id;
                    }


                    index = foundItems.findIndex(findItemIndex)

                    if (coverImage != null) {
                        coverImageUrl = await coverImageGetS3URL(coverImage.key);
                        if (coverImageUrl != null) {
                            item = { ...item, postCoverImage: { ...item.postCoverImage, signedURL: coverImageUrl } }
                            foundItems[index] = item;
                        }
                    }
                }
            }
            foundItems.sort((a, b) => a.slidePosition - b.slidePosition)

            pageDataSetList(foundItems)
            pageDataSetStatusForLoading(DataLoadingState.LOADED)
            return foundItems
        }).catch((e) => {
            console.log('error listAllHomeSlides', e)
            return null
        });
        return null;
    };



    const deleteHomeSlide = async (item: Slide) => {
        let promise: Promise<GraphQLResult<any>>;

        promise = API.graphql(graphqlOperation(mutations.deleteSlide, { input: item }));

        await promise.then((_) => {
            pageDataDelete(item)
            return true
        }).catch((e) => {
            console.log('error deleteHomeSlide', e)
            return false
        });
        return false
    };


    //FIGURE OUT API
    const updateHomeSlideField = async (
        id: string,
        key: string,
        value: any
    ) => {
        return false;
    };



    useEffect(() => {

        const createSubscription = (API.graphql(
            graphqlOperation(subscriptions.onCreateSlide)
        ) as unknown as any).subscribe({
            next: ({ provider, value }) => {
                console.log({ provider, value })
                pageDataAdd(value as Slide);
            },
            error: (error: any) => {
                console.warn(error)
            }
        });

        const updateSubscription = (API.graphql(
            graphqlOperation(subscriptions.onUpdateSlide)
        ) as unknown as any).subscribe({
            next: async ({ provider, value }) => {
                console.log({ provider, value })

                let data = value.data.onUpdateSlide as Slide;
                let url: string | null = ''
                if (data.postCoverImage != null) {
                    url = await coverImageGetS3URL(data.postCoverImage.key);
                    if (url != null) {
                        data = { ...data, postCoverImage: { ...data.postCoverImage!, signedURL: url } }
                        // console.log('onUpdatePage next ', data)
                    }
                }

                pageDataUpdate(data);

            },
            error: (error: any) => {
                console.warn(error)
            }
        });


        const deleteSubscription = (API.graphql(
            graphqlOperation(subscriptions.onDeleteSlide)
        ) as unknown as any).subscribe({
            next: ({ provider, value }) => {
                console.log({ provider, value })
                pageDataDelete(value as Slide);
            },
            error: (error: any) => {
                console.warn(error)
            }
        });


        return () => {
            const cleanupSubscriptions = () => {
                createSubscription.unsubscribe();
                updateSubscription.unsubscribe();
                deleteSubscription.unsubscribe();
            }
            cleanupSubscriptions();

        }
    }, []);

    return {
        addNewHomeSlide,
        updateHomeSlide,
        updateHomeSlideField,
        deleteHomeSlide,
        findOneHomeSlide,
        listAllHomeSlides,
    };
}
