export const createPageGQL = /* GraphQL */ `
  mutation CreatePage(
    $input: CreatePageInput!
    $condition: ModelPageConditionInput
  ) {
    createPage(input: $input, condition: $condition) {
      id
      title
      shortDescription
      details
      listPosition
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      status
      totalViewsCount
      totalDownloadsCount
      totalDeliveriesCount
      pageType
      tags
      slug
      postCategoryID
      postSubCategoryID
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
      }
      creatorUserAccountID
      dateAdded
      dateUpdated
      datePublished
      createdAt
      updatedAt
      _version
      _deleted
      deleted
      _lastChangedAt
    }
  }
`;

export const getPageGQL = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      title
      listPosition
      shortDescription
      details
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      status
      totalViewsCount
      totalStaffCount
      totalDownloadsCount
      totalDeliveriesCount
      pageType
      tags
      slug
      postCategoryID
      postSubCategoryID
      postSubCategory {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
  
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
 
      creatorUserAccountID
      dateAdded
      dateSent
      dateUpdated
      datePublished
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updatePageGQL = /* GraphQL */ `
  mutation UpdatePage(
    $input: UpdatePageInput!
    $condition: ModelPageConditionInput
  ) {
    updatePage(input: $input, condition: $condition) {
      id
      title
      listPosition
      shortDescription
      details
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      status
      totalViewsCount
      totalDownloadsCount
      totalDeliveriesCount
      pageType
      tags
      slug
      postCategoryID
      postSubCategoryID
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
      }
      creatorUserAccountID
      dateAdded
      dateUpdated
      dateSent
      datePublished
      createdAt
      updatedAt
      _version
      _deleted
      deleted
      _lastChangedAt
    }
  }
`;


export const listPagesGQL = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        deleted
        listPosition
        _lastChangedAt
        _version
        coverImage {
          blurHash
          eTag
          key
          lastModified
          size
        }
        createdAt
        creatorUserAccountID
        dateAdded
        datePublished
        dateSent
        dateUpdated
        details
        documents {
          blurHash
          eTag
          key
          lastModified
          size
        }
        galleryImages {
          blurHash
          eTag
          key
          lastModified
          signedURL
          size
        }
        id
        pageDesignPageBuilder
        pageDesignTextEditor
        pageDesignType
        pageType
        postCategoryID
        postCategory {
          id
          name
        }
        postSubCategoryID
        postSubCategory {
          id
          name
        }
        shortDescription
        slug
        status
        tags
        title
        totalDeliveriesCount
        totalDownloadsCount
        totalViewsCount
        updatedAt
      }
      nextToken
      startedAt
      
    }
  }
`;

export const deletePageGQL = /* GraphQL */ `
  mutation DeletePage(
    $input: DeletePageInput!
    $condition: ModelPageConditionInput
  ) {
    deletePage(input: $input, condition: $condition) {
      id
      title
      shortDescription
      details
      pageDesignType
      pageDesignTextEditor
      listPosition
      pageDesignPageBuilder
      status
      totalViewsCount
      totalDownloadsCount
      totalDeliveriesCount
      pageType
      tags
      slug
      postCategoryID
      postSubCategoryID
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
      }
      creatorUserAccountID
      dateAdded
      dateUpdated
      dateSent
      datePublished
      createdAt
      updatedAt
      _version
      _deleted
      deleted
      _lastChangedAt
    }
  }
`;
