import { useNavigate } from "react-router-dom";
import useDataForTextEditor from "../../../editors/data-for-editor";
import { FormActionType } from "../../../models";
import useRoutePaths from "../../../routes/route-path";
import TableAddNewButton from "../../../shared-components/button/TableAddNewButton";
import useDataForCoverImageFile from "../../../shared-components/images/data-cover-image";
import useDataForGalleryImagesFile from "../../../shared-components/images/data-gallery-images";
import CustomNavigation from "../../../shared-components/navigation/CustomNavigation";
import useDataRecreationFacilitiesForm from "../data/data-for-recreation-facilities-form";
import TableRecreationFacilities from "../tables/table-for-recreation-facilities";

function ListRecreationFacilities() {
    const { paths: route } = useRoutePaths();
    const navigation = useNavigate();
    const setFormActionType = useDataRecreationFacilitiesForm(
        (state) => state.setFormActionType
    );
    const resetForm = useDataRecreationFacilitiesForm(
        (state) => state.reset
    );

    const coverImageReset = useDataForCoverImageFile((state) => state.reset);
    const editorReset = useDataForTextEditor((state) => state.reset);
    const galleryImageReset = useDataForGalleryImagesFile((state) => state.reset);


    return (
        <div className="flex t-flex-col t-overflow-hidden t-justify-start t-h-fit t-rounded-lg t-shadow-lg t-m-10">
            <div className="">
                <CustomNavigation
                    background={false}
                    title="Recreation Facilities"
                    buttons={
                        <TableAddNewButton label='Add New' onClick={() => {
                            coverImageReset()
                            editorReset()
                            galleryImageReset()
                            setFormActionType(FormActionType.NEW);
                            resetForm()
                            navigation(route.recreationalFacilities.details);
                        }} />
                    }

                />
            </div>
            <TableRecreationFacilities />
        </div>
    );
}

export default ListRecreationFacilities;
