/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAppNotification = /* GraphQL */ `
  query GetAppNotification($id: ID!) {
    getAppNotification(id: $id) {
      id
      name
      title
      body
      receiversUserAccountsViews {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAppNotifications = /* GraphQL */ `
  query ListAppNotifications(
    $filter: ModelAppNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        title
        body
        receiversUserAccountsViews {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAppNotifications = /* GraphQL */ `
  query SyncAppNotifications(
    $filter: ModelAppNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAppNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        title
        body
        receiversUserAccountsViews {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getContactFormMsg = /* GraphQL */ `
  query GetContactFormMsg($id: ID!) {
    getContactFormMsg(id: $id) {
      id
      fullName
      emailAddress
      phoneNumber
      countryName
      countryIso3
      subject
      message
      status
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listContactFormMsgs = /* GraphQL */ `
  query ListContactFormMsgs(
    $filter: ModelContactFormMsgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactFormMsgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullName
        emailAddress
        phoneNumber
        countryName
        countryIso3
        subject
        message
        status
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContactFormMsgs = /* GraphQL */ `
  query SyncContactFormMsgs(
    $filter: ModelContactFormMsgFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContactFormMsgs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        fullName
        emailAddress
        phoneNumber
        countryName
        countryIso3
        subject
        message
        status
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHelpCenterPage = /* GraphQL */ `
  query GetHelpCenterPage($id: ID!) {
    getHelpCenterPage(id: $id) {
      id
      title
      icon
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      tags
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHelpCenterPages = /* GraphQL */ `
  query ListHelpCenterPages(
    $filter: ModelHelpCenterPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHelpCenterPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        icon
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        tags
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHelpCenterPages = /* GraphQL */ `
  query SyncHelpCenterPages(
    $filter: ModelHelpCenterPageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHelpCenterPages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        icon
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        tags
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPostUserAccountViewDownload = /* GraphQL */ `
  query GetPostUserAccountViewDownload($id: ID!) {
    getPostUserAccountViewDownload(id: $id) {
      id
      userAccountID
      userAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      pageType
      deleted
      postType
      postID
      appNotificationID
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      appNotification {
        id
        name
        title
        body
        receiversUserAccountsViews {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      downloadCount
      deliveriesCount
      viewCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listPostUserAccountViewDownloads = /* GraphQL */ `
  query ListPostUserAccountViewDownloads(
    $filter: ModelPostUserAccountViewDownloadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostUserAccountViewDownloads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userAccountID
        userAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        pageType
        deleted
        postType
        postID
        appNotificationID
        articlePublication {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        appNotification {
          id
          name
          title
          body
          dateAdded
          dateUpdated
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        downloadCount
        deliveriesCount
        viewCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPostUserAccountViewDownloads = /* GraphQL */ `
  query SyncPostUserAccountViewDownloads(
    $filter: ModelPostUserAccountViewDownloadFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPostUserAccountViewDownloads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userAccountID
        userAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        pageType
        deleted
        postType
        postID
        appNotificationID
        articlePublication {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        appNotification {
          id
          name
          title
          body
          dateAdded
          dateUpdated
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        downloadCount
        deliveriesCount
        viewCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserAccount = /* GraphQL */ `
  query GetUserAccount($id: ID!) {
    getUserAccount(id: $id) {
      id
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      category
      deleted
      nameFirst
      nameLast
      nameFull
      authId
      status
      contactPhoneNumber1
      contactPhoneNumber2
      contactEmail1
      contactEmail2
      countryIso
      countryName
      staffProfileID
      userRoleID
      userRole {
        id
        title
        shortDescription
        moreDetailsTextEditor
        status
        userAccounts {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        assignedAccess {
          id
          userRoleID
          tableName
          routePath
          menuItemTitle
          operations
        }
        creatorUserAccountID
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFollowing
      dateLastLogin
      dateAdded
      dateAddedYear
      dateAddedMonth
      dateAddedDay
      dateUpdated
      dateUpdatedYear
      dateUpdatedMonth
      dateUpdatedDay
      postViewsDownloads {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      totalViewsCount
      totalPostDownloadsCount
      totalPostViewsCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listUserAccounts = /* GraphQL */ `
  query ListUserAccounts(
    $filter: ModelUserAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserAccounts = /* GraphQL */ `
  query SyncUserAccounts(
    $filter: ModelUserAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserRole = /* GraphQL */ `
  query GetUserRole($id: ID!) {
    getUserRole(id: $id) {
      id
      title
      shortDescription
      moreDetailsTextEditor
      status
      userAccounts {
        items {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      assignedAccess {
        id
        userRoleID
        tableName
        routePath
        menuItemTitle
        operations
      }
      creatorUserAccountID
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserRoles = /* GraphQL */ `
  query ListUserRoles(
    $filter: ModelUserRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        shortDescription
        moreDetailsTextEditor
        status
        userAccounts {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        assignedAccess {
          id
          userRoleID
          tableName
          routePath
          menuItemTitle
          operations
        }
        creatorUserAccountID
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserRoles = /* GraphQL */ `
  query SyncUserRoles(
    $filter: ModelUserRoleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        shortDescription
        moreDetailsTextEditor
        status
        userAccounts {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        assignedAccess {
          id
          userRoleID
          tableName
          routePath
          menuItemTitle
          operations
        }
        creatorUserAccountID
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const postUserAccountViewDownloadByUserAccount = /* GraphQL */ `
  query PostUserAccountViewDownloadByUserAccount(
    $userAccountID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPostUserAccountViewDownloadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postUserAccountViewDownloadByUserAccount(
      userAccountID: $userAccountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userAccountID
        userAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        pageType
        deleted
        postType
        postID
        appNotificationID
        articlePublication {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        appNotification {
          id
          name
          title
          body
          dateAdded
          dateUpdated
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        downloadCount
        deliveriesCount
        viewCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const postUserAccountViewDownloadByPost = /* GraphQL */ `
  query PostUserAccountViewDownloadByPost(
    $postID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPostUserAccountViewDownloadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postUserAccountViewDownloadByPost(
      postID: $postID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userAccountID
        userAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        pageType
        deleted
        postType
        postID
        appNotificationID
        articlePublication {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        appNotification {
          id
          name
          title
          body
          dateAdded
          dateUpdated
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        downloadCount
        deliveriesCount
        viewCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const postUserAccountViewDownloadByAppNotification = /* GraphQL */ `
  query PostUserAccountViewDownloadByAppNotification(
    $appNotificationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPostUserAccountViewDownloadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postUserAccountViewDownloadByAppNotification(
      appNotificationID: $appNotificationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userAccountID
        userAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        pageType
        deleted
        postType
        postID
        appNotificationID
        articlePublication {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        appNotification {
          id
          name
          title
          body
          dateAdded
          dateUpdated
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        downloadCount
        deliveriesCount
        viewCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userAccountByCategoryAndStatus = /* GraphQL */ `
  query UserAccountByCategoryAndStatus(
    $category: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAccountByCategoryAndStatus(
      category: $category
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userAccountByFullNameAndStatus = /* GraphQL */ `
  query UserAccountByFullNameAndStatus(
    $nameFull: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAccountByFullNameAndStatus(
      nameFull: $nameFull
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userAccountByAuthAndStatus = /* GraphQL */ `
  query UserAccountByAuthAndStatus(
    $authId: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAccountByAuthAndStatus(
      authId: $authId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userAccountByContactPhoneNumber1AndStatus = /* GraphQL */ `
  query UserAccountByContactPhoneNumber1AndStatus(
    $contactPhoneNumber1: AWSPhone!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAccountByContactPhoneNumber1AndStatus(
      contactPhoneNumber1: $contactPhoneNumber1
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userAccountByContactEmail1AndStatus = /* GraphQL */ `
  query UserAccountByContactEmail1AndStatus(
    $contactEmail1: AWSEmail!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAccountByContactEmail1AndStatus(
      contactEmail1: $contactEmail1
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userAccountByStaffProfileAndStatus = /* GraphQL */ `
  query UserAccountByStaffProfileAndStatus(
    $staffProfileID: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAccountByStaffProfileAndStatus(
      staffProfileID: $staffProfileID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userAccountByUserRoleAndStatus = /* GraphQL */ `
  query UserAccountByUserRoleAndStatus(
    $userRoleID: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAccountByUserRoleAndStatus(
      userRoleID: $userRoleID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userRoleByCreatorUserAccountAndStatus = /* GraphQL */ `
  query UserRoleByCreatorUserAccountAndStatus(
    $creatorUserAccountID: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userRoleByCreatorUserAccountAndStatus(
      creatorUserAccountID: $creatorUserAccountID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        moreDetailsTextEditor
        status
        userAccounts {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        assignedAccess {
          id
          userRoleID
          tableName
          routePath
          menuItemTitle
          operations
        }
        creatorUserAccountID
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getArticlePublication = /* GraphQL */ `
  query GetArticlePublication($id: ID!) {
    getArticlePublication(id: $id) {
      id
      title
      shortDescription
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      tags
      authorStaffProfiles {
        items {
          id
          articlePublicationId
          staffProfileId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      authorStaffProfilesIds
      publishersIds
      publishers {
        items {
          id
          articlePublicationId
          articlePublicationPublisherId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      deleted
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalDownloadsCount
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postSubCategoryID
      postSubCategory {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      volumeNumber
      editionNumber
      issueNumber
      issn
      userViewsDownloads {
        items {
          id
          userAccountID
          pageType
          deleted
          postType
          postID
          appNotificationID
          downloadCount
          deliveriesCount
          viewCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      visibilityType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listArticlePublications = /* GraphQL */ `
  query ListArticlePublications(
    $filter: ModelArticlePublicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticlePublications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncArticlePublications = /* GraphQL */ `
  query SyncArticlePublications(
    $filter: ModelArticlePublicationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncArticlePublications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const articlePublicationByTitleAndStatus = /* GraphQL */ `
  query ArticlePublicationByTitleAndStatus(
    $title: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticlePublicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlePublicationByTitleAndStatus(
      title: $title
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const articlePublicationBySlugAndStatus = /* GraphQL */ `
  query ArticlePublicationBySlugAndStatus(
    $slug: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticlePublicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlePublicationBySlugAndStatus(
      slug: $slug
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const articlePublicationByPostCategoryAndStatus = /* GraphQL */ `
  query ArticlePublicationByPostCategoryAndStatus(
    $postCategoryID: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticlePublicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlePublicationByPostCategoryAndStatus(
      postCategoryID: $postCategoryID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const articlePublicationByPostSubCategoryAndStatus = /* GraphQL */ `
  query ArticlePublicationByPostSubCategoryAndStatus(
    $postSubCategoryID: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticlePublicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlePublicationByPostSubCategoryAndStatus(
      postSubCategoryID: $postSubCategoryID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const articlePublicationByVolumeNumberAndStatus = /* GraphQL */ `
  query ArticlePublicationByVolumeNumberAndStatus(
    $volumeNumber: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticlePublicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlePublicationByVolumeNumberAndStatus(
      volumeNumber: $volumeNumber
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const articlePublicationByEditionNumberAndStatus = /* GraphQL */ `
  query ArticlePublicationByEditionNumberAndStatus(
    $editionNumber: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticlePublicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlePublicationByEditionNumberAndStatus(
      editionNumber: $editionNumber
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const articlePublicationByIssueNumberAndStatus = /* GraphQL */ `
  query ArticlePublicationByIssueNumberAndStatus(
    $issueNumber: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticlePublicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlePublicationByIssueNumberAndStatus(
      issueNumber: $issueNumber
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const articlePublicationByIssnAndStatus = /* GraphQL */ `
  query ArticlePublicationByIssnAndStatus(
    $issn: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticlePublicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlePublicationByIssnAndStatus(
      issn: $issn
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const articlePublicationByCreatorUserAccountAndStatus = /* GraphQL */ `
  query ArticlePublicationByCreatorUserAccountAndStatus(
    $creatorUserAccountID: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticlePublicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlePublicationByCreatorUserAccountAndStatus(
      creatorUserAccountID: $creatorUserAccountID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const articlePublicationByVisibilityTypeAndStatus = /* GraphQL */ `
  query ArticlePublicationByVisibilityTypeAndStatus(
    $visibilityType: VisibilityType!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticlePublicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlePublicationByVisibilityTypeAndStatus(
      visibilityType: $visibilityType
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getArticlePublicationPublisher = /* GraphQL */ `
  query GetArticlePublicationPublisher($id: ID!) {
    getArticlePublicationPublisher(id: $id) {
      id
      name
      status
      articlesPublicationsPublished {
        items {
          id
          articlePublicationId
          articlePublicationPublisherId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listArticlePublicationPublishers = /* GraphQL */ `
  query ListArticlePublicationPublishers(
    $filter: ModelArticlePublicationPublisherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticlePublicationPublishers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        articlesPublicationsPublished {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncArticlePublicationPublishers = /* GraphQL */ `
  query SyncArticlePublicationPublishers(
    $filter: ModelArticlePublicationPublisherFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncArticlePublicationPublishers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        status
        articlesPublicationsPublished {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCrigStaffGroup = /* GraphQL */ `
  query GetCrigStaffGroup($id: ID!) {
    getCrigStaffGroup(id: $id) {
      id
      deleted
      name
      shortDescription
      status
      slug
      dateUploaded
      datePublished
      totalViewsCount
      totalStaff
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCrigStaffGroups = /* GraphQL */ `
  query ListCrigStaffGroups(
    $filter: ModelCrigStaffGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrigStaffGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deleted
        name
        shortDescription
        status
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCrigStaffGroups = /* GraphQL */ `
  query SyncCrigStaffGroups(
    $filter: ModelCrigStaffGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCrigStaffGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        deleted
        name
        shortDescription
        status
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const crigStaffGroupByNameAndStatus = /* GraphQL */ `
  query CrigStaffGroupByNameAndStatus(
    $name: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCrigStaffGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    crigStaffGroupByNameAndStatus(
      name: $name
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deleted
        name
        shortDescription
        status
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const crigStaffGroupBySlug = /* GraphQL */ `
  query CrigStaffGroupBySlug(
    $slug: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCrigStaffGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    crigStaffGroupBySlug(
      slug: $slug
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deleted
        name
        shortDescription
        status
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCrigUnitDepartment = /* GraphQL */ `
  query GetCrigUnitDepartment($id: ID!) {
    getCrigUnitDepartment(id: $id) {
      id
      name
      shortDescription
      deleted
      slug
      dateUploaded
      datePublished
      totalStaff
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      crigDivisionSubstationID
      crigDivisionSubstation {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCrigUnitDepartments = /* GraphQL */ `
  query ListCrigUnitDepartments(
    $filter: ModelCrigUnitDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrigUnitDepartments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shortDescription
        deleted
        slug
        dateUploaded
        datePublished
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        crigDivisionSubstationID
        crigDivisionSubstation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCrigUnitDepartments = /* GraphQL */ `
  query SyncCrigUnitDepartments(
    $filter: ModelCrigUnitDepartmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCrigUnitDepartments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        shortDescription
        deleted
        slug
        dateUploaded
        datePublished
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        crigDivisionSubstationID
        crigDivisionSubstation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const crigUnitDepartmentByNameAndStatus = /* GraphQL */ `
  query CrigUnitDepartmentByNameAndStatus(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCrigUnitDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    crigUnitDepartmentByNameAndStatus(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shortDescription
        deleted
        slug
        dateUploaded
        datePublished
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        crigDivisionSubstationID
        crigDivisionSubstation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const crigUnitDepartmentBySlug = /* GraphQL */ `
  query CrigUnitDepartmentBySlug(
    $slug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCrigUnitDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    crigUnitDepartmentBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shortDescription
        deleted
        slug
        dateUploaded
        datePublished
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        crigDivisionSubstationID
        crigDivisionSubstation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const crigUnitDepartmentByDivisionSubstation = /* GraphQL */ `
  query CrigUnitDepartmentByDivisionSubstation(
    $crigDivisionSubstationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCrigUnitDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    crigUnitDepartmentByDivisionSubstation(
      crigDivisionSubstationID: $crigDivisionSubstationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shortDescription
        deleted
        slug
        dateUploaded
        datePublished
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        crigDivisionSubstationID
        crigDivisionSubstation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFaq = /* GraphQL */ `
  query GetFaq($id: ID!) {
    getFaq(id: $id) {
      id
      title
      icon
      status
      faqItems {
        items {
          id
          title
          body
          status
          faqID
          dateAdded
          dateUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFaqs = /* GraphQL */ `
  query ListFaqs(
    $filter: ModelFaqFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFaqs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        icon
        status
        faqItems {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFaqs = /* GraphQL */ `
  query SyncFaqs(
    $filter: ModelFaqFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFaqs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        icon
        status
        faqItems {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFaqItem = /* GraphQL */ `
  query GetFaqItem($id: ID!) {
    getFaqItem(id: $id) {
      id
      title
      body
      status
      faqID
      faq {
        id
        title
        icon
        status
        faqItems {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFaqItems = /* GraphQL */ `
  query ListFaqItems(
    $filter: ModelFaqItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFaqItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        body
        status
        faqID
        faq {
          id
          title
          icon
          status
          dateAdded
          dateUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFaqItems = /* GraphQL */ `
  query SyncFaqItems(
    $filter: ModelFaqItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFaqItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        body
        status
        faqID
        faq {
          id
          title
          icon
          status
          dateAdded
          dateUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const faqItemByFaqAndStatus = /* GraphQL */ `
  query FaqItemByFaqAndStatus(
    $faqID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFaqItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    faqItemByFaqAndStatus(
      faqID: $faqID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        body
        status
        faqID
        faq {
          id
          title
          icon
          status
          dateAdded
          dateUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFolder = /* GraphQL */ `
  query GetFolder($id: ID!) {
    getFolder(id: $id) {
      id
      title
      color
      status
      pageType
      slug
      dateAdded
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFolders = /* GraphQL */ `
  query ListFolders(
    $filter: ModelFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFolders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        color
        status
        pageType
        slug
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFolders = /* GraphQL */ `
  query SyncFolders(
    $filter: ModelFolderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFolders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        color
        status
        pageType
        slug
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const folderByTypeAndStatus = /* GraphQL */ `
  query FolderByTypeAndStatus(
    $pageType: PageType!
    $sortDirection: ModelSortDirection
    $filter: ModelFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    folderByTypeAndStatus(
      pageType: $pageType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        color
        status
        pageType
        slug
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const folderBySlugAndStatus = /* GraphQL */ `
  query FolderBySlugAndStatus(
    $slug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    folderBySlugAndStatus(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        color
        status
        pageType
        slug
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      title
      shortDescription
      details
      pageDesignType
      pageDesignTextEditor
      pageDesignPageBuilder
      status
      totalViewsCount
      totalStaffCount
      totalDownloadsCount
      totalDeliveriesCount
      pageType
      tags
      slug
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postSubCategoryID
      postSubCategory {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      unitDepartments {
        items {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      galleryImages {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      documents {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      staffProfiles {
        items {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      dateAdded
      dateSent
      dateUpdated
      datePublished
      deleted
      listPosition
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPages = /* GraphQL */ `
  query SyncPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const pageByTypeAndStatus = /* GraphQL */ `
  query PageByTypeAndStatus(
    $pageType: PageType!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pageByTypeAndStatus(
      pageType: $pageType
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const pageBySlugAndStatus = /* GraphQL */ `
  query PageBySlugAndStatus(
    $slug: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pageBySlugAndStatus(
      slug: $slug
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const pageByPostCategoryAndStatus = /* GraphQL */ `
  query PageByPostCategoryAndStatus(
    $postCategoryID: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pageByPostCategoryAndStatus(
      postCategoryID: $postCategoryID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const pageByPageSubCategoryAndStatus = /* GraphQL */ `
  query PageByPageSubCategoryAndStatus(
    $postSubCategoryID: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pageByPageSubCategoryAndStatus(
      postSubCategoryID: $postSubCategoryID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const pageByCreatorUserAccountAndStatus = /* GraphQL */ `
  query PageByCreatorUserAccountAndStatus(
    $creatorUserAccountID: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pageByCreatorUserAccountAndStatus(
      creatorUserAccountID: $creatorUserAccountID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPageDesignTemplate = /* GraphQL */ `
  query GetPageDesignTemplate($id: ID!) {
    getPageDesignTemplate(id: $id) {
      id
      title
      description
      templateDesign
      status
      type
      deleted
      dateAdded
      datePublished
      dateUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPageDesignTemplates = /* GraphQL */ `
  query ListPageDesignTemplates(
    $filter: ModelPageDesignTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPageDesignTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        templateDesign
        status
        type
        deleted
        dateAdded
        datePublished
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPageDesignTemplates = /* GraphQL */ `
  query SyncPageDesignTemplates(
    $filter: ModelPageDesignTemplateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPageDesignTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        templateDesign
        status
        type
        deleted
        dateAdded
        datePublished
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPostCategory = /* GraphQL */ `
  query GetPostCategory($id: ID!) {
    getPostCategory(id: $id) {
      id
      name
      shortDescription
      slug
      dateUploaded
      deleted
      datePublished
      totalPostsCount
      totalSubCategoriesCount
      articlesPublications {
        items {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pages {
        items {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      subCategories {
        items {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPostCategories = /* GraphQL */ `
  query ListPostCategories(
    $filter: ModelPostCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPostCategories = /* GraphQL */ `
  query SyncPostCategories(
    $filter: ModelPostCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPostCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const postCategoryByName = /* GraphQL */ `
  query PostCategoryByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPostCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postCategoryByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const postCategoryBySlug = /* GraphQL */ `
  query PostCategoryBySlug(
    $slug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPostCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postCategoryBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPostSubCategory = /* GraphQL */ `
  query GetPostSubCategory($id: ID!) {
    getPostSubCategory(id: $id) {
      id
      name
      slug
      dateUploaded
      datePublished
      totalPosts
      deleted
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      articlesPublications {
        items {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pages {
        items {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPostSubCategories = /* GraphQL */ `
  query ListPostSubCategories(
    $filter: ModelPostSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostSubCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPostSubCategories = /* GraphQL */ `
  query SyncPostSubCategories(
    $filter: ModelPostSubCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPostSubCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const postSubCategoryByName = /* GraphQL */ `
  query PostSubCategoryByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPostSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postSubCategoryByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const postSubCategoryBySlug = /* GraphQL */ `
  query PostSubCategoryBySlug(
    $slug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPostSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postSubCategoryBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const postSubCategoryByParentCategory = /* GraphQL */ `
  query PostSubCategoryByParentCategory(
    $postCategoryID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPostSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postSubCategoryByParentCategory(
      postCategoryID: $postCategoryID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        dateUploaded
        datePublished
        totalPosts
        deleted
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSiteStats = /* GraphQL */ `
  query GetSiteStats($id: ID!) {
    getSiteStats(id: $id) {
      id
      researchActivities {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      products {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      aboutUs {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      externalServices {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      recreationFacilities {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      divisionsSubStations {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      articleAndPublications {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      eventsAndGallery {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      newsAndAnnouncements {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      newsletters {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      staff {
        totalViewsCount
        totalDownloadsCount
        totalFollowersCount
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSiteStats = /* GraphQL */ `
  query ListSiteStats(
    $filter: ModelSiteStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        researchActivities {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        products {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        aboutUs {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        externalServices {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        recreationFacilities {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        divisionsSubStations {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        articleAndPublications {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        eventsAndGallery {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        newsAndAnnouncements {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        newsletters {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        staff {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSiteStats = /* GraphQL */ `
  query SyncSiteStats(
    $filter: ModelSiteStatsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSiteStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        researchActivities {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        products {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        aboutUs {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        externalServices {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        recreationFacilities {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        divisionsSubStations {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        articleAndPublications {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        eventsAndGallery {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        newsAndAnnouncements {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        newsletters {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        staff {
          totalViewsCount
          totalDownloadsCount
          totalFollowersCount
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSlide = /* GraphQL */ `
  query GetSlide($id: ID!) {
    getSlide(id: $id) {
      id
      postCategoryID
      postCategory {
        id
        name
        shortDescription
        slug
        dateUploaded
        deleted
        datePublished
        totalPostsCount
        totalSubCategoriesCount
        articlesPublications {
          nextToken
          startedAt
        }
        pages {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      postCategoryName
      postSubCategoryName
      slideTitle
      slidePosition
      slidePageURL
      pageType
      isSidePop
      postID
      postSlug
      postCoverImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      status
      dateAdded
      dateUpdated
      deleted
      totalClicksCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSlides = /* GraphQL */ `
  query ListSlides(
    $filter: ModelSlideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSlides(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postCategoryName
        postSubCategoryName
        slideTitle
        slidePosition
        slidePageURL
        pageType
        isSidePop
        postID
        postSlug
        postCoverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        status
        dateAdded
        dateUpdated
        deleted
        totalClicksCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSlides = /* GraphQL */ `
  query SyncSlides(
    $filter: ModelSlideFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSlides(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postCategoryName
        postSubCategoryName
        slideTitle
        slidePosition
        slidePageURL
        pageType
        isSidePop
        postID
        postSlug
        postCoverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        status
        dateAdded
        dateUpdated
        deleted
        totalClicksCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const slideByPageType = /* GraphQL */ `
  query SlideByPageType(
    $pageType: PageType!
    $sortDirection: ModelSortDirection
    $filter: ModelSlideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    slideByPageType(
      pageType: $pageType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postCategoryName
        postSubCategoryName
        slideTitle
        slidePosition
        slidePageURL
        pageType
        isSidePop
        postID
        postSlug
        postCoverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        status
        dateAdded
        dateUpdated
        deleted
        totalClicksCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const slideBySidePop = /* GraphQL */ `
  query SlideBySidePop(
    $isSidePop: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSlideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    slideBySidePop(
      isSidePop: $isSidePop
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postCategoryName
        postSubCategoryName
        slideTitle
        slidePosition
        slidePageURL
        pageType
        isSidePop
        postID
        postSlug
        postCoverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        status
        dateAdded
        dateUpdated
        deleted
        totalClicksCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const slideByPost = /* GraphQL */ `
  query SlideByPost(
    $postID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSlideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    slideByPost(
      postID: $postID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postCategoryName
        postSubCategoryName
        slideTitle
        slidePosition
        slidePageURL
        pageType
        isSidePop
        postID
        postSlug
        postCoverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        status
        dateAdded
        dateUpdated
        deleted
        totalClicksCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const slideByPostSlug = /* GraphQL */ `
  query SlideByPostSlug(
    $postSlug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSlideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    slideByPostSlug(
      postSlug: $postSlug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postCategoryName
        postSubCategoryName
        slideTitle
        slidePosition
        slidePageURL
        pageType
        isSidePop
        postID
        postSlug
        postCoverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        status
        dateAdded
        dateUpdated
        deleted
        totalClicksCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const slideByStatus = /* GraphQL */ `
  query SlideByStatus(
    $status: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSlideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    slideByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postCategoryName
        postSubCategoryName
        slideTitle
        slidePosition
        slidePageURL
        pageType
        isSidePop
        postID
        postSlug
        postCoverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        status
        dateAdded
        dateUpdated
        deleted
        totalClicksCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getStaffProfile = /* GraphQL */ `
  query GetStaffProfile($id: ID!) {
    getStaffProfile(id: $id) {
      id
      listPosition
      profileImage {
        key
        size
        lastModified
        eTag
        blurHash
        signedURL
      }
      nameFirst
      nameLast
      deleted
      nameFull
      slug
      crigDivisionSubStationID
      crigDivisionSubStation {
        id
        title
        shortDescription
        details
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        status
        totalViewsCount
        totalStaffCount
        totalDownloadsCount
        totalDeliveriesCount
        pageType
        tags
        slug
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitDepartments {
          nextToken
          startedAt
        }
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        staffProfiles {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        dateAdded
        dateSent
        dateUpdated
        datePublished
        deleted
        listPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      crigStaffGroupID
      crigStaffGroup {
        id
        deleted
        name
        shortDescription
        status
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      crigUnitDepartmentID
      crigUnitDepartment {
        id
        name
        shortDescription
        deleted
        slug
        dateUploaded
        datePublished
        totalStaff
        staffProfiles {
          nextToken
          startedAt
        }
        crigDivisionSubstationID
        crigDivisionSubstation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      position
      isAContentFollower
      userAccountID
      bioDesignType
      bioDesignPageBuilder
      bioDesignTextEditors {
        title
        body
      }
      status
      dateAdded
      dateUpdated
      articlesPublicationsAuthored {
        items {
          id
          articlePublicationId
          staffProfileId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      totalViewsCount
      creatorUserAccountID
      creatorUserAccount {
        id
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        category
        deleted
        nameFirst
        nameLast
        nameFull
        authId
        status
        contactPhoneNumber1
        contactPhoneNumber2
        contactEmail1
        contactEmail2
        countryIso
        countryName
        staffProfileID
        userRoleID
        userRole {
          id
          title
          shortDescription
          moreDetailsTextEditor
          status
          dateAdded
          dateUpdated
          creatorUserAccountID
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFollowing
        dateLastLogin
        dateAdded
        dateAddedYear
        dateAddedMonth
        dateAddedDay
        dateUpdated
        dateUpdatedYear
        dateUpdatedMonth
        dateUpdatedDay
        postViewsDownloads {
          nextToken
          startedAt
        }
        totalViewsCount
        totalPostDownloadsCount
        totalPostViewsCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listStaffProfiles = /* GraphQL */ `
  query ListStaffProfiles(
    $filter: ModelStaffProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        listPosition
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        nameFirst
        nameLast
        deleted
        nameFull
        slug
        crigDivisionSubStationID
        crigDivisionSubStation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigStaffGroupID
        crigStaffGroup {
          id
          deleted
          name
          shortDescription
          status
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalStaff
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigUnitDepartmentID
        crigUnitDepartment {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        position
        isAContentFollower
        userAccountID
        bioDesignType
        bioDesignPageBuilder
        bioDesignTextEditors {
          title
          body
        }
        status
        dateAdded
        dateUpdated
        articlesPublicationsAuthored {
          nextToken
          startedAt
        }
        totalViewsCount
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStaffProfiles = /* GraphQL */ `
  query SyncStaffProfiles(
    $filter: ModelStaffProfileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStaffProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        listPosition
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        nameFirst
        nameLast
        deleted
        nameFull
        slug
        crigDivisionSubStationID
        crigDivisionSubStation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigStaffGroupID
        crigStaffGroup {
          id
          deleted
          name
          shortDescription
          status
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalStaff
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigUnitDepartmentID
        crigUnitDepartment {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        position
        isAContentFollower
        userAccountID
        bioDesignType
        bioDesignPageBuilder
        bioDesignTextEditors {
          title
          body
        }
        status
        dateAdded
        dateUpdated
        articlesPublicationsAuthored {
          nextToken
          startedAt
        }
        totalViewsCount
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const staffProfileBySlugAndStatus = /* GraphQL */ `
  query StaffProfileBySlugAndStatus(
    $slug: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffProfileBySlugAndStatus(
      slug: $slug
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        listPosition
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        nameFirst
        nameLast
        deleted
        nameFull
        slug
        crigDivisionSubStationID
        crigDivisionSubStation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigStaffGroupID
        crigStaffGroup {
          id
          deleted
          name
          shortDescription
          status
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalStaff
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigUnitDepartmentID
        crigUnitDepartment {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        position
        isAContentFollower
        userAccountID
        bioDesignType
        bioDesignPageBuilder
        bioDesignTextEditors {
          title
          body
        }
        status
        dateAdded
        dateUpdated
        articlesPublicationsAuthored {
          nextToken
          startedAt
        }
        totalViewsCount
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const staffProfileByCrigDivisionSubStationAndStatus = /* GraphQL */ `
  query StaffProfileByCrigDivisionSubStationAndStatus(
    $crigDivisionSubStationID: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffProfileByCrigDivisionSubStationAndStatus(
      crigDivisionSubStationID: $crigDivisionSubStationID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        listPosition
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        nameFirst
        nameLast
        deleted
        nameFull
        slug
        crigDivisionSubStationID
        crigDivisionSubStation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigStaffGroupID
        crigStaffGroup {
          id
          deleted
          name
          shortDescription
          status
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalStaff
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigUnitDepartmentID
        crigUnitDepartment {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        position
        isAContentFollower
        userAccountID
        bioDesignType
        bioDesignPageBuilder
        bioDesignTextEditors {
          title
          body
        }
        status
        dateAdded
        dateUpdated
        articlesPublicationsAuthored {
          nextToken
          startedAt
        }
        totalViewsCount
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const staffProfileByCrigStaffGroupAndStatus = /* GraphQL */ `
  query StaffProfileByCrigStaffGroupAndStatus(
    $crigStaffGroupID: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffProfileByCrigStaffGroupAndStatus(
      crigStaffGroupID: $crigStaffGroupID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        listPosition
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        nameFirst
        nameLast
        deleted
        nameFull
        slug
        crigDivisionSubStationID
        crigDivisionSubStation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigStaffGroupID
        crigStaffGroup {
          id
          deleted
          name
          shortDescription
          status
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalStaff
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigUnitDepartmentID
        crigUnitDepartment {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        position
        isAContentFollower
        userAccountID
        bioDesignType
        bioDesignPageBuilder
        bioDesignTextEditors {
          title
          body
        }
        status
        dateAdded
        dateUpdated
        articlesPublicationsAuthored {
          nextToken
          startedAt
        }
        totalViewsCount
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const staffProfileByCrigUnitDepartmentAndStatus = /* GraphQL */ `
  query StaffProfileByCrigUnitDepartmentAndStatus(
    $crigUnitDepartmentID: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffProfileByCrigUnitDepartmentAndStatus(
      crigUnitDepartmentID: $crigUnitDepartmentID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        listPosition
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        nameFirst
        nameLast
        deleted
        nameFull
        slug
        crigDivisionSubStationID
        crigDivisionSubStation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigStaffGroupID
        crigStaffGroup {
          id
          deleted
          name
          shortDescription
          status
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalStaff
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigUnitDepartmentID
        crigUnitDepartment {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        position
        isAContentFollower
        userAccountID
        bioDesignType
        bioDesignPageBuilder
        bioDesignTextEditors {
          title
          body
        }
        status
        dateAdded
        dateUpdated
        articlesPublicationsAuthored {
          nextToken
          startedAt
        }
        totalViewsCount
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const staffProfileByCreatorUserAccountAndStatus = /* GraphQL */ `
  query StaffProfileByCreatorUserAccountAndStatus(
    $creatorUserAccountID: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffProfileByCreatorUserAccountAndStatus(
      creatorUserAccountID: $creatorUserAccountID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        listPosition
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        nameFirst
        nameLast
        deleted
        nameFull
        slug
        crigDivisionSubStationID
        crigDivisionSubStation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigStaffGroupID
        crigStaffGroup {
          id
          deleted
          name
          shortDescription
          status
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalStaff
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigUnitDepartmentID
        crigUnitDepartment {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        position
        isAContentFollower
        userAccountID
        bioDesignType
        bioDesignPageBuilder
        bioDesignTextEditors {
          title
          body
        }
        status
        dateAdded
        dateUpdated
        articlesPublicationsAuthored {
          nextToken
          startedAt
        }
        totalViewsCount
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getArticlePublicationsAuthors = /* GraphQL */ `
  query GetArticlePublicationsAuthors($id: ID!) {
    getArticlePublicationsAuthors(id: $id) {
      id
      articlePublicationId
      staffProfileId
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      staffProfile {
        id
        listPosition
        profileImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        nameFirst
        nameLast
        deleted
        nameFull
        slug
        crigDivisionSubStationID
        crigDivisionSubStation {
          id
          title
          shortDescription
          details
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          status
          totalViewsCount
          totalStaffCount
          totalDownloadsCount
          totalDeliveriesCount
          pageType
          tags
          slug
          postCategoryID
          postSubCategoryID
          creatorUserAccountID
          dateAdded
          dateSent
          dateUpdated
          datePublished
          deleted
          listPosition
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigStaffGroupID
        crigStaffGroup {
          id
          deleted
          name
          shortDescription
          status
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalStaff
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        crigUnitDepartmentID
        crigUnitDepartment {
          id
          name
          shortDescription
          deleted
          slug
          dateUploaded
          datePublished
          totalStaff
          crigDivisionSubstationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        position
        isAContentFollower
        userAccountID
        bioDesignType
        bioDesignPageBuilder
        bioDesignTextEditors {
          title
          body
        }
        status
        dateAdded
        dateUpdated
        articlesPublicationsAuthored {
          nextToken
          startedAt
        }
        totalViewsCount
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listArticlePublicationsAuthors = /* GraphQL */ `
  query ListArticlePublicationsAuthors(
    $filter: ModelArticlePublicationsAuthorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticlePublicationsAuthors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articlePublicationId
        staffProfileId
        articlePublication {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        staffProfile {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncArticlePublicationsAuthors = /* GraphQL */ `
  query SyncArticlePublicationsAuthors(
    $filter: ModelArticlePublicationsAuthorsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncArticlePublicationsAuthors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        articlePublicationId
        staffProfileId
        articlePublication {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        staffProfile {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const articlePublicationsAuthorsByArticlePublicationId = /* GraphQL */ `
  query ArticlePublicationsAuthorsByArticlePublicationId(
    $articlePublicationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelArticlePublicationsAuthorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlePublicationsAuthorsByArticlePublicationId(
      articlePublicationId: $articlePublicationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articlePublicationId
        staffProfileId
        articlePublication {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        staffProfile {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const articlePublicationsAuthorsByStaffProfileId = /* GraphQL */ `
  query ArticlePublicationsAuthorsByStaffProfileId(
    $staffProfileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelArticlePublicationsAuthorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlePublicationsAuthorsByStaffProfileId(
      staffProfileId: $staffProfileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articlePublicationId
        staffProfileId
        articlePublication {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        staffProfile {
          id
          listPosition
          nameFirst
          nameLast
          deleted
          nameFull
          slug
          crigDivisionSubStationID
          crigStaffGroupID
          crigUnitDepartmentID
          position
          isAContentFollower
          userAccountID
          bioDesignType
          bioDesignPageBuilder
          status
          dateAdded
          dateUpdated
          totalViewsCount
          creatorUserAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getArticlePublicationsPublishers = /* GraphQL */ `
  query GetArticlePublicationsPublishers($id: ID!) {
    getArticlePublicationsPublishers(id: $id) {
      id
      articlePublicationId
      articlePublicationPublisherId
      articlePublication {
        id
        title
        shortDescription
        pageDesignType
        pageDesignTextEditor
        pageDesignPageBuilder
        coverImage {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        galleryImages {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        documents {
          key
          size
          lastModified
          eTag
          blurHash
          signedURL
        }
        tags
        authorStaffProfiles {
          nextToken
          startedAt
        }
        authorStaffProfilesIds
        publishersIds
        publishers {
          nextToken
          startedAt
        }
        status
        deleted
        slug
        dateUploaded
        datePublished
        totalViewsCount
        totalDownloadsCount
        postCategoryID
        postCategory {
          id
          name
          shortDescription
          slug
          dateUploaded
          deleted
          datePublished
          totalPostsCount
          totalSubCategoriesCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        postSubCategoryID
        postSubCategory {
          id
          name
          slug
          dateUploaded
          datePublished
          totalPosts
          deleted
          postCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        volumeNumber
        editionNumber
        issueNumber
        issn
        userViewsDownloads {
          nextToken
          startedAt
        }
        creatorUserAccountID
        creatorUserAccount {
          id
          category
          deleted
          nameFirst
          nameLast
          nameFull
          authId
          status
          contactPhoneNumber1
          contactPhoneNumber2
          contactEmail1
          contactEmail2
          countryIso
          countryName
          staffProfileID
          userRoleID
          contentFollowing
          dateLastLogin
          dateAdded
          dateAddedYear
          dateAddedMonth
          dateAddedDay
          dateUpdated
          dateUpdatedYear
          dateUpdatedMonth
          dateUpdatedDay
          totalViewsCount
          totalPostDownloadsCount
          totalPostViewsCount
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        visibilityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      articlePublicationPublisher {
        id
        name
        status
        articlesPublicationsPublished {
          nextToken
          startedAt
        }
        dateAdded
        dateUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listArticlePublicationsPublishers = /* GraphQL */ `
  query ListArticlePublicationsPublishers(
    $filter: ModelArticlePublicationsPublishersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticlePublicationsPublishers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articlePublicationId
        articlePublicationPublisherId
        articlePublication {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlePublicationPublisher {
          id
          name
          status
          dateAdded
          dateUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncArticlePublicationsPublishers = /* GraphQL */ `
  query SyncArticlePublicationsPublishers(
    $filter: ModelArticlePublicationsPublishersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncArticlePublicationsPublishers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        articlePublicationId
        articlePublicationPublisherId
        articlePublication {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlePublicationPublisher {
          id
          name
          status
          dateAdded
          dateUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const articlePublicationsPublishersByArticlePublicationId = /* GraphQL */ `
  query ArticlePublicationsPublishersByArticlePublicationId(
    $articlePublicationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelArticlePublicationsPublishersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlePublicationsPublishersByArticlePublicationId(
      articlePublicationId: $articlePublicationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articlePublicationId
        articlePublicationPublisherId
        articlePublication {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlePublicationPublisher {
          id
          name
          status
          dateAdded
          dateUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const articlePublicationsPublishersByArticlePublicationPublisherId = /* GraphQL */ `
  query ArticlePublicationsPublishersByArticlePublicationPublisherId(
    $articlePublicationPublisherId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelArticlePublicationsPublishersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlePublicationsPublishersByArticlePublicationPublisherId(
      articlePublicationPublisherId: $articlePublicationPublisherId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articlePublicationId
        articlePublicationPublisherId
        articlePublication {
          id
          title
          shortDescription
          pageDesignType
          pageDesignTextEditor
          pageDesignPageBuilder
          tags
          authorStaffProfilesIds
          publishersIds
          status
          deleted
          slug
          dateUploaded
          datePublished
          totalViewsCount
          totalDownloadsCount
          postCategoryID
          postSubCategoryID
          volumeNumber
          editionNumber
          issueNumber
          issn
          creatorUserAccountID
          visibilityType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        articlePublicationPublisher {
          id
          name
          status
          dateAdded
          dateUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
