import { Button } from "primereact/button";

interface Props {
  viewOnClick: VoidFunction;
  editOnClick: (rowData: any) => void;
  deleteOnClick: VoidFunction;
}

function TableActionButtonSet({ viewOnClick, editOnClick, deleteOnClick }: Props) {

  return (
    <span className="flex t-items-center t-justify-start t-rounded-xl t-h-8">
      {/* <Button
        label="View"
        className="p-button-rounded p-button-view-action p-button-text p-button-success"
        icon="pi pi-file"
        onClick={viewOnClick}
      /> */}
      <Button
        className="p-button-rounded p-button-view-edit p-button-text p-button-warning"
        label="Edit"
        icon="pi pi-pencil"
        onClick={editOnClick}
      />
      <Button
        className="p-button-rounded p-button-view-delete p-button-text p-button-danger"
        label="Delete"
        icon="pi pi-trash"
        onClick={deleteOnClick}
      />
    </span>
  );
}

export default TableActionButtonSet;
